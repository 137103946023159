import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React, { forwardRef } from 'react';

export const MoveIcon: React.FC<SvgIconProps> = forwardRef(
  ({ ...props }, ref) => {
    return (
      <SvgIcon {...props} viewBox="0 0 24 24.02" innerRef={ref}>
        <g transform="translate(-1068 -334)">
          <path
            d="M-4548.322,8569.105l-3.23-4.057a.993.993,0,0,1,.308-1.372.992.992,0,0,1,1.372.308l1.375,1.551v-5.3a1,1,0,0,1-.024-.217V8558.5h-5.5v.007h-.974l1.57,1.367a.993.993,0,0,1,.308,1.372,1,1,0,0,1-1.372.311l-4.056-3.209a.994.994,0,0,1-.456-.835,1,1,0,0,1,.456-.836l4.056-3.23a.992.992,0,0,1,1.372.308,1,1,0,0,1-.308,1.374l-1.55,1.372h5.518a1.035,1.035,0,0,1,.208-.021h.727v-5.5h-.006V8550l-1.366,1.572a1,1,0,0,1-1.372.308.994.994,0,0,1-.31-1.372l3.207-4.059a1,1,0,0,1,.836-.454,1,1,0,0,1,.837.454l3.23,4.059a.994.994,0,0,1-.31,1.372,1,1,0,0,1-1.372-.308l-1.374-1.556v5.3a1.03,1.03,0,0,1,.023.218v.938h5.289v-.007h.974l-1.57-1.367a.994.994,0,0,1-.308-1.372,1,1,0,0,1,1.372-.311l4.056,3.209a.992.992,0,0,1,.456.835,1,1,0,0,1-.456.836l-4.056,3.23a.992.992,0,0,1-1.372-.308,1,1,0,0,1,.308-1.374l1.55-1.372h-4.518a1.034,1.034,0,0,1-.207.021h-1.519v6.08h.006v.975l1.368-1.57a.992.992,0,0,1,1.372-.308.993.993,0,0,1,.308,1.372l-3.207,4.057a.994.994,0,0,1-.836.455A1,1,0,0,1-4548.322,8569.105Z"
            transform="translate(5627.498 -8211.541)"
            fill="#3f53d9"
          />
          <rect
            width="24"
            height="24"
            transform="translate(1068 334)"
            fill="none"
          />
        </g>
      </SvgIcon>
    );
  }
);

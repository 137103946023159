import React from 'react';
import { Box, Tooltip, Typography, makeStyles } from '@material-ui/core';
import { TIntegration } from '../../../types';
import { tl } from '../../../utils';
import { PointsMenu } from '../../PointsMenu';
import Cell from './cell';
import Row from './row';
import IntegrationCard from './rowComps/integrationCard';
import SellCard from './rowComps/sellCard';

const useStyles = makeStyles(theme =>({
  row: {
    height: 56,
  },
  name: {
    paddingLeft: 72,
    fontSize: "13px",
    fontWeight: 600,
    color: theme.palette.grey[600],
  },
  sell: {
    padding: "0 24px",
    fontSize: "13px",
    fontWeight: 600,
    color: theme.palette.grey[600],
  }
}));

interface IntegrationTableProps {
  list: Array<TIntegration>;
  onDelete: (integration: TIntegration) => void;
}

export const IntegrationTable: React.FC<IntegrationTableProps> = ({ list, onDelete }) => {
  const classes = useStyles();

  return (
    <Box>
      <Row key="header-integrationRow" className={classes.row}>
        <Cell flexGrow={15} alignItems="center" justifyContent="space-between">
          <Typography className={classes.name}>
            Интеграция с сервисом
          </Typography>
        </Cell>
        <Cell flexGrow={3} minWidth={'15%'} maxWidth={'15%'} alignItems="center" justifyContent="center">
          <Typography className={classes.sell}>
            Кол-во продаж
          </Typography>
        </Cell>
        <Cell flexGrow={2} minWidth={'10%'} maxWidth={'10%'} alignItems="center" justifyContent="center" />
      </Row>
      {list.map((integration, idx) => (
        <Row key={`${idx}-integrationRow`}>
          <Tooltip title="Редактировать" arrow placement="top">
            <Cell flexGrow={15} alignItems="center" justifyContent="space-between">
              <IntegrationCard integration={integration} />
            </Cell>
          </Tooltip>
          <Tooltip title="Посмотреть продажи" arrow placement="top">
            <Cell flexGrow={3} minWidth={'15%'} maxWidth={'15%'} alignItems="center" justifyContent="center">
              <SellCard integration={integration} />
            </Cell>
          </Tooltip>
          <Cell flexGrow={2} minWidth={'10%'} maxWidth={'10%'} alignItems="center" justifyContent="center">
            <PointsMenu menuItems={[
              {id: 1, title: tl('Удалить'), action: () => onDelete(integration)}
            ]} />
          </Cell>
        </Row>
      ))}
    </Box>
  );
}
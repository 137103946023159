import * as React from "react";
import cx from "classnames";

interface IPopupProps {
  header: any;
  bottom: any;
  bottomCSSModifier?: string;
  onClose?: any;
  onCancel?: any;
  isVisible: boolean;
  width?: string;
  info?: boolean;
}

export default class Popup extends React.Component<IPopupProps, {}> {
  onBackgroundClick(e: any) {
    if (e.target !== e.currentTarget) {
      return;
    }
    if (!!this.props.onCancel) {
      this.props.onCancel();
      return;
    }
    if (!!this.props.onClose) {
      this.props.onClose();
    }
  }

  render() {
    const {
      children,
      header,
      bottom,
      onClose,
      onCancel,
      isVisible,
      info,
    } = this.props;

    if (isVisible === false) {
      return null;
    }

    return (
      <>
        <div className="modal-fader" style={{display: "block"}}/>
        <div className="modal-wrap" onClick={this.onBackgroundClick.bind(this)}>
          <div
            className="modal"
            style={{
              display: "block",
              width: this.props.width !== undefined ? "100%" : "auto",
              maxWidth: this.props.width,
            }}
          >
            <div className="modal-head">{header}</div>
            <div
              className={cx("modal-text", {
                info: info,
                nobdrs: !!bottom,
                bdrs: !bottom && !info,
              })}
            >
              {children}
            </div>
            {!bottom || (
              <div
                className={`modal-panel ${
                  this.props.bottomCSSModifier
                    ? this.props.bottomCSSModifier
                    : ""
                }`}
              >
                {bottom}
              </div>
            )}
            {!!onCancel || !!onClose ? (
              <div
                className="modal-close"
                onClick={!!onCancel ? onCancel : onClose}
              >
                <i/>
              </div>
            ) : null}
          </div>
        </div>
      </>
    );
  }
}

import { EWebinarEvents, IWebinar, IWebinarEvent } from "../types";
import { apiClient, IListResponse } from "./_client";


export const fetch = async (webinar: IWebinar, offset: number, limit: number): Promise<IListResponse<IWebinarEvent, { isReady: boolean }>> => {
  const response = await apiClient.get<IListResponse<IWebinarEvent, { isReady: boolean; }>>("/scenario", {
    params: {
      webinar: webinar.id,
      offset: offset,
      limit: limit,
    }
  });
  return response.data;
}

export const add = async (webinar: IWebinar, event: string, params: any): Promise<IWebinarEvent> => {
  const response = await apiClient.post<IWebinarEvent>(`/scenario?webinar=${webinar.id}`, {
    event: event,
    params: params
  });
  return response.data;
}

export const save = async (event: IWebinarEvent): Promise<IWebinarEvent> => {
  const response = await apiClient.put<IWebinarEvent>(`/scenario/${event.id}`, event);
  return response.data;
}

export const remove = async (event: IWebinarEvent): Promise<boolean> => {
  await apiClient.delete<IWebinarEvent>(`/scenario/${event.id}`);
  return true;
}

export const removeByType = async (webinar: IWebinar, type: EWebinarEvents): Promise<boolean> => {
  await apiClient.delete(`/scenario?webinar=${webinar.id}`, {
    params: {
      type: type
    }
  });
  return true;
}

export const importScenario = async (
  webinar: IWebinar,
  file: File,
  truncate: boolean = false,
  needAdapter: boolean = false,
): Promise<boolean> => {
  const formData = new FormData();
  formData.append('file', (file as Blob), file.name);
  formData.append('webinar', webinar.id);
  truncate && formData.append('truncate', 'true');
  needAdapter && formData.append('adapter', 'smartwebinar');
  const response = await apiClient.post(`/scenario/import`,
    formData,
    {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    }
  );
  return response.status === 200;
}

import {IPlayerState, IWebinarEvent} from "../types";
import {apiClient, IListResponse} from "./_client";

export async function status (streamId: string): Promise<{state: Partial<IPlayerState>, isFinished: boolean}> {
  const response = await apiClient.get(`/stream/${streamId}/status`);
  return response.data;
}

export const history = async (streamId: string, offset:number, limit:number):Promise<IListResponse<IWebinarEvent, {isReady: boolean;}>> => {
  const response = await apiClient.get<IListResponse<IWebinarEvent, {isReady: boolean;}>>(`/stream/${streamId}/history`,{
    params: {
      offset:offset,
      limit:limit
    }
  });
  return response.data;
}
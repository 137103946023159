import { IWebinarEvent } from '../../types';

export const EVENT_SAVE = 'EVENT_SAVE';
export const EVENT_ADD = 'EVENT_ADD';
export const EVENT_REMOVE = 'EVENT_REMOVE';
export const EVENTS_RELOAD = 'EVENTS_RELOAD';
export const EVENTS_FETCH_ATTEMPT = 'EVENTS_FETCH_ATTEMPT';
export const EVENTS_FETCH_SUCCESS = 'EVENTS_FETCH_SUCCESS';
export const EVENTS_FETCH_FAILURE = 'EVENTS_FETCH_FAILURE';
export const EVENTS_FETCH_STATUS_CHANGE = 'EVENTS_FETCH_STATUS_CHANGE';

export interface IEventsFetchStatusChangeAction {
  type: typeof EVENTS_FETCH_STATUS_CHANGE;
  status: boolean;
}
export interface IEventsFetchAttemptAction {
  type: typeof EVENTS_FETCH_ATTEMPT;
}

export interface IEventsFetchSuccessAction {
  type: typeof EVENTS_FETCH_SUCCESS;
  events: IWebinarEvent[];
  count: number;
}

export interface IEventsFetchFailureAction {
  type: typeof EVENTS_FETCH_FAILURE;
}

export interface ISaveEventAction {
  type: typeof EVENT_SAVE;
  event: IWebinarEvent;
}

export interface IAddEventAction {
  type: typeof EVENT_ADD;
  event: IWebinarEvent;
}

export interface IRemoveEventAction {
  type: typeof EVENT_REMOVE;
  event: IWebinarEvent;
}

export interface IEventsReloadAction {
  type: typeof EVENTS_RELOAD;
}

export type TEventsActionType =
  | ISaveEventAction
  | IAddEventAction
  | IRemoveEventAction
  | IEventsReloadAction
  | IEventsFetchStatusChangeAction
  | IEventsFetchAttemptAction
  | IEventsFetchSuccessAction
  | IEventsFetchFailureAction;

import { Typography,Box } from '@material-ui/core';
import React from 'react'
import { MUIDialog } from '../../MUI';
import { EWebinarType, IWebinarExternalSettings } from '../../types';
import { tl } from '../../utils';


export interface WebinarDeleteModalProps {
    open: boolean
    webinarType: EWebinarType
    externalSettings?: IWebinarExternalSettings
    handleClose: () => void
    handleConfirm:()=>void
}

export const WebinarDeleteModal:React.FC<WebinarDeleteModalProps>=({open,handleClose,handleConfirm,webinarType,externalSettings})=>{


    return (
        <MUIDialog
            title={tl(`Удаление ${webinarType !== EWebinarType.WEBINAR ? "авто" : ""}вебинара`)}
            open={open}
            onClose={handleClose}
            mobileFullScreen
            maxWidth="xs"
            actions={[
                [],
                [
                {
                    label: tl("Отмена"),
                    btnProps: {
                    onClick:handleClose
                    },
                },
                {
                    label: tl("Удалить"),
                    btnProps: {
                    color: "primary",
                    onClick: handleConfirm
                    },
                },
                ],
            ]}
            >
            <Box>
                <Typography>
                    {tl(`Вы уверены, что хотите удалить ${webinarType !== EWebinarType.WEBINAR ? "авто" : ""}вебинар?`)}
                    {externalSettings?.type === 'at' && <b>{tl(" Связанный урок-вебинар на Антитренингах тоже будет удален.")}</b>}
                </Typography>
            </Box>
    </MUIDialog>
    )
}
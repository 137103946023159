import React, { createContext, useRef, useState, useEffect } from "react";
import useAudioContext from "../../../hooks/useAudioContext";
import { EWebinarSourceType } from "../../../types";
import { GradientScreenMask, ScreenMuted } from "../Screen";
import { KinescopeVideoPlayer } from "./kinescope";
import { RutubeVideoPlayer } from "./rutube";
import { YoutubeVideoPlayer } from "./youtube";
import { VKVideoPlayer } from "./vk";

type TErrorType = 'fatal' | 'simple'
interface VideoPlayerCtxValue {
  hasStarted: boolean;
  isPlaying: boolean;
  fatalError: boolean;
  ignoreCurtain: boolean;
  setStarted: (started: boolean) => void;
  setPlaying: (playing: boolean) => void;
  handleMask: () => void;
  setMask: (mask: boolean) => void;
  setError: (type: TErrorType, details: any) => void;
  setIgnoreCurtain: (type: boolean) => void;
}

export const VideoPlayerCtx = createContext<VideoPlayerCtxValue>({
  hasStarted: false,
  isPlaying: false,
  fatalError: false,
  ignoreCurtain: false,
  setStarted: () => {},
  setPlaying: () => {},
  setMask: () => {},
  handleMask: () => {},
  setError: () => {},
  setIgnoreCurtain: () => {},
});


export const VideoPlayerFactory: React.FC<VideoPlayerProps> = (props) => {

  switch (props.type) {
    case EWebinarSourceType.RUTUBE:
      return <RutubeVideoPlayer {...props} />
    case EWebinarSourceType.VK:
      return <VKVideoPlayer {...props} />
    case EWebinarSourceType.KINESCOPE:
      return <KinescopeVideoPlayer {...props} />
    case EWebinarSourceType.YOUTUBE:
    case EWebinarSourceType.VIMEO:
    case EWebinarSourceType.BOOMSTREAM:
      return <YoutubeVideoPlayer {...props} />
    default:
      return null;
  }
}

export interface VideoPlayerProps {
  className?: string;
  src: string;
  type: EWebinarSourceType;
  live?: boolean;
  startTime?: number;
  seekTime?: number;
  onStart?: () => void;
  onReady?: () => void;
  onPause?: () => void;
  onEnded?: () => void;
  small?: boolean;
  hideMutedOnMobile?: boolean;
  hideVideo?: boolean;
}

export const VideoPlayer: React.FC<VideoPlayerProps> = (props) => {
  const [hasStarted, setStarted] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [fatalError, setFatalError] = useState(false);
  const [ignoreCurtain, setIgnoreCurtain] = useState(false);
  const [mask, setMask] = useState(true);
  const maskTO = useRef(0);
  const {muted, isFirstTimeMuted, setMuted} = useAudioContext();

  useEffect(() => {
    if (!props.hideVideo && hasStarted) {
      setMask(true)
      handleMask()
    }

    if (props.hideVideo && maskTO.current)  {
      window.clearTimeout(maskTO.current)
    }
    
  }, [props.hideVideo])

  const handleMask = () => {
    const isRutube = props.type === EWebinarSourceType.RUTUBE;
    window.clearTimeout(maskTO.current);
    maskTO.current = window.setTimeout(() => {
      setMask(false);
    }, isRutube ? 9500 : 3500);
  };

  const handleError = (type: TErrorType, details?: any) => {
     if (type === 'fatal') {
       setFatalError(true);
     }
  }

  
  return (
    <div style={{width: '100%'}}>
      <VideoPlayerCtx.Provider value={{
        isPlaying: playing,
        hasStarted: hasStarted,
        fatalError: fatalError,
        ignoreCurtain: ignoreCurtain,
        handleMask: handleMask,
        setMask: setMask,
        setError: handleError,
        setPlaying: setPlaying,
        setStarted: setStarted,
        setIgnoreCurtain: setIgnoreCurtain
      }}>
        <VideoPlayerFactory {...props} />
        {mask && <GradientScreenMask style={{pointerEvents: 'none'}} className={props.small ? 'small' : ''} />}
        {muted && hasStarted && isFirstTimeMuted && !props.hideMutedOnMobile &&
          <ScreenMuted small={props.small} onClick={() => {setMuted(false);}} />
        }
        <div style={{width: '100%', height: '100%', position: 'absolute', top: '0px', left: '0px', pointerEvents: ignoreCurtain ? 'none' : 'auto'}}></div>
      </VideoPlayerCtx.Provider>
    </div>
   
  )
}


import { Box, Button, Checkbox, Divider, makeStyles, FormControl, FormControlLabel, Grid, IconButton, InputBase, Menu, MenuItem, Radio, Select, styled, Typography } from '@material-ui/core';
import { Close, KeyboardArrowDown } from '@material-ui/icons';
import React, {  useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { EWebinarType, IWebinarFilter } from '../../../types';
import { tl } from '../../../utils';
import WebinarSortIcon from '../../Icon/webinarSort';
import { PlusIcon } from '../../Icon/PlusIcon';
import SvgIcon from '../../SvgIcon';
import AddWebinarButton from '../AddWebinarButton';
import CreateAutoWebButton from '../CreateAutoWebButton';

const Input = styled(InputBase)({
  alignItems: 'center'
});

const Tag = styled(Button)({
  marginLeft: 4,
  marginRight: 4,
  '& > span': {
    display: 'flex',
    alignItems: 'center',
  },
  '& .tag-remove': {
    width: 14,
    height: 14,
    fontSize: 14,
  }
});

const Container = styled(Box)({
  border: '1px solid #E3E4E8',
  padding: '13px 21px',
});



const useStyles = makeStyles((theme) => ({
  addButtonText: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  paramsMenu: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  }
}))

interface WebinarSearchBarProps {
  webinarType: EWebinarType;
  filter: IWebinarFilter;
  title: string;
  onAddClick: () => void;
  sortBy?: string;
  filterOptionsSelected: Array<string>;
  disabled: boolean;
  hideBtn: boolean;
  onFilterSend: (data: {
    search: string;
    sortBy: string;
    sortOrder: "asc" | "desc";
    filterOptions: string[];
  }) => void;
  onAutowebImportClick: () => void;
}

const sortOptions = [
  {value: "date", label: 'По дате вебинара'},
  {value: "creation", label: "По дате создания"},
]

const filterOptionsAvailable = [
  {value:"soon", label:"Ближайшие (Сегодня)"},
  {value:"future", label:"Будущие"},
  {value:"past", label:"Прошедшие"},
  {value:"draft", label:"Черновики"},
]

export const WebinarSearchBar: React.FC<WebinarSearchBarProps> = ({
  filter, 
  webinarType,
  title,
  onAddClick,
  onAutowebImportClick,
  filterOptionsSelected,
  disabled,
  onFilterSend,
  hideBtn
}) => {
  const classes = useStyles();
  const [paramsAnchor, setParamsAnchor] = useState<HTMLElement | null>(null);
  const [defaultAllChecked, setDefaultAllChecked] = useState(filter.filterOptions.length === filterOptionsAvailable.length);
  
  const allChecked = useMemo(() => filter.filterOptions.length === filterOptionsAvailable.length, [filter.filterOptions])
  
  const onSortBy = (e: React.ChangeEvent<{
    name?: string;
    value: string;
}>) => {
    const value = e.target.value;
    setParamsAnchor(null);
    onFilterSend({
      ...filter,
      sortBy: value,
      sortOrder: value === 'creation' ? 'desc' : 'asc'
    })
  };

  const handleCheck = (checked: boolean, value: string ) => {
    if (allChecked && checked) {
      onFilterSend({
        ...filter,
        filterOptions: [value]
      })
    }
    else if (checked) {
      onFilterSend({
        ...filter,
        filterOptions: filter.filterOptions.concat(value)
      })
    } else if (!checked && filter.filterOptions.length === 1) {
      onFilterSend({
        ...filter,
        filterOptions: filterOptionsAvailable.map(opt => opt.value)
      });
      setDefaultAllChecked(true);
    } else {
      onFilterSend({
        ...filter,
        filterOptions: filter.filterOptions.filter(item => item !== value)
      })
    }
  }

  const handleFilterOption = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const checked = evt.target.checked;
    const value = evt.target.value;
    setDefaultAllChecked(false);
    handleCheck(checked, value);
  }

  const handleCreateAutoWeb = (type: 'simple' | 'scenario') => {
    
    switch (type) {
      case 'simple':
        onAddClick();
        break;
      case 'scenario':
        onAutowebImportClick();
        break;
      default:
        break;
    }
  }


  return (
    <Container>
      <Grid container alignItems="center" spacing={1} wrap="nowrap">
        <Grid item xs>
          <Box pr={1}>
            <Input
              fullWidth
              data-testid="search"
              placeholder={tl(' Поиск')}
              value={filter.search}
              onChange={(e) => onFilterSend({...filter, search: e.target.value})}
              startAdornment={<Box mr={1}><SvgIcon type="search" /></Box>} 
              endAdornment={filter.search && <SvgIcon type="close" onClick={() => onFilterSend({...filter, search: ''})} />}
            />
          </Box>
        </Grid>
        {
          (filter.filterOptions.length < 4 && filter.filterOptions.length >=1) &&
          <Divider orientation="vertical" flexItem />
        }
        {
        filter.filterOptions.length < 4 &&  
          <Grid item container direction="row" xs wrap="nowrap">
            {filterOptionsSelected.map((opt, idx) => {
              let tagLabel = filterOptionsAvailable.find(o => o.value === opt)?.label
              tagLabel = tagLabel?.replace('(Сегодня)', ' ')
              return (
                <Tag 
                  key={`${idx}-sortOption`} 
                  variant="contained" color="default" 
                  endIcon={
                    <Close 
                      className="tag-remove" 
                      onClick={() => {
                        handleCheck(false, opt);
                      }} 
                    />
                  }
                >
                  {tagLabel}
                </Tag>
              )
            } 
            )}
          </Grid>
        }
        <Divider orientation="vertical" flexItem />
        <Grid item className={classes.paramsMenu}>
          <Button variant="text" onClick={(e) => setParamsAnchor(e.currentTarget)} endIcon={<KeyboardArrowDown fontSize="small" />}>{tl('Параметры')}</Button>
        </Grid>
        <Divider orientation="vertical" flexItem />
        
        <Grid item>
          <Box pl={"16px"}>
            <Link to={`/statistics/${webinarType === EWebinarType.AUTOWEBINAR ? 'autowebinars' : 'webinars'}`}>
              <SvgIcon type="task-statistic" />
            </Link>
          </Box>
        </Grid>
        {!hideBtn && 
        <>
          <Box pl={'16px'} />
          <Divider orientation="vertical" flexItem />
          <Box pl={'16px'} />
          
          <Grid item>
            {
              webinarType === EWebinarType.WEBINAR ?
              <AddWebinarButton 
                startIcon={<PlusIcon />} 
                onClick={onAddClick} 
                variant="contained" 
                color="primary" 
                data-testid="createWeb"
                >
                  {tl(title)}
              </AddWebinarButton>:
              <CreateAutoWebButton onClick={handleCreateAutoWeb} />
            }
          </Grid>
        </>}
        <Menu 
          open={Boolean(paramsAnchor)} 
          anchorEl={paramsAnchor}
          onClose={()=> setParamsAnchor(null)}
        >
          <Box px="16px">
            <FormControl component="fieldset" fullWidth>
              <Typography><b>{tl("Сортировать")}</b></Typography>
              <Box py="5px">
                <Grid item container direction="row" wrap="nowrap" alignItems="center">
                  <Select
                    variant="filled"
                    fullWidth={true}
                    value={filter.sortBy}
                    disabled={disabled}
                    //@ts-ignore
                    onChange={onSortBy}
                  >
                    {sortOptions.map(
                      (
                        option: { value: string; label: string },
                        i: number
                      ) => (
                        <MenuItem
                          key={`sort-${i}_${option.value}`}
                          value={option.value}
                        >
                          {option.label}
                        </MenuItem>
                      )
                    )}
                  </Select>
                  <IconButton>
                    <WebinarSortIcon 
                      onClick={() => 
                        onFilterSend({
                          ...filter,
                          sortOrder: filter.sortOrder === 'asc' ? 'desc' : 'asc'
                        })
                      } 
                    />
                  </IconButton>
                </Grid>
              </Box>
            </FormControl>
            <Divider />
              <Typography><b>{tl("Показывать")}</b></Typography>
              <FormControlLabel
                label="Все"
                control={<Radio color="primary" />}
                checked={filterOptionsSelected.length === filterOptionsAvailable.length }
                onChange={() =>{
                  setDefaultAllChecked(false);
                  filterOptionsSelected.length === filterOptionsAvailable.length
                  ? onFilterSend({...filter, filterOptions: []})
                  : onFilterSend({...filter, filterOptions: filterOptionsAvailable.map(fo => fo.value)})
                }}
              />
            <Divider />
            <FormControl>
              {
                filterOptionsAvailable.map(
                  (param, idx) => 
                    <FormControlLabel 
                      key={`${idx}-filterParam-time`}
                      control={<Checkbox value={param.value} color="primary" />} 
                      label={param.label}
                      checked={!defaultAllChecked && !!filter.filterOptions.find(item => item === param.value)}
                      //@ts-ignore
                      onChange={handleFilterOption}
                    />
                  )
              }
            </FormControl>
          </Box>
        
        </Menu>
      </Grid>
    </Container>
  );
}


export default WebinarSearchBar;
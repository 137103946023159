import * as React from "react";
import classnames from "classnames";
import styles  from "./styles.scss";
import {makeStyles} from "@material-ui/core/styles";
import {themeVariables} from "../../../../MUI";


const useStyles = makeStyles({
  tab: {
    borderBottom: `4px solid transparent`,
    color: themeVariables.palette.grey[600],
    "&:hover": {
      borderBottomColor: themeVariables.palette.grey["200"],
    },
  },
  active: {
    borderBottomColor: themeVariables.palette.primary.dark,
    color: themeVariables.palette.common.black,
    "&:hover": {
      borderBottomColor: themeVariables.palette.primary.dark,
    },
  },
})

interface ICardTabProp {
  tab: any;
  id: string;
  url?: string;
  isActive: boolean;
  onClick: Function;
}


export const CardTab = (props: ICardTabProp) => {
  const clickHandler = (e: React.MouseEvent<any>) => {
    const {tab, id} = props;
    props.onClick({tab, id}, e);
  };

  const classes = useStyles();

  const {isActive, tab, url} = props;

  const className = classnames({
    [styles.cardTab]: true,
    [styles.active]: isActive,
  });

  if (url) {
    return (
      <a
        className={`${className} ${styles.tabLink} ${classes.tab} ${
          isActive ? classes.active : ``
        }`}
        onClick={clickHandler}
        href={url}
      >
        {tab}
      </a>
    );
  }

  return (
    <div
      className={`${className} ${classes.tab} ${
        isActive ? classes.active : ``
      }`}
      onClick={clickHandler}
    >
      {tab}
    </div>
  );
};

export default CardTab;

import { Box, Grid, TextField, Typography, makeStyles } from '@material-ui/core';
import React, { useState } from 'react'
import { MUIDialog, MUIDialogProps } from '../../MUI';
import { tl } from '../../utils';
import SWLoader from '../Icon/swLoader';


const useStyles = makeStyles((theme) =>({
  content: {
    width: 542,
    maxWidth: 542,

  },
  text: {
    font: 'normal normal normal 13px/15px SF UI Text',
  },
  dialogContent: {
    padding: '20px 28px',
  },
  inputWrap: {
    width: 316,
    [theme.breakpoints.down('xs')]: {
      width: 'auto'
    }
  },
}))
export interface NewWebinarModalProps extends MUIDialogProps {
  wordRoot: string;
  onClose: () => void;
  handleSubmit: (name: string) => void;
  loading: boolean;
}

export const NewWebinarModal: React.FC<NewWebinarModalProps> = ({ wordRoot, open, onClose, handleSubmit, loading }) => {
  const classes = useStyles();
  const [name, setName] = useState('')

  return (
    <MUIDialog
      title={tl(`Создание ${wordRoot}а`)}
      open={open}
      classes={{
        paper: classes.content,
      }}
      dialogContentClassName={classes.dialogContent}
      mobileFullScreen
      onClose={onClose}
      actions={[
        [],
        [
          {
            label: tl("Отмена"),
            btnProps: {
              onClick: () => {
                onClose();
              },
            },
          },
          {
            label: loading ? <Box width="140px" display="flex" alignItems="center" justifyContent="center"><SWLoader color="primary" fontSize="small" /></Box> : tl(`Создать ${wordRoot}`),
            btnProps: {
              color: "primary",
              'data-testid': "createWebBtn",
              disabled: !name || loading,
              onClick: () => handleSubmit(name),
            },
          },
        ],
      ]}
    >
      <form onSubmit={(e) => {
        e.preventDefault()
        handleSubmit(name)
      }}>
        <Grid container direction="row" wrap="nowrap">
        <Grid item container xs justify="flex-end">
          <Box mr="10px" mt={"3px"}>
            <Typography className={classes.text}>
              {tl(`Название ${wordRoot}а:`)}
            </Typography>
          </Box>
        </Grid>
        <Grid item className={classes.inputWrap}>
          <TextField
              data-testid="webNameTextField"
              variant="outlined"
              fullWidth
              autoFocus
              multiline
              style={{height: 55}}
              rows={2}
              rowsMax={3}
              value={name}
              inputProps={{
                maxLength: 95,
              }}
              placeholder={tl(`Введите название ${wordRoot}а`)}
              onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
                setName(evt.target.value);
              }}
            />
        </Grid>
        </Grid>
      </form>
    </MUIDialog>
  )
}
import React, { useState, createContext } from 'react';
import useLocalStorage from '../hooks/useLocalStorage';

interface AudioCtxProps {
  muted: boolean;
  setMuted: (muted: boolean) => void;
  volume: number;
  setVolume: (level: number) => void;
  setPlayerReady?: (value: boolean) => void;
  isFirstTimeMuted?: boolean;
  isPlayerReady?: boolean;
}

export const AudioCtx = createContext<AudioCtxProps>({
  muted: true,
  setMuted: () => {},
  volume: 100,
  setVolume: () => {},
  setPlayerReady: () => {},
  isFirstTimeMuted: false,
  isPlayerReady: false,
});

export const AudioContextProvider: React.FC = ({ children }) => {
  const [muted, setMuted] = useState(true);
  const [volume, setVolume] = useLocalStorage('player_volume', 0);
  const [lastVolume, setLastVolume] = useState(100);
  const [isFirstTimeMuted, setFirstTimeMuted] = useState(true);
  const [isPlayerReady, setPlayerReady] = useState(false);

  const handleMute = (value: boolean) => {
    setMuted(value);
    if (value) {
      setLastVolume(volume);
      setVolume(0);
    } else {
      setVolume(lastVolume);
      if (isFirstTimeMuted) {
        setFirstTimeMuted(false);
      }
    }
  };

  const handleVolumeChange = (value: number) => {
    if (muted) {
      setMuted(false);
    }
    if (value === 0) {
      setMuted(true);
      setLastVolume(100);
    }
    setVolume(value);
  };

  return (
    <AudioCtx.Provider
      value={{
        muted,
        volume,
        isFirstTimeMuted,
        isPlayerReady,
        setMuted: handleMute,
        setVolume: handleVolumeChange,
        setPlayerReady: setPlayerReady,
      }}
    >
      {children}
    </AudioCtx.Provider>
  );
};

export default AudioContextProvider;

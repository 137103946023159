import {
  Box,
  Button,
  DialogProps,
  MenuItem,
  Typography,
  makeStyles,
  useMediaQuery,
} from '@material-ui/core';
import React, { forwardRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import useAppSelector from '../../hooks/useAppSelector';
import { FormSettingsRow, MUIDialog, MUISelect } from '../../MUI';
import { tl } from '../../utils';
import AlarmIcon from '../Icon/alarm';
import SmartCurrencyIcon from '../Icon/smartCurrency';
import SvgIcon from '../SvgIcon';
import { setupBaseUrl } from '../../api/_client';
import { getPayLink } from '../../store/billing/actions';

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: 15,
    lineHeight: '18px',
  },
  select: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    // height: 30,
    maxHeight: 30,
  },
  label: {
    display: 'flex',
    alignItems: 'flex-start',
    lineHeight: '30px',
  },
  icon: {
    marginLeft: '4px',
  },
  grey: {
    color: theme.palette.grey[600],
    textAlign: 'left',
    width: '100%',
    marginTop: '8px',
    fontSize: 13,
    lineHeight: '15px',
  },
}));

interface AddCointsModalProps {
  type?: 'empty' | 'low' | 'normal';
}

export const AddCoinsModal = forwardRef<
  HTMLElement,
  AddCointsModalProps & DialogProps
>(({ open, onClose, type }, ref) => {
  const classes = useStyles();
  const plans = useAppSelector((state) => state.plans);
  const billing = useAppSelector((state) => state.billing);
  const [chosenAmount, setAmount] = useState(2000);
  const layoutSmall = useMediaQuery('screen and (max-width: 1280px)');

  const dispatch = useDispatch();
  return (
    <MUIDialog
      title={
        type !== 'normal' ? (
          <Box display="flex" flexDirection="row">
            <Box
              mr="10px"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <AlarmIcon color={type === 'empty' ? 'error' : 'warning'} />
            </Box>
            {tl('Закончились места на вебинаре')}
          </Box>
        ) : (
          tl('Пополнить баланс')
        )
      }
      open={open}
      maxWidth="xs"
      //@ts-ignore
      container={layoutSmall ? undefined : ref.current}
      style={{
        position: layoutSmall ? 'fixed' : 'absolute',
      }}
      BackdropProps={{
        style: { position: 'inherit' },
      }}
      square={false}
      disableScrollLock
      onClose={onClose}
      actions={[
        [
          <Button
            variant="outlined"
            // innerRef={ref}
            startIcon={<SvgIcon type={'sw-link-dark'} />}
            disabled={billing.pay.loading}
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();

              dispatch(getPayLink(chosenAmount));
            }}
          >
            {tl(`Ссылка на оплату (${chosenAmount} ₽)`)}
          </Button>,
        ],
        [
          {
            label: tl('Пополнить баланс'),

            btnProps: {
              color: 'primary',
              onClick: () => {},
              href: `${setupBaseUrl()}/billing/pay?price=${chosenAmount}`,
              target: '_blank',
            },
          },
        ],
      ]}
    >
      <Box display="flex" flexDirection="column">
        {type !== 'normal' && (
          <Box display="flex" flexDirection="row" mb={2}>
            <Typography align="left" className={classes.text}>
              {type === 'empty' &&
                tl(
                  'Новые зрители не могут зайти на вебинар. Пополните баланс.'
                )}
              {type === 'low' &&
                tl(
                  'Скоро новые зрители не смогут зайти на вебинар. Пополните баланс.'
                )}
              <br />
            </Typography>
          </Box>
        )}
        <FormSettingsRow
          smallLabel
          labelClassName={classes.label}
          label={tl('Выберите сумму:')}
          nohint
        >
          <MUISelect
            fullWidth
            value={chosenAmount}
            // className={classes.select}
            classes={{
              select: classes.select,
            }}
            onChange={(e) => {
              setAmount(e.target.value as number);
            }}
          >
            {plans.list.map((plan) => (
              <MenuItem key={plan.price + '-price'} value={plan.price}>
                {plan.price} ₽ / {plan.smart}{' '}
                <SmartCurrencyIcon className={classes.icon} />
              </MenuItem>
            ))}
          </MUISelect>
          <Typography className={classes.grey}>
            {tl('2 смарта = 1 зритель')}
          </Typography>
        </FormSettingsRow>
      </Box>
    </MUIDialog>
  );
});

import * as React from "react";

export class Checkbox extends React.Component<{
  checked: boolean;
  onChange?: any;
},
  {
    checked: boolean;
  }> {
  constructor(params: any) {
    super(params);

    this.state = {
      checked: params.checked
    };
  }

  change(e: any) {
    this.setState({checked: e.target.checked});

    if (this.props.onChange !== undefined) {
      this.props.onChange(e.target.checked, e);
    }
  }

  render() {
    const id = `checkbox_${Math.random().toString().replace(/0\./, '')}`;
    return (
      <div>
        <input id={id} type="checkbox" checked={this.state.checked} onChange={this.change.bind(this)}/>
        <label htmlFor={id}>{this.props.children}</label>
      </div>
    );
  }
}

import { Route } from 'react-router';
import { WebinarList } from './list';
import { AppTabs } from '../../components/AppTabs';
import { EWebinarType } from '../../types';
import useAppSelector from '../../hooks/useAppSelector';
import { Box } from '@material-ui/core';
import { BalanceBlockNotification } from '../../components/BalanceBlockNotification';

export const WebinarsPage: React.FC = () => {
  const { count, balance } = useAppSelector((state) => ({
    count: state.webinars.count,
    balance: state.user.profile.balance,
  }));

  return (
    <Box>
      <BalanceBlockNotification balance={balance} />
      <AppTabs count={count} balance={balance} hasLive={count}>
        <Route path="/" exact>
          <WebinarList type={EWebinarType.WEBINAR} />
        </Route>
        <Route path="/autowebinars" exact>
          <WebinarList type={EWebinarType.AUTOWEBINAR} />
        </Route>
      </AppTabs>
    
    </Box>
  );
};

import { apiClient, IListResponse } from "./_client"
import { IAdsLayout } from "../types";

export const get = async (id: string): Promise<IAdsLayout> => {
  const response = await apiClient.get<IAdsLayout>('/ads-layout/' + id);
  return response.data as IAdsLayout;
}

export const list = async (offset: number, limit: number): Promise<IListResponse<IAdsLayout>> => {
  const response = await apiClient.get<IListResponse<IAdsLayout>>('/ads-layout', {
    params: {
      offset: offset,
      limit: limit
    }
  });
  return response.data;
}

export const create = async (name: string): Promise<IAdsLayout> => {
  const response = await apiClient.post<IAdsLayout>('/ads-layout', {
    name: name
  });
  return response.data;
}

export const save = async (layout: IAdsLayout): Promise<IAdsLayout> => {
  const response = await apiClient.put<IAdsLayout>(`/ads-layout/${layout.id}`, layout);
  return response.data;
}


export const copy = async (layout: IAdsLayout): Promise<IAdsLayout> => {
  const response = await apiClient.post<IAdsLayout>(`/ads-layout/${layout.id}`);
  return response.data;
}


export const remove = async (layout: IAdsLayout, force = false): Promise<boolean> => {
  await apiClient.delete<IAdsLayout>(`/ads-layout/${layout.id}`, {
    data: {
      force: force
    }
  });
  return true;
}

import * as React from 'react';
import { Box, IconButton, makeStyles } from '@material-ui/core';
import { SvgIcon } from '../../';
import { MUIPreloader } from '../../../MUI';
import { useRef } from 'react';
import { IPayment, TAdsType, TIntegration } from '../../../types';
import usePaymentWidgets from '../../PaymentWidget';

interface BannerProps {
  image: string;
  link: string;
  type: TAdsType;
  userId?: string;
  integration?: TIntegration;
  payment?: IPayment;
  onClick?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
  editable?: boolean;
}

const useStyles = makeStyles((theme) => ({
  wrap: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    '&:hover div': {
      display: 'flex',
    },
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },

  preloaderWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 250,
    //@ts-ignore
    backgroundColor: theme.palette.default.main,
    [theme.breakpoints.down('sm')]: {
      height: 125,
    }
  },

  banner: {
    alignSelf: 'flex-start', // Safari fix
    maxWidth: '100%',
    [theme.breakpoints.between(360,420)]: {
      maxHeight: 154,
    },
    [theme.breakpoints.between(320,360)]: {
      maxHeight: 116,
    }
  },

  hidden: {
    display: 'none',
    height: 0,
    width: 0,
  },

  settings: {
    display: 'none',
    position: 'absolute',
    padding: '12px 20px',
    maxWidth: '140px',
    width: '100%',
    boxSizing: 'border-box',
    justifyContent: 'space-between',
    backgroundColor: 'rgba(77, 78, 78, 0.75)',
    borderRadius: '2px',
    marginTop: '10px',
  },

  settingsButton: {
    width: '40px',
    height: '40px',
    backgroundColor: '#fff',

    '&:hover': {
      backgroundColor: 'rgba(255,255,255, 0.85)',
    },
  },

  settingsIcon: {
    transform: 'scale(1.2)',
  },
}));

export const Banner = (props: BannerProps) => {
  const classes = useStyles();

  const paymentWidgets = usePaymentWidgets();

  const [loaded, setLoaded] = React.useState(false);

  const boxRef = useRef<HTMLDivElement>(null);

  if (!props.image) return null;

  const clickHandler = async (event: React.MouseEvent) => {
    if (props.payment && props.integration && paymentWidgets) {
      event.preventDefault();

      const result = await paymentWidgets.load(props.integration, props.payment);

      if (result || paymentWidgets.loaded[props.integration.type]) {
        const payFunction = paymentWidgets.createPayFunction(props.integration, props.payment, props.userId ?? '');
        payFunction();
      } else {
        alert('Не удалось загрузить виджет');
      }
    } else {
      if(!props.link) event.preventDefault();
      props.onClick && props.onClick();
    }
  }

  return (
    <div className={loaded ? classes.wrap : classes.preloaderWrap} ref={boxRef}>
      {!loaded && <MUIPreloader />}
      <a
        href={props.link ? props.link : ''}
        onClick={clickHandler}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          data-testid="BannerAds"
          crossOrigin="anonymous"
          onLoad={() => setLoaded(true)}
          className={loaded ? classes.banner : classes.hidden}
          src={props.image}
          alt="banner"
          style={{
            maxHeight: (boxRef.current && (boxRef.current.clientWidth / 16) * 9) || 250,
          }}
        />
      </a> 
      {loaded && props.editable && 
         <Box
            className={classes.settings}
            onClick={(event) => event.stopPropagation()}
          >
            <IconButton onClick={props.onEdit} className={classes.settingsButton}>
              <Box className={classes.settingsIcon}>
                <SvgIcon type="fs-list-settings" />
              </Box>
            </IconButton>
            <IconButton onClick={props.onDelete} className={classes.settingsButton}>
              <SvgIcon type="sw-solid-bin" />
            </IconButton>
          </Box>
      }
    </div>
  );
};

import React from 'react';
import {makeStyles} from '@material-ui/core';
import { IPlan } from "../../types/Billing";
import SmartCurrencyIcon from '../Icon/smartCurrency';

const usePayOptionStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    margin: '10px 0',
    fontFamily: 'SF UI Text',
    cursor: 'pointer',
    '&:hover': {
      '& > $discountBox': {
        display: 'none',
      },
      '& > $payBox': {
        display: 'flex',
      },
    }
  },
  inner: {
    borderColor: theme.palette.primary.light,
    background: theme.palette.common.white,
    borderWidth: 2,
    width: '100%',
    borderStyle: 'solid',
    borderRadius: 26,
    // height: 52,
    boxShadow: '0px 3px 4px #80808033',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    padding: '16px 14.5px 16px 20px',
    '& div': {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      '&:first-child': {
        justifyContent: 'flex-start',
      },
      '& svg': {
        marginLeft: 8,
      }
    },
    
  },
  price: {
    fontSize: 18,
    lineHeight: '21px',
    fontWeight: 500,
    color: theme.palette.primary.dark,
    [theme.breakpoints.down('md')] :{
      fontSize: 15,
      lineHeight: '18px',
    }
  },
  bonus: {
    fontSize: 15,
    lineHeight: '18px',
    fontWeight: 500,
    color: theme.palette.success.main,
    [theme.breakpoints.down('md')] :{
      fontSize: 13,
      lineHeight: '15px',
    }
  },
  smartCount: {
    fontSize: 18,
    lineHeight: '21px',
    fontWeight: 600,
    justifyContent: 'flex-start',
    color: theme.palette.primary.dark,
    [theme.breakpoints.down('md')] :{
      fontSize: 15,
      lineHeight: '18px',
    }
  },
  discountBox: {
    position: 'absolute',
    right: 0,
    top: -13,
    fontFamily: 'SF UI Text',
    fontSize: 15,
    lineHeight: '18px',
    fontWeight: 600,

    color: theme.palette.common.white,
    background: theme.palette.primary.main,
    borderRadius: 20,
    padding: '4px 12px',
  },
  payBox: {
    display: 'none',
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderRadius: 26,
    fontSize: 21,
    lineHeight: '28px',
    '& span': {
      font: 'Manrope',
      fontSize: 21,
      lineHeight: '28px',
    }
  }
}))

const numberFormat = Intl.NumberFormat('ru-RU');

export const PayOption: React.FC<{option: IPlan, onClick?: (price: number) => void}> = ({option, onClick}) => {
  const classes = usePayOptionStyles();
  return (
    <div className={classes.root} onClick={() => onClick && onClick(option.price)}>
      <div className={classes.inner}>
        <div className={classes.row}>
          <div className={classes.price}>{numberFormat.format(option.price)} ₽</div>
          {option.bonus > 0 && <div className={classes.bonus}>+ {option.bonus} <SmartCurrencyIcon /></div>}
          <div className={classes.smartCount}>{numberFormat.format(option.smart)} <SmartCurrencyIcon /></div>
        </div>
      </div>
      {option.bonus/option.price !== 0 && <div className={classes.discountBox}>+{Math.round((option.bonus/option.price) * 100)}%</div>}
      <div className={classes.payBox}>
        <span>Оплатить: {numberFormat.format(option.price)} ₽</span>
      </div>
    </div>
  )
}

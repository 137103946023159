import { TIntegration } from '../../types';

export const INTEGRATION_FETCH_ATTEMPT = 'INTEGRATION_FETCH_ATTEMPT';
export const INTEGRATION_FETCH_SUCCESS = 'INTEGRATION_FETCH_SUCCESS';
export const INTEGRATIONS_FETCH_ATTEMPT = 'INTEGRATIONS_FETCH_ATTEMPT';
export const INTEGRATIONS_FETCH_SUCCESS = 'INTEGRATIONS_FETCH_SUCCESS';
export const INTEGRATION_ADD = 'INTEGRATION_ADD';
export const INTEGRATION_UPDATE = 'INTEGRATION_UPDATE';
export const INTEGRATION_REMOVE = 'INTEGRATION_REMOVE';

export interface IIntegrationFetchAttemptAction {
  type: typeof INTEGRATION_FETCH_ATTEMPT;
}

export interface IIntegrationFetchSuccessAction {
  type: typeof INTEGRATION_FETCH_SUCCESS;
  integration: TIntegration;
}

export interface IIntegrationsFetchAttemptAction {
  type: typeof INTEGRATIONS_FETCH_ATTEMPT;
}

export interface IIntegrationsFetchSuccessAction {
  type: typeof INTEGRATIONS_FETCH_SUCCESS;
  list: TIntegration[];
  count: number;
}

export interface IIntegrationAddAction {
  type: typeof INTEGRATION_ADD;
  integration: TIntegration;
}

export interface IIntegrationUpdateAction {
  type: typeof INTEGRATION_UPDATE;
  integration: TIntegration;
}

export interface IIntegrationRemoveAction {
  type: typeof INTEGRATION_REMOVE;
  integration: TIntegration;
}

export type TIntegrationsAction =
  | IIntegrationFetchAttemptAction
  | IIntegrationFetchSuccessAction
  | IIntegrationsFetchAttemptAction
  | IIntegrationsFetchSuccessAction
  | IIntegrationAddAction
  | IIntegrationUpdateAction
  | IIntegrationRemoveAction
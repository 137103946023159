import { Box, styled } from '@material-ui/core';
import { themeVariables } from '../../../MUI';

export const PlusIcon = styled(Box)({
  position: 'relative',
  width: 20,
  height: 20,
  borderRadius: '50%',
  backgroundColor: '#fff',

  '&::after': {
    content: '""',
    position: 'absolute',
    top: '50%',
    left: '50%',
    borderRadius: '1px',
    backgroundColor: themeVariables.palette.primary.dark,
    transform: 'translate(-50%, -50%)',
    width: 10,
    height: 2,
  },

  '&::before': {
    content: '""',
    position: 'absolute',
    top: '50%',
    left: '50%',
    borderRadius: '1px',
    backgroundColor: themeVariables.palette.primary.dark,
    transform: 'translate(-50%, -50%)',
    width: 2,
    height: 10,
  },
});

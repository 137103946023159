import { IUser } from "../../types";
import { CLOSE_CONNECTION, ICloseConnectionAction, IStreamAuthorConnect, IStreamMemberConnect, IWSConnect, IWSSubscribe, STREAM_AUTHOR_CONNECT, STREAM_MEMBER_CONNECT, WS_CONNECT, WS_SUBSCRIBE } from "./types";

export function wsConnect(user: IUser, channel?: string): IWSConnect {
    return {
        type: WS_CONNECT,
        user, channel
    }
}

export function wsSubscribe(channel: string): IWSSubscribe {
    return {
        type: WS_SUBSCRIBE,
        channel
    }
}

export function connectAuthor(webinarLink:string, user:IUser, updateOnlineMembers:boolean):IStreamAuthorConnect {
    return {
        type: STREAM_AUTHOR_CONNECT,
        webinarLink,
        user,
        updateOnlineMembers
    }
}

export function connectMember(webinarLink:string, user:IUser):IStreamMemberConnect {
    return {
        type: STREAM_MEMBER_CONNECT,
        webinarLink,
        user
    }
}

export function closeConnection(channel?: string):ICloseConnectionAction {
    return {
        type: CLOSE_CONNECTION,
        channel: channel
    }
}
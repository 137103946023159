import { IAdsLayout } from '../../types';
import {
  ADS_FETCH_ATTEMPT,
  ADS_FETCH_FAILURE,
  ADS_FETCH_SUCCESS,
  ADS_RESET,
  AD_COPY,
  AD_REMOVE,
  AD_SAVED,
  AD_SAVING,
  AD_UPDATE,
  TAdsActionType,
} from './types';

interface IAdsInitialState {
  list: IAdsLayout[];
  count: number;
  loading: boolean;
  saving: boolean;
  error?: boolean;
}

const initialState: IAdsInitialState = {
  list: [],
  count: 0,
  loading: false,
  saving: false,
};

export default function adsReducer(
  state = initialState,
  action: TAdsActionType
) {
  switch (action.type) {
    case ADS_RESET:
      return {
        ...initialState,
        error: false,
        loading: true,
      };

    case ADS_FETCH_ATTEMPT:
      return {
        ...state,
        loading: true,
      };
    case AD_SAVING:
      return {
        ...state,
        saving: true,
      }
    case AD_SAVED:
      return {
        ...state,
        saving: false,
      }
    case ADS_FETCH_SUCCESS:
      return {
        ...state,
        list: action.clear ? action.ads : state.list.concat(action.ads),
        count: action.count,
        loading: false,
        error: false,
      };

    case ADS_FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case AD_UPDATE: {
      const prevList = state.list.slice();
      const idx = prevList.findIndex((ad) => ad.id === action.ad.id);

      if (idx > -1) {
        prevList[idx] = action.ad;
        return {
          ...state,
          list: prevList,
        };
      }

      return {
        ...state,
      };
    }

    case AD_REMOVE: {
      const prevList = state.list.slice();
      return {
        ...state,
        list: prevList.filter((ad) => ad.id !== action.ad.id),
        count: state.count - 1,
      };
    }

    case AD_COPY: {
      const prevList = state.list.slice();
      prevList.unshift(action.ad);
      return {
        ...state,
        list: prevList,
        count: state.count + 1,
      };
    }

    default:
      return state;
  }
}

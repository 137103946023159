import * as React from "react";
import {useState} from "react";
import {Box, Button, MenuItem, Select, Typography} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {tl} from "../../utils";
import { ExpandMore } from "@material-ui/icons";

import styles  from "./styles.module.scss";

interface WebinarEventChatProps {
  status: string;
  editable: boolean;
  onChange?: ({status}: {status: string}) => void;
}

const useStyles = makeStyles({
  title: {
    lineHeight: "30px",
  },
});

export const WebinarEventChat = (
  props: WebinarEventChatProps,
) => {
  const classes = useStyles();

  const [status, setStatus] = useState(props.status);
  const [savedStatus, setSavedStatus] = useState(props.status);
  const [isChanging, setIsChanging] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  const chatIsOnMessage = <>{tl("Чат ")}<b>{tl("ВКЛ")}</b>{tl("ючен")}</>;
  const chatIsOffMessage = <>{tl("Чат ")}<b>{tl("ВЫКЛ")}</b>{tl("ючен")}</>;
  const onKeydown = (event: any) => {
    event.key === "Escape" && handleCancel();
    event.key === "Enter" && handleSubmit();
  };

  const openSettings = () => {
    if (props.editable && !isChanging) {
      setIsChanging(true);
      document.addEventListener("keydown", onKeydown);
    }
  };

  const closeSettings = () => {
    setIsChanging(false);
    document.removeEventListener("keydown", onKeydown);
  };

  const handleCancel = () => {
    closeSettings();
    setStatus(savedStatus);
    setIsChanged(false);
  };

  const handleSubmit = (event?: any) => {
    event && event.preventDefault();
    closeSettings();
    props.onChange && props.onChange({status: status});
    setSavedStatus(status);
  };

  return (
    <Box
      px="10px"
      className={isChanging ? "" : styles.changeBox}
      onClick={openSettings}
    >
      <Typography className={classes.title}>
        <b>{tl("Управление чатом. ")}</b>
        {!isChanging && (status === 'active' ? chatIsOnMessage : chatIsOffMessage)}
      </Typography>
      {props.editable &&
        <Box display={isChanging ? "none" : ""} className={styles.changeBoxIcon}>
            <ExpandMore/>
        </Box>
      }
      {isChanging && (
        <form onSubmit={handleSubmit}>
          <Box className={styles.changeBoxRow}>
            <Select
              variant="outlined"
              fullWidth
              defaultValue={status}
              onChange={(event: any) => {
                setStatus(event.target.value);
                setIsChanged(true);
              }}
            >
              <MenuItem value="active" key="on">
                <Typography>{tl("Чат ВКЛючен")}</Typography>
              </MenuItem>
              <MenuItem value="not_active" key="off">
                <Typography>{tl("Чат ВЫКЛючен")}</Typography>
              </MenuItem>
            </Select>
            <Box className={styles.changeBoxButtons} pl="10px">
              <Box pr="8px">
                <Button
                  variant="contained"
                  onClick={handleCancel}
                >
                  Отмена
                </Button>
              </Box>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={!isChanged}
              >
                Сохранить
              </Button>
            </Box>
          </Box>
        </form>
      )}
    </Box>
  );
}
import {tl} from "../../utils";
import {FormSettingsRow, MUIBlock} from "../../MUI";
import React, { useEffect, useState } from "react";
import {Button, MenuItem, Select, TextField, Typography} from "@material-ui/core";
import {File} from "../../components";
import { api } from "../../api";
import { useDispatch } from "react-redux";
import { addNotification } from "../../store/notifications/actions";
import { apiClient } from "../../api/_client";


export const TariffPage: React.FC = () => {
  const dispatch = useDispatch();
  const [templates, setTemplates] = useState<Array<{key: string, label: string}>>([]);
  const [isTemplatesLoading, setTemplatesLoading] = useState(false);

  useEffect(() => {
    async function getTemplates() {
      setTemplatesLoading(true);
      try {
        const res = await apiClient.get('/mail/templates');
        setTemplates(res.data.list);
        setTemplatesLoading(false);
      } catch (error) {
        setTemplatesLoading(false);
      }
    }
    getTemplates();
  }, [])

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    try {
      await api.user.create(formData);
    } catch (error: any) {
      dispatch(addNotification('error', error.response?.data?.message || 'Произошла ошибка'));
    }
  }

  return (
      <form onSubmit={handleSubmit}>
      <MUIBlock style={{overflow: 'hidden'}} head={<Typography>Активация тарифа</Typography>}>
          <FormSettingsRow
              smallLabel
              label={tl('Тариф пользователя:')}
          >
            <TextField
                variant="outlined"
                fullWidth
                name="tariff"
                placeholder={tl('Тариф')}
            />
          </FormSettingsRow>
          <FormSettingsRow
              smallLabel
              label={tl('Пользователи:')}
          >
            <TextField
                variant="outlined"
                fullWidth
                multiline={true}
                name="users"
                rows={5}
                placeholder={tl('Email-ы пользователей через запятую')}
            />
          </FormSettingsRow>
          <FormSettingsRow
              smallLabel
              label={tl('Файл:')}
          >

            <File name="file" label={tl('Выбрать')}/>
          </FormSettingsRow>
          <FormSettingsRow
            smallLabel
            label="Тема письма"
          >
            <TextField
              variant="outlined"
              fullWidth
              name="subject"
              rows={5}
              placeholder={tl('Тема')}
            />
          </FormSettingsRow>
          <FormSettingsRow
            smallLabel
            label="От кого"
          >
            <TextField
              variant="outlined"
              fullWidth
              name="from[email]"
              rows={5}
              placeholder={tl('Email')}
            />
          </FormSettingsRow>
          <FormSettingsRow
            smallLabel
            label="Имя отправителя"
          >
            <TextField
              variant="outlined"
              fullWidth
              name="from[name]"
              rows={5}
              placeholder={tl('Доктор Эммет Браун')}
            />
          </FormSettingsRow>
          <FormSettingsRow
            smallLabel
            label="Шаблон"
          >
            <Select name="template" style={{width: 200}} variant="outlined">
              {isTemplatesLoading && <MenuItem>Загружается</MenuItem>}
              {
                templates.map((template, idx) => (
                  <MenuItem key={idx + template.label} value={template.key}>{template.label}</MenuItem>
                ))
              }
            </Select>
          </FormSettingsRow>
          <Button variant="contained" color="primary" type='submit'>Сохранить</Button>
      </MUIBlock>
      </form>
  );
}
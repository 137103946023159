import { AppThunk } from "..";
import { api } from "../../api";
import { addNotification } from "../notifications/actions";
import { IWebinarsFetchAttemptAction, IWebinarsFetchSuccessAction, IWebinarsItemRemoveAction, IWebinarsListResetAction, IWebinarsListUpdateAction, WEBINARS_FETCH_ATTEMPT, WEBINARS_FETCH_SUCCESS, WEBINARS_ITEM_REMOVE, WEBINARS_LIST_RESET, WEBINARS_LIST_UPDATE } from "./types";
import { EWebinarType, IWebinar, IWebinarFilter } from "../../types";
import { IMeta } from "../../api/_client";

export function webinarsFetchAttempt(webinarType: EWebinarType, more?: boolean): IWebinarsFetchAttemptAction {
    return {
        type: WEBINARS_FETCH_ATTEMPT,
        webinarType,
        more
    }
}

export function webinarsFetchSuccess(webinarType: EWebinarType, list: Array<IWebinar>, count: number, offset: number, meta: IMeta ): IWebinarsFetchSuccessAction {
    return {
        type: WEBINARS_FETCH_SUCCESS,
        webinarType,
        list,
        count,
        offset,
        meta
    }
}

export function webinarsListUpdate(webinar: IWebinar): IWebinarsListUpdateAction {
    return {
        type: WEBINARS_LIST_UPDATE,
        webinar
    }
}

export function webinarsListReset(webinarType: EWebinarType): IWebinarsListResetAction {
    return {
        type: WEBINARS_LIST_RESET,
        webinarType
    }
}

export function webinarsItemRemove(webinarId: string, webinarType: EWebinarType): IWebinarsItemRemoveAction {
    return {
        type: WEBINARS_ITEM_REMOVE,
        webinarId,
        webinarType
    }
}

export function fetchWebinars(webinarType: EWebinarType, filter: IWebinarFilter, offset: number = 0, limit: number = 7): AppThunk {
    return async dispatch => {
        dispatch(webinarsFetchAttempt(webinarType, offset > 0));
        try {
            const response = await api.webinar.list(webinarType, filter, offset, limit)
            dispatch(webinarsFetchSuccess(webinarType, response.list, response.paginator.countItems, offset, response.meta))
        } catch (error) {
            dispatch(addNotification('error', 'Не удалось загрузить список вебинаров'));
        }
    }
}
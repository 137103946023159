import { makeStyles, styled, Tooltip, Typography } from '@material-ui/core';
import { format, isSameHour, isSameMinute } from 'date-fns';
import React from 'react';
import { EWebinarDateType, EWebinarStatus, EWebinarType, IWebinar } from '../../../types';

interface DateInfoBuilderProps {
  webinar: IWebinar;
}

const intlFormatter = (date: string | Date) => Intl.DateTimeFormat('ru-RU', {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric'
}).format(new Date(date))

const useStyles = makeStyles(()=> ({
  dateTooltip: {
    maxWidth: '132px',
    width: '132px',
  },
  dateTooltipHelper: {
    textDecoration: 'underline',
  },
}))

export const weekDays = ["ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ", "ВСК"];

const checkIfAllNull = (days: Array<{
  enabled: boolean;
  time: Date | null;
}>) => {
  return days.every(day => day.time === null);
}

export const allDaysAreEqual = (days: Array<{
  enabled: boolean;
  time: Date | null;
}>) => {

  return days.filter(day => day.enabled).length === 7 &&
          days.every(day =>
            isSameHour(new Date(day.time!),new Date(days[0].time!)) &&
            isSameMinute(new Date(day.time!), new Date(days[0].time!)))
}

const buildDateFormat = (d: Date | null) => {
  if(d == null) {
    return null;
  }
  let day = new Date(d);
  return `${intlFormatter(day)} в ${format(new Date(day), 'HH:mm')} ${" "} `;
}

const buildListDateFormat = (day: Date) => {
  return `${intlFormatter(day)} в ${format(new Date(day), 'HH:mm')}${" "} `;
}
const buildListDateTooltipTitle = (dates: Array<Date | null>) => {
  return dates.map((day, idx) => {
    if(day === null) return null;
    else {
      return [`${buildListDateFormat(new Date(day))}${dates.length === idx +1 ? '' : ', '} `]
    }
  })
}


const buildTimeFormat = (d: {
  enabled: boolean;
  time: Date | null;
}, idx: number) => {
  if(!d.enabled || !d.time) {
    return null;
  }
  let day = new Date(d.time);
  return <span key={idx}>{weekDays[idx]} в {format(new Date(day), 'HH:mm')}{" "}</span>
}


export const HelperText = styled(Typography)(({theme})=>({
  fontSize: 13,
  lineHeight: 1.25,
  fontFamily: 'SF UI Text',
  fontWeight: 400,
  marginTop: 4,
  color: theme.palette.grey[600]
}))

export const DateInfoBuilder: React.FC<DateInfoBuilderProps> = ({webinar}) => {
  const classes = useStyles();
  if(webinar.type === EWebinarType.AUTOWEBINAR && webinar.status === EWebinarStatus.PAUSE) {
    return <HelperText>Автовебинар больше не проводится</HelperText>
  }
  if(webinar.status === EWebinarStatus.DRAFT) {
    return (
      <HelperText>
        {webinar.type !== EWebinarType.AUTOWEBINAR
          ? 'Не указана дата и время трансляции'
          : webinar.stats?.id
            ? 'Автовебинар больше не проводится'
            : 'Автовебинар не проводился'
        }
      </HelperText>
    )
  }
  switch (webinar.dateSettings.type) {
    case EWebinarDateType.SINGLE:{

      const dates = webinar.dateSettings.dates
      if (!dates || dates.length === 0) {
        return <HelperText>Не указана дата и время трансляции</HelperText>
      } else {
        return <HelperText>{buildDateFormat(dates[0])}</HelperText>
      }
    }
    case EWebinarDateType.LIST: {
      const dates = webinar.dateSettings.dates
      if(!dates || dates.length === 0) {
        return <HelperText>Не указана дата и время трансляции</HelperText>
      } else if(dates.length > 1) {
        return (
          <HelperText>
              Старт {buildListDateTooltipTitle(dates.slice(0,2))}
              <Tooltip placement="top" onOpen={e => e.stopPropagation()} classes={{tooltip: classes.dateTooltip}} arrow title={buildListDateTooltipTitle(dates)}>
                <span className={classes.dateTooltipHelper}> и др.</span>
              </Tooltip>
          </HelperText>
        );
      } else {
        return <HelperText>Старт {buildDateFormat(dates[0])}</HelperText>
      }
    }
    case EWebinarDateType.REPEAT:
      const days = webinar.dateSettings.days
        //@ts-ignore
      if(checkIfAllNull(days)) {
        return <HelperText>Не указана дата и время трансляции</HelperText>
        //@ts-ignore
      } else if (allDaysAreEqual(days)) {
        return (
          <HelperText>
            Старт ежедневно в {' '}
            {format(new Date(days[0].time!), 'HH:mm')}
          </HelperText>)
      } else {
        return (
          <HelperText>
            {
              //@ts-ignore
              days.map(buildTimeFormat)
            }
          </HelperText>
        )
      }
    default:
      return <></>
  }
}

export default DateInfoBuilder;
import { SvgIcon, SvgIconProps } from "@material-ui/core"


export const WebinarSortIcon: React.FC<SvgIconProps> = ({...props}) => {
  return (
    <SvgIcon {...props}  viewBox="0 0 20 20">
      <defs>
        <style>{".a-c{fill:#3f53d9;stroke:#3f53d9;}.b-c{fill:#8b97e8;stroke:#8b97e8;}.c-c{fill:none;}.d-c{stroke:none;}"}</style></defs>
        <g transform="translate(-623 -42)"><g transform="translate(68.279 -130.996)">
        <g transform="translate(555.005 175.566)"><g className="a-c" transform="translate(3.995 0.43)">
          <rect className="d-c" width="2" height="13" rx="1"/>
          <rect className="c-c" x="0.5" y="0.5" width="1" height="12" rx="0.5"/></g></g>
          <g transform="translate(552.659 188.293) rotate(-45)"><g className="a-c" transform="translate(3.995 0.335)">
            <rect className="d-c" width="2" height="6.003" rx="1"/>
            <rect className="c-c" x="0.5" y="0.5" width="1" height="5.003" rx="0.5"/></g></g><g transform="translate(560.222 181.233) rotate(45)">
              <g className="a-c" transform="translate(3.995 0.335)"><rect className="d-c" width="2" height="5.997" rx="1"/>
              <rect className="c-c" x="0.5" y="0.5" width="1" height="4.997" rx="0.5"/></g></g></g><g transform="translate(69.775 -130.396)"><g transform="translate(572.445 189.38) rotate(180)">
                <g className="b-c" transform="translate(3.995 0.43)"><rect className="d-c" width="2" height="13" rx="1"/>
                <rect className="c-c" x="0.5" y="0.5" width="1" height="12" rx="0.5"/></g></g><g transform="translate(574.791 176.653) rotate(135)">
                  <g className="b-c" transform="translate(3.995 0.335)"><rect className="d-c" width="2" height="6.003" rx="1"/>
                  <rect className="c-c" x="0.5" y="0.5" width="1" height="5.003" rx="0.5"/></g></g><g transform="translate(567.227 183.713) rotate(-135)"><g className="b-c" transform="translate(3.995 0.335)">
                    <rect className="d-c" width="2" height="5.997" rx="1"/>
                    <rect className="c-c" x="0.5" y="0.5" width="1" height="4.997" rx="0.5"/></g></g></g>
                    <rect className="c-c" width="20" height="20" transform="translate(623 42)"/></g>
    </SvgIcon>
  );
}

export default WebinarSortIcon;
import { Box, BoxProps, styled } from '@material-ui/core';
import React from 'react';


const Cell: React.ComponentType<BoxProps> = styled(Box)(({ theme }) => ({
  display: 'flex',
  borderLeftColor: 'transparent',
  borderLeft: 1,
  borderRightColor: 'transparent',
  borderRight: 1,
  borderStyle: 'solid',
  '&:hover': {
    backgroundColor: '#F9F9FE',
    borderLeftColor: '#E3E4E8',
    borderLeft: 1,
    borderRightColor: '#E3E4E8',
    borderRight: 1,
    borderStyle: 'solid',
  },
}));

export default Cell;

import { AppThunk } from "..";
import { api } from "../../api";
import { ITask, TQueueName } from "../../types/Queue";
import { ADD_TASK, IAddTask, IRemoveTaskAction, ISetTasksAction, MAKE_READY_TASK, REMOVE_TASK, SET_TASKS } from "./types";

export function setTasks(queueName: TQueueName, tasks: Array<ITask>): ISetTasksAction {
  return {
    type: SET_TASKS,
    queueName: queueName,
    tasks: tasks
  }
}

export function removeTask (taskId: string, queueName: TQueueName): IRemoveTaskAction {
  return {
    type: REMOVE_TASK,
    taskId,
    queueName
  }
}

export function addTask (task: ITask, queueName: TQueueName): IAddTask {
  return {
    type: ADD_TASK,
    task,
    queueName
  }
}

export function makeDone (taskId: string, queueName: TQueueName):AppThunk {
  return async (dispatch) => {
    try {
      await api.tasks.close(taskId);
      dispatch(removeTask(taskId, queueName));
    } catch (error) {
      
    }
  }
}

export function makeReady(taskId: string, queueName: TQueueName) {
  return {
    type: MAKE_READY_TASK,
    taskId, queueName
  }
}


export function fetchTicketsTasks(webinarId: string): AppThunk {
  return async (dispatch) => {
    try {
      const res = await api.tasks.list("tickets", webinarId)
      dispatch(setTasks("tickets", res.data))
    } catch (error) {
      console.error(error)
    }
  }
}
import * as React from "react";
import classnames from "classnames";
import styles  from "./styles.module.scss";

interface IInputProps {
  className?: string;
  defaultValue?: string;
  placeholder?: string;
  fullWidth?: boolean;
  noClassnames?: boolean;
  name?: string;
  onChange?: any;
  onKeyPress?: any;
  onBlur?: any;
  style?: any;
  id?: any;
  value?: any;
  search?: string;
  forwardingRef?: React.RefObject<HTMLInputElement>;
}

export class Input extends React.Component<IInputProps, {}> {
  render() {
    const {
      fullWidth,
      forwardingRef,
      noClassnames,
      ...inputProps
    } = this.props;
    let classNames = noClassnames
      ? undefined
      : classnames({
        [styles.Input]: true,
        [styles.search]: !!this.props.search || this.props.search === "",
        [styles.FullWidth]: fullWidth
      });
    if ("className" in this.props) {
      classNames += ` ${this.props.className}`;
    }

    return (
      <input
        type="text"
        {...inputProps}
        className={classNames}
        ref={forwardingRef}
      />
    );
  }
}

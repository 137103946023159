import { Divider } from '@material-ui/core';
import { differenceInMinutes, isSameDay, parseJSON } from 'date-fns';
import React, { useCallback, useMemo } from 'react';
import { EWebinarStatus, EWebinarType, IWebinar } from '../../../types';
import { PointsMenuItem } from '../../PointsMenu/menuItem';

export interface ContextMenuBuilderProps {
  webinar: IWebinar;
  onDelete: () => void;
  onClose?: () => void;
  onStop?: () => void;
  onCopy?: () => void;
}

export const CardContextMenuBuilder: React.FC<ContextMenuBuilderProps> = ({
  webinar, onDelete, onClose, onStop, onCopy
}) => {
  const handleClose = useCallback(() => {
    onClose && onClose();
  }, [onClose])

  const copy = useCallback(() => {
    handleClose();
    onCopy && onCopy();
  }, [handleClose, onCopy]);

  const finish = useCallback(() => {
    handleClose();

    onStop && onStop();
  }, [handleClose, onStop])


  const startDate = useMemo(() => webinar.startTime ? parseJSON(webinar.startTime) : null ,[webinar.startTime])
  const isSplashScreen  =  useMemo(() => webinar.startPage.openTime && startDate && differenceInMinutes(startDate, new Date())  < webinar.startPage.openTime, [startDate, webinar.startPage.openTime] )

  const todayOrSplashScreen = useMemo(() => 
    isSplashScreen ||
    (webinar.startTime && 
      isSameDay(new Date(), new Date((webinar.startTime)))), 
  [isSplashScreen, webinar.startTime])
 
  const isViewersStatsAvailable = useMemo(() => webinar.status === EWebinarStatus.DRAFT? webinar.stats.id? true: false: true, [webinar.stats.id, webinar.status]) ;

  if (webinar.type === EWebinarType.WEBINAR) {
    return (
      <>
        {webinar.status === EWebinarStatus.LIVE && 
          <PointsMenuItem title="Завершить эфир" action={finish} />}
        {webinar.status === EWebinarStatus.FINISH && 
          <PointsMenuItem title="Запись вебинара" link={`webinar/${webinar.id}/history`} />}
        {webinar.status === EWebinarStatus.FINISH && 
          <PointsMenuItem title="Статистика зрителей" link={`/statistics/webinars/${webinar.id}/${webinar.stats.id}`} />}
        {webinar.status === EWebinarStatus.DRAFT && 
          <PointsMenuItem title="Войти на вебинар" link={`webinar/${webinar.id}/player`} />}
        {[EWebinarStatus.DRAFT, EWebinarStatus.FINISH,EWebinarStatus.LIVE].includes(webinar.status) && 
          <Divider />}
        <PointsMenuItem title="Копировать" action={copy} />
        <PointsMenuItem title="Удалить" action={onDelete} />
      </>
    );
  }
  if (webinar.type === EWebinarType.AUTOWEBINAR) {
   
    return (
      <>
        {/* {webinar.status === EWebinarStatus.PAUSE && <PointsMenuItem title="Возобновить автовебинар" action={play} />} */}
        {(webinar.status === EWebinarStatus.PAUSE || 
          webinar.status === EWebinarStatus.DRAFT || 
          ((webinar.status === EWebinarStatus.ACTIVE) && !todayOrSplashScreen)) && 
            <PointsMenuItem title="Войти на автовебинар" link={`webinar/${webinar.id}/player`} />
        }
        {webinar.status === EWebinarStatus.LIVE && <PointsMenuItem title="Завершить эфир" action={finish} />}
        {(webinar.status === EWebinarStatus.PAUSE || webinar.status === EWebinarStatus.LIVE || (webinar.status === EWebinarStatus.DRAFT || 
            ((webinar.status === EWebinarStatus.ACTIVE) && !todayOrSplashScreen))) && <Divider />}

        {(webinar.status === EWebinarStatus.LIVE || (todayOrSplashScreen && webinar.status !== EWebinarStatus.PAUSE )) && <PointsMenuItem title="Сценарий автовебинара" link={`webinar/${webinar.id}/scenario`} />}
        {isViewersStatsAvailable && <PointsMenuItem title="Статистика зрителей" link={`/statistics/autowebinars/allmembers/${webinar.id}`} />}
        {isViewersStatsAvailable && <PointsMenuItem title="Статистика повторов" link={`/statistics/autowebinars/${webinar.id}`} />}
        {((webinar.status === EWebinarStatus.LIVE || todayOrSplashScreen) || isViewersStatsAvailable) && <Divider />}
        <PointsMenuItem title="Копировать" action={copy} />
        <PointsMenuItem title="Удалить" action={onDelete} />
      </>)
  }

  return (
   <>
   </>
  )
}
import { addDays, getDay, isBefore, isEqual, parseISO, subMinutes } from "date-fns";
import { utcToZonedTime, format as formatTZ } from "date-fns-tz";
import { EWebinarDateType, IListDateSettings, IRepeatDateSettings, ISingleDateSettings } from "../types";

export const secondsToHumanReadable = (seconds: number) => {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor(seconds / 60 - (h * 60));
  const s = Math.floor(seconds - (h * 3600) - (m * 60));
  let hs = h.toString().padStart(2, '0');
  let ms = m.toString().padStart(2, '0');
  let ss = s.toString().padStart(2, '0');
  return `${h > 0? `${hs}:`: ''}${ms}:${ss}`;
}

// const mscFormatter = new Intl.DateTimeFormat('ru-RU', {
//   dateStyle: 'medium',
//   timeStyle: 'medium',
//   timeZone: 'Europe/Moscow'
// })

export function toMoscowTime(utcDate: string | number | Date, format: string) {
  try {
    return formatTZ(utcToZonedTime(new Date(utcDate), 'Europe/Moscow'), format, {timeZone: 'Europe/Moscow'});
  } catch (error) {
    return '';
  }
}

export function isUserTimezoneMSC(): boolean {
  return new Intl.DateTimeFormat().resolvedOptions().timeZone === 'Europe/Moscow';
}

type TDateSettings = IListDateSettings | IRepeatDateSettings | ISingleDateSettings;

export function validateAutowebStartTime(dateSettings: TDateSettings, timeOffsetInMinutes: number) {
  const currentDate = new Date();
  const currentDay = getDay(currentDate) - 1;
  let result: boolean = true;
  switch (dateSettings.type) {
    case EWebinarDateType.LIST: {
      const activeDates = dateSettings.dates.filter(date => !!date).map(date => subMinutes(new Date(date!),timeOffsetInMinutes));
      for (let i = 0; i < activeDates.length; i++ ) {
        if (isBefore(activeDates[i], currentDate) || isEqual(activeDates[i], currentDate)) {
          result = false;
          break;
        }
      }
      break;
    }
    case EWebinarDateType.REPEAT:{
      const activeDays = dateSettings.days;
      for (let i = 0; i < activeDays.length; i++ ) {
        if (activeDays[i].time && activeDays[i].enabled) {
          let convertedDay = subMinutes(addDays(
            typeof activeDays[i].time! === 'string' 
              //@ts-ignore
              ? parseISO(activeDays[i].time!) 
              : activeDays[i].time!, i - currentDay), timeOffsetInMinutes);
          if (i >= currentDay && (isBefore(convertedDay, currentDate) || isEqual(convertedDay, currentDate))) {
            result = false;
            break;
          }
        }
      }
      break;
    }
    case EWebinarDateType.SINGLE:
      result = new Date(dateSettings.dates[0]).getTime() < new Date().getTime();
      break;
    default:
      break;
  }
  return result;
}
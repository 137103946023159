import { Menu, MenuItem } from "@material-ui/core";
import React, { useState } from "react";
import { tl } from "../../../utils";
import { PlusIcon } from "../../Icon/PlusIcon";
import AddWebinarButton from "../AddWebinarButton";


interface CreateAutoWebButtonProps {
  onClick: (type: 'simple' | 'scenario') => void;
}

export const CreateAutoWebButton: React.FC<CreateAutoWebButtonProps> = (props) => {

  const [createAnchor, setCreateAnchor] = useState<HTMLElement | null>(null);
  
  return (
    <React.Fragment>
      <AddWebinarButton 
        startIcon={<PlusIcon />} 
        onClick={(e) => setCreateAnchor(e.currentTarget)} 
        variant="contained" 
        color="primary" 
        data-testid="createAutoweb"
        >
          {tl('Автовебинар')}
      </AddWebinarButton>
      <Menu 
        anchorEl={createAnchor} 
        open={!!createAnchor}
        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
        onClose={() => setCreateAnchor(null)}
        getContentAnchorEl={null}
        onSelect={(e) => console.log(e)}
      >
        <MenuItem
          onClick={(e) => {
            setCreateAnchor(null);
            props.onClick('scenario');
          }}
        >
          Загрузить из Бизон
        </MenuItem>
        <MenuItem
          data-testid="createScenario0" 
          onClick={(e) => {
            setCreateAnchor(null);
            props.onClick('simple');
          }}
        >
          Создать сценарий с нуля
        </MenuItem>
      </Menu>
    </React.Fragment>
  )
}

export default CreateAutoWebButton;
import { IModerator } from '../../types';
import {
  MODERATORS_FETCH_ATTEMPT,
  MODERATORS_FETCH_SUCCESS,
  MODERATOR_REMOVE,
  TModeratorsAction,
} from './types';

export interface IModeratorsInitialState {
  list: IModerator[];
  count: number;
  loading: boolean;
  error?: string;
}

const initialState: IModeratorsInitialState = {
  list: [],
  count: 0,
  loading: false,
};

export default function moderatorsReducer(
  state = initialState,
  action: TModeratorsAction
) {
  switch (action.type) {
    case MODERATORS_FETCH_ATTEMPT:
      return {
        ...state,
        loading: true,
        list: []
      };

    case MODERATORS_FETCH_SUCCESS:
      return {
        ...state,
        list: state.list.concat(...action.list),
        count: action.count,
        loading: false,
      };

    case MODERATOR_REMOVE:
      const prevList = state.list.slice();
      return {
        ...state,
        list: prevList.filter(
          (moderator) => moderator.id !== action.moderator.id
        ),
        count: state.count - 1,
      };

    default:
      return state;
  }
}

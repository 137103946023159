import * as React from "react";
import { Box, Button } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { SvgIcon } from "../../../components";
import styles  from "./styles.module.scss";

interface IProps {
  className?: "coursesGroup" | "siteBG";
  onDelete?: (evt: any) => void;
  icon?: string;
  onChange: (files: FileList) => void;
  accept?: string;
}

export const FileUpload = ({
  className,
  onDelete,
  icon,
  onChange,
  accept,
}: IProps) => {


  const [inputId, setInputId] = React.useState<string>("fileUpload");
  const theme = useTheme();

  React.useEffect(() => {
    setInputId(`fileUpload-${Math.floor(Math.random() * 1e9)}`);
  }, []);

  return (
    <Box className={styles.wrap}>
      <input
        type="file"
        className={styles.input}
        id={inputId}
        accept={accept}
        onChange={(evt) => {
          if (evt.target.files && evt.target.files.length > 0) {
            onChange(evt.target.files);
          }
          evt.target.value = "";
        }}
      />
      <Button
        component="label"
        htmlFor={inputId}
        variant="contained"
        className={`MuiButton-cover ${styles.button} ${className && styles[className]}`}
      >
        {icon ? (
          <Box
            bgcolor={theme.palette.background.paper}
            className={styles.cover}
          >
            <img src={icon} className={styles.image} alt="file-preview" />
            <Box bgcolor={theme.palette.grey.A700} className={styles.refresh}>
              <SvgIcon type="courses-renew" />
            </Box>
            {onDelete ? (
              <Box className={styles.deleteButton} onClick={onDelete}>
                <SvgIcon type="courses-delete white" />
              </Box>
            ) : null}
          </Box>
        ) : (
          <Box bgcolor={theme.palette.background.paper} className={styles.plus}>
            <SvgIcon type="fs-attach" />
          </Box>
        )}
      </Button>
    </Box>
  );
};

import Axios from "axios";
import { object } from "yup";

export function setupBaseUrl () {
  if (process.env.REACT_APP_TEMP_ENV) {
    const latestBranch = window.localStorage.getItem('latest_branch');
    if (latestBranch) return JSON.parse(latestBranch);
  }
  return '/api'
}

export const apiClient = Axios.create({
  baseURL: setupBaseUrl(),
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest'
  },
  withCredentials: true
});

export interface IMeta {
  webinarCount?: number
  autowebinarCount?: number
}
export interface IListResponse<T, R = void | {}> {
  paginator: {
    countItems: number
  },
  list:Array<T>,
  meta: R extends object ? R : IMeta
}

import React, {useState} from "react";
import {FileUpload, MUIPreloader} from "../../MUI";
import {Box, Button, MenuItem, Select, TextField, Tooltip, Typography, makeStyles} from "@material-ui/core";
import {tl} from "../../utils";
import {IWebinar,IPresentation} from "../../types";
import { PointsMenu } from "..";
import { ExpandMore } from "@material-ui/icons";

import styles  from "./styles.module.scss";
import { useDispatch } from "react-redux";
import { fetchWebinarAddPresentation } from "../../store/webinar/actions";
import { setupBaseUrl } from "../../api/_client";
import useAppSelector from "../../hooks/useAppSelector";
import usePresentationProcessStatus from "../../hooks/usePresentationProcessStatus";

interface WebinarEventPresentationProps {
  webinar: IWebinar;
  presentation?: IPresentation;
  presentationPage?: number;
  editable: boolean;
  onChange?: (params: { presentation: IPresentation, presentationPage: number }) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    lineHeight: 1.27,
    paddingTop: "6px",
    paddingRight: "30px",
  },

  grey: {
    color: theme.palette.grey[600],
  },
  presPreview: {
    width: 206,
    height: 116,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #D3D9DE',
    borderRadius: 2,
    opacity: 1,
    marginTop: 8,
    overflow: 'hidden',
  },
}));

export const WebinarEventPresentation = (
  props: WebinarEventPresentationProps,
) => {
  const classes = useStyles();
  const presentations = useAppSelector(state => state.webinar.current?.presentations);
  const dispatch = useDispatch();

  const [presentation, setPresentation] = useState(props.presentation);
  const [presentationPage, setPresentationPage] = useState(props.presentationPage || 1);
  const [savedState, setSavedState] = useState({
    presentation: props.presentation,
    presentationPage: props.presentationPage,
  });
  const [isChanging, setIsChanging] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  const isPresReady = usePresentationProcessStatus(presentation, {
    onReady: (presentation) => {
      setPresentation(presentation)
      setPresentationPage(1);
    },
  })

  const onKeydown = (event: any) => {
    event.key === "Escape" && handleCancel();
    event.key === "Enter" && handleSubmit();
  };

  const openSettings = () => {
    if (props.editable && !isChanging) {
      setIsChanging(true);
      document.addEventListener("keydown", onKeydown);
    }
  };

  const closeSettings = () => {
    setIsChanging(false);
    document.removeEventListener("keydown", onKeydown);
  };

  const handleCancel = () => {
    closeSettings();
    setPresentation(savedState.presentation);
    setPresentationPage(savedState.presentationPage || 1);
    setIsChanged(false);
  };

  const handleSubmit = (event?: any) => {
    event && event.preventDefault();
    closeSettings();
    const page = presentationPage || 1;
    presentation && props.onChange && props.onChange({ presentation, presentationPage: page });
    setSavedState({ presentation, presentationPage: page });
  };

  const handleAddPresentation = () => {
    const fileUpload = document.getElementById("file-upload");
    if(fileUpload) {
      const input = fileUpload.getElementsByTagName("input")[0];
      input.click();
    }
  }

  const menuItems = [{
    id: 0,
    title: tl("Загрузить новую презентацию"),
    action: handleAddPresentation,
  }];

  return (
    <Box
      px="10px"
      className={isChanging ? "" : styles.changeBox}
      onClick={openSettings}
    >
      <Typography
        classes={{root: classes.root}}
        className={styles.presentationTitle}
      >
        <b>{tl('Презентации. ')}</b> {presentation ? (<>{tl('Слайд ')}<b>№ {presentationPage || 1}. </b>
        {presentation.name}</>)
          : tl('Выбрать')
        }
      </Typography>
      {
        presentation &&
          <Box className={classes.presPreview}>
            {
            !isPresReady ?
              <Tooltip title="Конвертируется...">
                <div>
                  <MUIPreloader />
                </div>
              </Tooltip>:
              <img 
                src={`${setupBaseUrl()}/presentation/${presentation.id}/${presentationPage || 1}`} 
                height="100%"
                width="auto"
                alt="pres-preview"
              />
            }
          </Box>
      }
      {props.editable &&
        <Box display={isChanging ? "none" : ""} className={styles.changeBoxIcon}>
            <ExpandMore/>
        </Box>
      }
      {isChanging && (
        <form onSubmit={handleSubmit}>
          <Box className={styles.changeBoxSettings}>
            <Box className={styles.changeBoxRow}>
              <Box width="100%" maxWidth="237px">
                <Select
                  variant="outlined"
                  fullWidth
                  value={presentation ? presentation.id : ""}
                  onChange={(event: any) => {
                    if (!presentations) return;
                    const selectedPresentation = presentations.find(
                      item => item.id === event.target.value
                    );
                    if (selectedPresentation) {
                      setPresentation(selectedPresentation);
                      setIsChanged(true);
                    }
                  }}
                >
                  {presentations && presentations.map((presentation) => (
                    <MenuItem
                      value={presentation.id}
                      key={presentation.id}
                    >
                      {presentation.name}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Box px="10px">
                <Typography classes={{root: classes.grey}}>{tl("Презентация")}</Typography>
              </Box>
              <PointsMenu menuItems={menuItems} />
            </Box>
            <Box className={styles.changeBoxRow}>
              {
                presentation &&
                  <Box maxWidth="64px" width="100%" pr="10px">
                    <TextField
                      variant="outlined"
                      fullWidth
                      disabled={!presentation.isReady}
                      value={presentationPage}
                      onChange={(event: any) => {
                        let newSlide = event.target.value;
                        if (newSlide && !Number(newSlide)) return;
                        setPresentationPage(Number(newSlide));
                        setIsChanged(true);
                        if (presentation && newSlide > presentation.pages) {
                          setPresentationPage(Number(presentation.pages));
                        }
                      }}
                    />
                  </Box>
              }
              {
                presentation && (
                  !presentation?.isReady ? 
                    <Typography classes={{root: classes.grey}}>Подождите, конвертируется</Typography>:
                    <Typography classes={{root: classes.grey}}>
                      {presentation && presentation.pages > 0 && `из ${presentation.pages} `}
                      {tl("Слайд")}
                    </Typography>
                  )
              }
            </Box>
            <Box className={styles.changeBoxButtons}>
              <Box pr="8px">
                <Button
                  variant="contained"
                  onClick={handleCancel}
                >
                  Отмена
                </Button>
              </Box>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={!isChanged || !presentation}
              >
                Сохранить
              </Button>
            </Box>
            <Box id="file-upload" display="none" pt="12px">
              <FileUpload
                accept="application/pdf"
                onChange={(files: FileList) => {
                  dispatch(fetchWebinarAddPresentation(
                    props.webinar,
                    files[0],
                    (newPresentation: IPresentation) => {
                      setPresentation(newPresentation);
                    },
                  ));
                  setPresentationPage(1);
                  setIsChanged(true);
                }}
              />
            </Box>
          </Box>
        </form>
      )}
    </Box>
  );
}
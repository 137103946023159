import * as React from 'react';
import { Box, Button, IconButton, Tooltip } from '@material-ui/core';
import { lighten, makeStyles } from '@material-ui/core/styles';
import usePaymentWidgets from '../../PaymentWidget';
import { SvgIcon } from '../../';
import { isMobile } from 'react-device-detect';
import { ConnectDragSource, useDrag, useDrop } from 'react-dnd';
import { useRef } from 'react';
import { Identifier } from 'typescript';
import { MoveIcon } from '../../Icon/move';
import { IIntegrationCommon, IPayment, TAdsType } from '../../../types';


const useStyles = makeStyles((theme) => ({
  wrap: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover span': {
      color: (props: { color: string }) => props.color,
    },

    '&:hover $settings': {
      display: 'flex',
    },
  },

  root: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: (props: { color: string }) => props.color,
    color: '#fff',
    borderRadius: 2,
    minWidth: 130,
    height: 66,
    maxWidth: 281,
    margin: 4,
    overflow: 'hidden',
    boxShadow: '0px 0px 6px #5E80A780',
    '&:hover': {
      backgroundColor: (props: { color: string }) => lighten(props.color, 0.2),
    },
    [theme.breakpoints.down(640)]: {
      fontSize: 13,
      height: 46,
      maxWidth: 178,
      padding: 8,
    },
  },

  rootEditable: {
    backgroundColor: (props: { color: string }) => props.color,
    color: '#fff',
    borderRadius: 2,
    height: 66,
    minWidth: 130,
    maxWidth: 281,
    margin: 5,
    willChange: 'width',
    transitionProperty: 'min-width',
    transitionDuration: '300ms',
    '&:hover': {
      backgroundColor: (props: { color: string }) => props.color,
    },
  },

  label: {
    fontSize: 18,
    lineHeight: 1.2,
    position: 'relative',
    '-webkit-box-orient': 'vertical',
    wordBreak: 'break-word',
    maxHeight: '2.4em',
    font: 'SF UI Text',
    fontWeight: 600,
    width: 'auto',
    lineClamp: 2,
    overflow: 'hidden',
    display: 'box',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down(640)]: {
      fontSize: 13,
      maxHeight: 30,
      lineHeight: '15px',
    },
  },

  settings: {
    display: 'none',
    position: 'absolute',
    width: 'calc(100% - 14px)',
    justifyContent: 'space-around',
    margin: 2,
  },

  settingsButton: {
    width: '40px',
    height: '40px',
    backgroundColor: '#fff',

    '&:hover': {
      backgroundColor: 'rgba(255,255,255, 0.85)',
    },
  },
  moveButton: {
    width: 24,
    height: 24,
    color: theme.palette.primary.main,
  },
  settingsIcon: {
    transform: 'scale(1.2)',
  },
}));
interface ButtonProps {
  id: string;
  color: string;
  text: string;
  link: string;
  onClick?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
  moveButton?: (dragIdx: number, hoverIdx: number) => void;
  dragEl?: ConnectDragSource;
  editable?: boolean;
  userId?: string;
  payment?: IPayment;
  integration?: IIntegrationCommon;
  type?: TAdsType
}

export const AdsButton = React.forwardRef<HTMLDivElement, ButtonProps>(
  (props, ref) => {
    const paymentWidgets = usePaymentWidgets();

    const classes = useStyles({ color: props.color });
    const clickHandler = async () => {
      if (props.payment && props.integration && paymentWidgets) {
        const result = await paymentWidgets.load(props.integration, props.payment);
  
        if (result || paymentWidgets.loaded[props.integration.type]) {
          const payFunction = paymentWidgets.createPayFunction(props.integration, props.payment, props.userId ?? '');
          payFunction();
        } else {
          alert('Не удалось загрузить виджет');
        }
      } else {
        props.onClick && props.onClick();
      }
    }

    return (
      <div ref={ref} className={props.editable ? classes.wrap : ''}>
        <Tooltip
          arrow
          placement="top"
          title={props.text.length > (isMobile ? 24 : 36) ? props.text : ''}
        >
          <Button
            data-testid="AdsBtn"
            // aria-label={props.text}
            classes={{
              root: props.editable ? classes.rootEditable : classes.root,
              label: classes.label,
            }}
            onClick={clickHandler}
            href={(!props.integration && props.link) || ''}
            target="_blank"
            rel="noopener noreferrer"
          >
            {props.text}
          </Button>
        </Tooltip>
        {props.editable && (
          <Box className={classes.settings}>
            {props.moveButton && (
              <IconButton className={classes.settingsButton} ref={props.dragEl}>
                {/* <SvgIcon type="sw-solid-bin" /> */}
                <MoveIcon className={classes.moveButton} />
              </IconButton>
            )}
            <IconButton
              onClick={props.onEdit}
              className={classes.settingsButton}
            >
              <Box className={classes.settingsIcon}>
                <SvgIcon type="fs-list-settings" modifier="dark" />
              </Box>
            </IconButton>
            <IconButton
              onClick={props.onDelete}
              className={classes.settingsButton}
            >
              <SvgIcon type="sw-solid-bin" />
            </IconButton>
          </Box>
        )}
      </div>
    );
  }
);

interface DragItem {
  index: number;
}

interface DraggableAdsButtonProps extends ButtonProps {
  index: number;
  moveButton: (dragIdx: number, hoverIdx: number) => void;
}

export const DraggableAdsButton: React.FC<DraggableAdsButtonProps> = (
  props
) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{ isDragging }, drag, preview] = useDrag({
    type: 'ads-button',
    item: () => {
      return { index: props.index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: 'ads-button',
    collect(monitor: any) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor: any) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = props.index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      // const hoverMiddleY =
      //   (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // const hoverMiddleX =
      //   (hoverBoundingRect.right - hoverBoundingRect.left) / 1.25;

      // // Determine mouse position
      // const clientOffset = monitor.getClientOffset();

      // // Get pixels to the top
      // const hoverClientY = clientOffset!.y - hoverBoundingRect.top;
      // const hoverClientX = clientOffset!.x - hoverBoundingRect.right;

      if (
        Math.abs(monitor.getClientOffset().x - hoverBoundingRect.left) >
        hoverBoundingRect.width / 2
      ) {
        return;
      }

      // Dragging upwards
      // if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      //   return;
      // }

      // if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
      //   console.log(hoverClientX, hoverMiddleX);
      //   return;
      // }

      // Time to actually perform the action
      props.moveButton(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  drop(ref);
  preview(ref);
  return (
    <div
      ref={ref}
      data-handler-id={handlerId}
      style={{
        opacity: isDragging ? 0.5 : 1,
        minWidth: isDragging ? 150 : 'auto',
      }}
    >
      <AdsButton {...props} dragEl={drag} />
    </div>
  );
};

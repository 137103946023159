import { TQueueName } from "../../types/Queue";

export const SUBSCRIBE_TO_QUEUE = 'SUBSCRIBE_TO_QUEUE';
export const UNSUB_BY_QUEUE_NAME = 'UNSUB_BY_QUEUE_NAME';
export const UNSUB_ALL = 'UNSUB_ALL';

export interface ISubscribeToQueueAction {
  type: typeof SUBSCRIBE_TO_QUEUE;
  queue: TQueueName;
  webinarId: string;
}

export interface IUnsubscribeFromAllQueues {
  type: typeof UNSUB_ALL;
}
export interface IUnsubscribeFromQueue {
  type: typeof UNSUB_BY_QUEUE_NAME;
  queueName: TQueueName;
}

export type TTasksQueueAction = 
  ISubscribeToQueueAction |
  IUnsubscribeFromAllQueues |
  IUnsubscribeFromQueue;
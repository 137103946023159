import {
  Box,
  BoxProps,
  IconButton,
  IconButtonProps,
  Slider,
  SliderProps,
  Theme,
} from '@material-ui/core';
import { VolumeDown, VolumeOff, VolumeUp } from '@material-ui/icons';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import React, { ChangeEvent, useState } from 'react';
import { MUITooltip } from '../../MUI';
import { tl } from '../../utils';

interface VolumeElementProps {
  volumeOff: boolean;
}

const sliderStyles = createStyles((theme: Theme) => ({
  root: {
    color: (props: VolumeElementProps) =>
      props.volumeOff ? theme.palette.common.white : theme.palette.grey[800],
    width: 2,
    borderRadius: 1,
    marginBottom: 6,
  },
  thumb: {
    backgroundColor: (props: VolumeElementProps) =>
      props.volumeOff ? theme.palette.common.white : theme.palette.grey[800],
    height: 12,
    width: 12,
    '&:focus, &:hover, &:active': {
      boxShadow: 'none',
    },
  },
  track: {
    width: 2,
    borderRadius: 1,
  },
  rail: {
    width: 2,
    borderRadius: 1,
    backgroundColor: theme.palette.grey[500],
    opacity: 1,
  },
}));

function SliderRaw(
  props: WithStyles<typeof volumeWrapStyles> &
    Omit<SliderProps, keyof VolumeElementProps> &
    VolumeElementProps
) {
  const { volumeOff, ...other } = props;
  return <Slider {...other} />;
}

const VolumeSlider = withStyles(sliderStyles)(SliderRaw);

interface VolumeControlWrapProps {
  volumeOff: boolean;
  open: boolean;
}

const volumeWrapStyles = createStyles((theme: Theme) => ({
  '@global': {
    '@keyframes popUp': {
      '0%': {
        opacity: 0,
        transform: 'scale(.65)',
      },
      '30%': {
        opacity: 0,
        transform: 'scale(.65)',
      },
      '100%': {
        opacity: 1,
        transform: 'scale(1)',
      }
    },
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    backgroundColor: (props: VolumeControlWrapProps) =>
      props.volumeOff ? theme.palette.grey[800] : theme.palette.common.white,
    borderRadius: '20px',
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)',
    transition: 'height .25s',
    paddingTop: (props: VolumeControlWrapProps) => (props.open ? 12 : 0),
    height: (props: VolumeControlWrapProps) => (props.open ? 117 : 40),
    animation: '$popUp 500ms ease',
    '& > span': {
      display: (props: VolumeControlWrapProps) =>
        props.open ? 'inline-block' : 'none',
    },

    '@media (min-width: 992px)': {
      '&:hover > span': {
        display: 'inline-block',
      },
  
      '&:hover': {
        paddingTop: 12,
        height: 117,
      },
    }

  },
}));

function VolumeWrapRaw(
  props: WithStyles<typeof volumeWrapStyles> &
    Omit<BoxProps, keyof VolumeControlWrapProps> &
    VolumeControlWrapProps
) {
  const { volumeOff, open, ...other } = props;
  return <Box {...other} />;
}

const VolumeControlWrap = withStyles(volumeWrapStyles)(VolumeWrapRaw);

const iconWrapStyles = createStyles((theme: Theme) => ({
  root: {
    padding: '10px 10.25px',
    color: (props: VolumeElementProps) =>
      props.volumeOff ? theme.palette.common.white : theme.palette.grey[800],
    transition: 'color .25s',
  },
}));

function IconWrapRaw(
  props: WithStyles<typeof iconWrapStyles> &
    Omit<IconButtonProps, keyof VolumeElementProps> &
    VolumeElementProps
) {
  const { volumeOff, ...other } = props;
  return <IconButton {...other} />;
}

const IconWrap = withStyles((theme: Theme) => ({
  root: {
    padding: '10px 10.25px',
    color: (props: VolumeElementProps) =>
      props.volumeOff ? theme.palette.common.white : theme.palette.grey[800],
    transition: 'color .25s',
  },
}))(IconWrapRaw);

interface VolumeControlProps {
  value?: number;
  handleChange?: (event: any, value: number) => void;
  volume: number;
  muted: boolean;
  handleMute: (value: boolean) => void;
  handleVolumeChange: (value: number) => void;
}

export const VolumeControl: React.FC<VolumeControlProps> = ({ 
  muted, 
  handleMute, 
  volume,
  handleVolumeChange,
}) => {
  const [open, setOpen] = useState(false);

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    handleMute(!muted);
  }

  return (
    <VolumeControlWrap volumeOff={muted} open={open}>
      <VolumeSlider
        volumeOff={muted}
        orientation="vertical"
        value={volume}
        //@ts-ignore
        onChange={(e: ChangeEvent<{}>, v: number | number[]) => {
          handleVolumeChange(v as number);
          if (!open) setOpen(true);
        }}
        onChangeCommitted={(_, value) => {
          setOpen(false);
          handleVolumeChange(value as number);
        }}
      />
      <MUITooltip
        title={muted ? tl('Включить звук') : tl('Отключить звук')}
      >
        <div>
          <IconWrap volumeOff={muted} onClick={handleClick}>
            {muted && <VolumeOff />}
            {volume < 20 && !muted && <VolumeDown />}
            {volume >= 20 && !muted && <VolumeUp />}
          </IconWrap>
        </div>
      </MUITooltip>
    </VolumeControlWrap>
  );
};

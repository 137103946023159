//@ts-nocheck
import * as React from "react";
import { SnackbarContent, Box } from "@material-ui/core";
import { makeStyles,useTheme } from "@material-ui/core/styles";
import { SvgIcon } from "../../components";
import { MessageType } from "../SnackbarNotifierClosable";

interface IProps {
  note: {
    messageType: MessageType;
    messageTitle?: string;
    message: string | React.ReactElement;
    hideIcon?: boolean;
    centered?: boolean;
  };
  left?: boolean;
  onClose?: () => void;
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  root: theme.overrides.MuiSnackbarContent.root,
  success: {
    backgroundColor: `${theme.palette.success.light} !important`,
    borderColor: theme.palette.success.dark,
    color: `${theme.palette.success.contrastText} !important`,
  },
  error: {
    backgroundColor: `${theme.palette.error.light} !important`,
    borderColor: theme.palette.error.dark,
    color: `${theme.palette.error.contrastText} !important`,
  },
  info: {
    backgroundColor: `${theme.palette.info.light} !important`,
    borderColor: theme.palette.info.dark,
    color: `${theme.palette.info.contrastText} !important`,
  },
  warning: {
    backgroundColor: `${theme.palette.warning.light} !important`,
    borderColor: theme.palette.warning.dark,
    color: `${theme.palette.warning.contrastText} !important`,
  },
  title: {
    marginBottom: "10px",
    fontSize: "15px",
    lineHeight: "18px",
    fontWeight: 700,
  },
  inline: {
    borderWidth: "1px",
    borderStyle: "solid",
    boxShadow: "none",
    minWidth: 0,
  },
  closer: {
    position: "absolute",
    top: "7px",
    right: "7px",
    cursor: "pointer",
  },
}));

export const SnackbarNotifierInline = (props: IProps) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <SnackbarContent
      classes={{ root: classes[props.note.messageType] }}
      className={`${classes.inline} ${
        props.note.hideIcon && !props.onClose ? "hideIcon" : ""
      } ${props.note.centered ? "centered" : ""} ${props.className || ""}`}
      message={
        <>
          {props.note.messageTitle ? (
            <Box className={classes.title}>{props.note.messageTitle}</Box>
          ) : null}
          {props.note.message}
          {props.note.hideIcon ? null : (
            <SvgIcon type={`notification ${props.note.messageType} inNotice`} />
          )}
          {props.onClose ? (
            <Box onClick={props.onClose} className={classes.closer}>
              <SvgIcon type={`close ${props.note.messageType}`} />
            </Box>
          ) : null}
        </>
      }
    />
  );
};

import { useEffect, useRef, useState } from "react";
import { api } from "../api";
import { IPresentation } from "../types";

interface IPresentationProcessStatusHook {
  checkInterval?: number;
  onReady: (presentation: IPresentation) => void;
}

export default function usePresentationProcessStatus(presentation: IPresentation | null = null, {onReady, checkInterval = 1500}: IPresentationProcessStatusHook) {
  
  const [isReady, setReady] = useState(false);
  const isReadyInterval = useRef(0);
  
  const checkPresentationStatus = async (id:string)=>{
    const presentation= await api.presentation.get(id);
    if(presentation.isReady) {
      clearInterval(isReadyInterval.current);
      setReady(true);
      onReady(presentation)
    }
  }

  useEffect(() => {
    if (presentation && !presentation.isReady) {
      setReady(false);
      isReadyInterval.current = window.setInterval(checkPresentationStatus, checkInterval, presentation.id);
    } else {
      setReady(true)
    }
    return () => clearInterval(isReadyInterval.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [presentation]);

  return isReady;
}
import { SvgIcon, SvgIconProps } from "@material-ui/core"
import { forwardRef } from "react";


export const CloseIcon: React.FC<SvgIconProps & {width?: number}> = forwardRef(({ width = 2, ...props}, ref) => {
  return (
    <SvgIcon {...props} viewBox="0 0 11.314 11.314" ref={ref}>
      <g id="Сгруппировать_24536" data-name="Сгруппировать 24536" transform="translate(0.657 0.657)">
        <rect id="Прямоугольник_2172" data-name="Прямоугольник 2172" width="14" height="2" rx="1" transform="translate(0.758 -0.657) rotate(45)" fill="currentColor"/>
        <rect id="Прямоугольник_2230" data-name="Прямоугольник 2230" width="14" height="2" rx="1" transform="translate(10.656 0.757) rotate(135)" fill="currentColor"/>
      </g>
      {/* <path d="M5,5,0,10,5,5,0,0,5,5l5-5L5,5l5,5Z" strokeLinecap="round" transform="translate(5 5)" strokeWidth={width} stroke="currentColor" /> */}
    </SvgIcon>
  );
})

export const ModalCloseIcon: React.FC<SvgIconProps> = ({...props}) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <path d="M5,5,0,10,5,5,0,0,5,5l5-5L5,5l5,5Z" transform="translate(5 5)" strokeWidth="2" stroke="currentColor" />
    </SvgIcon>
  );
}


export default CloseIcon;
export const PRESENTATION_CHECK_STATUS = 'PRESENTATION_CHECK_STATUS';

export interface IPresentationCheckAction {
  type: typeof PRESENTATION_CHECK_STATUS;
  id: string;
  updateStreamOnReady?: boolean
}


export type TPResentationsActions = 
  IPresentationCheckAction
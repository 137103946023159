//@ts-nocheck
import * as React from "react";
import {Box, Button} from "@material-ui/core";
import { makeStyles,withStyles } from "@material-ui/core/styles";
import {darken} from "@material-ui/core/styles";
import {CompactPicker, SketchPicker} from "react-color";
import {SvgIcon} from "../../components";
import {tl} from "../../utils";
import styles from "./styles.module.scss";
import {themeVariables} from "../_Theme";

interface IProps {
  bgcolor?: string;
  label?: string;
  labelcolor?: string;
  color: string;
  presetColors?: string[];
  position?: "bottom";
  onChange: (color: string) => void;
  big?: boolean;
  compact?: boolean;
  size?: "md";
}

export const MUIColorPicker = (props: IProps) => {
  const refContainer = React.useRef(null);

  const [color, setColor] = React.useState<string>(props.color);
  const [showPicker, setShowPicker] = React.useState<boolean>(false);

  React.useEffect(() => {
    setColor(props.color);
  }, [props.color]);

  const ColorButton = React.useMemo(() => {
    return withStyles(() => ({
      root: {
        minWidth: "32px",
        height: "32px",
        border: themeVariables.border,
        borderRadius: "2px",
        padding: 0,
        position: "relative",

        "&:before": {
          content: "''",
          position: "absolute",
          backgroundColor: props.bgcolor || color,
          "&:hover": {
            backgroundColor: darken(props.bgcolor || color, 0.3),
            boxShadow: "none",
          },
          width: "16px",
          height: "16px",
          top: "7px",
          left: "7px",
        },
      },
    }))(Button);
  }, [props.bgcolor, color]);

  const classes = makeStyles({
    colorButtonBig: {
      paddingTop: `${themeVariables.spacing * 1.6}px !important`,
      paddingBottom: `${themeVariables.spacing * 1.6}px !important`,
    },
  })({});

  const handleClickOutside = (evt: any) => {
    if (
      showPicker &&
      refContainer.current &&
      !refContainer.current.contains(evt.target)
    ) {
      setShowPicker(false);
      setColor(props.color);
    }
  };

  React.useEffect(() => {
    if (showPicker) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPicker]);

  const buttons = (
    <>
      <Box pt={1}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          className={styles.confirmButton}
          onClick={() => {
            setShowPicker(false);
            props.onChange(color);
          }}
        >
          {tl("Подтвердить")}
        </Button>
      </Box>
      <Box pt={1}>
        <Button
          variant="contained"
          fullWidth
          className={styles.confirmButton}
          onClick={() => {
            setShowPicker(false);
            setColor(props.color);
          }}
        >
          {tl("Отмена")}
        </Button>
      </Box>
    </>
  );

  return (
    <Box className={`${styles.container} ${props.label ? styles.labeled : ""}`}>
      <div ref={refContainer}>
        <ColorButton
          onClick={() => {
            setShowPicker(true);
          }}
          className={`${styles.trigger} ${
            props.big ? classes.colorButtonBig : ""
          } ${props.size === "md" ? styles.md : ""}`}
        >
          <Box color={props.labelcolor || props.color}>{props.label}</Box>
          <Box className={`${styles.renew} ${showPicker ? styles.active : ``}`}>
            <SvgIcon type="courses-renew small"/>
          </Box>
        </ColorButton>
        {showPicker && (
          props.compact
            ? 
              <Box position="absolute" zIndex="3">
                <CompactPicker
                  color={color}
                  colors={props.presetColors || []}
                  onChangeComplete={(value: any) => {
                    props.onChange(value.hex);
                    setShowPicker(false);
                  }}
                />
              </Box>
            :
              <Box
                p={1}
                border={themeVariables.border}
                bgcolor={themeVariables.palette.common.white}
                borderRadius={themeVariables.buttonsRadius}
                className={`${styles.colorpicker} ${
                  props.position === "bottom" ? styles.bottom : ``
                }`}
              >
                <SketchPicker
                  color={color}
                  disableAlpha
                  presetColors={props.presetColors || []}
                  
                  onChangeComplete={(value: any) => {
                    setColor(value.hex);
                  }}
                />
                {buttons}
              </Box>
        )}
      </div>
    </Box>
  );
};

export enum ETaskStatus  {
  PROCESS = 'process',
  FAILED = 'failed' ,
  WRONG_FORMAT = 'wrong format',
  READY = 'ready',
  UNKNOWN = 'unknown',
  INQUEUE = 'inqueue'
}

export interface ITask {
  name?: string
  id: string;
  status: ETaskStatus;
  created: string;
  format: "moderator" | "member"
}



export type TQueueName = 'tickets'

export type Queues = {
  [key in TQueueName]: {
    interval: number;
  };
};

import cx from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Divider, Grid, Typography } from "@material-ui/core";
import { MUIBlock } from "../../MUI";
import { tl } from "../../utils"
import { IWebinarFilter, IWebinar, EWebinarType } from "../../types";
import { makeStyles } from "@material-ui/core/styles";
import InfiniteScroll from "react-infinite-scroller";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { fetchWebinars, webinarsFetchAttempt, webinarsListReset } from "../../store/webinars/actions";
import { NewWebinarModal } from "../../components/Modals";
import { fetchAutowebinarCreateWithScenario, fetchWebinarCreate } from "../../store/webinar/actions";
import { WebinarListCard, WebinarListCardSkeleton } from "../../components/Webinar/ListCard";
import WebinarSearchBar from "../../components/Webinar/SearchBar";
import { PlusIcon } from "../../components/Icon/PlusIcon";
import { useScrollbarStyles } from "../../hooks/useScrollbarStyles";
import { NewAutowebinarModal } from "../../components/Modals/NewAutowebinarModal";
import { debounce } from "lodash";
import CreateAutoWebButton from "../../components/Webinar/CreateAutoWebButton";

interface WebinarsListProps {
  type: EWebinarType;
}

const useStyles = makeStyles((theme) => ({
  mobileSearchActive: {
    [theme.breakpoints.down("sm")]: {
      // display: "none",
      ".mobileSearchActive &": {
        display: "block",
      },
      ".mobileSearchActive .page": {
        paddingTop: 0,
      },
    },
  },
  [theme.breakpoints.down("sm")]: {
    ".mobileSearchActive .page": {
      paddingTop: 0,
    },
  },

  short: {
    maxHeight: "72vh",
  },
  greyText: {
    color: '#6d6d6d',
    fontSize: 14,
    lineHeight: 1,
  },
  nomArginDivider: {
    marginTop: 0,
    marginBottom: 0,
  },
  hintList: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
    '& li': {
      color: '#6d6d6d',
      fontSize: 14,
      linHeight: '16px',
    }
  }
}));

export const WebinarList = ({ type }: WebinarsListProps) => {
  const classes = useStyles();
  const scrollbarClass = useScrollbarStyles();
  const [modalOpen, setModalOpen] = useState(false);
  const [autowebModalOpen, setAutowebModalOpen] = useState(false);

  const [filter, setFilter] = useState<IWebinarFilter>({
    search: "",
    sortBy: "date",
    sortOrder: "asc",
    filterOptions: ["soon", "future", "past", "draft"],
  });

  const {
    loading,
    list,
    count,
    error,
    loadingNext,
    loadingWeb
  } = useSelector((state: RootState) => ({
    loading: state.webinars.loading[type],
    list: state.webinars.lists[type],
    count: state.webinars.count[type],
    error: state.webinars.error[type],
    loadingWeb: state.webinar.loading,
    loadingNext: state.webinars.loadingMore[type],
  }))

  const dispatch = useDispatch()

  const wordRoot = type === EWebinarType.WEBINAR ? "вебинар" : "автовебинар";


  const handleSubmit = (name: string) => {
    dispatch(fetchWebinarCreate(type, name))
  };

  const handleSubmitAutoWithScenario = (form: HTMLFormElement) => {
    const formData = new FormData(form);
    dispatch(fetchAutowebinarCreateWithScenario(formData));
  }

  const loadMore = () => {
    if (!loading && !loadingNext) {
      dispatch(fetchWebinars(type, filter, list.length, 7))
    }
  }

  useEffect(() => {
    count > 0 && dispatch(webinarsListReset(type))
    dispatch(fetchWebinars(type, filter, 0, 7))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const loadWithFilter = useCallback(
    debounce((filterData: IWebinarFilter)  => {
      dispatch(fetchWebinars(type, filterData, 0, 7))
    }, 200)
  , [count] )


  const onFilterSend = (filterData: IWebinarFilter) => {
    setFilter(filterData);
    dispatch(webinarsFetchAttempt(type))
    loadWithFilter(filterData);
  };

  const handleCreateAutoWeb = (type: 'simple' | 'scenario') => {
    
    switch (type) {
      case 'simple':
        setModalOpen(true);
        break;
      case 'scenario':
        setAutowebModalOpen(true);
        break;
      default:
        break;
    }
  }

  return (
    <Box>
    
      <Box className={classes.mobileSearchActive}>
        <WebinarSearchBar
          title={type === EWebinarType.WEBINAR ? "Вебинар" : "Автовебинар"}
          filter={filter} 
          onAddClick={()=> setModalOpen(true)}
          onAutowebImportClick={() => setAutowebModalOpen(true)}
          filterOptionsSelected={filter.filterOptions!}
          webinarType={type}
          disabled={!!loading}
          sortBy={filter.sortBy}
          onFilterSend={onFilterSend}
          hideBtn={(!filter.search && !loading && count === 0)}
        />
      </Box>
      <MUIBlock
        pInner={0}
        data={
          {
            style: {
              borderRadius: `0 0 4px 4px`,
              borderTop: "none",
            }
          }
        }
      >
        <Box className={cx(classes.short, scrollbarClass.scrollbar)}>
          <InfiniteScroll
            loadMore={loadMore}
            hasMore={!!(!loading && list.length < count)}
            loader={<WebinarListCardSkeleton />}
            useWindow={false}
            threshold={50}
          >

            {list.length > 0 && !loading 
              ? list.map((item: IWebinar) => (
                  <WebinarListCard key={item.id} webinar={item} />
                ))
              : loading
                ? Array.from({length: filter.search ? 2 : 7}, (v, k) => k++).map((i, idx) => <WebinarListCardSkeleton key={`${type}${i}${idx}-skeleton`} />)
                  : filter.search 
                  ? <Box>
                      <Box height="60px" pl={'21px'} display="flex" alignItems="center" justifyContent="center">
                        <Typography align="left" className={classes.greyText}>{tl(`Не нашли ${type === EWebinarType.WEBINAR ? 'вебинаров' : 'автовебинаров'} подходящих под условия поиска.`)}</Typography>
                      </Box>
                    </Box>
                  : type === EWebinarType.WEBINAR ? (
                      <Box>
                        <Box height="60px" pl={'21px'} display="flex" alignItems="center" >
                          <Typography align="left" className={classes.greyText}>{tl("У вас еще нет ни одного вебинара. Создайте новый вебинар и настройте его.")}</Typography>
                        </Box>
                        <Divider className={classes.noMarginDivider} classes={{
                          root: classes.noMarginDivider
                        }} />
                        <Box py={'40.5px'} display="flex" alignItems="center" justifyContent="center">
                          <Button
                            startIcon={<PlusIcon />}
                            onClick={() => setModalOpen(true)}
                            variant="contained"
                            color="primary">
                            {tl("Вебинар")}
                          </Button>
                        </Box>
                      </Box>
                  ) : (
                    <Box py={'20px'} pl={'21px'} >
                      <Grid container item direction="row">
                        <Grid item md={9} xs={12}>
                          <Typography align="left" className={classes.greyText}>{tl("Здесь будут ваши автовебинары. 4 способа создать автовебинар: ")}</Typography>
                          <ul className={classes.hintList}>
                            <li>1) Попросите поддержку перенести ваш автовебинар с другой площадки </li>
                            <li>2) Перенесите сами ваш автовебинар с другой площадки, это легко</li>
                            <li>3) Превратите в один клик проведенный у нас вебинар в автовебинар</li>
                            <li>4) Создайте автовебинар, загрузите в него видео, добавьте сообщения чата и другие события.</li>
                          </ul>
                          <br />
                          <Typography align="left" className={classes.greyText}>{tl("* Автовебинар – это автоматическое повторение вебинара, включая все события: показ видео, презентации, комментариев в чате, кнопок и баннеров.")}</Typography>
                        </Grid>
                        <Grid item container alignItems="center" justify="center" md={3} xs={12} >
                          <CreateAutoWebButton onClick={handleCreateAutoWeb} />
                        </Grid>
                      </Grid>
                    </Box>
                  )
            }
          </InfiniteScroll>
          {error &&
            <Box py="20px">
              <Typography align="center">
                {tl(`Ошибка при загрузке ${wordRoot}в. Попробуйте перезагрузить страницу.`)}
              </Typography>
            </Box>}
        </Box>
      </MUIBlock>
      <NewAutowebinarModal loading={loadingWeb} onClose={()=>setAutowebModalOpen(false)} open={autowebModalOpen} handleSubmit={handleSubmitAutoWithScenario}  />
      <NewWebinarModal loading={loadingWeb} wordRoot={wordRoot} onClose={()=>setModalOpen(false)} open={modalOpen} handleSubmit={handleSubmit} />
    </Box>
  )
};
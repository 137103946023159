import React, { lazy, Suspense, useEffect, useState } from 'react';
import { matchPath, Route, Switch, useLocation } from 'react-router-dom';
import Page from './hoc/page';
import { WebinarsPage } from './pages/webinars';
import { MUIPreloader } from './MUI';
import { PrivateRoute } from './components/PrivateRoute';
import { NameModal } from './components/Modals/NameModal';
import { Location } from 'history';
import AccessControlCTX, { defRules } from './context/accessControl';
import useAppSelector from './hooks/useAppSelector';
import { EAuthStatus } from './types';
import AccessControl from './services/AccessControl.service';
import Permission from './hoc/permission';
import { PermissionDenied } from './components/PermissionDenied';
import ErrorBoundary from './hoc/errorBoundary';
import { AdminRoutes } from './pages/admin/routes';
import { SettingsRoutes } from './pages/settings/routes';
import { useDispatch } from 'react-redux';
import { appendScript } from './utils';
import { PrivacyPolicyPage } from './pages/policy';
import { OfferPage } from './pages/offer';
import { history } from './store';
import { ConfigEnvPage } from './pages/admin/configEnv';
import { InviteFailPage } from './pages/inviteFail';
import { BillingPage } from './pages/billing';
import { PlansPage } from './pages/plans';
import { getPlans } from './store/plans/actions';
import { NoSeats } from './pages/noseats';
import { ExternalPlansPage } from './pages/plans/external';
import { WebinarAuthorReview } from './components/Modals/WebinarAuthorReview';
import { setWebinarForReview } from './store/app/actions';

const PlayerOnboardPage = lazy(() => import('./pages/onboard'));
const StatisticsPages = lazy(() => import('./pages/statistics'));
const WebinarPages = lazy(() => import('./pages/webinarPages'));
const PlayerPages = lazy(() => import('./pages/playerPages'));

const ws = process.env.REACT_APP_SOCKETBASEPATH as string;

const App: React.FC<{ history: typeof history }> = () => {
  const location = useLocation<{ nameModal?: Location<unknown>, reviewModal?: Location<unknown> }>();
  // CarrotQuest:
  useEffect(() => {
    if (
      !matchPath(location.pathname, '/:link')?.isExact &&
      !matchPath(location.pathname, '/webinar/:webinarId/player')?.isExact
    ) {
      const helpDesk = document.createElement('script');
      helpDesk.src = '//cdn5.helpdeskeddy.com//js/contact-widget.js';
      helpDesk.id = 'hde-contact-widget';
      helpDesk.dataset.assetsHost = '//cdn5.helpdeskeddy.com/';
      helpDesk.dataset.host = 'support.antitreningi.ru';
      helpDesk.dataset.lang = 'ru';
      helpDesk.defer = true;
      document.head.appendChild(helpDesk);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const dispatch = useDispatch();
  const nameModal = location.state?.nameModal;
  const reviewModal = location.state?.reviewModal;
  const [ac, setAC] = useState<AccessControl>(defRules);
  const {user, review} = useAppSelector((state) => ({user: state.user, review: state.app.review}));
  useEffect(() => {
    dispatch(getPlans());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user.status === EAuthStatus.AUTHORIZED) {
      setAC(new AccessControl(user.profile.permissions));
    }
  }, [user.profile.permissions, user.status]);

  return (
    <ErrorBoundary>
      <AccessControlCTX.Provider value={ac}>
        <Switch location={nameModal || reviewModal || location}>
          {process.env.REACT_APP_TEMP_ENV && (
            <Route path="/config-env" exact>
              <ConfigEnvPage />
            </Route>
          )}
          <PrivateRoute path="/billing" exact>
            <BillingPage />
          </PrivateRoute>
          <PrivateRoute path="/plans" exact>
            <PlansPage />
          </PrivateRoute>
          <Route path="/external-plans" exact>
            <ExternalPlansPage />
          </Route>
          <Route path="/privacy-policy" exact>
            <PrivacyPolicyPage />
          </Route>
          <Route path="/offer" exact>
            <OfferPage />
          </Route>
          <Route path="/invite-fail" exact>
            <InviteFailPage />
          </Route>
          <PrivateRoute path="/settings">
            <SettingsRoutes />
          </PrivateRoute>
          <PrivateRoute path="/admin">
            <AdminRoutes />
          </PrivateRoute>
         
          <PrivateRoute path="/webinar/:webinarId">
            <Suspense fallback={<MUIPreloader />}>
              <WebinarPages />
            </Suspense>
          </PrivateRoute>
          <PrivateRoute path="/statistics">
            <Suspense fallback={<MUIPreloader />}>
              <StatisticsPages />
            </Suspense>
          </PrivateRoute>
          <PrivateRoute exact path={['/', '/autowebinars']}>
            <Permission
              entity="webinar"
              action="list"
              fallback={<PermissionDenied />}
            >
              <Suspense fallback={<MUIPreloader />}>
                <Page size="sm" title="Вебинары" centered>
                  <WebinarsPage />
                </Page>
              </Suspense>
            </Permission>
          </PrivateRoute>
          <Route path="/:link/noseats">
            <Suspense fallback={<MUIPreloader />}>
              <NoSeats />
            </Suspense>
          </Route>
          <Route path="/:link/onboard">
            <Suspense fallback={<MUIPreloader />}>
              <PlayerOnboardPage />
            </Suspense>
          </Route>
          <Route path="/:link" key="PlayerPages">
            <Suspense fallback={<MUIPreloader />}>
              <PlayerPages ws={ws} />
            </Suspense>
          </Route>
        </Switch>
        <Route path="/name">
          <NameModal location={nameModal} />
        </Route>

        <WebinarAuthorReview
          open={!!review}
          webinar={review}
          onClose={() => {
            dispatch(setWebinarForReview());
          }}
        />
       
      </AccessControlCTX.Provider>
    </ErrorBoundary>
  );
};

export default App;

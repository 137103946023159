//@ts-nocheck
import * as React from "react";
import * as classnames from "classnames";
import styles  from "./styles.module.scss";

interface ISwitchState {
  isActive: boolean;
}

interface IProps {
  defaultChecked?: boolean;
  checked?: boolean;
  onChange?: Function;
  onClick?: Function;
  name?: string;
  value?: string;
  margin?: boolean;
  note?: any;
  noteHideOnChange?: boolean;
}

let id = 0;
let hideNote = false;

export default class Switch extends React.Component<IProps, ISwitchState> {
  public id: string;
  public state: ISwitchState = {
    isActive: 'checked' in this.props ? this.props.checked : (this.props.defaultChecked || false)
  };

  constructor(props: IProps) {
    super(props);
    this.id = `react-input-${id++}`;
  }

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isActive = !this.state.isActive;

    if (this.props.onChange) {
      this.props.onChange(isActive);
    }
    if (this.props.noteHideOnChange) {
      hideNote = true;
    }
    this.setState({isActive});
  };

  onClick(e: any) {
    if ('onClick' in this.props) {
      this.props.onClick(e);
    }
  }

  render() {
    let isActive = this.state.isActive;
    if ('checked' in this.props) {
      isActive = this.props.checked;
    }

    let noteText = "";

    const {name = "", value = "", note = ""} = this.props;

    const className = classnames({
      [styles.cSwitch]: true,
      [styles.cSwitchMargin]: this.props.margin
    });

    if (note !== "") {
      noteText = isActive ? note[1] : note[0];
    }

    return (
      <>
        <span className={className}>
          <label
            htmlFor={this.id}
            className={classnames({[styles.switcher]: true, [styles.active]: isActive})}
            onClick={this.onClick.bind(this)}
          />
          <input
            id={this.id}
            type="checkbox"
            value={value}
            name={name}
            hidden
            checked={isActive}
            onChange={this.handleChange}
          />
        </span>
        {note !== "" && !hideNote ?
          <span
            className={classnames({'switchnote': true, 'active': isActive})}
          >{noteText}</span>
          : null
        }
      </>
    );
  }
}

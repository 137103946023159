import React, { useState, useRef } from 'react';
import Cropper, { ReactCropperElement } from 'react-cropper';
import "cropperjs/dist/cropper.css";
import { Box, Button, Grid, makeStyles } from '@material-ui/core';
import { SvgIcon } from '../../../components';
import { MUIDialog } from '../../../MUI';
import { base64toFile, tl } from '../../../utils';

const useStyles = makeStyles((theme) => ({
  photo: {
    width: 168,
    height: 168,
    cursor: 'pointer'
  },
  crop: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .cropper-line': {
      backgroundColor: theme.palette.primary.light,
    },
    '& .cropper-point': {
      backgroundColor: theme.palette.primary.light,
    }
  },
  cropRotate: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  hiddenInput: {
    position: 'absolute',
    top: -1000,
  }
}));

interface PhotoCropperProps {
  onCropSave: (file: File) => void;
  photo?: string;
}

export const PhotoCropper: React.FC<PhotoCropperProps> = (props) => {
  const classes = useStyles();

  const [upImg, setUpImg] = useState<string | null | undefined>(null);
  const [file, setFile] = useState<File | undefined>();
  const [rotated, setRotated] = useState(0);

  const imgRef = useRef<ReactCropperElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleRotate = (value: number) => {
    const cropper = imgRef.current?.cropper
    if(cropper) {
      cropper.clear();
      let newRotated = rotated + value;
      cropper.rotate(value);
      cropper.zoomTo(0);
      let initialSize = cropper.getImageData();
      cropper.setCropBoxData({
        width: newRotated % 180 === 0 ? initialSize.width : initialSize.height,
        height: newRotated % 180 === 0 ? initialSize.height : initialSize.width,
      });
      cropper.crop();
      setRotated(newRotated);
    }
  }

  const handleInputClick = () => {
    inputRef.current?.click();
  }

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.currentTarget
    if (target && target.files && target.files.length > 0) {
      setFile(target.files[0]);
      setUpImg(URL.createObjectURL(target.files[0]));
    }
  };

  async function generateFile (e: React.MouseEvent) {
    e.preventDefault();
    const imageElement: ReactCropperElement | null = imgRef?.current;
    const cropper = imageElement?.cropper;
    if (cropper && file) {
      const data = cropper.getCroppedCanvas().toDataURL();
      const imageFile = base64toFile(data, file.name, file.type);
      props.onCropSave(imageFile);
      setUpImg(null);
      setFile(undefined);
    }
  }
  
  return (
    <React.Fragment>
      <input className={classes.hiddenInput} onChange={onSelectFile} type="file" name="photo" id="photo-file" ref={inputRef} />
      {
        !props.photo ?
        <Button
          variant="contained"
          onClick={handleInputClick}
        >
          {tl("Установить аватар")}
        </Button> :
        <img alt="photo" src={props.photo} className={classes.photo} onClick={handleInputClick}/>
      }
      <MUIDialog
        maxWidth="md"
        open={!!upImg}
        actions={[
          [
            {
              label: tl("Отмена"),
              btnProps: {
                onClick: () => {
                  setUpImg(null);
                  inputRef.current!.value = '';
                },
              },
            },
          ],
          [
            {
              label: tl("Подтвердить"),
              btnProps: {
                'data-testid': 'confirmBtn',
                color: "primary",
                onClick: generateFile,
              },
            },
          ],
        ]}
      >
        {
          upImg &&
          <Box className={classes.crop}>
             <Cropper
                allowTransparency={false}
                src={upImg} 
                responsive={false}
                modal={false}
                background={true}
                viewMode={2}
                minContainerWidth={508}
                aspectRatio={1}
                zoomable={true}
                center={true}
                autoCropArea={1}
                ref={imgRef} 
              />
            <Box pt={1} pr={1} className={classes.cropRotate}>
              <Grid container spacing={1}>
                <Grid item>
                  <Button
                    variant="contained"
                    className="MuiButton-iconAction"
                    onClick={() => {
                      handleRotate(-90);
                    }}
                  >
                    <SvgIcon type="rotate-rcw" />
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    className="MuiButton-iconAction"
                    onClick={() => {
                      handleRotate(90);
                    }}
                  >
                    <SvgIcon type="rotate-cw" />
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        }
      </MUIDialog>
    </React.Fragment>
  )
}
import { IWebinarEvent } from '../../types';
import {
  EVENTS_FETCH_ATTEMPT,
  EVENTS_FETCH_FAILURE,
  EVENTS_FETCH_STATUS_CHANGE,
  EVENTS_FETCH_SUCCESS,
  EVENTS_RELOAD,
  EVENT_ADD,
  EVENT_REMOVE,
  EVENT_SAVE,
  TEventsActionType,
} from './types';

export interface IEventsInitialState {
  events: IWebinarEvent[];
  count: number;
  isReady: boolean;
  loading: boolean;
  error?: boolean;
}

const initialState: IEventsInitialState = {
  events: [],
  count: 0,
  loading: false,
  error: false,
  isReady: true,
};

export default function eventsReducer(
  state = initialState,
  action: TEventsActionType
) {
  switch (action.type) {
    case EVENTS_FETCH_ATTEMPT:
      return {
        ...state,
        loading: true,
      };
    case EVENTS_FETCH_STATUS_CHANGE:
      return {
        ...state,
        count: !action.status ? 0 : state.count,
        isReady: action.status,
      }
    case EVENTS_FETCH_SUCCESS:
      return {
        ...state,
        events: state.events.concat(action.events),
        count: action.count,
        loading: false,
        error: false,
        isReady: true,
      };

    case EVENTS_FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        isReady: true
      };

    case EVENT_SAVE: {
      const prevList = state.events.slice();
      const oldEvent = prevList.find((item) => item.id === action.event.id);

      if (!oldEvent) return state;

      oldEvent.params = action.event.params;
      oldEvent.time = action.event.time;

      return {
        ...state,
        events: prevList,
      };
    }

    case EVENT_REMOVE: {
      const prevList = state.events.slice();
      return {
        ...state,
        events: prevList.filter((event) => event.id !== action.event.id),
        count: state.count - 1,
      };
    }

    case EVENTS_RELOAD: {
      return {
        ...state,
        events: [],
        count: 0,
        loading: false,
        isReady: true,
      };
    }

    case EVENT_ADD: {
      return {
        ...state,
        events: state.events.concat(action.event),
        count: state.count + 1,
      };
    }

    default:
      return state;
  }
}

import {Box, Button, Grid, makeStyles, Paper, Typography} from "@material-ui/core"
import Page from "../hoc/page"
import {Alert} from "@material-ui/lab";
import {Link} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: '800px',
    textAlign: 'center',
    padding: theme.spacing(4),
    '& p': {
      fontSize: 16,
      lineHeight: 1.2,
    },
    '& h3': {
      fontSize: 24,
      lineHeight: 1.5,
    },
    '& h1': {
      fontSize: 36,
      textAlign:'center'
    },
    [theme.breakpoints.down('md')]: {
      minWidth: '400px',
      padding: theme.spacing(1),
      '& p': {
        fontSize: 12,
        lineHeight: 1.1,
      },
      '& h3': {
        fontSize: 18,
        lineHeight: 1.2,
      },
      '& h1': {
        fontSize: 24,
        lineHeight: 1.1
      }
    }
  },
  alert: {
    backgroundColor: '#F1F3FD',
    color:'#2C3A98',
    fontFamily:'SF Pro Display',
    fontSize: '30px',
    margin: '30px',
    '& .MuiAlert-message': {
      textAlign: 'center',
      width:'100%'
    }
  },
  btn: {
    backgroundColor: '#3F53D9'
  }
}))

export const InviteFailPage: React.FC = () => {
  const classes = useStyles();

  return (
    <Page forStudent title="Ошибка доступа">
      <Grid item container xs={12} >
          <Paper>
            <Box className={classes.root}>
              <Typography variant="h1">
                Ошибка доступа
              </Typography>
              <Typography variant="body1">
              <Alert icon={false} className={classes.alert} severity='info'>Подарочный доступ уже был активирован</Alert>
              </Typography>
              <Box mb={2} />
              <Button className={classes.btn} color="primary" component={Link} to="/" variant="contained">На главную</Button>
            </Box>
          </Paper>
      </Grid>
    </Page>
  )
}
import * as React from "react";
import { Box, Divider, Grid, } from "@material-ui/core";
import styles from "./styles.module.scss";
import { themeVariables } from "../index";
import { MUITooltip } from "../Tooltip";

interface IProps {
  nolabel?: boolean;
  label?: any;
  smallLabel?: boolean;
  nohint?: boolean;
  hint?: any;
  hintAlign?: "flex-start" | "flex-end" | "center"
  control?: any;
  caption?: string;
  cssModifier?: "lowMargin" | "noMargin" | 'miniMargin';
  children?: any;
  labelClassName?: string;
  tooltipClassName?: string;
  divider?: boolean;
}

export const FormSettingsRow = (props: IProps) => (
  <Box className={`${styles.row} ${props.cssModifier && styles[props.cssModifier]}`}>
    <Grid container>
      {!props.nolabel ? (
        <Grid item xs={12} sm="auto">
          <Box
            className={`${styles.label} ${props.label ? "" : styles.labelEmpty
              } ${props.smallLabel ? styles.labelSmall : ""
              } ${props.labelClassName}`}
          >
            {props.label}
          </Box>
        </Grid>
      ) : null}
      <Grid container item xs={12} sm zeroMinWidth direction="column" className={styles.fields}>
        {props.nohint ? (
          props.children
        ) : (
          <Grid container wrap="nowrap" alignItems={props.hintAlign || "center"}>
            <Box clone alignSelf="center">
              <Grid item xs zeroMinWidth>
                {props.children}
                {props.caption ? (
                  <Box
                    className={styles.caption}
                    color={themeVariables.palette.grey[600]}
                  >
                    {props.caption}
                  </Box>
                ) : null}
              </Grid>
            </Box>
            <Grid item>
              <Box
                className={`${styles.hints} ${props.hint ? "" : styles.hintsEmpty
                  }`}
              >
                {props.hint ? (
                  <MUITooltip placement="top" classes={{ tooltip: props.tooltipClassName }} title={props.hint} />
                ) : null}
                {props.control}
              </Box>
            </Grid>
          </Grid>
        )}
        {props.divider && <Divider className={styles.divider} />}
      </Grid>
    </Grid>
  </Box>
);

import { Box, Grid, makeStyles, Paper, Typography } from "@material-ui/core"
import Page from "../hoc/page"

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    '& p': {
      fontSize: 16,
      lineHeight: 1.2,
    },
    '& h3': {
      fontSize: 24,
      lineHeight: 1.5,
    },
    '& h1': {
      fontSize: 36,
      lineHeight: 1.5
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1),
      '& p': {
        fontSize: 12,
        lineHeight: 1.1,
      },
      '& h3': {
        fontSize: 18,
        lineHeight: 1.2,
      },
      '& h1': {
        fontSize: 24,
        lineHeight: 1.1
      }
    }
  }
}))

export const PrivacyPolicyPage: React.FC = () => {
  const classes = useStyles();

  return (
    <Page forStudent title="SW Политика конфиденциальности">
      <Grid item container xs={12}>
          <Paper>
            <Box className={classes.root}>
              <Typography variant="h1">
                Политика конфиденциальности
              </Typography>
              <Typography variant="body1">
              Настоящая политика обработки персональных данных (далее по тексту - «Политика») Общества с ограниченной ответственностью «Интернет Университет» (далее по тексту — «Оператор») устанавливает объём, цели и способы обработки
персональных данных пользователей (посетителей) сайта https://smartwebinar.org (далее по тексту — «Сайт») и публикуется в открытом доступе в соответствиями с требованиями Федерального закона РФ «О персональных данных» № 152-ФЗ от 27.07.2006 г.
              </Typography>
              <Box mb={2} />
              <Typography variant="h3">
                1. Правовые основания обработки персональных данных
              </Typography>
              <Typography variant="body1">
                Оператор обрабатывает персональные данные в рамках правоотношений с пользователем, урегулированных Гражданским Кодексом Российской Федерации, а также в рамках лицензионного договора-публичной оферты (на предоставление права использования платформой на сайте https://smartwebinar.org), а также согласия на обработку персональных данных, которое дает пользователь, при акцепте лицензионного договора-публичной оферты (на предоставление права использования платформой на сайте https://smartwebinar.org), а также в рамках использования платформы клиентами пользователя. Если вы не согласны с настоящей Политикой, не пользуетесь Сайтами и программным обеспечением Компании.
              </Typography>
              <Box mb={1} />
              <Typography variant="h3">
                2. Основные понятия.
              </Typography>
              <Typography variant="body1">
              В настоящей Политике определены следующие термины, определения и сокращения:
              <ul>
                <li>
                  <b>персональные данные</b> — любая информация, относящаяся к прямо или косвенно определенному, или определяемому физическому лицу (субъекту персональных данных);
                </li>
                <li>
                  <b>обработка персональных данных</b> — любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.
                </li>
                <li>
                  <b>автоматизированная обработка персональных данных</b> – обработка персональных данных с помощью средств вычислительной техники;
                </li>
                <li>
                  <b>распространение персональных данных</b> – действия, направленные на раскрытие персональных данных неопределенному кругу лиц;
                </li>
                <li>
                  <b>предоставление персональных данных</b> – действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц;
                </li>
                <li>
                  <b>блокирование персональных данных</b> – временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных);
                </li>
                <li>
                  <b>уничтожение персональных данных</b> – действия, в результате которых становится невозможным восстановить содержание персональных данных в информационной системе персональных данных и (или) в результате которых уничтожаются материальные носители персональных данных;
                </li>
                <li>
                  <b>обезличивание персональных данных</b> – действия, в результате которых становится невозможным без использования дополнительной информации определить принадлежность персональных данных конкретному субъекту персональных данных.
                </li>
                <li>
                  <b>Автор</b> – ведущий и/или создатель вебинара/автовебинара, который организовал его проведение для зрителелей посредством платформы «Смартвебинар» (https://smartwebinar.org).
                </li>
                <li>
                  <b>Зритель</b> – лицо, которое участвует в вебинаре/автовебинаре на платформе «Смартвебинар» (https://smartwebinar.org) по приглашению Автора и выступает зрителем вебинара/автовебинара.
                </li>
              </ul>

                  <b>Далее, при упоминании совместно или обобщенно Авторы и Зрители именуются – Пользователи (пользователь).</b>

               </Typography>
              <Box mb={1} />
              <Typography variant="h3">
                3. Согласие пользователя на обработку персональных данных
              </Typography>
              <Typography variant="body1">
                Отправляя сообщение через формы обратной связи, размещенные на сайте, акцептуя размещенную оферту, пользователь Сайта выражает свое согласие на обработку персональных данных в определенных настоящей Политикой целях и объеме. 
               </Typography>
              <Box mb={1} />
              <Typography variant="h3">
              4. Цель обработки персональных данных
              </Typography>
              <Typography variant="body1">
                Целью обработки персональных данных является предоставление Пользователям информации об Операторе, в том числе условий предоставления предлагаемых Оператором услуг и прав пользования, заключения договоров, осуществление технической поддержки пользователей и своевременное их информирование обо всех изменениях в продукте, предоставляемом Оператором. Также Оператор обрабатывает персональные данные с целью предоставления функций платформы «Смартвебинар» и исполнения обязательств перед Автором. 
              </Typography>
              <Box mb={1} />
              <Typography variant="h3">
                5. Объем обрабатываемых персональных данных
              </Typography>
              <Typography variant="body1">
              Оператор обрабатывает следующие персональные данные: 
                <ul>
                  <li>
                    Авторов – е-мэил, телефон, имя, ip-адрес и время подключений.
                  </li>
                  <li>
                    Зрителей – объем обрабатываемых персональных данных зависит от того какие данные Автор сделал обязательными для Зрителей при входе на вебинар/автовебинар. По умолчанию обрабатываются только Имя Автора. Кроме него автор может установить е-мэил, телефон, дополнительное поле на усмотрение Автора. Также автоматически могут собираться невидимые данные: город из которого подключился Зритель, название браузера, операционной системы, тип устройства телефон/компьютер, IP-адрес, действия на вебинаре: комментарии, клики по кнопкам, время входа и выхода, рекламные метки несущие информацию по какой рекламе человек кликнул чтобы попасть на вебинар/автовебинар.
                  </li>
                </ul>
              </Typography>
              <Typography variant="body1">
                  Оператор осуществляет обработку персональных данных включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление и уничтожение.
              </Typography>
              <Typography variant="body1">
                  Автор является оператором персональных данных участников вебинаров, учеников курсов и тестов, посетителей мастер-классов, клиентов кассы , полученных с помощью любых функций платформы Смартвебинар (https://smartwebinar.org), и несет полную ответственность за обработку и хранения обозначенных персональных данных.
              </Typography>
              <Typography variant="body1">
                  Автор несет ответственность за распространение персональных данных своих Зрителей, которые воспользовались услугами Автора через функционал Сервиса, третьим лицам без их прямого согласия, если иное не предусмотрено политикой конфиденциальности Пользователя.
              </Typography>
              <Typography variant="body1">
                  Автор может использовать на платформе Смартвебинар (https://smartwebinar.org) интегрированные веб-сервисы третьих лиц (Сторонние веб-сервисы). Сторонние веб-сервисы размещаются Автором в целях повышения эффективности функционирования платформы, пользовательского комфорта и удовлетворенности от опыта взаимодействия с платформой. Автор не предоставляет Сторонним сервисам персональные данные его Зрителей. Автор интегрирует на платформе интерфейс, который позволяет Автору получать непосредственный доступ к Сторонним веб-сервисам. Автор может по собственному усмотрению использовать Сторонние сервисы или отказаться от использования. Сторонние веб-сервисы могут требовать предоставления персональных данных лица, воспользовавшегося им. 
              </Typography>
              <Typography variant="body1">
                  На сайте используется технологии обработки куки (cookie) — это небольшие текстовые файлы, в которые браузер записывает данные с посещенных Пользователями  сайтов. Эти данные служат для сбора информации о действиях Пользователей на сайте, для улучшения качества его содержания и предоставляемых им возможностей. 
              </Typography>
              <Typography variant="body1">
                  В любое время Пользователи могут изменить параметры в настройках своих браузеров таким образом, чтобы браузеры перестали сохранять все файлы cookie, а так же оповещали их об отправке. В этом случае некоторые сервисы и функции сайта могут перестать работать или работать некорректно.
              </Typography>
              <Box mb={1} />
              <Typography variant="h3">
                6. Конфиденциальность персональных данных
              </Typography>
              <Typography variant="body1">
              Оператор не раскрывает третьим лицам и не распространяет персональные данные пользователей сайта без их согласия кроме случаев, предусмотренных федеральным законом.
Оператор вправе передавать персональные данные органам дознания и следствия, иным уполномоченным органам по основаниям, предусмотренным действующим законодательством Российской Федерации.
Оператор при осуществлении хранения использует базы данных, находящиеся на территории Российской Федерации. 
              </Typography>
              <Box mb={1} />
              <Typography variant="h3">
                7. Срок обработки персональных данных
              </Typography>
              <Typography variant="body1">
              Предоставленные Пользователями данные, которые воспользовались функционалом платформы Смартвебинар обрабатываются бессрочно, либо до момента, отзыва своего согласия на обработку персональных данных.
              </Typography>
              <Box mb={1} />
              <Typography variant="h3">
                8. Права субъекта персональных данных
              </Typography>
              <Typography variant="body1">
                Пользователь вправе направить запрос Оператору на получение информации, касающейся обработки его персональных данных в соответствии с требованиями статьи 14 Федерального закона РФ «О персональных данных». 
              </Typography>
              <Typography variant="body1">
              Данный запрос может быть направлен в «Службу технической поддержки». Запрос должен содержать сведения, указанные в ч. 3 ст. 14 ФЗ «О персональных данных».
              </Typography>
              <Box mb={2} />
              <Typography variant="body1">
              Пользователь может подписаться на получение рассылки по электронной почте, если такая возможность предоставляется сайтом. Рассылка может содержать сведения о новостях, аналитических материалах, мероприятиях, проводимых Оператором и тому подобное. Заполняя поле «e-mail», пользователь дает свое согласие на получение таких рассылок. Пользователь в любой момент может отозвать свое согласие на получение рассылки. Возможность отписаться от рассылки предоставляется пользователю в каждом письме.
              </Typography>
              <Typography variant="body1">
              Пользователь имеет право:
              <ul>
                <li>
                на получение персональных данных, относящихся к данному субъекту, и информации, касающейся их обработки;
                </li>
                <li>
                на уточнение, блокирование или уничтожение его персональных данных в случае, если они являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки;
                </li>
                <li>
                на отзыв данного им согласия на обработку персональных данных.
                </li>
              </ul>
              </Typography>
              <Box mb={1} />
              <Typography variant="h3">
                9. Защита персональных данных
              </Typography>
              <Typography variant="body1">
              Оператор принимает меры, необходимые и достаточные для обеспечения выполнения обязанностей, предусмотренных Федеральным законом «О персональных данных» и принятыми в соответствии с ним нормативными правовыми актами. Оператор самостоятельно определяет состав и перечень мер, необходимых и достаточных для обеспечения выполнения таких обязанностей. 
              </Typography>
              <Typography variant="body1">
              Доступ к персональным данным имеют только уполномоченные сотрудники Оператора. Все сотрудники Оператора, имеющие доступ к персональным данным, должны придерживаться политики по обеспечению конфиденциальности и защиты персональных данных. В целях обеспечения конфиденциальности информации и защиты персональных данных Оператор поддерживает соответствующую ИТ-среду и принимает все меры, необходимые для предотвращения несанкционированного доступа.
              </Typography>
              <Box mb={1} />
              <Typography variant="h3">
                10. Контактные данные Оператора.
              </Typography>
              <Typography variant="body1">
              Оператор осуществляет прием сообщений через формы обратной связи, размещенной на сайте https://smartwebinar.org, по электронному адресу: support@antitreningi.ru., а также осуществляет прием письменной корреспонденции по адресу: 115191, г. Москва, пер. Духовской, д. 17 этаж 2 пом. 1 офис 212А.
              </Typography>
              <Box mb={4} />
            </Box>
          </Paper>
      </Grid>
    </Page>
  )
}
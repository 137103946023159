import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing'
import { history } from '../store';

export const SENTRY_HW_CATEGORY = "HW_INFO";
interface SampleDeviceInfo {
  deviceMemory: string;
  cpus: string | number;
}


const defaultConfig: Sentry.BrowserOptions = {
  integrations: [new Integrations.BrowserTracing({
    routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
  })],
  enabled: process.env.SENTRY_DSN ? true : false,
  dsn: process.env.SENTRY_DSN,
  tracesSampleRate: 1.0,

}

export function getHardwareInfo () {
  let info:SampleDeviceInfo = {
    deviceMemory: 'unknown',
    cpus: 'unknown',
  }
  if ('deviceMemory' in navigator) {
    //@ts-ignore
    info.deviceMemory = navigator.deviceMemory;
  }
  if ('hardwareConcurrency' in navigator) {
    info.cpus = navigator.hardwareConcurrency;
  }
  return info;
}


const sentry = (config: Sentry.BrowserOptions = defaultConfig) => Sentry.init(config);


export default sentry;
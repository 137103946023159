import { ITicket } from '../../types/Ticket';

export const TICKETS_FETCH_ATTEMPT = 'TICKETS_FETCH_ATTEMPT';
export const TICKETS_FETCH_SUCCESS = 'TICKETS_FETCH_SUCCESS';
export const TICKET_REMOVE = 'TICKETS_REMOVE';
export const TICKET_CLEAR = 'TICKETS_CLEAR';

export interface ITicketsFetchAttemptAction {
  type: typeof TICKETS_FETCH_ATTEMPT;
}

export interface ITicketsFetchSuccessAction {
  type: typeof TICKETS_FETCH_SUCCESS;
  list: ITicket[];
  count: number;
}

export interface IRemoveTicketAction {
  type: typeof TICKET_REMOVE;
  ticket: ITicket;
}
export interface IClearTicketsAction {
  type: typeof TICKET_CLEAR;
}

export type TTicketsAction =
  | ITicketsFetchAttemptAction
  | ITicketsFetchSuccessAction
  | IRemoveTicketAction
  | IClearTicketsAction;

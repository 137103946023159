import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { RootState } from "..";
import { api } from "../../api";
import { IPresentation } from "../../types";
import { createEvent } from "../stream/actions";
import { IWebinarInitialState } from "../webinar";
import { endPresentationProcessing } from "../webinar/actions";
import { IPresentationCheckAction, PRESENTATION_CHECK_STATUS } from "./types";


export function* fetchPresentationStatus(id: string) {
  while (true) {
    try {
      const resp: IPresentation = yield call(api.presentation.get, id);
      if (!resp.isReady) {
        yield delay(1500);
      } else {
        return resp;
      }
    } catch (error) {
      return error;
    }

  }
}

export function* checkPresentationStatus(action: IPresentationCheckAction) {
  // const presentation: IPresentation = yield call(fetchPresentationStatus, action.id);

  // yield put(endPresentationProcessing(action.id, presentation));
  // if (action.updateStreamOnReady) {
  //   yield call(changePresentation, presentation, 1, true);
  // }
}

const webinarSelector = (state: RootState) => state.webinar;

export function* changePresentation(presentation: IPresentation, presentationPage: number, swapLayout?: boolean) {
  const webinar: IWebinarInitialState = yield select(webinarSelector);
  if (webinar.current) {
    //TODO: overload type with new declaration;
    //@ts-ignore
    yield put(createEvent('presentation', webinar.current.link, { presentation, presentationPage, presentationLink: `/api/presentation/${presentation.id}/${presentationPage}` }));
    if (swapLayout) {
      //@ts-ignore
      yield put(createEvent('layout', webinar.current.link, 'video-br'));
    }
  }
}


export default function* presentationsSaga() {
  yield takeLatest(PRESENTATION_CHECK_STATUS, checkPresentationStatus);
}
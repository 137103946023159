import { Box, TableCell, TableRow, Typography } from "@material-ui/core"
import { format } from "date-fns"
import { IBillingStatMembers } from "../../../../types/Billing"
import { tl } from "../../../../utils"
import UserIcon from "../../../Icon/user"
import { Poster } from "../rowComps/poster"
import { SmartSum } from "../rowComps/smartSum"
import { numberFormat } from "../utils"
import { useStyles } from "./style"


export const MembersRow: React.FC<IBillingStatMembers> = (props) => {
  const classes = useStyles();
  return (
    <TableRow>
      <TableCell width="17%" className={classes.date}>
        {
          format(new Date(), 'dd.MM.yyyy HH:mm')
        }
      </TableCell>
      <TableCell>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Poster type="contacts" />
          <Typography className={classes.textBold}>
            {tl(props.description)}
          </Typography>
        </Box>
      </TableCell>
      <TableCell align="right">
        <Box display="flex" flexDirection="row" flexWrap="nowrap" alignItems="center" justifyContent="flex-end">
          <Typography className={classes.text}>{numberFormat.format(props.unit)}</Typography>
          <Box ml={'5px'} display="flex" alignItems="center" justifyContent="center" width="14px"><UserIcon /></Box>
        </Box>
      </TableCell>
      <TableCell>
        <SmartSum sum={props.amount} />
      </TableCell>
    </TableRow>
  )
}
import { apiClient } from "./_client"
import { IPresentation, IUploadedFile, IWebinar } from "../types";
import { api } from "./index";

export const get = async (id: string): Promise<IPresentation> => {
    const response = await apiClient.get<IPresentation>(`/presentation/${id}`);
    return response.data;
};

export const getAll = async (id: string): Promise<Array<IPresentation>> => {
    const response = await apiClient.get<{ list: Array<IPresentation> }>(`/presentation?webinar=${id}`);
    return response.data.list;
}

export const create = async (webinar: IWebinar, file: IUploadedFile) => {
    const response = await apiClient.post<IPresentation>(`/presentation`, {
        webinar: webinar.id,
        file,
    });
    return response.data;
}

export const upload = async (webinar: IWebinar, presentation: IPresentation, file: File) => {
    const uploadedFile = await api.file.upload(file);
    const response = await apiClient.post<IPresentation>(`/presentation`, {
        webinar: webinar.id,
        id: presentation.id,
        file: uploadedFile,
    });
    return response.data.id;
}

export const remove = async (webinar: IWebinar, presentation: IPresentation): Promise<boolean> => {
    await apiClient.delete(`/presentation/${presentation.id}`, {
        data: {
            webinar: webinar.id
        }
    });
    return true;
}
import { SvgIcon, SvgIconProps } from "@material-ui/core"
import { forwardRef } from "react";


export const PlusFilledIcon: React.FC<SvgIconProps> = forwardRef(({...props}, ref) => {
  return (
    <SvgIcon {...props} viewBox="0 0 18 18" innerRef={ref}>
        <path d="M9,18a9,9,0,1,1,9-9A9.01,9.01,0,0,1,9,18ZM5,8a1,1,0,0,0,0,2H8v3a1,1,0,0,0,2,0V10h3a1,1,0,0,0,0-2H10V5A1,1,0,0,0,8,5V8Z" fill="currentColor"/>
    </SvgIcon>
  );
})

export default PlusFilledIcon;
import * as user from "./user";
import * as webinar from "./webinar";
import * as adslayout from "./ads-layout";
import * as statistics from "./statistics";
import * as scenario from "./scenario";
import * as moderator from "./moderator";
import * as ticket from "./ticket";
import * as integration from "./integration";

import * as file from "./file";
import * as player from "./player";
import * as chat from "./chat";
import * as presentation from './presentation';
import * as report from './report';
import * as stream from './stream';
import * as tasks from './tasks';
import * as billing from './billing';
import * as userReview from './userReview'




export const api = {
  user: user,
  webinar: webinar,
  adsLayout: adslayout,
  statistics: statistics,
  scenario: scenario,
  moderator: moderator,
  integration:integration,
  presentation: presentation,
  chat: chat,
  file: file,
  player: player,
  report: report,
  stream: stream,
  ticket: ticket,
  tasks: tasks,
  userReview: userReview,
  billing
};


import { AppThunk } from '..';
import { api } from '../../api';
import {
  EWebinarEvents,
  EWebinarType,
  IWebinar,
  IWebinarEvent,
} from '../../types';
import { addNotification } from '../notifications/actions';
import { IEventsInitialState } from './index';
import {
  EVENTS_FETCH_ATTEMPT,
  EVENTS_FETCH_FAILURE,
  EVENTS_FETCH_SUCCESS,
  EVENT_REMOVE,
  IEventsFetchAttemptAction,
  IEventsFetchFailureAction,
  IEventsFetchSuccessAction,
  IRemoveEventAction,
  EVENTS_RELOAD,
  IEventsReloadAction,
  IAddEventAction,
  EVENT_ADD,
  ISaveEventAction,
  EVENT_SAVE,
  IEventsFetchStatusChangeAction,
  EVENTS_FETCH_STATUS_CHANGE,
} from './types';
import { webinarsListUpdate } from "../webinars/actions";
import { getWebinarSuccess } from "../webinar/actions";

export const selectSortedEvents = (state: IEventsInitialState) => {
  return state.events.slice().sort((a, b) => a.time - b.time);
};

export const selectMinTime = (state: IEventsInitialState) => {
  return state.events.length > 0 ? selectSortedEvents(state)[0].time : 0;
};

export const selectMaxTime = (state: IEventsInitialState) => {
  const sortedEvents = selectSortedEvents(state);
  return state.events.length > 0
    ? sortedEvents[sortedEvents.length - 1].time
    : 0;
};

export function eventsFetchAttempt(): IEventsFetchAttemptAction {
  return {
    type: EVENTS_FETCH_ATTEMPT,
  };
}

export function eventsChangeFetchStatus(status: boolean): IEventsFetchStatusChangeAction {
  return {
    type: EVENTS_FETCH_STATUS_CHANGE,
    status
  }
}

export function eventsFetchSuccess(
  events: IWebinarEvent[],
  count: number
): IEventsFetchSuccessAction {
  return {
    type: EVENTS_FETCH_SUCCESS,
    events,
    count,
  };
}

export function eventsFetchFailure(): IEventsFetchFailureAction {
  return {
    type: EVENTS_FETCH_FAILURE,
  }
}

export function fetchEvents(
  webinar: IWebinar,
  offset: number,
  limit: number
): AppThunk {
  return async (dispatch) => {
    dispatch(eventsFetchAttempt());
    try {
      const response =
        webinar.type === EWebinarType.AUTOWEBINAR
          ? await api.scenario.fetch(webinar, offset, limit)
          : await api.stream.history(webinar.stats.id, offset, limit);
      if (response.meta.isReady) {
        dispatch(
          eventsFetchSuccess(response.list, response.paginator.countItems)
        );
      } else {
        dispatch(eventsChangeFetchStatus(false))
      }
    } catch (error) {
      dispatch(clearEvents());
      dispatch(eventsFetchFailure());
      dispatch(addNotification('error', 'Произошла ошибка'));
    }
  };
}


export function saveEvent(event: IWebinarEvent): ISaveEventAction {
  return {
    type: EVENT_SAVE,
    event,
  };
}

export function fetchSaveEvent(event: IWebinarEvent, webinar: IWebinar): AppThunk {
  return async (dispatch) => {
    try {
      const response = await api.scenario.save(event);
      dispatch(saveEvent(response));
      const newWebinar = {
        ...webinar,
        startPage: {
          ...webinar.startPage,
          openTime: Math.abs(event.time / 60)
        }
      }
      dispatch(webinarsListUpdate(newWebinar))
      dispatch(getWebinarSuccess(webinar));
    } catch (error) {
      dispatch(addNotification('error', 'Произошла ошибка'));
    }
  };
}

export function removeEvent(event: IWebinarEvent): IRemoveEventAction {
  return {
    type: EVENT_REMOVE,
    event,
  };
}

export function fetchRemoveEvent(event: IWebinarEvent): AppThunk {
  return async (dispatch) => {
    try {
      await api.scenario.remove(event);
      dispatch(removeEvent(event));
    } catch (error) {
      dispatch(addNotification('error', 'Произошла ошибка'));
    }
  };
}

export function reloadEvents(): IEventsReloadAction {
  return {
    type: EVENTS_RELOAD,
  };
}

export function clearEvents(): AppThunk {
  return async dispatch => {
    dispatch(reloadEvents());
  }
}

export function fetchRemoveMessageEvents(webinar: IWebinar): AppThunk {
  return async (dispatch) => {
    try {
      await api.scenario.removeByType(webinar, EWebinarEvents.MESSAGE);
      dispatch(reloadEvents());
      dispatch(fetchEvents(webinar, 0, 20));
    } catch (error) {
      dispatch(addNotification('error', 'Произошла ошибка'));
    }
  };
}

export function addEvent(event: IWebinarEvent): IAddEventAction {
  return {
    type: EVENT_ADD,
    event,
  };
}

export function fetchAddEvent(
  webinar: IWebinar,
  eventName: string,
  params: any
): AppThunk {
  return async (dispatch) => {
    try {
      const response = await api.scenario.add(webinar, eventName, params);
      dispatch(addEvent(response));
    } catch (error) {
      dispatch(addNotification('error', 'Произошла ошибка'));
    }
  };
}

import { Box, PopoverProps, Popover, styled, BoxProps } from '@material-ui/core';
import {SvgIcon} from '../../SvgIcon';
import React, { useCallback, useState } from 'react';

const Wrap = styled(Box)(({theme})=>({
  padding: theme.spacing(1,0),
  minWidth: 226,
  border: '1px solid #D3D9DE',
  borderRadius:theme.shape.borderRadius,
  '& hr': {
    margin: theme.spacing(0.5,2.5),
  }
}))

interface ContextMenuProps {
  boxProps?: BoxProps;
  popoverProps?: PopoverProps;
  icon?: string | React.ReactElement;
}

export const ContextMenu: React.FC<ContextMenuProps> = ({popoverProps, boxProps, icon = 'points-menu', children}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
   <>
    <Box
      px={0.75}
      aria-owns={Boolean(anchorEl) ? "mouse-over-popover" : undefined}
      aria-haspopup="true"
      onClick={handlePopoverOpen}
      // className={classes.cursorPointer}
      {...boxProps}
    >
      {
        typeof icon === 'string' ? 
          <SvgIcon type={icon} /> :
          icon
      }
    </Box>
    <Popover
      id="mouse-over-popover"
      open={Boolean(anchorEl)}
      PaperProps={{
        elevation: 0,
        style: {
          boxShadow: '0px 3px 6px #00000029',
        }
      }}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      onClose={handlePopoverClose}
      disableRestoreFocus
    >
      <Wrap>
      {React.Children.map(children, (child) => {
          //@ts-ignore
          return React.cloneElement((child), {onClose: handlePopoverClose})
        })
      }
      </Wrap>
  </Popover>
   </>
  )
}
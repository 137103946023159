import { Box, Typography } from '@material-ui/core';
import React from 'react'
import { MUIDialog } from '../../MUI';
import { tl } from '../../utils';

interface StartStopWebinarModalProps {
  open: boolean;
  onClose: () => void;
  isStarted: boolean;
  onChangeStatus: (status: boolean) => void;
}

export const StartStopWebinarModal: React.FC<StartStopWebinarModalProps> = ({ 
  open, onChangeStatus, onClose, isStarted
}) => {

  return (
    <MUIDialog
      title={
        !isStarted ? tl('Запуск трансляции') : tl('Завершение трансляции')
      }
      open={open}
      mobileFullScreen
      maxWidth="xs"
      actions={[
        [],
        [
          {
            label: tl('Отмена'),
            btnProps: {
              onClick: () => {
                onClose();
              },
            },
          },
          {
            label: !isStarted ? tl('Выйти в эфир') : tl('Завершить'),
            btnProps: {
              'data-testid': 'confirmBtn',
              color: 'primary',
              onClick: () => {
                onChangeStatus &&
                  onChangeStatus(Boolean(!isStarted));
              },
            },
          },
        ],
      ]}
    >
      <Box>
        <Typography>
          {!isStarted
            ? tl('Вы уверены, что хотите начать вебинар?')
            : tl('Вы уверены, что хотите завершить вебинар?')}
        </Typography>
      </Box>
    </MUIDialog>
  )
}

export default StartStopWebinarModal;
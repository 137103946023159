import { EWebinarType } from "./Webinar";

export const enum EBillingType {
  BALANCE = 'BALANCE',
  CHARGE = 'CHARGE',
  STREAM = 'STREAM',
  MEMBERS = 'MEMBERS',
}





export interface IBillingStatCommon {
  type: EBillingType;
  amount: number;
  date: string;
  description: string;
  id: string;
  unit: number;

}

export interface IBillingStatMembers extends IBillingStatCommon {
  type: EBillingType.MEMBERS;

}

export interface IBillingStatBalance extends IBillingStatCommon {
  type: EBillingType.BALANCE | EBillingType.CHARGE;

}

export interface IBillingStatStream extends IBillingStatCommon {
  type: EBillingType.STREAM
  webinar: {
    type: EWebinarType
    name: string
    poster?: string
  }
  summary?: {
    start: number
    members: number
  }
}


export type IBillingStat =  IBillingStatStream | IBillingStatBalance | IBillingStatMembers

export interface IPlanMeta {
    bonus: {
      'at-author': number;
      registartion: number;
    },
    cost:{
      autowebinar:{
        member: number;
        start: number;
      }
      webinar:{
        member: number;
      }
    }
}

export interface IPlan {
  price: number;
  smart: number;
  bonus: number;
}
import { apiClient, IListResponse } from "./_client"
import { IWebinar, IModerator } from "../types";
import { ITask } from "../types/Queue";

export const get = async (id: string): Promise<IModerator> => {
  const response = await apiClient.get<IModerator>('/ticket/' + id);
  return response.data;
}

export const list = async (webinarId: string, offset: number, limit?: number): Promise<IListResponse<IModerator>> => {
  const response = await apiClient.get<IListResponse<IModerator>>(`/ticket?webinar=${webinarId}`, {
    params: {
      role: 'moderator',
      offset: offset,
      limit: limit
    }
  });
  return response.data;
}

export const create = async (webinar: IWebinar, moderators: Partial<IModerator>[], role: 'member' | 'moderator'): Promise<ITask> => {
  const res =  await apiClient.post(`/ticket?webinar=${webinar.id}`, {...moderators, role});
  return res.data;
}

export const importFile = async (webinar: IWebinar, file: File): Promise<ITask> => {
  const formData = new FormData();
  formData.append('file', (file as Blob));
  formData.append('role', 'moderator');
  const res = await apiClient.post(`/ticket?webinar=${webinar.id}`, formData);
  return res.data;
}

export const save = async (moderator: IModerator): Promise<IModerator> => {
  const response = await apiClient.put<IModerator>(`/ticket/${moderator.id}`, moderator);
  return response.data as IModerator;
}

export const remove = async (moderator: IModerator): Promise<boolean> => {
  await apiClient.delete<IModerator>(`/ticket/${moderator.id}`);
  return true;
}
export const resetPassword = async (moderator: IModerator): Promise<boolean> => {
  await apiClient.post<IModerator>(`/ticket/${moderator.id}/password`);
  return true;
}

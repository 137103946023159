import { makeStyles, SvgIcon, SvgIconProps } from "@material-ui/core"
import { forwardRef } from "react";

const useStyles = makeStyles({
  color: {
    color: (props: {color: TColor}) => props.color === 'warning' ? '#f58814' : '#E35248',
  }
})

type TColor = 'warning' | 'error'

export const AlarmIcon: React.FC<Omit<SvgIconProps, 'color'> & {color: TColor}> = forwardRef(({color, ...props}, ref) => {
  const classes = useStyles({color})
  return (
    <SvgIcon {...props} viewBox="0 0 20 20" ref={ref} className={[classes.color, props.className].join(' ')}>
      <g id="alarm_orange" data-name="alarm orange" transform="translate(-0.398)">
      <g id="Сгруппировать_27512" data-name="Сгруппировать 27512">
        <path id="Контур_10249" data-name="Контур 10249" d="M9,0A9,9,0,1,1,0,9,9,9,0,0,1,9,0Z" transform="translate(1.398 1)" fill="currentColor"/>
        <path id="Контур_880" data-name="Контур 880" d="M3.686-10.569,3.5-3.923H1.667l-.179-6.646ZM1.41-1.134a1.12,1.12,0,0,1,.336-.858,1.281,1.281,0,0,1,.913-.316,1.266,1.266,0,0,1,.913.316,1.107,1.107,0,0,1,.336.858,1.1,1.1,0,0,1-.336.851A1.266,1.266,0,0,1,2.66.034a1.281,1.281,0,0,1-.913-.316A1.1,1.1,0,0,1,1.41-1.134Z" transform="translate(7.672 15.354)" fill="#fff"/>
      </g>
    </g>
    </SvgIcon>
  );
})

export default AlarmIcon;
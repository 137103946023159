import { Avatar, AvatarProps, styled } from '@material-ui/core';
import React from 'react';
import SvgIcon from '../SvgIcon';

type TUserAvatarSize = 'small' | 'medium';

interface UserAvatarProps {
  size: TUserAvatarSize;
}

const UserAvatar = styled(
  ({
    size,
    ...other
  }: UserAvatarProps & Omit<AvatarProps, keyof UserAvatarProps>) => (
    <Avatar {...other} />
  )
)({
  width: (props: UserAvatarProps) => (props.size === 'small' ? 28 : 40),
  height: (props: UserAvatarProps) => (props.size === 'small' ? 28 : 40),
  backgroundColor: '#EEF2F6',
});

interface UserpicProps {
  size?: TUserAvatarSize;
  src?: string;
}

export const Userpic = (props: UserpicProps) => (
  <UserAvatar size={props.size || 'medium'} alt="avatar" src={props.src}>
    {!props.src && <SvgIcon type="sw-user" />}
  </UserAvatar>
);

import { tl } from "../../../utils";

const validationLevels = ['empty', 'invalid', 'minlength', 'weak', 'good', 'valid'] as const;
export type TValidationLevel = typeof validationLevels[number];

type TValidationLevesDict = {
  [key in TValidationLevel]: string;
};

export const validationColorMap: TValidationLevesDict = {
  valid: '#2F90E0',
  empty: '#b6b6b6',
  invalid : '#E35248',
  good: '#30AB59',
  minlength: '#E35248',
  weak: '#F58814',
}

export const validationDictMap : TValidationLevesDict = {
  valid: tl('Превосходный'),
  empty: '',
  invalid : tl('Введите больше 8 символов'),
  good: tl('Хороший пароль'),
  minlength: tl('Введите больше 8 символов'),
  weak: tl('Слабоватый пароль'),
}

const capitalLettersRegEx = /[A-Z]/;
const numbersRegex = /[0-9]/;
const lettersRegex = /[a-z]/;
const specCharactersRegEx = /[^A-Za-z0-9]/;


export type NewPasswordValidatorResult = {
  level: TValidationLevel, 
  failed: Array<string>
}
export function newPasswordValidator(password: string): NewPasswordValidatorResult {
 
  const tests = {
    capital: capitalLettersRegEx.test(password),
    minlength: password.length >= 8,
    numbers: numbersRegex.test(password) && lettersRegex.test(password),
    specialCharacters: specCharactersRegEx.test(password),
  }
  const testResultValues = Object.entries(tests);
  const validResults = testResultValues.filter(test => !!test[1]);
  const invalidTests = testResultValues.filter(test => !test[1]).map(test => test[0]);
  if (password.length < 8) {
    return {
      level: 'minlength',
      failed: invalidTests,
    };
  }
  if (validResults.length === 0) {
    return {
      level: 'weak',
      failed: invalidTests,
    };;
  }

  if (validResults.length > 0 && validResults.length < testResultValues.length ) {
    return {
      level: 'good',
      failed: invalidTests,
    };;
  }

  if(validResults.length === testResultValues.length) {
    return {
      level: 'valid',
      failed: invalidTests,
    };;
  }
  return {level: 'empty', failed: []};
}
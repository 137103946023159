import * as React from "react";
import {Grid} from "@material-ui/core";
import styles  from "./styles.module.scss";

import {INav, Menu} from "./Menu";
import {GridContainer} from "../index";

interface IProps {
  navList?: INav[][];
  children?: any;
}

export const MUISidebar = ({navList, children}: IProps) => {
  return (
    <Grid item className={styles.sidebar}>
      <GridContainer spacing={1} className="MuiGridContainer">
        {navList && navList.map((nav: INav[], idx: number) => {
          return nav !== null ? (
            <Grid item xs={12} key={`sidebar-nav-${idx}`}>
              <Menu nav={nav}></Menu>
            </Grid>
          ) : null;
        })}
        {children}
      </GridContainer>
    </Grid>
  );
};

import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React, { forwardRef } from 'react';

export const SuccessIcon: React.FC<SvgIconProps> = forwardRef(({...props}, ref) => {
  return (
    <SvgIcon width={18} height={18} {...props} viewBox="0 0 18 18" innerRef={ref}>
      <path id="Контур_10117" data-name="Контур 10117" d="M9,0A9,9,0,1,1,0,9,9,9,0,0,1,9,0Z" fill="#30ab59"/>
      <path id="Контур_10116" data-name="Контур 10116" d="M-20068.277,8056.485l-2.119-2.121a.994.994,0,0,1,0-1.415.991.991,0,0,1,1.41,0l1.416,1.413,4.24-4.244a1.007,1.007,0,0,1,1.416,0,1,1,0,0,1,0,1.416l-4.947,4.951a1,1,0,0,1-.709.291A1,1,0,0,1-20068.277,8056.485Z" transform="translate(20075.156 -8044.362)" fill="#fff"/>
    </SvgIcon>
  );
})
import { Box, styled } from '@material-ui/core';


export const GradientScreenMask = styled(Box)(({theme}) => ({
  background: 'linear-gradient(#000, #000 23%, rgba(0, 0, 0, 0) 33%, rgba(0,0,0,0) 35%, #000 65%)',
  position: 'absolute',
  height: '100%',
  width: '100%',
  top: 0,
  right: 0,
  display: 'flex',
  // maxWidth: 1280,
  bottom: 0,
  left: 0,
  '&.small': {
    background: 'linear-gradient(#000, #000 32%, rgba(0, 0, 0, 0) 45%, rgba(0,0,0,0) 56%, #000 65%)',
  }
}));

export default GradientScreenMask;
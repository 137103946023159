import { Box, Button, Divider, Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useStyles } from './styles';
import SmartCurrencyIcon from '../../components/Icon/smartCurrency';
import { PayOption } from '../../components/Plans/payOption';
import useAppSelector from '../../hooks/useAppSelector';
import { MUIPreloader } from '../../MUI';
import { EAuthStatus } from '../../types';
import { tl } from '../../utils';
import { setupBaseUrl } from '../../api/_client';



export const ExternalPlansPage: React.FC = () => {
  const classes = useStyles();
  const {plans, user} = useAppSelector(store => ({
    plans: store.plans,
    user: store.user,
  }));
  useEffect(() => {
    document.body.style.setProperty('background-color', 'transparent', 'important');
  }, []);

  const handlePayOptionClick = async (price: number) => {
    if (user.status === EAuthStatus.AUTHORIZED) {
      window.open(
        `${setupBaseUrl()}/billing/pay?price=${price}`
      )
      return;
    }
    window.top?.postMessage('pay-0', '*')
  }

  const handleRegClick = () => {
    window.top?.postMessage(`pay-${user.status === EAuthStatus.AUTHORIZED ? 1 : 0}`, '*')
  }

  if (plans.loading) {
    return <MUIPreloader />
  }
  return (
      <Box mx={2}>
      {plans.isPaying &&
        <div className={classes.payOverlay}>
          <MUIPreloader />
          <p>
            {tl('Формируем ссылку для оплаты')}
          </p>
        </div>
      }
      <Box width="100%" display="flex" flexDirection='column' alignItems='center'>
        <div className={classes.root}>
          {user.status === EAuthStatus.AUTHORIZED 
            ? <>
                <h1 className={classes.header}>
                  ВАШ БАЛАНС:
                </h1>
                <span className={classes.header}>300 000 <SmartCurrencyIcon fontSize='inherit' /></span>
              </>
            : <>
                <h1 className={classes.header} style={{marginTop: 40}}>
                  Тарифы и оплата
                </h1>
                <p className={classes.regText}>
                  Смарт <SmartCurrencyIcon /> - условная единица. <br />
                  При регистрации начисляется <b>200 </b><SmartCurrencyIcon />
                </p>
              </>
           }

          <h3 className={classes.head2}>Тарификация</h3>
          <div className={classes.biling}>
            <div className={classes.row}>
              <div>Вебинар</div>
              <div className={classes.bilingRowDivider} />
              <div>
                <div className={classes.bilingDesc}><span>{plans.meta?.cost.webinar.member}</span> <SmartCurrencyIcon/> за зрителя</div>
              </div>
            </div>
            <div className={classes.row}>
              <div>Автовебинар</div>
              <div className={classes.bilingRowDivider} />
              <div className={classes.column}>
                <div className={classes.bilingDesc}><span>{plans.meta?.cost.autowebinar.member}</span> <SmartCurrencyIcon /> за зрителя</div>
                <div className={classes.bilingDesc}><span>{plans.meta?.cost.autowebinar.start}</span> <SmartCurrencyIcon/> за старт</div>
              </div>
            </div>
          </div>
          <h3 className={classes.head2}>Оплата</h3>
          <h5 className={classes.head5}>Чем больше Смартов оплачиваете, тем дешевле обходится зритель на вебинаре.</h5>

          <Grid item container direction="column"  alignItems='center' spacing={2}>
            
            <div className={classes.optionsHead}>
              <div>Оплачиваете</div>
              <div>Бонус</div>
              <div>Зачисляется</div>
            </div>
            {
              plans.list.map((option, idx) => <PayOption onClick={handlePayOptionClick} option={option} key={idx} />)
            }
          </Grid>
          { user.status !== EAuthStatus.AUTHORIZED
            ? <Button variant='contained' color="primary" className={classes.regBtn} onClick={handleRegClick}>
                Зарегистрироваться
              </Button>
            : <>
                <Divider style={{width: '100%'}} />
                <p className={classes.sub}>
                  Нажимая «Оплатить», вы соглашаетесь с условиями <a className={classes.offerLink} href="/offer" target='_blank' rel="norefferrer">Оферты</a>
                </p>
              </>
          }

          <div className={classes.contactBox}>
            <h6 className={classes.head6}>Есть вопросы по оплате?</h6>
            <a className={classes.contactLink} href="tel:+74997030168">+7 (499) 703-01-68</a>
            <a className={classes.contactLink} href="tel:+78007775162">8 (800) 777 51 62</a>
            <Divider style={{width: '100%'}} />
            <a className={classes.contactLink} href="mailto:support@smartwebinar.org">support@smartwebinar.org</a>
          </div>

        </div>
      </Box>
      </Box>
  );
}
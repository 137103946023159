import { Box, TableCell, TableRow, Typography } from "@material-ui/core"
import { format } from "date-fns"
import { IBillingStatCommon } from "../../../../types/Billing"
import { tl } from "../../../../utils"
import { Poster } from "../rowComps/poster"
import { SmartSum } from "../rowComps/smartSum"
import { numberFormat } from "../utils"
import { useStyles } from "./style"



export const BalanceRow: React.FC<IBillingStatCommon> = (props) => {
  const classes = useStyles();

  return (
    <TableRow>
      <TableCell width="17%" className={classes.date}>
        {
          format(new Date(props.date), 'dd.MM.yyyy HH:mm')
        }
      </TableCell>
      <TableCell>
        <Box display="flex" alignItems="center">
          <Poster type="balance" />
          <Typography className={classes.textBold}>{tl(props.description)}</Typography>
        </Box>
      </TableCell>
      <TableCell align="right">
        <Box className={classes.textBold} display="flex" flexDirection="row" flexWrap="nowrap" alignItems="center" justifyContent="flex-end">
          {props.unit && 
            <>
              <Typography className={classes.textBold}>{numberFormat.format(props.unit)}</Typography>
              <Box ml={'5px'} width="14px" textAlign="left">₽</Box>
            </>
          }
        </Box>
      </TableCell>
      <TableCell>
        <SmartSum sum={props.amount} />
      </TableCell>
    </TableRow>
  )
}
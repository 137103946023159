import * as React from "react";
import styles  from "./styles.module.scss";

interface IProps {
  type: string;
  modifier?: string;
  width?: string;
  height?: string;
  notimage?: boolean;
  title?: string;
  hover?: "opacity" | "opacityInversed";
  onClick?: () => void;
}

export class SvgIcon extends React.Component<IProps> {
  render() {
    const attrs = {
      width: this.props.width,
      height: this.props.height,
      className: `svgicon svgicon-${this.props.type} ${
        this.props.modifier ? this.props.modifier : ""
      } ${
        this.props.hover === "opacity"
          ? styles.opacity
          : this.props.hover === "opacityInversed"
          ? styles.opacityInversed
          : ""
      }`,
      title: this.props.title,
    };

    return (
      <>
        {this.props.notimage ? (
          <i {...attrs} onClick={this.props.onClick}/>
        ) : (
          <i onClick={this.props.onClick} {...attrs} />
        )}
      </>
    );
  }
}
export default SvgIcon

import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from "redux";
import { RootState } from "..";
import { Subscription, Centrifuge, ConnectionTokenContext, PublicationContext } from 'centrifuge'
import { EReportStatus, IPresentation, IUser, IWebinar } from "../../types";
import {
    adChange,
    adsListChange,
    layoutChange,
    membersAvailableUpdate,
    membersOnlineUpdate,
    presentationChange,
    sourceChange, startTimeChange,
    streamEnd, streamOpen,
    streamStart
} from "../stream/actions";
import { addChatNotification, addNotification, } from "../notifications/actions";
import { apiClient } from "../../api/_client";
import { CLOSE_CONNECTION, STREAM_AUTHOR_CONNECT, STREAM_MEMBER_CONNECT, TStreamConnectActions, WS_CONNECT, WS_SUBSCRIBE } from './types';
import { chatSettingsChange, playerClose, playerLogout, playerUnblock, redirectLinkChange, updateSplashScreen } from "../player/actions";
import { chatMessagesChange, endPresentationProcessing, setPresentationsList } from "../webinar/actions";
import { updateBalance } from "../user/actions";
import { push } from "connected-react-router";
import { fetchEvents } from "../events/actions";
import { fetchStatisticsMembers, updateReportExportStatus, updateReportStatus } from "../statistics/actions";
import fscreen from "fscreen";
import { setWebinarForReview } from "../app/actions";

import { makeReady } from "../tasks/actions";

function getToken(url: string, ctx: {id?: string, webinarLink?: string}) : Promise<string> {
    return new Promise((resolve, reject) => {
        let data  = undefined;
        let config  = undefined;
        if (ctx.webinarLink) {
            config = {
                params: {
                    link: ctx.webinarLink
                }
            };
            data = {
                id: ctx.id
            }
        }
        apiClient.post(url, data, config)
        .then((res: any) => {
            if (res.status !== 200) {
                throw new Error(`Unexpected status code ${res.status}`);
            }
            return res.data;
        })
        .then(data => {
            resolve(data.token);
        })
        .catch(err => {
            reject(err);
        });
    });
}

export default function streamWSMiddleware(): Middleware {

    let cf: Centrifuge;
    const subscriptions: Array<Subscription> = [];
    let interval: number;
    let currentInterval: number;
    let webinarLinkCurrent: string;

    async function getMembers(webinarLink: string, store: MiddlewareAPI<Dispatch<AnyAction>, RootState>) {
        try {
            const response = await apiClient.get(`/player/${webinarLink}/members`)
            const data = await response.data
            store.dispatch(membersOnlineUpdate(data.online))
            updateMembers(data.updateInterval, webinarLink, store)
        } catch (error) {
            clearInterval(interval);
            store.dispatch(addNotification('error', 'Ошибка обновления количества зрителей'));
        }
    }

    async function subscribe(channel: string, store: MiddlewareAPI<Dispatch<AnyAction>, RootState>, isAuthor?: boolean) {
        if (!cf) {
            return;
        }
        function messageReducer (ctx: PublicationContext) {
            const params = ctx.data.params;
            switch (ctx.data.event) {
                case 'webinar:finish':
                    const finished = params as IWebinar;
                    store.dispatch<any>(setWebinarForReview(finished));
                    break;
                case 'open':
                    store.dispatch(streamOpen(params))
                    break;
                case 'start':
                    store.dispatch(streamStart(params))
                    break;
                case 'close':
                    store.dispatch<any>(playerLogout());
                    store.dispatch(playerClose());
                    break;
                case 'end':
                    store.dispatch(redirectLinkChange(params))
                    store.dispatch(streamEnd());
                    clearStreamSubscription();
                    clearInterval(interval);
                    // cf.disconnect();
                    if (fscreen.fullscreenElement) {
                        fscreen.exitFullscreen();
                    }
                    store.dispatch<any>(playerLogout())
                    break;
                case 'ads':
                    store.dispatch(adChange(params))
                    break;
                case 'layout':
                    store.dispatch(layoutChange(params))
                    break;
                case 'presentation':
                    const { presentations, ...streamParams } = params
                    store.dispatch(presentationChange(streamParams))
                    presentations && store.dispatch(setPresentationsList(presentations))
                    break;
                case 'presentation-ready':
                    const { presentation }: { presentation: IPresentation, webinarId: string } = params;
                    store.dispatch(endPresentationProcessing(presentation.id, presentation.pages));
                    break;
                case 'ticket-import': {
                        const { webinar, id }: { webinar: { id: string }, id: string } = params;
                        const current = store.getState().webinar.current;
                        if (current && current.id === webinar.id) {
                            store.dispatch(makeReady(id, 'tickets'));
                        }
                        break;
                    }
                    case 'scenario-import': {
                    const { webinar }: { webinar: { id: string } } = params;
                    const current = store.getState().webinar.current;
                    if (current && current.id === webinar.id) {
                        //@ts-ignore
                        store.dispatch(fetchEvents(current, 0, 20));
                    }
                    break;
                    }
                case 'membersOnline':
                    store.dispatch(membersOnlineUpdate(parseInt(params)))
                    break;
                case 'membersAvailable':
                    store.dispatch(membersAvailableUpdate(parseInt(params)));
                    break;
                case 'video':
                case 'update-source':
                    store.dispatch(sourceChange(params))
                    break;
                case 'update-start-time':
                    store.dispatch(startTimeChange(Date.parse(params)))
                    break;
                case 'update-redirect-link':
                    store.dispatch(redirectLinkChange(params))
                    break;
                case 'update-ads':
                    store.dispatch(adChange(params))
                    break;
                case 'update-ads-list':
                    store.dispatch(adsListChange(params))
                    break;
                case 'update-splash-screen':
                    store.dispatch(updateSplashScreen(params))
                    break;
                case 'statistics:export':
                    store.dispatch(updateReportExportStatus(params.status, params.reportId));
                    break;
                case 'statistics:report': {
                    const { current } = store.getState().webinar;
                    const { id } = params;
                    if (current && current.stats.id === id) {
                        store.dispatch(
                            //@ts-ignore
                            fetchStatisticsMembers(id, 10, 0, {
                                query: '',
                                sortBy: 'name',
                                sortOrder: 'asc',
                            })
                        );
                        store.dispatch(updateReportStatus(EReportStatus.DONE));
                    }
                    break;
                }
                case 'update-chat-settings': {
                    const player = store.getState().player

                    player.player
                        ? store.dispatch(chatSettingsChange(params))
                        : store.dispatch(chatMessagesChange(params))
                    store.dispatch(chatSettingsChange(params))
                    break;
                }
                case 'update-presentations-list':
                    // список доступных презентаций
                    break;
                case 'alert':
                    store.dispatch(addChatNotification(params.message))
                    break;
                case 'update-balance':
                    store.dispatch(updateBalance(params));
                    break;
                case 'member-login':
                    const { member, player } = store.getState().player
                    if (member && player) {
                        const ids: Array<string> = params;
                        const idx = ids.findIndex(val => val === member.id);
                        if (idx > -1) {
                            setTimeout(() => {
                                clearStreamSubscription(`webinar:${player.link}-noseats`)
                                store.dispatch(push(`/${player.link}`));
                                //@ts-ignore
                                store.dispatch(playerUnblock());
                            }, 100 * idx)
                        }
                    }
                    break;
                default:
                    break;
            }
        }
        let sub = cf.getSubscription(channel)
        if (!sub) {
            sub = cf.newSubscription(channel)
            sub.subscribe()
        }
        sub.on('publication', messageReducer)
        sub.on('error', (ctx) => {
            store.dispatch(addNotification('error', 'Ошибка события в трансляции, перезагрузите страницу'))
        })
        subscriptions.push(sub)
    }


    async function updateMembers(time: number, webinarLink: string, store: MiddlewareAPI<Dispatch<AnyAction>>) {

        if (interval && time === currentInterval) {
            return;
        }
        clearInterval(interval);
        interval = window.setInterval(
            () => getMembers(webinarLink, store),
            time * 1000
        );
        currentInterval = time;
    }

    async function initCentrifuge(store: MiddlewareAPI<Dispatch<AnyAction>, RootState>, user: IUser, webinarLink?: string) {
        if (!cf) {
            try {
                cf = new Centrifuge([
                    {
                        endpoint: `wss://${process.env.REACT_APP_SOCKETBASEPATH}/connection/websocket`,
                        transport: 'websocket'
                    },
                    {
                        transport: 'http_stream',
                        endpoint: `https://${process.env.REACT_APP_SOCKETBASEPATH}/connection/http_stream`
                    },
                ], {
                    data: {
                        webinarLink: webinarLinkCurrent,
                        id: user.id
                    },
                    token: user.wsToken || user.token,
                    getToken: function (ctx) {

                        return getToken('/centrifugo/token/refresh', {
                            webinarLink: webinarLinkCurrent,
                            id: user.id
                        });
                    },
                    debug: false,
                    
                });

                cf.connect()
                cf.on('error', (ctx) => {
                    store.dispatch(addNotification('error', 'Произошла ошибка подключения к вебсокету, перезагрузите страницу'));
                })
                cf.on('disconnected', (ctx) => console.log(ctx))

            } catch (error) {
                console.error(error);
                store.dispatch(addNotification('error', 'Произошла ошибка подключения к вебсокету, перезагрузите страницу'));
            }
        }


        // clearStreamSubscription();
    }

    function clearStreamSubscription(channel?: string) {
        if (channel) {
            const sub = subscriptions.find((s) => s.channel === channel);
            if (sub) {
                sub.removeAllListeners();
                sub.unsubscribe();
            }
        } else {
            subscriptions.forEach((sub) => {
                if (sub) {
                    sub.removeAllListeners();
                    sub.unsubscribe();
                }
            })
        }
    }

    return store => next => (action: TStreamConnectActions) => {
        switch (action.type) {
            case WS_CONNECT:
                if (action.channel) {
                    webinarLinkCurrent = action.channel;
                }
                initCentrifuge(store, action.user, action.channel)
                break;
            case WS_SUBSCRIBE:
                subscribe(action.channel, store,)
                break;
            case STREAM_AUTHOR_CONNECT:
                initCentrifuge(store, action.user, action.webinarLink);
                if (action.updateOnlineMembers) {
                    updateMembers(1, action.webinarLink, store);
                }
                break;
            case STREAM_MEMBER_CONNECT:
                initCentrifuge(store, action.user, action.webinarLink);
                break;
            case CLOSE_CONNECTION:
                clearInterval(interval);
                clearStreamSubscription(action.channel);
                break;
            default:
                next(action)
                break;
        }
    }
}
import { Box, MenuItem, Grid, Link as MUILink, } from '@material-ui/core';
import {SvgIcon} from '../SvgIcon';
import React from 'react';
import { IPointsMenuItem } from './types';

interface PointsMenuItemProps {
  action?: () => void;
  title: string;
  link?: string;
  icon?: string;
}

export const PointsMenuItem:React.FC<PointsMenuItemProps> = ({action, title, link, icon}) => (
  <MUILink
    href={link}
    onClick={() => {
      if(action) {
        action();
      }
    }}
    target="_blank"
    underline={"none"}
    color="inherit"
  >
    <Box clone px={3}>
      <MenuItem value={title} style={{
        padding: '8px 20px',
        fontSize:13,
        lineHeight: '15px',
      }} ContainerProps={{
        style: {
        }
      }}>
        <Grid container alignItems="center" spacing={1} wrap="nowrap">
          {icon ? (
            <Grid item>
              <SvgIcon type={icon} />
            </Grid>
          ) : null}
          <Grid item>{title}</Grid>
        </Grid>
      </MenuItem>
    </Box>
  </MUILink>
);
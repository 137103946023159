import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    flex: '1 1 100%',
    width: 460,
    [theme.breakpoints.down('sm')]: {
      width: 324,
    }
  },
  payOverlay: {
    position: 'fixed',
    display: 'flex',
    flex: '1 1 100%',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 100,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.75)',
    '& p': {
      fontSize: 18,
      lineHeight: '24px',
      font: 'SF UI Text',
    },
  },
  biling: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: 12,
    marginBottom: 32,
  },
  column: {
    display: 'flex',
    //@ts-ignore
    flexDirection: 'column',
  },
  freeText: {
    fontWeight: 600,
    color: `${theme.palette.success.dark}!important`,
  },
  bilingDesc: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    '& svg': {
      marginLeft: 4,
      marginRight: 8,
    },
    '& span': {
      fontWeight: 500,
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.common.white,
    borderColor: theme.palette.primary.light,
    borderStyle: 'solid',
    borderLeftWidth: 2,
    borderRightWidth: 2,
    '& > div': {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      fontSize: 18,
      lineHeight: '21px',
      // fontWeight: 500,
      color: theme.palette.primary.dark,
      '&:first-child': {
        flex: 2,
      },
      '&:last-child': {
        flex: 2,
        padding: 14,
        flexDirection: 'column',
      },
    },
    '&:first-child': {
      borderRadius: '8px 8px 0 0',
      borderWidth: 2,
      borderBottomWidth: 0,
    },
    '&:last-child': {
      borderWidth: 2,
      borderRadius: '0 0 8px 8px',
    },
  },
  bilingRowDivider: {
    width: 2,
    height: 20,
    background: '#D8DCF8',
  },
  header: {
    fontFamily: 'Manrope',
    fontSize: 40,
    lineHeight: '55px',
    fontWeight: 400,
    letterSpacing: '0.8px',
    whiteSpace: 'nowrap',
    flexWrap: 'nowrap',
    display: 'inline-flex',
    alignItems: 'center',
    '& svg': {
      marginLeft: 10,
    },
    '& span': {
      marginLeft: 10,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 31,
      lineHeight: '53px',
    },
    // font: normal normal bold 40px/55px Manrope;
  },
  optionsHead: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    // margin: '10px 0px',
    marginTop: 20,
    marginBottom: 10,
    '& > div': {
      flex: 1,
      display: 'flex',
      justifyContent: 'flex-end',
      fontFamily: 'Manrope',
      fontSize: 20,
      lineHeight: '22px',
      fontWeight: 600,
      '&:first-child': {
        justifyContent: 'flex-start',
      },
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: 2,
      '& > div': {
        fontSize: 14,
        lineHeight: '22px',
      },
    },
  },
  head2: {
    fontFamily: 'Manrope',
    fontSize: 32,
    lineHeight: '54px',
    fontWeight: 600,
    letterSpacing: '0.64px',
  },
  head5: {
    fontFamily: 'Manrope',
    fontSize: 20,
    lineHeight: '33px',
    fontWeight: 400,
    letterSpacing: '0.4px',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      lineHeight: '22px',
    },
  },
  sub: {
    color: theme.palette.grey[600],
  },
  head6: {
    fontFamily: 'Manrope',
    fontSize: 20,
    lineHeight: '22px',
    fontWeight: 600,
    // wordWrap: 'n',
    // wordBreak: 'nowrap',
  },
  contactBox: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    marginTop: 48,
    paddingBottom: 40,
    '& h6': {
      marginBottom: 12,
    },
    '& a[href^="tel:"]': {
      margin: '4px 0',
    },
  },
  offerLink: {
    color: '#2F90E0',
  },
  contactLink: {
    color: theme.palette.grey[50],
    textDecoration: 'none',
  },
  regBtn: {
    fontSize: 22,
    lineHeight: '37px',
    borderRadius: 37,
    padding: '11px 42px',
    marginTop: 40,
    marginBottom: 40,
  },
  regText: {
    display: 'inline-block',
    fontFamily: 'Manrope',
    fontSize: 20,
    lineHeight: '33px',
    textAlign: 'center',
    padding: 0,
    marginTop: 12,
    marginBottom: 32,
    '& svg': {
      marginBottom: -3,
      fontSize: 26,
      lineHeight: '33px',
    }
  },
}));

import {apiClient, IListResponse} from "./_client"
import {IWebinarFilter, IWebinar, EWebinarType, EWebinarStatus} from "../types";

export const get = async (id: string): Promise<IWebinar> => {
  const response = await apiClient.get<IWebinar>(`/webinar/${id}`);
  return response.data;
};

export const list = async (type: EWebinarType, filter:IWebinarFilter, offset: number, limit:number): Promise<IListResponse<IWebinar>> => {
  const response = await apiClient.get<IListResponse<IWebinar>>('/webinar', {
      params: {
        type: type,
        filter:filter,
        offset: offset,
        limit:limit
      }
    });
    return response.data;
}

export const create = async (type: EWebinarType, name: string): Promise<IWebinar> => {
    const response = await apiClient.post<IWebinar>('/webinar', {
      type: type,
      name: name
    });
    return response.data;
}

export const createWithFormData = async (data: FormData): Promise<IWebinar> => {
  const response = await apiClient.post('/webinar', data);
  return response.data;
}

export const save = async (webinar: Partial<IWebinar>): Promise<IWebinar> => {
    const response = await apiClient.put<IWebinar>(`/webinar/${webinar.id}`, webinar);
    return response.data;
}

export const play = async (webinar: IWebinar): Promise<IWebinar> => {
    const response = await apiClient.put<IWebinar>(`/webinar/${webinar.id}`, {
      id:webinar.id,
      status:EWebinarStatus.ACTIVE
    });
    return response.data;
}

export const pause = async (webinar: IWebinar): Promise<IWebinar> => {
    const response = await apiClient.put<IWebinar>(`/webinar/${webinar.id}`, {
      id:webinar.id,
      status:EWebinarStatus.PAUSE
    });
    return response.data;
}

export const remove = async (webinarId:string):Promise<void> => {
    await apiClient.delete(`/webinar/${webinarId}`);
}


export const convert = async (webinarId:string):Promise<IWebinar> => {
  const response = await apiClient.post<IWebinar>(`/webinar/${webinarId}`,{
    action:'convert'
  });
  return response.data;
}

export const copy = async (webinarId:string):Promise<IWebinar> => {
  const response = await apiClient.post<IWebinar>(`/webinar/${webinarId}`);
  return response.data;
}

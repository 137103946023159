import { Link, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import useAccessControl from '../../hooks/useAccessControl';

export interface LogoProps {
  forStudent?: boolean;
}

const useStyles = makeStyles((theme)=>({
  logoText: {
    font: 'normal normal normal 18px/22px Days',
    textDecoration: 'none',
    color: '#2E2E4A',
    paddingBottom: '4px',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 0,
      display: 'none',
    },
  },

  logoImg: {
    width: '28px',
    paddingRight: '8px',
  },
}));

export const Logo: React.FC<LogoProps> = ({ forStudent }) => {
  const classes = useStyles();
  const acl = useAccessControl();
  return (
    <>
      <img src="/images/logo.svg" className={classes.logoImg} alt="logo" />
      {forStudent ? (
        <Typography className={classes.logoText}>SmartWebinar</Typography>
      ) : (
        <Link href={acl.check('webinar', 'list') ? "/" : process.env.REACT_APP_BASE_PATH} className={classes.logoText} underline="none">
          SmartWebinar
        </Link>
      )}
    </>
  );
};

export default Logo;

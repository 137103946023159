import { apiClient } from './_client';
import { IUploadedFile, IUploadLink } from '../types';
import axios from 'axios';

export const upload = async (
  file: File,
  callback?: (progress: number) => void,
  fileId?: string
): Promise<IUploadedFile> => {
  if (callback) {
    callback(5);
  }
  const linkResponse = await apiClient.post<IUploadLink>('file', {
    id: fileId,
    name: file.name,
    size: file.size,
    mimetype: file.type,
  });
  if (callback) {
    callback(15);
  }
  if (linkResponse.status === 201) {
    const uploadedFile = linkResponse.data.file;
    await uploadToCDN(linkResponse.data, file, callback);
    return uploadedFile;
  }
  throw new Error(linkResponse.statusText);
};

const uploadToCDN = async (
  uploadLink: IUploadLink,
  file: File,
  callback?: (progress: number) => void
): Promise<void> => {
  try {
    const formData = new FormData();
    formData.append('file', file, file.name);
    await axios.put(uploadLink.link, file, {
      headers: {
        'Content-Type': file.type,
      },
      onUploadProgress: (event: ProgressEvent) => {
        if (callback) {
          const completed = Math.round((event.loaded * 100) / event.total) - 15;
          if (completed > 0) {
            callback(completed);
          }
        }
      },
    });
    // оповещаем бэк об успешной загрузке файла на селектел
    const processResponse = await apiClient.post(`/file/${uploadLink.file.id}`);
    if (callback) {
      callback(90);
      setTimeout(() => callback(100), 200);
    }
  } catch (e: any) {
    // оповещаем бэк об ошибки при загрузке файла
    apiClient.post(`/file/${uploadLink.file.id}`, {
      error: e.toString(),
    });
    throw e;
  }
};

import { makeStyles, styled } from "@material-ui/core";

export const useStyles = makeStyles((theme)=>({
  root: {
    padding: '18px 21px',
    fontSize: 18,
    lineHeight: 1.2,
    marginRight: 12,
    backgroundColor: theme.palette.common.white,
    border: '1px solid #E3E4E8',
    textDecoration: 'none',
    //@ts-ignore
    color: theme.palette.disabled.contrastText,
    cursor: 'pointer',
    marginBottom: -1,
    borderRadius: '4px 4px 0px 0px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexFlow: 'row nowrap',
    zIndex: 12,
    [theme.breakpoints.down('sm')]: {
      marginRight: 6,
      fontSize: 15,
      padding: '12px 14px',
      marginBottom: -1,

    }
  },
  active: {
    color: theme.palette.common.black,
    borderBottom: 'none',
  },
  container: {
    width: '100%',
  }
}));

export const Count = styled('span')(({ theme }) => ({
  fontSize: 15,
  lineHeight: '18px',
  minWidth: 15,
  fontFamily: 'SF UI Text',
  marginBottom: -1,
  marginLeft: 8,
  [theme.breakpoints.down('sm')]: {
    fontSize: 13,
  }
}));

export const TabHeader = styled('span')(({ theme }) => ({
  fontSize: 18,
  lineHeight: 1.16,
  fontWeight: 600,
  fontFamily: 'SF UI Text',
  [theme.breakpoints.down('sm')]: {
    fontSize: 15,
  }
}));
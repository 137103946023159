import * as React from "react";
import {
  Box,
  MenuItem,
  Grid,
  Link as MUILink,
  Popover,
} from "@material-ui/core";
import { lighten, makeStyles } from "@material-ui/core/styles";

import { themeVariables } from "../../MUI";
import { SvgIcon } from "../SvgIcon";
import {IPointsMenuItem,IPointsMenuProps} from "./types";

const useStyles =  makeStyles({
  cursorPointer: {
    cursor: "pointer",
  },
});

export const PointsMenu = (props: IPointsMenuProps) => {
  const { menuItems } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const classes = useStyles();

  if(menuItems === undefined) return null;

  const menuItem = (item: IPointsMenuItem) => (
    <Box clone px={3}>
      <MenuItem value={item.title}>
        <Grid container alignItems="center" spacing={1} wrap="nowrap">
          {item.icon ? (
            <Grid item>
              <SvgIcon type={item.icon} />
            </Grid>
          ) : null}
          {item.title && <Grid item>{item.title}</Grid>}
        </Grid>
      </MenuItem>
    </Box>
  );

  const open = Boolean(anchorEl);

  return (
    <div>
      <Box
        // px={0.75}
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onClick={handlePopoverOpen}
        className={classes.cursorPointer}
      >
        <SvgIcon type={`points-menu`} />
      </Box>
      <Popover
        id="mouse-over-popover"
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        disableScrollLock
      >
        <Box
          py={1}
          bgcolor={themeVariables.palette.common.white}
          borderRadius={themeVariables.shape.borderRadius}
          boxShadow={`0 0 ${themeVariables.spacing * 0.75}px ${lighten(
            themeVariables.palette.fader,
            0.4
          )}`}
        >
          {menuItems.map((item: IPointsMenuItem) => (
            <MUILink
              href={item.link}
              onClick={() => {
                if(item.action) {
                  item.action();
                  handlePopoverClose()
                }

              }}
              target="_blank"
              underline={"none"}
              color="inherit"
              key={`menuItem-${item.id}`}
            >
              {menuItem(item)}
            </MUILink>
          ))}
        </Box>
      </Popover>
    </div>
  );
};

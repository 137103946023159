import React, { useCallback, useEffect, useState } from "react";
import { Link, useParams } from 'react-router-dom';
import { Box, Collapse, Typography, Button, styled } from "@material-ui/core";
import { MUIBlock, MUIPreloader, themeVariables } from '../../MUI';
import { TIntegration } from '../../types';
import { tl } from "../../utils";
import { useDispatch } from "react-redux";
import { fetchIntegrations, fetchRemoveIntegration } from '../../store/integrations/actions';
import { TableInfo } from '../../MUI/Table/TableInfo';
import { PlusIcon } from '../../components/Icon/PlusIcon';
import { IntegrationTable } from '../../components/Tables/Integration';
import Permission from "../../hoc/permission";
import useAppSelector from '../../hooks/useAppSelector';

const EmptyControlBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 110,
  borderTop: '2px solid #E3E4E8'
}));

export const PaymentIntegrationsTable: React.FC = () => {
  const dispatch = useDispatch();
  const { list, loading } = useAppSelector((state) => state.integrations);

  const [infoOpened, setInfoOpened] = useState(true);

  const breadcrumbs = (
    <Box clone color={themeVariables.palette.grey[50]}>
      <Typography variant="subtitle1">{tl("Интеграции с платежными системами")}</Typography>
    </Box>
  );

  const handleIntegrationRemove = useCallback((integration: TIntegration) => {
    dispatch(fetchRemoveIntegration(integration));
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchIntegrations());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <MUIPreloader />;
  }

  const controls = (
    // <Permission entity="integrations" action="create">
      <Link style={{ textDecoration: 'none' }} to={`/settings/integrations/cloud-payments`}>
        <Button
          color="primary"
          variant="contained"
          startIcon={<PlusIcon />}
        >
          {tl(" Интеграцию")}
        </Button>
      </Link>
    // </Permission>
  );

  return (
    <MUIBlock
      head={breadcrumbs}
      headControls={list.length && controls}
      pInner={0}
    >
      <Collapse in={infoOpened}>
        <TableInfo
          info={tl("Чтобы осуществлять продажу своего продукта прямо в вебинарной комнате, подключите интеграцию с платежной системой. Тогда зрители при нажатии на кнопку или баннер увидят платежный виджет и смогут произвести оплату, не покидая страницу трансляции.")}
          noBorderTop
          onClose={() => setInfoOpened(false)}
        />
      </Collapse>
      {
        list.length !== 0 ?
          <IntegrationTable list={list} onDelete={handleIntegrationRemove}/> :
          <EmptyControlBox>
            {controls}
          </EmptyControlBox>
      }
    </MUIBlock>
  );
}
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, makeStyles, Grid, Box, Typography, FormLabel } from '@material-ui/core';
import { format, subDays } from 'date-fns';
import {ru} from 'date-fns/locale'
import React, { useEffect, useState } from 'react';
import { apiClient } from '../../api/_client';
import { useScrollbarStyles } from '../../hooks/useScrollbarStyles';
import { MUIBlock, MUIDateTimePicker, MUIPreloader } from '../../MUI';

const useStyles = makeStyles({
  first: {
    minWidth: 200,
    fontWeight: 400,
  },
  th:{
    borderBottom: 'none',
    minWidth: 200,
  },
  weekAndAll: {
    minWidth: 70,
  },
  container: {
    overflowY: 'scroll',
    maxHeight: '85vh',
  }
})

export const AdminStatistics: React.FC = () => {
  const classes = useStyles();
  const scrollbarStyles = useScrollbarStyles();
  //TODO: type this
  const [data, setData] = useState<any | undefined>();
  const [date, setDate] = useState(subDays(new Date(), 7));

  useEffect(() => {
    async function getData() {
      //TODO: add loading state
      try {
        const response = await apiClient.get('/statistics?date='+encodeURIComponent(format(date, 'yyyy-MM-dd')));
        setData(response.data);
      } catch (error: any) {
        if(error.data?.code === '403') {
          throw new Error('403')

        } else {
        }
      }
    }
    getData();
  }, [date]);

  if (!data) {
    return <MUIPreloader />
  }
  return (
      <MUIBlock style={{overflow: 'hidden'}} head={<Typography variant="h3">Использование и продвижение</Typography>}>
       <Grid container item direction="column">
        <Grid item container direction="row" alignItems="center">
          <FormLabel>Дата начала недели:</FormLabel>
          <Box mr={1}/>
          <MUIDateTimePicker type="date" value={date} onChange={(e)=> setDate((e as Date))} />
        </Grid>
        <Box mb={2}/>
       <TableContainer className={[classes.container, scrollbarStyles.scrollbar].join(' ')} >
          <Table style={{overflowY: 'scroll'}} stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell component="th" className={classes.first}>

                </TableCell>
                <TableCell className={classes.weekAndAll}>
                  За все время
                </TableCell>
                <TableCell className={classes.weekAndAll}>
                  За неделю
                </TableCell>
                {
                  Object.keys(data.active.day).map((headerDate, i) => (
                    <TableCell key={i + 'tableHeadCell'}>
                      <Table style={{width: 70, padding: 0, margin: 0, border: 'none'}}>
                        <TableBody role="cell" >
                          <TableRow>
                            <TableCell style={{border: 'none',padding: 0, marginBottom: 10}}>
                              {headerDate}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{border: 'none', padding: 0}}>
                            { format(new Date(headerDate), 'EE', {locale: ru})}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th" className={classes.th}>
                  Продвижение:
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" className={classes.first}>Регистрации через форму</TableCell>
                <TableCell className={classes.weekAndAll}>{data.registrations.webinar.all}</TableCell>
                <TableCell className={classes.weekAndAll}>{data.registrations.webinar.week}</TableCell>
                {
                  Object.values(data.registrations.webinar.day).map((regDay: any, i) => (
                    <TableCell key={'webinarReg'+i}>
                      {regDay}
                    </TableCell>
                  ))
                }

              </TableRow>
              <TableRow>
                <TableCell component="th" className={classes.first}>Впервые перешли из АТ</TableCell>
                <TableCell className={classes.weekAndAll}>{data.registrations.at.all}</TableCell>
                <TableCell className={classes.weekAndAll}>{data.registrations.at.week}</TableCell>
                {
                  Object.values(data.registrations.at.day).map((regDay: any, i) => (
                    <TableCell key={'atReg'+i}>
                      {regDay}
                    </TableCell>
                  ))
                }
              </TableRow>
              <TableRow>
                <TableCell component="th" className={classes.first}>Количество регистраций по инвайтам</TableCell>
                <TableCell className={classes.weekAndAll}>{data.registrations.invites.all}</TableCell>
                <TableCell className={classes.weekAndAll}>{data.registrations.invites.week}</TableCell>
                {
                  Object.values(data.registrations.invites.day).map((regDay: any, i) => (
                    <TableCell key={'atReg'+i}>
                      {regDay}
                    </TableCell>
                  ))
                }
              </TableRow>
              <TableRow>
                <TableCell component="th" className={classes.first}>Сумма новых пользователей</TableCell>
                <TableCell className={classes.weekAndAll}>{data.users.all}</TableCell>
                <TableCell className={classes.weekAndAll}>{data.users.week}</TableCell>
                {
                  Object.values(data.users.day).map((regDay: any, i) => (
                    <TableCell key={'atReg'+i}>
                      {regDay}
                    </TableCell>
                  ))
                }
              </TableRow>
              <TableRow></TableRow>
              <TableRow>
                <TableCell component="th" className={classes.th}>
                 Использование:
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" className={classes.first}>
                  Вебинаров (10+ зрителей)
                </TableCell>
                <TableCell className={classes.weekAndAll}>{data.webinars.all}</TableCell>
                <TableCell className={classes.weekAndAll}>{data.webinars.week}</TableCell>
                {
                  Object.values(data.webinars.day).map((regDay: any, i) => (
                    <TableCell key={'web'+i}>
                      {regDay}
                    </TableCell>
                  ))
                }
              </TableRow>
              <TableRow>
                <TableCell component="th" className={classes.first}>
                  Автовебинаров (10+ зрителей)
                </TableCell>
                <TableCell className={classes.weekAndAll}>{data.autowebinars.all}</TableCell>
                <TableCell className={classes.weekAndAll}>{data.autowebinars.week}</TableCell>
                {
                  Object.values(data.autowebinars.day).map((regDay: any, i) => (
                    <TableCell key={'autoWeb'+i}>
                      {regDay}
                    </TableCell>
                  ))
                }
              </TableRow>
              <TableRow>
                <TableCell component="th" className={classes.first}>
                  Зрителей суммарно
                </TableCell>
                <TableCell className={classes.weekAndAll}>{data.members.all}</TableCell>
                <TableCell className={classes.weekAndAll}>{data.members.week}</TableCell>
                {
                  Object.values(data.members.day).map((regDay: any, i) => (
                    <TableCell key={'membersAll'+i}>
                      {regDay}
                    </TableCell>
                  ))
                }
              </TableRow>
              <TableRow>
                <TableCell component="th" className={classes.first}>
                  Активных авторов (веб/автовеб 10чел, 30мин)
                </TableCell>
                <TableCell className={classes.weekAndAll}>{data.active.all}</TableCell>
                <TableCell className={classes.weekAndAll}>{data.active.week}</TableCell>
                {
                  Object.values(data.active.day).map((regDay: any, i) => (
                    <TableCell key={'activeAuthors'+i}>
                      {regDay}
                    </TableCell>
                  ))
                }
              </TableRow>
              <TableRow>
                <TableCell component="th" className={classes.first}>
                  Импортиров с бизона
                </TableCell>
                <TableCell className={classes.weekAndAll}>{data.import.bizon.all}</TableCell>
                <TableCell className={classes.weekAndAll}>{data.import.bizon.week}</TableCell>
                {
                  Object.values(data.import.bizon.day).map((regDay: any, i) => (
                    <TableCell key={'bizon'+i}>
                      {regDay}
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
       </Grid>
      </MUIBlock>
  );
}
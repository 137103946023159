import { Box, makeStyles, Typography } from '@material-ui/core';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { tl } from '../../utils';
import BlockHeaderHint, { BlockHeaderTitle } from '../BlockHeaderHint';
import AlarmIcon from '../Icon/alarm';

const useStyles = makeStyles({
  text: {
    fontSize: 15,
    lineHeight: '18px',
  },
});

export const BalanceBlockNotification: React.FC<{ balance: number }> = ({
  balance,
}) => {
  const [show, setShow] = useState(balance < 200);
  const classes = useStyles();

  return (
    <>
      <BlockHeaderHint
        variant={balance === 0 ? 'error' : 'warning'}
        show={show}
        onClose={() => setShow(false)}
        mb={1.5}
      >
        {balance === 0 ? (
          <BlockHeaderTitle>
            <Box display="inline-flex" alignItems="center">
              <Box
                mr={0.5}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <AlarmIcon color="error" />
              </Box>
              <Typography className={classes.text}>
                <b>{tl('Зрители не могут зайти')} </b>
                {tl(' на вебинары, выделенные красным ниже. ')}
                <Link to="/plans">{tl('Пополните баланс')}</Link>
              </Typography>
            </Box>
          </BlockHeaderTitle>
        ) : (
          <BlockHeaderTitle>
            {tl('Места для зрителей заканчиваются. ')}
            <Link to="/plans">{tl('Пополните баланс')}</Link>
          </BlockHeaderTitle>
        )}
      </BlockHeaderHint>
    </>
  );
};

const useInnerStyles = makeStyles((theme) => ({
  link: {
    color: '#000',
  },
}));

export const BalanceBlockNotificationInner: React.FC<{ balance: number }> = ({
  balance,
}) => {
  const classesInner = useInnerStyles();
  const classes = useStyles();
  const [show, setShow] = useState(balance < 200);
  return (
    <>
      <BlockHeaderHint
        variant={balance === 0 ? 'error' : 'warning'}
        show={show}
        onClose={() => setShow(false)}
        mb={1.5}
      >
        {balance === 0 ? (
          <BlockHeaderTitle>
            <Box display="inline-flex" alignItems="center">
              <Box
                mr={0.5}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <AlarmIcon color="error" />
              </Box>
              <Typography className={classes.text}>
                <b>{tl('Зрители не могут зайти')} </b>
                <Link to="/" className={classesInner.link}>
                  {tl('на эти вебинары')}
                </Link>
                . <Link to="/plans">{tl('Пополните баланс')}</Link>
              </Typography>
            </Box>
          </BlockHeaderTitle>
        ) : (
          <BlockHeaderTitle>
            {tl('Места для зрителей заканчиваются. ')}
            <Link to="/plans">{tl('Пополните баланс')}</Link>
          </BlockHeaderTitle>
        )}
      </BlockHeaderHint>
    </>
  );
};

import { Box, makeStyles, Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import { IBillingStatStream } from '../../../../types/Billing';
import { tl } from '../../../../utils';
import SmartCurrencyIcon from '../../../Icon/smartCurrency';
import { numberFormat } from '../utils';

const useStyles = makeStyles((theme) => ({
  sum: {
    fontFamily: 'SF UI Text',
    fontSize: 13,
    lineHeight: '15px',
    fontWeight: 600,
    color: (props: SmartSumProps) => props.sum < 1 ? theme.palette.error.main : theme.palette.success.main,
    marginRight: 8,
    '&::first-letter': {
      marginRight: 4,
    }
  }
}));

interface SmartSumProps {
  sum: number;
  summary?: IBillingStatStream['summary'];
}

export const SmartSum: React.FC<SmartSumProps> = (props) => {
  const classes = useStyles(props)
  return (
    <Tooltip title={props.summary ? tl(`${props.summary.start} за старт \n ${props.summary.members} за зрителей`): ''}>
      <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end">
        <Typography className={classes.sum}>{props.sum === 0 ? ' ' : props.sum > 0 ? '+' : '-'}{numberFormat.format(Math.abs(props.sum))}</Typography>
        <SmartCurrencyIcon />
      </Box>
    </Tooltip>
  )
}
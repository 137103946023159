import React, { useCallback } from "react";
import cx from "classnames";
import {tl} from "../../utils";
import {IAdsLayout} from "../../types";
import {
  Box,
  Button,
  Grid,
  Hidden,
  makeStyles,
  styled,
} from "@material-ui/core";
import {MUIDialog, MUIPreloader} from "../../MUI";
import {AdsLayoutCard} from "../AdsLayouts/Card";
import InfiniteScroll from "react-infinite-scroller";
import { PlusIcon } from "../Icon/PlusIcon";
import { Link, useHistory } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Permission from "../../hoc/permission";
import { useScrollbarStyles } from "../../hooks/useScrollbarStyles";
import MenuSettingsIcon from "../Icon/menuSettings";


const AdTransition = styled(CSSTransition)({
  '&.fade-enter': {
    transform: 'scale(0)',
  },
  '&.fade-exit': {
    transform: 'scale(1)',
  },
  '&.fade-enter-active': {
    transform: 'scale(1)',

  },
  '&.fade-exit-active': {
    transform: 'scale(0)',
  },
  '&.fade-enter-active, &.fade-exit-active':{
    transition: 'all 300ms',
    overflow: 'hidden',
  }
});

const useStyles = makeStyles((theme)=>({
  dialog: {
    maxWidth: 719,
    width: 705,
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100vw',
      width: '100vw',
    }
  },
  list: {
    width: '100%',
    backgroundColor: '#F2F6F8',
    // margin: '-8px -16px',
    padding: 10,
    paddingRight: 16,
    border: '1px solid #e3e4e8',
    maxHeight: 428,
    transition: 'height 300ms',
  },
  itemIdx: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 10,
    left: 10,
    height: 24,
    width: 24,
    textAlign: 'center',
    background: theme.palette.common.white,
    zIndex: 1450,
    fontSize: 13,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.common.black,
    cursor: 'pointer',
  },
  dialogContentPadding: {
    padding: '12px 12px',
    width: 705,
    [theme.breakpoints.down('xs')]: {
      maxWidth: 'calc(100% - 24px)',
      width: 'calc(100% - 24px)',
    }
  }
}));

interface AdsModalProps {
  ads: Array<IAdsLayout>;
  webinarId: string;
  list: Array<IAdsLayout>;
  onSelect: (ads: IAdsLayout) => void;
  onClose: () => void;
  onRemove: (ad: IAdsLayout) => void;
  onEdit: (ad: IAdsLayout) => void;
  hasMore: boolean;
  loading: boolean;
  loadMore: () => void;
  open: boolean;
}

export const AdsSelectModal = (props: AdsModalProps) => {
  const classes = useStyles();
  const scrollbarClass = useScrollbarStyles();

  const history = useHistory();

  const handleModalClose = () => {
    props.onClose();
  };

  const handleAdsClick = (ad: IAdsLayout) => {
    if(adsSelected(ad)){
      props.onRemove(ad);
    }
    else {
      props.onSelect(ad);
    }
    props.onClose();
  }

  const handleAdEdit = useCallback((ad: IAdsLayout)=>{
    props.onEdit(ad);
    props.onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  } ,[props.webinarId])

  const adsSelected = (adsLayout: IAdsLayout) => {
    return props.ads.find(item=>item.id === adsLayout.id) !== undefined;
  };

  const createAd = async () => {
    if (props.list.length === 0) {
      history.push(`/webinar/${props.webinarId}/ads-layouts/new`,{
        fromStream: props.webinarId
      });
    }
    handleModalClose();
    window.open(`/webinar/${props.webinarId}/ads-layouts/new`, "_blank");
  };

  const headControls = (
    <Box display="flex" maxHeight="30px">
      <Permission entity="adsLayout" action="update">
        <Hidden xsDown implementation="css">
          {props.list.length > 0 && <Link
            //@ts-ignore
            variant="contained"
            target="_blank"
            to={`/webinar/${props.webinarId}/ads-layouts`}
            component={Button}
          >
            {tl("Редактор блоков")}
          </Link>}
        </Hidden>
        <Hidden smUp implementation="css">
          {props.list.length > 0 && <Link
            //@ts-ignore
            variant="contained"
            target="_blank"
            to={`/webinar/${props.webinarId}/ads-layouts`}
            component={Button}
          >
            <MenuSettingsIcon color="primary" />
          </Link>}
        </Hidden>
      </Permission>
    </Box>
  );

  return (
    <MUIDialog
      title={tl("Выбор продающего блока")}
      open={props.open}
      dialogContentClassName={classes.dialogContentPadding}
      PaperProps={{
        className: classes.dialog
      }}
      mobileFullScreen
      maxWidth="lg"
      fullWidth={false}
      headControls={headControls}
      onClose={handleModalClose}
    >
        <Grid
          container
          alignItems="center"
          wrap="nowrap"
        >
          {props.list.length > 0 ? 
            <Box className={cx(classes.list, scrollbarClass.scrollbar)}>
              <Grid container spacing={1}>
                  <InfiniteScroll
                    loadMore={() => props.loadMore()}
                    hasMore={props.hasMore}
                    loader={<MUIPreloader key="preloader" />}
                    useWindow={false}
                    style={{padding: 4, width: '100%'}}
                  >
                    <TransitionGroup component={Grid} xs={12} item container spacing={1}>
                      {props.list.map((item: IAdsLayout) => (
                        <AdTransition 
                          key={item.id} 
                          //@ts-ignore
                          classNames="fade" 
                          //@ts-ignore
                          timeout={300}
                          
                        >
                          <Grid item component="div" xs={6} sm={4}>
                            <AdsLayoutCard
                              selectable={true}
                              selected={adsSelected(item)}
                              adsLayout={item}
                              onClick={handleAdsClick}
                              onEdit={handleAdEdit}
                            />
                          </Grid>
                        </AdTransition>
                      ))}
                    </TransitionGroup>
                  </InfiniteScroll>
                </Grid>
              </Box>
            : (
              <Box
                display="flex"
                justifyContent="center"
                width="100%"
                py="20px"
              >
                {props.loading ? <MUIPreloader/> : <Button
                  variant="contained"
                  onClick={createAd}
                  startIcon={<PlusIcon />}
                >
                  {tl("Продающий блок")}
                </Button>}
              </Box>
            )
          }
        </Grid>
    </MUIDialog>
  );
};

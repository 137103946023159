import * as React from "react";
import {Box, BoxProps} from "@material-ui/core";
import styles  from "./styles.module.scss";
import cx from "classnames";

interface IProps extends BoxProps {
  start?: boolean;
  end?: boolean;
}

export const MUIAdornment:React.FC<IProps> = ({children, start, end,...boxProps}) => {
  return (
    <Box
      px={1}
      py={0}
      mr={start ? 1 : 0}
      ml={end ? 1 : 0}
      {...boxProps}
      clone
      className={cx({
        [styles.adornment]: true,
        [styles.start]: start,
        [styles.end]: end
      })}
    >
      {children}
    </Box>
  );
};

import { IPlan, IPlanMeta } from "../../types/Billing";

export const PLANS_FETCH_ATTEMPT = 'PLANS_FETCH_ATTEMPT';
export const PLANS_FETCH_SUCCESS = 'PLANS_FETCH_SUCCESS';
export const PLANS_FETCH_ERROR = 'PLANS_FETCH_ERROR';
export const GET_PAYLINK = 'GET_PAYLINK';


export interface IPlansFetchAttempt {
  type: typeof PLANS_FETCH_ATTEMPT
}

export interface IPlansFetchError {
  type: typeof PLANS_FETCH_ERROR
}

export interface IPayAction {
  type: typeof GET_PAYLINK,
  isPaying: boolean;
}

export interface IPlansFetchSuccess {
  type: typeof PLANS_FETCH_SUCCESS
  list: Array<IPlan>
  meta: IPlanMeta
}



export type TPlansAction =
  IPayAction |
  IPlansFetchAttempt |
  IPlansFetchError |
  IPlansFetchSuccess



import { EAuthStatus, IUser } from "../../types"

export const PROFILE_FETCH_ATTEMPT = 'PROFILE_ATTEMPT'
export const PROFILE_FETCH_FAILURE = 'PROFILE_FETCH_FAILURE'
export const PROFILE_FETCH_SUCCESS = 'PROFILE_FETCH_SUCCESS'
export const PROFILE_UPDATE_ATTEMPT = 'PROFILE_UPDATE_ATTEMPT'
export const PROFILE_UPDATE_SUCCESS = 'PROFILE_UPDATE_SUCCESS'
export const PROFILE_UPDATE_FAILURE = 'PROFILE_UPDATE_FAILURE'
export const SECURITY_UPDATE_ATTEMPT = 'SECURITY_UPDATE_ATTEMPT'
export const SECURITY_UPDATE_SUCCESS = 'SECURITY_UPDATE_SUCCESS'
export const SECURITY_UPDATE_FAILURE = 'SECURITY_UPDATE_FAILURE'
export const AUTH_STATUS_CHANGED = 'AUTH_STATUS_CHANGED'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const UPDATE_BALANCE = 'UPDATE_BALANCE'

export interface IProfileFetchAttemptAction {
    type: typeof PROFILE_FETCH_ATTEMPT
}

export interface IProfileFetchSuccessAction  {
    type: typeof PROFILE_FETCH_SUCCESS
    profile: IUser
}

export interface IProfileFetchFailureAction {
    type: typeof PROFILE_FETCH_FAILURE
    error: string
}

export interface IProfileUpdateAttemptAction {
    type: typeof PROFILE_UPDATE_ATTEMPT
}

export interface IProfileUpdateSuccessAction {
    type: typeof PROFILE_UPDATE_SUCCESS
    profile: IUser
}

export interface IProfileUpdateFailureAction {
    type: typeof PROFILE_UPDATE_FAILURE
    error: string
}

export interface ISecurityUpdateAttemptAction {
    type: typeof SECURITY_UPDATE_ATTEMPT
}

export interface ISecurityUpdateSuccessAction {
    type: typeof SECURITY_UPDATE_SUCCESS
}

export interface ISecurityUpdateFailureAction {
    type: typeof SECURITY_UPDATE_FAILURE
    securityError: string
}

export interface IProfileUpdateBalance {
    type: typeof UPDATE_BALANCE
    balance: number
}

export interface IAuthStatusChangedAction {
    type: typeof AUTH_STATUS_CHANGED
    status: EAuthStatus
}

export interface IAuthLogoutAction {
    type: typeof AUTH_LOGOUT
}

export type TUserActionType =
    IProfileFetchAttemptAction |
    IProfileFetchFailureAction |
    IProfileFetchSuccessAction |
    IProfileUpdateAttemptAction |
    IProfileUpdateSuccessAction |
    IProfileUpdateFailureAction |
    ISecurityUpdateAttemptAction |
    ISecurityUpdateSuccessAction |
    ISecurityUpdateFailureAction |
    IProfileUpdateBalance |
    IAuthStatusChangedAction |
    IAuthLogoutAction
import { styled, Box } from "@material-ui/core";
import { usePagination } from "@material-ui/lab";
import {ChevronLeft, ChevronRight} from '@material-ui/icons'
import React from "react";
import { ThemeType } from "../../MUI";


const PaginatorArrowBtn = styled('button')<ThemeType,{selected?: boolean, next?: boolean}>(({theme, selected, next}) => ({
  width: 30,
  height: 30,
  background: '#FFFFFF 0% 0% no-repeat padding-box',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: '1px solid #E3E4E8',
  borderRadius: 4,
  cursor: 'pointer',
  padding: 0,
  margin: 4,
  '&:hover' : {
    background: '#5E80A70D 0% 0% no-repeat padding-box',
  },
  '& svg': {
    fontSize: 24,
    color: !next ? 'rgba(109,109,109,0.5)': '#8B97E8' ,
  }
}));

const PaginatorButton = styled('button')<ThemeType,{selected?: boolean, next?: boolean}>(({theme, selected, next}) => ({
  textAlign: 'center',
  font: 'normal normal meduim 13px/15px SF UI Text',
  letterSpacing: 0,
  color: '#000000',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: 30,
  height: 30,
  boxSizing: 'border-box',
  // backgroundClip: 'border-box',
  backgroundSize: 'padding-box',
  background: `${selected ? theme.palette.primary.light : '#FFFFFF'} `,
  border: `${selected ?'none' : '1px solid #E3E4E8'} `,
  borderRadius: 4,
  cursor: 'pointer',
  paddingLeft: 3,
  paddingRight: 3,
  margin: 4,
  transition: 'background 100ms',
  '&:hover' : {
    border: '1px solid #E3E4E8',
    background: '#5E80A70D 0% 0% no-repeat padding-box',
  },
}));

const EmptyPaginator = styled('span')({
  textAlign: 'center',
  font: 'normal normal 13px/15px SF UI Text',
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'center',
  color: '#000000',
  width: 30,
  height: 30,
  padding: 0,
  paddingBottom: 4,
  letterSpacing: '-0.08px',
  borderRadius: 4,
  margin: 4,
  background: '#FFFFFF 0% 0% no-repeat padding-box',
  boxSizing: 'border-box',
  border: '1px solid #E3E4E8',
})


interface IPagingatorProps {
  currentPage: number;
  perPage: number;
  count: number;
  onPageClick: (page: number) => void;
}

const Paginator:React.FC<IPagingatorProps> = ({currentPage, perPage, count, onPageClick}) => {
  const {items} = usePagination({
    count: Math.ceil(count/perPage),
    page: currentPage,
    siblingCount: 3,
  })
  return (
    <Box flexDirection="row" display="flex" style={{marginLeft: -4}}>
      {
        items.map((val, key) => {
          if (val.type === 'previous') {
            return (
              <PaginatorArrowBtn 
                onClick={() => { 
                  onPageClick(val.page)
                }} 
                key={key + 'paginatorItem'}
              >
              <ChevronLeft />
            </PaginatorArrowBtn>)
          }
          if (val.type === 'next') {
            return (
              <PaginatorArrowBtn 
                onClick={() => { 
                  onPageClick(val.page)
                }}
                key={key + 'paginatorItem'}
              >
              <ChevronRight />
            </PaginatorArrowBtn>);
          }
          if (['end-ellipsis', 'start-ellipsis'].includes(val.type)) {
            return <EmptyPaginator key={key + 'paginatorItem'}>...</EmptyPaginator>;
          }
          return (
            <PaginatorButton 
              selected={val.selected}
              onClick={() => { 
                onPageClick(val.page)
              }}
              key={key + 'paginatorItem'}
            >
            {val.page}
          </PaginatorButton>);
        })
      }
    </Box>
  )
}

export default Paginator;
import { Typography } from "@material-ui/core";
import React from "react";
import { FullSize } from "../core/FullSizePage";

export const ErrorBoundaryView: React.FC = () => (
  <FullSize>
    <Typography variant="h2">
      &#128580;
    </Typography>
    <Typography variant="h2">
      Что-то пошло не так.
    </Typography>
    
    <Typography variant="body1">
      Мы уже в курсе и ищем решение!
    </Typography>
  </FullSize>
)
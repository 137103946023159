import * as React from "react";

type offset = number | string;

interface StickyProps extends React.HTMLProps<HTMLDivElement> {
  mode?: "top" | "bottom";
  offset?: offset | offset[];
  stickyClassName?: string;
}

export const MUISticky = ({
                            mode,
                            offset,
                            stickyClassName = '',
                            children,
                            ...divProps
                          }: StickyProps) => {
  const elTrigger = React.useRef<HTMLDivElement>(null);
  const elSticky = React.useRef<HTMLDivElement>(null);

  mode = mode || "top";

  const offsetCorrected =
    typeof offset === "object"
      ? {top: offset[0], bottom: offset[1]}
      : {[mode]: offset ? offset : 0};

  const handler = (entries: any) => {
    if (elSticky.current) {
      if (!entries[0].isIntersecting) {
        elSticky.current.classList.add(stickyClassName);
      } else {
        elSticky.current.classList.remove(stickyClassName);
      }
    }
  };

  React.useEffect(() => {
    if (stickyClassName) {
      const observer = new window.IntersectionObserver(handler);
      const target = elTrigger.current;
      observer.observe(target as Element);
      return () => {
        observer.unobserve(target as Element);
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {mode === "top" ? <div ref={elTrigger}/> : null}
      <div
        ref={elSticky}
        {...divProps}
        style={{
          position: "sticky",
          zIndex: 100,
          ...offsetCorrected,
          ...divProps.style,
        }}
      >
        {children}
      </div>
      {mode === "bottom" ? <div ref={elTrigger}/> : null}
    </>
  );
};

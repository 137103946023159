import { SvgIcon, SvgIconProps } from "@material-ui/core"
import { forwardRef } from "react";


export const OnAirIcon: React.FC<SvgIconProps> = forwardRef(({...props}, ref) => {
  return (
    <SvgIcon {...props} viewBox="0 0 26 26" innerRef={ref}>
      <path id="Back" d="M0,0H26V26H0Z" fill="none"/>
      <path id="Контур_16476" data-name="Контур 16476" d="M3,0A3,3,0,1,1,0,3,3,3,0,0,1,3,0Z" transform="translate(10 10)" fill="#ff3048"/>
      <path id="Контур_16477" data-name="Контур 16477" d="M18774.764-14726.759a11.192,11.192,0,0,1,2.7-7.308l1.266,1.179a9.43,9.43,0,0,0-2.234,6.129,9.439,9.439,0,0,0,2.252,6.15l-1.266,1.179A11.2,11.2,0,0,1,18774.764-14726.759Zm18.539,6.1a9.441,9.441,0,0,0,2.213-6.1,9.436,9.436,0,0,0-2.2-6.083l1.266-1.181a11.2,11.2,0,0,1,2.66,7.264,11.193,11.193,0,0,1-2.68,7.285Zm-15.082-6.1a7.747,7.747,0,0,1,1.775-4.946l1.27,1.185a6.02,6.02,0,0,0-1.312,3.762,6.025,6.025,0,0,0,1.33,3.783l-1.27,1.186A7.755,7.755,0,0,1,18778.221-14726.759Zm12.545,3.734a6.029,6.029,0,0,0,1.293-3.734,6.025,6.025,0,0,0-1.275-3.713l1.27-1.186a7.745,7.745,0,0,1,1.736,4.9,7.752,7.752,0,0,1-1.754,4.921Z" transform="translate(-18773.055 14739.739)" fill="#2c3a98"/>
    </SvgIcon>
  );
})

export default OnAirIcon;
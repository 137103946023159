import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { Box, Grid, Paper } from '@material-ui/core';

import { Count, TabHeader, useStyles } from './style';
import { EWebinarType } from '../../types';
import AlarmIcon from '../Icon/alarm';

interface AppTabsType {
  count: {
    [key: number]: number;
  };
  hasLive: {
    [key: number]: number;
  };
  balance: number;
}

export const AppTabs: FC<AppTabsType> = ({
  count,
  balance,
  hasLive,
  children,
}) => {
  const classes = useStyles();

  const webinarCount = count[EWebinarType.WEBINAR];
  const autoWebinarCount = count[EWebinarType.AUTOWEBINAR];

  return (
    <Grid container alignContent="flex-start">
      <Grid container item direction="row" wrap="nowrap">
        <NavLink
          to="/"
          exact
          className={classes.root}
          activeClassName={classes.active}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            mr={1}
          >
            {hasLive[EWebinarType.WEBINAR] && balance === 0 ? (
              <AlarmIcon color="error" />
            ) : null}
          </Box>
          <TabHeader data-testid="webinars">Вебинары</TabHeader>
          {webinarCount > 0 && <Count>{webinarCount}</Count>}
        </NavLink>
        <NavLink
          to="/autowebinars"
          exact
          className={classes.root}
          activeClassName={classes.active}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            mr={1}
          >
            {hasLive[EWebinarType.AUTOWEBINAR] && balance === 0 ? (
              <AlarmIcon color="error" />
            ) : null}
          </Box>
          <TabHeader data-testid="autowebinars">Автовебинары</TabHeader>
          {autoWebinarCount > 0 && <Count>{autoWebinarCount}</Count>}
        </NavLink>
      </Grid>
      <Paper square elevation={0} className={classes.container}>
        {children}
      </Paper>
    </Grid>
  );
};

import { IBillingStat } from "../../types/Billing";

export const BILLING_LIST_FETCH_SUCCESS = 'BILLING_LIST_FETCH_SUCCESS';
export const BILLING_LIST_FETCH_NEXT_SUCCESS = 'BILLING_LIST_FETCH_NEXT_SUCCESS';

export const BILLING_LIST_FETCH_ATTEMPT = 'BILLING_LIST_FETCH_ATTEMPT';
export const BILLING_LIST_FETCH_ERROR = 'BILLING_LIST_FETCH_ERROR';

export const PAY_ATTEMPT = 'PAY_ATTEMPT';
export const PAY_SUCCESS = 'PAY_SUCCESS';
export const PAY_ERROR = 'PAY_ERROR';


export interface IBillingListFetchAttempt {
  type: typeof BILLING_LIST_FETCH_ATTEMPT
  loadingNext?: boolean;
}

export interface IBillingListFetchError {
  type: typeof BILLING_LIST_FETCH_ERROR
}


export interface IBillingListFetchSuccess {
  type: typeof BILLING_LIST_FETCH_SUCCESS;
  list: Array<IBillingStat>;
  countItems: number;
}

export interface IBillingListFetchNextSuccess {
  type: typeof BILLING_LIST_FETCH_NEXT_SUCCESS;
  list: Array<IBillingStat>;
  countItems: number;
}

export interface IPayAttempt {
  type: typeof PAY_ATTEMPT;
}

export interface IPaySuccess {
  type: typeof PAY_SUCCESS;
}

export interface IPayError {
  type: typeof PAY_ERROR;
}

export type TBillingActionType = 
  IBillingListFetchSuccess |
  IBillingListFetchNextSuccess |
  IBillingListFetchAttempt |
  IBillingListFetchError|
  IPayAttempt |
  IPaySuccess |
  IPayError;
import { INewUserReview, IUserReview } from "../types/UserReviews";
import { apiClient, IListResponse } from "./_client";


interface IListRequestQueryParams {
  page: number;
  limit: number;
  filter: {
    sortBy: 'webinarDate' | 'score' | 'comment',
    sortOrder: 'asc' | 'desc'
  }
}

export async function list(params: IListRequestQueryParams) {
  const res = await apiClient.get<IListResponse<IUserReview>>('/reviews', {
    params: {
      ...params,
      offset: params.page * params.limit
    }
  });

  return res.data;
}

export async function create(review: INewUserReview) {
  const res = await apiClient.post(`/review`, review);
  return res.data;
}

export async function unreviewed() {

}
import { apiClient, IListResponse } from "./_client"
import {
  IUserStatistic,
  IWebinarStatistic, EWebinarType, IEpisodeStatistics, IUserFilter, IStatisticsMeta, IStatisticsEpisodeMeta,
} from '../types';

export interface IMembersQueryParams {
  webinar?: string,
  streamId?: string,
  limit: number,
  offset: number,
  query?: string,
  filter?: IUserFilter,
}

export const members = async (params: IMembersQueryParams): Promise<IListResponse<IUserStatistic, IStatisticsMeta>> => {
  const response = await apiClient.get<IListResponse<IUserStatistic, IStatisticsMeta>>(
    `/member`,
    {
      params: params
    });
  return response.data;
}

export const episodeStats = async (webinarId: string): Promise<IEpisodeStatistics> => {
  const response = await apiClient.get<IEpisodeStatistics>(`/statistic/${webinarId}`);
  return response.data;
}

export const webinars = async (type: EWebinarType, limit: number, offset: number, search?: string): Promise<IListResponse<IWebinarStatistic>> => {
  const response = await apiClient.get<IListResponse<IWebinarStatistic>>(
    `/stats/${type}`,
    {
      params: {
        offset: offset,
        limit: limit,
        search: search

      }
    });
  return response.data;
}

export const byWebinar = async (webinarId: string, limit: number, offset: number, search?: string): Promise<IListResponse<IWebinarStatistic,IStatisticsEpisodeMeta>> => {
  const response = await apiClient.get<IListResponse<IWebinarStatistic,IStatisticsEpisodeMeta>>(
    `/stream`,
    {
      params: {
        webinar: webinarId,
        offset: offset,
        limit: limit,
        search: search
      }
    });
  return response.data;
}
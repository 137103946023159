import * as React from "react";
import {Box, Button, Grid, TextField} from "@material-ui/core";
import {MUITooltip} from "../../index";
import {textPlainToRich, textRichToPlain} from "../../../utils";
import {SvgIcon} from "../../../components";
import styles  from "../styles.module.scss";

import CKEditor from 'ckeditor4-react';
import { useMemo, useRef, useState, useCallback } from "react";
import { debounce } from "lodash";


interface TextEditorProps {
  value: string;
  placeholder?: string;
  rows?: number;
  rowsMax?: number;

  hint?: string;
  attachAllowed?: boolean;
  attachCourse?: number;
  editorAllowed?: boolean;
  onPreview?: any;
  editor?: boolean;
  newFormats?: boolean;

  hasPanel?: boolean;
  onChange?: any;
}

export const TextEditor: React.FC<TextEditorProps> = (props) => {
  const [editor, setEditor] = useState(false);
  const [value, setValue] = useState(props.value);
  const editorRef = useRef(null);


  const changeHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(event.target.value);
  }, [props.onChange, props.value]);

  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 300)
  , [changeHandler]);

  // filestorage
  const [isFilestorageOpen, setFilestorageOpen] = React.useState(false);
  const onFileAdd = () => {
    (window as any).showEmbededFilestorage();
    setFilestorageOpen(true);
  };
  // const onFileAddConfirm = (data: any) => {
  //   const fileCode =
  //     typeof data === "string"
  //       ? ` ${data} `
  //       : data.reduce((acc: string, a: any) => {
  //         acc += ` ${a.attachedCode ? a.attachedCode : a.code} `;
  //         return acc;
  //       }, ``);
  //   if (!editorRef.current) {
  //     return;
  //   }
  //   if (editor) {
  //     setValue(editorRef.current.inserFileCode(fileCode));
  //   } else {
  //     const textareaElement = editorRef.current.querySelector(`textarea`);
  //     const newValue =
  //       textRichToPlain(value).substring(0, textareaElement.selectionStart) +
  //       fileCode +
  //       textRichToPlain(value).substring(textareaElement.selectionEnd);
  //     setValue(newValue);
  //   }
  //   (window as any).closeEmbededFilestorage();
  //   setFilestorageOpen(false);
  // };
  // filestorage -- end

  const hasPanel =
    props.hasPanel ||
    props.hint ||
    props.attachAllowed ||
    props.editorAllowed ||
    props.onPreview;

  return (
    <Grid container wrap="nowrap">
      <Grid item xs className={styles.editor}>
        {editor ? (
            <CKEditor
              data={textPlainToRich(value)}
              config={{
                toolbar: [{
                  items: ["Format", "FontSize", "-", "Bold", "Italic", "Underline"],
                },
                  {
                    items: [
                      "TextColor",
                      "BGColor",
                      "-",
                      "NumberedList",
                      "BulletedList" /*, '-', 'Outdent', 'Indent'*/,
                    ],
                  },
                  {
                    items: ["GroupedJustify", "RemoveFormat"],
                  },
                  {
                    items: ["Table", "-", "Link"],
                  },
                  {
                    items: ["Source", "-", "Undo", "Redo", "-", "Maximize"],
                  }],
                language: 'ru'
              }}
              onChange={(editor: CKEditor) => {
                const data = editor.getData();
                setValue(data);
              }}
            />)
          :
          (
            <TextField
              data-testid="HTML"
              variant="outlined"
              fullWidth
              multiline
              rows={props.rows || 3}
              rowsMax={props.rowsMax || 10}
              placeholder={props.placeholder}
              defaultValue={props.value}
              ref={editorRef}
              onChange={debouncedChangeHandler}
            />
          )
        }
      </Grid>
      {hasPanel ? (
        <Grid item>
          <Box className={styles.hints}>
            {props.hint ? (
              <MUITooltip placement="top" title={props.hint}/>
            ) : null}
            {props.attachAllowed ? (
              <>
                <Button
                  variant="contained"
                  className="MuiButton-iconAction"
                  onClick={onFileAdd}
                >
                  <SvgIcon type="courses-attach"/>
                </Button>
              </>
            ) : null}
            {/* {props.editorAllowed ? (
              <Button
                variant="contained"
                color={editor ? "primary" : undefined}
                className="MuiButton-iconAction"
                onClick={() => {
                  setEditor(!editor);
                }}
              >
                <SvgIcon type={`editor ${editor ? "white" : ""}`}/>
              </Button>
            ) : null} */}
            {props.onPreview ? (
              <Button
                variant="contained"
                className="MuiButton-iconAction"
                onClick={props.onPreview}
              >
                <SvgIcon type="courses-preview"/>
              </Button>
            ) : null}
          </Box>
        </Grid>
      ) : null}
    </Grid>
  );
};

import React from 'react';
import List, { ListRowProps } from 'react-virtualized/dist/commonjs/List';
import { Box, MenuItem, Popover } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import cx from 'classnames';
import { MUISelect } from '../..';
import { tl } from '../../../utils';
import flagStyles from './flagStyles.module.scss';

export interface ICountryCode {
  code: string;
  name: string;
  country: string;
}

export interface IProps {
  countryCodes: ICountryCode[];
  value: string;
  onChange: any;
  big?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      background: 'none',
    },
  },

  modal: {
    position: 'absolute',
    top: '30%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.common.white,
    width: '228px',
    padding: theme.spacing(2, 2, 4),
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },

  select: {
    borderRadius: '30px',
    backgroundColor: theme.palette.grey.A400,
  },

  input: {
    padding: 0,
  },

  list: {
    padding: '8px 0',
    backgroundColor: theme.palette.common.white,
    borderRadius: '10px',
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.common.black,
      borderRadius: 10,
      border: '4px solid transparent',
      backgroundClip: 'padding-box',
    },
    '&::-webkit-scrollbar': {
      width: 15,
    },
  },

  paper: {
    marginTop: theme.spacing(2),
    border: '1px solid #000',
    borderRadius: 10,
  },

  countrySelect: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 14px',
    cursor: 'pointer',
    boxSizing: 'border-box',

    '&:hover': {
      backgroundColor: theme.palette.grey[500],
    },
  },

  countrySelectUnderlined: {
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      width: '100%',
      borderBottom: `1px solid ${theme.palette.grey[500]}`,
    },
  },

  countrySelectButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 10px',
  },

  countrySelectName: {
    fontSize: '22px',
    lineHeight: '26px',
    padding: '0 10px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },

  countrySelectCode: {
    fontSize: '22px',
    fontWeight: 'bold',
  },

  selectFilled: {
    paddingRight: '8px !important',
    paddingLeft: '28px !important',
    '&:hover,&:active,&:focus': {
      background: 'none',
    },
    '& ~ div': {
      left: 4,
    },
  },
}));

export const MUICountryCodesSelect = (props: IProps) => {

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const countryCodeValue = props.countryCodes.find(
    (item: ICountryCode) => item.name === props.value
  );
  const classes = useStyles();

  function rowRenderer({
    key, // Unique key within array of rows
    index, // Index of row within collection
    style, // Style object to be applied to row (to position it)
  }: ListRowProps) {
    return (
      <Box
        key={key}
        style={style}
        className={cx(classes.countrySelect, {
          [classes.countrySelectUnderlined]: index === 2,
        })}
        onClick={() => {
          props.onChange(props.countryCodes[index].name);
          handlePopoverClose();
        }}
      >
        <Box
          className={
            props.big
              ? `${flagStyles.flag} phoneInput-flag-${
                  props.countryCodes[index].name.toLowerCase() || 'unknown'
                }`
              : `phone_country_flag ${
                  props.countryCodes[index].name.toLowerCase() || 'unknown'
                }`
          }
        />
        <Box className={classes.countrySelectName}>
          {tl(props.countryCodes[index].country)}
        </Box>
        <Box className={classes.countrySelectCode}>{props.countryCodes[index].code}</Box>
      </Box>
    );
  }

  return (
    <Box display="flex" alignItems="center" className={classes.wrapper}>
      <Popover
        id="mouse-over-popover"
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        PaperProps={{
          className: classes.paper,
        }}
      >
        <List
          width={350}
          height={360}
          rowCount={props.countryCodes.length}
          rowHeight={50}
          rowRenderer={rowRenderer}
          className={classes.list}
        />
      </Popover>
      <MUISelect
        fullWidth
        value={props.value}
        className={classes.select}
        classes={{
          filled: classes.selectFilled,
        }}
        inputProps={{ className: classes.input }}
        onClick={handlePopoverOpen}
        open={false}
        variant="filled"
      >
        <MenuItem
          value={countryCodeValue?.name}
          key={`code-${countryCodeValue?.name}`}
        >
          <Box className={classes.countrySelectButton}>
            <Box
              className={
                props.big
                  ? `${flagStyles.flag} phoneInput-flag-${
                      countryCodeValue?.name.toLowerCase() || 'unknown'
                    }`
                  : `phone_country_flag ${
                      countryCodeValue?.name.toLowerCase() || 'unknown'
                    }`
              }
            />
            <Box pl="10px" pr={1}>
              <span style={{ color: '#2C3A97' }}>{countryCodeValue?.code}</span>
            </Box>
          </Box>
        </MenuItem>
      </MUISelect>
    </Box>
  );
};

import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  textBold: {
    textAlign: 'left',
    fontFamily: 'SF UI Text',
    fontWeight: 600,
    fontSize: 13,
    lineHeight: '15px',
    letterSpacing:'-0.21px',
  },
  text: {
    textAlign: 'left',
    fontFamily: 'SF UI Text',
    fontSize: 13,
    lineHeight: '19.5px',
    letterSpacing:'-0.21px',
  },
  date: {
    fontSize: 13,
    lineHeight: '15px',
    whiteSpace: 'nowrap',
  }
}))
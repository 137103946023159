import { AppThunk } from '..';
import { api } from '../../api';
import {
  EReportStatus,
  EReportType,
  EWebinarType,
  IEpisodeStatistics,
  IStatisticsEpisodeMeta,
  IStatisticsMeta,
  IUserStatistic,
  IWebinarStatistic,
} from '../../types';
import { addNotification } from '../notifications/actions';
import { IMembersQueryParams } from '../../api/statistics';
import {
  IStatisticsMembersFetchAttemptAction,
  IStatisticsMembersFetchFailureAction,
  IStatisticsMembersFetchSuccessAction,
  IStatisticsWebinarsFetchAttemptAction,
  IStatisticsWebinarsFetchFailureAction,
  IStatisticsWebinarsFetchSuccessAction,
  IStatisticsAutowebinarEpisodesFetchAttemptAction,
  IStatisticsAutowebinarEpisodesFetchFailureAction,
  IStatisticsAutowebinarEpisodesFetchSuccessAction,
  STATISTICS_MEMBERS_FETCH_ATTEMPT,
  STATISTICS_MEMBERS_FETCH_FAILURE,
  STATISTICS_MEMBERS_FETCH_SUCCESS,
  STATISTICS_WEBINARS_FETCH_ATTEMPT,
  STATISTICS_WEBINARS_FETCH_FAILURE,
  STATISTICS_WEBINARS_FETCH_SUCCESS,
  STATISTICS_AUTOWEBINAR_EPISODES_FETCH_ATTEMPT,
  STATISTICS_AUTOWEBINAR_EPISODES_FETCH_FAILURE,
  STATISTICS_AUTOWEBINAR_EPISODES_FETCH_SUCCESS,
  STATISTICS_CLEAR_STATE,
  IEpisodeStatisticsFetchAttemptAction,
  IEpisodeStatisticsFetchSuccessAction,
  IEpisodeStatisticsFetchFailureAction,
  EPISODE_STATISTICS_FETCH_ATTEMPT,
  EPISODE_STATISTICS_FETCH_SUCCESS,
  EPISODE_STATISTICS_FETCH_FAILURE,
  IUpdateExportReportStatusAction,
  UPDATE_REPORT_EXPORT_STATUS,
  UPDATE_REPORT_STATUS,
} from './types';

export function statisticsWebinarsFetchAttempt(id: number): IStatisticsWebinarsFetchAttemptAction {
  return {
    type: STATISTICS_WEBINARS_FETCH_ATTEMPT,
    id,
  };
}

export function statisticsWebinarsFetchSuccess(
  list: Array<IWebinarStatistic>,
  count: number,
  id: number,
): IStatisticsWebinarsFetchSuccessAction {
  return {
    type: STATISTICS_WEBINARS_FETCH_SUCCESS,
    list,
    count,
    id,
  };
}

export function statisticsWebinarsFetchFailure(
  error: string
): IStatisticsWebinarsFetchFailureAction {
  return {
    type: STATISTICS_WEBINARS_FETCH_FAILURE,
    error,
  };
}

export function statisticsAutowebinarEpisodesFetchAttempt(id: number): IStatisticsAutowebinarEpisodesFetchAttemptAction {
  return {
    type: STATISTICS_AUTOWEBINAR_EPISODES_FETCH_ATTEMPT,
    id,
  };
}

export function statisticsAutowebinarEpisodesFetchSuccess(
  list: Array<IWebinarStatistic>,
  count: number,
  id: number,
  meta: IStatisticsEpisodeMeta
): IStatisticsAutowebinarEpisodesFetchSuccessAction {
  return {
    type: STATISTICS_AUTOWEBINAR_EPISODES_FETCH_SUCCESS,
    list,
    count,
    id,
    meta
  };
}

export function statisticsAutowebinarEpisodesFetchFailure(
  error: string
): IStatisticsAutowebinarEpisodesFetchFailureAction {
  return {
    type: STATISTICS_AUTOWEBINAR_EPISODES_FETCH_FAILURE,
    error,
  };
}

export function episodeStatisticsFetchAttempt(id: number): IEpisodeStatisticsFetchAttemptAction {
  return {
    type: EPISODE_STATISTICS_FETCH_ATTEMPT,
    id,
  };
}

export function episodeStatisticsFetchSuccess(
  episode: IEpisodeStatistics,
  id: number,
): IEpisodeStatisticsFetchSuccessAction {
  return {
    type: EPISODE_STATISTICS_FETCH_SUCCESS,
    episode,
    id,
  };
}

export function episodeStatisticsFetchFailure(
  error: string
): IEpisodeStatisticsFetchFailureAction {
  return {
    type: EPISODE_STATISTICS_FETCH_FAILURE,
    error,
  };
}

export function statisticsMembersFetchAttempt(offset?: number): IStatisticsMembersFetchAttemptAction {
  return {
    type: STATISTICS_MEMBERS_FETCH_ATTEMPT,
    offset
  };
}

export function updateReportExportStatus(status: EReportStatus, reportId: string): IUpdateExportReportStatusAction {
  return {
    type: UPDATE_REPORT_EXPORT_STATUS,
    status,
    reportId,

  }
}

export function updateReportStatus(status: EReportStatus) {
  return {
    type: UPDATE_REPORT_STATUS,
    status
  }
}

export function statisticsMembersFetchSuccess(
  list: Array<IUserStatistic>,
  count: number,
  meta: IStatisticsMeta,
  offset: number = 0,
): IStatisticsMembersFetchSuccessAction {
  return {
    type: STATISTICS_MEMBERS_FETCH_SUCCESS,
    list,
    count,
    meta,
    offset,
  };
}

export function statisticsMembersFetchFailure(
  error: string
): IStatisticsMembersFetchFailureAction {
  return {
    type: STATISTICS_MEMBERS_FETCH_FAILURE,
    error,
  };
}

export function fetchStatistcsWebinars(
  type: EWebinarType,
  limit: number,
  offset: number,
  search?: string
): AppThunk {
  return async (dispatch) => {
    const ajaxID = Math.random();
    dispatch(statisticsWebinarsFetchAttempt(ajaxID));
    try {
      const response = await api.statistics.webinars(type, limit, offset, search);
      dispatch(
        statisticsWebinarsFetchSuccess(
          response.list,
          response.paginator.countItems,
          ajaxID,
        )
      );
    } catch (error: any) {
      dispatch(statisticsWebinarsFetchFailure(error.message));
    }
  };
}

export function statisticsClearState() {
  return {
    type: STATISTICS_CLEAR_STATE
  };
};

export function fetchStatistcsAutowebinarEpisodes(
  webinarId: string,
  limit: number,
  offset: number,
  search: string
): AppThunk {
  return async (dispatch) => {
    const ajaxID = Math.random();
    dispatch(statisticsAutowebinarEpisodesFetchAttempt(ajaxID));
    try {
      const response = await api.statistics.byWebinar(webinarId, limit, offset, search);
      dispatch(
        statisticsAutowebinarEpisodesFetchSuccess(
          response.list,
          response.paginator.countItems,
          ajaxID,
          response.meta
        )
      );
    } catch (error: any) {
      dispatch(statisticsAutowebinarEpisodesFetchFailure(error.message));
    }
  };
}

export function fetchEpisodeStatistics(
  webinarId: string
): AppThunk {
  return async (dispatch) => {
    const ajaxID = Math.random();
    dispatch(episodeStatisticsFetchAttempt(ajaxID));
    try {
      const response = await api.statistics.episodeStats(webinarId);
      dispatch(
        episodeStatisticsFetchSuccess(response, ajaxID)
      );
    } catch (error: any) {
      dispatch(episodeStatisticsFetchFailure(error.message));
    }
  };
}

export function fetchStatisticsMembers(params: IMembersQueryParams): AppThunk {
  return async (dispatch) => {
    dispatch(statisticsMembersFetchAttempt(params.offset));
    try {
      const response = await api.statistics.members(params);
      dispatch(
        statisticsMembersFetchSuccess(
          response.list,
          response.paginator.countItems,
          response.meta,
          params.offset
        )
      );
    } catch (error: any) {
      dispatch(statisticsMembersFetchFailure(error.message));
    }
  };
}


export function createReport(ids: Array<string>, type: EReportType): AppThunk {
  return async dispatch => {
    try {
      const report = await api.report.create(ids, type);
      dispatch(updateReportExportStatus(EReportStatus.PROCESS, report.id));
    } catch (error) {
      dispatch(addNotification('info', 'Невозможно создать отчет, обратитесь в поддержку'))
    }
  }
}

import * as React from "react";
import {InputBase, makeStyles, TextField} from "@material-ui/core";
import {MUIAdornment} from "../index";
import {SvgIcon} from "../../components";
import {tl} from "../../utils";


const useStyles = makeStyles(({}) => ({
  textField: {
    height: 30,
    // '& > input': {
    //   padding: '0 8px',
    // },
    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #E3E4E8'
    }
  }
}))
interface IProps {
  disabled?: boolean;
  search: string;
  setSearch: (value: string) => void;
  onFilterSend: any;
  placeholder?: string;
  naked?: boolean;
  variant?: "filled" | "outlined" | "standard";
}

export const FilterSimple = (props: IProps) => {
  const classes = useStyles();
  const onSearch = (e: any) => {
    props.setSearch(e.target.value);
  };
  return props.naked ? (
    <InputBase
      disabled={props.disabled}
      defaultValue={tl("Поиск")}
      inputProps={{
        
        ariaLabel: tl("Поиск"),
        startAdornment: (
          <MUIAdornment>
            <button
              type="submit"
              aria-label={tl("Поиск")}
              disabled={props.disabled}
              onClick={props.onFilterSend}
            >
              <SvgIcon type="search" />
            </button>
          </MUIAdornment>
        ),
        endAdornment: props.search ? (
          <MUIAdornment>
            <button
              type="reset"
              aria-label={tl("Очистить")}
              onClick={() => {
                props.setSearch("");
              }}
              disabled={props.disabled}
            >
              <SvgIcon type="search-clear" />
            </button>
          </MUIAdornment>
        ) : null,
      }}
      onChange={onSearch}
      placeholder={props.placeholder ? props.placeholder : tl("Поиск")}
      value={props.search}
    />
  ) : (
    <TextField
      disabled={props.disabled}
      fullWidth
      inputProps={{
        "aria-label": tl("Поиск"),
      }}
      className={classes.textField}
      InputProps={{
        startAdornment: (
          <MUIAdornment
            start
            style={{
              backgroundColor: '#F9F9FE',
              borderRight: '1px solid #E3E4E8'
            }}
          >
            <button
              type="submit"
              aria-label={tl("Поиск")}
              disabled={props.disabled}
              onClick={props.onFilterSend}
            >
              <SvgIcon type="search" />
            </button>
          </MUIAdornment>
        ),
        endAdornment: props.search ? (
          <MUIAdornment>
            <button
              type="reset"
              aria-label={tl("Очистить")}
              onClick={() => {
                props.setSearch("");
              }}
              disabled={props.disabled}
            >
              <SvgIcon type="search-clear" />
            </button>
          </MUIAdornment>
        ) : null,
      }}
      onChange={onSearch}
      placeholder={props.placeholder ? props.placeholder : tl("Поиск")}
      value={props.search}
      variant={props.variant || "outlined"}
    />
  );
};

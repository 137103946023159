import { push } from 'connected-react-router';
import {AppThunk} from '..';
import {api} from '../../api';
import {EUSerStatus, IChatSettings, IPlayer, IUser, TSplashScreen} from '../../types';
import qs from 'query-string';
import {v4 as uuid} from 'uuid'
import {addNotification} from '../notifications/actions';
import {
  IPlayerChatSettingsChangeAction,
  IPlayerCloseAction,
  IPlayerFetchAttemptAction,
  IPlayerFetchErrorAction,
  IPlayerFetchSuccessAction,
  IPlayerRedirectLinkChangeAction,
  ISetMemberAction, IUpdateSplashScreenAction, PLAYER_CHAT_SETTINGS_CHANGE, PLAYER_CLOSE,
  PLAYER_FETCH_ATTEMPT,
  PLAYER_FETCH_ERROR,
  PLAYER_FETCH_SUCCESS,
  PLAYER_REDIRECT_LINK_CHANGE,
  PLAYER_SET_MEMBER,
  UPDATE_SPLASH_SCREEN,
} from './types';
import * as Sentry from '@sentry/react';
import { userQueryParamsValidator } from '../../utils';
import { getHardwareInfo, SENTRY_HW_CATEGORY } from '../../services/ErrorLog.service';


const MEMBER_UUID_ = 'MEMBER_UUID_';


export function setMember(member: IUser): ISetMemberAction {
  return {
    type: PLAYER_SET_MEMBER,
    member,
  };
}

export function playerFetchAttempt(): IPlayerFetchAttemptAction {
  return {
    type: PLAYER_FETCH_ATTEMPT,
  };
}

export function playerFetchSuccess(player: IPlayer): IPlayerFetchSuccessAction {
  return {
    type: PLAYER_FETCH_SUCCESS,
    player,
  };
}

export function playerFetchError(error: 'not-found' | boolean): IPlayerFetchErrorAction {
  return {
    type: PLAYER_FETCH_ERROR,
    error
  }
}

export function updateSplashScreen(params: TSplashScreen) : IUpdateSplashScreenAction {

  return {
    type: UPDATE_SPLASH_SCREEN,
    params
  }
}


export function getUUID (link: string) {
  let data = window.localStorage.getItem(`${MEMBER_UUID_}${link}`);
  if (!data) {
    data = uuid();
    window.localStorage.setItem(`${MEMBER_UUID_}${link}`, data);
  }
  return data;
}

export function fetchPlayer(link: string): AppThunk {
  return async (dispatch) => {
    dispatch(playerFetchAttempt());
    try {
      const response = await api.player.get(link);
      const data = window.localStorage.getItem(`member-${link}`);
      if (data) {
        dispatch(setMember(JSON.parse(data)));
      }
      dispatch(playerFetchSuccess(response));

    } catch (error: any) {
      if (error && error.response.status === 404) {
        dispatch(playerFetchError('not-found'))
      }
      dispatch(addNotification('error', 'Произошла ошибка'));
    }
  };
}


let attempts = 1;
let timeout = 0;

export function playerLogin(player: IPlayer, user: Partial<IUser>): AppThunk {
  return async (dispatch) => {
    const params = qs.stringify({
      ...user,
      params: JSON.stringify(user.params)
    });
    if (!userQueryParamsValidator(user, player.startPage?.contactsRequired)) {
      dispatch(push(`/${player.link}?${params}`));
      return;
    }
    let userId = getUUID(player.link);
    const hwInfo = getHardwareInfo();
    Sentry.addBreadcrumb({
      category: 'stream.id',
      message: player.link,
      level: Sentry.Severity.Info,
    });
    Sentry.addBreadcrumb({
        category: SENTRY_HW_CATEGORY,
        message: "device memory: " + hwInfo.deviceMemory,
        level: Sentry.Severity.Info,
    });
    Sentry.addBreadcrumb({
        category: SENTRY_HW_CATEGORY,
        message: "device cpus: " + hwInfo.cpus,
        level: Sentry.Severity.Info,
    });
    Sentry.setUser({
        id: userId,
    });
    if (attempts === 1) {
      dispatch(push(`/${player.link}/onboard?${params}`));
    }
    try {
      const response = await api.player.login(player, {
        ...user,
        id: userId,
      });

      response.params = user.params;
      dispatch(setMember(response));
      window.localStorage.setItem(
        `member-${player.link}`,
        JSON.stringify(response)
      );
      attempts = 0;
      dispatch(push(`/${player.link}`));
      window.clearTimeout(timeout);
      return;
    } catch (error: any) {
      if ([429,502,500,503,504].includes(error.response.status)) {
        window.clearTimeout(timeout);
        timeout = window.setTimeout(() => {
          dispatch(playerLogin(player, user));
          attempts = attempts + 1
        }, attempts * (2500 + Math.floor(Math.random() * 1500)));
        return;
      }  else if(error.response?.data?.title === 'Webinar not started') {
        //TODO: fix this later, error status codes
        window.location.reload();
        return;
      } else {
        dispatch(addNotification('error', 'Произошла ошибка'));
      }
    }
  };
}

export function playerAutoLogin(link: string, user: Partial<IUser>): AppThunk {
  return async (dispatch) => {
    const params = qs.stringify({
      ...user,
      params: JSON.stringify(user.params),
    });
    try {
      const playerResponse = await api.player.get(link);
      dispatch(playerFetchSuccess(playerResponse));
      if (playerResponse.startPage && playerResponse.startPage.facecontrol) {
        const fc = await api.player.facecontrol(user, playerResponse.link);
        if (!fc) {
          dispatch(push(`/${link}?${params}&facecontrol=false`));
          return;
        }
      }

      if (!userQueryParamsValidator(user, playerResponse.startPage?.contactsRequired)) {
        dispatch(push(`/${link}?${params}&fromAutologin=true`));
        return;
      }

      if (attempts === 1) {
        dispatch(push(`/${playerResponse.link}/onboard?${params}`));
      }
      let userId = getUUID(playerResponse.link);
      const hwInfo = getHardwareInfo();
      Sentry.addBreadcrumb({
        category: 'stream.id',
        message: link,
        level: Sentry.Severity.Info,
      });
      Sentry.addBreadcrumb({
          category: SENTRY_HW_CATEGORY,
          message: "device memory: " + hwInfo.deviceMemory,
          level: Sentry.Severity.Info,
      });
      Sentry.addBreadcrumb({
          category: SENTRY_HW_CATEGORY,
          message: "device cpus: " + hwInfo.cpus,
          level: Sentry.Severity.Info,
      });
      Sentry.setUser({
          id: userId,
      });
      try {
        const userData = window.localStorage.getItem(`member-${link}`);
        let userProfile: IUser;
        if (!playerResponse.isFinished) {
          if (!userData ) {
            const memberResponse = await api.player.login(playerResponse, {
              ...user,
              id: userId,
            });
            memberResponse.params = user.params;
            userProfile = memberResponse;
            window.localStorage.setItem(
              `member-${playerResponse.link}`,
              JSON.stringify(memberResponse)
            );
          } else {
            userProfile = JSON.parse(userData);
          }
          dispatch(setMember(userProfile));
          attempts = 0;
          dispatch(push(`/${playerResponse.link}`));
          window.clearTimeout(timeout);
          
        }
      } catch (error: any) {
        if([429,502,500,503,504].includes(error.response.status)) {

          window.clearTimeout(timeout);
          timeout = window.setTimeout(() => {
            dispatch(playerLogin(playerResponse, user));
            attempts = attempts + 1
          }, attempts * (2500 + Math.floor(Math.random() * 1500)));
          return;
        }
      }
      dispatch(playerFetchSuccess(playerResponse));
    } catch (error) {

    }
  }
}


export function playerLogout(): AppThunk {
  return async (dispatch,getState) => {
    const {player} = getState().player;
    if (player) {
      window.localStorage.removeItem(
        `member-${player.link}`
      );
    }
  };
}
export function playerBlock(): AppThunk {
  return async (dispatch,getState) => {
    const {player} = getState().player;
    if (player) {
      const userData= window.localStorage.getItem(`member-${player.link}`)
      if (userData) {
        const userProfile: IUser = JSON.parse(userData);
        userProfile.status = EUSerStatus.STATUS_BANNED;
        window.localStorage.setItem(
          `member-${player.link}`,
          JSON.stringify(userProfile)
        );
        dispatch(setMember(userProfile));
      }
    }
  };
}

export function playerUnblock(): AppThunk {
  return async (dispatch,getState) => {
    const {player} = getState().player;
    if (player) {
      const userData= window.localStorage.getItem(`member-${player.link}`)
      if (userData) {
        const userProfile: IUser = JSON.parse(userData);
        userProfile.status = EUSerStatus.STATUS_ACTIVE;
        window.localStorage.setItem(
          `member-${player.link}`,
          JSON.stringify(userProfile)
        );
        dispatch(setMember(userProfile));
      }
    }
  };
}

export function playerRemember(player: IPlayer, phone: string): AppThunk {
  return async (dispatch) => {
    try {
      await api.player.remember(player, phone);
      dispatch(addNotification('success', 'Напоминание установлено'));
    } catch (error) {
      dispatch(addNotification('error', 'Произошла ошибка'));
    }
  };
}


export function redirectLinkChange(redirectLink: string): IPlayerRedirectLinkChangeAction {
  return {
    type: PLAYER_REDIRECT_LINK_CHANGE,
    redirectLink
  }
}

export function chatSettingsChange(settings: IChatSettings): IPlayerChatSettingsChangeAction {
  return {
    type: PLAYER_CHAT_SETTINGS_CHANGE,
    settings
  }
}

export function playerClose(): IPlayerCloseAction {
  return {
    type: PLAYER_CLOSE
  }
}
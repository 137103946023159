import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  matchPath,
  Route,
  RouteProps,
  useHistory,
  useLocation,
} from 'react-router';
import { Location } from 'history';
import { MUIPreloader } from '../../MUI';
import { RootState } from '../../store';
import { profile } from '../../store/user/actions';
import { EAuthStatus } from '../../types';
import useAccessControl from '../../hooks/useAccessControl';

export const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const user = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const ac = useAccessControl();
  const history = useHistory();
  const location = useLocation<{ nameModal?: Location<unknown> }>();
  useEffect(() => {
    if (user.status === EAuthStatus.UNKNOWN && !user.profile.id) {
      dispatch(profile());
    }
  }, [user.status, dispatch, user.profile.id]);

  useEffect(() => {
    if (user.status === EAuthStatus.UNKNOWN) {
      return;
    }
    if (
      user.status === EAuthStatus.UNATUHORIZED &&
      !matchPath(location.pathname, '/:link')?.isExact
    ) {
      // я сделяль
      // (╯°□°）╯︵ ┻━┻
      if (process.env.REACT_APP_TEMP_ENV) {
        window.location.href = `${
          process.env.REACT_APP_API_GATEWAY_ENDPOINT
        }/auth/check?service=webinar&redirectUrl=${encodeURI(
          window.location.origin + '/config-env'
        )}`;
      } else {
        window.location.href = `${process.env.REACT_APP_API_GATEWAY_ENDPOINT}/auth/check?service=webinar`;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.status]);

  if (user.status === EAuthStatus.UNATUHORIZED) {
    window.location.href = `${process.env.REACT_APP_BASE_PATH}`;
  }

  if (
    user.status === EAuthStatus.AUTHORIZED &&
    !user.profile.name &&
    !location.state?.nameModal &&
    ac.check('profile', 'update')
  ) {
    history.push('/name', { nameModal: location });
  }

  return (
    <Route {...rest}>
      {user.status === EAuthStatus.UNKNOWN && <MUIPreloader />}
      {user.status === EAuthStatus.AUTHORIZED && children}
    </Route>
  );
};

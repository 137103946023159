import { AppThunk } from '../index';
import { api } from '../../api';
import { TIntegration } from '../../types';
import { addNotification } from '../notifications/actions';
import {
  IIntegrationFetchAttemptAction,
  IIntegrationFetchSuccessAction,
  IIntegrationsFetchAttemptAction,
  IIntegrationsFetchSuccessAction,
  IIntegrationAddAction,
  IIntegrationUpdateAction,
  IIntegrationRemoveAction,
  INTEGRATION_FETCH_ATTEMPT,
  INTEGRATION_FETCH_SUCCESS,
  INTEGRATIONS_FETCH_ATTEMPT,
  INTEGRATIONS_FETCH_SUCCESS,
  INTEGRATION_ADD,
  INTEGRATION_UPDATE,
  INTEGRATION_REMOVE,
} from './types';

export function addIntegration(integration: TIntegration): IIntegrationAddAction {
  return {
    type: INTEGRATION_ADD,
    integration,
  }
}

export function fetchAddIntegration(integration: TIntegration): AppThunk {
  return async (dispatch) => {
    try {
      await api.integration.create(integration);
      dispatch(addIntegration(integration));
      dispatch(addNotification('success', 'Интеграция создана'));
    } catch (error) {
      dispatch(addNotification('error', 'Произошла ошибка'));
    }
  };
}

export function updateIntegration(integration: TIntegration): IIntegrationUpdateAction {
  return {
    type: INTEGRATION_UPDATE,
    integration,
  }
}

export function fetchUpdateIntegration(integration: TIntegration): AppThunk {
  return async (dispatch) => {
    try {
      await api.integration.update(integration);
      dispatch(updateIntegration(integration));
      dispatch(addNotification('success', 'Интеграция обновлена'));
    } catch (error) {
      dispatch(addNotification('error', 'Произошла ошибка'));
    }
  };
}

export function removeIntegration(integration: TIntegration): IIntegrationRemoveAction {
  return {
    type: INTEGRATION_REMOVE,
    integration,
  };
}

export function fetchRemoveIntegration(integration: TIntegration): AppThunk {
  return async (dispatch) => {
    try {
      await api.integration.remove(integration);
      dispatch(removeIntegration(integration));
      dispatch(addNotification('info', 'Интеграция удалена'));
    } catch (error) {
      dispatch(addNotification('error', 'Произошла ошибка'));
    }
  };
}

export function integrationFetchAttempt(): IIntegrationFetchAttemptAction {
  return {
    type: INTEGRATION_FETCH_ATTEMPT,
  }
}

export function integrationFetchSuccess(integration: TIntegration): IIntegrationFetchSuccessAction {
  return {
    type: INTEGRATION_FETCH_SUCCESS,
    integration
  }
}

export function fetchIntegration(
  id: string,
): AppThunk {
  return async (dispatch) => {
    dispatch(integrationFetchAttempt);
    try {
      const response = await api.integration.get(id);
      dispatch(integrationFetchSuccess(response));
    } catch (error) {
      dispatch(addNotification('error', 'Ошибка загрузки интеграции'));
    }
  }
}

export function integrationsFetchAttempt(): IIntegrationsFetchAttemptAction {
  return {
    type: INTEGRATIONS_FETCH_ATTEMPT,
  };
}

export function integrationsFetchSuccess(
  list: TIntegration[],
  count: number,
): IIntegrationsFetchSuccessAction {
  return {
    type: INTEGRATIONS_FETCH_SUCCESS,
    list,
    count,
  };
}

export function fetchIntegrations(
  offset: number = 0,
  limit?: number
): AppThunk {
  return async (dispatch) => {
    dispatch(integrationsFetchAttempt());
    try {
      const response = await api.integration.list(offset, limit);
      dispatch(
        integrationsFetchSuccess(response.list, response.paginator.countItems)
      );
    } catch (error) {
      dispatch(addNotification('error', 'Произошла ошибка загрузки списка интеграций'));
    }
  }
}
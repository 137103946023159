import { differenceInSeconds } from 'date-fns';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import useAudioContext from '../../../hooks/useAudioContext';
import { VideoPlayerCtx, VideoPlayerProps } from './factory';
import { PlayButton } from '../PlayButton';
import useVisibilityChange from '../../../hooks/useVisibilityChange';
import { isMobile } from 'react-device-detect';

const makeLink = (url: string) => {
  const isVideo = /video\//.test(url);
  if (isVideo) {
    return url.replace('video', 'play/embed');
  }
  return url;
}

export const RutubeVideoPlayer: React.FC<VideoPlayerProps> = (props) => {
  const playerRef = useRef<HTMLIFrameElement | null>(null);
  const { volume, muted, setMuted} = useAudioContext();
  const {setPlaying, setStarted, setMask, handleMask, hasStarted} = useContext(VideoPlayerCtx);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  window.addEventListener('message', function (event) {
    var message = JSON.parse(event.data);
    const player = playerRef.current;
    switch (message.type) {
        case 'player:changeState':
          setIsVideoPlaying(message.data.state === 'playing');
          break;
        case 'player:playComplete':
          setMask(true);
          break;
        case 'player:ready':
          handlePlay();
          if (muted) {
            player?.contentWindow?.postMessage(JSON.stringify({type: 'player:mute', data: {}}), '*');
          }
          break;
    };
  });
  useEffect(() => {
    setPlaying(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const player = playerRef.current;
    if (player) {
      player.contentWindow?.postMessage(JSON.stringify({type: 'player:setVolume', data: {volume: volume / 100}}), '*');
    }
  }, [volume]);

  useEffect(() => {
    const player = playerRef.current;
    if (player) {
      if (muted) {
        player.contentWindow?.postMessage(JSON.stringify({type: 'player:mute', data: {}}), '*');
      } else {
        player.contentWindow?.postMessage(JSON.stringify({type: 'player:unMute', data: {}}), '*');
      }
    }
  }, [muted]);

  useVisibilityChange({
    onHidden: () => {
      setMask(true);
      if (isMobile) {
        setMuted(true);
        setPlaying(false);
      }
    },
    onVisible: () => {
      setMask(true);
      handleMask();
      if (isMobile) {
        /*
          следи за руками:
            когда происходит плей, ютуб по куке ищет последнее место отсановки видео и сам туда мотает его
            потом срабатывает синхра.
            для остальных плееров лучше в начале синхронизировать, а потом уже включать
            так дешевле для клиента
        */
        synchronize();
        setMuted(true);
        setPlaying(true);
      }
    },
  });

  const synchronize = useCallback(() => {
    const player = playerRef.current;
    if (!player) return;
    let streamDuration = differenceInSeconds(Date.now(), props.startTime || Date.now());
    console.log('sync event started');
    console.log({streamDuration});
    if (
      !!playerRef.current &&// getDuration может вернуть null согласно документации
      props.startTime !== undefined &&
      streamDuration >= 0 &&
      hasStarted
    ) {
      if (!props.live && props.seekTime) {
        streamDuration += props.seekTime
      }

      if(props.live) {
        console.log('seekTo Infinity');
        player.contentWindow?.postMessage(JSON.stringify({type:'player:setCurrentTime', data:{time: 99999} }), '*');
      } else {
        console.log('seekTo ' + streamDuration);
        player.contentWindow?.postMessage(JSON.stringify({type:'player:setCurrentTime', data:{time: streamDuration} }), '*');
      }
    }

  }, [hasStarted, props.startTime, props.live, props.seekTime, playerRef]);

  useEffect(() => {
    synchronize();
    const id = setInterval(synchronize, 3 * 60 * 1000); // every 5 minutes
    return () => clearInterval(id);
  }, [synchronize]);

  const handlePlay = () => {
    setPlaying(true);
    console.log('play reinitiated', new Date().toString());
    const player = playerRef.current;
    if (player && !isVideoPlaying) {
      player.contentWindow?.postMessage(JSON.stringify({type:'player:play', data:{} }), '*');
      setStarted(true);
      handleMask();
    }
  }
  
  return (
    <>
    <div style={{width: '100%', height: '100%', position: 'absolute', top: 0, left: 0}}>
      <iframe ref={playerRef} title="rutube player" width="100%" height="100%" src={makeLink(props.src)} frameBorder="0" allow="autoplay"></iframe>
    </div>
    {!hasStarted &&  <PlayButton onClick={handlePlay} />}
    </>
  )
}
import { Box, makeStyles } from '@material-ui/core';
import React from 'react';
import { EWebinarType } from '../../../../types';
import OnAirIcon from '../../../Icon/onAir';
import SmartCurrencyIcon from '../../../Icon/smartCurrency';
import UserIcon from '../../../Icon/user';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 60,
    height: 40,
    borderRadius: 3,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    marginRight: 10,
    background: (props: PosterProps) => {
      switch (props.type) {
        case 'balance':
          return '#FEF9E6';
        case 'contacts':
          return '#E9F4FC';
        case 'webinar':
          return '#E9F4FC';
        default:
          return '#E9F4FC';
      }
    },
  },
  onairIcon: {
    width: 22,
    height: 22,
  },
  cuurencyIcon: {
    width: 26,
    height: 26,
  },
  userIcon: {
    width: 20,
    height: 20,

  }
}))


interface PosterProps {
  type: 'balance' | 'contacts' | 'webinar';
  src?: string;
  webinarType?: EWebinarType;
}

export const Poster: React.FC<PosterProps> = (props) => {

  const classes = useStyles(props);

  return (
    <Box className={classes.root}>
      {props.src 
        ? <img src={props.src} alt="_alt"  height="100%" /> 
        : <>
            {props.type === 'balance' && <SmartCurrencyIcon className={classes.cuurencyIcon} />}
            {props.type === 'contacts' && <UserIcon className={classes.userIcon} />}
            {props.type === 'webinar' && <OnAirIcon className={classes.onairIcon} />}
          </>
      }
   
    </Box>
  )
}
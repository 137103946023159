import {
  Breadcrumbs,
  Button,
  Grid,
  Link as MUILink,
  Typography,
} from '@material-ui/core';
import { push } from 'connected-react-router';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { IInvoiceSort } from '../api/billing';
import BreadcrumbsSeparatorIcon from '../components/Icon/breadcrumbsSeparator';
import { BillingTransactionsTable } from '../components/Tables/billingTransactions';
import Page from '../hoc/page';
import useAppSelector from '../hooks/useAppSelector';
import { MUIBlock } from '../MUI';
import { getInvoices } from '../store/billing/actions';
import { tl } from '../utils';

export const BillingPage: React.FC = () => {
  const dispatch = useDispatch();
  const [sort, setSort] = useState<IInvoiceSort>({
    sortBy: 'date',
    sortOrder: 'desc',
  });
  const billing = useAppSelector((store) => store.billing);
  useEffect(() => {
    dispatch(getInvoices(sort, 0));
  }, []);

  const handleSortChange = (newSort: IInvoiceSort) => {
    if (newSort.search !== sort.search) {
      loadWithFilter(newSort);
    } else {
      dispatch(getInvoices(newSort, 0));
    }
    setSort(newSort);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadWithFilter = useCallback(
    debounce((filterData: IInvoiceSort) => {
      dispatch(getInvoices(filterData, 0));
    }, 200),
    [billing.invoiceList.countItems]
  );

  const handleLoadMore = () => {
    dispatch(getInvoices(sort, billing.invoiceList.items.length));
  };

  return (
    <Page size="sm" title={tl('Тарифы')} centered={true}>
      <Grid container>
        <Grid item xs zeroMinWidth>
          <MUIBlock
            pInner={0}
            head={
              <Breadcrumbs
                separator={<BreadcrumbsSeparatorIcon fontSize="inherit" />}
              >
                <MUILink component={Link} to={`/plans`} color="inherit">
                  <Typography variant="subtitle1">{tl('Тарифы')}</Typography>
                </MUILink>
                <MUILink component={Link} to={`/billing`} color="textPrimary">
                  <Typography variant="subtitle1">
                    {tl('История баланса')}
                  </Typography>
                </MUILink>
              </Breadcrumbs>
            }
            headControls={
              <Button variant="contained" onClick={() => dispatch(push('/plans'))}>{tl('Пополнить баланс')}</Button>
            }
          >
          <BillingTransactionsTable
            items={billing.invoiceList.items}
            onSortChange={handleSortChange}
            sort={sort}
            hasMore={!billing.invoiceList.loadingNext && billing.invoiceList.items.length < billing.invoiceList.countItems}
            loadMore={handleLoadMore}
            loadingNext={billing.invoiceList.loadingNext}
            loading={billing.invoiceList.loading}
            onAddCoins={() => dispatch(push('/plans'))}
          />
          </MUIBlock>
        </Grid>
      </Grid>
    </Page>
  );
};

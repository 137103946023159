import * as React from "react";
import {useState} from "react";
import {Box, Button, Checkbox, FormControlLabel,TextField, Typography} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ExpandMore } from "@material-ui/icons";
import {tl} from "../../utils";
import { themeDefault } from "../../MUI";

import styles  from "./styles.module.scss";

interface WebinarEventMessageProps {
  content: string;
  author: string;
  role: string;
  editable: boolean;
  onChange?: (message:{content: string, author: string, role: string}) => void;
}

const useStyles = makeStyles({
  root: {
    lineHeight: 1.27,
  },

  title: {
    fontWeight: 600,
    lineHeight: "30px",
  },

  authorModerator: {
    color: themeDefault.palette.marks[2],
  },

  authorUser: {
    color: themeDefault.palette.marks[0],
  },
});

export const WebinarEventMessage = (
  props: WebinarEventMessageProps,
) => {
  const classes = useStyles();

  const [content, setMessage] = useState(props.content || "");
  const [author, setAuthor] = useState(props.author || "");
  const [isModerator, setIsModerator] = useState(props.role === "moderator");
  const [savedState, setSavedState] = useState({
    content: props.content || "",
    author: props.author || "",
    isModerator: props.role === "moderator",
  });
  const [isChanging, setIsChanging] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  const initialTitle = (
    <Typography className={classes.title}>
      {tl("Сообщение в чат")}
    </Typography>
  );

  const messageTitle = (
    <Box
      pt="6px"
      pr="30px"
      className={styles.chatMessage}
    >
      <Typography
        component="span"
        classes={{root: classes.root}}
        className={isModerator ? classes.authorModerator : classes.authorUser}
      >
        {`${tl(author)}. `}
      </Typography>
      <Typography
        component="span"
        classes={{root: classes.root}}
      >
        {tl(content)}
      </Typography>
    </Box>
  );

  const onKeydown = (event: any) => {
    event.key === "Escape" && handleCancel();
  }

  const openSettings = () => {
    if (props.editable && !isChanging) {
      setIsChanging(true);
      document.addEventListener("keydown", onKeydown);
    }
  };

  const closeSettings = () => {
    setIsChanging(false);
    document.removeEventListener("keydown", onKeydown);
  }

  const handleCancel = () => {
    closeSettings();
    setMessage(savedState.content);
    setAuthor(savedState.author);
    setIsModerator(savedState.isModerator);
    setIsChanged(false);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    closeSettings();
    const role = isModerator ? "moderator" : props.role;
    props.onChange && props.onChange({content, author, role});
    setSavedState({content, author, isModerator});
  }

  return (
    <Box
      px="10px"
      className={isChanging ? "" : styles.changeBox}
      onClick={openSettings}
    >
      {(isChanging || !content) ? initialTitle : messageTitle}
      {props.editable &&
        <Box display={isChanging ? "none" : ""} className={styles.changeBoxIcon}>
            <ExpandMore/>
        </Box>
      }
      {isChanging && (
        <form onSubmit={handleSubmit}>
          <Box className={styles.changeBoxSettings}>
            <Box pb="12px">
              <TextField
                variant="outlined"
                fullWidth
                multiline
                rows={3}
                rowsMax={10}
                placeholder={tl("Введите текст")}
                value={content}
                onChange={(evt: any) => {
                  setMessage(evt.target.value);
                  setIsChanged(true);
                }}
              />
            </Box>
            <Box className={styles.changeBoxRow}>
              <Box pr="10px">
                <TextField
                  variant="outlined"
                  fullWidth
                  placeholder={tl("Введите имя автора")}
                  value={author}
                  onChange={(evt: any) => {
                    setAuthor(evt.target.value);
                    setIsChanged(true);
                  }}
                />
              </Box>
              <FormControlLabel
                control={<Checkbox color="primary" />}
                label={tl("Модератор")}
                labelPlacement="end"
                checked={isModerator}
                onChange={(evt: any) => {
                  setIsModerator(evt.target.checked);
                  setIsChanged(true);
                }}
              />
            </Box>
            <Box className={styles.changeBoxButtons}>
              <Box pr="8px">
                <Button
                  variant="contained"
                  onClick={handleCancel}
                >
                  Отмена
                </Button>
              </Box>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={!isChanged}
              >
                Сохранить
              </Button>
            </Box>
          </Box>
        </form>
      )}
    </Box>
  );
}
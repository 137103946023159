import React from "react";
import { Menu, MenuItem } from "@material-ui/core";
import { tl } from "../../utils";
import { IWebinar, EWebinarType } from "../../types";

interface IPreviewMenu {
  webinar: IWebinar;
  anchorEl: null | HTMLElement;
  onClose: () => void;
}

export const PreviewMenu: React.FC<IPreviewMenu> = ({ webinar, anchorEl, onClose }) => {
  const isAutowebinar = webinar.type === EWebinarType.AUTOWEBINAR;

  return (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={onClose}
    >
      <MenuItem onClick={() => window.open(`/webinar/${webinar.id}/preview/player`, '_blank')}>
        {tl(isAutowebinar ? "Автовебинар. " : "Вебинар. ")}{tl("В ЭФИРЕ")}
      </MenuItem>
      <MenuItem onClick={() => window.open(`/webinar/${webinar.id}/preview/screen`, '_blank')}>
        {tl(isAutowebinar ? "Автовебинар. " : "Вебинар. ")}{tl("Заставка")}
      </MenuItem>
      <MenuItem onClick={() => window.open(`/webinar/${webinar.id}/preview/date`, '_blank')}>
        {tl("Страница входа. Дата начала")}
      </MenuItem>
      <MenuItem onClick={() => window.open(`/webinar/${webinar.id}/preview/soon`, '_blank')}>
        {tl("Страница входа. Скоро начало")}
      </MenuItem>
      <MenuItem onClick={() => window.open(`/webinar/${webinar.id}/preview/ready`, '_blank')}>
        {tl("Страница входа. Вебинар идет")}
      </MenuItem>
    </Menu>
  );
};

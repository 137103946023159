import { Box, Grid, makeStyles, Paper, Typography } from "@material-ui/core"
import Page from "../hoc/page"

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    '& p': {
      fontSize: 16,
      lineHeight: 1.2,
    },
    '& h2': {
      fontSize: 24,
      lineHeight: 1.5,
      margin: theme.spacing(1),
      
    },
    '& h1': {
      fontSize: 36,
      lineHeight: 1.5,
      margin: theme.spacing(1),
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1),
      '& p': {
        fontSize: 12,
        lineHeight: 1.1,
      },
      '& h3': {
        fontSize: 18,
        lineHeight: 1.2,
      },
      '& h1': {
        fontSize: 24,
        lineHeight: 1.1
      }
    }
  }
}))

export const OfferPage: React.FC = () => {
  const classes = useStyles();

  return (
    <Page forStudent title="Самртвебинар Оферта">
      <Grid item container xs={12}>
        
          <Paper className={classes.root}>
          <p className="c7 c23"><span className="c2"></span></p>
  <h1 className="c22"><span className="c16">&#1051;&#1048;&#1062;&#1045;&#1053;&#1047;&#1048;&#1054;&#1053;&#1053;&#1067;&#1049;
      &#1044;&#1054;&#1043;&#1054;&#1042;&#1054;&#1056;-&#1054;&#1060;&#1045;&#1056;&#1058;&#1040;</span></h1>
  <p className="c6"><span className="c2">(&#1085;&#1072;
      &#1087;&#1088;&#1077;&#1076;&#1086;&#1089;&#1090;&#1072;&#1074;&#1083;&#1077;&#1085;&#1080;&#1077;
      &#1087;&#1088;&#1072;&#1074;&#1072;
      &#1080;&#1089;&#1087;&#1086;&#1083;&#1100;&#1079;&#1086;&#1074;&#1072;&#1085;&#1080;&#1103;
      &#1087;&#1083;&#1072;&#1090;&#1092;&#1086;&#1088;&#1084;&#1099;
      &ldquo;&#1057;&#1084;&#1072;&#1088;&#1090;&#1074;&#1077;&#1073;&#1080;&#1085;&#1072;&#1088;&rdquo;)</span></p>
  <p className="c6"><span className="c2"><br/></span><span className="c12">11</span><span className="c2">.</span><span
      className="c12">07</span><span className="c2">.202</span><span className="c12">2</span><span className="c2">&nbsp;&#1075;.</span>
  </p>
  <p className="c4"><span className="c2">&#1053;&#1072;&#1089;&#1090;&#1086;&#1103;&#1097;&#1080;&#1081;
      &#1076;&#1086;&#1082;&#1091;&#1084;&#1077;&#1085;&#1090; &#1103;&#1074;&#1083;&#1103;&#1077;&#1090;&#1089;&#1103;
      &#1086;&#1092;&#1080;&#1094;&#1080;&#1072;&#1083;&#1100;&#1085;&#1099;&#1084;
      &#1087;&#1088;&#1077;&#1076;&#1083;&#1086;&#1078;&#1077;&#1085;&#1080;&#1077;&#1084;
      (&#1054;&#1092;&#1077;&#1088;&#1090;&#1086;&#1081;) &#1054;&#1054;&#1054;
      &ldquo;&#1048;&#1085;&#1090;&#1077;&#1088;&#1085;&#1077;&#1090;
      &#1091;&#1085;&#1080;&#1074;&#1077;&#1088;&#1089;&#1080;&#1090;&#1077;&#1090;&rdquo;
      (&#1080;&#1084;&#1077;&#1085;&#1091;&#1077;&#1084;&#1086;&#1075;&#1086; &#1074;
      &#1076;&#1072;&#1083;&#1100;&#1085;&#1077;&#1081;&#1096;&#1077;&#1084;
      &ldquo;&#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088;&rdquo;, &#1074;
      &#1083;&#1080;&#1094;&#1077; &#1043;&#1077;&#1085;&#1077;&#1088;&#1072;&#1083;&#1100;&#1085;&#1086;&#1075;&#1086;
      &#1076;&#1080;&#1088;&#1077;&#1082;&#1090;&#1086;&#1088;&#1072;
      &#1045;&#1083;&#1100;&#1085;&#1080;&#1094;&#1082;&#1086;&#1075;&#1086; &#1040;&#1085;&#1090;&#1086;&#1085;&#1072;
      &#1070;&#1088;&#1100;&#1077;&#1074;&#1080;&#1095;&#1072;,
      &#1076;&#1077;&#1081;&#1089;&#1090;&#1074;&#1091;&#1102;&#1097;&#1077;&#1075;&#1086; &#1085;&#1072;
      &#1086;&#1089;&#1085;&#1086;&#1074;&#1072;&#1085;&#1080;&#1080; &#1059;&#1089;&#1090;&#1072;&#1074;&#1072;)
      &#1080; &#1089;&#1086;&#1076;&#1077;&#1088;&#1078;&#1080;&#1090; &#1074;&#1089;&#1077;
      &#1089;&#1091;&#1097;&#1077;&#1089;&#1090;&#1074;&#1077;&#1085;&#1085;&#1099;&#1077;
      &#1091;&#1089;&#1083;&#1086;&#1074;&#1080;&#1103; &#1087;&#1086;
      &#1087;&#1088;&#1077;&#1076;&#1086;&#1089;&#1090;&#1072;&#1074;&#1083;&#1077;&#1085;&#1080;&#1102;
      &#1087;&#1088;&#1072;&#1074;&#1072;
      &#1080;&#1089;&#1087;&#1086;&#1083;&#1100;&#1079;&#1086;&#1074;&#1072;&#1085;&#1080;&#1103;
      &#1087;&#1083;&#1072;&#1090;&#1092;&#1086;&#1088;&#1084;&#1099; &ndash;
      &laquo;&#1057;&#1084;&#1072;&#1088;&#1090;&#1074;&#1077;&#1073;&#1080;&#1085;&#1072;&#1088;&raquo;
      (https://smartwebinar.org) &#1074;
      &#1091;&#1089;&#1090;&#1072;&#1085;&#1086;&#1074;&#1083;&#1077;&#1085;&#1085;&#1099;&#1093;
      &#1085;&#1072;&#1089;&#1090;&#1086;&#1103;&#1097;&#1077;&#1081; &#1086;&#1092;&#1077;&#1088;&#1090;&#1077;
      &#1087;&#1088;&#1077;&#1076;&#1077;&#1083;&#1072;&#1093; &#1085;&#1072;
      &#1091;&#1089;&#1083;&#1086;&#1074;&#1080;&#1103;&#1093;
      &#1085;&#1077;&#1080;&#1089;&#1082;&#1083;&#1102;&#1095;&#1080;&#1090;&#1077;&#1083;&#1100;&#1085;&#1086;&#1081;
      &#1083;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1080; (&#1089;
      &#1089;&#1086;&#1093;&#1088;&#1072;&#1085;&#1077;&#1085;&#1080;&#1077;&#1084; &#1079;&#1072;
      &#1083;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088;&#1086;&#1084; &#1087;&#1088;&#1072;&#1074;&#1072;
      &#1074;&#1099;&#1076;&#1072;&#1095;&#1080; &#1083;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1081;
      &#1076;&#1088;&#1091;&#1075;&#1080;&#1084; &#1083;&#1080;&#1094;&#1072;&#1084;;
      &#1076;&#1072;&#1083;&#1077;&#1077; &ndash; &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1103;).</span></p>
  <p className="c4"><span className="c2">&#1042;
      &#1089;&#1086;&#1086;&#1090;&#1074;&#1077;&#1090;&#1089;&#1090;&#1074;&#1080;&#1080; &#1089;
      &#1087;&#1091;&#1085;&#1082;&#1090;&#1086;&#1084; 3 &#1089;&#1090;&#1072;&#1090;&#1100;&#1080; 438
      &#1043;&#1088;&#1072;&#1078;&#1076;&#1072;&#1085;&#1089;&#1082;&#1086;&#1075;&#1086;
      &#1082;&#1086;&#1076;&#1077;&#1082;&#1089;&#1072;
      &#1056;&#1086;&#1089;&#1089;&#1080;&#1081;&#1089;&#1082;&#1086;&#1081;
      &#1060;&#1077;&#1076;&#1077;&#1088;&#1072;&#1094;&#1080;&#1080; (&#1043;&#1050; &#1056;&#1060;) &#1074;
      &#1089;&#1083;&#1091;&#1095;&#1072;&#1077; &#1089;&#1086;&#1074;&#1077;&#1088;&#1096;&#1077;&#1085;&#1080;&#1103;
      &#1086;&#1076;&#1085;&#1086;&#1075;&#1086; &#1080;&#1079;
      &#1076;&#1077;&#1081;&#1089;&#1090;&#1074;&#1080;&#1081;,
      &#1091;&#1082;&#1072;&#1079;&#1072;&#1085;&#1085;&#1099;&#1093; &#1074; &#1087;.1.3.
      &#1085;&#1072;&#1089;&#1090;&#1086;&#1103;&#1097;&#1077;&#1081; &#1086;&#1092;&#1077;&#1088;&#1090;&#1099;
      (&#1076;&#1072;&#1083;&#1077;&#1077; &ndash; &#1054;&#1092;&#1077;&#1088;&#1090;&#1072;)
      &#1102;&#1088;&#1080;&#1076;&#1080;&#1095;&#1077;&#1089;&#1082;&#1086;&#1077; &#1080;&#1083;&#1080;
      &#1092;&#1080;&#1079;&#1080;&#1095;&#1077;&#1089;&#1082;&#1086;&#1077; &#1083;&#1080;&#1094;&#1086;,
      &#1089;&#1090;&#1072;&#1085;&#1086;&#1074;&#1080;&#1090;&#1089;&#1103; &#1080;
      &#1080;&#1084;&#1077;&#1085;&#1091;&#1077;&#1090;&#1089;&#1103;
      &laquo;&#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1086;&#1084;&raquo; (&#1074;
      &#1089;&#1086;&#1086;&#1090;&#1074;&#1077;&#1090;&#1089;&#1090;&#1074;&#1080;&#1080; &#1089;
      &#1087;&#1091;&#1085;&#1082;&#1090;&#1086;&#1084; 3 &#1089;&#1090;&#1072;&#1090;&#1100;&#1080; 438 &#1043;&#1050;
      &#1056;&#1060; &#1072;&#1082;&#1094;&#1077;&#1087;&#1090; &#1054;&#1092;&#1077;&#1088;&#1090;&#1099;
      &#1088;&#1072;&#1074;&#1085;&#1086;&#1089;&#1080;&#1083;&#1077;&#1085;
      &#1079;&#1072;&#1082;&#1083;&#1102;&#1095;&#1077;&#1085;&#1080;&#1102;
      &#1076;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1072; &#1085;&#1072;
      &#1091;&#1089;&#1083;&#1086;&#1074;&#1080;&#1103;&#1093;,
      &#1080;&#1079;&#1083;&#1086;&#1078;&#1077;&#1085;&#1085;&#1099;&#1093; &#1074;
      &#1054;&#1092;&#1077;&#1088;&#1090;&#1077;), &#1072;
      &laquo;&#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088;&raquo; &#1080;
      &laquo;&#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&raquo;
      &#1089;&#1090;&#1072;&#1085;&#1086;&#1074;&#1103;&#1090;&#1089;&#1103;
      &laquo;&#1057;&#1090;&#1086;&#1088;&#1086;&#1085;&#1072;&#1084;&#1080;&raquo; (&#1087;&#1088;&#1080;
      &#1089;&#1086;&#1074;&#1084;&#1077;&#1089;&#1090;&#1085;&#1086;&#1084;
      &#1091;&#1087;&#1086;&#1084;&#1080;&#1085;&#1072;&#1085;&#1080;&#1080;
      &#1080;&#1084;&#1077;&#1085;&#1091;&#1102;&#1090;&#1089;&#1103;
      &laquo;&#1057;&#1090;&#1086;&#1088;&#1086;&#1085;&#1099;&raquo;). &#1054;&#1092;&#1077;&#1088;&#1090;&#1072;
      &#1089; &#1084;&#1086;&#1084;&#1077;&#1085;&#1090;&#1072; &#1072;&#1082;&#1094;&#1077;&#1087;&#1090;&#1072;
      &#1087;&#1088;&#1080;&#1086;&#1073;&#1088;&#1077;&#1090;&#1072;&#1077;&#1090; &#1089;&#1080;&#1083;&#1091;
      &#1076;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1072; &#1076;&#1083;&#1103;
      &#1057;&#1090;&#1086;&#1088;&#1086;&#1085;. &#1040;&#1082;&#1094;&#1077;&#1087;&#1090;
      &#1054;&#1092;&#1077;&#1088;&#1090;&#1099; &#1086;&#1079;&#1085;&#1072;&#1095;&#1072;&#1077;&#1090;
      &#1087;&#1086;&#1083;&#1085;&#1086;&#1077; &#1080;
      &#1073;&#1077;&#1079;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1086;&#1095;&#1085;&#1086;&#1077;
      &#1089;&#1086;&#1075;&#1083;&#1072;&#1089;&#1080;&#1077; &#1089;
      &#1091;&#1089;&#1083;&#1086;&#1074;&#1080;&#1103;&#1084;&#1080;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1080;,
      &#1091;&#1082;&#1072;&#1079;&#1072;&#1085;&#1085;&#1099;&#1093; &#1074;
      &#1085;&#1072;&#1089;&#1090;&#1086;&#1103;&#1097;&#1077;&#1081; &#1054;&#1092;&#1077;&#1088;&#1090;&#1077;,
      &#1072; &#1090;&#1072;&#1082;&#1078;&#1077; &#1087;&#1086;&#1088;&#1086;&#1078;&#1076;&#1072;&#1077;&#1090;
      &#1089;&#1086;&#1086;&#1090;&#1074;&#1077;&#1090;&#1089;&#1090;&#1074;&#1091;&#1102;&#1097;&#1080;&#1077;
      &#1087;&#1088;&#1072;&#1074;&#1072; &#1080;
      &#1086;&#1073;&#1103;&#1079;&#1072;&#1085;&#1085;&#1086;&#1089;&#1090;&#1080; &#1076;&#1083;&#1103;
      &#1082;&#1072;&#1078;&#1076;&#1086;&#1081; &#1080;&#1079; &#1057;&#1090;&#1086;&#1088;&#1086;&#1085;.</span></p>
  <p className="c4 c7"><span className="c2"></span></p>
  <h2 className="c3"><span className="c16">1. &#1058;&#1077;&#1088;&#1084;&#1080;&#1085;&#1099;,
      &#1086;&#1087;&#1088;&#1077;&#1076;&#1077;&#1083;&#1077;&#1085;&#1080;&#1103; &#1080;
      &#1089;&#1086;&#1082;&#1088;&#1072;&#1097;&#1077;&#1085;&#1080;&#1103;</span></h2>
  <p className="c6"><span className="c2">1.1. &#1054;&#1092;&#1077;&#1088;&#1090;&#1072; &ndash;
      &#1085;&#1072;&#1089;&#1090;&#1086;&#1103;&#1097;&#1080;&#1081;
      &#1076;&#1086;&#1082;&#1091;&#1084;&#1077;&#1085;&#1090;
      &laquo;&#1051;&#1048;&#1062;&#1045;&#1053;&#1047;&#1048;&#1054;&#1053;&#1053;&#1067;&#1049;
      &#1044;&#1054;&#1043;&#1054;&#1042;&#1054;&#1056; &#1054;&#1060;&#1045;&#1056;&#1058;&#1040; (&#1085;&#1072;
      &#1087;&#1088;&#1077;&#1076;&#1086;&#1089;&#1090;&#1072;&#1074;&#1083;&#1077;&#1085;&#1080;&#1077;
      &#1087;&#1088;&#1072;&#1074;&#1072;
      &#1080;&#1089;&#1087;&#1086;&#1083;&#1100;&#1079;&#1086;&#1074;&#1072;&#1085;&#1080;&#1103;
      &#1087;&#1083;&#1072;&#1090;&#1092;&#1086;&#1088;&#1084;&#1099;
      &ldquo;&#1057;&#1084;&#1072;&#1088;&#1090;&#1074;&#1077;&#1073;&#1080;&#1085;&#1072;&#1088;&rdquo;)&raquo;,
      &#1086;&#1087;&#1091;&#1073;&#1083;&#1080;&#1082;&#1086;&#1074;&#1072;&#1085;&#1085;&#1099;&#1081; &#1074;
      &#1089;&#1077;&#1090;&#1080; &#1048;&#1085;&#1090;&#1077;&#1088;&#1085;&#1077;&#1090; &#1087;&#1086;
      &#1072;&#1076;&#1088;&#1077;&#1089;&#1091;: </span><span className="c11"><a className="c8"
        href="https://www.google.com/url?q=https://smartwebinar.org/legal/&amp;sa=D&amp;source=editors&amp;ust=1658481495910962&amp;usg=AOvVaw2x2Y_gmkuJFzI2X-VX33F_">https://smartwebinar.org/legal/</a></span><span
      className="c2">, &#1082;&#1086;&#1090;&#1086;&#1088;&#1099;&#1081; &#1087;&#1086;&#1089;&#1083;&#1077;
      &#1072;&#1082;&#1094;&#1077;&#1087;&#1090;&#1072; &#1054;&#1092;&#1077;&#1088;&#1090;&#1099;,
      &#1089;&#1090;&#1072;&#1085;&#1086;&#1074;&#1080;&#1090;&#1089;&#1103;
      &#1076;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1086;&#1084;.</span></p>
  <p className="c6"><span className="c2">1.2. &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090; &ndash;
      &#1083;&#1080;&#1094;&#1086; &#1082;&#1086;&#1090;&#1086;&#1088;&#1086;&#1077;
      &#1087;&#1088;&#1086;&#1080;&#1079;&#1074;&#1077;&#1083;&#1086; &#1072;&#1082;&#1094;&#1077;&#1087;&#1090;
      &#1054;&#1092;&#1077;&#1088;&#1090;&#1099; &#1080;
      &#1079;&#1072;&#1082;&#1083;&#1102;&#1095;&#1080;&#1074;&#1096;&#1077;&#1077; &#1090;&#1072;&#1082;&#1080;&#1084;
      &#1086;&#1073;&#1088;&#1072;&#1079;&#1086;&#1084;
      &#1083;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1086;&#1085;&#1085;&#1099;&#1081;
      &#1076;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;.</span></p>
  <p className="c6"><span className="c2">1.3. &#1040;&#1082;&#1094;&#1077;&#1087;&#1090;
      &#1086;&#1092;&#1077;&#1088;&#1090;&#1099; &ndash; &#1087;&#1086;&#1083;&#1085;&#1086;&#1077; &#1080;
      &#1073;&#1077;&#1079;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1086;&#1095;&#1085;&#1086;&#1077;
      &#1087;&#1088;&#1080;&#1085;&#1103;&#1090;&#1080;&#1077; &#1054;&#1092;&#1077;&#1088;&#1090;&#1099;
      &#1089;&#1086;&#1074;&#1077;&#1088;&#1096;&#1072;&#1077;</span><span className="c12">&#1090;&#1089;&#1103;
      &#1087;</span><span className="c2">&#1091;&#1090;&#1077;&#1084; &#1074;&#1093;&#1086;&#1076;&#1072; &#1074;
      &#1087;&#1077;&#1088;&#1074;&#1099;&#1081; &#1088;&#1072;&#1079; &#1074;
      &#1083;&#1080;&#1095;&#1085;&#1099;&#1081; &#1082;&#1072;&#1073;&#1080;&#1085;&#1077;&#1090;.
      &#1055;&#1088;&#1080; &#1101;&#1090;&#1086;&#1084;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1086;&#1084;
      &#1089;&#1090;&#1072;&#1085;&#1086;&#1074;&#1080;&#1090;&#1089;&#1103; &#1080;&#1084;&#1077;&#1085;&#1085;&#1086;
      &#1090;&#1086;&#1090; &#1095;&#1100;&#1080;
      &#1080;&#1076;&#1077;&#1085;&#1090;&#1080;&#1092;&#1080;&#1082;&#1072;&#1094;&#1080;&#1086;&#1085;&#1085;&#1099;&#1077;
      &#1076;&#1072;&#1085;&#1085;&#1099;&#1077; &#1073;&#1099;&#1083;&#1080;
      &#1091;&#1082;&#1072;&#1079;&#1072;&#1085;&#1099; &#1087;&#1088;&#1080;
      &#1088;&#1077;&#1075;&#1080;&#1089;&#1090;&#1088;&#1072;&#1094;&#1080;&#1080; &#1085;&#1072;
      &#1055;&#1083;&#1072;&#1090;&#1092;&#1086;&#1088;&#1084;&#1077;.</span></p>
  <p className="c6"><span className="c2">1.4. &#1055;&#1083;&#1072;&#1090;&#1092;&#1086;&#1088;&#1084;&#1072; &ndash;
      &#1087;&#1088;&#1086;&#1075;&#1088;&#1072;&#1084;&#1084;&#1085;&#1086;&#1077;
      &#1086;&#1073;&#1077;&#1089;&#1087;&#1077;&#1095;&#1077;&#1085;&#1080;&#1077; &#1087;&#1086;&#1076;
      &#1085;&#1072;&#1080;&#1084;&#1077;&#1085;&#1086;&#1074;&#1072;&#1085;&#1080;&#1077;
      &laquo;&#1057;&#1084;&#1072;&#1088;&#1090;&#1074;&#1077;&#1073;&#1080;&#1085;&#1072;&#1088;&raquo;
      (https://smartwebinar.org), &#1076;&#1086;&#1089;&#1090;&#1091;&#1087; &#1082;
      &#1082;&#1086;&#1090;&#1086;&#1088;&#1086;&#1084;&#1091;
      &#1087;&#1088;&#1077;&#1076;&#1086;&#1089;&#1090;&#1072;&#1074;&#1083;&#1103;&#1077;&#1090;&#1089;&#1103;
      &#1095;&#1077;&#1088;&#1077;&#1079; &#1073;&#1088;&#1072;&#1091;&#1079;&#1077;&#1088;,
      &#1103;&#1074;&#1083;&#1103;&#1102;&#1097;&#1077;&#1077;&#1089;&#1103;
      &#1087;&#1088;&#1077;&#1076;&#1089;&#1090;&#1072;&#1074;&#1083;&#1077;&#1085;&#1085;&#1086;&#1081; &#1074;
      &#1086;&#1073;&#1098;&#1077;&#1082;&#1090;&#1080;&#1074;&#1085;&#1086;&#1081; &#1092;&#1086;&#1088;&#1084;&#1077;
      &#1089;&#1086;&#1074;&#1086;&#1082;&#1091;&#1087;&#1085;&#1086;&#1089;&#1090;&#1100;&#1102;
      &#1076;&#1072;&#1085;&#1085;&#1099;&#1093; &#1080; &#1082;&#1086;&#1084;&#1072;&#1085;&#1076;, &#1074;
      &#1090;&#1086;&#1084; &#1095;&#1080;&#1089;&#1083;&#1077;
      &#1080;&#1089;&#1093;&#1086;&#1076;&#1085;&#1086;&#1075;&#1086; &#1090;&#1077;&#1082;&#1089;&#1090;&#1072;,
      &#1073;&#1072;&#1079;&#1099; &#1076;&#1072;&#1085;&#1085;&#1099;&#1093;.
      &#1055;&#1083;&#1072;&#1090;&#1092;&#1086;&#1088;&#1084;&#1072; &ndash; &#1101;&#1090;&#1086;
      &#1085;&#1072;&#1073;&#1086;&#1088; &#1092;&#1091;&#1085;&#1082;&#1094;&#1080;&#1081; &#1076;&#1083;&#1103;
      &#1086;&#1088;&#1075;&#1072;&#1085;&#1080;&#1079;&#1072;&#1094;&#1080;&#1080;
      &#1086;&#1085;&#1083;&#1072;&#1081;&#1085;-&#1086;&#1073;&#1091;&#1095;&#1077;&#1085;&#1080;&#1103;
      &#1087;&#1086;&#1089;&#1088;&#1077;&#1076;&#1089;&#1090;&#1074;&#1086;&#1084;
      &#1087;&#1088;&#1086;&#1074;&#1077;&#1076;&#1077;&#1085;&#1080;&#1103;
      &#1074;&#1077;&#1073;&#1080;&#1085;&#1072;&#1088;&#1086;&#1074; &#1080;
      &#1072;&#1074;&#1090;&#1086;&#1074;&#1077;&#1073;&#1080;&#1085;&#1072;&#1088;&#1086;&#1074;.</span></p>
  <p className="c6"><span className="c2">1.</span><span className="c12">5</span><span className="c2">.
      &#1055;&#1088;&#1086;&#1092;&#1080;&#1083;&#1100; &ndash;
      &#1089;&#1086;&#1074;&#1086;&#1082;&#1091;&#1087;&#1085;&#1086;&#1089;&#1090;&#1100;
      &#1076;&#1072;&#1085;&#1085;&#1099;&#1093; &#1086;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1077;,
      &#1085;&#1077;&#1086;&#1073;&#1093;&#1086;&#1076;&#1080;&#1084;&#1072;&#1103; &#1076;&#1083;&#1103;
      &#1072;&#1091;&#1090;&#1077;&#1085;&#1090;&#1080;&#1092;&#1080;&#1082;&#1072;&#1094;&#1080;&#1080; &#1080;
      &#1087;&#1088;&#1077;&#1076;&#1086;&#1089;&#1090;&#1072;&#1074;&#1083;&#1077;&#1085;&#1080;&#1103;
      &#1076;&#1086;&#1089;&#1090;&#1091;&#1087;&#1072; &#1082;
      &#1085;&#1072;&#1089;&#1090;&#1088;&#1086;&#1081;&#1082;&#1072;&#1084; &#1080;
      &#1076;&#1072;&#1085;&#1085;&#1099;&#1084;,
      &#1089;&#1086;&#1076;&#1077;&#1088;&#1078;&#1072;&#1097;&#1080;&#1084;&#1089;&#1103; &#1086;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1077; &#1085;&#1072;
      &#1055;&#1083;&#1072;&#1090;&#1092;&#1086;&#1088;&#1084;&#1077;, &#1076;&#1086;&#1089;&#1090;&#1091;&#1087;
      &#1082; &#1082;&#1086;&#1090;&#1086;&#1088;&#1086;&#1081;
      &#1087;&#1088;&#1077;&#1076;&#1086;&#1089;&#1090;&#1072;&#1074;&#1083;&#1103;&#1077;&#1090;&#1089;&#1103;
      &#1087;&#1086;&#1089;&#1083;&#1077; &#1074;&#1074;&#1077;&#1076;&#1077;&#1085;&#1080;&#1103;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1086;&#1084;
      &#1083;&#1086;&#1075;&#1080;&#1085;&#1072; &#1080; &#1087;&#1072;&#1088;&#1086;&#1083;&#1103;.</span></p>
  <p className="c6" id="h.gjdgxs"><span className="c2">1.</span><span className="c12">6</span><span className="c2">.
      &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088; &ndash;
      &#1083;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1086;&#1085;&#1085;&#1099;&#1081;
      &#1076;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088; &#1084;&#1077;&#1078;&#1076;&#1091;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088;&#1086;&#1084; &#1080;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1086;&#1084;,
      &#1082;&#1086;&#1090;&#1086;&#1088;&#1099;&#1081;
      &#1079;&#1072;&#1082;&#1083;&#1102;&#1095;&#1072;&#1077;&#1090;&#1089;&#1103;
      &#1087;&#1086;&#1089;&#1088;&#1077;&#1076;&#1089;&#1090;&#1074;&#1086;&#1084;
      &#1072;&#1082;&#1094;&#1077;&#1087;&#1090;&#1072; &#1054;&#1092;&#1077;&#1088;&#1090;&#1099;.</span></p>
  <p className="c6 c7"><span className="c2"></span></p>
  <h2 className="c3"><span className="c16">2. &#1055;&#1088;&#1077;&#1076;&#1084;&#1077;&#1090;
      &#1076;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1072;</span></h2>
  <p className="c6"><span className="c2">2.1. &#1042;
      &#1089;&#1086;&#1086;&#1090;&#1074;&#1077;&#1090;&#1089;&#1090;&#1074;&#1080;&#1080; &#1089;
      &#1091;&#1089;&#1083;&#1086;&#1074;&#1080;&#1103;&#1084;&#1080;
      &#1085;&#1072;&#1089;&#1090;&#1086;&#1103;&#1097;&#1077;&#1075;&#1086;
      &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1072;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088;
      &#1087;&#1088;&#1077;&#1076;&#1086;&#1089;&#1090;&#1072;&#1074;&#1083;&#1103;&#1077;&#1090;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1091; &#1087;&#1088;&#1072;&#1074;&#1086;
      &#1080;&#1089;&#1087;&#1086;&#1083;&#1100;&#1079;&#1086;&#1074;&#1072;&#1085;&#1080;&#1103;
      &#1055;&#1083;&#1072;&#1090;&#1092;&#1086;&#1088;&#1084;&#1086;&#1081; &#1085;&#1072;
      &#1091;&#1089;&#1083;&#1086;&#1074;&#1080;&#1103;&#1093; &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1080;
      (&#1089; &#1089;&#1086;&#1093;&#1088;&#1072;&#1085;&#1077;&#1085;&#1080;&#1077;&#1084; &#1079;&#1072;
      &#1089;&#1086;&#1073;&#1086;&#1081; &#1087;&#1088;&#1072;&#1074;&#1072; &#1074;&#1099;&#1076;&#1072;&#1095;&#1080;
      &#1083;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1081; &#1076;&#1088;&#1091;&#1075;&#1080;&#1084;
      &#1083;&#1080;&#1094;&#1072;&#1084;). &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;
      &#1087;&#1088;&#1080;&#1085;&#1080;&#1084;&#1072;&#1077;&#1090; &#1085;&#1072; &#1089;&#1077;&#1073;&#1103;
      &#1086;&#1073;&#1103;&#1079;&#1072;&#1085;&#1085;&#1086;&#1089;&#1090;&#1100;
      &#1074;&#1099;&#1087;&#1083;&#1072;&#1090;&#1080;&#1090;&#1100;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088;&#1091;
      &#1074;&#1086;&#1079;&#1085;&#1072;&#1075;&#1088;&#1072;&#1078;&#1076;&#1077;&#1085;&#1080;&#1077; &#1074;
      &#1089;&#1086;&#1086;&#1090;&#1074;&#1077;&#1090;&#1089;&#1090;&#1074;&#1080;&#1080; &#1089;
      &#1088;&#1072;&#1079;&#1076;&#1077;&#1083;&#1086;&#1084; 4
      &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1072; (&#1074; &#1089;&#1083;&#1091;&#1095;&#1072;&#1077;
      &#1087;&#1083;&#1072;&#1090;&#1085;&#1086;&#1075;&#1086;
      &#1080;&#1089;&#1087;&#1086;&#1083;&#1100;&#1079;&#1086;&#1074;&#1072;&#1085;&#1080;&#1103;
      &#1055;&#1083;&#1072;&#1090;&#1092;&#1086;&#1088;&#1084;&#1099;) &#1080;
      &#1074;&#1099;&#1087;&#1086;&#1083;&#1085;&#1103;&#1090;&#1100; &#1080;&#1085;&#1099;&#1077;
      &#1091;&#1089;&#1083;&#1086;&#1074;&#1080;&#1103;
      &#1085;&#1072;&#1089;&#1090;&#1086;&#1103;&#1097;&#1077;&#1075;&#1086;
      &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1072;.</span></p>
  <p className="c6"><span className="c2">2.2. &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;
      &#1074;&#1087;&#1088;&#1072;&#1074;&#1077;
      &#1080;&#1089;&#1087;&#1086;&#1083;&#1100;&#1079;&#1086;&#1074;&#1072;&#1090;&#1100;
      &#1055;&#1083;&#1072;&#1090;&#1092;&#1086;&#1088;&#1084;&#1091; &#1074;
      &#1089;&#1086;&#1086;&#1090;&#1074;&#1077;&#1090;&#1089;&#1090;&#1074;&#1080;&#1080; &#1089;
      &#1091;&#1089;&#1083;&#1086;&#1074;&#1080;&#1103;&#1084;&#1080;
      &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1072; &#1085;&#1072;
      &#1090;&#1077;&#1088;&#1088;&#1080;&#1090;&#1086;&#1088;&#1080;&#1080; &#1074;&#1089;&#1077;&#1075;&#1086;
      &#1084;&#1080;&#1088;&#1072;.</span></p>
  <p className="c6"><span className="c2">2.3. &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090; &#1085;&#1077;
      &#1080;&#1084;&#1077;&#1077;&#1090; &#1087;&#1088;&#1072;&#1074;&#1072;
      &#1087;&#1088;&#1077;&#1076;&#1086;&#1089;&#1090;&#1072;&#1074;&#1083;&#1103;&#1090;&#1100;
      &#1089;&#1091;&#1073;&#1083;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1080;
      &#1090;&#1088;&#1077;&#1090;&#1100;&#1080;&#1084; &#1083;&#1080;&#1094;&#1072;&#1084; &#1085;&#1072;
      &#1080;&#1089;&#1087;&#1086;&#1083;&#1100;&#1079;&#1086;&#1074;&#1072;&#1085;&#1080;&#1077;
      &#1055;&#1083;&#1072;&#1090;&#1092;&#1086;&#1088;&#1084;&#1099;.</span></p>
  <p className="c6"><span className="c2">2.4. &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088; &#1085;&#1077;
      &#1086;&#1082;&#1072;&#1079;&#1099;&#1074;&#1072;&#1077;&#1090; &#1091;&#1089;&#1083;&#1091;&#1075;&#1080;
      &#1087;&#1086; &#1086;&#1073;&#1091;&#1095;&#1077;&#1085;&#1080;&#1102;, &#1072; &#1076;&#1072;&#1077;&#1090;
      &#1080;&#1085;&#1089;&#1090;&#1088;&#1091;&#1082;&#1094;&#1080;&#1080; &#1076;&#1083;&#1103;
      &#1080;&#1089;&#1087;&#1086;&#1083;&#1100;&#1079;&#1086;&#1074;&#1072;&#1085;&#1080;&#1103;
      &#1055;&#1083;&#1072;&#1090;&#1092;&#1086;&#1088;&#1084;&#1099;.</span></p>
  <p className="c6"><span className="c2">2.5. &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;
      &#1074;&#1087;&#1088;&#1072;&#1074;&#1077;
      &#1080;&#1089;&#1087;&#1086;&#1083;&#1100;&#1079;&#1086;&#1074;&#1072;&#1090;&#1100;
      &#1055;&#1083;&#1072;&#1090;&#1092;&#1086;&#1088;&#1084;&#1091; &#1076;&#1083;&#1103;
      &#1086;&#1088;&#1075;&#1072;&#1085;&#1080;&#1079;&#1072;&#1094;&#1080;&#1080;
      &#1086;&#1085;&#1083;&#1072;&#1081;&#1085;-&#1086;&#1073;&#1091;&#1095;&#1077;&#1085;&#1080;&#1103;
      &#1087;&#1086;&#1089;&#1088;&#1077;&#1076;&#1089;&#1090;&#1074;&#1086;&#1084;
      &#1087;&#1088;&#1086;&#1074;&#1077;&#1076;&#1077;&#1085;&#1080;&#1103;
      &#1074;&#1077;&#1073;&#1080;&#1085;&#1072;&#1088;&#1086;&#1074; &#1080;
      &#1072;&#1074;&#1090;&#1086;&#1074;&#1077;&#1073;&#1080;&#1085;&#1072;&#1088;&#1086;&#1074;.</span></p>
  <p className="c6"><span className="c2">2.6. &#1057;&#1088;&#1086;&#1082;&#1080; &#1080;
      &#1091;&#1089;&#1083;&#1086;&#1074;&#1080;&#1103;
      &#1087;&#1088;&#1077;&#1076;&#1086;&#1089;&#1090;&#1072;&#1074;&#1083;&#1077;&#1085;&#1080;&#1103;
      &#1087;&#1088;&#1077;&#1076;&#1086;&#1089;&#1090;&#1072;&#1074;&#1083;&#1077;&#1085;&#1080;&#1103;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1080;.</span></p>
  <ul className="c10 lst-kix_yyrlzxvtc2xr-0 start">
    <li className="c5 li-bullet-0"><span className="c2">&#1055;&#1083;&#1072;&#1090;&#1085;&#1086;&#1077;
        &#1080;&#1089;&#1087;&#1086;&#1083;&#1100;&#1079;&#1086;&#1074;&#1072;&#1085;&#1080;&#1077; &#1085;&#1072;
        &#1085;&#1077;&#1086;&#1075;&#1088;&#1072;&#1085;&#1080;&#1095;&#1077;&#1085;&#1085;&#1099;&#1081;
        &#1089;&#1088;&#1086;&#1082;, &#1089;
        &#1086;&#1075;&#1088;&#1072;&#1085;&#1080;&#1095;&#1077;&#1085;&#1080;&#1077;&#1084;
        &#1082;&#1086;&#1083;&#1080;&#1095;&#1077;&#1089;&#1090;&#1074;&#1072;
        &#1091;&#1095;&#1072;&#1089;&#1090;&#1085;&#1080;&#1082;&#1086;&#1074; &#1074;
        &#1089;&#1086;&#1086;&#1090;&#1074;&#1077;&#1090;&#1089;&#1090;&#1074;&#1080;&#1080; &#1089;
        &#1088;&#1072;&#1079;&#1076;&#1077;&#1083;&#1086;&#1084; 4
        &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1072;.</span></li>
  </ul>
  <p className="c6"><span className="c2">2.7. &#1042; &#1089;&#1083;&#1091;&#1095;&#1072;&#1077;
      &#1087;&#1088;&#1077;&#1082;&#1088;&#1072;&#1097;&#1077;&#1085;&#1080;&#1103;
      &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1072;,, &#1082;&#1086;&#1085;&#1090;&#1077;&#1085;&#1090;
      (&#1092;&#1086;&#1090;&#1086;-,
      &#1074;&#1080;&#1076;&#1077;&#1086;&#1084;&#1072;&#1090;&#1077;&#1088;&#1080;&#1072;&#1083;&#1099;)
      &#1088;&#1072;&#1079;&#1084;&#1077;&#1097;&#1077;&#1085;&#1085;&#1099;&#1081;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1086;&#1084; &#1085;&#1072;
      &#1055;&#1083;&#1072;&#1090;&#1092;&#1086;&#1088;&#1084;&#1077;
      &#1089;&#1086;&#1093;&#1088;&#1072;&#1085;&#1103;&#1077;&#1090;&#1089;&#1103; &#1074;
      &#1090;&#1077;&#1095;&#1077;&#1085;&#1080;&#1077; 12 &#1084;&#1077;&#1089;&#1103;&#1094;&#1077;&#1074; &#1089;
      &#1084;&#1086;&#1084;&#1077;&#1085;&#1090;&#1072;
      &#1087;&#1088;&#1077;&#1082;&#1088;&#1072;&#1097;&#1077;&#1085;&#1080;&#1103;
      &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1072;. &#1055;&#1086;&#1089;&#1083;&#1077;
      &#1080;&#1089;&#1090;&#1077;&#1095;&#1077;&#1085;&#1080;&#1103; &#1089;&#1088;&#1086;&#1082;&#1072;
      &#1093;&#1088;&#1072;&#1085;&#1077;&#1085;&#1080;&#1103; &#1076;&#1072;&#1085;&#1085;&#1099;&#1093;
      &#1086;&#1085;&#1080; &#1089;&#1090;&#1080;&#1088;&#1072;&#1102;&#1090;&#1089;&#1103;
      &#1072;&#1074;&#1090;&#1086;&#1084;&#1072;&#1090;&#1080;&#1095;&#1077;&#1089;&#1082;&#1080; &#1073;&#1077;&#1079;
      &#1074;&#1086;&#1079;&#1084;&#1086;&#1078;&#1085;&#1086;&#1089;&#1090;&#1080;
      &#1074;&#1086;&#1089;&#1089;&#1090;&#1072;&#1085;&#1086;&#1074;&#1083;&#1077;&#1085;&#1080;&#1103;.</span></p>
  <p className="c6"><span className="c2">2.8. &#1042;
      &#1089;&#1086;&#1086;&#1090;&#1074;&#1077;&#1090;&#1089;&#1090;&#1074;&#1080;&#1080; &#1089;
      &#1087;&#1086;&#1083;&#1086;&#1078;&#1077;&#1085;&#1080;&#1103;&#1084;&#1080; &#1095;. 1 &#1089;&#1090;. 1237
      &#1043;&#1050; &#1056;&#1060; &#1057;&#1090;&#1086;&#1088;&#1086;&#1085;&#1099;
      &#1091;&#1089;&#1090;&#1072;&#1085;&#1086;&#1074;&#1080;&#1083;&#1080;, &#1095;&#1090;&#1086;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;
      &#1086;&#1089;&#1074;&#1086;&#1073;&#1086;&#1078;&#1076;&#1072;&#1077;&#1090;&#1089;&#1103; &#1086;&#1090;
      &#1086;&#1073;&#1103;&#1079;&#1072;&#1085;&#1085;&#1086;&#1089;&#1090;&#1080;
      &#1087;&#1088;&#1077;&#1076;&#1086;&#1089;&#1090;&#1072;&#1074;&#1083;&#1103;&#1090;&#1100;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088;&#1091; &#1086;&#1090;&#1095;&#1077;&#1090;&#1099;
      &#1086;&#1073; &#1080;&#1089;&#1087;&#1086;&#1083;&#1100;&#1079;&#1086;&#1074;&#1072;&#1085;&#1080;&#1080;
      &#1055;&#1083;&#1072;&#1090;&#1092;&#1086;&#1088;&#1084;&#1099;.</span></p>
  <p className="c6"><span className="c2">2.9. &#1042;&#1089;&#1077; &#1091;&#1089;&#1083;&#1086;&#1074;&#1080;&#1103;,
      &#1087;&#1088;&#1077;&#1076;&#1091;&#1089;&#1084;&#1086;&#1090;&#1088;&#1077;&#1085;&#1085;&#1099;&#1077;
      &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1086;&#1084;,
      &#1086;&#1090;&#1085;&#1086;&#1089;&#1103;&#1090;&#1089;&#1103; &#1082;&#1072;&#1082; &#1082;
      &#1055;&#1083;&#1072;&#1090;&#1092;&#1086;&#1088;&#1084;&#1077; &#1074; &#1094;&#1077;&#1083;&#1086;&#1084;,
      &#1090;&#1072;&#1082; &#1080; &#1082;&#1086; &#1074;&#1089;&#1077;&#1084; &#1077;&#1075;&#1086;
      &#1082;&#1086;&#1084;&#1087;&#1086;&#1085;&#1077;&#1085;&#1090;&#1072;&#1084; &#1074;
      &#1086;&#1090;&#1076;&#1077;&#1083;&#1100;&#1085;&#1086;&#1089;&#1090;&#1080;,
      &#1074;&#1082;&#1083;&#1102;&#1095;&#1072;&#1103;
      &#1086;&#1073;&#1085;&#1086;&#1074;&#1083;&#1077;&#1085;&#1080;&#1103;,
      &#1076;&#1086;&#1087;&#1086;&#1083;&#1085;&#1077;&#1085;&#1080;&#1103;,
      &#1076;&#1086;&#1073;&#1072;&#1074;&#1083;&#1103;&#1077;&#1084;&#1099;&#1077;
      &#1082;&#1086;&#1084;&#1087;&#1086;&#1085;&#1077;&#1085;&#1090;&#1099; &#1080;
      &#1082;&#1086;&#1084;&#1087;&#1086;&#1085;&#1077;&#1085;&#1090;&#1099;
      &#1087;&#1088;&#1086;&#1075;&#1088;&#1072;&#1084;&#1084;&#1085;&#1086;&#1075;&#1086;
      &#1086;&#1073;&#1077;&#1089;&#1087;&#1077;&#1095;&#1077;&#1085;&#1080;&#1103;,
      &#1086;&#1073;&#1077;&#1089;&#1087;&#1077;&#1095;&#1080;&#1074;&#1072;&#1102;&#1097;&#1080;&#1077;
      &#1088;&#1072;&#1073;&#1086;&#1090;&#1091; &#1055;&#1083;&#1072;&#1090;&#1092;&#1086;&#1088;&#1084;&#1099;,
      &#1089;&#1072;&#1081;&#1090;&#1099; &#1080; &#1089;&#1077;&#1088;&#1074;&#1080;&#1089;&#1099;.</span></p>
  <p className="c6"><span className="c2">2.10. &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;
      &#1090;&#1072;&#1082;&#1078;&#1077; &#1074;&#1087;&#1088;&#1072;&#1074;&#1077;
      &#1079;&#1072;&#1087;&#1088;&#1086;&#1089;&#1080;&#1090;&#1100; &#1091;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088;&#1072;
      &#1091;&#1089;&#1083;&#1091;&#1075;&#1080;:</span></p>
  <ul className="c10 lst-kix_sl133cxntny7-0 start">
    <li className="c5 li-bullet-0"><span className="c2">&#1087;&#1077;&#1088;&#1077;&#1085;&#1086;&#1089;
        &#1084;&#1072;&#1090;&#1077;&#1088;&#1080;&#1072;&#1083;&#1086;&#1074; &#1080;
        &#1082;&#1083;&#1080;&#1077;&#1085;&#1090;&#1086;&#1074; &#1080;&#1079;
        &#1087;&#1088;&#1077;&#1076;&#1099;&#1076;&#1091;&#1097;&#1077;&#1075;&#1086;
        &#1084;&#1077;&#1089;&#1090;&#1072; &#1093;&#1088;&#1072;&#1085;&#1077;&#1085;&#1080;&#1103; &#1080;
        &#1088;&#1072;&#1073;&#1086;&#1090;&#1099;;</span></li>
    <li className="c5 li-bullet-0"><span className="c2">&#1085;&#1072;&#1089;&#1090;&#1088;&#1086;&#1081;&#1082;&#1072;
        &#1072;&#1082;&#1082;&#1072;&#1091;&#1085;&#1090;&#1072; &#1080;
        &#1072;&#1074;&#1090;&#1086;&#1084;&#1072;&#1090;&#1080;&#1095;&#1077;&#1089;&#1082;&#1080;&#1093;
        &#1087;&#1088;&#1086;&#1094;&#1077;&#1089;&#1089;&#1086;&#1074;.</span></li>
  </ul>
  <p className="c6 c7 c25"><span className="c2"></span></p>
  <h2 className="c3"><span className="c16">3. &#1055;&#1088;&#1072;&#1074;&#1072; &#1080;
      &#1086;&#1073;&#1103;&#1079;&#1072;&#1085;&#1085;&#1086;&#1089;&#1090;&#1080;
      &#1089;&#1090;&#1086;&#1088;&#1086;&#1085;</span></h2>
  <p className="c6"><span className="c16 c19">3.1. &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088;
      &#1086;&#1073;&#1103;&#1079;&#1091;&#1077;&#1090;&#1089;&#1103;:</span></p>
  <p className="c6"><span className="c2">3.1.1.
      &#1055;&#1088;&#1077;&#1076;&#1086;&#1089;&#1090;&#1072;&#1074;&#1080;&#1090;&#1100;
      &#1076;&#1086;&#1089;&#1090;&#1091;&#1087; &#1082; &#1055;&#1083;&#1072;&#1090;&#1092;&#1086;&#1088;&#1084;&#1077;
      &#1085;&#1072; &#1091;&#1089;&#1083;&#1086;&#1074;&#1080;&#1103;&#1093;
      &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1072;.</span></p>
  <p className="c6 c7"><span className="c2"></span></p>
  <p className="c6"><span className="c16 c19">3.2. &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088;
      &#1080;&#1084;&#1077;&#1077;&#1090; &#1087;&#1088;&#1072;&#1074;&#1086;:</span></p>
  <p className="c6"><span className="c12">3.2.1. &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088;
      &#1080;&#1084;&#1077;&#1077;&#1090; &#1087;&#1088;&#1072;&#1074;&#1086; &#1074;
      &#1083;&#1102;&#1073;&#1086;&#1081; &#1084;&#1086;&#1084;&#1077;&#1085;&#1090;
      &#1080;&#1079;&#1084;&#1077;&#1085;&#1103;&#1090;&#1100; &#1091;&#1089;&#1083;&#1086;&#1074;&#1080;&#1103;
      &#1085;&#1072;&#1089;&#1090;&#1086;&#1103;&#1097;&#1077;&#1075;&#1086;
      &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1072; &#1074;
      &#1086;&#1076;&#1085;&#1086;&#1089;&#1090;&#1086;&#1088;&#1086;&#1085;&#1085;&#1077;&#1084;
      &#1087;&#1086;&#1088;&#1103;&#1076;&#1082;&#1077; &#1073;&#1077;&#1079;
      &#1087;&#1088;&#1077;&#1076;&#1074;&#1072;&#1088;&#1080;&#1090;&#1077;&#1083;&#1100;&#1085;&#1086;&#1075;&#1086;
      &#1089;&#1086;&#1075;&#1083;&#1072;&#1089;&#1086;&#1074;&#1072;&#1085;&#1080;&#1103; &#1089;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1086;&#1084;,
      &#1086;&#1073;&#1077;&#1089;&#1087;&#1077;&#1095;&#1080;&#1074;&#1072;&#1103; &#1087;&#1088;&#1080;
      &#1101;&#1090;&#1086;&#1084; &#1087;&#1091;&#1073;&#1083;&#1080;&#1082;&#1072;&#1094;&#1080;&#1102;
      &#1080;&#1079;&#1084;&#1077;&#1085;&#1077;&#1085;&#1085;&#1099;&#1093;
      &#1091;&#1089;&#1083;&#1086;&#1074;&#1080;&#1081; &#1085;&#1072;
      &#1048;&#1085;&#1090;&#1077;&#1088;&#1085;&#1077;&#1090;-&#1088;&#1077;&#1089;&#1091;&#1088;&#1089;&#1077;
      &#1087;&#1086; &#1072;&#1076;&#1088;&#1077;&#1089;&#1091;: </span><span className="c18 c12"><a className="c8"
        href="https://www.google.com/url?q=https://smartwebinar.org/legal/&amp;sa=D&amp;source=editors&amp;ust=1658481495912981&amp;usg=AOvVaw3EXp5EiPhfs-KExZErefyI">https://smartwebinar.org/legal/</a></span><span
      className="c2">&nbsp;&#1085;&#1077; &#1084;&#1077;&#1085;&#1077;&#1077; &#1095;&#1077;&#1084; &#1079;&#1072;
      &#1086;&#1076;&#1080;&#1085; &#1076;&#1077;&#1085;&#1100; &#1076;&#1086; &#1080;&#1093;
      &#1074;&#1074;&#1086;&#1076;&#1072; &#1074; &#1076;&#1077;&#1081;&#1089;&#1090;&#1074;&#1080;&#1077;.
      &#1048;&#1079;&#1084;&#1077;&#1085;&#1077;&#1085;&#1085;&#1099;&#1081;
      &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;
      &#1089;&#1090;&#1072;&#1085;&#1086;&#1074;&#1080;&#1090;&#1089;&#1103;
      &#1086;&#1073;&#1103;&#1079;&#1072;&#1090;&#1077;&#1083;&#1100;&#1085;&#1099;&#1084; &#1076;&#1083;&#1103;
      &#1057;&#1090;&#1086;&#1088;&#1086;&#1085;, &#1074; &#1090;.&#1095;. &#1076;&#1083;&#1103;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1072;,
      &#1082;&#1086;&#1090;&#1086;&#1088;&#1099;&#1081;
      &#1072;&#1082;&#1094;&#1077;&#1087;&#1090;&#1086;&#1074;&#1072;&#1083; &#1054;&#1092;&#1077;&#1088;&#1090;&#1091;
      &#1076;&#1086; &#1080;&#1079;&#1084;&#1077;&#1085;&#1077;&#1085;&#1080;&#1081;.
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088; &#1080;&#1084;&#1077;&#1077;&#1090;
      &#1087;&#1088;&#1072;&#1074;&#1086; &#1074; &#1083;&#1102;&#1073;&#1086;&#1081;
      &#1084;&#1086;&#1084;&#1077;&#1085;&#1090; &#1080;&#1079;&#1084;&#1077;&#1085;&#1103;&#1090;&#1100;
      &#1091;&#1089;&#1083;&#1086;&#1074;&#1080;&#1103; &#1086;&#1087;&#1083;&#1072;&#1090;&#1099; &#1080;
      &#1090;&#1072;&#1088;&#1080;&#1092;&#1080;&#1082;&#1072;&#1094;&#1080;&#1080; &#1074;
      &#1086;&#1076;&#1085;&#1086;&#1089;&#1090;&#1086;&#1088;&#1086;&#1085;&#1085;&#1077;&#1084;
      &#1087;&#1086;&#1088;&#1103;&#1076;&#1082;&#1077; &#1073;&#1077;&#1079;
      &#1087;&#1088;&#1077;&#1076;&#1074;&#1072;&#1088;&#1080;&#1090;&#1077;&#1083;&#1100;&#1085;&#1086;&#1075;&#1086;
      &#1089;&#1086;&#1075;&#1083;&#1072;&#1089;&#1086;&#1074;&#1072;&#1085;&#1080;&#1103; &#1089;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1086;&#1084;.
      &#1042;&#1099;&#1096;&#1077;&#1091;&#1082;&#1072;&#1079;&#1072;&#1085;&#1085;&#1099;&#1077;
      &#1080;&#1079;&#1084;&#1077;&#1085;&#1077;&#1085;&#1080;&#1103; &#1085;&#1077; &#1084;&#1086;&#1075;&#1091;&#1090;
      &#1089;&#1086;&#1082;&#1088;&#1072;&#1090;&#1080;&#1090;&#1100; &#1089;&#1088;&#1086;&#1082; &#1080;&#1083;&#1080;
      &#1087;&#1086;&#1074;&#1099;&#1089;&#1080;&#1090;&#1100;
      &#1089;&#1090;&#1086;&#1080;&#1084;&#1086;&#1089;&#1090;&#1100; &#1091;&#1078;&#1077;
      &#1086;&#1087;&#1083;&#1072;&#1095;&#1077;&#1085;&#1085;&#1086;&#1081;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1080;.</span></p>
  <p className="c6"><span className="c2">3.2.2. &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088; &#1089;
      &#1094;&#1077;&#1083;&#1100;&#1102; &#1088;&#1072;&#1079;&#1074;&#1080;&#1090;&#1080;&#1103;
      &#1055;&#1083;&#1072;&#1090;&#1092;&#1086;&#1088;&#1084;&#1099;, &#1080;&#1084;&#1077;&#1077;&#1090;
      &#1087;&#1088;&#1072;&#1074;&#1086; &#1074; &#1083;&#1102;&#1073;&#1086;&#1081;
      &#1084;&#1086;&#1084;&#1077;&#1085;&#1090; &#1080;&#1079;&#1084;&#1077;&#1085;&#1103;&#1090;&#1100;
      &#1092;&#1091;&#1085;&#1082;&#1094;&#1080;&#1080;,
      &#1090;&#1077;&#1093;&#1085;&#1086;&#1083;&#1086;&#1075;&#1080;&#1080; &#1080;
      &#1076;&#1080;&#1079;&#1072;&#1081;&#1085; &#1055;&#1083;&#1072;&#1090;&#1092;&#1086;&#1088;&#1084;&#1099;,
      &#1074; &#1086;&#1076;&#1085;&#1086;&#1089;&#1090;&#1086;&#1088;&#1086;&#1085;&#1085;&#1077;&#1084;
      &#1087;&#1086;&#1088;&#1103;&#1076;&#1082;&#1077; &#1073;&#1077;&#1079;
      &#1087;&#1088;&#1077;&#1076;&#1074;&#1072;&#1088;&#1080;&#1090;&#1077;&#1083;&#1100;&#1085;&#1086;&#1075;&#1086;
      &#1089;&#1086;&#1075;&#1083;&#1072;&#1089;&#1086;&#1074;&#1072;&#1085;&#1080;&#1103; &#1089;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1086;&#1084;.</span></p>
  <p className="c6"><span className="c2">3.2.3. &#1042;
      &#1086;&#1076;&#1085;&#1086;&#1089;&#1090;&#1086;&#1088;&#1086;&#1085;&#1085;&#1077;&#1084;
      &#1087;&#1086;&#1088;&#1103;&#1076;&#1082;&#1077;
      &#1087;&#1088;&#1080;&#1086;&#1089;&#1090;&#1072;&#1085;&#1072;&#1074;&#1083;&#1080;&#1074;&#1072;&#1090;&#1100;
      &#1080;&#1089;&#1087;&#1086;&#1083;&#1100;&#1079;&#1086;&#1074;&#1072;&#1085;&#1080;&#1077;
      &#1055;&#1083;&#1072;&#1090;&#1092;&#1086;&#1088;&#1084;&#1086;&#1081; &#1087;&#1086;
      &#1089;&#1083;&#1077;&#1076;&#1091;&#1102;&#1097;&#1080;&#1084;
      &#1086;&#1089;&#1085;&#1086;&#1074;&#1072;&#1085;&#1080;&#1103;&#1084;:</span></p>
  <ul className="c10 lst-kix_6gej3hwedac2-0 start">
    <li className="c5 li-bullet-0"><span className="c2">&#1080;&#1089;&#1090;&#1077;&#1095;&#1077;&#1085;&#1080;&#1077;
        &#1089;&#1088;&#1086;&#1082;&#1072;
        &#1080;&#1089;&#1087;&#1086;&#1083;&#1100;&#1079;&#1086;&#1074;&#1072;&#1085;&#1080;&#1103;
        &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1080;;</span></li>
    <li className="c5 li-bullet-0"><span className="c2">&#1085;&#1072;&#1088;&#1091;&#1096;&#1077;&#1085;&#1080;&#1077;
        &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1086;&#1084;
        &#1091;&#1089;&#1083;&#1086;&#1074;&#1080;&#1081; &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1072;,
        &#1085;&#1072;&#1088;&#1091;&#1096;&#1077;&#1085;&#1080;&#1077;
        &#1079;&#1072;&#1082;&#1086;&#1085;&#1086;&#1076;&#1072;&#1090;&#1077;&#1083;&#1100;&#1089;&#1090;&#1074;&#1072;
        &#1056;&#1060;, &#1072; &#1090;&#1072;&#1082;&#1078;&#1077; &#1087;&#1088;&#1072;&#1074; &#1085;&#1072;
        &#1080;&#1085;&#1090;&#1077;&#1083;&#1083;&#1077;&#1082;&#1090;&#1091;&#1072;&#1083;&#1100;&#1085;&#1091;&#1102;
        &#1076;&#1077;&#1103;&#1090;&#1077;&#1083;&#1100;&#1085;&#1086;&#1089;&#1090;&#1100;
        &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088;&#1072; &#1080;
        &#1090;&#1088;&#1077;&#1090;&#1100;&#1080;&#1093; &#1083;&#1080;&#1094;.</span></li>
    <li className="c5 li-bullet-0"><span className="c2">&#1086;&#1090;&#1089;&#1091;&#1090;&#1089;&#1090;&#1074;&#1080;&#1103;
        &#1085;&#1077;&#1086;&#1073;&#1093;&#1086;&#1076;&#1080;&#1084;&#1086;&#1075;&#1086;
        &#1082;&#1086;&#1083;&#1080;&#1095;&#1077;&#1089;&#1090;&#1074;&#1072;
        &laquo;&#1057;&#1084;&#1072;&#1088;&#1090;&#1086;&#1074;&raquo; &#1076;&#1083;&#1103;
        &#1074;&#1077;&#1076;&#1077;&#1085;&#1080;&#1103;
        &#1074;&#1077;&#1073;&#1080;&#1085;&#1072;&#1088;&#1086;&#1074; &#1080;
        &#1072;&#1074;&#1090;&#1086;&#1074;&#1077;&#1073;&#1080;&#1085;&#1072;&#1088;&#1086;&#1074;.</span></li>
  </ul>
  <p className="c6 c7 c21"><span className="c2"></span></p>
  <p className="c6"><span className="c2">3.2.4. &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088;
      &#1080;&#1084;&#1077;&#1077;&#1090; &#1087;&#1088;&#1072;&#1074;&#1086;
      &#1087;&#1088;&#1080;&#1086;&#1089;&#1090;&#1072;&#1085;&#1072;&#1074;&#1083;&#1080;&#1074;&#1072;&#1090;&#1100;
      &#1076;&#1086;&#1089;&#1090;&#1091;&#1087; &#1082; &#1055;&#1083;&#1072;&#1090;&#1092;&#1086;&#1088;&#1084;&#1077;
      &#1076;&#1083;&#1103; &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1072; &#1080;
      &#1077;&#1075;&#1086; &#1082;&#1083;&#1080;&#1077;&#1085;&#1090;&#1086;&#1074; &#1074;
      &#1089;&#1083;&#1091;&#1095;&#1072;&#1103;&#1093;:</span></p>
  <ul className="c10 lst-kix_qtgcah44kgbh-0 start">
    <li className="c5 li-bullet-0"><span className="c2">&#1091;&#1089;&#1090;&#1088;&#1072;&#1085;&#1077;&#1085;&#1080;&#1103;
        &#1090;&#1077;&#1093;&#1085;&#1080;&#1095;&#1077;&#1089;&#1082;&#1080;&#1093;
        &#1089;&#1073;&#1086;&#1077;&#1074;;</span></li>
    <li className="c5 li-bullet-0"><span className="c2">&#1082;&#1088;&#1091;&#1087;&#1085;&#1099;&#1093;
        &#1086;&#1073;&#1085;&#1086;&#1074;&#1083;&#1077;&#1085;&#1080;&#1081;
        &#1092;&#1091;&#1085;&#1082;&#1094;&#1080;&#1086;&#1085;&#1072;&#1083;&#1072;
        &#1055;&#1083;&#1072;&#1090;&#1092;&#1086;&#1088;&#1084;&#1099;;</span></li>
    <li className="c5 li-bullet-0"><span className="c2">&#1082;&#1088;&#1091;&#1087;&#1085;&#1099;&#1093;
        &#1080;&#1079;&#1084;&#1077;&#1085;&#1077;&#1085;&#1080;&#1081;
        &#1090;&#1077;&#1093;&#1085;&#1080;&#1095;&#1077;&#1089;&#1082;&#1086;&#1081;
        &#1072;&#1088;&#1093;&#1080;&#1090;&#1077;&#1082;&#1090;&#1091;&#1088;&#1099;
        &#1089;&#1077;&#1088;&#1074;&#1077;&#1088;&#1086;&#1074; &#1080; &#1089;&#1077;&#1090;&#1080;;</span></li>
    <li className="c5 li-bullet-0"><span
        className="c2">&#1087;&#1088;&#1086;&#1090;&#1080;&#1074;&#1086;&#1076;&#1077;&#1081;&#1089;&#1090;&#1074;&#1080;&#1103;
        &#1082;&#1080;&#1073;&#1077;&#1088;&#1072;&#1090;&#1072;&#1082;&#1077;, DDOS &#1072;&#1090;&#1072;&#1082;&#1077;
        &#1080; &#1087;&#1088;&#1086;&#1095;&#1080;&#1084;
        &#1074;&#1088;&#1077;&#1076;&#1086;&#1085;&#1086;&#1089;&#1085;&#1099;&#1084;
        &#1076;&#1077;&#1081;&#1089;&#1090;&#1074;&#1080;&#1103;&#1084;
        &#1090;&#1088;&#1077;&#1090;&#1100;&#1080;&#1093; &#1083;&#1080;&#1094;.</span></li>
  </ul>
  <p className="c6"><span className="c2">&#1044;&#1086;&#1089;&#1090;&#1091;&#1087;
      &#1087;&#1088;&#1080;&#1086;&#1089;&#1090;&#1072;&#1085;&#1072;&#1074;&#1083;&#1080;&#1074;&#1072;&#1077;&#1090;&#1089;&#1103;
      &#1085;&#1072; &#1074;&#1088;&#1077;&#1084;&#1103;
      &#1085;&#1077;&#1086;&#1073;&#1093;&#1086;&#1076;&#1080;&#1084;&#1086;&#1077; &#1076;&#1083;&#1103;
      &#1074;&#1099;&#1087;&#1086;&#1083;&#1085;&#1077;&#1085;&#1080;&#1103; &#1088;&#1072;&#1073;&#1086;&#1090;.</span>
  </p>
  <p className="c6 c7"><span className="c2"></span></p>
  <p className="c6"><span className="c16 c19">3.3. &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;
      &#1086;&#1073;&#1103;&#1079;&#1091;&#1077;&#1090;&#1089;&#1103;:</span></p>
  <p className="c6"><span className="c12">3.3.1.
      &#1057;&#1072;&#1084;&#1086;&#1089;&#1090;&#1086;&#1103;&#1090;&#1077;&#1083;&#1100;&#1085;&#1086; &#1080;
      &#1089;&#1074;&#1086;&#1077;&#1074;&#1088;&#1077;&#1084;&#1077;&#1085;&#1085;&#1086;
      &#1079;&#1085;&#1072;&#1082;&#1086;&#1084;&#1080;&#1090;&#1100;&#1089;&#1103; &#1089;
      &#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1080;&#1077;&#1081;
      &#1087;&#1091;&#1073;&#1083;&#1080;&#1082;&#1091;&#1077;&#1084;&#1086;&#1081; &#1085;&#1072;
      &#1089;&#1072;&#1081;&#1090;&#1077; </span><span className="c12 c18"><a className="c8"
        href="https://www.google.com/url?q=https://smartwebinar.org&amp;sa=D&amp;source=editors&amp;ust=1658481495913998&amp;usg=AOvVaw3AvU_cFTDnaEdhRc8vprU6">https://smartwebinar.org</a></span><span
      className="c2">;</span></p>
  <p className="c6"><span className="c2">3.3.2. &#1055;&#1088;&#1080;
      &#1088;&#1077;&#1075;&#1080;&#1089;&#1090;&#1088;&#1072;&#1094;&#1080;&#1080; &#1085;&#1072;
      &#1055;&#1083;&#1072;&#1090;&#1092;&#1086;&#1088;&#1084;&#1077; &#1091;&#1082;&#1072;&#1079;&#1072;&#1090;&#1100;
      &#1076;&#1086;&#1089;&#1090;&#1086;&#1074;&#1077;&#1088;&#1085;&#1091;&#1102;
      &#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1080;&#1102;,
      &#1087;&#1086;&#1076;&#1076;&#1077;&#1088;&#1078;&#1080;&#1074;&#1072;&#1090;&#1100; &#1077;&#1077; &#1074;
      &#1072;&#1082;&#1090;&#1091;&#1072;&#1083;&#1100;&#1085;&#1086;&#1084;
      &#1089;&#1086;&#1089;&#1090;&#1086;&#1103;&#1085;&#1080;&#1080;;</span></p>
  <p className="c6"><span className="c2">3.3.4. &#1055;&#1086;&#1089;&#1083;&#1077;
      &#1080;&#1089;&#1090;&#1077;&#1095;&#1077;&#1085;&#1080;&#1103; &#1089;&#1088;&#1086;&#1082;&#1072;
      &#1080;&#1089;&#1087;&#1086;&#1083;&#1100;&#1079;&#1086;&#1074;&#1072;&#1085;&#1080;&#1103;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1080; &#1085;&#1077;&#1089;&#1090;&#1080;
      &#1086;&#1090;&#1074;&#1077;&#1090;&#1089;&#1090;&#1074;&#1077;&#1085;&#1085;&#1086;&#1089;&#1090;&#1100;
      &#1087;&#1077;&#1088;&#1077;&#1076; &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088;&#1086;&#1084;
      &#1079;&#1072; &#1087;&#1086;&#1089;&#1083;&#1077;&#1076;&#1089;&#1090;&#1074;&#1080;&#1103;
      &#1085;&#1072;&#1088;&#1091;&#1096;&#1077;&#1085;&#1080;&#1103; &#1087;&#1088;&#1072;&#1074;&#1080;&#1083;
      &#1087;&#1086;&#1083;&#1100;&#1079;&#1086;&#1074;&#1072;&#1085;&#1080;&#1103;
      &#1055;&#1083;&#1072;&#1090;&#1092;&#1086;&#1088;&#1084;&#1086;&#1081;
      &#1087;&#1086;&#1083;&#1100;&#1079;&#1086;&#1074;&#1072;&#1090;&#1077;&#1083;&#1103;&#1084;&#1080;,
      &#1082;&#1086;&#1090;&#1086;&#1088;&#1099;&#1084; &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;
      &#1087;&#1088;&#1077;&#1076;&#1086;&#1089;&#1090;&#1072;&#1074;&#1080;&#1083;
      &#1076;&#1086;&#1089;&#1090;&#1091;&#1087;.</span></p>
  <p className="c6 c7"><span className="c2"></span></p>
  <p className="c6"><span className="c16 c19">3.4. &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;
      &#1080;&#1084;&#1077;&#1077;&#1090; &#1087;&#1088;&#1072;&#1074;&#1086;:</span></p>
  <p className="c6"><span className="c2">3.4.1.
      &#1048;&#1089;&#1087;&#1086;&#1083;&#1100;&#1079;&#1086;&#1074;&#1072;&#1090;&#1100;
      &#1055;&#1083;&#1072;&#1090;&#1092;&#1086;&#1088;&#1084;&#1091; &#1085;&#1072;
      &#1091;&#1089;&#1083;&#1086;&#1074;&#1080;&#1103;&#1093;,
      &#1091;&#1082;&#1072;&#1079;&#1072;&#1085;&#1085;&#1099;&#1093; &#1074;
      &#1085;&#1072;&#1089;&#1090;&#1086;&#1103;&#1097;&#1077;&#1084;
      &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1077;.</span></p>
  <p className="c6"><span className="c2">3.4.2. &#1055;&#1086;&#1083;&#1091;&#1095;&#1080;&#1090;&#1100;
      &#1082;&#1086;&#1085;&#1089;&#1091;&#1083;&#1100;&#1090;&#1072;&#1094;&#1080;&#1102; &#1074;
      &#1095;&#1072;&#1090;&#1077; &#1074; &#1083;&#1080;&#1095;&#1085;&#1086;&#1084;
      &#1082;&#1072;&#1073;&#1080;&#1085;&#1077;&#1090;&#1077; &#1080;&#1083;&#1080; &#1087;&#1086;
      &#1090;&#1077;&#1083;&#1077;&#1092;&#1086;&#1085;&#1072;&#1084; &#1080;
      &#1077;-&#1084;&#1101;&#1081;&#1083;&#1091; &#1091;&#1082;&#1072;&#1079;&#1072;&#1085;&#1085;&#1099;&#1084;
      &#1074; &#1083;&#1080;&#1095;&#1085;&#1086;&#1084; &#1082;&#1072;&#1073;&#1080;&#1085;&#1077;&#1090;&#1077;
      &#1087;&#1086; &#1074;&#1086;&#1087;&#1088;&#1086;&#1089;&#1072;&#1084;
      &#1080;&#1089;&#1087;&#1086;&#1083;&#1100;&#1079;&#1086;&#1074;&#1072;&#1085;&#1080;&#1103;
      &#1055;&#1083;&#1072;&#1090;&#1092;&#1086;&#1088;&#1084;&#1099;.</span></p>
  <p className="c6 c7"><span className="c2"></span></p>
  <h2 className="c3"><span className="c16">4. &#1055;&#1086;&#1088;&#1103;&#1076;&#1086;&#1082; &#1080;
      &#1089;&#1088;&#1086;&#1082;&#1080; &#1086;&#1087;&#1083;&#1072;&#1090;&#1099;.</span></h2>
  <p className="c6"><span className="c2">4.1. &#1054;&#1087;&#1083;&#1072;&#1090;&#1072;
      &#1087;&#1088;&#1086;&#1080;&#1079;&#1074;&#1086;&#1076;&#1080;&#1090;&#1089;&#1103; &#1074;
      &#1088;&#1091;&#1073;&#1083;&#1103;&#1093; &#1056;&#1060;. &#1055;&#1086;&#1089;&#1083;&#1077;
      &#1086;&#1087;&#1083;&#1072;&#1090;&#1099; &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1091;
      &#1074; &#1083;&#1080;&#1095;&#1085;&#1099;&#1081; &#1082;&#1072;&#1073;&#1080;&#1085;&#1077;&#1090;
      &#1085;&#1072;&#1095;&#1080;&#1089;&#1083;&#1103;&#1077;&#1090;&#1089;&#1103;
      &#1091;&#1089;&#1083;&#1086;&#1074;&#1085;&#1072;&#1103; &#1077;&#1076;&#1080;&#1085;&#1080;&#1094;&#1072;
      &laquo;&#1057;&#1084;&#1072;&#1088;&#1090;&raquo;, &#1082;&#1086;&#1090;&#1086;&#1088;&#1072;&#1103;
      &#1086;&#1087;&#1088;&#1077;&#1076;&#1077;&#1083;&#1103;&#1077;&#1090;
      &#1082;&#1086;&#1083;&#1080;&#1095;&#1077;&#1089;&#1090;&#1074;&#1086;
      &#1087;&#1086;&#1083;&#1100;&#1079;&#1086;&#1074;&#1072;&#1090;&#1077;&#1083;&#1077;&#1081;,
      &#1082;&#1086;&#1090;&#1086;&#1088;&#1099;&#1093; &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;
      &#1084;&#1086;&#1078;&#1077;&#1090; &#1087;&#1088;&#1080;&#1074;&#1083;&#1077;&#1095;&#1100; &#1085;&#1072;
      &#1074;&#1077;&#1073;&#1080;&#1085;&#1072;&#1088;&#1099; &#1080;
      &#1072;&#1074;&#1090;&#1086;&#1074;&#1077;&#1073;&#1080;&#1085;&#1072;&#1088;&#1099;. </span></p>
  <p className="c6"><span className="c12">4.2. &#1050;&#1086;&#1083;&#1080;&#1095;&#1077;&#1089;&#1090;&#1074;&#1086;
      &#1087;&#1088;&#1077;&#1076;&#1086;&#1089;&#1090;&#1072;&#1074;&#1083;&#1103;&#1077;&#1084;&#1099;&#1093;
      &laquo;&#1057;&#1084;&#1072;&#1088;&#1090;&#1086;&#1074;&raquo; &#1080;
      &#1082;&#1086;&#1083;&#1080;&#1095;&#1077;&#1089;&#1090;&#1074;&#1086;
      &#1079;&#1088;&#1080;&#1090;&#1077;&#1083;&#1077;&#1081; &#1079;&#1072;
      &laquo;&#1057;&#1084;&#1072;&#1088;&#1090;&#1099;&raquo;
      &#1086;&#1087;&#1088;&#1077;&#1076;&#1077;&#1083;&#1103;&#1077;&#1090;&#1089;&#1103;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088;&#1086;&#1084; &#1074;
      &#1089;&#1086;&#1086;&#1090;&#1074;&#1077;&#1090;&#1089;&#1090;&#1074;&#1080;&#1080; &#1089;
      &#1091;&#1089;&#1083;&#1086;&#1074;&#1080;&#1103;&#1084;&#1080; &#1087;&#1086;
      &#1086;&#1087;&#1083;&#1072;&#1090;&#1077; &#1080;
      &#1090;&#1072;&#1088;&#1080;&#1092;&#1080;&#1082;&#1072;&#1094;&#1080;&#1080;,
      &#1088;&#1072;&#1079;&#1084;&#1077;&#1097;&#1077;&#1085;&#1085;&#1099;&#1077; &#1085;&#1072;
      &#1089;&#1072;&#1081;&#1090;&#1077; </span><span className="c18 c12"><a className="c8"
        href="https://www.google.com/url?q=https://smartwebinar.org&amp;sa=D&amp;source=editors&amp;ust=1658481495914747&amp;usg=AOvVaw1VMHZYCAYu2m3paNU5Ao7C">https://smartwebinar.org</a></span><span
      className="c2">. </span></p>
  <p className="c6"><span className="c2">4.3. &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1099; &ndash;
      &#1092;&#1080;&#1079;&#1080;&#1095;&#1077;&#1089;&#1082;&#1080;&#1077; &#1083;&#1080;&#1094;&#1072;
      &#1074;&#1087;&#1088;&#1072;&#1074;&#1077; &#1085;&#1072;&#1089;&#1090;&#1088;&#1086;&#1080;&#1090;&#1100;
      &#1072;&#1074;&#1090;&#1086;&#1084;&#1072;&#1090;&#1080;&#1095;&#1077;&#1089;&#1082;&#1091;&#1102;
      &#1086;&#1087;&#1083;&#1072;&#1090;&#1091; &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1080;
      &#1087;&#1091;&#1090;&#1077;&#1084;
      &#1073;&#1077;&#1079;&#1072;&#1082;&#1094;&#1077;&#1087;&#1090;&#1085;&#1086;&#1075;&#1086;
      &#1089;&#1087;&#1080;&#1089;&#1072;&#1085;&#1080;&#1103; &#1076;&#1077;&#1085;&#1077;&#1078;&#1085;&#1099;&#1093;
      &#1089;&#1088;&#1077;&#1076;&#1089;&#1090;&#1074; &#1089;
      &#1073;&#1072;&#1085;&#1082;&#1086;&#1074;&#1089;&#1082;&#1086;&#1081; &#1082;&#1072;&#1088;&#1090;&#1099;.</span>
  </p>
  <p className="c6"><span className="c2">4.4. &#1042; &#1089;&#1083;&#1091;&#1095;&#1072;&#1077;
      &#1089;&#1086;&#1074;&#1077;&#1088;&#1096;&#1077;&#1085;&#1080;&#1103;
      &#1087;&#1083;&#1072;&#1090;&#1077;&#1078;&#1072; &#1089; &#1087;&#1086;&#1084;&#1086;&#1097;&#1100;&#1102;
      &#1073;&#1072;&#1085;&#1082;&#1086;&#1074;&#1089;&#1082;&#1086;&#1081; &#1082;&#1072;&#1088;&#1090;&#1099;,
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1091;
      &#1088;&#1077;&#1082;&#1086;&#1084;&#1077;&#1085;&#1076;&#1091;&#1077;&#1090;&#1089;&#1103;
      &#1080;&#1089;&#1087;&#1086;&#1083;&#1100;&#1079;&#1086;&#1074;&#1072;&#1090;&#1100;
      &#1073;&#1072;&#1085;&#1082;&#1086;&#1074;&#1089;&#1082;&#1091;&#1102; &#1082;&#1072;&#1088;&#1090;&#1091;,
      &#1074;&#1099;&#1087;&#1091;&#1097;&#1077;&#1085;&#1085;&#1091;&#1102; &#1085;&#1072; &#1080;&#1084;&#1103;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1072;. &#1042;
      &#1089;&#1083;&#1091;&#1095;&#1072;&#1077;
      &#1086;&#1089;&#1091;&#1097;&#1077;&#1089;&#1090;&#1074;&#1083;&#1077;&#1085;&#1080;&#1103;
      &#1074;&#1086;&#1079;&#1074;&#1088;&#1072;&#1090;&#1072;
      &#1086;&#1087;&#1083;&#1072;&#1095;&#1077;&#1085;&#1085;&#1099;&#1093;
      &#1076;&#1077;&#1085;&#1077;&#1078;&#1085;&#1099;&#1093; &#1089;&#1088;&#1077;&#1076;&#1089;&#1090;&#1074;,
      &#1074; &#1089;&#1074;&#1103;&#1079;&#1080; &#1089;
      &#1086;&#1096;&#1080;&#1073;&#1086;&#1095;&#1085;&#1099;&#1084;
      &#1087;&#1077;&#1088;&#1077;&#1095;&#1080;&#1089;&#1083;&#1077;&#1085;&#1080;&#1077;&#1084;
      &#1074;&#1086;&#1079;&#1074;&#1088;&#1072;&#1090;
      &#1087;&#1088;&#1086;&#1080;&#1079;&#1074;&#1086;&#1076;&#1080;&#1090;&#1089;&#1103; &#1087;&#1086;
      &#1090;&#1077;&#1084; &#1078;&#1077; &#1088;&#1077;&#1082;&#1074;&#1080;&#1079;&#1080;&#1090;&#1072;&#1084;,
      &#1087;&#1086; &#1082;&#1086;&#1090;&#1086;&#1088;&#1099;&#1084; &#1073;&#1099;&#1083;
      &#1087;&#1086;&#1083;&#1091;&#1095;&#1077;&#1085; &#1087;&#1083;&#1072;&#1090;&#1077;&#1078;, &#1080;
      &#1085;&#1072; &#1086;&#1089;&#1085;&#1086;&#1074;&#1072;&#1085;&#1080;&#1080;
      &#1083;&#1080;&#1095;&#1085;&#1086;&#1075;&#1086; &#1079;&#1072;&#1103;&#1074;&#1083;&#1077;&#1085;&#1080;&#1103;
      &#1083;&#1080;&#1094;&#1072;, &#1085;&#1072; &#1095;&#1100;&#1077; &#1080;&#1084;&#1103;
      &#1073;&#1099;&#1083;&#1072; &#1074;&#1099;&#1087;&#1091;&#1097;&#1077;&#1085;&#1072;
      &#1073;&#1072;&#1085;&#1082;&#1086;&#1074;&#1089;&#1082;&#1072;&#1103; &#1082;&#1072;&#1088;&#1090;&#1072;.
      &#1042;&#1086;&#1079;&#1074;&#1088;&#1072;&#1090; &#1089;&#1091;&#1084;&#1084; &#1074;
      &#1090;&#1072;&#1082;&#1086;&#1084; &#1089;&#1083;&#1091;&#1095;&#1072;&#1077;
      &#1086;&#1089;&#1091;&#1097;&#1077;&#1089;&#1090;&#1074;&#1083;&#1103;&#1077;&#1090;&#1089;&#1103; &#1079;&#1072;
      &#1084;&#1080;&#1085;&#1091;&#1089;&#1086;&#1084; &#1088;&#1072;&#1089;&#1093;&#1086;&#1076;&#1086;&#1074;,
      &#1082;&#1086;&#1090;&#1086;&#1088;&#1099;&#1077; &#1087;&#1086;&#1085;&#1077;&#1089;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088; &#1085;&#1072;
      &#1074;&#1086;&#1079;&#1074;&#1088;&#1072;&#1090; &#1076;&#1077;&#1085;&#1077;&#1078;&#1085;&#1099;&#1093;
      &#1089;&#1088;&#1077;&#1076;&#1089;&#1090;&#1074;.</span></p>
  <p className="c6 c7"><span className="c2"></span></p>
  <h2 className="c3"><span className="c16">5. &#1054;&#1075;&#1086;&#1074;&#1086;&#1088;&#1082;&#1072; &#1086;
      &#1087;&#1088;&#1072;&#1074;&#1072;&#1093; &#1074; &#1086;&#1090;&#1085;&#1086;&#1096;&#1077;&#1085;&#1080;&#1080;
      &#1088;&#1072;&#1079;&#1084;&#1077;&#1097;&#1077;&#1085;&#1085;&#1086;&#1075;&#1086;
      &#1082;&#1086;&#1085;&#1090;&#1077;&#1085;&#1090;&#1072;.
      &#1055;&#1088;&#1086;&#1094;&#1077;&#1076;&#1091;&#1088;&#1072;
      &#1088;&#1072;&#1079;&#1088;&#1077;&#1096;&#1077;&#1085;&#1080;&#1103; &#1089;&#1087;&#1086;&#1088;&#1086;&#1074;
      &#1087;&#1088;&#1080; &#1086;&#1073;&#1088;&#1072;&#1097;&#1077;&#1085;&#1080;&#1080;
      &#1090;&#1088;&#1077;&#1090;&#1100;&#1080;&#1093; &#1083;&#1080;&#1094;.</span></h2>
  <ol className="c10 lst-kix_qdq1ctoq1dix-1 start" start={1}>
    <li className="c0 li-bullet-1"><span className="c2">&#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088;
        &#1087;&#1088;&#1077;&#1076;&#1086;&#1089;&#1090;&#1072;&#1074;&#1083;&#1103;&#1077;&#1090;
        &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1091; &#1087;&#1088;&#1072;&#1074;&#1086;
        &#1085;&#1072; &#1080;&#1089;&#1087;&#1086;&#1083;&#1100;&#1079;&#1086;&#1074;&#1072;&#1085;&#1080;&#1080;
        &#1055;&#1083;&#1072;&#1090;&#1092;&#1086;&#1088;&#1084;&#1086;&#1081;,
        &#1082;&#1086;&#1090;&#1086;&#1088;&#1091;&#1102;
        &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;
        &#1080;&#1089;&#1087;&#1086;&#1083;&#1100;&#1079;&#1091;&#1077;&#1090; &#1076;&#1083;&#1103;
        &#1088;&#1072;&#1079;&#1084;&#1077;&#1097;&#1077;&#1085;&#1080;&#1103;
        &#1050;&#1072;&#1090;&#1072;&#1083;&#1086;&#1075;&#1072; &#1082;&#1091;&#1088;&#1089;&#1086;&#1074;.
        &#1056;&#1072;&#1079;&#1084;&#1077;&#1097;&#1077;&#1085;&#1080;&#1077;
        &#1091;&#1082;&#1072;&#1079;&#1072;&#1085;&#1085;&#1086;&#1081;
        &#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1080;&#1080;
        (&#1082;&#1091;&#1088;&#1089;&#1086;&#1074;,
        &#1084;&#1072;&#1090;&#1077;&#1088;&#1080;&#1072;&#1083;&#1086;&#1074; &#1080; &#1076;&#1088;.)
        &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1086;&#1084;
        &#1087;&#1088;&#1086;&#1080;&#1079;&#1074;&#1086;&#1076;&#1080;&#1090;&#1089;&#1103; &#1085;&#1072;
        &#1092;&#1072;&#1081;&#1083;&#1086;&#1074;&#1086;&#1084;
        &#1093;&#1088;&#1072;&#1085;&#1080;&#1083;&#1080;&#1097;&#1077;,
        &#1082;&#1086;&#1090;&#1086;&#1088;&#1086;&#1077;
        &#1087;&#1088;&#1077;&#1076;&#1086;&#1089;&#1090;&#1072;&#1074;&#1083;&#1103;&#1077;&#1090;&#1089;&#1103;
        &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088;&#1086;&#1084;. </span></li>
    <li className="c0 li-bullet-1"><span className="c2">&#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088;
        &#1085;&#1077; &#1086;&#1089;&#1091;&#1097;&#1077;&#1089;&#1090;&#1074;&#1083;&#1103;&#1077;&#1090; &#1080;
        &#1085;&#1077; &#1080;&#1085;&#1080;&#1094;&#1080;&#1080;&#1088;&#1091;&#1077;&#1090;
        &#1087;&#1077;&#1088;&#1077;&#1076;&#1072;&#1095;&#1091;
        &#1088;&#1072;&#1079;&#1084;&#1077;&#1097;&#1077;&#1085;&#1085;&#1086;&#1081;
        &#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1080;&#1080;, &#1072;
        &#1090;&#1072;&#1082;&#1078;&#1077; &#1085;&#1077; &#1074;&#1083;&#1080;&#1103;&#1077;&#1090; &#1085;&#1072;
        &#1077;&#1077; &#1094;&#1077;&#1083;&#1086;&#1089;&#1090;&#1085;&#1086;&#1089;&#1090;&#1100;.
        &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088; &#1085;&#1077;
        &#1087;&#1086;&#1083;&#1091;&#1095;&#1072;&#1077;&#1090;
        &#1074;&#1086;&#1079;&#1085;&#1072;&#1075;&#1088;&#1072;&#1078;&#1076;&#1077;&#1085;&#1080;&#1103;
        &#1086;&#1090;
        &#1087;&#1088;&#1077;&#1076;&#1086;&#1089;&#1090;&#1072;&#1074;&#1083;&#1077;&#1085;&#1080;&#1103;
        &#1082;&#1091;&#1088;&#1089;&#1086;&#1074;
        &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1086;&#1084;.
        &#1054;&#1090;&#1074;&#1077;&#1090;&#1089;&#1090;&#1074;&#1077;&#1085;&#1085;&#1086;&#1089;&#1090;&#1100;
        &#1079;&#1072; &#1089;&#1086;&#1076;&#1077;&#1088;&#1078;&#1072;&#1085;&#1080;&#1077;
        &#1089;&#1074;&#1086;&#1077;&#1075;&#1086; &#1082;&#1091;&#1088;&#1089;&#1072;,
        &#1088;&#1072;&#1079;&#1084;&#1077;&#1097;&#1077;&#1085;&#1085;&#1086;&#1075;&#1086; &#1085;&#1072;
        &#1055;&#1083;&#1072;&#1090;&#1092;&#1086;&#1088;&#1084;&#1077; &#1085;&#1077;&#1089;&#1077;&#1090;
        &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;.</span></li>
    <li className="c0 li-bullet-1"><span className="c2">&#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088;
        &#1087;&#1088;&#1080; &#1088;&#1072;&#1079;&#1084;&#1077;&#1097;&#1077;&#1085;&#1080;&#1080;
        &#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1080;&#1080;
        &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1086;&#1084; &#1085;&#1077;
        &#1086;&#1089;&#1091;&#1097;&#1077;&#1089;&#1090;&#1074;&#1083;&#1103;&#1077;&#1090;
        &#1082;&#1086;&#1085;&#1090;&#1088;&#1086;&#1083;&#1100; &#1079;&#1072;
        &#1089;&#1086;&#1076;&#1077;&#1088;&#1078;&#1072;&#1085;&#1080;&#1077;&#1084;
        &#1088;&#1072;&#1079;&#1084;&#1077;&#1097;&#1072;&#1077;&#1084;&#1086;&#1081;
        &#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1080;&#1080;.
        &#1050;&#1086;&#1085;&#1090;&#1088;&#1086;&#1083;&#1100;
        &#1086;&#1089;&#1091;&#1097;&#1077;&#1089;&#1090;&#1074;&#1083;&#1103;&#1077;&#1090;&#1089;&#1103;
        &#1090;&#1086;&#1083;&#1100;&#1082;&#1086; &#1087;&#1086;&#1089;&#1083;&#1077;
        &#1086;&#1073;&#1088;&#1072;&#1097;&#1077;&#1085;&#1080;&#1103; &#1074; &#1072;&#1076;&#1088;&#1077;&#1089;
        &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088;&#1072;
        &#1090;&#1088;&#1077;&#1090;&#1100;&#1080;&#1093; &#1083;&#1080;&#1094;, &#1089;
        &#1078;&#1072;&#1083;&#1086;&#1073;&#1072;&#1084;&#1080; &#1085;&#1072;
        &#1085;&#1072;&#1088;&#1091;&#1096;&#1077;&#1085;&#1080;&#1077; &#1080;&#1093;
        &#1080;&#1085;&#1090;&#1077;&#1083;&#1083;&#1077;&#1082;&#1090;&#1091;&#1072;&#1083;&#1100;&#1085;&#1099;&#1093;
        &#1087;&#1088;&#1072;&#1074; &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1086;&#1084;,
        &#1072; &#1090;&#1072;&#1082;&#1078;&#1077; &#1074; &#1080;&#1085;&#1099;&#1093;
        &#1089;&#1083;&#1091;&#1095;&#1072;&#1103;&#1093;,
        &#1087;&#1088;&#1077;&#1076;&#1091;&#1089;&#1084;&#1086;&#1090;&#1088;&#1077;&#1085;&#1085;&#1099;&#1093;
        &#1085;&#1072;&#1089;&#1090;&#1086;&#1103;&#1097;&#1077;&#1081;
        &#1054;&#1092;&#1077;&#1088;&#1090;&#1086;&#1081;.</span></li>
    <li className="c0 li-bullet-1"><span className="c2">&#1055;&#1088;&#1080;
        &#1080;&#1089;&#1087;&#1086;&#1083;&#1100;&#1079;&#1086;&#1074;&#1072;&#1085;&#1080;&#1080;
        &#1055;&#1083;&#1072;&#1090;&#1092;&#1086;&#1088;&#1084;&#1099;
        &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090; &#1086;&#1073;&#1103;&#1079;&#1072;&#1085;
        &#1085;&#1077; &#1085;&#1072;&#1088;&#1091;&#1096;&#1072;&#1090;&#1100; &#1087;&#1088;&#1072;&#1074;&#1072;
        &#1080; &#1080;&#1085;&#1090;&#1077;&#1088;&#1077;&#1089;&#1099;
        &#1090;&#1088;&#1077;&#1090;&#1100;&#1080;&#1093; &#1083;&#1080;&#1094;. &#1042;
        &#1079;&#1072;&#1074;&#1080;&#1089;&#1080;&#1084;&#1086;&#1089;&#1090;&#1080; &#1086;&#1090;
        &#1085;&#1072;&#1088;&#1091;&#1096;&#1077;&#1085;&#1080;&#1103;, &#1082;
        &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1091; &#1084;&#1086;&#1075;&#1091;&#1090;
        &#1073;&#1099;&#1090;&#1100; &#1087;&#1088;&#1080;&#1084;&#1077;&#1085;&#1077;&#1085;&#1099;
        &#1075;&#1088;&#1072;&#1078;&#1076;&#1072;&#1085;&#1089;&#1082;&#1086;-&#1087;&#1088;&#1072;&#1074;&#1086;&#1074;&#1099;&#1077;,
        &#1072;&#1076;&#1084;&#1080;&#1085;&#1080;&#1089;&#1090;&#1088;&#1072;&#1090;&#1080;&#1074;&#1085;&#1099;&#1077;
        &#1080; &#1091;&#1075;&#1086;&#1083;&#1086;&#1074;&#1085;&#1099;&#1077; &#1084;&#1077;&#1088;&#1099;
        &#1086;&#1090;&#1074;&#1077;&#1090;&#1089;&#1090;&#1074;&#1077;&#1085;&#1085;&#1086;&#1089;&#1090;&#1080;.</span>
    </li>
    <li className="c0 li-bullet-1"><span className="c2">&#1055;&#1083;&#1072;&#1090;&#1092;&#1086;&#1088;&#1084;&#1072;
        &#1085;&#1077; &#1080;&#1084;&#1077;&#1077;&#1090;
        &#1090;&#1077;&#1093;&#1085;&#1080;&#1095;&#1077;&#1089;&#1082;&#1080;&#1093; &#1080;
        &#1087;&#1088;&#1086;&#1075;&#1088;&#1072;&#1084;&#1084;&#1085;&#1099;&#1093;
        &#1088;&#1077;&#1096;&#1077;&#1085;&#1080;&#1081;, &#1082;&#1086;&#1090;&#1086;&#1088;&#1099;&#1077;
        &#1087;&#1086;&#1079;&#1074;&#1086;&#1083;&#1103;&#1102;&#1090;
        &#1072;&#1074;&#1090;&#1086;&#1084;&#1072;&#1090;&#1080;&#1095;&#1077;&#1089;&#1082;&#1080;
        &#1086;&#1089;&#1091;&#1097;&#1077;&#1089;&#1090;&#1074;&#1083;&#1103;&#1090;&#1100;
        &#1094;&#1077;&#1085;&#1079;&#1091;&#1088;&#1091; &#1080;
        &#1082;&#1086;&#1085;&#1090;&#1088;&#1086;&#1083;&#1100; &#1074;
        &#1086;&#1090;&#1085;&#1086;&#1096;&#1077;&#1085;&#1080;&#1080;
        &#1082;&#1086;&#1085;&#1090;&#1077;&#1085;&#1090;&#1072;, &#1082;&#1086;&#1090;&#1086;&#1088;&#1099;&#1081;
        &#1079;&#1072;&#1075;&#1088;&#1091;&#1078;&#1072;&#1077;&#1090;&#1089;&#1103;
        &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1086;&#1084; &#1074;
        &#1055;&#1083;&#1072;&#1090;&#1092;&#1086;&#1088;&#1084;&#1091;.</span></li>
    <li className="c24 li-bullet-2"><span className="c2">&nbsp;&#1055;&#1088;&#1086;&#1094;&#1077;&#1076;&#1091;&#1088;&#1072;
        &#1088;&#1072;&#1089;&#1089;&#1084;&#1086;&#1090;&#1088;&#1077;&#1085;&#1080;&#1103;
        &#1078;&#1072;&#1083;&#1086;&#1073; &#1085;&#1072;
        &#1085;&#1072;&#1088;&#1091;&#1096;&#1077;&#1085;&#1080;&#1077;
        &#1080;&#1085;&#1090;&#1077;&#1083;&#1083;&#1077;&#1082;&#1090;&#1091;&#1072;&#1083;&#1100;&#1085;&#1099;&#1093;
        &#1087;&#1088;&#1072;&#1074;.</span></li>
  </ol>
  <ol className="c10 lst-kix_qdq1ctoq1dix-2 start" start={1}>
    <li className="c14 li-bullet-3"><span className="c2">&#1051;&#1080;&#1094;&#1086;,
        &#1082;&#1086;&#1090;&#1086;&#1088;&#1086;&#1077; &#1089;&#1095;&#1080;&#1090;&#1072;&#1077;&#1090;,
        &#1095;&#1090;&#1086; &#1077;&#1075;&#1086; &#1087;&#1088;&#1072;&#1074;&#1086; &#1085;&#1072;
        &#1080;&#1085;&#1090;&#1077;&#1083;&#1083;&#1077;&#1082;&#1090;&#1091;&#1072;&#1083;&#1100;&#1085;&#1091;&#1102;
        &#1089;&#1086;&#1073;&#1089;&#1090;&#1074;&#1077;&#1085;&#1085;&#1086;&#1089;&#1090;&#1100;
        &#1073;&#1099;&#1083;&#1086; &#1085;&#1072;&#1088;&#1091;&#1096;&#1077;&#1085;&#1086;
        &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1086;&#1084;, &#1074;
        &#1087;&#1077;&#1088;&#1074;&#1091;&#1102; &#1086;&#1095;&#1077;&#1088;&#1077;&#1076;&#1100;
        &#1087;&#1088;&#1077;&#1076;&#1087;&#1088;&#1080;&#1085;&#1080;&#1084;&#1072;&#1077;&#1090;
        &#1074;&#1089;&#1077; &#1074;&#1086;&#1079;&#1084;&#1086;&#1078;&#1085;&#1099;&#1077;
        &#1084;&#1077;&#1088;&#1099; &#1087;&#1086;
        &#1089;&#1072;&#1084;&#1086;&#1089;&#1090;&#1086;&#1103;&#1090;&#1077;&#1083;&#1100;&#1085;&#1086;&#1084;&#1091;
        &#1086;&#1073;&#1088;&#1072;&#1097;&#1077;&#1085;&#1080;&#1102; &#1082;
        &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1091; &#1087;&#1086;
        &#1091;&#1088;&#1077;&#1075;&#1091;&#1083;&#1080;&#1088;&#1086;&#1074;&#1072;&#1085;&#1080;&#1102;
        &#1074;&#1086;&#1079;&#1085;&#1080;&#1082;&#1096;&#1077;&#1081;
        &#1089;&#1080;&#1090;&#1091;&#1072;&#1094;&#1080;&#1080;.</span></li>
    <li className="c14 li-bullet-4"><span className="c2">&#1051;&#1080;&#1094;&#1086;,
        &#1082;&#1086;&#1090;&#1086;&#1088;&#1086;&#1077; &#1089;&#1095;&#1080;&#1090;&#1072;&#1077;&#1090;,
        &#1095;&#1090;&#1086; &#1077;&#1075;&#1086; &#1087;&#1088;&#1072;&#1074;&#1086; &#1085;&#1072;
        &#1080;&#1085;&#1090;&#1077;&#1083;&#1083;&#1077;&#1082;&#1090;&#1091;&#1072;&#1083;&#1100;&#1085;&#1091;&#1102;
        &#1089;&#1086;&#1073;&#1089;&#1090;&#1074;&#1077;&#1085;&#1085;&#1086;&#1089;&#1090;&#1100;
        &#1073;&#1099;&#1083;&#1086; &#1085;&#1072;&#1088;&#1091;&#1096;&#1077;&#1085;&#1086;
        &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1086;&#1084;, &#1074;
        &#1089;&#1083;&#1091;&#1095;&#1072;&#1077;
        &#1085;&#1077;&#1091;&#1088;&#1077;&#1075;&#1091;&#1083;&#1080;&#1088;&#1086;&#1074;&#1072;&#1085;&#1080;&#1103;
        &#1074;&#1086;&#1079;&#1085;&#1080;&#1082;&#1096;&#1077;&#1081;
        &#1089;&#1080;&#1090;&#1091;&#1072;&#1094;&#1080;&#1080;
        &#1085;&#1072;&#1087;&#1088;&#1103;&#1084;&#1091;&#1102; &#1089;
        &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1086;&#1084;, &#1083;&#1080;&#1073;&#1086;
        &#1074; &#1089;&#1083;&#1091;&#1095;&#1072;&#1077;
        &#1085;&#1077;&#1074;&#1086;&#1079;&#1084;&#1086;&#1078;&#1085;&#1086;&#1089;&#1090;&#1080;
        &#1086;&#1089;&#1091;&#1097;&#1077;&#1089;&#1090;&#1074;&#1080;&#1090;&#1100; &#1089; &#1085;&#1080;&#1084;
        &#1082;&#1086;&#1085;&#1090;&#1072;&#1082;&#1090;,
        &#1088;&#1091;&#1082;&#1086;&#1074;&#1086;&#1076;&#1089;&#1090;&#1074;&#1091;&#1103;&#1089;&#1100;
        &#1089;&#1090;. 1253.1 &#1043;&#1050; &#1056;&#1060; &#1080; &#1089;&#1090;. 15.7
        &#1060;&#1077;&#1076;&#1077;&#1088;&#1072;&#1083;&#1100;&#1085;&#1086;&#1075;&#1086;
        &#1079;&#1072;&#1082;&#1086;&#1085;&#1072; &#1086;&#1090; &nbsp;27.07.2006 N 149-&#1060;&#1047;
        &quot;&#1054;&#1073; &#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1080;&#1080;,
        &#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1080;&#1086;&#1085;&#1085;&#1099;&#1093;
        &#1090;&#1077;&#1093;&#1085;&#1086;&#1083;&#1086;&#1075;&#1080;&#1103;&#1093; &#1080; &#1086;
        &#1079;&#1072;&#1097;&#1080;&#1090;&#1077;
        &#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1080;&#1080;&quot;
        &#1085;&#1072;&#1087;&#1088;&#1072;&#1074;&#1083;&#1103;&#1077;&#1090; &#1074;
        &#1072;&#1076;&#1088;&#1077;&#1089; &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088;&#1072;
        &#1086;&#1073;&#1088;&#1072;&#1097;&#1077;&#1085;&#1080;&#1077; &#1087;&#1086;
        &#1101;&#1083;&#1077;&#1082;&#1090;&#1088;&#1086;&#1085;&#1085;&#1086;&#1084;&#1091;
        &#1072;&#1076;&#1088;&#1077;&#1089;&#1091;, &#1082;&#1086;&#1090;&#1086;&#1088;&#1099;&#1081;
        &#1091;&#1082;&#1072;&#1079;&#1072;&#1085; &#1074;
        &#1085;&#1072;&#1089;&#1090;&#1086;&#1103;&#1097;&#1077;&#1081; &#1054;&#1092;&#1077;&#1088;&#1090;&#1077;.
        &#1054;&#1073;&#1088;&#1072;&#1097;&#1077;&#1085;&#1080;&#1077; &#1076;&#1086;&#1083;&#1078;&#1085;&#1086;
        &#1089;&#1086;&#1076;&#1077;&#1088;&#1078;&#1072;&#1090;&#1100;:</span></li>
  </ol>
  <ul className="c10 lst-kix_cxldnylomimy-3 start">
    <li className="c13 li-bullet-5"><span className="c2">&#1057;&#1074;&#1077;&#1076;&#1077;&#1085;&#1080;&#1103; &#1086;
        &#1079;&#1072;&#1103;&#1074;&#1080;&#1090;&#1077;&#1083;&#1077; (&#1060;&#1048;&#1054; &#1080;&#1083;&#1080;
        &#1085;&#1072;&#1080;&#1084;&#1077;&#1085;&#1086;&#1074;&#1072;&#1085;&#1080;&#1077;
        &#1086;&#1088;&#1075;&#1072;&#1085;&#1080;&#1079;&#1072;&#1094;&#1080;&#1080;, &#1072;
        &#1090;&#1072;&#1082;&#1078;&#1077; &#1048;&#1053;&#1053;
        &#1086;&#1088;&#1075;&#1072;&#1085;&#1080;&#1079;&#1072;&#1094;&#1080;&#1080;),
        &#1082;&#1086;&#1085;&#1090;&#1072;&#1082;&#1090;&#1085;&#1099;&#1081; e-mail,
        &#1087;&#1086;&#1095;&#1090;&#1086;&#1074;&#1099;&#1081; &#1072;&#1076;&#1088;&#1077;&#1089;
        &#1076;&#1083;&#1103; &#1085;&#1072;&#1087;&#1088;&#1072;&#1074;&#1083;&#1077;&#1085;&#1080;&#1103;
        &#1082;&#1086;&#1088;&#1088;&#1077;&#1089;&#1087;&#1086;&#1085;&#1076;&#1077;&#1085;&#1094;&#1080;&#1080;.
      </span></li>
    <li className="c13 li-bullet-6"><span className="c2">&#1043;&#1086;&#1088;&#1086;&#1076;,
        &#1089;&#1091;&#1073;&#1098;&#1077;&#1082;&#1090;
        &#1060;&#1077;&#1076;&#1077;&#1088;&#1072;&#1094;&#1080;&#1080; (&#1080;&#1083;&#1080;
        &#1075;&#1086;&#1088;&#1086;&#1076;
        &#1092;&#1077;&#1076;&#1077;&#1088;&#1072;&#1083;&#1100;&#1085;&#1086;&#1075;&#1086;
        &#1079;&#1085;&#1072;&#1095;&#1077;&#1085;&#1080;&#1103;), &#1089;&#1090;&#1088;&#1072;&#1085;&#1072;
        &#1075;&#1076;&#1077; &#1087;&#1088;&#1086;&#1078;&#1080;&#1074;&#1072;&#1077;&#1090;
        &#1047;&#1072;&#1103;&#1074;&#1080;&#1090;&#1077;&#1083;&#1100;.</span></li>
    <li className="c13 li-bullet-6"><span className="c2">&#1057;&#1074;&#1077;&#1076;&#1077;&#1085;&#1080;&#1103; &#1086;
        &#1083;&#1080;&#1094;&#1077; (&#1060;&#1048;&#1054; &#1080;&#1083;&#1080;
        &#1085;&#1072;&#1080;&#1084;&#1077;&#1085;&#1086;&#1074;&#1072;&#1085;&#1080;&#1077;
        &#1086;&#1088;&#1075;&#1072;&#1085;&#1080;&#1079;&#1072;&#1094;&#1080;&#1080;, &#1072;
        &#1090;&#1072;&#1082;&#1078;&#1077; &#1048;&#1053;&#1053;
        &#1086;&#1088;&#1075;&#1072;&#1085;&#1080;&#1079;&#1072;&#1094;&#1080;&#1080;),
        &#1082;&#1086;&#1090;&#1086;&#1088;&#1086;&#1077; &#1087;&#1086; &#1084;&#1085;&#1077;&#1085;&#1080;&#1102;
        &#1079;&#1072;&#1103;&#1074;&#1080;&#1090;&#1077;&#1083;&#1103;,
        &#1085;&#1072;&#1088;&#1091;&#1096;&#1080;&#1083;&#1086;
        &#1080;&#1085;&#1090;&#1077;&#1083;&#1083;&#1077;&#1082;&#1090;&#1091;&#1072;&#1083;&#1100;&#1085;&#1099;&#1077;
        &#1087;&#1088;&#1072;&#1074;&#1072; &#1079;&#1072;&#1103;&#1074;&#1080;&#1090;&#1077;&#1083;&#1103;.</span></li>
    <li className="c13 li-bullet-7"><span className="c2">&#1054;&#1087;&#1080;&#1089;&#1072;&#1085;&#1080;&#1077;
        &#1085;&#1072;&#1088;&#1091;&#1096;&#1077;&#1085;&#1080;&#1103;
        (&#1085;&#1072;&#1079;&#1074;&#1072;&#1085;&#1080;&#1077; &#1082;&#1091;&#1088;&#1089;&#1072;,
        &#1089;&#1074;&#1077;&#1076;&#1077;&#1085;&#1080;&#1103; &#1086;
        &#1079;&#1072;&#1088;&#1077;&#1075;&#1080;&#1089;&#1090;&#1088;&#1080;&#1088;&#1086;&#1074;&#1072;&#1085;&#1085;&#1086;&#1084;
        &#1087;&#1088;&#1072;&#1074;&#1077;, &#1082;&#1086;&#1090;&#1086;&#1088;&#1086;&#1077;
        &#1085;&#1072;&#1088;&#1091;&#1096;&#1077;&#1085;&#1086; &#1087;&#1086;
        &#1084;&#1085;&#1077;&#1085;&#1080;&#1102; &#1079;&#1072;&#1103;&#1074;&#1080;&#1090;&#1077;&#1083;&#1103;
        &#1080; &#1087;&#1088;.), &#1089; &#1087;&#1088;&#1080;&#1083;&#1086;&#1078;&#1077;&#1085;&#1080;&#1077;&#1084;
        &#1076;&#1086;&#1082;&#1072;&#1079;&#1072;&#1090;&#1077;&#1083;&#1100;&#1089;&#1090;&#1074;.</span></li>
    <li className="c13 li-bullet-7"><span className="c2">&#1054;&#1090;&#1074;&#1077;&#1090;
        &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1072; (&#1087;&#1088;&#1080;
        &#1085;&#1072;&#1083;&#1080;&#1095;&#1080;&#1080;),
        &#1076;&#1086;&#1082;&#1072;&#1079;&#1072;&#1090;&#1077;&#1083;&#1100;&#1089;&#1090;&#1074;&#1072;
        &#1086;&#1089;&#1091;&#1097;&#1077;&#1089;&#1090;&#1074;&#1083;&#1077;&#1085;&#1080;&#1103;
        &#1087;&#1088;&#1103;&#1084;&#1086;&#1075;&#1086; &#1082;&#1086;&#1085;&#1090;&#1072;&#1082;&#1090;&#1072;
        &#1074; &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1086;&#1084; (&#1074;
        &#1089;&#1083;&#1091;&#1095;&#1072;&#1077; &#1077;&#1089;&#1083;&#1080; &#1073;&#1099;&#1083;&#1080;
        &#1080;&#1079;&#1074;&#1077;&#1089;&#1090;&#1085;&#1099;
        &#1089;&#1074;&#1077;&#1076;&#1077;&#1085;&#1080;&#1103; &#1086;
        &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1077;).</span></li>
  </ul>
  <ol className="c10 lst-kix_qdq1ctoq1dix-2" start={3}>
    <li className="c14 li-bullet-3"><span className="c2">&#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088;,
        &#1087;&#1088;&#1080; &#1087;&#1086;&#1089;&#1090;&#1091;&#1087;&#1083;&#1077;&#1085;&#1080;&#1080; &#1082;
        &#1085;&#1077;&#1084;&#1091; &#1086;&#1073;&#1088;&#1072;&#1097;&#1077;&#1085;&#1080;&#1103; &#1086;
        &#1085;&#1072;&#1088;&#1091;&#1096;&#1077;&#1085;&#1080;&#1080; &#1087;&#1088;&#1072;&#1074;,
        &#1089;&#1074;&#1103;&#1079;&#1099;&#1074;&#1072;&#1077;&#1090;&#1089;&#1103; &#1089;
        &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1086;&#1084;,
        &#1079;&#1072;&#1087;&#1088;&#1072;&#1096;&#1080;&#1074;&#1072;&#1077;&#1090; &#1086;&#1090;
        &#1085;&#1077;&#1075;&#1086; &#1089;&#1074;&#1086;&#1080;
        &#1087;&#1086;&#1103;&#1089;&#1085;&#1077;&#1085;&#1080;&#1103;
        &#1086;&#1090;&#1085;&#1086;&#1089;&#1080;&#1090;&#1077;&#1083;&#1100;&#1085;&#1086;
        &#1074;&#1086;&#1079;&#1085;&#1080;&#1082;&#1096;&#1077;&#1081;
        &#1089;&#1080;&#1090;&#1091;&#1072;&#1094;&#1080;&#1080;, &#1072; &#1090;&#1072;&#1082;&#1078;&#1077;
        &#1087;&#1088;&#1077;&#1076;&#1083;&#1072;&#1075;&#1072;&#1077;&#1090;
        &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1091;
        &#1076;&#1086;&#1073;&#1088;&#1086;&#1074;&#1086;&#1083;&#1100;&#1085;&#1086;
        &#1091;&#1076;&#1072;&#1083;&#1080;&#1090;&#1100; &#1082;&#1086;&#1085;&#1090;&#1077;&#1085;&#1090;,
        &#1082;&#1086;&#1090;&#1086;&#1088;&#1099;&#1081; &#1084;&#1086;&#1078;&#1077;&#1090;
        &#1085;&#1072;&#1088;&#1091;&#1096;&#1072;&#1090;&#1100; &#1087;&#1088;&#1072;&#1074;&#1072;
        &#1079;&#1072;&#1103;&#1074;&#1080;&#1090;&#1077;&#1083;&#1103;.</span></li>
    <li className="c14 li-bullet-8"><span className="c2">&#1042; &#1089;&#1083;&#1091;&#1095;&#1072;&#1077;
        &#1077;&#1089;&#1083;&#1080; &#1074; &#1090;&#1077;&#1095;&#1077;&#1085;&#1080;&#1077; 5
        &#1082;&#1072;&#1083;&#1077;&#1085;&#1076;&#1072;&#1088;&#1085;&#1099;&#1093; &#1076;&#1085;&#1077;&#1081;
        &#1089; &#1084;&#1086;&#1084;&#1077;&#1085;&#1090;&#1072;
        &#1085;&#1072;&#1087;&#1088;&#1072;&#1074;&#1083;&#1077;&#1085;&#1080;&#1103;
        &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088;&#1086;&#1084;
        &#1086;&#1073;&#1088;&#1072;&#1097;&#1077;&#1085;&#1080;&#1103;,
        &#1091;&#1082;&#1072;&#1079;&#1072;&#1085;&#1085;&#1086;&#1075;&#1086; &#1074; &#1087;. 5.6.3
        &#1085;&#1072;&#1089;&#1090;&#1086;&#1103;&#1097;&#1077;&#1081; &#1054;&#1092;&#1077;&#1088;&#1090;&#1099;,
        &#1086;&#1090; &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1072; &#1085;&#1077;
        &#1087;&#1086;&#1089;&#1090;&#1091;&#1087;&#1080;&#1090;
        &#1084;&#1086;&#1090;&#1080;&#1074;&#1080;&#1088;&#1086;&#1074;&#1072;&#1085;&#1085;&#1099;&#1093;
        &#1087;&#1086;&#1103;&#1089;&#1085;&#1077;&#1085;&#1080;&#1081;
        &#1086;&#1090;&#1085;&#1086;&#1089;&#1080;&#1090;&#1077;&#1083;&#1100;&#1085;&#1086;
        &#1086;&#1073;&#1088;&#1072;&#1097;&#1077;&#1085;&#1080;&#1103;
        &#1079;&#1072;&#1103;&#1074;&#1080;&#1090;&#1077;&#1083;&#1103;, &#1083;&#1080;&#1073;&#1086;
        &#1077;&#1089;&#1083;&#1080; &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090; &#1085;&#1077;
        &#1087;&#1088;&#1080;&#1079;&#1085;&#1072;&#1077;&#1090; &#1089;&#1086;&#1073;&#1099;&#1090;&#1080;&#1103;,
        &#1091;&#1082;&#1072;&#1079;&#1072;&#1085;&#1085;&#1099;&#1077;
        &#1079;&#1072;&#1103;&#1074;&#1080;&#1090;&#1077;&#1083;&#1077;&#1084; &#1074;
        &#1086;&#1073;&#1088;&#1072;&#1097;&#1077;&#1085;&#1080;&#1080;, &#1083;&#1080;&#1073;&#1086;
        &#1086;&#1090;&#1082;&#1072;&#1078;&#1077;&#1090;&#1089;&#1103;
        &#1091;&#1076;&#1072;&#1083;&#1080;&#1090;&#1100; &#1082;&#1086;&#1085;&#1090;&#1077;&#1085;&#1090;,
        &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088;
        &#1086;&#1089;&#1091;&#1097;&#1077;&#1089;&#1090;&#1074;&#1083;&#1103;&#1077;&#1090;
        &#1089;&#1072;&#1084;&#1086;&#1089;&#1090;&#1086;&#1103;&#1090;&#1077;&#1083;&#1100;&#1085;&#1091;&#1102;
        &#1087;&#1088;&#1086;&#1074;&#1077;&#1088;&#1082;&#1091;
        &#1086;&#1073;&#1088;&#1072;&#1097;&#1077;&#1085;&#1080;&#1103;
        &#1079;&#1072;&#1103;&#1074;&#1080;&#1090;&#1077;&#1083;&#1103; &#1085;&#1072;
        &#1087;&#1088;&#1077;&#1076;&#1084;&#1077;&#1090;
        &#1085;&#1072;&#1088;&#1091;&#1096;&#1077;&#1085;&#1080;&#1103; &#1077;&#1075;&#1086;
        &#1087;&#1088;&#1072;&#1074;.</span></li>
    <li className="c14 li-bullet-3"><span className="c2">&#1042; &#1089;&#1083;&#1091;&#1095;&#1072;&#1077;
        &#1077;&#1089;&#1083;&#1080; &#1074; &#1090;&#1077;&#1095;&#1077;&#1085;&#1080;&#1077; 5
        &#1082;&#1072;&#1083;&#1077;&#1085;&#1076;&#1072;&#1088;&#1085;&#1099;&#1093; &#1076;&#1085;&#1077;&#1081;
        &#1089; &#1084;&#1086;&#1084;&#1077;&#1085;&#1090;&#1072;
        &#1085;&#1072;&#1087;&#1088;&#1072;&#1074;&#1083;&#1077;&#1085;&#1080;&#1103;
        &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088;&#1086;&#1084;
        &#1086;&#1073;&#1088;&#1072;&#1097;&#1077;&#1085;&#1080;&#1103;,
        &#1091;&#1082;&#1072;&#1079;&#1072;&#1085;&#1085;&#1086;&#1075;&#1086; &#1074; &#1087;. 5.6.3
        &#1085;&#1072;&#1089;&#1090;&#1086;&#1103;&#1097;&#1077;&#1081; &#1054;&#1092;&#1077;&#1088;&#1090;&#1099;,
        &#1086;&#1090; &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1072; &#1085;&#1077;
        &#1087;&#1086;&#1089;&#1090;&#1091;&#1087;&#1080;&#1090;
        &#1084;&#1086;&#1090;&#1080;&#1074;&#1080;&#1088;&#1086;&#1074;&#1072;&#1085;&#1085;&#1099;&#1093;
        &#1087;&#1086;&#1103;&#1089;&#1085;&#1077;&#1085;&#1080;&#1081;
        &#1086;&#1090;&#1085;&#1086;&#1089;&#1080;&#1090;&#1077;&#1083;&#1100;&#1085;&#1086;
        &#1086;&#1073;&#1088;&#1072;&#1097;&#1077;&#1085;&#1080;&#1103;
        &#1079;&#1072;&#1103;&#1074;&#1080;&#1090;&#1077;&#1083;&#1103;,
        &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088; &#1074;&#1087;&#1088;&#1072;&#1074;&#1077;
        &#1079;&#1072;&#1073;&#1083;&#1086;&#1082;&#1080;&#1088;&#1086;&#1074;&#1072;&#1090;&#1100;
        &#1089;&#1087;&#1086;&#1088;&#1085;&#1099;&#1081; &#1082;&#1086;&#1085;&#1090;&#1077;&#1085;&#1090;
        &#1073;&#1077;&#1079; &#1087;&#1088;&#1086;&#1074;&#1077;&#1076;&#1077;&#1085;&#1080;&#1103;
        &#1087;&#1088;&#1086;&#1074;&#1077;&#1088;&#1082;&#1080;.</span></li>
    <li className="c14 li-bullet-4"><span className="c2">&#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088;
        &#1087;&#1086;&#1089;&#1083;&#1077;
        &#1087;&#1086;&#1089;&#1090;&#1091;&#1087;&#1083;&#1077;&#1085;&#1080;&#1103; &#1086;&#1090;
        &#1085;&#1077;&#1075;&#1086; &#1086;&#1090;&#1074;&#1077;&#1090;&#1072;
        &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1072;,
        &#1082;&#1086;&#1090;&#1086;&#1088;&#1099;&#1081;
        &#1089;&#1086;&#1086;&#1090;&#1074;&#1077;&#1090;&#1089;&#1090;&#1074;&#1091;&#1077;&#1090; &#1087;. 5.6.4
        &#1054;&#1092;&#1077;&#1088;&#1090;&#1099;, &#1074; &#1090;&#1077;&#1095;&#1077;&#1085;&#1080;&#1077; 5
        &#1088;&#1072;&#1073;&#1086;&#1095;&#1080;&#1093; &#1076;&#1085;&#1077;&#1081;
        &#1088;&#1072;&#1089;&#1089;&#1084;&#1072;&#1090;&#1088;&#1080;&#1074;&#1072;&#1077;&#1090;
        &#1086;&#1073;&#1088;&#1072;&#1097;&#1077;&#1085;&#1080;&#1103;
        &#1079;&#1072;&#1103;&#1074;&#1080;&#1090;&#1077;&#1083;&#1103; &#1080;
        &#1086;&#1089;&#1091;&#1097;&#1077;&#1089;&#1090;&#1074;&#1083;&#1103;&#1077;&#1090; &#1077;&#1075;&#1086;
        &#1087;&#1088;&#1086;&#1074;&#1077;&#1088;&#1082;&#1091;. &#1042; &#1089;&#1083;&#1091;&#1095;&#1072;&#1077;
        &#1077;&#1089;&#1083;&#1080; &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088;
        &#1087;&#1088;&#1080;&#1093;&#1086;&#1076;&#1080;&#1090; &#1082; &#1074;&#1099;&#1074;&#1086;&#1076;&#1091;
        &#1086; &#1090;&#1086;&#1084;, &#1095;&#1090;&#1086; &#1082;&#1086;&#1085;&#1090;&#1077;&#1085;&#1090;,
        &#1088;&#1072;&#1079;&#1084;&#1077;&#1097;&#1077;&#1085;&#1085;&#1099;&#1081;
        &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1086;&#1084;,
        &#1085;&#1072;&#1088;&#1091;&#1096;&#1072;&#1077;&#1090; &#1087;&#1088;&#1072;&#1074;&#1072;
        &#1079;&#1072;&#1103;&#1074;&#1080;&#1090;&#1077;&#1083;&#1103;, &#1086;&#1085;
        &#1073;&#1083;&#1086;&#1082;&#1080;&#1088;&#1091;&#1077;&#1090; &#1076;&#1086;&#1089;&#1090;&#1091;&#1087;
        &#1082; &#1085;&#1077;&#1084;&#1091; &#1076;&#1083;&#1103; &#1083;&#1102;&#1073;&#1099;&#1093;
        &#1083;&#1080;&#1094;, &#1074; &#1090;.&#1095;. &#1080;
        &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1072;.
        &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088; &#1090;&#1072;&#1082;&#1078;&#1077;
        &#1074;&#1087;&#1088;&#1072;&#1074;&#1077; &#1091;&#1076;&#1072;&#1083;&#1080;&#1090;&#1100;
        &#1082;&#1086;&#1085;&#1090;&#1077;&#1085;&#1090;
        &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1072;,
        &#1082;&#1086;&#1090;&#1086;&#1088;&#1099;&#1081; &#1085;&#1072;&#1088;&#1091;&#1096;&#1072;&#1077;&#1090;
        &#1087;&#1088;&#1072;&#1074;&#1072;
        &#1086;&#1073;&#1088;&#1072;&#1090;&#1080;&#1074;&#1096;&#1077;&#1075;&#1086;&#1089;&#1103;
        &#1090;&#1088;&#1077;&#1090;&#1100;&#1077;&#1075;&#1086; &#1083;&#1080;&#1094;&#1072;.</span></li>
    <li className="c14 li-bullet-8"><span className="c2">&#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088;
        &#1087;&#1088;&#1080; &#1088;&#1072;&#1089;&#1089;&#1084;&#1086;&#1090;&#1088;&#1077;&#1085;&#1080;&#1080;
        &#1086;&#1073;&#1088;&#1072;&#1097;&#1077;&#1085;&#1080;&#1103;
        &#1079;&#1072;&#1103;&#1074;&#1080;&#1090;&#1077;&#1083;&#1103; &#1074;&#1087;&#1088;&#1072;&#1074;&#1077;
        &#1086;&#1090;&#1082;&#1072;&#1079;&#1072;&#1090;&#1100;
        &#1079;&#1072;&#1103;&#1074;&#1080;&#1090;&#1077;&#1083;&#1102; &#1074;
        &#1091;&#1076;&#1086;&#1074;&#1083;&#1077;&#1090;&#1074;&#1086;&#1088;&#1077;&#1085;&#1080;&#1080;
        &#1077;&#1075;&#1086; &#1086;&#1073;&#1088;&#1072;&#1097;&#1077;&#1085;&#1080;&#1103; &#1074;
        &#1089;&#1083;&#1091;&#1095;&#1072;&#1077; &#1085;&#1072;&#1083;&#1080;&#1095;&#1080;&#1103;
        &#1082;&#1072;&#1082;&#1086;&#1075;&#1086;-&#1083;&#1080;&#1073;&#1086; &#1080;&#1079;
        &#1089;&#1083;&#1077;&#1076;&#1091;&#1102;&#1097;&#1080;&#1093;
        &#1091;&#1089;&#1083;&#1086;&#1074;&#1080;&#1081;:</span></li>
  </ol>
  <ul className="c10 lst-kix_qdq1ctoq1dix-3 start">
    <li className="c13 li-bullet-6"><span className="c2">&#1044;&#1072;&#1085;&#1085;&#1099;&#1077; &#1086;
        &#1085;&#1072;&#1088;&#1091;&#1096;&#1077;&#1085;&#1080;&#1080; &#1087;&#1088;&#1072;&#1074;
        &#1079;&#1072;&#1103;&#1074;&#1080;&#1090;&#1077;&#1083;&#1103; &#1085;&#1077;
        &#1085;&#1072;&#1096;&#1083;&#1080;
        &#1087;&#1086;&#1076;&#1090;&#1074;&#1077;&#1088;&#1078;&#1076;&#1077;&#1085;&#1080;&#1103;
        &#1087;&#1088;&#1080; &#1087;&#1088;&#1086;&#1074;&#1077;&#1088;&#1082;&#1077;
        &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088;&#1086;&#1084;.</span></li>
    <li className="c13 li-bullet-9"><span className="c2">&#1047;&#1072;&#1103;&#1074;&#1080;&#1090;&#1077;&#1083;&#1100;
        &#1079;&#1083;&#1086;&#1091;&#1087;&#1086;&#1090;&#1088;&#1077;&#1073;&#1083;&#1103;&#1077;&#1090;
        &#1085;&#1077;&#1086;&#1076;&#1085;&#1086;&#1082;&#1088;&#1072;&#1090;&#1085;&#1099;&#1084;&#1080;
        &#1086;&#1073;&#1088;&#1072;&#1097;&#1077;&#1085;&#1080;&#1103;&#1084;&#1080; &#1074;
        &#1072;&#1076;&#1088;&#1077;&#1089; &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088;&#1072;,
        &#1087;&#1086;&#1089;&#1083;&#1077; &#1087;&#1088;&#1086;&#1074;&#1077;&#1088;&#1082;&#1080;
        &#1082;&#1086;&#1090;&#1086;&#1088;&#1099;&#1093;
        &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088; &#1085;&#1077;
        &#1085;&#1072;&#1093;&#1086;&#1076;&#1080;&#1090;
        &#1087;&#1086;&#1076;&#1090;&#1074;&#1077;&#1088;&#1078;&#1076;&#1077;&#1085;&#1080;&#1103;
        &#1086;&#1073;&#1089;&#1090;&#1086;&#1103;&#1090;&#1077;&#1083;&#1100;&#1089;&#1090;&#1074;&#1072;&#1084;,
        &#1091;&#1082;&#1072;&#1079;&#1072;&#1085;&#1085;&#1099;&#1084;
        &#1079;&#1072;&#1103;&#1074;&#1080;&#1090;&#1077;&#1083;&#1077;&#1084; &#1074;
        &#1086;&#1073;&#1088;&#1072;&#1097;&#1077;&#1085;&#1080;&#1080;.</span></li>
  </ul>
  <ol className="c10 lst-kix_qdq1ctoq1dix-2" start={8}>
    <li className="c14 li-bullet-3"><span className="c2">&#1054;
        &#1088;&#1077;&#1079;&#1091;&#1083;&#1100;&#1090;&#1072;&#1090;&#1072;&#1093;
        &#1088;&#1072;&#1089;&#1089;&#1084;&#1086;&#1090;&#1088;&#1077;&#1085;&#1080;&#1103;
        &#1086;&#1073;&#1088;&#1072;&#1097;&#1077;&#1085;&#1080;&#1103;
        &#1079;&#1072;&#1103;&#1074;&#1080;&#1090;&#1077;&#1083;&#1103;
        &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088;
        &#1091;&#1074;&#1077;&#1076;&#1086;&#1084;&#1083;&#1103;&#1077;&#1090;
        &#1079;&#1072;&#1103;&#1074;&#1080;&#1090;&#1077;&#1083;&#1103; &#1080;
        &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1072;.
        &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088; &#1090;&#1072;&#1082;&#1078;&#1077;
        &#1087;&#1088;&#1077;&#1076;&#1086;&#1089;&#1090;&#1072;&#1074;&#1083;&#1103;&#1077;&#1090;,
        &#1079;&#1072;&#1103;&#1074;&#1080;&#1090;&#1077;&#1083;&#1102;
        &#1080;&#1084;&#1077;&#1102;&#1097;&#1080;&#1077;&#1089;&#1103; &#1091; &#1085;&#1077;&#1075;&#1086;
        &#1089;&#1074;&#1077;&#1076;&#1077;&#1085;&#1080;&#1103;,
        &#1087;&#1086;&#1079;&#1074;&#1086;&#1083;&#1103;&#1102;&#1097;&#1080;&#1077;
        &#1080;&#1076;&#1077;&#1085;&#1090;&#1080;&#1092;&#1080;&#1094;&#1080;&#1088;&#1086;&#1074;&#1072;&#1090;&#1100;
        &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1072; &#1076;&#1083;&#1103;
        &#1086;&#1073;&#1088;&#1072;&#1097;&#1077;&#1085;&#1080;&#1103;
        &#1079;&#1072;&#1103;&#1074;&#1080;&#1090;&#1077;&#1083;&#1103; &#1079;&#1072;
        &#1088;&#1072;&#1079;&#1088;&#1077;&#1096;&#1077;&#1085;&#1080;&#1077;&#1084;
        &#1089;&#1087;&#1086;&#1088;&#1072; &#1074; &#1089;&#1091;&#1076;&#1077;&#1073;&#1085;&#1086;&#1084;
        &#1087;&#1086;&#1088;&#1103;&#1076;&#1082;&#1077;.</span></li>
  </ol>
  <ol className="c10 lst-kix_qdq1ctoq1dix-1" start={7}>
    <li className="c0 li-bullet-1"><span className="c2">&#1047;&#1072;&#1103;&#1074;&#1080;&#1090;&#1077;&#1083;&#1100;
        &#1076;&#1072;&#1077;&#1090; &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088;&#1091;
        &#1089;&#1086;&#1075;&#1083;&#1072;&#1089;&#1080;&#1077; &#1085;&#1072;
        &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1082;&#1091; &#1077;&#1075;&#1086;
        &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1100;&#1085;&#1099;&#1093;
        &#1076;&#1072;&#1085;&#1085;&#1099;&#1093;
        &#1089;&#1086;&#1074;&#1077;&#1088;&#1096;&#1072;&#1077;&#1084;&#1091;&#1102; &#1089;
        &#1080;&#1089;&#1087;&#1086;&#1083;&#1100;&#1079;&#1086;&#1074;&#1072;&#1085;&#1080;&#1077;&#1084;
        &#1089;&#1088;&#1077;&#1076;&#1089;&#1090;&#1074;
        &#1072;&#1074;&#1090;&#1086;&#1084;&#1072;&#1090;&#1080;&#1079;&#1072;&#1094;&#1080;&#1080;, &#1080;
        &#1073;&#1077;&#1079;
        &#1080;&#1089;&#1087;&#1086;&#1083;&#1100;&#1079;&#1086;&#1074;&#1072;&#1085;&#1080;&#1103;
        &#1089;&#1088;&#1077;&#1076;&#1089;&#1090;&#1074;
        &#1072;&#1074;&#1090;&#1086;&#1084;&#1072;&#1090;&#1080;&#1079;&#1072;&#1094;&#1080;&#1080;,
        &#1074;&#1082;&#1083;&#1102;&#1095;&#1072;&#1103; &#1089;&#1073;&#1086;&#1088;,
        &#1079;&#1072;&#1087;&#1080;&#1089;&#1100;,
        &#1089;&#1080;&#1089;&#1090;&#1077;&#1084;&#1072;&#1090;&#1080;&#1079;&#1072;&#1094;&#1080;&#1102;,
        &#1085;&#1072;&#1082;&#1086;&#1087;&#1083;&#1077;&#1085;&#1080;&#1077;,
        &#1093;&#1088;&#1072;&#1085;&#1077;&#1085;&#1080;&#1077;,
        &#1091;&#1090;&#1086;&#1095;&#1085;&#1077;&#1085;&#1080;&#1077;
        (&#1086;&#1073;&#1085;&#1086;&#1074;&#1083;&#1077;&#1085;&#1080;&#1077;,
        &#1080;&#1079;&#1084;&#1077;&#1085;&#1077;&#1085;&#1080;&#1077;),
        &#1080;&#1079;&#1074;&#1083;&#1077;&#1095;&#1077;&#1085;&#1080;&#1077;,
        &#1080;&#1089;&#1087;&#1086;&#1083;&#1100;&#1079;&#1086;&#1074;&#1072;&#1085;&#1080;&#1077;,
        &#1087;&#1077;&#1088;&#1077;&#1076;&#1072;&#1095;&#1091;,
        &#1087;&#1088;&#1077;&#1076;&#1086;&#1089;&#1090;&#1072;&#1074;&#1083;&#1077;&#1085;&#1080;&#1077;,
        &#1086;&#1073;&#1077;&#1079;&#1083;&#1080;&#1095;&#1080;&#1074;&#1072;&#1085;&#1080;&#1077;,
        &#1073;&#1083;&#1086;&#1082;&#1080;&#1088;&#1086;&#1074;&#1072;&#1085;&#1080;&#1077;,
        &#1091;&#1076;&#1072;&#1083;&#1077;&#1085;&#1080;&#1077;,
        &#1091;&#1085;&#1080;&#1095;&#1090;&#1086;&#1078;&#1077;&#1085;&#1080;&#1077;
        &#1076;&#1072;&#1085;&#1085;&#1099;&#1093; &#1089; &#1094;&#1077;&#1083;&#1100;&#1102;
        &#1088;&#1072;&#1089;&#1089;&#1084;&#1086;&#1090;&#1088;&#1077;&#1085;&#1080;&#1103;
        &#1086;&#1073;&#1088;&#1072;&#1097;&#1077;&#1085;&#1080;&#1103;
        &#1079;&#1072;&#1103;&#1074;&#1080;&#1090;&#1077;&#1083;&#1103; &#1080;
        &#1087;&#1088;&#1080;&#1085;&#1103;&#1090;&#1080;&#1103; &#1087;&#1086; &#1085;&#1077;&#1084;&#1091;
        &#1088;&#1077;&#1096;&#1077;&#1085;&#1080;&#1103;. &#1057;&#1088;&#1086;&#1082;
        &#1080;&#1089;&#1087;&#1086;&#1083;&#1100;&#1079;&#1086;&#1074;&#1072;&#1085;&#1080;&#1103;
        &#1087;&#1088;&#1077;&#1076;&#1086;&#1089;&#1090;&#1072;&#1074;&#1083;&#1077;&#1085;&#1085;&#1099;&#1093;
        &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1086;&#1084;
        &#1076;&#1072;&#1085;&#1085;&#1099;&#1093; -
        &#1073;&#1077;&#1089;&#1089;&#1088;&#1086;&#1095;&#1085;&#1086;.</span></li>
  </ol>
  <h2 className="c20"><span className="c9"></span></h2>
  <h2 className="c3"><span className="c16">6. &#1055;&#1088;&#1086;&#1095;&#1080;&#1077;
      &#1091;&#1089;&#1083;&#1086;&#1074;&#1080;&#1103;</span></h2>
  <p className="c6"><span className="c2">6.1. &#1050; &#1086;&#1090;&#1085;&#1086;&#1096;&#1077;&#1085;&#1080;&#1103;&#1084;,
      &#1074;&#1086;&#1079;&#1085;&#1080;&#1082;&#1096;&#1080;&#1084; &#1087;&#1086;
      &#1085;&#1072;&#1089;&#1090;&#1086;&#1103;&#1097;&#1077;&#1084;&#1091;
      &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1091; &#1087;&#1086;&#1076;&#1083;&#1077;&#1078;&#1080;&#1090;
      &#1087;&#1088;&#1080;&#1084;&#1077;&#1085;&#1077;&#1085;&#1080;&#1102; &#1087;&#1088;&#1072;&#1074;&#1086;
      &#1056;&#1086;&#1089;&#1089;&#1080;&#1081;&#1089;&#1082;&#1086;&#1081;
      &#1060;&#1077;&#1076;&#1077;&#1088;&#1072;&#1094;&#1080;&#1080;
      &#1080;&#1089;&#1082;&#1083;&#1102;&#1095;&#1072;&#1103;
      &#1082;&#1086;&#1083;&#1083;&#1080;&#1079;&#1080;&#1086;&#1085;&#1085;&#1099;&#1077;
      &#1085;&#1086;&#1088;&#1084;&#1099;.</span></p>
  <p className="c6"><span className="c2">6.2. &#1055;&#1086;
      &#1087;&#1080;&#1089;&#1100;&#1084;&#1077;&#1085;&#1085;&#1086;&#1084;&#1091;
      &#1090;&#1088;&#1077;&#1073;&#1086;&#1074;&#1072;&#1085;&#1080;&#1102;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1072;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088; &#1084;&#1086;&#1078;&#1077;&#1090;
      &#1086;&#1092;&#1086;&#1088;&#1084;&#1080;&#1090;&#1100; &#1087;&#1077;&#1095;&#1072;&#1090;&#1085;&#1091;&#1102;
      &#1074;&#1077;&#1088;&#1089;&#1080;&#1102; &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1072; &#1089;
      &#1087;&#1086;&#1076;&#1087;&#1080;&#1089;&#1103;&#1084;&#1080; &#1057;&#1090;&#1086;&#1088;&#1086;&#1085;.</span>
  </p>
  <p className="c6"><span className="c2">6.3. &#1055;&#1080;&#1089;&#1100;&#1084;&#1077;&#1085;&#1085;&#1099;&#1084;
      &#1090;&#1088;&#1077;&#1073;&#1086;&#1074;&#1072;&#1085;&#1080;&#1077;&#1084;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1072; &#1086;
      &#1087;&#1086;&#1076;&#1087;&#1080;&#1089;&#1072;&#1085;&#1080;&#1080;
      &#1073;&#1091;&#1084;&#1072;&#1078;&#1085;&#1086;&#1075;&#1086;
      &#1101;&#1082;&#1079;&#1077;&#1084;&#1087;&#1083;&#1103;&#1088;&#1072;
      &#1085;&#1072;&#1089;&#1090;&#1086;&#1103;&#1097;&#1077;&#1075;&#1086;
      &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1072;
      &#1089;&#1095;&#1080;&#1090;&#1072;&#1077;&#1090;&#1089;&#1103;
      &#1076;&#1086;&#1089;&#1090;&#1072;&#1074;&#1082;&#1072; &#1074; &#1086;&#1092;&#1080;&#1089;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088;&#1072; &#1087;&#1086;
      &#1072;&#1076;&#1088;&#1077;&#1089;&#1091;, &#1091;&#1082;&#1072;&#1079;&#1072;&#1085;&#1085;&#1086;&#1084;&#1091;
      &#1074; &#1088;&#1072;&#1079;&#1076;&#1077;&#1083;&#1077; 7
      &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1072;,
      &#1087;&#1086;&#1076;&#1087;&#1080;&#1089;&#1072;&#1085;&#1085;&#1086;&#1081;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1086;&#1084; &#1074; &#1076;&#1074;&#1091;&#1093;
      &#1101;&#1082;&#1079;&#1077;&#1084;&#1087;&#1083;&#1103;&#1088;&#1072;&#1093;
      &#1087;&#1077;&#1095;&#1072;&#1090;&#1085;&#1086;&#1081; &#1074;&#1077;&#1088;&#1089;&#1080;&#1080;
      &#1085;&#1072;&#1089;&#1090;&#1086;&#1103;&#1097;&#1077;&#1075;&#1086;
      &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1072;,
      &#1089;&#1086;&#1076;&#1077;&#1088;&#1078;&#1072;&#1097;&#1077;&#1081;
      &#1088;&#1077;&#1082;&#1074;&#1080;&#1079;&#1080;&#1090;&#1099;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1072;. &#1040;&#1076;&#1088;&#1077;&#1089;
      &#1076;&#1083;&#1103; &#1086;&#1090;&#1087;&#1088;&#1072;&#1074;&#1082;&#1080;: 115191, &#1075;.
      &#1052;&#1086;&#1089;&#1082;&#1074;&#1072;, &#1087;&#1077;&#1088;.
      &#1044;&#1091;&#1093;&#1086;&#1074;&#1089;&#1082;&#1086;&#1081;, &#1076;. 17 &#1101;&#1090;&#1072;&#1078; 2
      &#1087;&#1086;&#1084;. 1 &#1086;&#1092;&#1080;&#1089; 212&#1040;</span></p>
  <p className="c6"><span className="c2">6.4. &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;
      &#1074;&#1087;&#1088;&#1072;&#1074;&#1077; &#1086;&#1090;&#1082;&#1072;&#1079;&#1072;&#1090;&#1100;&#1089;&#1103;
      &#1086;&#1090; &#1080;&#1089;&#1087;&#1086;&#1083;&#1085;&#1077;&#1085;&#1080;&#1103;
      &#1086;&#1073;&#1103;&#1079;&#1072;&#1090;&#1077;&#1083;&#1100;&#1089;&#1090;&#1074; &#1087;&#1086;
      &#1085;&#1072;&#1089;&#1090;&#1086;&#1103;&#1097;&#1077;&#1084;&#1091;
      &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1091; &#1074;
      &#1086;&#1076;&#1085;&#1086;&#1089;&#1090;&#1086;&#1088;&#1086;&#1085;&#1085;&#1077;&#1084;
      &#1087;&#1086;&#1088;&#1103;&#1076;&#1082;&#1077;, &#1087;&#1080;&#1089;&#1100;&#1084;&#1077;&#1085;&#1085;&#1086;
      &#1091;&#1074;&#1077;&#1076;&#1086;&#1084;&#1080;&#1074; &#1086;&#1073; &#1101;&#1090;&#1086;&#1084;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088;&#1072; &#1085;&#1077;
      &#1084;&#1077;&#1085;&#1077;&#1077; &#1095;&#1077;&#1084; &#1079;&#1072; 7 (&#1057;&#1077;&#1084;&#1100;)
      &#1088;&#1072;&#1073;&#1086;&#1095;&#1080;&#1093; &#1076;&#1085;&#1077;&#1081;.</span></p>
  <p className="c6"><span className="c2">6.5. &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088;
      &#1074;&#1087;&#1088;&#1072;&#1074;&#1077; &#1086;&#1090;&#1082;&#1072;&#1079;&#1072;&#1090;&#1100;&#1089;&#1103;
      &#1086;&#1090; &#1080;&#1089;&#1087;&#1086;&#1083;&#1085;&#1077;&#1085;&#1080;&#1103;
      &#1086;&#1073;&#1103;&#1079;&#1072;&#1090;&#1077;&#1083;&#1100;&#1089;&#1090;&#1074; &#1087;&#1086;
      &#1085;&#1072;&#1089;&#1090;&#1086;&#1103;&#1097;&#1077;&#1084;&#1091;
      &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1091; &#1091;&#1074;&#1077;&#1076;&#1086;&#1084;&#1080;&#1074;
      &#1086;&#1073; &#1101;&#1090;&#1086;&#1084; &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1072;
      &#1087;&#1086; &#1072;&#1076;&#1088;&#1077;&#1089;&#1091;
      &#1101;&#1083;&#1077;&#1082;&#1090;&#1088;&#1086;&#1085;&#1085;&#1086;&#1081; &#1087;&#1086;&#1095;&#1090;&#1099;,
      &#1091;&#1082;&#1072;&#1079;&#1072;&#1085;&#1085;&#1086;&#1084;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1086;&#1084; &#1074;
      &#1092;&#1086;&#1088;&#1084;&#1077; &#1079;&#1072;&#1087;&#1086;&#1083;&#1085;&#1077;&#1085;&#1085;&#1086;&#1081;
      &#1085;&#1072; &#1057;&#1072;&#1081;&#1090;&#1077;, &#1085;&#1077;
      &#1087;&#1086;&#1079;&#1076;&#1085;&#1077;&#1077; &#1095;&#1077;&#1084; &#1079;&#1072; 7
      (&#1057;&#1077;&#1084;&#1100;) &#1088;&#1072;&#1073;&#1086;&#1095;&#1080;&#1093;
      &#1076;&#1085;&#1077;&#1081;.</span></p>
  <p className="c6"><span className="c2">6.6. &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088;
      &#1074;&#1087;&#1088;&#1072;&#1074;&#1077; &#1090;&#1072;&#1082;&#1078;&#1077;
      &#1086;&#1090;&#1082;&#1072;&#1079;&#1072;&#1090;&#1100;&#1089;&#1103; &#1086;&#1090;
      &#1080;&#1089;&#1087;&#1086;&#1083;&#1085;&#1077;&#1085;&#1080;&#1103;
      &#1086;&#1073;&#1103;&#1079;&#1072;&#1090;&#1077;&#1083;&#1100;&#1089;&#1090;&#1074; &#1087;&#1086;
      &#1085;&#1072;&#1089;&#1090;&#1086;&#1103;&#1097;&#1077;&#1084;&#1091;
      &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1091; &#1074; &#1089;&#1083;&#1091;&#1095;&#1072;&#1077;
      &#1085;&#1072;&#1088;&#1091;&#1096;&#1077;&#1085;&#1080;&#1103;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1086;&#1084;
      &#1091;&#1089;&#1083;&#1086;&#1074;&#1080;&#1081; &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1072;,
      &#1072; &#1090;&#1072;&#1082;&#1078;&#1077; &#1074; &#1089;&#1083;&#1091;&#1095;&#1072;&#1103;&#1093;:</span></p>
  <ul className="c10 lst-kix_rtdkxihlzq80-0 start">
    <li className="c5 li-bullet-10"><span
        className="c2">&#1085;&#1077;&#1089;&#1072;&#1085;&#1082;&#1094;&#1080;&#1086;&#1085;&#1080;&#1088;&#1086;&#1074;&#1072;&#1085;&#1085;&#1086;&#1077;
        &#1082;&#1086;&#1087;&#1080;&#1088;&#1086;&#1074;&#1072;&#1085;&#1080;&#1077;
        &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1086;&#1084;
        &#1101;&#1083;&#1077;&#1084;&#1077;&#1085;&#1090;&#1086;&#1074;
        &#1076;&#1080;&#1079;&#1072;&#1081;&#1085;&#1072;
        &#1055;&#1083;&#1072;&#1090;&#1092;&#1086;&#1088;&#1084;&#1099;;</span></li>
    <li className="c5 li-bullet-10"><span
        className="c2">&#1086;&#1089;&#1082;&#1086;&#1088;&#1073;&#1083;&#1077;&#1085;&#1080;&#1103;
        &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1086;&#1084; (&#1083;&#1080;&#1073;&#1086;
        &#1082;&#1077;&#1084;-&#1083;&#1080;&#1073;&#1086; &#1080;&#1079; &#1077;&#1075;&#1086;
        &#1087;&#1088;&#1077;&#1076;&#1089;&#1090;&#1072;&#1074;&#1080;&#1090;&#1077;&#1083;&#1077;&#1081; &#1080;
        &#1089;&#1086;&#1090;&#1088;&#1091;&#1076;&#1085;&#1080;&#1082;&#1086;&#1074;)
        &#1089;&#1086;&#1090;&#1088;&#1091;&#1076;&#1085;&#1080;&#1082;&#1086;&#1074;
        &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088;&#1072; &#1080;&#1083;&#1080;
        &#1087;&#1086;&#1076;&#1088;&#1103;&#1076;&#1095;&#1080;&#1082;&#1086;&#1074;
        &#1055;&#1083;&#1072;&#1090;&#1092;&#1086;&#1088;&#1084;&#1099;;</span></li>
    <li className="c5 li-bullet-11"><span className="c2">&#1085;&#1072;&#1088;&#1091;&#1096;&#1077;&#1085;&#1080;&#1103;
        &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1086;&#1084; &#1085;&#1086;&#1088;&#1084;
        &#1084;&#1086;&#1088;&#1072;&#1083;&#1080; &#1080;
        &#1085;&#1088;&#1072;&#1074;&#1089;&#1090;&#1074;&#1077;&#1085;&#1085;&#1086;&#1089;&#1090;&#1080;,
        &#1079;&#1072;&#1082;&#1086;&#1085;&#1086;&#1076;&#1072;&#1090;&#1077;&#1083;&#1100;&#1089;&#1090;&#1074;&#1072;
        &#1056;&#1060;.</span></li>
  </ul>
  <p className="c6"><span className="c2">6.7. &#1044;&#1077;&#1085;&#1077;&#1078;&#1085;&#1099;&#1077;
      &#1089;&#1088;&#1077;&#1076;&#1089;&#1090;&#1074;&#1072;,
      &#1091;&#1087;&#1083;&#1072;&#1095;&#1077;&#1085;&#1085;&#1099;&#1077;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1086;&#1084; &#1079;&#1072;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1102;, &#1074; &#1089;&#1083;&#1091;&#1095;&#1072;&#1077;
      &#1086;&#1090;&#1082;&#1072;&#1079;&#1072; &#1086;&#1090;
      &#1080;&#1089;&#1087;&#1086;&#1083;&#1085;&#1077;&#1085;&#1080;&#1103;
      &#1086;&#1073;&#1103;&#1079;&#1072;&#1090;&#1077;&#1083;&#1100;&#1089;&#1090;&#1074; &#1087;&#1086;
      &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1091;
      &#1082;&#1072;&#1082;&#1086;&#1081;-&#1083;&#1080;&#1073;&#1086;
      &#1089;&#1090;&#1086;&#1088;&#1086;&#1085;&#1086;&#1081;, &#1085;&#1077;
      &#1074;&#1086;&#1079;&#1074;&#1088;&#1072;&#1097;&#1072;&#1102;&#1090;&#1089;&#1103;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1091;.</span></p>
  <p className="c6"><span className="c2">6.8. &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088; &#1085;&#1077;
      &#1085;&#1077;&#1089;&#1077;&#1090;
      &#1086;&#1090;&#1074;&#1077;&#1090;&#1089;&#1090;&#1074;&#1077;&#1085;&#1085;&#1086;&#1089;&#1090;&#1080;
      &#1079;&#1072; &#1085;&#1072;&#1088;&#1091;&#1096;&#1077;&#1085;&#1080;&#1077;
      &#1091;&#1089;&#1083;&#1086;&#1074;&#1080;&#1081; &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1072; ,
      &#1077;&#1089;&#1083;&#1080; &#1090;&#1072;&#1082;&#1086;&#1077;
      &#1085;&#1072;&#1088;&#1091;&#1096;&#1077;&#1085;&#1080;&#1077; &#1074;&#1099;&#1079;&#1074;&#1072;&#1085;&#1086;
      &#1076;&#1077;&#1081;&#1089;&#1090;&#1074;&#1080;&#1077;&#1084;
      &#1086;&#1073;&#1089;&#1090;&#1086;&#1103;&#1090;&#1077;&#1083;&#1100;&#1089;&#1090;&#1074;
      &#1085;&#1077;&#1087;&#1088;&#1077;&#1086;&#1076;&#1086;&#1083;&#1080;&#1084;&#1086;&#1081;
      &#1089;&#1080;&#1083;&#1099; (&#1092;&#1086;&#1088;&#1089;-&#1084;&#1072;&#1078;&#1086;&#1088;),
      &#1074;&#1082;&#1083;&#1102;&#1095;&#1072;&#1103;: &#1076;&#1077;&#1081;&#1089;&#1090;&#1074;&#1080;&#1103;
      &#1086;&#1088;&#1075;&#1072;&#1085;&#1086;&#1074;
      &#1075;&#1086;&#1089;&#1091;&#1076;&#1072;&#1088;&#1089;&#1090;&#1074;&#1077;&#1085;&#1085;&#1086;&#1081; &#1080;
      &#1079;&#1072;&#1082;&#1086;&#1085;&#1086;&#1076;&#1072;&#1090;&#1077;&#1083;&#1100;&#1085;&#1086;&#1081;
      &#1074;&#1083;&#1072;&#1089;&#1090;&#1080;, &#1087;&#1086;&#1078;&#1072;&#1088;,
      &#1085;&#1072;&#1074;&#1086;&#1076;&#1085;&#1077;&#1085;&#1080;&#1077;,
      &#1079;&#1077;&#1084;&#1083;&#1077;&#1090;&#1088;&#1103;&#1089;&#1077;&#1085;&#1080;&#1077;,
      &#1076;&#1088;&#1091;&#1075;&#1080;&#1077; &#1089;&#1090;&#1080;&#1093;&#1080;&#1081;&#1085;&#1099;&#1077;
      &#1076;&#1077;&#1081;&#1089;&#1090;&#1074;&#1080;&#1103;,
      &#1086;&#1090;&#1089;&#1091;&#1090;&#1089;&#1090;&#1074;&#1080;&#1077;
      &#1101;&#1083;&#1077;&#1082;&#1090;&#1088;&#1086;&#1101;&#1085;&#1077;&#1088;&#1075;&#1080;&#1080;
      &#1080;/&#1080;&#1083;&#1080; &#1089;&#1073;&#1086;&#1080; &#1088;&#1072;&#1073;&#1086;&#1090;&#1099;
      &#1082;&#1086;&#1084;&#1087;&#1100;&#1102;&#1090;&#1077;&#1088;&#1085;&#1086;&#1081; &#1089;&#1077;&#1090;&#1080;
      &#1080; &#1076;&#1072;&#1090;&#1072;&#1094;&#1077;&#1085;&#1090;&#1088;&#1072;,
      &#1076;&#1077;&#1081;&#1089;&#1090;&#1074;&#1080;&#1103; &#1090;&#1088;&#1077;&#1090;&#1100;&#1080;&#1093;
      &#1083;&#1080;&#1094;
      &#1089;&#1087;&#1088;&#1086;&#1074;&#1086;&#1094;&#1080;&#1088;&#1086;&#1074;&#1072;&#1074;&#1096;&#1080;&#1077;
      &#1087;&#1086;&#1074;&#1099;&#1096;&#1077;&#1085;&#1085;&#1091;&#1102;
      &#1085;&#1072;&#1075;&#1088;&#1091;&#1079;&#1082;&#1091; &#1074; &#1089;&#1080;&#1089;&#1090;&#1077;&#1084;&#1077;
      &#1080;&#1083;&#1080; &#1086;&#1090;&#1082;&#1072;&#1079; &#1074;
      &#1086;&#1073;&#1089;&#1083;&#1091;&#1078;&#1080;&#1074;&#1072;&#1085;&#1080;&#1080;
      &#1089;&#1080;&#1089;&#1090;&#1077;&#1084;&#1099;,
      &#1079;&#1072;&#1073;&#1072;&#1089;&#1090;&#1086;&#1074;&#1082;&#1080;,
      &#1075;&#1088;&#1072;&#1078;&#1076;&#1072;&#1085;&#1089;&#1082;&#1080;&#1077;
      &#1074;&#1086;&#1083;&#1085;&#1077;&#1085;&#1080;&#1103;,
      &#1073;&#1077;&#1089;&#1087;&#1086;&#1088;&#1103;&#1076;&#1082;&#1080;, &#1083;&#1102;&#1073;&#1099;&#1077;
      &#1080;&#1085;&#1099;&#1077;
      &#1086;&#1073;&#1089;&#1090;&#1086;&#1103;&#1090;&#1077;&#1083;&#1100;&#1089;&#1090;&#1074;&#1072;, &#1085;&#1077;
      &#1086;&#1075;&#1088;&#1072;&#1085;&#1080;&#1095;&#1080;&#1074;&#1072;&#1103;&#1089;&#1100;
      &#1087;&#1077;&#1088;&#1077;&#1095;&#1080;&#1089;&#1083;&#1077;&#1085;&#1085;&#1099;&#1084;,
      &#1082;&#1086;&#1090;&#1086;&#1088;&#1099;&#1077; &#1084;&#1086;&#1075;&#1091;&#1090;
      &#1087;&#1086;&#1074;&#1083;&#1080;&#1103;&#1090;&#1100; &#1085;&#1072;
      &#1074;&#1099;&#1087;&#1086;&#1083;&#1085;&#1077;&#1085;&#1080;&#1077;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088;&#1086;&#1084;
      &#1091;&#1089;&#1083;&#1086;&#1074;&#1080;&#1081;
      &#1085;&#1072;&#1089;&#1090;&#1086;&#1103;&#1097;&#1077;&#1075;&#1086;
      &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1072; &#1080;
      &#1085;&#1077;&#1087;&#1086;&#1076;&#1082;&#1086;&#1085;&#1090;&#1088;&#1086;&#1083;&#1100;&#1085;&#1099;&#1077;
      &#1077;&#1084;&#1091;.</span></p>
  <p className="c6"><span className="c2">6.9. &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088; &#1085;&#1077;
      &#1085;&#1077;&#1089;&#1077;&#1090;
      &#1086;&#1090;&#1074;&#1077;&#1090;&#1089;&#1090;&#1074;&#1077;&#1085;&#1085;&#1086;&#1089;&#1090;&#1080;,
      &#1077;&#1089;&#1083;&#1080; &#1085;&#1077;&#1085;&#1072;&#1076;&#1083;&#1077;&#1078;&#1072;&#1097;&#1077;&#1077;
      &#1080;&#1089;&#1087;&#1086;&#1083;&#1085;&#1077;&#1085;&#1080;&#1077;
      &#1103;&#1074;&#1080;&#1083;&#1086;&#1089;&#1100;
      &#1089;&#1083;&#1077;&#1076;&#1089;&#1090;&#1074;&#1080;&#1077;&#1084;
      &#1085;&#1077;&#1076;&#1086;&#1089;&#1090;&#1086;&#1074;&#1077;&#1088;&#1085;&#1086;&#1089;&#1090;&#1080;,
      &#1085;&#1077;&#1076;&#1086;&#1089;&#1090;&#1072;&#1090;&#1086;&#1095;&#1085;&#1086;&#1089;&#1090;&#1080;
      &#1080;&#1083;&#1080;
      &#1085;&#1077;&#1089;&#1074;&#1086;&#1077;&#1074;&#1088;&#1077;&#1084;&#1077;&#1085;&#1085;&#1086;&#1089;&#1090;&#1080;
      &#1087;&#1088;&#1077;&#1076;&#1086;&#1089;&#1090;&#1072;&#1074;&#1083;&#1077;&#1085;&#1085;&#1099;&#1093;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1086;&#1084;
      &#1089;&#1074;&#1077;&#1076;&#1077;&#1085;&#1080;&#1081;, &#1072; &#1090;&#1072;&#1082;&#1078;&#1077;
      &#1074;&#1089;&#1083;&#1077;&#1076;&#1089;&#1090;&#1074;&#1080;&#1077; &#1076;&#1088;&#1091;&#1075;&#1080;&#1093;
      &#1085;&#1072;&#1088;&#1091;&#1096;&#1077;&#1085;&#1080;&#1081; &#1091;&#1089;&#1083;&#1086;&#1074;&#1080;&#1081;
      &#1085;&#1072;&#1089;&#1090;&#1086;&#1103;&#1097;&#1077;&#1075;&#1086;
      &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1072; &#1089;&#1086;
      &#1089;&#1090;&#1086;&#1088;&#1086;&#1085;&#1099;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1072;.</span></p>
  <p className="c6"><span className="c2">6.10. &#1056;&#1072;&#1079;&#1084;&#1077;&#1088;
      &#1086;&#1090;&#1074;&#1077;&#1090;&#1089;&#1090;&#1074;&#1077;&#1085;&#1085;&#1086;&#1089;&#1090;&#1080;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088;&#1072; &#1087;&#1077;&#1088;&#1077;&#1076;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1086;&#1084; &#1087;&#1086;
      &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1091;
      &#1086;&#1075;&#1088;&#1072;&#1085;&#1080;&#1095;&#1077;&#1085;
      &#1088;&#1072;&#1079;&#1084;&#1077;&#1088;&#1086;&#1084; &#1076;&#1077;&#1085;&#1077;&#1078;&#1085;&#1099;&#1093;
      &#1089;&#1088;&#1077;&#1076;&#1089;&#1090;&#1074;, &#1074;&#1085;&#1077;&#1089;&#1077;&#1085;&#1085;&#1099;&#1093;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1086;&#1084; &#1079;&#1072;
      &#1080;&#1089;&#1087;&#1086;&#1083;&#1100;&#1079;&#1086;&#1074;&#1072;&#1085;&#1080;&#1077;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1080;. &nbsp;</span></p>
  <p className="c6"><span className="c2">6.11. &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088; &#1085;&#1077;
      &#1085;&#1077;&#1089;&#1077;&#1090;
      &#1086;&#1090;&#1074;&#1077;&#1090;&#1089;&#1090;&#1074;&#1077;&#1085;&#1085;&#1086;&#1089;&#1090;&#1080;
      &#1079;&#1072; &#1085;&#1077;&#1089;&#1086;&#1086;&#1090;&#1074;&#1077;&#1090;&#1089;&#1090;&#1074;&#1080;&#1077;
      &#1087;&#1088;&#1077;&#1076;&#1086;&#1089;&#1090;&#1072;&#1074;&#1083;&#1077;&#1085;&#1085;&#1086;&#1081;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1080; &#1080;
      &#1055;&#1083;&#1072;&#1090;&#1092;&#1086;&#1088;&#1084;&#1099;
      &#1086;&#1078;&#1080;&#1076;&#1072;&#1085;&#1080;&#1103;&#1084;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1072; &#1080;/&#1080;&#1083;&#1080;
      &#1079;&#1072; &#1077;&#1075;&#1086;
      &#1089;&#1091;&#1073;&#1098;&#1077;&#1082;&#1090;&#1080;&#1074;&#1085;&#1091;&#1102;
      &#1086;&#1094;&#1077;&#1085;&#1082;&#1091;.
      &#1053;&#1077;&#1089;&#1086;&#1086;&#1090;&#1074;&#1077;&#1090;&#1089;&#1090;&#1074;&#1080;&#1077;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1080; &#1080;
      &#1055;&#1083;&#1072;&#1090;&#1092;&#1086;&#1088;&#1084;&#1099;
      &#1086;&#1078;&#1080;&#1076;&#1072;&#1085;&#1080;&#1103;&#1084;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1072; &#1080;/&#1080;&#1083;&#1080;
      &#1077;&#1075;&#1086; &#1089;&#1091;&#1073;&#1098;&#1077;&#1082;&#1090;&#1080;&#1074;&#1085;&#1086;&#1081;
      &#1086;&#1094;&#1077;&#1085;&#1082;&#1077; &#1085;&#1077; &#1103;&#1074;&#1083;&#1103;&#1102;&#1090;&#1089;&#1103;
      &#1086;&#1089;&#1085;&#1086;&#1074;&#1072;&#1085;&#1080;&#1103;&#1084;&#1080; &#1076;&#1083;&#1103;
      &#1086;&#1090;&#1082;&#1072;&#1079;&#1072; &#1086;&#1090;
      &#1080;&#1089;&#1087;&#1086;&#1083;&#1085;&#1077;&#1085;&#1080;&#1103;
      &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1072; &#1089;&#1086;
      &#1089;&#1090;&#1086;&#1088;&#1086;&#1085;&#1099;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1072; &#1080;
      &#1074;&#1086;&#1079;&#1074;&#1088;&#1072;&#1090;&#1072; &#1076;&#1077;&#1085;&#1077;&#1078;&#1085;&#1099;&#1093;
      &#1089;&#1088;&#1077;&#1076;&#1089;&#1090;&#1074;.</span></p>
  <p className="c6"><span className="c2">6.12. &#1042;&#1089;&#1077;
      &#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1080;&#1086;&#1085;&#1085;&#1099;&#1077;
      &#1084;&#1072;&#1090;&#1077;&#1088;&#1080;&#1072;&#1083;&#1099;,
      &#1087;&#1088;&#1077;&#1076;&#1086;&#1089;&#1090;&#1072;&#1074;&#1083;&#1103;&#1077;&#1084;&#1099;&#1077;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088;&#1086;&#1084;,
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1091; &#1074;
      &#1087;&#1088;&#1086;&#1094;&#1077;&#1089;&#1089;&#1077;
      &#1080;&#1089;&#1087;&#1086;&#1083;&#1100;&#1079;&#1086;&#1074;&#1072;&#1085;&#1080;&#1103;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1080; &#1103;&#1074;&#1083;&#1103;&#1102;&#1090;&#1089;&#1103;
      &#1088;&#1077;&#1079;&#1091;&#1083;&#1100;&#1090;&#1072;&#1090;&#1086;&#1084;
      &#1080;&#1085;&#1090;&#1077;&#1083;&#1083;&#1077;&#1082;&#1090;&#1091;&#1072;&#1083;&#1100;&#1085;&#1086;&#1081;
      &#1076;&#1077;&#1103;&#1090;&#1077;&#1083;&#1100;&#1085;&#1086;&#1089;&#1090;&#1080;,
      &#1080;&#1089;&#1082;&#1083;&#1102;&#1095;&#1080;&#1090;&#1077;&#1083;&#1100;&#1085;&#1086;&#1077;
      &#1072;&#1074;&#1090;&#1086;&#1088;&#1089;&#1082;&#1086;&#1077; &#1087;&#1088;&#1072;&#1074;&#1086;, &#1074;
      &#1090;&#1086;&#1084; &#1095;&#1080;&#1089;&#1083;&#1077; &#1089;&#1084;&#1077;&#1078;&#1085;&#1099;&#1077;
      &#1089; &#1072;&#1074;&#1090;&#1086;&#1088;&#1089;&#1082;&#1080;&#1084;
      &#1087;&#1088;&#1072;&#1074;&#1086;&#1084;, &#1087;&#1088;&#1072;&#1074;&#1072;
      &#1087;&#1088;&#1080;&#1085;&#1072;&#1076;&#1083;&#1077;&#1078;&#1072;&#1090;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088;&#1091;. &#1044;&#1072;&#1085;&#1085;&#1099;&#1077;
      &#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1080;&#1086;&#1085;&#1085;&#1099;&#1077;
      &#1084;&#1072;&#1090;&#1077;&#1088;&#1080;&#1072;&#1083;&#1099;
      &#1088;&#1072;&#1079;&#1088;&#1077;&#1096;&#1077;&#1085;&#1086;
      &#1080;&#1089;&#1087;&#1086;&#1083;&#1100;&#1079;&#1086;&#1074;&#1072;&#1090;&#1100;
      &#1090;&#1086;&#1083;&#1100;&#1082;&#1086; &#1089;&#1086; &#1089;&#1089;&#1099;&#1083;&#1082;&#1086;&#1081;
      &#1085;&#1072; &#1089;&#1072;&#1081;&#1090; &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088;&#1072;
      &#1089; &#1089;&#1086;&#1093;&#1088;&#1072;&#1085;&#1077;&#1085;&#1080;&#1077;&#1084; &#1074;&#1089;&#1077;&#1093;
      &#1083;&#1086;&#1075;&#1086;&#1090;&#1080;&#1087;&#1086;&#1074; &#1080;
      &#1094;&#1077;&#1083;&#1086;&#1089;&#1090;&#1085;&#1086;&#1089;&#1090;&#1080;
      &#1074;&#1080;&#1076;&#1077;&#1086;&#1084;&#1072;&#1090;&#1077;&#1088;&#1080;&#1072;&#1083;&#1086;&#1074; &#1080;
      &#1076;&#1088;.</span></p>
  <p className="c6"><span
      className="c2">6.13.&nbsp;&#1048;&#1089;&#1087;&#1086;&#1083;&#1100;&#1079;&#1086;&#1074;&#1072;&#1085;&#1080;&#1077;
      &#1088;&#1077;&#1079;&#1091;&#1083;&#1100;&#1090;&#1072;&#1090;&#1086;&#1074;
      &#1080;&#1085;&#1090;&#1077;&#1083;&#1083;&#1077;&#1082;&#1090;&#1091;&#1072;&#1083;&#1100;&#1085;&#1086;&#1081;
      &#1076;&#1077;&#1103;&#1090;&#1077;&#1083;&#1100;&#1085;&#1086;&#1089;&#1090;&#1080; &#1073;&#1077;&#1079;
      &#1087;&#1080;&#1089;&#1100;&#1084;&#1077;&#1085;&#1085;&#1086;&#1075;&#1086;
      &#1089;&#1086;&#1075;&#1083;&#1072;&#1089;&#1080;&#1103;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088;&#1072;, &#1079;&#1072;
      &#1087;&#1088;&#1077;&#1076;&#1077;&#1083;&#1072;&#1084;&#1080; &#1091;&#1089;&#1083;&#1086;&#1074;&#1080;&#1081;
      &#1085;&#1072;&#1089;&#1090;&#1086;&#1103;&#1097;&#1077;&#1075;&#1086;
      &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1072;, &#1103;&#1074;&#1083;&#1103;&#1077;&#1090;&#1089;&#1103;
      &#1085;&#1072;&#1088;&#1091;&#1096;&#1077;&#1085;&#1080;&#1077;&#1084;
      &#1080;&#1089;&#1082;&#1083;&#1102;&#1095;&#1080;&#1090;&#1077;&#1083;&#1100;&#1085;&#1099;&#1093;
      &#1087;&#1088;&#1072;&#1074; &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088;&#1072;,
      &#1095;&#1090;&#1086; &#1074;&#1083;&#1077;&#1095;&#1077;&#1090; &#1079;&#1072;
      &#1089;&#1086;&#1073;&#1086;&#1081; &#1075;&#1088;&#1072;&#1078;&#1076;&#1072;&#1085;&#1089;&#1082;&#1091;&#1102;,
      &#1072;&#1076;&#1084;&#1080;&#1085;&#1080;&#1089;&#1090;&#1088;&#1072;&#1090;&#1080;&#1074;&#1085;&#1091;&#1102;
      &#1080; &#1080;&#1085;&#1091;&#1102;
      &#1086;&#1090;&#1074;&#1077;&#1090;&#1089;&#1090;&#1074;&#1077;&#1085;&#1085;&#1086;&#1089;&#1090;&#1100; &#1074;
      &#1089;&#1086;&#1086;&#1090;&#1074;&#1077;&#1090;&#1089;&#1090;&#1074;&#1080;&#1080; &#1089;
      &#1076;&#1077;&#1081;&#1089;&#1090;&#1074;&#1091;&#1102;&#1097;&#1080;&#1084;
      &#1079;&#1072;&#1082;&#1086;&#1085;&#1086;&#1076;&#1072;&#1090;&#1077;&#1083;&#1100;&#1089;&#1090;&#1074;&#1086;&#1084;
      &#1056;&#1086;&#1089;&#1089;&#1080;&#1081;&#1089;&#1082;&#1086;&#1081;
      &#1060;&#1077;&#1076;&#1077;&#1088;&#1072;&#1094;&#1080;&#1080;.</span></p>
  <p className="c6"><span className="c2">6.14. &#1057;&#1090;&#1086;&#1088;&#1086;&#1085;&#1099;
      &#1091;&#1089;&#1090;&#1072;&#1085;&#1086;&#1074;&#1080;&#1083;&#1080;, &#1095;&#1090;&#1086; &#1079;&#1072;
      &#1082;&#1072;&#1078;&#1076;&#1099;&#1081; &#1089;&#1083;&#1091;&#1095;&#1072;&#1081;
      &#1085;&#1072;&#1088;&#1091;&#1096;&#1077;&#1085;&#1080;&#1103;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1086;&#1084;
      &#1080;&#1089;&#1082;&#1083;&#1102;&#1095;&#1080;&#1090;&#1077;&#1083;&#1100;&#1085;&#1099;&#1093;
      &#1087;&#1088;&#1072;&#1074; &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088;&#1072;,
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088; &#1080;&#1084;&#1077;&#1077;&#1090;
      &#1087;&#1088;&#1072;&#1074;&#1086; &#1074; &#1073;&#1077;&#1089;&#1089;&#1087;&#1086;&#1088;&#1085;&#1086;&#1084;
      &#1074;&#1085;&#1077;&#1089;&#1091;&#1076;&#1077;&#1073;&#1085;&#1086;&#1084;
      &#1087;&#1086;&#1088;&#1103;&#1076;&#1082;&#1077; &#1090;&#1088;&#1077;&#1073;&#1086;&#1074;&#1072;&#1090;&#1100;
      &#1089; &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1072;
      &#1091;&#1087;&#1083;&#1072;&#1090;&#1099; &#1096;&#1090;&#1088;&#1072;&#1092;&#1072; &#1074;
      &#1088;&#1072;&#1079;&#1084;&#1077;&#1088;&#1077; 1 000 000 (&#1054;&#1076;&#1080;&#1085;
      &#1084;&#1080;&#1083;&#1083;&#1080;&#1086;&#1085;) &#1088;&#1091;&#1073;&#1083;&#1077;&#1081; &#1085;&#1072;
      &#1086;&#1089;&#1085;&#1086;&#1074;&#1072;&#1085;&#1080;&#1080;
      &#1087;&#1080;&#1089;&#1100;&#1084;&#1077;&#1085;&#1085;&#1086;&#1075;&#1086;
      &#1090;&#1088;&#1077;&#1073;&#1086;&#1074;&#1072;&#1085;&#1080;&#1103;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088;&#1072;.</span></p>
  <p className="c6"><span className="c2">6.15. &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;
      &#1085;&#1077;&#1089;&#1077;&#1090;
      &#1086;&#1090;&#1074;&#1077;&#1090;&#1089;&#1090;&#1074;&#1077;&#1085;&#1085;&#1086;&#1089;&#1090;&#1100;
      &#1087;&#1077;&#1088;&#1077;&#1076; &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088;&#1086;&#1084;,
      &#1090;&#1088;&#1077;&#1090;&#1100;&#1080;&#1084;&#1080; &#1083;&#1080;&#1094;&#1072;&#1084;&#1080; &#1080;
      &#1056;&#1086;&#1089;&#1089;&#1080;&#1081;&#1089;&#1082;&#1086;&#1081;
      &#1060;&#1077;&#1076;&#1077;&#1088;&#1072;&#1094;&#1080;&#1077;&#1081; &#1079;&#1072;
      &#1082;&#1086;&#1085;&#1090;&#1077;&#1085;&#1090;,
      &#1088;&#1072;&#1079;&#1084;&#1077;&#1097;&#1077;&#1085;&#1085;&#1099;&#1081;
      &#1083;&#1080;&#1094;&#1072;&#1084;&#1080;,
      &#1087;&#1086;&#1083;&#1091;&#1095;&#1080;&#1074;&#1096;&#1080;&#1084;&#1080;
      &#1076;&#1086;&#1089;&#1090;&#1091;&#1087; &#1082; &#1055;&#1083;&#1072;&#1090;&#1092;&#1086;&#1088;&#1084;&#1077;
      &#1086;&#1090; &#1072;&#1082;&#1082;&#1072;&#1091;&#1085;&#1090;&#1072;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1072;
      &#1085;&#1077;&#1087;&#1086;&#1089;&#1088;&#1077;&#1076;&#1089;&#1090;&#1074;&#1077;&#1085;&#1085;&#1086;,
      &#1095;&#1077;&#1088;&#1077;&#1079;
      &#1087;&#1088;&#1077;&#1076;&#1089;&#1090;&#1072;&#1074;&#1080;&#1090;&#1077;&#1083;&#1077;&#1081;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1072; &#1080;&#1083;&#1080; &#1089;
      &#1087;&#1086;&#1084;&#1086;&#1097;&#1100;&#1102;
      &#1085;&#1072;&#1089;&#1090;&#1088;&#1086;&#1077;&#1085;&#1085;&#1099;&#1093;
      &#1072;&#1074;&#1090;&#1086;&#1084;&#1072;&#1090;&#1080;&#1079;&#1072;&#1094;&#1080;&#1081;, &#1080;
      &#1082;&#1086;&#1090;&#1086;&#1088;&#1099;&#1081; &#1085;&#1072;&#1088;&#1091;&#1096;&#1072;&#1077;&#1090;
      &#1087;&#1088;&#1072;&#1074;&#1080;&#1083;&#1072; &#1087;&#1086;&#1074;&#1077;&#1076;&#1077;&#1085;&#1080;&#1103;
      &#1074; &#1086;&#1073;&#1097;&#1077;&#1089;&#1090;&#1074;&#1077;, &#1085;&#1086;&#1088;&#1084;&#1099;
      &#1084;&#1086;&#1088;&#1072;&#1083;&#1080;, &#1087;&#1088;&#1072;&#1074;&#1072; &#1080;
      &#1080;&#1085;&#1090;&#1077;&#1088;&#1077;&#1089;&#1099; &#1090;&#1088;&#1077;&#1090;&#1100;&#1080;&#1093;
      &#1083;&#1080;&#1094; &#1080;
      &#1079;&#1072;&#1082;&#1086;&#1085;&#1086;&#1076;&#1072;&#1090;&#1077;&#1083;&#1100;&#1089;&#1090;&#1074;&#1086;
      &#1056;&#1060;.</span></p>
  <p className="c6"><span className="c2">6.16. &#1042;&#1089;&#1077; &#1089;&#1087;&#1086;&#1088;&#1099; &#1080;
      &#1088;&#1072;&#1079;&#1085;&#1086;&#1075;&#1083;&#1072;&#1089;&#1080;&#1103;,
      &#1074;&#1086;&#1079;&#1085;&#1080;&#1082;&#1072;&#1102;&#1097;&#1080;&#1077; &#1084;&#1077;&#1078;&#1076;&#1091;
      &#1057;&#1090;&#1086;&#1088;&#1086;&#1085;&#1072;&#1084;&#1080; &#1087;&#1086;
      &#1074;&#1086;&#1087;&#1088;&#1086;&#1089;&#1072;&#1084;
      &#1080;&#1089;&#1087;&#1086;&#1083;&#1085;&#1077;&#1085;&#1080;&#1103;
      &#1086;&#1073;&#1103;&#1079;&#1072;&#1090;&#1077;&#1083;&#1100;&#1089;&#1090;&#1074; &#1087;&#1086;
      &#1085;&#1072;&#1089;&#1090;&#1086;&#1103;&#1097;&#1077;&#1084;&#1091;
      &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1091;, &#1073;&#1091;&#1076;&#1091;&#1090;
      &#1088;&#1072;&#1079;&#1088;&#1077;&#1096;&#1072;&#1090;&#1100;&#1089;&#1103; &#1087;&#1091;&#1090;&#1077;&#1084;
      &#1087;&#1077;&#1088;&#1077;&#1075;&#1086;&#1074;&#1086;&#1088;&#1086;&#1074; &#1085;&#1072;
      &#1086;&#1089;&#1085;&#1086;&#1074;&#1077;
      &#1076;&#1077;&#1081;&#1089;&#1090;&#1074;&#1091;&#1102;&#1097;&#1077;&#1075;&#1086;
      &#1079;&#1072;&#1082;&#1086;&#1085;&#1086;&#1076;&#1072;&#1090;&#1077;&#1083;&#1100;&#1089;&#1090;&#1074;&#1072;
      &#1056;&#1086;&#1089;&#1089;&#1080;&#1081;&#1089;&#1082;&#1086;&#1081;
      &#1060;&#1077;&#1076;&#1077;&#1088;&#1072;&#1094;&#1080;&#1080; &#1080;
      &#1086;&#1073;&#1099;&#1095;&#1072;&#1077;&#1074; &#1076;&#1077;&#1083;&#1086;&#1074;&#1086;&#1075;&#1086;
      &#1086;&#1073;&#1086;&#1088;&#1086;&#1090;&#1072;.</span></p>
  <p className="c6"><span className="c2">6.17. &#1042; &#1089;&#1083;&#1091;&#1095;&#1072;&#1077;
      &#1085;&#1077;&#1091;&#1088;&#1077;&#1075;&#1091;&#1083;&#1080;&#1088;&#1086;&#1074;&#1072;&#1085;&#1080;&#1103;
      &#1074; &#1087;&#1088;&#1086;&#1094;&#1077;&#1089;&#1089;&#1077;
      &#1087;&#1077;&#1088;&#1077;&#1075;&#1086;&#1074;&#1086;&#1088;&#1086;&#1074;
      &#1089;&#1087;&#1086;&#1088;&#1085;&#1099;&#1093; &#1074;&#1086;&#1087;&#1088;&#1086;&#1089;&#1086;&#1074;
      &#1089;&#1087;&#1086;&#1088;&#1099; &#1088;&#1072;&#1079;&#1088;&#1077;&#1096;&#1072;&#1102;&#1090;&#1089;&#1103;
      &#1074; &#1089;&#1091;&#1076;&#1077; &#1087;&#1086;
      &#1084;&#1077;&#1089;&#1090;&#1086;&#1085;&#1072;&#1093;&#1086;&#1078;&#1076;&#1077;&#1085;&#1080;&#1102;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088;&#1072;.</span></p>
  <p className="c6"><span className="c2">6.18. &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;
      &#1074;&#1089;&#1090;&#1091;&#1087;&#1072;&#1077;&#1090; &#1074; &#1089;&#1080;&#1083;&#1091; &#1089;
      &#1084;&#1086;&#1084;&#1077;&#1085;&#1090;&#1072; &#1040;&#1082;&#1094;&#1077;&#1087;&#1090;&#1072;
      &#1054;&#1092;&#1077;&#1088;&#1090;&#1099; &#1080; &#1076;&#1077;&#1081;&#1089;&#1090;&#1074;&#1091;&#1077;&#1090;
      &#1076;&#1086; &#1087;&#1088;&#1077;&#1082;&#1088;&#1072;&#1097;&#1077;&#1085;&#1080;&#1103;
      &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1072;. &#1055;&#1086;&#1076;
      &#1087;&#1088;&#1077;&#1082;&#1088;&#1072;&#1097;&#1077;&#1085;&#1080;&#1077;&#1084;
      &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1072; &#1090;&#1072;&#1082;&#1078;&#1077;
      &#1087;&#1086;&#1085;&#1080;&#1084;&#1072;&#1077;&#1090;&#1089;&#1103;
      &#1085;&#1077;&#1080;&#1089;&#1087;&#1086;&#1083;&#1100;&#1079;&#1086;&#1074;&#1072;&#1085;&#1080;&#1077;
      &#1055;&#1083;&#1072;&#1090;&#1092;&#1086;&#1088;&#1084;&#1099;, &#1072; &#1090;&#1072;&#1082;&#1078;&#1077;
      &#1086;&#1090;&#1089;&#1091;&#1090;&#1089;&#1090;&#1074;&#1080;&#1077;
      &#1072;&#1082;&#1090;&#1080;&#1074;&#1085;&#1086;&#1089;&#1090;&#1080; &#1085;&#1072;
      &#1055;&#1083;&#1072;&#1090;&#1092;&#1086;&#1088;&#1084;&#1077; &#1074;
      &#1090;&#1077;&#1095;&#1077;&#1085;&#1080;&#1077; 12 &#1084;&#1077;&#1089;&#1103;&#1094;&#1077;&#1074;. </span>
  </p>
  <p className="c6"><span className="c2">6.19. &#1040;&#1082;&#1094;&#1077;&#1087;&#1090;&#1091;&#1103;
      &#1091;&#1089;&#1083;&#1086;&#1074;&#1080;&#1103; &#1054;&#1092;&#1077;&#1088;&#1090;&#1099;,
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090; &#1076;&#1072;&#1077;&#1090;
      &#1089;&#1086;&#1075;&#1083;&#1072;&#1089;&#1080;&#1077; &#1074;
      &#1089;&#1086;&#1086;&#1090;&#1074;&#1077;&#1090;&#1089;&#1090;&#1074;&#1080;&#1080; &#1089;
      &#1076;&#1077;&#1081;&#1089;&#1090;&#1074;&#1091;&#1102;&#1097;&#1080;&#1084;
      &#1079;&#1072;&#1082;&#1086;&#1085;&#1086;&#1076;&#1072;&#1090;&#1077;&#1083;&#1100;&#1089;&#1090;&#1074;&#1086;&#1084;
      &#1085;&#1072; &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1082;&#1091;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088;&#1086;&#1084;
      &#1087;&#1088;&#1077;&#1076;&#1086;&#1089;&#1090;&#1072;&#1074;&#1083;&#1077;&#1085;&#1085;&#1086;&#1081;
      &#1080;&#1084; &#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1080;&#1080; &#1080;
      (&#1080;&#1083;&#1080;) &#1077;&#1075;&#1086;
      &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1100;&#1085;&#1099;&#1093;
      &#1076;&#1072;&#1085;&#1085;&#1099;&#1093;, &#1072; &#1090;&#1072;&#1082;&#1078;&#1077;
      &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1100;&#1085;&#1099;&#1093;
      &#1076;&#1072;&#1085;&#1085;&#1099;&#1093; &#1090;&#1088;&#1077;&#1090;&#1100;&#1080;&#1093;
      &#1083;&#1080;&#1094;, &#1089;&#1086;&#1074;&#1077;&#1088;&#1096;&#1072;&#1077;&#1084;&#1091;&#1102; &#1089;
      &#1080;&#1089;&#1087;&#1086;&#1083;&#1100;&#1079;&#1086;&#1074;&#1072;&#1085;&#1080;&#1077;&#1084;
      &#1089;&#1088;&#1077;&#1076;&#1089;&#1090;&#1074;
      &#1072;&#1074;&#1090;&#1086;&#1084;&#1072;&#1090;&#1080;&#1079;&#1072;&#1094;&#1080;&#1080;, &#1080;
      &#1073;&#1077;&#1079; &#1080;&#1089;&#1087;&#1086;&#1083;&#1100;&#1079;&#1086;&#1074;&#1072;&#1085;&#1080;&#1103;
      &#1089;&#1088;&#1077;&#1076;&#1089;&#1090;&#1074;
      &#1072;&#1074;&#1090;&#1086;&#1084;&#1072;&#1090;&#1080;&#1079;&#1072;&#1094;&#1080;&#1080;,
      &#1074;&#1082;&#1083;&#1102;&#1095;&#1072;&#1103; &#1089;&#1073;&#1086;&#1088;,
      &#1079;&#1072;&#1087;&#1080;&#1089;&#1100;,
      &#1089;&#1080;&#1089;&#1090;&#1077;&#1084;&#1072;&#1090;&#1080;&#1079;&#1072;&#1094;&#1080;&#1102;,
      &#1085;&#1072;&#1082;&#1086;&#1087;&#1083;&#1077;&#1085;&#1080;&#1077;,
      &#1093;&#1088;&#1072;&#1085;&#1077;&#1085;&#1080;&#1077;,
      &#1091;&#1090;&#1086;&#1095;&#1085;&#1077;&#1085;&#1080;&#1077;
      (&#1086;&#1073;&#1085;&#1086;&#1074;&#1083;&#1077;&#1085;&#1080;&#1077;,
      &#1080;&#1079;&#1084;&#1077;&#1085;&#1077;&#1085;&#1080;&#1077;),
      &#1080;&#1079;&#1074;&#1083;&#1077;&#1095;&#1077;&#1085;&#1080;&#1077;,
      &#1080;&#1089;&#1087;&#1086;&#1083;&#1100;&#1079;&#1086;&#1074;&#1072;&#1085;&#1080;&#1077;,
      &#1087;&#1077;&#1088;&#1077;&#1076;&#1072;&#1095;&#1091;,
      &#1087;&#1088;&#1077;&#1076;&#1086;&#1089;&#1090;&#1072;&#1074;&#1083;&#1077;&#1085;&#1080;&#1077;,
      &#1086;&#1073;&#1077;&#1079;&#1083;&#1080;&#1095;&#1080;&#1074;&#1072;&#1085;&#1080;&#1077;,
      &#1073;&#1083;&#1086;&#1082;&#1080;&#1088;&#1086;&#1074;&#1072;&#1085;&#1080;&#1077;,
      &#1091;&#1076;&#1072;&#1083;&#1077;&#1085;&#1080;&#1077;,
      &#1091;&#1085;&#1080;&#1095;&#1090;&#1086;&#1078;&#1077;&#1085;&#1080;&#1077;
      &#1076;&#1072;&#1085;&#1085;&#1099;&#1093; &#1089; &#1094;&#1077;&#1083;&#1100;&#1102;
      &#1074;&#1099;&#1087;&#1086;&#1083;&#1085;&#1077;&#1085;&#1080;&#1103;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088;&#1086;&#1084; &#1089;&#1074;&#1086;&#1080;&#1093;
      &#1086;&#1073;&#1103;&#1079;&#1072;&#1090;&#1077;&#1083;&#1100;&#1089;&#1090;&#1074;,
      &#1089;&#1074;&#1103;&#1079;&#1072;&#1085;&#1085;&#1099;&#1093; &#1089;
      &#1087;&#1088;&#1077;&#1076;&#1086;&#1089;&#1090;&#1072;&#1074;&#1083;&#1077;&#1085;&#1080;&#1077;&#1084;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1080;, &#1080;&#1085;&#1099;&#1093;
      &#1086;&#1073;&#1103;&#1079;&#1072;&#1090;&#1077;&#1083;&#1100;&#1089;&#1090;&#1074;,
      &#1087;&#1088;&#1077;&#1076;&#1091;&#1089;&#1084;&#1086;&#1090;&#1088;&#1077;&#1085;&#1085;&#1099;&#1093;
      &#1085;&#1072;&#1089;&#1090;&#1086;&#1103;&#1097;&#1080;&#1084;
      &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1086;&#1084;, &#1072; &#1090;&#1072;&#1082;&#1078;&#1077;
      &#1089; &#1094;&#1077;&#1083;&#1100;&#1102; &#1074;&#1099;&#1087;&#1086;&#1083;&#1085;&#1077;&#1085;&#1080;&#1103;
      &#1090;&#1088;&#1077;&#1073;&#1086;&#1074;&#1072;&#1085;&#1080;&#1081;
      &#1085;&#1086;&#1088;&#1084;&#1072;&#1090;&#1080;&#1074;&#1085;&#1099;&#1093; &#1072;&#1082;&#1090;&#1086;&#1074;
      &#1087;&#1086;
      &#1087;&#1088;&#1086;&#1090;&#1080;&#1074;&#1086;&#1076;&#1077;&#1081;&#1089;&#1090;&#1074;&#1080;&#1102;
      &#1083;&#1077;&#1075;&#1072;&#1083;&#1080;&#1079;&#1072;&#1094;&#1080;&#1080;
      &#1076;&#1077;&#1085;&#1077;&#1078;&#1085;&#1099;&#1093; &#1089;&#1088;&#1077;&#1076;&#1089;&#1090;&#1074;,
      &#1087;&#1086;&#1083;&#1091;&#1095;&#1077;&#1085;&#1085;&#1099;&#1093;
      &#1087;&#1088;&#1077;&#1089;&#1090;&#1091;&#1087;&#1085;&#1099;&#1084; &#1087;&#1091;&#1090;&#1077;&#1084;.
      &#1057;&#1088;&#1086;&#1082;
      &#1080;&#1089;&#1087;&#1086;&#1083;&#1100;&#1079;&#1086;&#1074;&#1072;&#1085;&#1080;&#1103;
      &#1087;&#1088;&#1077;&#1076;&#1086;&#1089;&#1090;&#1072;&#1074;&#1083;&#1077;&#1085;&#1085;&#1099;&#1093;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1086;&#1084;
      &#1076;&#1072;&#1085;&#1085;&#1099;&#1093; &ndash;
      &#1073;&#1077;&#1089;&#1089;&#1088;&#1086;&#1095;&#1085;&#1086;.
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090; &#1090;&#1072;&#1082;&#1078;&#1077;
      &#1076;&#1072;&#1077;&#1090; &#1089;&#1074;&#1086;&#1077; &#1089;&#1086;&#1075;&#1083;&#1072;&#1089;&#1080;&#1077;
      &#1085;&#1072; &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1082;&#1091; &#1080;
      &#1080;&#1089;&#1087;&#1086;&#1083;&#1100;&#1079;&#1086;&#1074;&#1072;&#1085;&#1080;&#1077;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088;&#1086;&#1084;
      &#1087;&#1088;&#1077;&#1076;&#1086;&#1089;&#1090;&#1072;&#1074;&#1083;&#1077;&#1085;&#1085;&#1086;&#1081;
      &#1080;&#1084; &#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1080;&#1080; &#1080;
      (&#1080;&#1083;&#1080;) &#1077;&#1075;&#1086;
      &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1100;&#1085;&#1099;&#1093;
      &#1076;&#1072;&#1085;&#1085;&#1099;&#1093; &#1089; &#1094;&#1077;&#1083;&#1100;&#1102;
      &#1086;&#1089;&#1091;&#1097;&#1077;&#1089;&#1090;&#1074;&#1083;&#1077;&#1085;&#1080;&#1103; &#1087;&#1086;
      &#1091;&#1082;&#1072;&#1079;&#1072;&#1085;&#1085;&#1086;&#1084;&#1091;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1086;&#1084;
      &#1082;&#1086;&#1085;&#1090;&#1072;&#1082;&#1090;&#1085;&#1086;&#1084;&#1091;
      &#1090;&#1077;&#1083;&#1077;&#1092;&#1086;&#1085;&#1091; &#1080; (&#1080;&#1083;&#1080;)
      &#1082;&#1086;&#1085;&#1090;&#1072;&#1082;&#1090;&#1085;&#1086;&#1084;&#1091;
      &#1101;&#1083;&#1077;&#1082;&#1090;&#1088;&#1086;&#1085;&#1085;&#1086;&#1084;&#1091;
      &#1072;&#1076;&#1088;&#1077;&#1089;&#1091;
      &#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1080;&#1086;&#1085;&#1085;&#1086;&#1081;
      &#1088;&#1072;&#1089;&#1089;&#1099;&#1083;&#1082;&#1080; (&#1086;
      &#1074;&#1086;&#1079;&#1084;&#1086;&#1078;&#1085;&#1086;&#1089;&#1090;&#1103;&#1093;
      &#1087;&#1083;&#1072;&#1090;&#1092;&#1086;&#1088;&#1084;&#1099;, &#1072;&#1082;&#1094;&#1080;&#1103;&#1093;,
      &#1076;&#1086;&#1087;&#1086;&#1083;&#1085;&#1080;&#1090;&#1077;&#1083;&#1100;&#1085;&#1099;&#1093;
      &#1091;&#1089;&#1083;&#1091;&#1075;&#1072;&#1093; &#1080;&#1083;&#1080;
      &#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1080;&#1080;
      &#1082;&#1072;&#1089;&#1072;&#1102;&#1097;&#1077;&#1081;&#1089;&#1103;
      &#1085;&#1072;&#1089;&#1090;&#1086;&#1103;&#1097;&#1077;&#1075;&#1086;
      &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1072; &#1080; &#1088;&#1072;&#1073;&#1086;&#1090;&#1099;
      &#1089; &#1055;&#1083;&#1072;&#1090;&#1092;&#1086;&#1088;&#1084;&#1086;&#1081;)
      &#1073;&#1077;&#1089;&#1089;&#1088;&#1086;&#1095;&#1085;&#1086; &#1076;&#1086;
      &#1087;&#1086;&#1083;&#1091;&#1095;&#1077;&#1085;&#1080;&#1103;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088;&#1086;&#1084;
      &#1101;&#1083;&#1077;&#1082;&#1090;&#1088;&#1086;&#1085;&#1085;&#1086;&#1075;&#1086;
      &#1091;&#1074;&#1077;&#1076;&#1086;&#1084;&#1083;&#1077;&#1085;&#1080;&#1103; &#1086;&#1073;
      &#1086;&#1090;&#1082;&#1072;&#1079;&#1077; &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1072;
      &#1086;&#1090; &#1087;&#1086;&#1083;&#1091;&#1095;&#1077;&#1085;&#1080;&#1103;
      &#1088;&#1072;&#1089;&#1089;&#1099;&#1083;&#1086;&#1082; &#1087;&#1091;&#1090;&#1077;&#1084;
      &#1086;&#1090;&#1082;&#1083;&#1102;&#1095;&#1077;&#1085;&#1080;&#1103;
      &#1088;&#1072;&#1089;&#1089;&#1099;&#1083;&#1086;&#1082; &#1074; &#1089;&#1074;&#1086;&#1077;&#1084;
      &#1072;&#1082;&#1082;&#1072;&#1091;&#1085;&#1090;&#1077; &#1085;&#1072;
      &#1055;&#1083;&#1072;&#1090;&#1092;&#1086;&#1088;&#1084;&#1077;.
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090; &#1090;&#1072;&#1082;&#1078;&#1077;
      &#1076;&#1072;&#1077;&#1090; &#1089;&#1074;&#1086;&#1077; &#1089;&#1086;&#1075;&#1083;&#1072;&#1089;&#1080;&#1077;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088;&#1091; &#1085;&#1072;
      &#1087;&#1077;&#1088;&#1077;&#1076;&#1072;&#1095;&#1091;, &#1074; &#1094;&#1077;&#1083;&#1103;&#1093;
      &#1086;&#1089;&#1091;&#1097;&#1077;&#1089;&#1090;&#1074;&#1083;&#1077;&#1085;&#1080;&#1103;
      &#1076;&#1077;&#1081;&#1089;&#1090;&#1074;&#1080;&#1081;,
      &#1087;&#1088;&#1077;&#1076;&#1091;&#1089;&#1084;&#1086;&#1090;&#1088;&#1077;&#1085;&#1085;&#1099;&#1093;
      &#1085;&#1072;&#1089;&#1090;&#1086;&#1103;&#1097;&#1080;&#1084; &#1087;&#1091;&#1085;&#1082;&#1090;&#1086;&#1084;,
      &#1091;&#1082;&#1072;&#1079;&#1072;&#1085;&#1085;&#1086;&#1081;
      &#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1080;&#1080;
      &#1090;&#1088;&#1077;&#1090;&#1100;&#1080;&#1084; &#1083;&#1080;&#1094;&#1072;&#1084;.</span></p>
  <p className="c6"><span className="c2">6.20. &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;
      &#1103;&#1074;&#1083;&#1103;&#1077;&#1090;&#1089;&#1103;
      &#1072;&#1076;&#1084;&#1080;&#1085;&#1080;&#1089;&#1090;&#1088;&#1072;&#1090;&#1086;&#1088;&#1086;&#1084;
      &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1100;&#1085;&#1099;&#1093;
      &#1076;&#1072;&#1085;&#1085;&#1099;&#1093; &#1091;&#1095;&#1072;&#1089;&#1090;&#1085;&#1080;&#1082;&#1086;&#1074;
      &#1074;&#1077;&#1073;&#1080;&#1085;&#1072;&#1088;&#1086;&#1074;,
      &#1091;&#1095;&#1077;&#1085;&#1080;&#1082;&#1086;&#1074; &#1082;&#1091;&#1088;&#1089;&#1086;&#1074; &#1080;
      &#1090;&#1077;&#1089;&#1090;&#1086;&#1074;,
      &#1087;&#1086;&#1089;&#1077;&#1090;&#1080;&#1090;&#1077;&#1083;&#1077;&#1081;
      &#1084;&#1072;&#1089;&#1090;&#1077;&#1088;-&#1082;&#1083;&#1072;&#1089;&#1089;&#1086;&#1074;,
      &#1082;&#1083;&#1080;&#1077;&#1085;&#1090;&#1086;&#1074; &#1082;&#1072;&#1089;&#1089;&#1099; ,
      &#1087;&#1086;&#1083;&#1091;&#1095;&#1077;&#1085;&#1085;&#1099;&#1093; &#1089;
      &#1087;&#1086;&#1084;&#1086;&#1097;&#1100;&#1102; &#1083;&#1102;&#1073;&#1099;&#1093;
      &#1092;&#1091;&#1085;&#1082;&#1094;&#1080;&#1081; &#1055;&#1083;&#1072;&#1090;&#1092;&#1086;&#1088;&#1084;&#1099;,
      &#1080; &#1085;&#1077;&#1089;&#1077;&#1090; &#1087;&#1086;&#1083;&#1085;&#1091;&#1102;
      &#1086;&#1090;&#1074;&#1077;&#1090;&#1089;&#1090;&#1074;&#1077;&#1085;&#1085;&#1086;&#1089;&#1090;&#1100;
      &#1079;&#1072; &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1082;&#1091; &#1080;
      &#1093;&#1088;&#1072;&#1085;&#1077;&#1085;&#1080;&#1103;
      &#1086;&#1073;&#1086;&#1079;&#1085;&#1072;&#1095;&#1077;&#1085;&#1085;&#1099;&#1093;
      &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1100;&#1085;&#1099;&#1093;
      &#1076;&#1072;&#1085;&#1085;&#1099;&#1093;.</span></p>
  <p className="c6"><span className="c2">6.21.
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&nbsp;&#1085;&#1077;&#1089;&#1077;&#1090;
      &#1086;&#1090;&#1074;&#1077;&#1090;&#1089;&#1090;&#1074;&#1077;&#1085;&#1085;&#1086;&#1089;&#1090;&#1100;&nbsp;&#1079;&#1072;
      &#1088;&#1072;&#1089;&#1087;&#1088;&#1086;&#1089;&#1090;&#1088;&#1072;&#1085;&#1077;&#1085;&#1080;&#1077;
      &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1100;&#1085;&#1099;&#1093;
      &#1076;&#1072;&#1085;&#1085;&#1099;&#1093; &#1089;&#1074;&#1086;&#1080;&#1093;
      &#1079;&#1088;&#1080;&#1090;&#1077;&#1083;&#1077;&#1081;,
      &#1091;&#1095;&#1077;&#1085;&#1080;&#1082;&#1086;&#1074;,
      &#1082;&#1083;&#1080;&#1077;&#1085;&#1090;&#1086;&#1074;, &#1082;&#1086;&#1090;&#1086;&#1088;&#1099;&#1077;
      &#1074;&#1086;&#1089;&#1087;&#1086;&#1083;&#1100;&#1079;&#1086;&#1074;&#1072;&#1083;&#1080;&#1089;&#1100;
      &#1091;&#1089;&#1083;&#1091;&#1075;&#1072;&#1084;&#1080;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1072; &#1095;&#1077;&#1088;&#1077;&#1079;
      &#1092;&#1091;&#1085;&#1082;&#1094;&#1080;&#1086;&#1085;&#1072;&#1083;
      &#1055;&#1083;&#1072;&#1090;&#1092;&#1086;&#1088;&#1084;&#1099;, &#1090;&#1088;&#1077;&#1090;&#1100;&#1080;&#1084;
      &#1083;&#1080;&#1094;&#1072;&#1084; &#1073;&#1077;&#1079; &#1080;&#1093;
      &#1087;&#1088;&#1103;&#1084;&#1086;&#1075;&#1086; &#1089;&#1086;&#1075;&#1083;&#1072;&#1089;&#1080;&#1103;,
      &#1077;&#1089;&#1083;&#1080; &#1080;&#1085;&#1086;&#1077; &#1085;&#1077;
      &#1087;&#1088;&#1077;&#1076;&#1091;&#1089;&#1084;&#1086;&#1090;&#1088;&#1077;&#1085;&#1086;
      &#1087;&#1086;&#1083;&#1080;&#1090;&#1080;&#1082;&#1086;&#1081;
      &#1082;&#1086;&#1085;&#1092;&#1080;&#1076;&#1077;&#1085;&#1094;&#1080;&#1072;&#1083;&#1100;&#1085;&#1086;&#1089;&#1090;&#1080;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;&#1072;.</span></p>
  <p className="c6"><span className="c2">6.22. &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1090;
      &#1086;&#1073;&#1103;&#1079;&#1072;&#1085;
      &#1087;&#1088;&#1077;&#1076;&#1091;&#1089;&#1084;&#1086;&#1090;&#1088;&#1077;&#1090;&#1100; &#1074;
      &#1089;&#1086;&#1075;&#1083;&#1072;&#1096;&#1077;&#1085;&#1080;&#1103;&#1093; &#1089;&#1086;
      &#1089;&#1074;&#1086;&#1080;&#1084;&#1080;
      &#1087;&#1086;&#1083;&#1100;&#1079;&#1086;&#1074;&#1072;&#1090;&#1077;&#1083;&#1103;&#1084;&#1080;
      &#1074;&#1086;&#1079;&#1084;&#1086;&#1078;&#1085;&#1086;&#1089;&#1090;&#1100;
      &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1082;&#1080;
      &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1100;&#1085;&#1099;&#1093;
      &#1076;&#1072;&#1085;&#1085;&#1099;&#1093;
      &#1087;&#1086;&#1083;&#1100;&#1079;&#1086;&#1074;&#1072;&#1090;&#1077;&#1083;&#1077;&#1081;, &#1080;&#1093;
      &#1087;&#1088;&#1077;&#1076;&#1089;&#1090;&#1072;&#1074;&#1080;&#1090;&#1077;&#1083;&#1077;&#1081;,
      &#1089;&#1086;&#1090;&#1088;&#1091;&#1076;&#1085;&#1080;&#1082;&#1086;&#1074; &#1080; &#1076;&#1088;.
      &#1083;&#1080;&#1094; &#1087;&#1088;&#1072;&#1074;&#1086; &#1085;&#1072; &#1089;&#1073;&#1086;&#1088;,
      &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1082;&#1091;
      &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1100;&#1085;&#1099;&#1093;
      &#1076;&#1072;&#1085;&#1085;&#1099;&#1093; &#1090;&#1088;&#1077;&#1090;&#1100;&#1080;&#1084;&#1080;
      &#1083;&#1080;&#1094;&#1072;&#1084;&#1080; &#1080; &#1076;&#1088;&#1091;&#1075;&#1080;&#1077;
      &#1087;&#1088;&#1072;&#1074;&#1072; &#1089;&#1074;&#1103;&#1079;&#1072;&#1085;&#1085;&#1099;&#1077; &#1089;
      &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1100;&#1085;&#1099;&#1084;&#1080;
      &#1076;&#1072;&#1085;&#1085;&#1099;&#1084;&#1080;.</span></p>
  <p className="c6"><span className="c2">6.23. &#1042;&#1086; &#1074;&#1089;&#1077;&#1084;
      &#1086;&#1089;&#1090;&#1072;&#1083;&#1100;&#1085;&#1086;&#1084;, &#1095;&#1090;&#1086; &#1085;&#1077;
      &#1087;&#1088;&#1077;&#1076;&#1091;&#1089;&#1084;&#1086;&#1090;&#1088;&#1077;&#1085;&#1086;
      &#1085;&#1072;&#1089;&#1090;&#1086;&#1103;&#1097;&#1080;&#1084;
      &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1086;&#1084;, &#1089;&#1090;&#1086;&#1088;&#1086;&#1085;&#1099;
      &#1088;&#1091;&#1082;&#1086;&#1074;&#1086;&#1076;&#1089;&#1090;&#1074;&#1091;&#1102;&#1090;&#1089;&#1103;
      &#1076;&#1077;&#1081;&#1089;&#1090;&#1074;&#1091;&#1102;&#1097;&#1080;&#1084;
      &#1079;&#1072;&#1082;&#1086;&#1085;&#1086;&#1076;&#1072;&#1090;&#1077;&#1083;&#1100;&#1089;&#1090;&#1074;&#1086;&#1084;
      &#1056;&#1086;&#1089;&#1089;&#1080;&#1081;&#1089;&#1082;&#1086;&#1081;
      &#1060;&#1077;&#1076;&#1077;&#1088;&#1072;&#1094;&#1080;&#1080;.</span></p>
  <p className="c6 c7"><span className="c2"></span></p>
  <h2 className="c3" id="h.uplle6rlzm0w"><span className="c16">7.
      &#1056;&#1077;&#1082;&#1074;&#1080;&#1079;&#1080;&#1090;&#1099;
      &#1051;&#1080;&#1094;&#1077;&#1085;&#1079;&#1080;&#1072;&#1088;&#1072;</span></h2>
  <p className="c15"><span className="c2">&#1054;&#1054;&#1054; &ldquo;&#1048;&#1085;&#1090;&#1077;&#1088;&#1085;&#1077;&#1090;
      &#1091;&#1085;&#1080;&#1074;&#1077;&#1088;&#1089;&#1080;&#1090;&#1077;&#1090;&rdquo;<br/>&#1070;&#1088;&#1080;&#1076;&#1080;&#1095;&#1077;&#1089;&#1082;&#1080;&#1081;
      &#1072;&#1076;&#1088;&#1077;&#1089;: 115191, &#1075;.
      &#1052;&#1086;&#1089;&#1082;&#1074;&#1072;,<br/>&#1087;&#1077;&#1088;.
      &#1044;&#1091;&#1093;&#1086;&#1074;&#1089;&#1082;&#1086;&#1081;, &#1076;. 17 &#1101;&#1090;&#1072;&#1078; 2
      &#1087;&#1086;&#1084;. 1 &#1086;&#1092;&#1080;&#1089; 212 &#1040;<br/>&#1048;&#1053;&#1053;/&#1050;&#1055;&#1055;
      7726407960/772601001<br/>&#1055;&#1086;&#1083;&#1085;&#1086;&#1077;
      &#1085;&#1072;&#1080;&#1084;&#1077;&#1085;&#1086;&#1074;&#1072;&#1085;&#1080;&#1077;
      &#1073;&#1072;&#1085;&#1082;&#1072;: &#1040;&#1054;
      &ldquo;&#1058;&#1048;&#1053;&#1068;&#1050;&#1054;&#1060;&#1060;
      &#1041;&#1040;&#1053;&#1050;&rdquo;<br/>&#1056;&#1072;&#1089;&#1095;&#1077;&#1090;&#1085;&#1099;&#1081;
      &#1089;&#1095;&#1077;&#1090;: 40702810710000173566<br/>&#1041;&#1048;&#1050;:
      044525974<br/>&#1050;&#1086;&#1088;&#1088;&#1077;&#1089;&#1087;&#1086;&#1085;&#1076;&#1077;&#1085;&#1090;&#1089;&#1082;&#1080;&#1081;
      &#1089;&#1095;&#1077;&#1090;: 30101810145250000974</span></p>
  <p className="c15"><span className="c2">&#1058;&#1077;&#1083;&#1077;&#1092;&#1086;&#1085;&#1099;:<br/>+7 495 108 5092<br/>+7 999
      444 3290</span></p>
  <p className="c15"><span className="c12">&#1057;&#1072;&#1081;&#1090;: </span><span className="c18 c12"><a className="c8"
        href="https://www.google.com/url?q=https://smartwebinar.org&amp;sa=D&amp;source=editors&amp;ust=1658481495919511&amp;usg=AOvVaw1Se66QbQFP9g5rE4pZhEsR">https://smartwebinar.org</a></span><span
      className="c2">&nbsp;<br/>&#1043;&#1077;&#1085;&#1077;&#1088;&#1072;&#1083;&#1100;&#1085;&#1099;&#1081;
      &#1076;&#1080;&#1088;&#1077;&#1082;&#1090;&#1086;&#1088; &#1040;. &#1070;.
      &#1045;&#1083;&#1100;&#1085;&#1080;&#1094;&#1082;&#1080;&#1081;</span></p>
  <p className="c15 c7"><span className="c2"></span></p>
  <p className="c15 c7"><span className="c2"></span></p>
  <p className="c15"><span className="c9">&#1044;&#1072;&#1090;&#1072; &#1085;&#1072;&#1095;&#1072;&#1083;&#1072;
      &#1076;&#1077;&#1081;&#1089;&#1090;&#1074;&#1080;&#1103; &#1054;&#1092;&#1077;&#1088;&#1090;&#1099; 11.07.2022
      &#1075;.</span></p>
  <p className="c1"><span className="c2"></span></p>
  <p className="c15 c7"><span className="c9"></span></p>
  <p className="c1"><span className="c2"></span></p>
          </Paper>
      </Grid>
    </Page>
  )
}
import * as React from "react";
import classnames from "classnames";
import styles  from "./styles.module.scss";
import table from "./icons/tablet.svg";
import envelop from "./icons/envelop.svg";
import eye from "./icons/eye.svg";
import search from "./icons/search.svg";
import eyeOff from "./icons/eyeOff.svg";
import close from "./icons/close.svg";
import left from "./icons/left-arrow.svg";
import right from "./icons/right-arrow.svg";
import document from "./icons/document.svg";
import {themeVariables} from "../../MUI";

const icons: any = {
  table,
  envelop,
  eye,
  search,
  eyeOff,
  close,
  left,
  right,
  document,
};

interface Props {
  style?: any;
  id?: any;
  name:
    | string
    | "table"
    | "envelop"
    | "eye"
    | "search"
    | "eyeOff"
    | "close"
    | "left"
    | "right"
    | "document";
  children?: string;
  onClick?: any;
  size?: number;
  color?: any;
  tooltip?: any;
}

export class Icon extends React.Component<Props, {}> {
  svg: any;

  componentDidMount() {
    this.injectSVGProps();
  }

  componentDidUpdate() {
    this.injectSVGProps();
  }

  injectSVGProps() {
    const {size, color} = this.props;

    const svg = this.svg.children[0];
    svg.setAttribute("width", size);
    svg.setAttribute("height", size);

    Array.from(svg.querySelectorAll("g,path,circle,rect")).forEach((el: any) =>
      el.setAttribute("fill", color)
    );
  }

  render() {
    const {children, name, size, color, ...badgeProps} = this.props;
    const className = classnames({
      [styles.Icon]: true,
    });

    const style = {
      width: `${size}px`,
      height: `${size}px`,
    };

    return (
      <span
        className={className}
        {...badgeProps}
        dangerouslySetInnerHTML={{__html: icons[name]}}
        ref={(r) => (this.svg = r)}
        title={this.props.tooltip}
        style={{
          ...style,
          fill: themeVariables.palette.primary.light,
        }}
      />
    );
  }

  static defaultProps = {
    size: 16,
    color: "#8B97E8",
  };
}

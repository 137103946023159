import React from 'react';
import {
  Box,
  Button,
  ButtonProps,
  Hidden,
  lighten,
  styled,
} from '@material-ui/core';
import { tl } from '../../utils';
import SvgIcon from '../SvgIcon';
import { themeVariables } from '../../MUI';
import { IUser } from '../../types';

const ServiceToggleWrap = styled(Box)({
  border: '1px solid #E3E4E8',
  borderRadius: '0 4px 4px 0',
  padding: '8px 0',
  position: 'fixed',
  top: '58px',
  left: 0,
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#fff',
  zIndex: 1000,

  '& .serviceButtonLabel': {
    width: '0',
    overflow: 'hidden',
    transition: 'width .25s',
  },

  '&:hover .serviceButtonLabel': {
    width: '149px',
  },
});

type TServiceButtonColor = 'lightblue' | 'blue' | 'orange';

interface ServiceButtonProps {
  color: TServiceButtonColor;
  active?: boolean;
}

const getBgColor = (color: TServiceButtonColor) => {
  switch (color) {
    case 'lightblue':
      return lighten(themeVariables.palette.marks[7], 0.93);
    case 'blue':
      return lighten(themeVariables.palette.marks[0], 0.93);
    case 'orange':
      return lighten(themeVariables.palette.marks[2], 0.93);
    default:
      return '';
  }
};

const ServiceButton = styled(
  ({
    color,
    active,
    ...other
  }: ServiceButtonProps & Omit<ButtonProps, keyof ServiceButtonProps>) => (
    <Button {...other} />
  )
)({
  height: '50px',
  borderRadius: 0,
  padding: 0,
  font: 'normal normal normal 18px/22px Days',
  textTransform: 'uppercase',
  justifyContent: 'flex-start',
  backgroundColor: (props: ServiceButtonProps) => {
    if (!props.active) return '';
    return getBgColor(props.color);
  },
  color: (props: ServiceButtonProps) => {
    switch (props.color) {
      case 'lightblue':
        return themeVariables.palette.marks[7];
      case 'blue':
        return themeVariables.palette.marks[0];
      case 'orange':
        return themeVariables.palette.marks[2];
      default:
        return '';
    }
  },
  borderRight: (props: ServiceButtonProps) => {
    if (!props.active) return '3px solid transparent';
    switch (props.color) {
      case 'lightblue':
        return `3px solid ${themeVariables.palette.marks[7]}`;
      case 'blue':
        return `3px solid ${themeVariables.palette.marks[0]}`;
      case 'orange':
        return `3px solid ${themeVariables.palette.marks[2]}`;
      default:
        return '3px solid transparent';
    }
  },
  borderLeft: '3px solid transparent',
  '& .MuiButton-label': {
    minHeight: '30px',
  },
  '& .serviceButtonLabel': {
    textAlign: 'left',
  },
  '& .MuiButton-startIcon': {
    margin: '0 auto',
    minWidth: '50px',
    justifyContent: 'center',
  },
  '&:hover': {
    backgroundColor: (props: ServiceButtonProps) => getBgColor(props.color),
  },
});

export const ServiceToggle = ({user}:{user:IUser}) => {
  return (
    <Hidden only="xs">
      <ServiceToggleWrap>
        <ServiceButton
          color="lightblue"
          startIcon={<SvgIcon type="sw-courses" />}
          href={`${process.env.REACT_APP_AUTH_ENDPOINT}/api/enter?auth=${user.authId}&signature=${user.signature}&service=at`}
        >
          <span className="serviceButtonLabel">{tl('Курсы')}</span>
        </ServiceButton>
        <ServiceButton
          color="blue"
          startIcon={<SvgIcon type="sw-webinars" />}
          active
          href="/"
        >
          <span className="serviceButtonLabel">{tl('Вебинары')}</span>
        </ServiceButton>
        <ServiceButton
          color="orange"
          startIcon={<SvgIcon type="sw-payments" />}
          href={`${process.env.REACT_APP_AUTH_ENDPOINT}/api/enter?auth=${user.authId}&signature=${user.signature}&service=ok`}
        >
          <span className="serviceButtonLabel">{tl('Платежи')}</span>
        </ServiceButton>
      </ServiceToggleWrap>
    </Hidden>
  );
};

import * as React from "react";
import Autocomplete, {createFilterOptions,} from "@material-ui/lab/Autocomplete";
import {SvgIcon} from "../components";
import {MenuItem, MenuList, TextField,} from "@material-ui/core";
import {tl} from "../utils";

const filter = createFilterOptions();

export const MUITagsSelect = (props: any) => {
  return (
    <Autocomplete
      noOptionsText={tl("Совпадений не найдено")}
      popupIcon={
        <div className="MuiSelect-icon">
          <SvgIcon type="select-drop"/>
        </div>
      }
      renderInput={(params: any) => <TextField variant="outlined" {...params} />}
      ChipProps={{
        size: "small",
        deleteIcon: (
          <div>
            <SvgIcon type="tag-delete" hover="opacityInversed"/>
          </div>
        ),
      }}
      filterOptions={(options: any, params: any) => {
        const filtered = filter(options, params) as string[];

        if (params.inputValue !== "") {
          filtered.push(params.inputValue);
        }

        return filtered;
      }}
      ListboxComponent={MenuList}
      renderOption={(option: string, {selected}) => (
        <MenuItem
          component="div"
          selected={selected}
          placeholder={props.placeholder}
        >
          {option}
        </MenuItem>
      )}
      {...props}
    />
  );
};

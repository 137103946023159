import * as React from "react";
import {Box, Typography} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
interface WebinarEventSimpleProps {
  name:string;
}

const useStyles = makeStyles({
  root: {
    fontWeight: 600,
    lineHeight: "30px",
  },
});

export const WebinarEventSimple = (
  props: WebinarEventSimpleProps,
) => {
  const classes = useStyles();

  return (
    <Box px="10px">
      <Typography classes={{root: classes.root}}>{props.name}</Typography>
    </Box>
  );
}
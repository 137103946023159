import { SvgIcon, SvgIconProps } from "@material-ui/core"


export const AWConvert: React.FC<SvgIconProps> = ({...props}) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 17.323">
      <defs>
        <style>{'.a-s,.b-s{fill:#2c3a98;}.b-s{font-size:11px;font-family:SFUIText-Bold, SF UI Text;font-weight:700;}'}</style>
      </defs>
      <path className="a-s" d="M18.5,12.478a7.085,7.085,0,0,1,2.192-.369,6.785,6.785,0,0,1,2.146.315,6.992,6.992,0,0,1,4.823,7.862l-1.931-1.115.185,5.715,5.038-2.669-2.015-1.162a8.392,8.392,0,0,0-5.677-9.938,8.531,8.531,0,0,0-5.208.062.7.7,0,1,0,.446,1.3Z" transform="translate(-10.949 -10.74)"/>
      <path className="a-s" d="M21.469,27.151a7.085,7.085,0,0,1-2.192.369,6.923,6.923,0,0,1-2.146-.369,7.015,7.015,0,0,1-4.785-8.108l1.538.9V14.32L9,17.151l2.085,1.2a8.415,8.415,0,0,0,5.608,10.162,8.285,8.285,0,0,0,2.485.377h.085a8.069,8.069,0,0,0,2.631-.438.692.692,0,0,0,.438-.892.685.685,0,0,0-.862-.408Z" transform="translate(-9 -11.566)"/>
      <text className="b-s" transform="translate(5.746 12.26)"><tspan x="0" y="0">А</tspan></text>
    </SvgIcon>
  );
}

export default AWConvert;
import React from 'react';
import { Box, makeStyles, Tooltip } from '@material-ui/core';
import { tl } from '../../utils';

interface FreePeriodProps {
  freePeriodDays: number;
}

const useStyles = makeStyles ((theme) => ({
  freeDaysBox: {
    padding: '4px 12px',
    fontSize: '13px',
    lineHeight: '16px',
    fontWeight: 600,
    backgroundColor: (props: FreePeriodProps) => {
      return props.freePeriodDays <= 7 ? theme.palette.warning.light : theme.palette.grey['A400']
    },
    color: (props: FreePeriodProps) => {
      return props.freePeriodDays <= 7 ? theme.palette.warning.main : theme.palette.primary.light
    },
    cursor: 'pointer',
  },
}));

export const FreePeriod: React.FC<FreePeriodProps> = ({freePeriodDays}) => {
  const classes = useStyles({freePeriodDays});

  return (
    <Tooltip title={tl('Осталось бесплатных дней использования SmartWebinar:') + ` ${freePeriodDays}`} arrow placement="bottom">
      <Box className={classes.freeDaysBox}>
        Бесплатных дней: {freePeriodDays}
      </Box>
    </Tooltip>
  )
}
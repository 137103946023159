import {IUser} from "../types";
import axios, {default as Axios} from "axios";
import {apiClient} from "./_client"


export const login = async (email: string, password: string): Promise<IUser> => {
  const authEndpoint = process.env.REACT_APP_API_GATEWAY_ENDPOINT;
  const authResponse = await Axios.post(`${authEndpoint}/auth/login`,
    {
      service: 'webinar',
      login: email,
      password: password
    },
      {
        headers: {'Content-Type': 'application/json'},
        withCredentials: true
      }
    );
  const response = await apiClient.get<IUser>(`/auth?token=${authResponse.data.token}`);
  return response.data;
};

export const profile = async (): Promise<IUser> => {
  const response = await apiClient.get('/profile');
  // Приводим профиль к заявленному типу IUser из-за того
  // что с бэка приходит распарсенный телефон. Если понадобится
  // использовать дополнительные данные, то это можно будет убрать
  const profile  = {...response.data, phone: `+${response.data.phone.countryCode}${response.data.phone.number}`};
  return profile;
}

export const updateProfile = async (profile: Partial<IUser>): Promise<IUser> => {
  const response = await apiClient.put('/profile', profile);
  // Приводим профиль к заявленному типу IUser из-за того
  // что с бэка приходит распарсенный телефон. Если понадобится
  // использовать дополнительные данные, то это можно будет убрать
  const newProfile  = {...response.data, phone: `+${response.data.phone.countryCode}${response.data.phone.number}`};
  return newProfile;
}

export const getPhotoUploadLink = async (size: string | number, mimetype: string): Promise<string> => {
  const response = await apiClient.post('/profile/photo', {
    size: size,
    mimetype: mimetype,
  });
  return response.data.link;
}

export const uploadPhoto = async (uploadLink: string, file: File): Promise<void> => {
  const formData = new FormData();
  formData.append('file', file, file.name);
  await axios.put(uploadLink, file, {
    headers: {
      'Content-Type': file.type,
    },
  });
}

export const updateSecurity = async (password: string) => {
  const response = await apiClient.post('/profile/password', {
    password: password
  });
  return response.data;
}

export const create = async (formData: FormData) => {
    const response = await apiClient.post('/users', formData);
    return response.data;
}

export const createFromFile = async (tariff: string, file: File) => {
    let formData = new FormData();
    formData.append('file', (file as Blob));
    formData.append('tariff', tariff);
    const response = await apiClient.post('/users', formData);
    return response.data;
}

export const logout = async()=> {
  await apiClient.post('/logout')
}

import { Overrides as CoreOverrides } from '@material-ui/core/styles/overrides';
import { AutocompleteClassKey } from '@material-ui/lab';
import { CSSProperties } from '@material-ui/styles';
import { createMuiTheme, darken, lighten } from '@material-ui/core/styles';

//
// BACKUP
//
// success: {
//   main: "#d9ffe0",
//   dark: "#c6e0d2"
// },
// error: {
//   main: "#fee4e5",
//   dark: "#e9c0c8"
// },
//

type TLineClamp = {
  display: string;
  lineClamp: number;
  textOverflow: string;
  overflow: string;
  '-webkit-line-clamp': number;
  '-webkit-box-orient': string;
  maxHeight: string;
  lineHeight: string;
  wordBreak: any;
};

export function makeLineClamp(lines: number, lineHeight = 1.27): TLineClamp {
  return {
    display: 'box',
    lineClamp: lines,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    '-webkit-line-clamp': lines,
    '-webkit-box-orient': 'vertical',
    maxHeight: `calc( 1em * ${lines * lineHeight} )`,
    lineHeight: `calc( ${lineHeight} * 1em)`,
    //@ts-ignore
    wordBreak: 'break-word',
  };
}

interface IThemeElementColors {
  dark?: string;
  main: string;
  light?: string;
  contrastText?: string;
  hover?: string;
  hoverContrastText?: string;
}

export interface IThemeSettings {
  mainColor: string;
  buttonsColor: string;
  buttonsRadius: number;
  blockRadius: number;
  //   faviconURL: string;
  logoImageURL?: string;
  bgImageURL: string;
  bgRepeat: 'repeat' | 'no-repeat' | 'repeat-x' | 'repeat-y';
  bgPosition:
    | '0 0'
    | '50% 0'
    | '100% 0'
    | '0 50%'
    | '50% 50%'
    | '100% 50%'
    | '0 100%'
    | '50% 100%'
    | '100% 100%';
  bgSize: 'auto' | '100% 100%' | 'cover' | 'contain';
  customSvgSprite: string;
  palette: {
    bgColor: string;
    headerColor: string;
    headerTextColor: string;
    blockBgColor: string;
    border: string;
    borderOuter: string;
    common: {
      black: string;
      white: string;
    };
    default: IThemeElementColors;
    disabled: IThemeElementColors;
    primary: IThemeElementColors;
    shop: IThemeElementColors;
    subscribe: IThemeElementColors;
    webinar?: IThemeElementColors;
    success: IThemeElementColors;
    info: IThemeElementColors;
    warning: IThemeElementColors;
    error: IThemeElementColors;
    marks: string[];
    grey: {
      [key: string]: string;
    };
    fader: string;
    faderControls: string;
  };
}

export const themeDefault: IThemeSettings = {
  mainColor: '#3F53D9',
  buttonsColor: '#3F53D9',
  buttonsRadius: 4,
  blockRadius: 4,
  //   faviconURL: "/public/images/_anti/favicon.ico",
  //   logoImageURL: "",
  bgImageURL: '',
  bgRepeat: 'repeat-y',
  bgPosition: '50% 0',
  bgSize: 'auto',
  customSvgSprite: '',
  palette: {
    bgColor: '#EFF0F2',
    headerColor: '#7e99b8',
    headerTextColor: '#7e99b8',
    blockBgColor: '#ffffff',
    border: '#d3d3d3',
    borderOuter: '#e1e1e1',
    common: {
      black: '#000000',
      white: '#ffffff',
    },
    // BUTTONS
    default: {
      main: '#F1F3FD',
      contrastText: '#2C3A98',
      hover: darken('#F1F3FD', 0.1),
      hoverContrastText: '#2C3A98',
    },
    disabled: {
      main: '#f5f6fd',
      contrastText: '#6d6d6d',
    },
    primary: {
      dark: '#2C3A98',
      light: '#8B97E8',
      main: '#3F53D9',
      contrastText: '#FFFFFF',
      hover: darken('#3F53D9', 0.1),
      hoverContrastText: '#FFFFFF',
    },
    shop: {
      main: '#ff8c11',
      contrastText: '#ffffff',
    },
    subscribe: {
      main: '#db24b6',
      contrastText: '#ffffff',
    },
    webinar: {
      main: '#7987e4',
      contrastText: '#ffffff',
    },
    // NOTIFICATIONS
    success: {
      light: '#eaf6ee',
      main: '#30AB59',
      dark: '#22783E',
      contrastText: '#22783e',
    },
    info: {
      light: '#eaf3fb',
      main: '#2F90E0',
      dark: '#c1def6',
      contrastText: '#21659d',
    },
    warning: {
      light: '#fef3e7',
      main: '#F58814',
      dark: '#fcdbb9',
      contrastText: '#ac5f0e',
    },
    error: {
      light: '#fcedec',
      main: '#E35248',
      dark: '#f7cbc8',
      contrastText: '#9f3932',
    },
    // PALETTE
    marks: [
      '#3F53D9',
      '#e35248',
      '#f58814',
      '#f2c410',
      '#acc214',
      '#30ab59',
      '#02a8a1',
      '#2f90e0',
      '#3a47e0',
      '#6f3bd9',
      '#3F53D9',
      '#e03496',
    ],
    grey: {
      50: '#000000', // Основной текст
      100: '#415974', // FREE TO USE
      200: '#e2e2e2', // Grey 20%
      300: '#d3d3d3', // Grey 30%
      400: '#93ABC4', // FREE TO USE
      500: '#b6b6b6', // Grey 50% -- Основной серый 50% (Текст на Disabled кнопке)
      600: '#6d6d6d', // Grey -- Основной серый (Текст в полях ввода по умолчанию)
      700: '#797a7a', // Grey 2 75% -- Доп. серый (Фон кнопок поверх изображений)
      800: '#4d4e4e', // Grey 2 -- Доп. серый
      900: '#d9ddf7', // M Blue 20%
      A100: '#f9fafe', // M Blue 3% -- Фон заголовков
      A200: '#f5f6fd', // M Blue 5%
      A400: '#f2f3fc', // M Blue 7% -- Фон заголовков модальных окон
      A700: '#eceefb', // M Blue 10%
      A800: '#D8DCF8', // blue 20%
    },
    fader: 'rgba(0, 0, 0, 0.8)',
    faderControls: 'rgba(77, 78, 78, 0.75)',
  },
};

export const themeVariables = (window as any).THEME_SETTINGS
  ? { ...(window as any).THEME_SETTINGS }
  : { ...themeDefault };

themeVariables.breakpoints = {
  xs: 640,
  sm: 768,
  md: 1000,
  lg: 1280,
  xl: 1600,
  up(val: string) {
    return `@media (min-width: ${this[val]}px)`;
  },
  down(val: string) {
    return `@media (max-width: ${this[val] - 1}px)`;
  },
};

themeVariables.border = '1px solid ' + themeVariables.palette.border;
themeVariables.borderOuter = '1px solid ' + themeVariables.palette.borderOuter;
themeVariables.spacing = 8;
themeVariables.shape = {
  borderRadius: 4,
};
themeVariables.typography = {
  body1: {
    fontSize: 13,
    lineHeight: 1.1539,
  },
};

interface Overrides extends CoreOverrides {
  MuiAutocomplete?:
    | Partial<
        Record<AutocompleteClassKey, CSSProperties | (() => CSSProperties)>
      >
    | undefined;
}
const overrides: Overrides = {
  MuiAutocomplete: {
    root: {},
    inputRoot: {
      '&[class*="MuiOutlinedInput-root"]': {
        minHeight: 32,
        padding: `2px`,
        '$hasPopupIcon &, $hasClearIcon &': {
          paddingRight: 32,
        },
        '$hasPopupIcon$hasClearIcon &': {
          paddingRight: 52,
        },
        '& $input': {
          minHeight: 28,
          padding: `0 4px`,
        },
        '& $input:first-child': {
          paddingLeft: 3,
        },
        '& $endAdornment': {
          right: 0,
        },
      },
      '& $input': {
        minWidth: 10,
      },
    },
    tag: {
      margin: 2,
    },
    endAdornment: {
      top: `calc(50% - 12px)`,
    },
    popupIndicator: {
      padding: 0,
      marginRight: 0,
      '& .MuiSelect-icon': {
        position: `static`,
      },
    },
    option: {
      display: 'block',
      padding: '0 !important',
      background: 'none !important',
    },
  },
  MuiBackdrop: {
    root: {
      backgroundColor: themeVariables.palette.fader,
    },
  },
  MuiBreadcrumbs: {
    root: {
      color: themeVariables.palette.grey[600],
    },
    li: {
      '&::before': {
        display: 'none',
      },
    },
    separator: {
      '&::before': {
        display: 'none',
      },
    },
  },
  MuiButton: {
    root: {
      minWidth: 40,
      minHeight: 30,
      padding: `${themeVariables.spacing * 0.625}px ${
        themeVariables.spacing * 2
      }px`,
      '& .svgicon': {
        marginTop: -themeVariables.spacing,
        marginBottom: -themeVariables.spacing,
      },
      '&.MuiButton-nopad': {
        padding: 0,
        lineHeight: 'initial',
        textAlign: 'left',
      },
      [themeVariables.breakpoints.down('md')]: {
        padding: `${themeVariables.spacing}px ${
          themeVariables.spacing * 1.5
        }px`,
      },
      [themeVariables.breakpoints.down('sm')]: {
        padding: `${themeVariables.spacing}px ${themeVariables.spacing * 2}px`,
      },
      '& .MuiButton-startIcon': {
        marginRight: 6,
      },
      '&.MuiButton-check .MuiButton-startIcon': {
        marginTop: -10,
        marginRight: 0,
        marginBottom: -10,
        marginLeft: -themeVariables.spacing * 2,
      },
      '&.MuiButton-social': {
        paddingRight: themeVariables.spacing,
        paddingLeft: themeVariables.spacing,
        '& .MuiButton-label': {
          justifyContent: 'space-between',
          '&::before': {
            display: 'none',
          },
          '& .MuiButton-startIcon': {
            marginLeft: -3,
            [themeVariables.breakpoints.down('sm')]: {
              transformOrigin: '0 50%',
              transform: 'scale(1.5)',
            },
          },
        },
      },
      '&.MuiPhoneNumber-flagButton .flag': {
        border: themeVariables.border,
      },
    },
    text: {
      padding: `${themeVariables.spacing}px ${themeVariables.spacing * 2}px`,
      color: themeVariables.palette.primary.dark,
      borderRadius: themeVariables.buttonsRadius,
      '&.MuiButton-box': {
        minWidth: 0,
        padding: themeVariables.spacing,
        '& .MuiButton-label::before': {
          display: 'none',
        },
      },
      '&.MuiButton-smallbox': {
        minWidth: 0,
        padding: themeVariables.spacing / 2,
        '& .MuiButton-label::before': {
          display: 'none',
        },
      },
      '&$disabled': {
        backgroundColor: themeVariables.palette.disabled.main,
        color: themeVariables.palette.grey[500],
      },
    },
    contained: {
      backgroundColor: themeVariables.palette.default.main,
      color: themeVariables.palette.default.contrastText,
      boxShadow: 'none',
      borderRadius: themeVariables.buttonsRadius,
      '&:hover': {
        backgroundColor: themeVariables.palette.default.hover,
        color: themeVariables.palette.default.hoverContrastText,
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
      },
      '&$disabled': {
        backgroundColor: themeVariables.palette.disabled.main,
        color: themeVariables.palette.disabled.contrastText,
      },
      '&.MuiButton-smallpad': {
        padding: `${themeVariables.spacing}px ${
          themeVariables.spacing * 0.5
        }px`,
      },
      '&.MuiButton-nopad': {
        padding: 0,
      },
      '&.MuiButton-iconAction': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: 0,
        width: 32,
        height: 32,
        padding: 0,
        [themeVariables.breakpoints.down('sm')]: {
          width: 40,
          height: 40,
        },
      },
      '&.MuiButton-big': {
        padding: `${themeVariables.spacing}px ${
          themeVariables.spacing * 1.5
        }px`,
        fontSize: 18,
        lineHeight: '24px',
      },
      '&.MuiButton-cover': {
        display: 'flex',
        padding: themeVariables.spacing / 2,
      },
      '&.MuiButton-cover .MuiButton-label': {
        height: '100%',
        border: 1,
        borderStyle: 'dashed',
        borderColor: themeVariables.palette.primary.main,
        boxSizing: 'border-box',
        borderRadius: Math.max(
          0,
          themeVariables.buttonsRadius - themeVariables.spacing / 2
        ),
      },
      '&.MuiButton-shop': {
        backgroundColor: themeVariables.palette.shop.main,
        color: themeVariables.palette.shop.contrastText,
        '&:hover': {
          backgroundColor: themeVariables.palette.shop.hover,
          color: themeVariables.palette.shop.hoverContrastText,
        },
        '&:startIcon': {
          marginRight: 0,
        },
      },
      '&.MuiButton-subscribe': {
        backgroundColor: themeVariables.palette.subscribe.main,
        color: themeVariables.palette.subscribe.contrastText,
        '&:hover': {
          backgroundColor: themeVariables.palette.subscribe.hover,
          color: themeVariables.palette.subscribe.hoverContrastText,
        },
        '&:startIcon': {
          marginRight: 0,
        },
      },
    },
    containedPrimary: {
      backgroundColor: themeVariables.palette.primary.main,
      color: themeVariables.palette.primary.contrastText,
      '&:hover': {
        backgroundColor: themeVariables.palette.primary.hover,
        color: themeVariables.palette.primary.hoverContrastText,
      },
    },
    outlined: {
      border: '2px solid ' + themeVariables.palette.primary.main,
      color: themeVariables.palette.default.contrastText,
      padding: `${themeVariables.spacing * 0.625}px ${
        themeVariables.spacing * 2
      }px`,
      '&.Mui-disabled': {
        color: themeVariables.palette.grey[600],
        border: '2px solid #D3D9DE',
      },
    },
    sizeSmall: {
      padding: `${themeVariables.spacing / 4}px ${
        themeVariables.spacing / 2
      }px`,
    },
    sizeLarge: {
      width: 170,
      minHeight: 66,
      padding: `${themeVariables.spacing * 1}px ${
        themeVariables.spacing * 2.5
      }px ${themeVariables.spacing * 1.5}px ${themeVariables.spacing * 2.5}px`,
      fontSize: 18,
      lineHeight: '21px',
      fontWeight: 700,
      textAlign: 'center',
      boxShadow: `0 0 ${themeVariables.spacing * 0.75}px ${lighten(
        themeVariables.palette.fader,
        0.4
      )}`,
      '&:hover': {
        boxShadow: `0 0 ${themeVariables.spacing * 0.75}px ${lighten(
          themeVariables.palette.fader,
          0.4
        )}`,
      },
      [themeVariables.breakpoints.down('sm')]: {
        width: 'auto',
        minHeight: 0,
        padding: `${themeVariables.spacing}px ${themeVariables.spacing * 2}px`,
        fontSize: 16,
        lineHeight: '24px',
        boxShadow: 'none',
      },
    },
    fullWidth: {
      [themeVariables.breakpoints.down('sm')]: {
        paddingRight: themeVariables.spacing,
        paddingLeft: themeVariables.spacing,
      },
    },
  },
  MuiCheckbox: {
    root: {
      padding: `${themeVariables.spacing * 1.25}px`,
      paddingRight: `${themeVariables.spacing * 0.625}px`,
      color: '#D3D9DE',
      marginTop: -themeVariables.spacing / 2,
      marginBottom: -themeVariables.spacing / 2,
      '&::before': {
        display: 'block',
        content: "''",
        width: 12,
        height: 12,
        marginTop: -6,
        marginLeft: -6,
        backgroundColor: '#ffffff',
        position: 'absolute',
        top: '50%',
        left: '50%',
        zIndex: -1,
      },
      '&$colorPrimary': {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      '&.MuiCheckbox-error path': {
        fill: themeVariables.palette.error.main,
      },
    },
  },
  MuiChip: {
    root: {
      height: `auto`,
      color: themeVariables.palette.grey[600],
      fontSize: 15,
      lineHeight: '15px',
      borderRadius: themeVariables.shape.borderRadius,
      backgroundColor: themeVariables.palette.grey[200],
      '&$sizeSmall': {
        fontSize: 13,
        lineHeight: '15px',
      },
      [themeVariables.breakpoints.down('sm')]: {
        fontSize: 14,
      },
    },
    label: {
      paddingTop: 4,
      paddingRight: 12,
      paddingBottom: 5,
      paddingLeft: 12,
      '&Small': {
        paddingRight: 6,
        paddingLeft: 6,
      },
    },
    deleteIcon: {
      display: `flex`,
      alignItems: `center`,
      justifyContent: `center`,
      width: 18,
      height: `100%`,
      margin: 0,
      marginLeft: -4,
    },
  },
  MuiRadio: {
    root: {
      color: '#D3D9DE',
      '&.Mui-checked': {
        color: themeVariables.palette.primary.dark,
      },
    },
  },
  MuiDialog: {
    root: {
      '&.mobileFullScreen': {
        '& .MuiDialog-paper': {
          [themeVariables.breakpoints.down('sm')]: {
            width: '100%',
            margin: 0,
          },
        },
        '& .MuiDialog-paperScrollPaper': {
          [themeVariables.breakpoints.down('sm')]: {
            maxHeight: '100%',
          },
        },
        '& .MuiDialog-paperWidthFalse': {
          [themeVariables.breakpoints.down('sm')]: {
            maxWidth: '100%',
          },
        },
      },
      '&.withControls': {
        '& .MuiDialog-paper': {
          overflow: 'visible',
        },
      },
      '&.achievementsDialog': {
        '& .MuiDialog-paper': {
          maxWidth: 685,
        },
        '& .MuiDialogContent': {
          marginRight: 8,
        },
      },
    },
    paper: {
      borderRadius: themeVariables.shape.borderRadius,
      [themeVariables.breakpoints.down('sm')]: {
        margin: 0,
      },
    },
    paperWidthMd: {
      maxWidth: 556,
    },
  },
  MuiDialogActions: {
    root: {
      padding: `${themeVariables.spacing * 1.5}px ${
        themeVariables.spacing * 3
      }px`,
      borderTop: themeVariables.border,
    },
  },
  MuiDialogContent: {
    root: {
      padding: `${themeVariables.spacing * 2}px ${
        themeVariables.spacing * 3
      }px`,
      fontSize: '15px',
      lineHeight: '22px',
      '& p:last-child': {
        marginBottom: 0,
      },
    },
  },
  MuiDialogTitle: {
    root: {
      padding: `${themeVariables.spacing * 2}px ${
        themeVariables.spacing * 3
      }px`,
      backgroundColor: themeVariables.palette.grey.A400,
      borderBottom: themeVariables.border,
    },
  },
  MuiDivider: {
    root: {
      marginTop: themeVariables.spacing,
      marginBottom: themeVariables.spacing,
      '.sidebarMenuDeeper &': {
        marginTop: themeVariables.spacing / 2,
        marginRight: themeVariables.spacing * 2,
        marginBottom: themeVariables.spacing / 2,
        marginLeft: themeVariables.spacing * 3,
        '.sidebarMenuDeeper &': {
          marginLeft: themeVariables.spacing * 4,
        },
      },
    },
  },
  MuiFormControlLabel: {
    root: {
      marginTop: -3,
      marginBottom: -3,
      padding: 0,
      '&.MuiLineControl': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: 0,
      },
      '&.MuiLineControl + &.MuiLineControl': {
        marginTop: themeVariables.spacing * 2,
      },
    },
  },
  MuiFormHelperText: {
    root: {
      '&$error': {
        color: themeVariables.palette.error.main,
      },
    },
    contained: {
      marginRight: 10,
      marginLeft: 10,
    },
  },
  MuiGrid: {
    root: {
      flexShrink: 0,
    },
  },
  MuiInputAdornment: {
    root: {
      '&.MuiPhoneNumber-positionStart': {
        height: 'auto',
        maxHeight: 'none',
        borderRight: themeVariables.border,
      },
    },
  },
  MuiInputBase: {
    root: {
      alignItems: 'stretch',
      lineHeight: '16px',
      [themeVariables.breakpoints.down('sm')]: {
        fontSize: '16px',
        lineHeight: '24px',
      },
    },
    input: {
      '&::placeholder': {
        color: themeVariables.palette.grey[600],
      }
    }
  },
  MuiLinearProgress: {
    bar: {
      '.green > &': {
        backgroundColor: themeVariables.palette.success.main,
      },
      '.red > &': {
        backgroundColor: themeVariables.palette.error.main,
      },
    },
  },
  MuiLink: {
    root: {
      cursor: 'pointer',
    },
  },
  MuiList: {
    root: {
      '&$padding': {
        '.sidebarMenuDeeper &': {
          padding: 0,
        },
      },
    },
  },
  MuiMenuItem: {
    root: {
      minHeight: 0,
      paddingTop: themeVariables.spacing,
      paddingBottom: themeVariables.spacing,
      color: themeVariables.palette.primary.dark,
      '&:hover': {
        backgroundColor: themeVariables.palette.grey.A400,
      },
      '&$selected': {
        backgroundColor: themeVariables.palette.grey.A400,
        color: themeVariables.palette.common.black,
        fontWeight: 500,
        '&::before': {
          display: 'block',
          content: "''",
          width: 2,
          backgroundColor: themeVariables.palette.primary.main,
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
        },
        '&:hover': {
          backgroundColor: themeVariables.palette.grey.A400,
        },
      },
      '.sidebarMenuDeeper &': {
        paddingLeft: themeVariables.spacing * 3,
        color: themeVariables.palette.grey[600],
        '.sidebarMenuDeeper &': {
          paddingLeft: themeVariables.spacing * 4,
        },
      },
      '&.country': {
        '& .flag': {
          border: themeVariables.border,
        },
        '& .country-name': {
          marginRight: 4,
        },
      },
    },
  },
  MuiFilledInput: {
    input: {
      height: 'auto',
      padding: `${themeVariables.spacing}px ${themeVariables.spacing * 1.25}px`,
      [themeVariables.breakpoints.down('sm')]: {
        paddingRight: themeVariables.spacing * 2,
        paddingLeft: themeVariables.spacing * 2,
      },
      '&[type=number]': {
        paddingRight: 0,
      },
    },
    underline: {
      '&::before, &::after': {
        display: 'none',
      },
    },
    adornedStart: {
      paddingLeft: 0,
    },
    adornedEnd: {
      paddingRight: 0,
    },
    root: {
      borderBottomRightRadius: themeVariables.shape.borderRadius,
      borderBottomLeftRadius: themeVariables.shape.borderRadius,
      '.MuiFilledInputWhite &': {
        backgroundColor: themeVariables.palette.common.white,
      },
      '&:hover': {
        '.MuiFilledInputWhite &': {
          backgroundColor: themeVariables.palette.common.white,
        },
      },
      '&:active': {
        '.MuiFilledInputWhite &': {
          backgroundColor: themeVariables.palette.common.white,
        },
      },
      '.MuiLiveInput &': {
        font: 'inherit',
      },
    },
  },
  MuiOutlinedInput: {
    input: {
      height: 'auto',
      padding: `${themeVariables.spacing}px ${themeVariables.spacing * 1.25}px`,
      [themeVariables.breakpoints.down('sm')]: {
        paddingRight: themeVariables.spacing * 2,
        paddingLeft: themeVariables.spacing * 2,
      },
      '&[type=number]': {
        paddingRight: 0,
      },
    },
    inputMarginDense: {
      paddingTop: 3,
      paddingBottom: 3,
    },
    notchedOutline: {
      border: themeVariables.border,
      position: 'absolute',
      top: -5,
      right: 0,
      bottom: 0,
      left: 0,
      pointerEvents: 'none',
      borderRadius: 'inherit',
      '.MuiOutlinedInput-dashed &': {
        borderStyle: 'dashed',
      },
      '& legend': {
        lineHeight: '11px',
      },
    },
    multiline: {
      height: '100%',
      padding: `${themeVariables.spacing}px ${themeVariables.spacing * 1.5}px`,
      [themeVariables.breakpoints.down('sm')]: {
        paddingRight: themeVariables.spacing * 2,
        paddingLeft: themeVariables.spacing * 2,
      },
    },
    adornedStart: {
      paddingLeft: 0,
    },
    adornedEnd: {
      paddingRight: 0,
    },
    root: {
      '&:hover $notchedOutline': {
        borderColor: themeVariables.palette.primary.main,
      },
      '&$error $notchedOutline': {
        borderColor: themeVariables.palette.error.main,
      },
      '.MuiLiveInput &': {
        height: 32,
        padding: 0,
        font: 'inherit',
        lineHeight: '32px',
        [themeVariables.breakpoints.down('sm')]: {
          height: 40,
          lineHeight: '40px',
        },
      },
    },
  },
  MuiPaper: {
    root: {
      color: themeVariables.palette.grey[50],
    },
  },
  MuiPopover: {
    paper: {
      maxHeight: 400,
      [themeVariables.breakpoints.down('sm')]: {
        maxHeight: 200,
      },
    },
  },
  MuiSelect: {
    selectMenu: {
      paddingRight: 24,
      color: themeVariables.palette.grey[50],
    },
    icon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 25,
      height: 24,
    },
  },
  MuiSnackbarContent: {
    root: {
      position: 'relative',
      minWidth: '252px',
      padding: '13px 42px 12px 20px',
      textAlign: 'left',
      fontSize: '13px !important',
      lineHeight: '15px !important',
      letterSpacing: 0,
      '&.hideIcon': {
        paddinRight: '20px',
      },
      '&.centered': {
        justifyContent: 'center',
      },
      '&.nominwidth': {
        minWidth: 0,
      },
    },
    message: {
      padding: 0,
    },
  },
  MuiSwitch: {
    root: {
      width: 50,
      margin: -12,
    },
    switchBase: {
      top: 2,
      left: 2,
      '&$checked': {
        transform: 'translateX(13px)',
      },
      '&$checked + $track': {
        opacity: 1,
      },
    },
    colorPrimary: {
      '& + $track': {
        backgroundColor: themeVariables.palette.border,
        '&$checked': {
          backgroundColor: themeVariables.palette.primary.main,
        },
      },
      '& $thumb': {
        borderColor: themeVariables.palette.border,
      },
      '&$checked $thumb': {
        borderColor: themeVariables.palette.primary.main,
      },
    },
    track: {
      width: 28,
      height: 14,
      opacity: 1,
    },
    thumb: {
      width: 12,
      height: 12,
      borderWidth: 2,
      borderStyle: 'solid',
      backgroundColor: themeVariables.palette.common.white,
      boxShadow: 'none',
      borderRadius: 9,
    },
  },
  MuiTab: {
    root: {
      minWidth: 0,
      padding: `${themeVariables.spacing * 2}px ${themeVariables.spacing}px`,
      fontWeight: 600,
      [themeVariables.breakpoints.down('sm')]: {
        fontSize: '13px',
        lineHeight: '16px',
      },
      [themeVariables.breakpoints.up('sm')]: {
        minWidth: 0,
        fontSize: '15px',
        lineHeight: '20px',
      },
      '&:hover': {
        color: themeVariables.palette.grey[600],
      },
    },
    textColorPrimary: {
      '&$selected': {
        color: themeVariables.palette.common.black,
      },
    },
  },
  MuiTooltip: {
    tooltip: {
      paddingTop: themeVariables.spacing * 1.25,
      paddingRight: themeVariables.spacing * 1.5,
      paddingBottom: themeVariables.spacing * 1.25,
      paddingLeft: themeVariables.spacing * 1.5,
      ...themeVariables.typography.body1,
    },
  },
  MuiTypography: {
    colorTextPrimary: {
      color: themeVariables.palette.grey[50],
    },
    colorTextSecondary: {
      color: themeVariables.palette.grey[600],
    },
  },
};

export const Theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: themeVariables.breakpoints.xs,
      sm: themeVariables.breakpoints.sm,
      md: themeVariables.breakpoints.md,
      lg: themeVariables.breakpoints.lg,
      xl: themeVariables.breakpoints.xl,
    },
  },
  palette: { ...themeVariables.palette, contrastThreshold: 2.49 },
  spacing: themeVariables.spacing,
  shape: {
    borderRadius: themeVariables.shape.borderRadius,
  },
  typography: {
    fontFamily: '"SF UI Text", "Arial", "Helvetica", sans-serif',
    subtitle1: {
      fontSize: '16px',
      lineHeight: '19px',
      fontWeight: 600,
      [themeVariables.breakpoints.up('sm')]: {
        fontSize: '18px',
        lineHeight: '21px',
      },
    },
    subtitle2: {
      fontSize: '15px',
      lineHeight: '20px',
      fontWeight: 400,
    },
    h1: {
      fontSize: 40,
      lineHeight: 1.4,
      fontWeight: 600,
    },
    h2: {
      fontSize: 34,
      lineHeight: 1.4,
      fontWeight: 600,
    },
    h3: {
      fontSize: 28,
      lineHeight: 1.4,
      fontWeight: 600,
    },
    h4: {
      fontSize: 23,
      lineHeight: 1.4,
      fontWeight: 600,
    },
    h5: {
      fontSize: 18,
      lineHeight: 1.4,
      fontWeight: 600,
    },
    h6: {
      fontSize: 16,
      lineHeight: 1.4,
      fontWeight: 600,
    },
    body2: {
      fontSize: 12,
      fontWeight: undefined,
      lineHeight: 1.25,
    },
    button: {
      color: themeVariables.palette.primary.main,
      fontSize: '13px',
      lineHeight: '16px',
      fontWeight: 600,
      textTransform: 'none',
      [themeVariables.breakpoints.down('sm')]: {
        fontSize: '16px',
        lineHeight: '24px',
      },
    },
  },
  overrides,
});

export type ThemeType = typeof Theme;

import React from 'react';
import { ErrorBoundaryView } from '../components/ErrorBoundaryView';
import { PermissionDenied } from '../components/PermissionDenied';
import * as Sentry from '@sentry/react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';


class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, code: '' };
  }

  componentDidCatch(error, errorInfo) {
    // Можно также сохранить информацию об ошибке в соответствующую службу журнала ошибок
    // logErrorToMyService(error, errorInfo);
    if (error !== '403') {
      // getHardwareInfo();
      Sentry.captureException(error);
      // Sentry.showReportDialog()
    }
  }

  static getDerivedStateFromError(error, errorInfo) {
    return { hasError: true, code: error };
  }

  render() {
    if (this.state.hasError) {
      if(this.state.code === '403') {
        return <PermissionDenied />
      }
      return <ErrorBoundaryView />
    }

    return this.props.children
  }
}

const mapStateToProps = (state) => {
  return ({
    user: state.user,
    player: state.player,
  })
}

export default withRouter(connect(mapStateToProps)(ErrorBoundary))
import { useCallback, useEffect } from "react";

interface VisibilityChangeHookSettings {
  // fireCbOnMount?: boolean;
  onMount?: () => any;
  onVisible?: () => any;
  onHidden?: () => any;
}

export default function useVisibilityChange(settings: VisibilityChangeHookSettings) {

  const handleVisibilityChange = useCallback(() => {
    if (document.visibilityState === 'visible' && settings.onVisible) {
      settings.onVisible();
    }
    else if (document.visibilityState === 'hidden' && settings.onHidden) {
      settings.onHidden();
    }
  }, [
    settings
  ]);


  useEffect(() => {
    if (settings.onMount) {
      settings.onMount();
    }
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
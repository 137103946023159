
import { differenceInSeconds } from 'date-fns';
import React, { useCallback, useContext, useEffect, useRef } from 'react';
import ReactPlayer, { Config } from 'react-player';
import useAudioContext from '../../../hooks/useAudioContext';
import { EWebinarSourceType } from '../../../types';
import { getVideoSourceTypeByLink } from '../../../utils';
import { VideoPlayerCtx, VideoPlayerProps } from './factory';
import * as Sentry from '@sentry/react';
import { PlayButton } from '../PlayButton';
import useVisibilityChange from '../../../hooks/useVisibilityChange';
import { isMobile } from 'react-device-detect';

const makeLink = (url: string) => {
  const type = getVideoSourceTypeByLink(url);
  if (type === EWebinarSourceType.BOOMSTREAM) {
    return `${url}?use_fullscreen_mode=0`
  }
  else return url;
}

const config: Config = {
  youtube: {
    playerVars: {
      modestbranding: 1,
      controls: 0,
      fs: 0,
      rel: 0,
      start: 0,
    },
    // onUnstarted: () => alert('ошибка, видео не запустилось, перезагрузите страницу')
  },
  file: {
    attributes: {
      playsinline: 1
    }
  }
};


export const YoutubeVideoPlayer: React.FC<VideoPlayerProps> = (props) => {
  const playerRef = useRef<ReactPlayer>();
  const { volume, muted, setMuted, setPlayerReady} = useAudioContext();
  const {setPlaying, setStarted, setMask, handleMask, hasStarted, isPlaying} = useContext(VideoPlayerCtx);
  useEffect(() => {
    setPlaying(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useVisibilityChange({
    onHidden: () => {
      setMask(true);
      if (isMobile) {
        setMuted(true);
        setPlaying(false);
      }
    },
    onVisible: () => {
      setMask(true);
      handleMask();
      if (isMobile) {
        /*
          следи за руками:
            когда происходит плей, ютуб по куке ищет последнее место отсановки видео и сам туда мотает его
            потом срабатывает синхра.
            для остальных плееров лучше в начале синхронизировать, а потом уже включать
            так дешевле для клиента
        */
        setMuted(true);
        setPlaying(true);
        synchronize();
      }
    },
  });

  const synchronize = useCallback(() => {
    let streamDuration = differenceInSeconds(Date.now(), props.startTime || Date.now());
    const contentDuration = playerRef.current?.getDuration();
    console.log('sync event started');
    console.log({streamDuration});
    console.log({contentDuration});
    if (!contentDuration) {
      return;
    }
    if (
      !!playerRef.current &&// getDuration может вернуть null согласно документации
      props.startTime !== undefined &&
      streamDuration >= 0 &&
      hasStarted
    ) {
      if (!props.live && props.seekTime) {
        streamDuration += props.seekTime
      }

      if(props.live) {
        console.log('seekTo Infinity');
        playerRef.current.seekTo(contentDuration, 'seconds');
      } else {
        console.log('seekTo ' + streamDuration);
        playerRef.current.seekTo(streamDuration , 'seconds');
      }
    }

  }, [hasStarted, props.startTime, props.live, props.seekTime, playerRef]);

  useEffect(() => {
    synchronize();
    const id = setInterval(synchronize, 3 * 60 * 1000); // every 5 minutes
    return () => clearInterval(id);
  }, [synchronize]);

  const handlePlay = () => {
    setPlaying(true);
    console.log('play reinitiated', new Date().toString());
    const player = playerRef.current;
    const isReactPlayer = player instanceof ReactPlayer;
    if (playerRef.current?.props.playing) {
      setStarted(true);
    }
    if (props.type ===  EWebinarSourceType.YOUTUBE && player) {
      if (isReactPlayer && props.type === EWebinarSourceType.YOUTUBE) {
        playerRef.current?.getInternalPlayer().playVideo();
      }
    }
  }

  const handleStart = () => {
    props.onStart && props.onStart();
    setPlayerReady && setPlayerReady(true);
    setStarted(true);
    handleMask();
    const player = playerRef.current
    if ((player as ReactPlayer)?.getInternalPlayer().playVideo) {
      (player as ReactPlayer)?.getInternalPlayer().playVideo();
    }
    
  };
  
  return (
    <>
    <ReactPlayer
      ref={(playerRef as React.MutableRefObject<ReactPlayer>)}
      className={props.className}
      url={makeLink(props.src)}
      playing={isPlaying}
      muted={muted}
      volume={volume / 100}
      controls={false}
      width="100%"
      height="100%"
      config={config}
      onStart={handleStart}
      onError={(error, data, hls, hlsg) => {
        Sentry.addBreadcrumb({
          category: 'player.src',
          message: props.src,
          level: Sentry.Severity.Info
        });
        Sentry.captureException(error);
      }}
      onPause={props.onPause}
      onReady={() => {
        props.onReady && props.onReady();
      }}
      onPlay={props.onStart}
      onEnded={() => {
        console.log('play ended', new Date().toString());
        if (props.live && !props.onEnded) {
          handlePlay();
        } else props.onEnded && props.onEnded();
      }}
    />
    {!hasStarted &&  <PlayButton onClick={handlePlay} />}
    </>
  )
}
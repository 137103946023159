import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { GridContainer, themeVariables } from '../../MUI';
import Page from '../../hoc/page';
import { tl } from '../../utils';
import { ProfilePage } from './profile';
import { SettingsNavigationMenu } from './menu';
import { SecurityPage } from './security';
import { PaymentIntegrationsPage } from '../payment-integrations';

const useStyles = makeStyles({
  wrap: {
    justifyContent: "flex-end",
  
    [themeVariables.breakpoints.down("md")]: {
      justifyContent: "flex-start",
    },
  },
  
  content: {
    minWidth: "300px",
    maxWidth: "687px",
    boxSizing: "content-box",
    // Заглушка вместо левого меню, которое теоретически
    // должно быть добавлено в МР с CloudPayments
    marginLeft: "216px",

    [themeVariables.breakpoints.down("md")]: {
      marginLeft: 0,
    },
  },
});

export const SettingsRoutes: React.FC = () => {
  const classes = useStyles();

  return (
    <Switch>
      <Route path="/settings/profile" exact>
        <ProfilePage />
      </Route>
      <Route path="/settings/security" exact>
        <SecurityPage />
      </Route>
      <Route path={['/settings/integrations', '/settings/integrations/:integrationId']}>
        <PaymentIntegrationsPage />
      </Route>            
      <Route path="/settings">
        <Redirect to="/settings/profile" />
      </Route>
    </Switch>
    // <Page title={tl("Личные данные")}>
    //   <GridContainer direction="row-reverse" spacing={2} className={classes.wrap}>
    //     <SettingsNavigationMenu />
    //     <Grid item xs zeroMinWidth className={classes.content}>
    //       <Switch>
    //         <Route path="/settings/profile" exact>
    //           <ProfilePage />
    //         </Route>
    //         <Route path="/settings/security" exact>
    //           <SecurityPage />
    //         </Route>
    //         <Route path={['/settings/integrations', '/settings/integrations/:integrationId']}>
    //           <PaymentIntegrationsPage />
    //         </Route>            
    //         <Route path="/settings">
    //           <Redirect to="/settings/profile" />
    //         </Route>
    //       </Switch>
    //     </Grid>
    //   </GridContainer>
    // </Page>
  );
};
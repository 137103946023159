import { TSplashScreen } from "./SplashScreen";
import { IAdsLayout } from "./AdsLayout";
import { IPlayerState } from "./Player";
import { EUserRole } from ".";

export interface IWebinar {
  id: string;
  type: EWebinarType;
  name: string;
  link: string;
  startPage: IStartPage;
  splashScreen: TSplashScreen;
  source: IWebinarSource;
  presentations: Array<IPresentation>,
  adsLayouts: Array<IAdsLayout>,
  dateSettings: ISingleDateSettings | IRepeatDateSettings | IListDateSettings;
  chatSettings: IChatSettings;
  customSettings: ICustomSettings;
  redirectLink?: string;
  status: EWebinarStatus;
  host?: string;
  startTime?: string;
  stats: IWebinarStats;
  duration: number;
  state?: IPlayerState;
  externalSettings?: IWebinarExternalSettings;
}

type TWebinarExternalSettingsType = 'at';

export interface IWebinarExternalSettings {
  type: TWebinarExternalSettingsType;
  courseId?: number;
  lessonId?: number;
  userId?: string;
}

export interface IWebinarStats {
  id: string;
  members?: number;
  clicks?: number;
  comments?: number;
  allMembers?: number;
  allComments?: number;
  allClicks?: number;
  startDate?: string;
  endDate?: string;
}


export interface IWebinarEvent {
  id: string;
  name: string;
  time: number;
  params: any;
}

export enum EWebinarType {
  WEBINAR = 1,
  AUTOWEBINAR = 2
}

export const webinarTypeDict = {
  [EWebinarType.WEBINAR]: 'Вебинар',
  [EWebinarType.AUTOWEBINAR]: 'Автовебинар',
}

export enum EWebinarStatus {
  ACTIVE = 1,
  DELETED = -1,
  DRAFT = 2,
  OPEN = 3,
  LIVE = 4,
  FINISH = 5,
  PAUSE = 6,
}

export interface IWebinarSource {
  type: EWebinarSourceType;
  src: string;
  videoLink?: string;
  apikey?: string;
  poster?: string;
  time?: number;
}
export enum EWebinarDateType {
  SINGLE = 'single',
  REPEAT = 'repeat',
  LIST = 'list'
}

export interface IDateSettings {
  type: EWebinarDateType
  clientTz?: string
}

export interface ISingleDateSettings extends IDateSettings {
  type: EWebinarDateType.SINGLE,
  dates: Array<Date>,
}

export interface IListDateSettings extends IDateSettings {
  type: EWebinarDateType.LIST,
  dates: Array<Date | null>,
  endDate?: Date
}

export interface IDay {
  enabled: boolean
  time: Date | null
}

export interface IRepeatDateSettings extends IDateSettings {
  type: EWebinarDateType.REPEAT,
  days: Array<IDay>,
  endDate?: Date
}

export interface IMessage {
  value: string
  id: string
}

export interface IChatSettings {
  showMembersCount: boolean,
  membersCount: number,
  hideMembersMessages?: boolean,
  hideInMobile: boolean,
  hideMessagesIfBlocked: boolean,
  appendNewMessageToScenario: boolean,
  moderation: IModerationSettings;
  greetingMessage: string;
  technicalHint: string;
  messages: IMessage[];
  positiveHighPriority: boolean;
}


export const CHAT_BAN_VARIANTS = ['ban', 'close', 'remove'] as const;

type TChatBanActions = typeof CHAT_BAN_VARIANTS[number];

export interface IModerationSettings {
  obscene: boolean;
  negative: boolean;
  phones: boolean;
  links: boolean;
  aiFilter: boolean;
  blackList: {
    enabled: boolean;
    list: string;
  };
  action: TChatBanActions;
  showBlocked: Array<EUserRole>;
}

export interface ICustomSettings {
  trackingAdTags: boolean,
  codeInject: string,
  forecastMembers: number,
  allowFullscreen: boolean
}

export interface ICustomField {
  name: string;
  description: string;
  value?: string;
  required: boolean;
}


export interface IStartPage {
  title: string;
  useNameAsTitle: boolean;
  subTitle: string;
  leader: string;
  contacts: {
    email: boolean;
    phone: boolean;
  };
  contactsRequired: {
    email: boolean;
    phone: boolean;
  };
  customFields: Array<ICustomField>;
  requestPhoneForNotify: boolean;
  openTime: number | null;
  membersLimit?: number;
  facecontrol?: boolean;
  showStream?: boolean;
}

export interface IPresentation {
  id: string;
  name: string;
  isReady: boolean;
  pages: number;
  loading?: boolean;
  processing?: boolean;
}

export enum EWebinarSourceType {
  YOUTUBE = 'youtube',
  VIMEO = 'vimeo',
  BOOMSTREAM = 'boomstream',
  KINESCOPE = 'kinescope',
  RUTUBE = 'rutube',
  VK = 'vk',
}


export const WebinarOpenTime = [
  {
    value: 0,
    label: 'Открыта сразу'
  },
  {
    value: 30,
    label: 'за 30 минут'
  },
  {
    value: 60,
    label: 'за 1 час'
  },
  {
    value: 90,
    label: 'за 1 час 30 минут'
  },
  {
    value: 120,
    label: 'за 2 часа'
  },
  {
    value: 180,
    label: 'за 3 часа'
  },
  {
    value: 360,
    label: 'за 6 часов'
  },
  {
    value: 720,
    label: 'за 12 часов'
  }
];

export enum EWebinarEvents {
  OPEN = 'open',
  START = 'start',
  VIDEO = 'video',
  END = 'end',
  MESSAGE = 'message',
  CHAT = 'chat',
  LAYOUT = 'layout',
  PRESENTATION = 'presentation',
  ADS = 'ads',
  ALERT = 'alert'
}

export interface IWebinarFilter {
  search: string;
  sortBy: string;
  sortOrder: "asc" | "desc";
  filterOptions: Array<string>;
}

import { AppThunk } from "..";
import { IUser } from "../../types";
import { PROFILE_FETCH_ATTEMPT, PROFILE_FETCH_SUCCESS, PROFILE_FETCH_FAILURE, IProfileFetchAttemptAction, IProfileFetchSuccessAction, IProfileFetchFailureAction, PROFILE_UPDATE_ATTEMPT, PROFILE_UPDATE_SUCCESS, PROFILE_UPDATE_FAILURE, IProfileUpdateAttemptAction, IProfileUpdateSuccessAction, IProfileUpdateFailureAction, SECURITY_UPDATE_ATTEMPT, SECURITY_UPDATE_SUCCESS, SECURITY_UPDATE_FAILURE, ISecurityUpdateAttemptAction, ISecurityUpdateSuccessAction, ISecurityUpdateFailureAction, AUTH_LOGOUT, IAuthLogoutAction, UPDATE_BALANCE } from "./types";
import { api } from '../../api';
import { getHardwareInfo, SENTRY_HW_CATEGORY } from "../../services/ErrorLog.service";
import * as Sentry from '@sentry/react';
import { go } from "connected-react-router";
import { wsConnect, wsSubscribe } from "../streamWSMiddleware/actions";


export function profileAttempt(): IProfileFetchAttemptAction {
    return {
        type: PROFILE_FETCH_ATTEMPT
    }
}

export function profileFailure(error: string): IProfileFetchFailureAction {
    return {
        type: PROFILE_FETCH_FAILURE,
        error: error
    }
}

export function profileSuccess(profile: IUser): IProfileFetchSuccessAction {
    return {
        type: PROFILE_FETCH_SUCCESS,
        profile
    }
}

export function profileUpdateAttempt(): IProfileUpdateAttemptAction {
    return {
        type: PROFILE_UPDATE_ATTEMPT
    }
}

export function profileUpdateSuccess(profile: IUser): IProfileUpdateSuccessAction {
    return {
        type: PROFILE_UPDATE_SUCCESS,
        profile
    }
}

export function profileUpdateFailure(error: string): IProfileUpdateFailureAction {
    return {
        type: PROFILE_UPDATE_FAILURE,
        error: error
    }
}

export function securityUpdateAttempt(): ISecurityUpdateAttemptAction {
    return {
        type: SECURITY_UPDATE_ATTEMPT
    }
}

export function securityUpdateSuccess(): ISecurityUpdateSuccessAction {
    return {
        type: SECURITY_UPDATE_SUCCESS
    }
}

export function securityUpdateFailure(securityError: string): ISecurityUpdateFailureAction {
    return {
        type: SECURITY_UPDATE_FAILURE,
        securityError: securityError
    }
}

export function authLogout(): IAuthLogoutAction {
    return {
        type: AUTH_LOGOUT
    }
}

export function updateBalance(balance: number) {
    return {
        type: UPDATE_BALANCE, balance
    }
}

export function profile(): AppThunk {
    return async dispatch => {
        dispatch(profileAttempt())
        try {
            const response = await api.user.profile()
            dispatch(profileSuccess(response));
            const hwInfo = getHardwareInfo();
            Sentry.addBreadcrumb({
                category: SENTRY_HW_CATEGORY,
                message: "device memory: " + hwInfo.deviceMemory,
                level: Sentry.Severity.Info,
            });
            Sentry.addBreadcrumb({
                category: SENTRY_HW_CATEGORY,
                message: "device cpus: " + hwInfo.cpus,
                level: Sentry.Severity.Info,
            });
            Sentry.setUser({
                id: response.id,
                email: response.email
            });
            dispatch(wsConnect(response));
            if(response.author) {
                dispatch(wsSubscribe(`webinar:author-${response.author.id}`));
            } else {
                dispatch(wsSubscribe(`webinar:author-${response.id}`));
            }
        } catch (error: any) {
            dispatch(profileFailure(error))
        }
    }
}

export function initializeProfile(profile: Partial<IUser>): AppThunk {
    return async dispatch => {
        dispatch(profileUpdateAttempt());
        try {
            const response = await api.user.updateProfile(profile);
            dispatch(profileUpdateSuccess(response));
            dispatch(go(-1));
        } catch (error: any) {
            dispatch(profileUpdateFailure(error));
        }
    }
}

export function updateProfile(profile: Partial<IUser>): AppThunk {
    return async dispatch => {
        dispatch(profileUpdateAttempt());
        try {
            const response = await api.user.updateProfile(profile);
            dispatch(profileUpdateSuccess(response));
        } catch (error: any) {
            dispatch(profileUpdateFailure(error));
        }
    }
}

export function updateSecurity(password: string): AppThunk {
    return async dispatch => {
        dispatch(securityUpdateAttempt());
        try {
            const response = await api.user.updateSecurity(password);
            dispatch(securityUpdateSuccess());
        } catch (error: any) {
            dispatch(securityUpdateFailure(error));
        }
    }
}

export function logout(): AppThunk {
    return async dispatch => {
        await api.user.logout();
        window.location.href = `${process.env.REACT_APP_BASE_PATH}`;
    }
}
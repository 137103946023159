import { CHAT_NOTIFICATION_ADD, CHAT_NOTIFICATION_REMOVE, NOTIFICATION_ADD, NOTIFICATION_REMOVE, TNotificationsAction } from "./types";

export type TNotificationType = 'success' | 'info' | 'error' | 'warning'

export interface INotification {
    id: string
    type: TNotificationType,
    message: string
}
export interface IChatNotification {
    id: string
    message: string
}

export interface INotficationsInitialState {
    list: Array<INotification>
    chatList: Array<IChatNotification>
    display: boolean
}

const initialState:INotficationsInitialState = {
    list: [],
    chatList: [],
    display: true
}

export default function notificationsReducer(state=initialState,action:TNotificationsAction) {
    switch (action.type) {
        case NOTIFICATION_ADD:
            return {
                ...state,
                list: [
                    ...state.list,
                    {
                        type: action.notificationType,
                        id: action.id,
                        message: action.message,
                    }
                ]
            }
        case NOTIFICATION_REMOVE: {
            const prevList = state.list.slice()
            const newList = prevList.filter(n=>n.id !== action.id)
            return {
                ...state,
                list: newList
            }
        }   

        case CHAT_NOTIFICATION_ADD: {
            const { message, id } = action

            const newChatMessage = {
                id,
                message,
            }
            return {
                ...state,
                chatList: [
                    newChatMessage
                ] 
            }
        }
        case CHAT_NOTIFICATION_REMOVE: {
            const { id } = action
            const newChatList = state.chatList.slice().filter(n => n.id !== id)
            return {
                ...state,
                chatList: newChatList
            }
        }
        default:
            return state;
    }
}
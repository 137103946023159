import React from 'react';
import { Link } from 'react-router-dom';
import { TIntegration } from '../../../../types';
import { Box, Typography, makeStyles } from "@material-ui/core";
import CloudPaymentsIcon from '../../../Icon/cloudPayments';
import MenuSettingsIcon from '../../../Icon/menuSettings';

const useStyles = makeStyles(theme =>({
  link: {
    flex: "1 1 auto",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    textDecoration: "none",
    color: theme.palette.common.black
  },
  name: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  nameText: {
    fontSize: "13px",
    fontWeight: 600,
  },
  nameIcon: {
    padding: "8px 12px 8px 20px",
  },
  nameIconRoot: {
    width: 40,
    height: 40,
  },
  settingsIcon: {
    color: theme.palette.primary.light,
    padding: "0 20px 0 0",
  }
}));

interface IntegrationCardProps {
  integration: TIntegration;
}

export const IntegrationCard: React.FC<IntegrationCardProps> = ({ integration }) => {
  const classes = useStyles();

  return (
    <Link
      to={`/settings/integrations/${integration.id}`}
      className={classes.link}
    >
      <Box className={classes.name}>
        <CloudPaymentsIcon className={classes.nameIcon} classes={{ root: classes.nameIconRoot }}/>
        <Typography className={classes.nameText}>
          {integration.type}
        </Typography>
      </Box>
      <MenuSettingsIcon className={classes.settingsIcon}/>
    </Link>
  );
};

export default IntegrationCard;
import React, { useState } from "react";
import { Box, Grid, Hidden, Menu, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { themeVariables } from "../../MUI/_Theme";
import { SvgIcon } from "../SvgIcon";
import cx from "classnames";
import { tl } from "../../utils";
import styles from "./styles.module.scss";
import { IUser } from "../../types";
import { Userpic } from "../Userpic";
import Permission from "../../hoc/permission";
import { Link } from "react-router-dom";

interface UserCardHeaderProps {
  user: IUser;
  userNameShort?: boolean;
  hideOnline?: boolean;
  isOnline?: boolean
  handleLogout: ()=>void
};

const useStyles = makeStyles({
  wrap: {
    alignItems: "center",
    flexWrap: "nowrap",
    cursor: "pointer",
  },
  userWidth: {
    [themeVariables.breakpoints.down("sm")]: {
      maxWidth: "250px",
    },
  },
});

export const UserCard:React.FC<UserCardHeaderProps> = ({user,isOnline,hideOnline,handleLogout}) => {
  const classes = useStyles();

  const [anchorEl,setAnchorElement] = useState<HTMLDivElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorElement(event.currentTarget)

  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  const handleSettings = () => {
    window.open(`${process.env.REACT_APP_AT_BASE_PATH}/account/auth?token=${user.sid}&redirect_uri=/account/profile`);
    handleClose();
  };

  const handleExit = () => {
    handleLogout();
    handleClose();
  };

  return (
    <>
      <Box onClick={handleClick}>
        <Grid container className={classes.wrap} spacing={1}>
          <Hidden only="xs">
            <Grid item className={styles.userName}>
              <Box className={cx(styles.hideLong, classes.userWidth)}>
                {user.name? `${user.name} ${user.lastName}` : user.email}
              </Box>
            </Grid>
          </Hidden>
          <Grid item>
            <Userpic src={user.avatar} size="small" />
          </Grid>
          <Hidden only="xs">
            <Grid item>
              <SvgIcon type="webinar-expand" />
            </Grid>
          </Hidden>
        </Grid>
      </Box>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        disableScrollLock
        getContentAnchorEl={null}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        disableAutoFocusItem
      >
        <MenuItem divider component={Link} to="/settings">
          Настройки
        </MenuItem>
        <MenuItem onClick={handleExit}>{tl("Выход")}</MenuItem>
        <Permission entity="statistics">
          <MenuItem component={Link} to="/admin/stats">
            Статистика
          </MenuItem>
        </Permission>

      </Menu>
    </>
  );
};

import * as React from "react";
import {Box, Grid, TextField, Typography,} from "@material-ui/core";
import {MUIAdornment} from "../index";
import {SvgIcon} from "../../components";
import {tl} from "../../utils";
import styles  from "./styles.module.scss";
import {themeVariables} from "../_Theme";
import { SuccessIcon } from "../../components/Icon/success";

export const MUILiveInput = ({onSave, onChange, inactiveView, ...props}: any) => {
  const [isActive, setActive] = React.useState(false);
  const [savedValue, setSavedValue] = React.useState("");
  const [value, setValue] = React.useState("");

  const throttleTO = React.useRef<number>(0);

  React.useEffect(() => {
    clearTimeout(throttleTO.current);
    //@ts-ignore
    throttleTO.current = setTimeout(updateValue, 500);
  }, [value]);

  React.useEffect(() => {
    setSavedValue(props.value || "");
    setValue(props.value || "");
  }, [props.value]);

  const updateValue = () => {
    clearTimeout(throttleTO.current);
  };

  const saveValue = () => {
    setActive(false);
    updateValue();

    if (savedValue !== value) {
      onSave(value);
      setSavedValue(value);
    }
  };

  const resetValue = () => {
    clearTimeout(throttleTO.current);
    if (savedValue !== value) {
      setValue(savedValue);
    }
    setActive(false);
  };

  const onKeyUp = (evt: any) => {
    if (evt.key === "Escape") {
      resetValue();
    } else if (evt.key === "Enter") {
      saveValue();
    }
  };

  return (
    <Box
      className={`MuiLiveInput ${styles.MuiLiveInput} ${
        isActive ? styles.active : ""
      }`}
    >
      {isActive || !value ? (
        <TextField
          className={styles.MuiLiveInputText}
          placeholder={props.placeholder}
          variant="outlined"
          fullWidth
          autoFocus={isActive}
          onBlur={saveValue}
          {...props}
          value={value}
          InputProps={{
            onKeyUp: onKeyUp,
            className: styles.input,
            endAdornment: (
              <MUIAdornment>
                <Box
                  component='button'
                  //@ts-ignore
                  type="reset"
                  aria-label={tl("Сохранить")}
                  onClick={saveValue}
                  display='flex'
                  alignItems='center'
                  px='5px'
                >
                  <SuccessIcon />
                </Box>
              </MUIAdornment>
            ),
          }}
          onChange={(evt: any) => {
            onChange && onChange(evt.target.value);
            setValue(evt.target.value);
          }}
        />
      ) : (
        <Box
          bgcolor={themeVariables.palette.grey.A400}
          className={styles.notActive}
          onClick={() => {
            setActive(true);
          }}
        >
          <Grid container wrap="nowrap" alignItems="center">
            <Grid item xs className={styles.notActiveText}>
              {inactiveView || (
                <Typography variant="subtitle1">{props.value}</Typography>
              )}
            </Grid>
            <Grid item>
              <Box className={styles.notActiveIcon}>
                <SvgIcon type="edit"/>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

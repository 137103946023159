import { IUser } from "../../types"

export const STREAM_AUTHOR_CONNECT = 'STREAM_AUTHOR_CONNECT'
export const STREAM_MEMBER_CONNECT = 'STREAM_MEMBER_CONNECT'
export const CHAT_STATUS = 'CHAT_STATUS'
export const CLOSE_CONNECTION = 'CLOSE_CONNECTION'

export const WS_SUBSCRIBE = 'WS_SUBSCRIBE'
export const WS_CONNECT = 'WS_CONNECT'


interface IStreamConnect {
    webinarLink:string
    user: IUser
}

export interface IWSConnect {
    type: typeof WS_CONNECT;
    channel?: string;
    user: IUser
}

export interface IWSSubscribe {
    type: typeof WS_SUBSCRIBE;
    channel: string;
}
export interface IStreamAuthorConnect extends IStreamConnect {
    type: typeof STREAM_AUTHOR_CONNECT,
    updateOnlineMembers:boolean
}
export interface IStreamMemberConnect extends IStreamConnect {
    type: typeof STREAM_MEMBER_CONNECT
}

export interface ICloseConnectionAction {
    type: typeof CLOSE_CONNECTION;
    channel?: string;
}

export interface IChatStatusAction {
    type: typeof CHAT_STATUS
    webinarLink: string
    status: string
}


export type TStreamConnectActions =
    IWSConnect |
    IWSSubscribe |
    IStreamAuthorConnect |
    IStreamMemberConnect |
    ICloseConnectionAction

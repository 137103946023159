import { IWebinar } from "../../types";
import { SET_REVIEW_WEBINAR, TAppActions } from "./types";


interface IAppInitialState {
  review?: IWebinar;
}

const initialState: IAppInitialState = {
  review: undefined
}

export default function appReducer(state = initialState, action: TAppActions) {
  switch (action.type) {
    case SET_REVIEW_WEBINAR:
      return {
        ...state,
        review: action.webinar
      }

    default:
      return state;
  }
}
import { IMeta } from "../../api/_client"
import { EWebinarType, IWebinar } from "../../types"

export const WEBINARS_FETCH_ATTEMPT = 'WEBINARS_FETCH_ATTEMPT'
export const WEBINARS_FETCH_SUCCESS = 'WEBINARS_FETCH_SUCCESS'
export const WEBINARS_FETCH_FAILURE = 'WEBINARS_FETCH_FAILURE'

export const WEBINARS_LIST_UPDATE = 'WEBINARS_LIST_UPDATE'
export const WEBINARS_LIST_RESET = 'WEBINARS_LIST_RESET'
export const WEBINARS_ITEM_REMOVE = 'WEBINARS_ITEM_REMOVE'

export interface IWebinarsFetchAttemptAction { 
    type: typeof WEBINARS_FETCH_ATTEMPT
    webinarType: EWebinarType
    more?: boolean
}

export interface IWebinarsFetchSuccessAction {
    type: typeof WEBINARS_FETCH_SUCCESS
    list: Array<IWebinar>
    count: number
    offset: number
    meta: IMeta
    webinarType: EWebinarType
}

export interface IWebinarsFetchFailureAction {
    type: typeof WEBINARS_FETCH_FAILURE
    webinarType: EWebinarType
}

export interface IWebinarsListUpdateAction {
    type: typeof WEBINARS_LIST_UPDATE
    webinar: IWebinar
}

export interface IWebinarsListResetAction {
    type: typeof WEBINARS_LIST_RESET
    webinarType: EWebinarType
}

export interface IWebinarsItemRemoveAction {
    type: typeof WEBINARS_ITEM_REMOVE
    webinarId:string
    webinarType: EWebinarType
}


export type TWebinarsActionType = 
    IWebinarsFetchAttemptAction |
    IWebinarsFetchFailureAction | 
    IWebinarsFetchSuccessAction |
    IWebinarsListUpdateAction |
    IWebinarsListResetAction |
    IWebinarsItemRemoveAction

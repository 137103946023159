import KinescopePlayer from "@kinescope/react-kinescope-player";
import { differenceInSeconds } from "date-fns";
import React, { useCallback, useContext, useEffect, useRef } from "react";
import { isMobile } from "react-device-detect";
import useAudioContext from "../../../hooks/useAudioContext";
import useVisibilityChange from "../../../hooks/useVisibilityChange";
import { getKinescopeVideoId } from "../../../utils";
import { PlayButton } from "../PlayButton";
import { VideoPlayerCtx, VideoPlayerProps } from "./factory";

export const KinescopeVideoPlayer: React.FC<VideoPlayerProps> = ({src, live, seekTime, startTime}) => {
  const playerRef = useRef<KinescopePlayer>(null);
  const {isPlaying, setPlaying, setStarted, hasStarted, setMask, handleMask} = useContext(VideoPlayerCtx);
  const {muted, volume, setMuted} = useAudioContext();

  useEffect(() => {
    const player = playerRef.current;
    if (player) {
      player.setVolume(volume / 100);
    }
  }, [volume]);

  useVisibilityChange({
    onHidden: () => {
      setMask(true);
      if (isMobile) {
        setMuted(true);
        setPlaying(false);
      }
    },
    onVisible: () => {
      setMask(true);
      handleMask();
      if (isMobile) {
        setMuted(true);
        playerRef.current?.play().then(() => {
          setPlaying(true)
        })
      }
    },
  });


  const handleError = (e: any) => {
    // console.log(e);
    // playerRef.current?.isPaused().then(res => console.log(res));
    if(['bufferNudgeOnStall', 'bufferStalledError'].includes(e?.error?.details)) {
      playerRef.current?.isPaused().then(res => {
        if (res) {
          setPlaying(false);
        }
      });
    } else {
      setPlaying(false);
    }
  }

  const synchronize = useCallback(() => {
    let streamDuration = differenceInSeconds(Date.now(), startTime || Date.now());
    
    const player = playerRef.current;
    if (player && hasStarted) {
      player.getDuration().then((time) => {
        if (seekTime) {
          streamDuration += seekTime;
        }
        if (live) {
          console.log(time);
          player.seekTo(time).catch(e => console.log(e));
        } else {
          console.log(streamDuration);
          player.seekTo(streamDuration).catch(e => console.log(e));
        }
      }).catch(e => console.warn(e));

    }
  }, [hasStarted, live, seekTime, startTime])

  useEffect(() => {
    synchronize();
    const id = setInterval(synchronize, 5 * 60 * 1000); // every 5 minutes
    return () => clearInterval(id);
  }, [synchronize, isPlaying]);

  const handlePlay = () => {
    const player = playerRef.current;
    if (player) {
      console.log('play reinitiated', new Date().toString());
      player.play()
        .then(() => {
          handleMask();
          setPlaying(true);
        })
        .catch(() => {
          setPlaying(false);
        })
    }
  }

  return (
    <div style={{width: '100%', height: '100%', position: 'absolute', top: 0, left: 0}}>
      <KinescopePlayer 
        autoPlay={true} 
        controls={false} 
        onReady={(data) => {
          setStarted(true)
        }}
        muted={muted}
        ref={playerRef}  
        onPlay={handlePlay}
        onEnded={() => {
          if (live) {
            handlePlay();
          }
        }}
        onPause={() => {
          setPlaying(false);
        }}
        onError={handleError}
        playsInline={true} 
        videoId={getKinescopeVideoId(src)} 
        width="100%" 
        height="100%"

      />
      {hasStarted && !isPlaying && <PlayButton onClick={handlePlay} />}
    </div>
  )
};
import * as React from "react";
import {Box, BoxProps, Button, Grid, Paper, PaperProps, makeStyles, useTheme} from "@material-ui/core";
import {MUISticky, themeVariables} from "../index";
// import {cssHidden} from "../../utils";
import cssHidden from '../_Theme.scss';
import styles  from "./styles.module.scss";

const useStyles = makeStyles({
  Paper: {
    height: "100%",
    marginTop: (props: IProps) => props.panelBlock
      ? `-${themeVariables.spacing * 2 + 1}px`
      : undefined,
    border: (props: IProps) => props.noBorder ? "none" : themeVariables.borderOuter,
    borderTopColor: (props: IProps) => props.panelBlock ? themeVariables.palette.border : undefined,
    borderRadius: themeVariables.blockRadius,
    boxShadow: "none",
    ".stackedPanel &": {
      borderRadius: `${themeVariables.blockRadius}px ${themeVariables.blockRadius}px 0 0`,
    },
    backgroundColor: (props: IProps) => props.noBgColor ? "unset" : themeVariables.palette.common.white,
    [themeVariables.breakpoints.down("sm")]: {
      backgroundColor: (props: IProps) => props.noBgColorOnMobile ? "initial" : undefined,
    },
  },
});
interface IProps extends BoxProps {
  pInner?: number | object;
  data?: PaperProps;
  head?: any;
  headControls?: any;
  headPadding?: number | string;
  controls?: any;
  controlsCollapsed?: any;
  controlsDivider?: boolean;
  controlsUnsticky?: boolean;
  noBgColorOnMobile?: boolean;
  noBgColor?: boolean;
  noBorder?: boolean;
  wideControlsMobile?: boolean;
  panelBlock?: boolean;
}

export const MUIBlock = ({
    pInner,
    data,
    head,
    headControls,
    controls,
    controlsCollapsed,
    controlsDivider,
    controlsUnsticky,
    noBgColorOnMobile,
    wideControlsMobile,
    panelBlock,
    headPadding = 2,
    ...boxProps
  }: IProps) => {
  const theme = useTheme();

  const classes = useStyles({
    pInner,
    data,
    head,
    headControls,
    controls,
    controlsCollapsed,
    controlsDivider,
    controlsUnsticky,
    noBgColorOnMobile,
    wideControlsMobile,
    panelBlock,
    ...boxProps
  });

  if (!pInner && pInner !== 0) {
    pInner = 2;
  }

  return (
    <Box {...boxProps}>
      <Paper className={`${classes.Paper} ${styles.Paper}`} {...data}>
        <Box flex="1">
          {head ? (
            <Box
              p={headPadding}
              bgcolor={theme.palette.common.white}
              borderBottom={
                !!boxProps.children ? themeVariables.border : undefined
              }
              borderRadius={
                themeVariables.blockRadius
                  ? `${themeVariables.blockRadius}px ${themeVariables.blockRadius}px 0 0 `
                  : undefined
              }
              className={styles.head}
            >
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm>
                  <Box
                    clone
                    fontWeight={600}
                    color={themeVariables.palette.grey[50]}
                  >
                    {head}
                  </Box>
                </Grid>
                {headControls ? (
                  <Grid item xs={12} sm="auto">
                    <Box my={-1}>{headControls}</Box>
                  </Grid>
                ) : null}
              </Grid>
            </Box>
          ) : null}
          {!!boxProps.children ? (
            <Box p={pInner} className={!head ? `noheadMUIBlock` : undefined}>
              {boxProps.children}
            </Box>
          ) : null}
          {controls ? (
            <MUIBlockControls
              divider={controlsDivider && !!boxProps.children}
              controls={controls}
              controlsCollapsed={controlsCollapsed}
              controlsUnsticky={controlsUnsticky}
              wideControlsMobile={wideControlsMobile}
            />
          ) : null}
        </Box>
      </Paper>
    </Box>
  );
};

interface IInnerProps extends BoxProps {
  pInner?: number;
}

export const MUIBlockSubhead = ({
                                  pInner,
                                  children,
                                  ...boxProps
                                }: IInnerProps) => {
  if (!pInner && pInner !== 0) {
    pInner = 2;
  }

  return (
    <Box
      mx={-pInner}
      mt="-1px"
      p={pInner}
      borderTop={themeVariables.border}
      borderBottom={themeVariables.border}
      {...boxProps}
    >
      {children}
    </Box>
  );
};



export const MUIBlockDivider = (props: BoxProps) => {
  return <Box my={2} borderTop={themeVariables.border} {...props} />;
};

const useMUIBlockControlsStyles = makeStyles((theme) => ({
  Sticky: {
    ".stacked &": {
      borderRadius: 0,
    },
  }, //
  navSection: {
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: (props: any) =>  props.wideControlsMobile
      ? {
        justifyContent: "stretch",
        "& > *": {
          flexGrow: 1,
        },
        flexWrap: "wrap",
      }
      : {},
    [theme.breakpoints.up("sm")]: {
      flexWrap: "nowrap",
    },
  },
}))

export const MUIBlockControls = ({
                                   controls,
                                   divider,
                                   controlsCollapsed,
                                   controlsUnsticky,
                                   wideControlsMobile,
                                 }: {
  controls: IProps["controls"];
  controlsCollapsed?: IProps["controlsCollapsed"];
  controlsUnsticky?: IProps["controlsUnsticky"];
  divider?: boolean;
  wideControlsMobile?: IProps["wideControlsMobile"];
}) => {
  const theme = useTheme();
  const classes = useMUIBlockControlsStyles({wideControlsMobile});
  const controlsMain = (
    <Grid
      container
      spacing={1}
      alignItems="center"
      className={classes.navSection}
    >
      {controls.map((controlsGroup: any, idx: number) => (
        <Grid
          item
          xs="auto"
          key={`controlsGroup-${idx}`}
          className={!controlsGroup.length ? cssHidden.onlyXs : undefined}
        >
          <Grid
            container
            spacing={1}
            alignItems="center"
            className={classes.navSection}
          >
            {controlsGroup.map((control: any, i: number) => {
              if (control) {
                const {label, ...controlProps} = control;
                return (
                  <Grid
                    item
                    xs={wideControlsMobile ? 12 : "auto"}
                    sm="auto"
                    key={`controlsItem-${idx}-${i}`}
                  >
                    {control["$$typeof"] ? (
                      <Box clone>{control}</Box>
                    ) : (
                      <Button fullWidth {...controlProps}>
                        {label}
                      </Button>
                    )}
                  </Grid>
                );
              }
            })}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
  const controlsInner = (
    <Box
      px={2}
      py={1.5}
      borderTop={divider ? `1px solid ${theme.palette.grey[300]}` : undefined}
      bgcolor={theme.palette.common.white}
      borderRadius={
        theme.shape.borderRadius
          ? `0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`
          : undefined
      }
      className={`${classes.Sticky} ${styles.Sticky}`}
    >
      {controlsCollapsed ? (
        <Grid container alignItems="center">
          <Grid item xs={12}>
            {controlsCollapsed}
          </Grid>
          <Grid item xs={12}>
            {controlsMain}
          </Grid>
        </Grid>
      ) : (
        controlsMain
      )}
    </Box>
  );

  return controlsUnsticky ? (
    controlsInner
  ) : (
    <MUISticky
      mode="bottom"
      stickyClassName={"stacked"}
    >
      {controlsInner}
    </MUISticky>
  );
};

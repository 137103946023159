import { IModerator } from '../../types';

export const MODERATORS_FETCH_ATTEMPT = 'MODERATORS_FETCH_ATTEMPT';
export const MODERATORS_FETCH_SUCCESS = 'MODERATORS_FETCH_SUCCESS';
export const MODERATOR_REMOVE = 'MODERATOR_REMOVE';

export interface IModeratorsFetchAttemptAction {
  type: typeof MODERATORS_FETCH_ATTEMPT;
}

export interface IModeratorsFetchSuccessAction {
  type: typeof MODERATORS_FETCH_SUCCESS;
  list: IModerator[];
  count: number;
}

export interface IRemoveModeratorAction {
  type: typeof MODERATOR_REMOVE;
  moderator: IModerator;
}

export type TModeratorsAction =
  | IModeratorsFetchAttemptAction
  | IModeratorsFetchSuccessAction
  | IRemoveModeratorAction;

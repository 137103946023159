import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import SmartCurrencyIcon from '../Icon/smartCurrency';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: (props: SmartCounterProps) => props.amount < 200 ? '#FCEDEC' : 'transparent',
    color: (props: SmartCounterProps) => props.amount < 200 ? '#E35248' : '#000',
    position: 'relative',
    borderRadius: 15,
    height: 24,
    paddingRight: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  currencyIcon: {
    width: 26,
    height: 26,
    position: 'absolute',
    left: 0,
   },
   text: {
    marginLeft: 32,
    fontSize: 13,
    lineHeight: '15px',
    fontWeight: (props: SmartCounterProps) => props.amount < 200 ? 500 : 400,

   }
}))

interface SmartCounterProps {
  amount: number;
}

export const SmartCounter: React.FC<SmartCounterProps> = ({
  amount
}) => {
  const classes = useStyles({amount})
  return (
    <Box className={classes.root}>
      <SmartCurrencyIcon className={classes.currencyIcon} />
      <Typography className={classes.text}>{Intl.NumberFormat('ru-RU').format(amount)}</Typography>
    </Box>
  );
}
import React, { useRef, useEffect, useState, forwardRef } from 'react';
import { useDispatch } from 'react-redux';
import { api } from '../../api';
import { themeDefault } from '../../MUI';
import { addNotification } from '../../store/notifications/actions';
import { EUserRole, IChatConfig, IChatMessage, IUser } from '../../types';

interface IChatToolbarItemStatus {
  command: 'status';
  callback: (status: string) => void;
}

interface IChatToolbarItemList {
  command: 'list';
  icon: string;
  title: string;
  list: Array<{
    id: string;
    value: string;
  }>;
  callback: (id: string) => void;
  extraButton?: {
    title: string;
    callback: (id: string) => void;
  };
}

interface IChatToolbarItemLink {
  command: 'button';
  icon: string;
  tooltip?: string;
  callback: () => void;
}

type IChatToolbarItem =
  | IChatToolbarItemLink
  | IChatToolbarItemList
  | IChatToolbarItemStatus;

interface UseChatProps {
  children: React.ReactNode;
  room: string;
  user: Partial<IUser>;
  className: string;
  onMessage?: (message: IChatMessage) => void;
  hideMessagesIfBlocked?: boolean;
  hideRoles: Array<string>;
  overlayRoot?: string;
  membersOnline: number;
  onHighLoad?: () => void;
  onBlocked?: () => void;
  onTechnicalIssue?: (message?: IChatMessage) => void;
  blockedMessagesRecipients: Array<EUserRole>;
  toolbar: Array<IChatToolbarItem>;
}

const HIGHLOAD_LIMIT = 1000;

let initAttempts = 0;

const getChatWidgetLink = () => {
  if (process.env.REACT_APP_TEMP_ENV) {
    const latestBranch = window.localStorage.getItem('chat_widget_link');
    if (latestBranch) return JSON.parse(latestBranch) + 'chat.js';
  }
  return process.env.REACT_APP_CHAT_WIDGET_LINK!;
};

export const Chat = forwardRef<HTMLDivElement, UseChatProps>((props, ref) => {
  const [config, setConfig] = useState<IChatConfig>();
  const [init, setInit] = useState(false);
  const timeoutRef = useRef(0);
  const dispatch = useDispatch();
  const chatRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const script = document.createElement('script');
    script.src = getChatWidgetLink();
    script.async = true;
    document.body.appendChild(script);

    return () => script.remove();
  }, []);


  useEffect(() => {
    let timeout = 0;
    let attempts = 1;

    if (props.user) {
      if (props.user.chatConfig) {
        setConfig(props.user.chatConfig);
      } else if (props.user.id) {
        const getChatConfig = (room: string, userId: string) => {
          return api.chat
            .get(room, userId)
            .then((data) => {
              setConfig(data);
              attempts = 0;
              window.clearTimeout(timeout);
            })
            .catch((error) => {
              if (
                [429, 500, 501, 502, 503, 504].includes(error.response.status)
              ) {
                clearTimeout(timeout);
                timeout = window.setTimeout(() => {
                  getChatConfig(room, userId);
                  attempts = attempts + 1;
                }, attempts * (2500 + Math.floor(Math.random() * 1500)));
                return;
              }
            });
        };
        getChatConfig(props.room, props.user.id);
      }
    }
  }, [props.room, props.user]);

  useEffect(() => {
    const tryInit = (nodeId: string) => {
      window.clearTimeout(timeoutRef.current);
      initAttempts += 1;
      if (initAttempts > 60) {
        return;
      }
      if (typeof (window as any).ChatApp === 'undefined') {
        timeoutRef.current = window.setTimeout(
          () => tryInit(nodeId),
          500 * initAttempts
        );
      } else {
        try {
          (window as any).ChatApp.default({
            ...config,
            onMessage: props.onMessage,
            nodeid: nodeId,
            hideMessageWhenDisabled: props.hideMessagesIfBlocked,
            theme: themeDefault,
            hideRoles: props.hideRoles,
            overlayRoot: props.overlayRoot,
            highLoad: props.membersOnline > HIGHLOAD_LIMIT,
            onBlocked: props.onBlocked,
            blockedMessagesRecipients: props.blockedMessagesRecipients,
            toolbar: props.toolbar,
            onTechnicalIssue: props.onTechnicalIssue,
          });
          setInit(true);
        } catch (e) {
          dispatch(addNotification('error', 'Ошибка инициализации чата'));
        }
      }
    };
    if (
      (chatRef).current &&
      config &&
      !init
    ) {
      tryInit((chatRef).current.id);
    }

    return () => {
      window.clearTimeout(timeoutRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    config,
    (chatRef).current,
    props.overlayRoot,
  ]);

  useEffect(() => {
    const chatApp = (window as any).ChatApp;
    if (
      chatApp &&
      (ref as React.MutableRefObject<HTMLDivElement>).current &&
      props.membersOnline > HIGHLOAD_LIMIT
    ) {
      const result = chatApp.setHighLoad(true);
      if (result && props.onHighLoad) {
        props.onHighLoad();
      }
    }
  }, [ref, props.membersOnline, props.onHighLoad, props]);

  useEffect(() => {
    const chatApp = (window as any).ChatApp;
    if (chatApp && (ref as React.MutableRefObject<HTMLDivElement>).current) {
      chatApp.updateToolbar(props.toolbar);
    }
  }, [ref, props.toolbar]);

  return (
      
     
      <div ref={ref}  className={props.className}>
  <div
      data-channel={props.room}
      id={`chat-${props.room}`}
      ref={chatRef}
      style={{width: '100%', height: '100%'}}
    >
      {props.children}
    </div>
    </div>
  
  );
});

import {IChatSettings, ICustomSettings, IPresentation, IStartPage, IWebinarSource} from "./Webinar";
import {IAdsLayout} from "./AdsLayout";
import {TSplashScreen} from "./SplashScreen";

export interface IPlayer {
  id:string;
  name: string;
  link:string;
  startPage?: IStartPage;
  splashScreen?: TSplashScreen;
  source: IWebinarSource;
  presentations?:Array<IPresentation>;
  chatSettings: IChatSettings;
  customSettings: ICustomSettings;
  redirectLink?: string;
  cntMembers?: number;
  isActive:boolean;
  isFinished:boolean;
  isOpen: boolean;
  startTime:string;
  type: number;
  signature?:string;
  state:IPlayerState
}

export interface IPlayerLayout {
  type:string;
  icon:string;
  label:string;
}

export type TStreamLayout = 'video' | 'video-bl' | 'video-br' | 'video-tl' | 'video-tr' | 'presentation' | 'side-by-side-left' | 'side-by-side-right'

type TLayoutHintDict = {
  [key in TStreamLayout]: string
}


export const layoutHintDict: TLayoutHintDict = {
  'presentation': 'Только презентация',
  'video': 'Только видео',
  'video-tr': 'Видео справа вверху',
  'video-tl': 'Видео слева вверху',
  'video-bl': 'Видео слева внизу',
  'video-br': 'Видео справа внизу',
  'side-by-side-left': 'Видео перекрывает презентацию слева',
  'side-by-side-right': 'Видео перекрывает презентацию справа'
}

export interface IPlayerState {
  layout: TStreamLayout;
  presentation?: {
    presentation: IPresentation;
    presentationPage?: number;
  };
  start?: {
    layout: string;
  }
  video: IWebinarSource & {time?: number};
  adsLayout:IAdsLayout;
  ads: Array<IAdsLayout>;
  isFinished?: boolean;
  membersOnline?: number;
  membersAvailable?: number;
}
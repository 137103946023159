import React from "react";
import {Box, Grid, Typography, makeStyles} from "@material-ui/core";
import {makeLineClamp, MUIBlock, MUILiveInput,} from "../../../MUI";
import {tl} from "../../../utils";
import { EWebinarType, IWebinar } from "../../../types";
import WebinarStatus from "../Status";

const useStyles = makeStyles((theme)=>({
  webinarName: {
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(1),
    },
  },
  liveInputWrap: {
    width: "100%",
    paddingLeft: "10px",
    paddingRight: "25px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
    },
  },
  text: {
    fontFamily: "SF UI Text, Arial, Helvetica, sans-serif",
    fontSize: 17,
    fontWeight: 600,
    '&>span': {
      color: theme.palette.grey[600]
    },
    ...makeLineClamp(2, 1.25)
  }
}));
interface WebinarNameProps {
  webinar: IWebinar;
  editable?: boolean;
  onChange?: (name: string) => void;
}

export const WebinarName:React.FC<WebinarNameProps> = ({editable, webinar, onChange}) => {
  const classes = useStyles();

  return (
    <MUIBlock
      mb={1.25}
      m="-1px"
      data={{style: {overflow: "hidden"}}}
    >
      <Grid container wrap="nowrap" className={classes.webinarName}>
        <WebinarStatus webinar={webinar} />
        
          
          {editable 
            ? <Box className={classes.liveInputWrap}>
                <MUILiveInput
                  placeholder={tl("Введите название вебинара")}
                  value={webinar.name}
                  onSave={(value: string) => {
                    onChange && onChange(value);
                  }}
                />
              </Box>
            : <Box height="100%" ml={1.5}>
                <Typography className={classes.text}>
                  <span>{webinar.type === EWebinarType.AUTOWEBINAR ? 'Автовебинар:': 'Вебинар:'}</span> {webinar.name}
                </Typography>
              </Box>
          }
      </Grid>
    </MUIBlock>
  );
}
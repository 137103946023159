import { IPlan, IPlanMeta } from "../../types/Billing";
import { PLANS_FETCH_ATTEMPT, PLANS_FETCH_ERROR, PLANS_FETCH_SUCCESS, GET_PAYLINK, TPlansAction } from "./types";


interface IPlansIntitalState {
  loading: boolean;
  error?: boolean;
  list: Array<IPlan>;
  meta?: IPlanMeta;
  isPaying: boolean;

}


const initialState: IPlansIntitalState = {
  loading: true,
  list: [],
  isPaying: false,
}


export default function plansReducer(state = initialState, action: TPlansAction) {
  switch (action.type) {
    case GET_PAYLINK:
      return {
        ...state,
        isPaying: action.isPaying,
      }
    case PLANS_FETCH_ATTEMPT:
      return {
        ...state,
        error: false,
        loading: true
      }
    case PLANS_FETCH_ERROR:
      return {
        ...state,
        error: true,
        loading: false
      }
    case PLANS_FETCH_SUCCESS:
      return {
        ...state,
        list: action.list,
        meta: action.meta,
        error: false,
        loading: false
      }
    default:
      return state;
  }
}
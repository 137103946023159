import { Box, Button, Grid, styled, TextField, Typography, makeStyles } from '@material-ui/core';
import React, { useRef, useState } from 'react'
import { MUIDialog, MUIDialogProps } from '../../MUI';
import { EWebinarType } from '../../types';
import { tl } from '../../utils';
import CloseIcon from '../Icon/close';
import { PlayIconHintLink } from '../Icon/PlayIconHintLink';
import { PlusIcon } from '../Icon/PlusIcon';
import SWLoader from '../Icon/swLoader';


const useStyles = makeStyles((theme) => ({
  content: {
    width: 542,
    maxWidth: 542,
  },
  text: {
    font: 'normal normal normal 13px/15px SF UI Text',
    whiteSpace: 'nowrap'
  },
  dialogContent: {
    padding: '20px 28px',
  },
  hintText: {
    font: 'normal normal normal 13px/15px SF UI Text',
    marginRight: 18,
    color: '#6d6d6d',
  },
  nameRow: {
    marginBottom: theme.spacing(3),
  },
}));

const FileBtn = styled('button')(({theme}) => ({
  height: 17,
  font: 'normal normal 13px/16px SF UI Text',
  padding: '0 4px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  border: 'none',
  outline: 'none',
  cursor: 'pointer',
  borderRadius: 2,
  //@ts-ignore
  backgroundColor: theme.palette.default.main,
  //@ts-ignore
  color: theme.palette.default.contrastText,
  '& svg': {
    marginLeft: 4,
    marginTop: 1,
    color: theme.palette.primary.light,
  }
}))


interface NewAutowebinarModalCommonProps extends MUIDialogProps {
  onClose: () => void;
  hideNameInput?: boolean;
  loading?: boolean;
}

interface NewAutowebinarModalFormProps extends NewAutowebinarModalCommonProps {
  hideNameInput?: false;
  onClose: () => void;
  handleSubmit: (form: HTMLFormElement) => void;
}
interface NewAutwebinarOnlyScenarioFileProps extends NewAutowebinarModalCommonProps {
  hideNameInput?: true;
  handleSubmit: (scenarioFile: File) => void;
  onClose: () => void;
}

export type NewAutowebinarModalProps = NewAutowebinarModalFormProps | NewAutwebinarOnlyScenarioFileProps;


export const NewAutowebinarModal: React.FC<NewAutowebinarModalProps> = ({ 
  open, onClose, hideNameInput, handleSubmit, loading = false
}) => {
  const classes = useStyles();
  const [name, setName] = useState('');
  const uploadRef = useRef<HTMLInputElement>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const [file, setFile] = useState<File | undefined>()
  const handleUploadChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files) {
      setFile(e.currentTarget.files[0]);
    }
  }

  const resetFileUpload = () => {
    if (uploadRef.current) {
      setFile(undefined);
      uploadRef.current.value = '';
    }
  }

  return (
    <MUIDialog
      title={tl(`Перенос Автовебинара из Бизон`)}
      open={open}
      classes={{
        paper: classes.content,
      }}
      dialogContentClassName={classes.dialogContent}
      mobileFullScreen
      onClose={onClose}
      actions={[
        [],
        [
          {
            label: tl("Отмена"),
            btnProps: {
              onClick: () => {
                onClose();
              },
            },
          },
          {
            label: loading ? <Box width="140px" display="flex" alignItems="center" justifyContent="center"><SWLoader color="primary" fontSize="small" /></Box> : hideNameInput ? tl('Загрузить сценарий') : tl(`Создать Автовебинар`),
            btnProps: {
              color: "primary",
              disabled: loading || !hideNameInput ?  (!name || !file) :  !file,
              'data-testid': "createAutowebModalBtn",
              onClick: () => {
                if (hideNameInput && file) {
                  //@ts-ignore
                  handleSubmit(file);
                } else if (!hideNameInput) {
                  //@ts-ignore
                  formRef.current && handleSubmit(formRef.current);
                }
              },
            },
          },
        ],
      ]}
    >
      <form onSubmit={(e) => {
        e.preventDefault();
      }} ref={formRef}>
        <Grid item container direction="column">
        <input type="hidden" name="type" value={EWebinarType.AUTOWEBINAR} />
        <Grid container direction="row" wrap="nowrap" justify="center" alignItems="center">
          <Grid item container xs justify="flex-end">
            <Box mr="10px">
              <Typography className={classes.text}>
                {tl(`Сценарий автовебинара:`)}
              </Typography>
            </Box>
          </Grid>
          
          <Grid container item style={{width: 316}} alignItems="flex-end">
            <Box height="30px" display="flex" alignItems="center">
              {file ? 
                <FileBtn>
                  {file.name}
                  <CloseIcon style={{fontSize: 12}} onClick={resetFileUpload} />
                </FileBtn>:
                <Button 
                variant="contained" 
                startIcon={<PlusIcon />} 
                onClick={() => {
                  uploadRef.current?.click();
                }}
                >
                {tl('Загрузить файл')}
              </Button>
              }
            </Box>
            <input style={{position: 'absolute', top: -1000}} onChange={handleUploadChange} type="file" name="scenario" id="scenario" ref={uploadRef} />
          </Grid>
        </Grid>
        <Grid container direction="row" wrap="nowrap" justify="center" alignItems="center" >
          <Grid item container xs justify="flex-end">
            <Box mr="10px" mt={"3px"}>
              <Typography className={classes.text} />
            </Box>
          </Grid>
          <Grid item style={{width: 316}}>
            <Box mt={2}>
              <Grid container item direction="row" alignItems="center">
                <Typography className={classes.hintText}>Где взять файл?</Typography>
                <PlayIconHintLink href="https://www.youtube.com/watch?v=yA_WlHNiGJ0" rel="noreferrer" target="_blank" >Бизон</PlayIconHintLink>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        {!hideNameInput && 
          <Grid container direction="row" wrap="nowrap" className={classes.nameRow} data-testid="autoweb_name_textfield">
            <Grid item container xs justify="flex-end">
              <Box mr="10px" mt={"3px"}>
                <Typography className={classes.text}>
                  {tl(`Название Автовебинара:`)}
                </Typography>
              </Box>
            </Grid>
            <Grid item style={{width: 316}} data-testid="autoweb_name_textfield">
              <TextField
                variant="outlined"
                fullWidth
                autoFocus
                multiline
                name="name"
                style={{height: 55}}
                rows={2}
                rowsMax={3}
                inputProps={{
                  maxLength: 95,
                }}
                value={name}
                placeholder={tl(`Введите название Автовебинара`)}
                onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
                  setName(evt.target.value);
                }}
              />
            </Grid>
          </Grid>
        }

        </Grid>
      </form>
    </MUIDialog>
  )
}
import * as React from "react";
import {Grid, IconButton, MenuItem, styled, Typography} from "@material-ui/core";
import { makeStyles,useTheme } from "@material-ui/core/styles";
import {MUILiveInput, MUISelect, themeVariables} from "../../MUI";
import {tl} from "../../utils";
import {IWebinar, IWebinarEvent, EWebinarEvents, WebinarOpenTime} from "../../types";
import {WebinarEventSimple} from "./simple";
import {WebinarEventVideo} from "./video";
import {WebinarEventPresentation} from "./presentation";
import {WebinarEventLayout} from "./layout";
import {WebinarEventEnd} from "./end";
import {WebinarEventAds} from "./ads";
import { SvgIcon } from "..";
import { WebinarEventChat } from "./chat";
import { WebinarEventMessage } from "./message";
import {useCallback, useEffect, useState} from "react";
import { isArray } from "lodash";
import { WebinarEventAlert } from "./alert";

interface WebinarEventProps {
  webinar:IWebinar;
  event: IWebinarEvent;
  editable: boolean;
  minTime?: number;
  maxTime?: number;
  onChange?: (event:IWebinarEvent) => void;
  onDelete?: (event:IWebinarEvent) => void;
}

export const useWebinarEventStyles = makeStyles(theme => ({
  webinarEvent: {
    alignItems: "start",
    padding: "5px 0",
    maxWidth: "calc(100% - 40px)",
    margin: "0 20px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: themeVariables.spacing,
    },

    "&:not(:last-child)": {
      borderBottom: themeVariables.border,
    },
  },

  deleteButton: {
    color: "#E35248",
  },
}));

const TimeView = styled(Typography)({
  lineHeight: "30px",
});

const rawTimeRegex = /^(-)?(?:(?:([0-9]?\d):)?([0-5]?\d):)?([0-5]?\d)$/
const hasZero = (time: number) => time < 10 ? "0" : "";


const convertSecondsToRawTime = (seconds: number) => {
  const time = Math.abs(seconds)
  if (time === 0) {
    return "00:00:00";
  }

  const h = Math.floor(time / 3600);
  const m = Math.floor(time / 60 - (h * 60));
  const s = time - (h * 3600) - (m * 60);

  return `${seconds < 0 ? "-" : ""}${`${hasZero(h)}${h}:`}${hasZero(m)}${m}:${hasZero(s)}${s}`;
};

export const WebinarEventComponent = (props: WebinarEventProps) => {
  const theme = useTheme();
  const classes = useWebinarEventStyles(theme);

  const [rawTime, setRawTime] = useState(convertSecondsToRawTime(props.event.time));

  useEffect(()=>{
    setRawTime(convertSecondsToRawTime(props.event.time));
  }, [props.event.time]);

  const prettifyRawTime = (rawTime: string) => {
    const trimmedTime = rawTime.replace(/\s/g, "");

    if (trimmedTime === "00:00" || trimmedTime === "00:00:00") return "0 сек:";

    //@ts-ignore
    const isNegative = !!trimmedTime.match(rawTimeRegex)[1];
    //@ts-ignore
    const h = Number(trimmedTime.match(rawTimeRegex)[2]);
    //@ts-ignore
    const m = Number(trimmedTime.match(rawTimeRegex)[3]);
    //@ts-ignore
    const s = Number(trimmedTime.match(rawTimeRegex)[4]);



    return (
      `
        ${isNegative ? "-" : ""}
        ${h && h !== 0 ? `${h} ${tl("ч")} ` : ""}
        ${m && m !== 0 ? `${m} ${tl("мин")} ` : ""}
        ${s !== 0 ? `${s} ${tl("сек")}` : ""}
      `
    );
  };

  const convertRawTimeToSeconds = (time:string):number => {
    const trimmedTime = time.replace(/\s/g, "");


    if (trimmedTime === "00:00" || trimmedTime === "00:00:00") return 0;

    //@ts-ignore
    const isNegative = !!trimmedTime.match(rawTimeRegex)[1];
    //@ts-ignore
    const h = Number(trimmedTime.match(rawTimeRegex)[2]) || 0;
    //@ts-ignore
    const m = Number(trimmedTime.match(rawTimeRegex)[3]) || 0;
    //@ts-ignore
    const s = Number(trimmedTime.match(rawTimeRegex)[4]) || 0;

    return (h * 3600 + m * 60 + s) * (isNegative ? -1 : 1);
  };

  const saveEvent = (params: any, time?: string) => {
    if(props.onChange) {
      props.onChange({
        ...props.event,
        time: convertRawTimeToSeconds(time || rawTime),
        params,
      });
    }
  }

  const handleTimeSave = (rawValue: string) => {
    if (rawTimeRegex.test(rawValue.replace(/\s/g, ""))) {
      const rawSeconds = convertRawTimeToSeconds(rawValue);
      let correctRawTime = rawValue;

      if (props.minTime && rawSeconds < props.minTime) {
        correctRawTime = convertSecondsToRawTime(props.minTime);
      }

      if (props.event.name !== 'end' && props.maxTime && rawSeconds > props.maxTime) {
        correctRawTime = convertSecondsToRawTime(props.maxTime);
      }
      setRawTime(correctRawTime);
      saveEvent(props.event.params, correctRawTime);
    }
  };

  const onDeleteEvent = useCallback(()=>{
    if(props.onDelete) {
      props.onDelete(props.event);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.event])

  const timeView = (
    <TimeView>
      <b>{prettifyRawTime(rawTime)}</b>
    </TimeView>
  );

  const renderBody = (event: IWebinarEvent) => {
    switch (event.name) {
      case EWebinarEvents.START:
        return (<WebinarEventSimple
          name={tl("Начало трансляции.")}
        />);
      case EWebinarEvents.OPEN:
        return (<WebinarEventSimple
          name={tl("Открытие вебинарной комнаты.")}
        />);
      case EWebinarEvents.END:
        return (<WebinarEventEnd
          editable={props.editable}
          link={isArray(event.params) ? '' : event.params}
          onChange={saveEvent}
        />);
      case EWebinarEvents.LAYOUT:
        return (<WebinarEventLayout
          editable={props.editable}
          layout={event.params}
          onChange={saveEvent}
        />);
      case EWebinarEvents.VIDEO:
        return (<WebinarEventVideo
          editable={props.editable}
          video={event.params ?? {
            src: '',
            time: 0,
            type: '',
          }}
          onChange={saveEvent}

        />);
      case EWebinarEvents.ADS:
        return (<WebinarEventAds
          editable={props.editable}
          adsLayout={Array.isArray(event.params) ? event.params : [event.params]}
          onChange={saveEvent}
        />);
      case EWebinarEvents.PRESENTATION:
        return (<WebinarEventPresentation
          webinar={props.webinar}
          editable={props.editable}
          presentation={event.params.presentation}
          presentationPage={event.params.presentationPage}
          onChange={saveEvent}
        />);
      case EWebinarEvents.CHAT:
        return (<WebinarEventChat
          editable={props.editable}
          status={event.params.status}
          onChange={saveEvent}
        />);
      case EWebinarEvents.MESSAGE:
        return (<WebinarEventMessage
          editable={props.editable}
          content={event.params.content}
          author={event.params.author}
          role={event.params.role}
          onChange={saveEvent}
        />);
      case EWebinarEvents.ALERT:
        const messages = props.webinar.chatSettings.messages || []

        return (
          <WebinarEventAlert
            editable={props.editable}
            content={event.params.content}
            author={event.params.author}
            role={event.params.role}
            messages={messages}
            currentMessage={props.event.params}
            webinarId={props.webinar.id}
            onChange={saveEvent}
          />);
      default:
        return (<WebinarEventSimple
          name={event.name}
        />);
    }
  };

  const renderDeleteButton = (event: IWebinarEvent) => {
    switch (event.name) {
      case EWebinarEvents.START:
      case EWebinarEvents.OPEN:
      case EWebinarEvents.END:
      case EWebinarEvents.VIDEO:
        return null;
      default:
        return (
          <IconButton
            classes={{label: classes.deleteButton}}
            size="small"
            onClick={onDeleteEvent}
          >
            <SvgIcon type="bin" modifier="red" />
          </IconButton>
        );
    }
  };

  const renderTimeInput = (event: IWebinarEvent) => {
    if( !props.editable) {
      return timeView;
    }
    if(event.name === EWebinarEvents.OPEN) {
      return <MUISelect
        variant="outlined"
        fullWidth
        value={rawTime}
        onChange={(evt: any) => {;
          handleTimeSave(evt.target.value);
        }}
      >
        {WebinarOpenTime.map((time: any) => (
          <MenuItem
            value={convertSecondsToRawTime(- time.value * 60) || ""}
            key={`openTime-${time.value}`}
          >
            {prettifyRawTime(convertSecondsToRawTime(-time.value * 60))}
          </MenuItem>
        ))}
      </MUISelect>
    }
    switch (event.name) {
      case EWebinarEvents.START:
      case EWebinarEvents.VIDEO:
        return timeView;
    }
    return <MUILiveInput
      value={rawTime}
      inactiveView={timeView}
      onSave={handleTimeSave}
    />
  }

  return (
    <Grid container justify="flex-start" item wrap="nowrap" direction="row" className={classes.webinarEvent}>
      <Grid item xs={4}>
        {renderTimeInput(props.event)}
      </Grid>
      <Grid item xs={7}>
        {renderBody(props.event)}
      </Grid>
      {props.editable &&
        <Grid item xs={1}>
          {renderDeleteButton(props.event)}
        </Grid>
      }
    </Grid>
  )
}
import { Box, makeStyles, Typography } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { ThemeType } from '../../../MUI';
import { EWebinarStatus, EWebinarType, IWebinar } from '../../../types';
import { tl } from '../../../utils';
import SvgIcon from '../../SvgIcon';
import {differenceInHours, isSameDay, differenceInMinutes, parseJSON, differenceInDays} from 'date-fns'
import getHours from 'date-fns/getHours';
import getMinutes from 'date-fns/getMinutes';


const intlFormat = Intl.DateTimeFormat('ru-RU', {
  day: '2-digit',
  month: '2-digit',
  year: '2-digit'
});

const useStyles = makeStyles((theme: ThemeType) => ({
  webinarStatus: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '91px',
    maxWidth: 91,
    height: '60px',
    position: 'relative',
    borderRadius: `${theme.shape.borderRadius}px`,
    overflow: 'hidden',
    '& img': {
      height: 'auto',
      width: '100%',
    }
  },
  timeFont: {
    fontSize: 16,
    lineHeight: '19px',
    fontWeight: 600,
  },
  OnAir: {
    backgroundColor: '#2f90e01a',
    color: '#2C3A98',
  },

  StartingSoon: {
    backgroundColor: '#30ab591a',
    color: theme.palette.success.main,
  },

  StartingLate: {
    backgroundColor: '#3F53D91a',
    color: '#2C3A98',
  },
  Draft: {
    backgroundColor: '#6d6d6d0d',
    color: theme.palette.grey[600],
  },
  semiBold: {
    fontWeight: 600,
  },
  webinarIcon: {
    Clock: {
      transform: 'scale(0.8)',
      paddingLeft: '5px',
    },

    Members: {
      transform: 'scale(0.9)',
      paddingLeft: '10px',
      paddingRight: '5px',
    },
  },
  floatingDate: {
    position: 'absolute',
    top: 2,
    borderRadius: 2,
    padding: '2px 4px',
    backgroundColor: '#F7F7F7',
  },
  small: {
    fontSize:13,
    lineHeight: '18px',
  },
  finishNoImg: {
    backgroundColor: theme.palette.grey.A700,
    color: theme.palette.primary.main,
  },
}));

// const StatusCircle = styled(Box)({
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   zIndex: 1,
//   transform: 'translate(-50%, -50%)',
//   borderRadius: '50%',
//   backgroundColor: '#fff',
//   width: '26px',
//   height: '26px',
// });

// const StatusPause = styled(Box)({
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   backgroundColor: '#fff',
//   borderLeft: '3px solid #FF8C11',
//   borderRight: '3px solid #FF8C11',
//   height: '12px',
//   width: '8px',
//   boxSizing: 'border-box',
  
// });

// const StatusFader = styled(Box)({
//   position: 'absolute',
//   width: '100%',
//   height: '100%',
//   backgroundColor: '#6d6d6d',
//   opacity: 0.5,
// });

export interface WebinarStatusProps {
  webinar: IWebinar;
}

export const WebinarStatus: React.FC<WebinarStatusProps> = ({
  webinar
}) => {
  const classes = useStyles();
  const [error, setError] = useState(false);
  const startDate = useMemo(() => webinar.startTime ? parseJSON(webinar.startTime) : null, [webinar])
  const isStartingSoon = useMemo(() => {
    if (startDate === null) return false;
    if (differenceInHours(startDate, Date.now()) < 0) return true;
    return  isSameDay(startDate, Date.now())
  }, [startDate]);


  const lastTime = useMemo(() => {
    if (startDate === null) return "";
    const minutes = differenceInMinutes(startDate, Date.now());
    if (minutes < 0) return "";
    if (minutes > 60) {
      const hours = differenceInHours(startDate, Date.now());
      if (hours > 24) {
        const days = differenceInDays(startDate, Date.now());
        return `${days} д ${hours - days * 24}ч`;
      }
      return `${hours}ч ${minutes - hours * 60}м`;
    }
    return `${minutes} мин`;
  }, [startDate]);


  switch (webinar.status) {
    case EWebinarStatus.LIVE:
      return (
        <Box className={`${classes.webinarStatus} ${classes.OnAir}`}>
          <SvgIcon type="on-air" notimage />
          <Typography className={classes.semiBold}>{tl('В эфире')}</Typography>
        </Box>
      );
    case EWebinarStatus.ACTIVE:
    case EWebinarStatus.OPEN:
      return (
        <Box
          className={`${classes.webinarStatus} ${
            isStartingSoon ? classes.StartingSoon : classes.StartingLate
          }`}
        >
          {webinar.startTime && 
            webinar.startPage.openTime !== null && 
            (webinar.startPage.openTime === 0 || (differenceInMinutes(new Date(webinar.startTime), new Date()) < webinar.startPage.openTime) )?
            <>
            <Typography className={classes.semiBold}>
              {tl('Заставка')}
            </Typography>
            <Typography className={classes.timeFont}>{lastTime}</Typography>
            </>
           : 
           
            webinar.startTime && isSameDay(new Date(), new Date(webinar.startTime)) ?
            <>
              <Typography className={classes.semiBold}>{ tl('Сегодня')} </Typography>
              <Typography className={classes.timeFont}>{getHours(new Date(webinar.startTime)).toString().padStart(2,'0')}:{getMinutes(new Date(webinar.startTime)).toString().padStart(2,'0')}</Typography>
            </>
            :
            webinar.startTime && differenceInHours(new Date(webinar.startTime), new Date()) < 24 ?
            <>
              <Typography className={classes.semiBold}>  {tl('Завтра') }</Typography> 
              <Typography className={classes.timeFont}>{getHours(new Date(webinar.startTime)).toString().padStart(2,'0')}:{getMinutes(new Date(webinar.startTime)).toString().padStart(2,'0')}</Typography>
            </>
            :
            webinar.startTime &&
            <>
              <Typography className={classes.semiBold}>{intlFormat.format(new Date(webinar.startTime))}</Typography>
              <Typography className={classes.timeFont}>
                {getHours(new Date(webinar.startTime)).toString().padStart(2,'0')}:{getMinutes(new Date(webinar.startTime)).toString().padStart(2,'0')}
              </Typography>
            </>
          }
        </Box>
      );

    case EWebinarStatus.DRAFT:
    case EWebinarStatus.PAUSE:
      return (
        <Box className={`${classes.webinarStatus} ${classes.Draft}`}>
          <SvgIcon type="on-air-draft" notimage/>
          <Typography className={classes.semiBold}>
            {EWebinarType.AUTOWEBINAR 
              ? tl('Нет даты')
              : tl('Черновик')
            }
          </Typography>
        </Box>
      );

    case EWebinarStatus.DELETED:
      return (
        <Box className={`${classes.webinarStatus} ${classes.Draft}`}>
          <Typography className={classes.semiBold}>{tl('Удален')}</Typography>
        </Box>
      );

    case EWebinarStatus.FINISH:
      return (
        <Box className={`${classes.webinarStatus} ${!webinar.source.poster ? classes.finishNoImg : classes.Draft}`}>
            {!webinar.source.poster 
            ?
              <>
                <Typography className={classes.small}>{tl('Завершен')}</Typography>
                {startDate &&  
                  <Typography className={classes.timeFont}>{intlFormat.format(new Date(startDate))}</Typography>}
              
              </>
          
            : <>
              { !error && webinar.source.poster && <img onError={() => {if(!error) setError(true)}} src={webinar.source.poster} alt="any" />}
                <Box className={classes.floatingDate}>
                {
                  startDate &&  
                    <Typography className={classes.semiBold}>{intlFormat.format(new Date(startDate))}</Typography>
                }
                </Box>
            </>}
        </Box>
      );

    // case EWebinarStatus.PAUSE:
    //   return (
    //     <Box className={`${classes.webinarStatus} ${classes.Draft}`}>
    //       {poster ? (
    //         <>
    //           <StatusCircle>
    //             <StatusPause />
    //           </StatusCircle>
    //           <StatusFader />
    //           {!error && poster && <img onError={() => {if(!error) setError(true)}} src={poster} alt="any" height="100%" />}
    //         </>
    //       ) : (
    //         <Typography className={classes.semiBold}>
    //           {tl('Нет Даты')}
    //         </Typography>
    //       )}
    //     </Box>
    //   );
    default:
      return null;
  }
};

export default WebinarStatus;

import {
  EReportStatus,
  IEpisodeStatistics,
  IStatisticsEpisodeMeta,
  IStatisticsMeta,
  IUserStatistic,
  IWebinarStatistic,
} from '../../types';

export const STATISTICS_WEBINARS_FETCH_ATTEMPT =
  'STATISTICS_WEBINARS_FETCH_ATTEMPT';
export const STATISTICS_WEBINARS_FETCH_FAILURE =
  'STATISTICS_WEBINARS_FETCH_FAILURE';
export const STATISTICS_WEBINARS_FETCH_SUCCESS =
  'STATISTICS_WEBINARS_FETCH_SUCCESS';

export const STATISTICS_AUTOWEBINAR_EPISODES_FETCH_ATTEMPT =
  'STATISTICS_AUTOWEBINAR_EPISODES_FETCH_ATTEMPT';
export const STATISTICS_AUTOWEBINAR_EPISODES_FETCH_FAILURE =
  'STATISTICS_AUTOWEBINAR_EPISODES_FETCH_FAILURE';
export const STATISTICS_AUTOWEBINAR_EPISODES_FETCH_SUCCESS =
  'STATISTICS_AUTOWEBINAR_EPISODES_FETCH_SUCCESS';

export const EPISODE_STATISTICS_FETCH_ATTEMPT =
  'EPISODE_STATISTICS_FETCH_ATTEMPT';
export const EPISODE_STATISTICS_FETCH_FAILURE =
  'EPISODE_STATISTICS_FETCH_FAILURE';
export const EPISODE_STATISTICS_FETCH_SUCCESS =
  'EPISODE_STATISTICS_FETCH_SUCCESS';

export const STATISTICS_MEMBERS_FETCH_ATTEMPT =
  'STATISTICS_MEMBERS_FETCH_ATTEMPT';
export const STATISTICS_MEMBERS_FETCH_FAILURE =
  'STATISTICS_MEMBERS_FETCH_FAILURE';
export const STATISTICS_MEMBERS_FETCH_SUCCESS =
  'STATISTICS_MEMBERS_FETCH_SUCCESS';
export const STATISTICS_CLEAR_STATE = 'STATISTICS_CLEAR_STATE';

export const UPDATE_REPORT_STATUS = 'UPDATE_REPORT_STATUS';
export const UPDATE_REPORT_EXPORT_STATUS = 'UPDATE_REPORT_EXPORT_STATUS';


export interface IUpdateReportStatusAction {
  type: typeof UPDATE_REPORT_STATUS;
  status: EReportStatus;
  reportId?: string;
}
export interface IUpdateExportReportStatusAction {
  type: typeof UPDATE_REPORT_EXPORT_STATUS;
  status: EReportStatus;
  reportId?: string;
}

export interface IStatisticsWebinarsFetchAttemptAction {
  type: typeof STATISTICS_WEBINARS_FETCH_ATTEMPT;
  id: number;
}

export interface IStatisticsWebinarsFetchFailureAction {
  type: typeof STATISTICS_WEBINARS_FETCH_FAILURE;
  error: string;
}

export interface IStatisticsWebinarsFetchSuccessAction {
  type: typeof STATISTICS_WEBINARS_FETCH_SUCCESS;
  list: Array<IWebinarStatistic>;
  count: number;
  id: number;
}

export interface IStatisticsAutowebinarEpisodesFetchAttemptAction {
  type: typeof STATISTICS_AUTOWEBINAR_EPISODES_FETCH_ATTEMPT;
  id: number;
}

export interface IStatisticsAutowebinarEpisodesFetchFailureAction {
  type: typeof STATISTICS_AUTOWEBINAR_EPISODES_FETCH_FAILURE;
  error: string;
}

export interface IStatisticsAutowebinarEpisodesFetchSuccessAction {
  type: typeof STATISTICS_AUTOWEBINAR_EPISODES_FETCH_SUCCESS;
  list: Array<IWebinarStatistic>;
  count: number;
  id: number;
  meta: IStatisticsEpisodeMeta
}

export interface IEpisodeStatisticsFetchAttemptAction {
  type: typeof EPISODE_STATISTICS_FETCH_ATTEMPT;
  id: number;
}

export interface IEpisodeStatisticsFetchFailureAction {
  type: typeof EPISODE_STATISTICS_FETCH_FAILURE;
  error: string;
}

export interface IEpisodeStatisticsFetchSuccessAction {
  type: typeof EPISODE_STATISTICS_FETCH_SUCCESS;
  episode: IEpisodeStatistics;
  id: number;
}

export interface IStatisticsMembersFetchAttemptAction {
  type: typeof STATISTICS_MEMBERS_FETCH_ATTEMPT;
  offset?: number;
}

export interface IStatisticsMembersFetchFailureAction {
  type: typeof STATISTICS_MEMBERS_FETCH_FAILURE;
  error: string;
}

export interface IStatisticsMembersFetchSuccessAction {
  type: typeof STATISTICS_MEMBERS_FETCH_SUCCESS;
  list: Array<IUserStatistic>;
  count: number;
  meta: IStatisticsMeta;
  offset: number;
}

export interface IStatisticsClearStateAction {
  type: typeof STATISTICS_CLEAR_STATE;
}

export type TStatisticsAction =
  | IStatisticsWebinarsFetchAttemptAction
  | IStatisticsWebinarsFetchFailureAction
  | IStatisticsWebinarsFetchSuccessAction
  | IStatisticsAutowebinarEpisodesFetchAttemptAction
  | IStatisticsAutowebinarEpisodesFetchFailureAction
  | IStatisticsAutowebinarEpisodesFetchSuccessAction
  | IEpisodeStatisticsFetchAttemptAction
  | IEpisodeStatisticsFetchFailureAction
  | IEpisodeStatisticsFetchSuccessAction
  | IStatisticsMembersFetchAttemptAction
  | IStatisticsMembersFetchFailureAction
  | IStatisticsMembersFetchSuccessAction
  | IStatisticsClearStateAction
  | IUpdateExportReportStatusAction
  | IUpdateReportStatusAction;

import { createContext } from 'react'
import AccessControl from '../services/AccessControl.service';

export const defRules =  new AccessControl({
  adsLayout: [],
  webinar: ['list'],
  stream: [],
  autowebinar: ['list'],
  chat: [],
  presentation: [],
  moderator: [],
  profile: [],
  statistics: [],
  integrations: [],
});


export const acIns = defRules;

const AccessControlCTX = createContext(acIns);

export default AccessControlCTX;
import { createStore, combineReducers, applyMiddleware, Middleware, Action } from 'redux'
import thunk, { ThunkAction } from 'redux-thunk'
import { userReducer } from './user'
import webinarsReducer from './webinars'
import { createBrowserHistory } from 'history'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import webinarReducer from './webinar'
import notificationsReducer from './notifications'
import statisticsReducer from './statistics'
import moderatorsReducer from './moderators'
import adsReducer from './ads'
import streamWSMiddleware from './streamWSMiddleware'
import playerReducer from './player'
import eventsReducer from './events'
import streamReducer from './stream'
import ticketsReducer from './tickets'
import integrationsReducer from './integrations';
import tasksMiddleware from './tasksQueueMiddleware'
import tasksReducers from './tasks'
import createSagaMiddleware from 'redux-saga'
import rootSaga from './rootSaga'
import plansReducer from './plans'
import billingReducer from './billing'
import appReducer from './app'


export const history = createBrowserHistory()

export const reducers = combineReducers({
    app: appReducer,
    user: userReducer,
    billing: billingReducer,
    webinars: webinarsReducer,
    webinar: webinarReducer,
    statistics: statisticsReducer,
    moderators: moderatorsReducer,
    ads: adsReducer,
    events: eventsReducer,
    notifications: notificationsReducer,
    player: playerReducer,
    stream: streamReducer,
    tasks: tasksReducers,
    tickets: ticketsReducer,
    integrations: integrationsReducer,
    plans: plansReducer,
    router: connectRouter(history),
})

const logger: Middleware = store => next => action => {
    if (process.env.NODE_ENV === 'development') {
        console.log('dispatching', action)
        let result = next(action)
        console.log('next state', store.getState())
        return result
    } else return next(action);
}

const sagaMiddleWare = createSagaMiddleware();
//@ts-ignore
const middlewares = [thunk, logger, routerMiddleware(history), streamWSMiddleware(), (store) => tasksMiddleware(store)(), sagaMiddleWare]
const store = createStore(reducers, applyMiddleware(...middlewares))

sagaMiddleWare.run(rootSaga);

export type StoreType = typeof store
export type RootState = ReturnType<typeof reducers>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>

export default store

//@ts-nocheck
import * as React from "react";
import classnames from "classnames";
import styles from "./styles.module.scss";

interface BadgeProps {
  style?: string | "success" | "danger" | "info";
  type?: "rect" | "circle";
  id?: any;
  children?: any;
  onClick?: any;
  size?: "medium" | "large";
}

export default class Badge extends React.Component<BadgeProps, {}> {
  render() {
    const {children, style, type, size, ...badgeProps} = this.props;
    const className = classnames({
      [styles.Badge]: true,
      [styles.success]: style === "success",
      [styles.danger]: style === "danger",
      [styles.info]: style === "info",
      [styles.draft]: style === "draft",
      [styles.typeRect]: type === "rect",
      [styles[size]]: !!size
    });

    return (
      <span className={className} {...badgeProps}>
        {children}
      </span>
    );
  }
}

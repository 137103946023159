import { Button, styled, Typography } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router';
import { FullSize } from '../core/FullSizePage';


const BigNumber = styled(Typography)({
  fontSize: '7rem',
});

export const PermissionDenied: React.FC = () => {
  const history = useHistory();
  return (
    <FullSize>
      <BigNumber variant="h1">403</BigNumber>
      <Typography variant="h2">Страница недоступна.</Typography>
      {
        history.length > 0 &&
        <Button variant="text" onClick={() => history.goBack()}>
          Назад
        </Button>
      }
    </FullSize>
  );
}
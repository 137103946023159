import React,{ReactNode} from "react";
import classnames from "classnames";
import styles  from "./styles.module.scss";

interface FormProps {
  children:ReactNode;
  onSubmit?: any;
  method?: string;
  action?: string;
  style?: any;
  id?: any;
  encType?: any;
}

export const Form  = React.forwardRef<HTMLFormElement, FormProps>((props,ref) =>{
    const {children,...formProps} = props;
    const className = classnames({
      [styles.Form]: true
    });
  return <form ref={ref} className={className} {...formProps} >{children}</form>;
})

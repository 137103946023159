import { IPresentation, IWebinar } from "../../types"

export const WEBINAR_CLEAR = 'WEBINAR_CLEAR'
export const WEBINAR_FETCH_ATTEMPT = 'WEBINAR_FETCH_ATTEMPT'
export const WEBINAR_FETCH_FAILURE = 'WEBINAR_FETCH_FAILURE'
export const WEBINAR_FETCH_SUCCESS = 'WEBINAR_FETCH_SUCCESS'

export const WEBINAR_EDIT = 'WEBINAR_EDIT'
export const WEBINAR_ADD_PRESENTATION = 'WEBINAR_ADD_PRESENTATION'
export const WEBINAR_PRESENTATION_UPLOAD_START = 'WEBINAR_PRESENTATION_UPLOAD_START'
export const WEBINAR_PRESENTATION_UPLOAD_END = 'WEBINAR_PRESENTATION_UPLOAD_END'

export const WEBINAR_PRESENTATION_PROCESSING_START = 'WEBINAR_PRESENTATION_PROCESSING_START'
export const WEBINAR_PRESENTATION_PROCESSING_END = 'WEBINAR_PRESENTATION_PROCESSING_END'
export const WEBINAR_REMOVE_PRESENTATION = 'WEBINAR_REMOVE_PRESENTATION'
export const WEBINAR_FETCH_PRESENTATIONS = 'WEBINAR_FETCH_PRESENTATIONS'
export const WEBINAR_SET_PRESENTATIONS_LIST = 'WEBINAR_SET_PRESENTATIONS_LIST'
export const CHAT_SET_MESSAGES = 'CHAT_SET_MESSAGES'

export interface IWebinarFetchAttemptAction {
    type: typeof WEBINAR_FETCH_ATTEMPT
}
export interface IWebinarClearAction {
    type: typeof WEBINAR_CLEAR
}

export interface IWebinarFetchSuccessAction {
    type: typeof WEBINAR_FETCH_SUCCESS
    webinar: IWebinar
}

export interface IWebinarFetchFailureAction {
    type: typeof WEBINAR_FETCH_FAILURE
    error: string
}


export interface IWebinarEditAction {
    type: typeof WEBINAR_EDIT
    isEditing: boolean
}

export interface IWebinarFetchPresentations {
    type: typeof WEBINAR_FETCH_PRESENTATIONS
    presentations: Array<IPresentation>
}

export interface IWebinarPresentationUploadStartAction {
    type: typeof WEBINAR_PRESENTATION_UPLOAD_START,
    presentation: IPresentation
}

export interface IWebinarPresentationUploadEndAction {
    type: typeof WEBINAR_PRESENTATION_UPLOAD_END,
    id: string,
    presentation: IPresentation
}


export interface IWebinarPresentationProcessingStartAction {
    type: typeof WEBINAR_PRESENTATION_PROCESSING_START,
    presentation: IPresentation
}

export interface IWebinarPresentationProcessingEndAction {
    type: typeof WEBINAR_PRESENTATION_PROCESSING_END,
    id: string,
    pages: number
}
export interface IWebinarAddPresentationAction {
    type: typeof WEBINAR_ADD_PRESENTATION
    presentation: IPresentation
}

export interface IWebinarRemovePresentationAction {
    type: typeof WEBINAR_REMOVE_PRESENTATION
    presentation: IPresentation
}
export interface IWebinarSetPresentationsListAction {
    type: typeof WEBINAR_SET_PRESENTATIONS_LIST
    presentations: IPresentation[]
}

export interface IChatSetMessagesAction {
    type: typeof CHAT_SET_MESSAGES,
    payload: any
}

export type TWebinarActionType =
    IWebinarFetchAttemptAction |
    IWebinarFetchFailureAction |
    IWebinarFetchSuccessAction |
    IWebinarAddPresentationAction |
    IWebinarPresentationUploadStartAction |
    IWebinarPresentationUploadEndAction |
    IWebinarPresentationProcessingStartAction |
    IWebinarPresentationProcessingEndAction |
    IWebinarFetchPresentations |
    IWebinarRemovePresentationAction |
    IWebinarEditAction |
    IWebinarClearAction |
    IWebinarSetPresentationsListAction |
    IChatSetMessagesAction
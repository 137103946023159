import {
  Box,
  Button,
  Hidden,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Tooltip,
} from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { tl } from '../../utils';
import PlusFilledIcon from '../Icon/plusFilled';
import { SmartCounter } from '../SmartCounter';

const useStyles = makeStyles((theme) => ({
  plusBox: {
    borderRadius: '50%',
    width: 30,
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 12,
    marginRight: 12,
    //@ts-ignore
    color: theme.palette.primary.light,
    //@ts-ignore
    background: theme.palette.default.main,
    '& svg': {
      width: 18,
      height: 18,
    },
  },
  menuBox: {
    cursor: 'pointer',
    textDecoration: 'none',
    marginLeft: 50,
    [theme.breakpoints.down('md')]: {
      marginLeft: 32,
    },
    '&:hover, &:active, &:focus': {
      '& $billingMenu': {
        display: 'flex',
      },
    },
  },
  hoverBox: {
    display: 'flex',
    flexDirection: 'row',
  },
  currencyIcon: {
    width: 26,
    height: 26,
    marginRight: 8,
  },
  billingMenu: {
    position: 'absolute',
    top: 0,
    right: 0,
    // display: 'none',
  },
}));

export const Balance: React.FC<{ balance: number }> = ({ balance }) => {
  const classes = useStyles();
  const [show] = useState(false);
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      flexWrap="nowrap"
      className={classes.menuBox}
      component={Link}
      //@ts-ignore
      to="/plans"
      aria-haspopup="menu"
    >
      <Box className={classes.hoverBox}  >
        <SmartCounter amount={balance} />
        <Box alignItems="center" display="flex" position="relative">
          <KeyboardArrowDown color="primary" fontSize="small" />
          <Box display={show? 'flex' : 'none'} className={classes.billingMenu} mt={3} >
            <Paper>
              <MenuList>
                <MenuItem component={Link} to="/plans">
                  Тарифы
                </MenuItem>
                <MenuItem component={Link} to="/billing">
                  История баланса
                </MenuItem>
              </MenuList>
            </Paper>
          </Box>
        </Box>
      </Box>
      <Hidden mdDown>
        {(balance < 200)
        ? <Box ml={1.5} mr={1.5}><Button variant="outlined">{tl('Пополнить баланс')}</Button></Box>
        : <Tooltip title={tl('Пополнить баланс')} arrow placement="bottom">
            <Box className={classes.plusBox}>
              <PlusFilledIcon/>
            </Box>
          </Tooltip>
        }
        {/* <Tooltip title={tl('Пополнить баланс')} arrow placement="bottom">
          <Box className={classes.plusBox}>
            <PlusFilledIcon />
          </Box>
        </Tooltip> */}
      </Hidden>
    </Box>
  );
};

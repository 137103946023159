import React, { forwardRef, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Typography,
  styled,
  makeStyles,
  useMediaQuery,
  Tooltip,
} from '@material-ui/core';
// import { makeStyles } from '@material-ui/core/styles';
import { MUITooltip } from '../../../MUI';
import { copyToClipboard, tl } from '../../../utils';
import { SvgIcon } from '../..';
import { useDispatch } from 'react-redux';
import { addNotification } from '../../../store/notifications/actions';
import PlusFilledIcon from '../../Icon/plusFilled';
import { AddCoinsModal } from '../../Modals/AddCoins';
import { FreePeriod } from '../../Header/freePeriod';

const useStyles = makeStyles((theme) => ({
  header: {
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '7px 19px',
    background: '#fff',
    borderBottom: '1px solid #E3E4E8',
    color: '#000',
    minHeight: '48px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    cursor: 'pointer',
    '@media (max-width: 1280px)': {
      padding: '4px 19px',
    },
  },
  onlineText: {
    fontFamily: 'SF UI Text',
    fontSize: 13,
    lineHeight: '15px',
    marginRight: 12,
    '& span': {
      color: theme.palette.primary.main,
      fontWeight: 500,
    },
  },
  membersLimit: {
    color: theme.palette.grey[600],
    fontFamily: 'SF UI Text',
    fontSize: 13,
    lineHeight: '15px',
  },
  btnLink: {
    '@media screen and (max-width: 1280px)': {
      '& .MuiButton-startIcon': {
        display: 'none',
      },
    },
  },
  plusBox: {
    borderRadius: '50%',
    width: 30,
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 4,
    //@ts-ignore
    color: theme.palette.primary.light,
    //@ts-ignore
    background: theme.palette.default.main,
    '& svg': {
      width: 18,
      height: 18,
    },
  },
  lowMembersCount: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    height: 30,
    borderRadius: 15,
    '& p': {
      paddingLeft: 12,
      paddingRight: 8,
      fontFamily: 'SF UI Text',
      fontSize: 13,
      lineHeight: '15px',
      fontWeight: 600,
    },
  },
  warning: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.main,
  },
  error: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.main,
  },
  smallBtn: {
    width: 30,
    height: 30,
  },
  tooltip: {
    maxWidth: 219,
    textAlign: 'center',
  },
}));

const AddIconButton = styled('button')(({ theme }) => ({
  display: 'inline-flex',
  wordBreak: 'unset',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  outline: 'none',
  border: 'none',
  width: 30,
  height: 30,
  borderRadius: '50%',
  '& > svg': {
    minWidth: 20,
    minHeight: 20,
  },
}));

interface ChatHeaderProps {
  membersOnline: number;
  membersAvailable: number;
  onlyMembersCount: true;
  isStarted?: undefined;
  waitingCount?: undefined;
}

interface ChatHeaderAuthorProps {
  isStarted: boolean;
  isFinished: boolean;
  membersOnline: number;
  membersAvailable: number;
  onlyMembersCount: false;
  freePeriodDays?: number;
  isAuto: boolean;
  paused: boolean;
  noStartTime: boolean;
  link: string;
  membersLimitState: 'low' | 'empty' | 'normal';
}

export const ChatHeader = forwardRef<
  HTMLDivElement,
  ChatHeaderProps | ChatHeaderAuthorProps
>((props, ref) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const layoutSmall = useMediaQuery('screen and (max-width: 1280px)');
  const [addCoinsModal, setAddCoinsModal] = useState(false);

  useEffect(() => {
    if (!props.onlyMembersCount && props.membersLimitState !== 'normal') {
      setAddCoinsModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [(props as ChatHeaderAuthorProps).membersLimitState]);

  if (props.onlyMembersCount) {
    return null;
  }

  const getLinkTitle: () => string = () => {
    if (props.noStartTime)
      return tl(
        'Ссылка входа неактивна, потому что вы не выбрали дату вебинара или не сохранили ее'
      );

    if (props.paused) {
      return tl('Ссылка входа неактивна, потому что автовебинар остановлен');
    }

    return tl('Скопировать ссылку входа зрителям');
  };

  const handleCopy = async (e: React.MouseEvent) => {
    e.preventDefault();
    await copyToClipboard(`${window.location.origin}/${props.link}`);
    dispatch(addNotification('success', 'Ссылка скопирована'));
  };

  return (
    <>
      <Box className={classes.header}>
        <Box
          display="flex"
          flexDirection="row"
          flexWrap="nowrap"
          alignItems="center"
          onClick={() => setAddCoinsModal(true)}
        >
          <Typography className={classes.onlineText}>
            <span>Online:</span> {props.membersOnline}
          </Typography>
          {props.freePeriodDays ? (
            <FreePeriod freePeriodDays={props.freePeriodDays} />
          ) : props.membersAvailable < 0 ? (
            <Box className={[classes.lowMembersCount, classes.error].join(' ')}>
              {layoutSmall ? (
                <Typography>Зрители не могут войти</Typography>
              ) : (
                <Typography>
                  Не могут войти: {Math.abs(props.membersAvailable)}
                </Typography>
              )}
              <Tooltip
                classes={{ tooltip: classes.tooltip }}
                arrow
                placement="bottom"
                title="Пополнить баланс и добавить мест для зрителей"
              >
                <AddIconButton>
                  <PlusFilledIcon color="inherit" />
                </AddIconButton>
              </Tooltip>
            </Box>
          ) : props.membersAvailable > 200 ? (
            <Box
              display="flex"
              flexDirection="row"
              flexWrap="nowrap"
              alignItems="center"
            >
              <Typography className={classes.membersLimit}>
                Мест: {props.membersAvailable}
              </Typography>
              <Tooltip
                classes={{ tooltip: classes.tooltip }}
                arrow
                placement="bottom"
                title="Пополнить баланс и добавить мест для зрителей"
              >
                <Box className={classes.plusBox}>
                  <PlusFilledIcon color="inherit" />
                </Box>
              </Tooltip>
            </Box>
          ) : (
            <Box
              className={[classes.lowMembersCount, classes.warning].join(' ')}
            >
              <Typography>Мест: {props.membersAvailable}</Typography>
              <Tooltip
                classes={{ tooltip: classes.tooltip }}
                arrow
                placement="bottom"
                title="Пополнить баланс и добавить мест для зрителей"
              >
                <AddIconButton>
                  <PlusFilledIcon color="inherit" />
                </AddIconButton>
              </Tooltip>
            </Box>
          )}
        </Box>
        <MUITooltip title={getLinkTitle()} arrow placement="bottom">
          {layoutSmall ? (
            props.membersAvailable > 200 ? (
              <Button
                className={classes.btnLink}
                variant="outlined"
                onClick={handleCopy}
                startIcon={<SvgIcon type={'sw-link-dark'} />}
              >
                {tl('Ссылка входа')}
              </Button>
            ) : (
              <Button
                className={classes.smallBtn}
                variant="outlined"
                onClick={handleCopy}
              >
                <SvgIcon type={'sw-link-dark'} />
              </Button>
            )
          ) : (
            <Button
              className={classes.btnLink}
              variant="outlined"
              onClick={handleCopy}
              startIcon={<SvgIcon type={'sw-link-dark'} />}
            >
              {tl('Ссылка входа')}
            </Button>
          )}
        </MUITooltip>
      </Box>
      {ref && (
        <AddCoinsModal
          ref={ref}
          open={addCoinsModal}
          type={props.membersLimitState}
          onClose={() => setAddCoinsModal(false)}
        />
      )}
    </>
  );
});

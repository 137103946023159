import { TNotificationType } from "."

export const NOTIFICATION_ADD = 'NOTIFICATION_ADD'
export const NOTIFICATION_REMOVE = 'NOTIFICATION_REMOVE'
export const CHAT_NOTIFICATION_ADD = 'CHAT_NOTIFICATION_ADD'
export const CHAT_NOTIFICATION_REMOVE = 'CHAT_NOTIFICATION_REMOVE'


export interface INotificationAddAction {
    type: typeof NOTIFICATION_ADD
    notificationType: TNotificationType,
    message: string
    id: string
}

export interface INotificationRemoveAction {
    type: typeof NOTIFICATION_REMOVE
    id:string
}

export interface IChatNotificationAddAction {
    type: typeof CHAT_NOTIFICATION_ADD
    message: string
    id: string
}

export interface IChatNotificationRemoveAction {
    type: typeof CHAT_NOTIFICATION_REMOVE
    id:string
}



export type TNotificationsAction = INotificationAddAction | INotificationRemoveAction | IChatNotificationAddAction | IChatNotificationRemoveAction

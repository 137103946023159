import { Box, Button, FormControl, FormHelperText, Grid, Input, InputLabel, Paper, Typography } from "@material-ui/core";
import {useEffect, useState} from "react";
import { api } from "../../api";
import useLocalStorage from "../../hooks/useLocalStorage";
import {useLocation} from "react-router-dom";
import {parse as parseQueryString} from "query-string";
import {apiClient} from "../../api/_client";
import {IUser} from "../../types";

export const ConfigEnvPage: React.FC = () => {

  const [branch, setBranch] = useLocalStorage('latest_branch', '');
  const [chatWidgetLink, setChatWidgetLink] = useLocalStorage('chat_widget_link', '');
  const [chat,setChat] = useState('')

  const [newBranch, setNewBranch] = useState('');
  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');
  const location = useLocation();

  const handleSaveChat = () => {
    if (chat.trim().length !== 0) {
      setChatWidgetLink(chat);
      window.location.reload();
    }
  }

  const handleSaveBranch = () => {
    setBranch(newBranch + '/api');
    window.location.reload();
  }

  const handleLogin = async () => {
    const user = await api.user.login(email, pass);
    if(user) {
      window.location.href = '/';
    }
  }

  useEffect(()=>{
    const queryString = parseQueryString(location.search)
    if (queryString?.token) {
      apiClient.get<IUser>(`/auth?token=${queryString.token}`).then(()=>{
        window.location.href = '/';
      });
    }
  },[location]);

  return (
    <Box p={4}>
      <Grid container item direction="column" spacing={2}>
        <Typography variant="h1">Конфигурация окружения</Typography>
        <Grid container item direction="row" spacing={2}>
          <Grid item md={4} xs={6}>
            <Paper>
              <Box p={1}>
                <Typography variant="h5">{branch? branch : 'Текущая ветка мастер'}</Typography>
                <FormControl fullWidth>
                  <InputLabel>Путь до апи</InputLabel>
                  <Input type="text" defaultValue={branch} placeholder="Вставь путь до бе" onChange={(e) => setNewBranch(e.currentTarget.value)} />
                </FormControl>
                <Box mb={1} />
                <Button variant="contained" onClick={handleSaveBranch} color="primary">Сохранить</Button>
              </Box>
            </Paper>
          </Grid>
          <Grid item md={4} xs={6}>
            <Paper>
              <Box p={1}>
                <Typography variant="h5">CHAT APP</Typography>
                <FormControl fullWidth>
                  <InputLabel>Ссылка на feature-ветку</InputLabel>
                  <Input type="text" defaultValue={chatWidgetLink} placeholder="Путь до " onChange={(e) => setChat(e.currentTarget.value)} />
                </FormControl>
                <Box mb={1} />
                <Button variant="contained" onClick={handleSaveChat} color="primary">Сохранить</Button>
              </Box>
            </Paper>
          </Grid>
        </Grid>
        <Grid item md={4} xs={6}>
          <Paper>
            <Box p={1}>
              <FormHelperText>Входи только после того, как сохранишь новую ветку</FormHelperText>
              <FormControl fullWidth>
                <InputLabel>Логин</InputLabel>
                <Input type="text" defaultValue={email} onChange={(e) => setEmail(e.currentTarget.value)} />
              </FormControl>
              <FormControl fullWidth>
                <InputLabel>Пароль</InputLabel>
                <Input type="text" defaultValue={pass} onChange={(e) => setPass(e.currentTarget.value)} />
              </FormControl>
              <Box mb={1} />
              <FormHelperText>Обязательно войди по новой после смены ветки через эту форму</FormHelperText>
              <Box mb={1} />
              <Button variant="contained" disabled={!email || !pass} onClick={handleLogin} color="primary">Войти</Button>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>

  );
}
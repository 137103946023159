import { ETaskStatus, ITask, TQueueName } from "../../types/Queue";
import { ADD_TASK, CLEAR_TASKS, MAKE_READY_TASK, REMOVE_TASK, SET_TASKS, TTasksActions } from "./types";


type ITasksState = {
  [key in TQueueName] :{
    tasks: Array<ITask>
  }
}

const initialState: ITasksState = {
  tickets: {
    tasks: []
  }
}

export default function tasksReducers (state = initialState, action: TTasksActions) {
  switch (action.type) {
    case SET_TASKS:
      return {
        ...initialState,
        [action.queueName]: {
          tasks: action.tasks,
        }
      };
    case REMOVE_TASK: {
      let foundIdx = state[action.queueName].tasks.findIndex(task => task.id === action.taskId);
      if (foundIdx < 0) {
        return {
          ...state
        }
      } else {
        let tasks = state[action.queueName].tasks.slice();
        tasks.splice(foundIdx, 1);
        return {
          ...state,
          [action.queueName]: {
            tasks: tasks
          }
        }

      }
    }
    case MAKE_READY_TASK: {
      const tasks = state[action.queueName].tasks.slice()
      const idx = tasks.findIndex(task => task.id === action.taskId)
      if (idx > -1) {
        tasks[idx].status = ETaskStatus.READY
      }
      return {
        ...state,
        [action.queueName]: {
          tasks: tasks
        }
      }
    }
    case ADD_TASK: 
      return {
        ...state,
        [action.queueName]: {
          tasks: [
            ...state[action.queueName].tasks,
            action.task
          ]
        }
      }
    case CLEAR_TASKS:
      return {
        ...state,
        [action.queueName]: {
          tasks: []
        }
      };
    default:
      return state;
  }
}
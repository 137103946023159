import { IBillingStat } from "../../types/Billing";
import { BILLING_LIST_FETCH_ATTEMPT, BILLING_LIST_FETCH_NEXT_SUCCESS, BILLING_LIST_FETCH_SUCCESS, PAY_ATTEMPT, PAY_ERROR, PAY_SUCCESS, TBillingActionType } from "./types";

interface IBillingInitialState {
  invoiceList: {
    loading: boolean;
    loadingNext: boolean;
    items: Array<IBillingStat>;
    countItems: number;
    error?: string;
  },
  pay: {
    loading: boolean;
  }
}

const initialState: IBillingInitialState = {
  invoiceList: {
    loading: false,
    loadingNext: false,
    items: [],
    countItems: 0,
  },
  pay: {
    loading: false,
  }
}

export default function billingReducer(
  state = initialState,
  action: TBillingActionType
) {
  switch (action.type) {
    case BILLING_LIST_FETCH_ATTEMPT:
      return {
        ...state,
        invoiceList: {
          ...state.invoiceList,
          loading: action.loadingNext ? false : true,
          loadingNext: action.loadingNext || false,
        }
      }
    case BILLING_LIST_FETCH_SUCCESS: 
      return {
        ...state,
        invoiceList: {
          ...state.invoiceList,
          loading: false,
          items: action.list,
          countItems: action.countItems
        }
      };
    case BILLING_LIST_FETCH_NEXT_SUCCESS: 
      return {
        ...state,
        invoiceList: {
          ...state.invoiceList,
          loading: false,
          loadingNext: false,
          items: [...state.invoiceList.items, ...action.list],
          countItems: action.countItems
        }
      };
    case PAY_ATTEMPT:
      return {
        ...state,
        pay: {
          loading: true,
        }
      }
    case PAY_SUCCESS:
      return {
        ...state,
        pay: {
          loading: false,
        }
      }
    case PAY_ERROR:
      return {
        ...state,
        pay: {
          loading: false,
        }
      }  
    default:
      return state;
  }
}
import React from 'react';
import { TIntegration } from '../../../../types';
import { Box, Typography, makeStyles } from "@material-ui/core";
import { ChevronRight } from '@material-ui/icons';

const useStyles = makeStyles(theme =>({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    cursor: "pointer",
  },
  chevron: {
    paddingLeft: 3,
    color: theme.palette.primary.light,
  },
  sellCountText: {
    display: "flex",
    alignItems: "center",
    fontSize: 13,
    lineHeight: "15px",
  }
}));

interface SellCardProps {
  integration: TIntegration;
}

export const SellCard: React.FC<SellCardProps> = ({ integration }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography className={classes.sellCountText}>
        {'sellCount' in integration ? integration.sellCount : 0}
      </Typography>
      <ChevronRight className={classes.chevron}/>
    </Box>
  );
};

export default SellCard;
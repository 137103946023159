import { Box, styled } from '@material-ui/core';
import React from 'react';

const Row = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  borderBottom: '1px solid #E3E4E8',
  flex: '1 0 100%',
  paddingTop: 1,
})

export default Row;
import React from 'react';
import { Grid } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import Page from '../../hoc/page';
import { tl } from "../../utils";
import { GridContainer, themeVariables } from '../../MUI';
import { SettingsNavigationMenu } from '../settings/menu';
import { PaymentIntegrationsTable } from './table';
import { CloudPaymentsIntegration } from './cloudPayments';
import { Route, Switch } from 'react-router-dom';

const useStyles = makeStyles({
  wrap: {
    justifyContent: "flex-end",
  
    [themeVariables.breakpoints.down("md")]: {
      justifyContent: "flex-start",
    },
  },
  
  content: {
    minWidth: "300px",
    maxWidth: "687px",
    boxSizing: "content-box",
    // Заглушка вместо левого меню, которое теоретически
    // должно быть добавлено в МР с CloudPayments
    marginLeft: "216px",

    [themeVariables.breakpoints.down("md")]: {
      marginLeft: 0,
    },
  },
});

export const PaymentIntegrationsPage: React.FC = () => {
  const classes = useStyles();

  return (
    <Page title={tl("Интеграции с платежными системами")}>
      <GridContainer direction="row-reverse" spacing={2} className={classes.wrap}>
        <SettingsNavigationMenu />
        <Grid item xs zeroMinWidth className={classes.content}>
          <Switch>
            <Route exact path="/settings/integrations">
              <PaymentIntegrationsTable />
            </Route>
            <Route exact path="/settings/integrations/cloud-payments">
              <CloudPaymentsIntegration />
            </Route>
            <Route path="/settings/integrations/:integrationId">
              <CloudPaymentsIntegration  />
            </Route>
          </Switch>
        </Grid>
      </GridContainer>
    </Page>
  )
}
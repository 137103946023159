import * as React from "react";
import { Box, IconButton, Typography, makeStyles } from "@material-ui/core";
import { SvgIcon } from "../../../components";
import { themeVariables } from "../../../MUI";
import { tl } from "../../../utils";

import styles  from "./styles.module.scss";

const useStyles = makeStyles({
  root: {
    padding: 0,
  },
})
interface TableInfoProps {
  info: string;
  onClose: () => void;
  noBorder?: boolean;
  noBorderTop?: boolean;

}

export const TableInfo:React.FC<TableInfoProps> = ({ info, onClose, noBorder, noBorderTop }) => {
  const classes = useStyles();

  return (
    <Box borderTop={noBorderTop ? "" : themeVariables.border}  borderBottom={noBorder ? "" : themeVariables.border} className={styles.tableInfo}>
      <Box color="#6d6d6d">
        <Typography>{tl(info)}</Typography>
      </Box>
      <Box className={styles.tableInfoClose}>
        <IconButton onClick={onClose} classes={{ root: classes.root }}>
          <SvgIcon type="courses-delete" />
        </IconButton>
      </Box>
    </Box>
  );
};

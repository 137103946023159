import {apiClient} from "./_client"
import {IAdsLayout, IPlayer, IUser} from "../types";

export const get = async (link: string): Promise<IPlayer> => {
    const response = await apiClient.get<IPlayer>(`/player/${link}`);
    return response.data;
}

export const event = (link:string, name: string, params: any): void => {
  apiClient.post(`/player/${link}/event`, {
    name:name,
    params:params
  });
}


export const remember = async (webinar:IPlayer, phone: string): Promise<boolean> => {
  const response = await apiClient.post<boolean>(`/player/${webinar.link}/remember`,
      {phone:phone}
      );
  return response.data;
};

export const login = async (webinar:IPlayer, user:Partial<IUser>): Promise<IUser> => {
    const response = await apiClient.post<IUser>(`/player/${webinar.link}/member`,user);
    return response.data;
};

export const adsClick = async (webinar:IPlayer, user:IUser, ads:IAdsLayout):Promise<void> =>{
    return await apiClient.post(`/player/${webinar.link}/ads`, {
      member:user.id,
      ads:ads.id
    });
}


export const facecontrol = async (user: Partial<IUser>, link: string):Promise<string | boolean> => {
  const res = await apiClient.post(`/player/${link}/facecontrol`,user);
  if (res.data.success === true) {
    return true;
  }
  return res.data.message;
}
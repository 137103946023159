import {
  Box,
  BoxProps,
  Collapse,
  IconButton,
  makeStyles,
  styled,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { ThemeType } from '../../MUI';
import SvgIcon from '../SvgIcon';

const useStyles = makeStyles((theme: ThemeType) => ({
  root: {
    display: 'flex',
    flex: '0 1 auto',
    flexWrap: 'nowrap',
    flexDirection: 'row',
    position: 'relative',
    borderColor: (props: BlockHeaderHintProps) =>
      theme.palette[props.variant].main,
    color: (props: BlockHeaderHintProps) => theme.palette[props.variant].main,
    backgroundColor: (props: BlockHeaderHintProps) =>
      theme.palette[props.variant].light,
    borderWidth: 2,
    borderRadius: theme.shape.borderRadius,
    borderStyle: 'solid',
  },
  inner: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    padding: 19,
  },
}));

export const BlockHeaderTitle = styled(Typography)({
  fontFamily: 'SF UI Text',
  fontSize: 13,
  lineHeight: '15px',
  color: '#000',
  fontWeight: 500,
});
BlockHeaderTitle.defaultProps = {
  className: 'block-header-title',
};

const CloseButton = styled(IconButton)({
  height: 20,
  margin: 8,
  flexBasis: 20,
  display: 'flex',
  alignSelf: 'flex-start',
  justifySelf: 'flex-end',
});

export const BlockHeaderBody = styled(Typography)({
  fontFamily: 'SF UI Text',
  fontSize: 13,
  lineHeight: '15px',
  color: '#000',
  fontWeight: 500,
  marginTop: 10,
  '& a': {
    color: '#2F90E0',
  },
});
BlockHeaderBody.defaultProps = {
  className: 'block-header-body',
};

export type TBlockHeaderType = 'warning' | 'success' | 'info' | 'error';
export interface BlockHeaderHintProps {
  variant: TBlockHeaderType;
  show?: boolean;
  onClose?: () => void;
  className?: string;
}

export const BlockHeaderHint: React.FC<BlockHeaderHintProps & BoxProps> = ({
  variant,
  children,
  className,
  onClose,
  show,
  ...boxProps
}) => {
  const classes = useStyles({ variant });
  const handleClose = () => {
    onClose && onClose();
  };
  return (
    <Collapse in={show} collapsedHeight={0} className={className}>
      <Box className={classes.root} {...boxProps}>
        <Box className={classes.inner}>{children}</Box>
        {onClose && (
          <CloseButton size="small" onClick={handleClose}>
            <SvgIcon type={`close ${variant}`} />
          </CloseButton>
        )}
      </Box>
    </Collapse>
  );
};

export default BlockHeaderHint;

import React from 'react';
import useAccessControl from '../../hooks/useAccessControl';
import { tl } from '../../utils';
import { SideMenu } from '../../components/SideMenu';
import { SideMenuItem } from '../../components/SideMenu/item';

interface SettingsNavigationMenuProps {
    redirectModal?: boolean;
    onRedirectDismiss?: () => void
  }
  
  export const SettingsNavigationMenu:React.FC <SettingsNavigationMenuProps> = ({redirectModal, onRedirectDismiss}) => {
    const acl = useAccessControl();
  
    return (
      <SideMenu redirectModal={redirectModal} onRedirectDismiss={onRedirectDismiss}>
        {
          acl.check('profile', 'get') ?
            <SideMenuItem
              label={tl("Личные данные")}
              variant="navigation"
              exact
              to="/settings/profile"
            /> : null
        }
        {
          acl.check('profile', 'get') ?
            <SideMenuItem
              label={tl("Безопасность")}
              variant="navigation"
              exact
              to="/settings/security"
            /> : null
        }
        {
          acl.check('integrations', 'list') || true ?
            <SideMenuItem
              label={tl('Платежные системы')}
              variant="navigation"
              to="/settings/integrations"
            /> : null
        }
      </SideMenu>
    )
  }
import { Box, TableCell, TableRow, Typography } from "@material-ui/core"
import { format } from "date-fns"
import { EWebinarType } from "../../../../types"
import { IBillingStatStream } from "../../../../types/Billing"
import { tl } from "../../../../utils"
import UserIcon from "../../../Icon/user"
import { Poster } from "../rowComps/poster"
import { SmartSum } from "../rowComps/smartSum"
import { numberFormat } from "../utils"
import { useStyles } from "./style"


export const StreamRow: React.FC<IBillingStatStream> = (props) => {
  const classes = useStyles();
  return (
    <TableRow>
      <TableCell width="17%" className={classes.date}>
        {
          format(new Date(props.date), 'dd.MM.yyyy HH:mm')
        }
      </TableCell>
      <TableCell width="70%">
        <Box display="flex" flexDirection="row" flexWrap="nowrap" flexGrow="1" alignItems="center">
          <Poster type="webinar" src={props.webinar.poster}  webinarType={props.webinar.type} />
          <Box display="flex" flexDirection="column" justifyContent="center">
            <Typography className={classes.textBold}>{props.webinar.type === EWebinarType.WEBINAR ? tl('Вебинар') : tl('Автовебинар')}</Typography>
            <Typography className={classes.textBold}>{tl(props.webinar.name)}</Typography>
          </Box>
        </Box>
      </TableCell>
      <TableCell align="right">
        <Box display="flex" flexDirection="row" flexWrap="nowrap" alignItems="center" justifyContent="flex-end">
          <Typography className={classes.text}>{numberFormat.format(props.unit)}</Typography>
          <Box ml={'5px'} display="flex" alignItems="center" justifyContent="center" width="14px"><UserIcon /></Box>
        </Box>
      </TableCell>
      <TableCell>
        <SmartSum sum={props.amount} summary={props.summary} />
      </TableCell>
    </TableRow>
  )
}
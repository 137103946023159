import { Box, Grid, Paper, styled, Typography, makeStyles } from "@material-ui/core";
import { VolumeOff } from "@material-ui/icons";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { VideoPlayer } from "../components/Video/Player/factory";
import { VolumeControl } from "../components/VolumeControl";
import useAppSelector from "../hooks/useAppSelector";
import useAudioContext from "../hooks/useAudioContext";
import { playerLogin } from "../store/player/actions";
import { wsConnect, wsSubscribe } from "../store/streamWSMiddleware/actions";



export const Wrap = styled('div')(({theme}) => ({
  display: 'flex',
  width: '40vw',
  minHeight: 'calc( (40vw / 16) * 9)',
  position: 'relative',
  aspectRatio: '16/9',
  fontFamily: "SF UI Display, Arial, Helvetica, sans-serif",
  borderRadius: 4,
  overflow: 'hidden',
  [theme.breakpoints.down('md')] : {
    width: '40vw',
    minHeight: 'calc( (40vw / 16) * 9)',
  },
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100vw',
    width: '100vw',
    height: '56.25vw',
    borderRadius: 0,
  }
}));


const useStyles = makeStyles((theme) => ({
  block: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    borderRadius: 4,
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
    }
  },
  innerPaper: {
    display: 'flex',
    flex: '1 1 100%',
    // alignItems: 'center',
    justifyContent: 'center'
  },
  root: {
    flex: '1 1 100%',

  },
  rootPadding: {
    width: '40vw',
    [theme.breakpoints.down('sm')]: {
      width: '100vw'
    }
  },
  helperText: {
    font: 'normal normal 17px/22px SF UI Display',
    marginBottom: 40,
    whiteSpace: 'nowrap',
    [theme.breakpoints.down(1600)]: {
      marginBottom: 32,
      font: 'normal normal 17px/22px SF UI Text',
    },
    [theme.breakpoints.down(1280)]: {
      marginBottom: 24,
      font: 'normal normal 15px/20px SF UI Text',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 16,
    },
    [theme.breakpoints.down(360)]: {
      font: 'normal normal 13px/20px SF UI Text',
      marginBottom: 20,
      marginRight: 16,
    },
  },
  title: {
    font: 'normal normal bold 26px/31px SF UI Display',
    marginTop: 56,
    marginBottom: 16,
    [theme.breakpoints.down(1600)]: {
      marginTop: 44,
      marginBottom: 12,
    },
    [theme.breakpoints.down(1440)]: {
      font: 'normal normal bold 21px/28px SF UI Display',
    },
    [theme.breakpoints.down(1280)]: {
      marginTop: 36
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 16,
     },
    [theme.breakpoints.down(360)]: {
      font: 'normal normal bold 17px/22px SF UI Text',
      marginTop: 20,
      marginBottom: 8,
      marginRight: 16,
    },
  },
  subtext1: {
    font: 'normal normal 21px/28px SF UI Display',
    marginTop: 40,
    marginBottom: 16,
    [theme.breakpoints.down(1600)]: {
      marginTop: 32,
    },
    [theme.breakpoints.down(1440)]: {
      font: 'normal normal 17px/22px SF UI Text',
    },
    [theme.breakpoints.down(1280)]: {
      marginTop: 24,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 16,
    },
    [theme.breakpoints.down(360)]: {
      font: 'normal normal 15px/20px SF UI Text',
      marginTop: 20,
      marginBottom: 16,
      marginRight: 16,
    },
  },
  subtext2: {
    font: 'normal normal 21px/28px SF UI Display',
    marginBottom: 16,
    [theme.breakpoints.down(1440)]: {
      font: 'normal normal bold 17px/22px SF UI Text',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 16,
    },
    [theme.breakpoints.down(360)]: {
      font: 'normal normal bold 15px/20px SF UI Text',
      marginBottom: 16,
      marginRight: 16,
    },
  },
  mutedBtn: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    top: '50%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    color: '#fff',
    borderRadius: 4,
    padding: 10,
    zIndex: 1300,
    left: '50%',
    transform: 'translate(-50%, -50%)',
    cursor: 'pointer',
    userSelect: 'none',
    
  },
  videoPlayer: {
    width: '100%',
    height: '100%',
  },
}));


export const NoSeats: React.FC =() => {
  const {member, player} = useAppSelector(store => store.player);
  const dispatch = useDispatch();
  const {muted, setMuted, volume, setVolume} = useAudioContext();
  const classes = useStyles();
  useEffect(() => {
    if(member && player) {
      dispatch(wsConnect(member));
      dispatch(wsSubscribe(`webinar:${player.link}-noseats`));
      dispatch(playerLogin(player, member));
    }
  }, []);

  return (
    <Grid item container className={classes.root}>
        <Paper className={classes.innerPaper}>
          <Box className={classes.rootPadding} display="flex" >
            <Grid container item xs={12} direction="column" >
              <Typography variant="h3" className={classes.title}>
                Вы находитесь в комнате ожидания входа на вебинар.
              </Typography>
              <Typography className={classes.subtext2}>
                Свободные места на вебинаре закончились. Здесь вы можете смотреть видео-трансляцию. Как только организатор увеличит количество мест для участников, мы автоматически перенаправим вас в комнату вебинара с чатом, презентацией и другими возможностями. Перезагружать страницу не нужно.
              </Typography>
              {
                (player?.source?.videoLink || player?.source?.src) &&
                  <Wrap>
                    <VideoPlayer
                      className={classes.videoPlayer}
                      type={player.source.type}
                      src={player.source?.videoLink || player.source?.src} 
                      startTime={Date.parse(player.startTime)}
                    />
                    <Box className={classes.block} />
                    {muted &&  
                      <Box onClick={() => setMuted(false)} className={classes.mutedBtn}>
                          <Box pr="8px"><VolumeOff /></Box>
                          <Typography>
                            Нажмите, чтобы включить звук
                          </Typography>
                        </Box>
                      }
                      <Box position="absolute" bottom="12px" left="8px" >
                        <VolumeControl 
                          volume={volume} 
                          muted={muted} 
                          handleMute={(value) => setMuted(value)}
                          handleVolumeChange={(value) => setVolume(value)}
                        />
                      </Box>
                  </Wrap>
              }
            </Grid>
            </Box>
          </Paper>
       </Grid>
  )
}
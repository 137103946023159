import {IPlayer, IUser} from '../../types';
import {
  PLAYER_CHAT_SETTINGS_CHANGE,
  PLAYER_CLOSE,
  PLAYER_FETCH_ATTEMPT,
  PLAYER_FETCH_ERROR,
  PLAYER_FETCH_SUCCESS,
  PLAYER_REDIRECT_LINK_CHANGE,
  PLAYER_SET_MEMBER,
  TPlayerActionType,
  UPDATE_SPLASH_SCREEN,
} from './types';

interface IPlayerInitialState {
  player?: IPlayer;
  member?: IUser;
  loading: boolean;
  error?: 'not-found' | boolean;
  userData: Partial<IUser>;
}

const initialState: IPlayerInitialState = {
  loading: false,
  userData: {}
};

export default function playerReducer(
  state = initialState,
  action: TPlayerActionType
) {
  switch (action.type) {
    case PLAYER_FETCH_ATTEMPT:
      return {
        ...state,
        loading: true,
      };

    case PLAYER_FETCH_SUCCESS:
      return {
        ...state,
        player: action.player,
        loading: false,
      };
    case PLAYER_FETCH_ERROR:
      return {
        ...state,
        error: action.error
      }
    case PLAYER_SET_MEMBER:
      return {
        ...state,
        member: action.member,
      };

    case PLAYER_CLOSE:
      return initialState;

    case PLAYER_REDIRECT_LINK_CHANGE:
      if (state.player === undefined) {
        return {...state};
      }
      return {
        ...state,
        player: {
          ...state.player,
          redirectLink: action.redirectLink
        }
      };
    case PLAYER_CHAT_SETTINGS_CHANGE:
      if (state.player === undefined) {
        return {...state};
      }
      return {
        ...state,
        player: {
          ...state.player,
          chatSettings: action.settings
        }
      };
    case UPDATE_SPLASH_SCREEN:
      if (state.player === undefined) {
        return {...state};
      }
      return {
        ...state,
        player: {
          ...state.player,
          splashScreen: action.params
        }
      };
    default:
      return state;
  }
}

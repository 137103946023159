import * as React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Divider, MenuItem, MenuList } from "@material-ui/core";
import { SvgIcon } from "../../components";
import { themeVariables } from "../_Theme";

export interface INav {
  to?: string;
  href?: string;
  target?: string;
  onClick?: any;
  selected?: boolean;
  label?: string;
  noactive?: boolean;
  iconType?: string;
  count?: number;
};

interface IProps {
  nav: INav[];
};

const useStyles = makeStyles({
  list: {
    "& .Mui-selected":{
      borderColor: themeVariables.palette.grey[200],
      borderWidth: "1px",
      borderStyle: "solid",
      backgroundColor: "#FFF",
    },

    "& .Mui-selected:hover":{
      backgroundColor: "#FFF",
    },

    "& .Mui-selected::before":{
      width: 0,
    },
  },

  item: {
    height: "32px",
    width: "200px",
    borderRadius: themeVariables.shape.borderRadius,
    paddingLeft: "8px",
    paddingRight: "8px",
    color: "#2C3A98",
    boxSizing: "border-box",

    "&:hover": {
      backgroundColor: themeVariables.palette.grey.A100,
    },

    "&:not(:last-child)": {
      marginBottom: "3px",
    }
  },

  icon: {
    paddingRight: "8px",
  },

  label: {
    maxWidth: "134px",
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  count: {
    lineHeight: "18px",
    minWidth: "18px",
    color: "#fff",
    backgroundColor: "#FF3048",
    borderRadius: "9px",
    textAlign: "center",
    fontWeight: 600,
    marginLeft: "4px",
  },

  divider: {
    maxWidth: "130px",
    margin: "10px auto 6px",
    backgroundColor: themeVariables.palette.grey[200],
  },
});

export const MUIMainMenu = ({ nav }: IProps) => {
  const classes = useStyles();

  return (
    <MenuList className={classes.list}>
      <nav>
        {nav.map((item: INav, idx: number) => (
          item.label
           ? (
            <MenuItem
              component={item.href ? "a" : item.to ? Link : "li"}
              to={item.to}
              href={item.href}
              target={item.target}
              onClick={item.onClick}
              rel="noopener noreferrer"
              selected={
                !item.noactive &&
                (item.selected ||
                  `${window.location.pathname}${window.location.search}` ===
                  item.href ||
                  `${window.location.pathname}/${window.location.search}` ===
                  item.href)
              }
              className={classes.item}
              key={`menu-item-${item.label}`}
            >
              {item.iconType &&
                <span className={classes.icon}>
                  <SvgIcon type={item.iconType} />
                </span>
              }
              <span className={classes.label}>{item.label}</span>
              {item.count &&
              <span className={classes.count}>{item.count}</span>}
            </MenuItem>
           )
           : <Divider className={classes.divider} key={`menu-item-${idx}`} />
        ))}
      </nav>
    </MenuList>
  );
};

import {IAdsLayout, IPresentation, IWebinarSource, TStreamLayout} from "../../types"

export const PRESENTATION_CHANGE = 'PRESENTATION_CHANGE'
export const STREAM_OPEN = 'STREAM_OPEN'
export const STREAM_START = 'STREAM_START'
export const STREAM_END = 'STREAM_END'
export const UPDATE_STREAM_STATUS = 'UPDATE_STREAM_STATUS'
export const ADS_CHANGE = 'ADS_CHANGE'
export const ADS_LIST_INIT = 'ADS_LIST_INIT'
export const ADS_LIST_CHANGE = 'ADS_LIST_CHANGE'
export const LAYOUT_CHANGE = 'LAYOUT_CHANGE'
export const MEMBERS_ONLINE_UPDATE = 'MEMBERS_ONLINE_UPDATE'
export const MEMBERS_AVAILABLE_UPDATE = 'MEMBERS_AVAILABLE_UPDATE'
export const UPDATE_STREAM_STATE = 'UPDATE_STREAM_STATE'
export const SOURCE_CHANGE = 'SOURCE_CHANGE'
export const START_TIME_CHANGE = 'START_TIME_CHANGE'
export const CLEAR_STREAM_STATE = 'CLEAR_STREAM_STATE'



export interface IClearStreamStateAction {
    type: typeof CLEAR_STREAM_STATE;
}
export interface IChangeStreamStatusAction {
    type: typeof UPDATE_STREAM_STATUS
    status: boolean
}

export interface IStreamStartAction {
    type: typeof STREAM_START
    params: {
        webinarLayout: TStreamLayout;
        presentation?: IPresentation;
        presentationPage?: number;
        ad: Array<IAdsLayout>

    }
}


export interface IStreamOpenAction {
    type: typeof STREAM_OPEN
    id: string
}

export interface IStreamEndAction {
    type: typeof STREAM_END
}

export interface IPresentationChangeAction {
    type: typeof PRESENTATION_CHANGE
    params: {
        presentation?: IPresentation;
        presentationPage?: number;
        presentationLink?: string;
    }
}

export interface ISourceChangeAction {
    type: typeof SOURCE_CHANGE
    params: IWebinarSource
}

export interface IStartTimeChangeAction {
    type: typeof START_TIME_CHANGE
    params: number
}

export interface IAdsChangeAction {
    type: typeof ADS_CHANGE
    params: Array<IAdsLayout>
}

export interface IAdsListInitAction {
    type: typeof ADS_LIST_INIT
    params: Array<IAdsLayout>
}

export interface IAdsListChangeAction {
    type: typeof ADS_LIST_CHANGE
    params: Array<IAdsLayout>
}

export interface ILayoutChangeAction {
    type: typeof LAYOUT_CHANGE
    layout: TStreamLayout
}

export interface IMembersOnlineUpdateAction {
    type: typeof MEMBERS_ONLINE_UPDATE
    members: number
}

export interface IMembersAvailableUpdateAction {
    type: typeof MEMBERS_AVAILABLE_UPDATE
    available: number
}




export type TStreamEvent =
    "members" |
    "open" |
    "start" |
    "video" |
    "end" |
    "message" |
    "layout" |
    "presentation" |
    "ads" |
    "chat" |
    "alert"


export type TStreamActions =
    IStreamOpenAction |
    IStreamStartAction |
    IStreamEndAction |
    IChangeStreamStatusAction |
    IPresentationChangeAction |
    IAdsChangeAction |
    IAdsListInitAction |
    IAdsListChangeAction |
    ILayoutChangeAction |
    IMembersOnlineUpdateAction |
    IMembersAvailableUpdateAction |
    ISourceChangeAction |
    IClearStreamStateAction |
    IStartTimeChangeAction
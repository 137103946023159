import {v4 as uuid} from 'uuid'
import { TNotificationType } from ".";
import { INotificationAddAction, INotificationRemoveAction, NOTIFICATION_ADD, NOTIFICATION_REMOVE, CHAT_NOTIFICATION_ADD, CHAT_NOTIFICATION_REMOVE} from "./types";


export function addNotification(type:TNotificationType, message: string):INotificationAddAction {
    const id = uuid()
    return {
        type: NOTIFICATION_ADD,
        message: message,
        notificationType: type,
        id
    }
}

export function removeNotification(id:string):INotificationRemoveAction {
    return {
        type: NOTIFICATION_REMOVE,
        id
    }
}
export function addChatNotification(message: string) {
    const id = uuid()
    return {
        type: CHAT_NOTIFICATION_ADD,
        message,
        id
    }
}

export function removeChatNotification(id:string) {
    return {
        type: CHAT_NOTIFICATION_REMOVE,
        id
    }
}
import { Middleware, Store } from "redux";
import { api } from "../../api";
import { ETaskStatus, Queues, TQueueName } from "../../types/Queue";
import { setTasks } from "../tasks/actions";
import { SUBSCRIBE_TO_QUEUE, TTasksQueueAction, UNSUB_ALL, UNSUB_BY_QUEUE_NAME } from "./types";


export default function tasksQueueMiddleware(store: Store): Middleware {
  const queues: Queues = {
    tickets: {
      interval: 0,
    },
  }
  async function getQueueTasks(queueName: TQueueName, webinarId: string) {
    try {
      const response = await api.tasks.list(queueName, webinarId);
      const tasksInQueue = response.data;
      const isDone = tasksInQueue.every(task => [ETaskStatus.FAILED, ETaskStatus.READY, ETaskStatus.WRONG_FORMAT].includes(task.status));
      if (isDone || tasksInQueue.length === 0) {
        window.clearInterval(queues[queueName].interval);
      }
      store.dispatch(setTasks(queueName, tasksInQueue));
    } catch (error) {
      window.clearInterval(queues[queueName].interval);
    }
  }

  function watchQueue(queueName: TQueueName, webinarId: string) {
    window.clearInterval(queues[queueName].interval);
    queues[queueName].interval = window.setInterval(getQueueTasks, 500, queueName, webinarId);
  }

  function unsubscribeAll() {
    (Object.keys(queues) as Array<TQueueName>).forEach((queue) => window.clearInterval(queues[queue].interval));
  }

  function unsubscribeByQueueName(queueName: TQueueName) {
    window.clearInterval(queues[queueName].interval);
  }

  return store => next => (action: TTasksQueueAction) => {
    switch (action.type) {
      case SUBSCRIBE_TO_QUEUE:
        watchQueue(action.queue, action.webinarId)
        break;
      case UNSUB_ALL:
        unsubscribeAll();
        break;
      case UNSUB_BY_QUEUE_NAME:
        unsubscribeByQueueName(action.queueName);
        break;
      default:
        next(action);
        break;
    }
  }
}
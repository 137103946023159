

import { SvgIcon, SvgIconProps } from "@material-ui/core"


export const TrashBin: React.FC<SvgIconProps> = ({...props}) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
        <rect fill="none" width="20" height="20"/>
        <g transform="translate(-194 -106)">
            <rect fill="currentColor" width="12" height="11" rx="1" transform="translate(198 113)"/>
            <rect fill="currentColor" width="14" height="3" rx="1" transform="translate(197 109)"/>
            <rect fill="currentColor" width="4" height="4" rx="1" transform="translate(202 108)"/>
        </g>
    </SvgIcon>
  );
}

export default TrashBin;
import { makeStyles } from '@material-ui/core';

export const useScrollbarStyles = makeStyles((theme) => ({
  scrollbar: {
    overflow: 'overlay',
    fallbacks: [
      {
        overflow: 'auto',
      },
    ],
    '&::-webkit-scrollbar': {
      width: 8,
      height: 8,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.light,
      borderRadius: 7,
      border: '2px solid transparent',
      backgroundClip: 'content-box',

      '&:hover': {
        backgroundClip: 'border-box',
      },
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
  },
}));

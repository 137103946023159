import {
  EReportStatus,
  IEpisodeStatistics,
  IStatisticsEpisodeMeta,
  IStatisticsMeta,
  IUserStatistic,
  IWebinarStatistic,
} from '../../types';
import {
  STATISTICS_MEMBERS_FETCH_ATTEMPT,
  STATISTICS_MEMBERS_FETCH_FAILURE,
  STATISTICS_MEMBERS_FETCH_SUCCESS,
  STATISTICS_WEBINARS_FETCH_ATTEMPT,
  STATISTICS_WEBINARS_FETCH_FAILURE,
  STATISTICS_WEBINARS_FETCH_SUCCESS,
  STATISTICS_AUTOWEBINAR_EPISODES_FETCH_ATTEMPT,
  STATISTICS_AUTOWEBINAR_EPISODES_FETCH_FAILURE,
  STATISTICS_AUTOWEBINAR_EPISODES_FETCH_SUCCESS,
  TStatisticsAction,
  STATISTICS_CLEAR_STATE,
  EPISODE_STATISTICS_FETCH_ATTEMPT,
  EPISODE_STATISTICS_FETCH_SUCCESS,
  EPISODE_STATISTICS_FETCH_FAILURE,
  UPDATE_REPORT_EXPORT_STATUS,
  UPDATE_REPORT_STATUS,
} from './types';

export interface IStatisticsInitialState {
  webinars: Array<IWebinarStatistic>;
  autowebinarEpisodes: Array<IWebinarStatistic>;
  autowebinarEpisodesMeta: IStatisticsEpisodeMeta,
  members: Array<IUserStatistic>;
  episode?: IEpisodeStatistics;
  countWebinars: number;
  countAutowebinarEpisodes: number;
  countMembers: number;
  loading: boolean;
  loadingNext: boolean;
  error?: string;
  ajaxIDs: number[];
  membersMeta: IStatisticsMeta,
  exportFile: {
    status: EReportStatus,
    reportId?: string,
  },
}

const initialState: IStatisticsInitialState = {
  webinars: [],
  autowebinarEpisodes: [],
  autowebinarEpisodesMeta: {
    clicks: 0,
    comments: 0,
    members: 0,
  },
  members: [],
  membersMeta: {
    blocked: 0,
    clicks: 0,
    comments: 0,
    members: 0,
    unblockPrice: 0,
    status: EReportStatus.INQUEUE
  },
  countWebinars: 0,
  countAutowebinarEpisodes: 0,
  countMembers: 0,
  loading: false,
  loadingNext: false,
  exportFile: {
    status: EReportStatus.INQUEUE,
  },
  ajaxIDs: [],
};

export default function statisticsReducer(
  state = initialState,
  action: TStatisticsAction
) {
  switch (action.type) {
    case STATISTICS_MEMBERS_FETCH_ATTEMPT:
      return {
        ...state,
        loading: action.offset ? false : true,
        loadingNext: action.offset ? true : false,
        membersMeta: {
          ...state.membersMeta,
          status: action.offset ? state.membersMeta.status : EReportStatus.INQUEUE
        },
      };
    case STATISTICS_MEMBERS_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loadingNext: false,
        members: action.offset === 0 ? action.list : [
          ...state.members,
          ...action.list
        ],
        countMembers: action.count,
        membersMeta: action.meta
      };
    case STATISTICS_MEMBERS_FETCH_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case STATISTICS_WEBINARS_FETCH_ATTEMPT:
      return {
        ...state,
        loading: true,
        ajaxIDs: [...state.ajaxIDs, action.id],
      };
    case STATISTICS_WEBINARS_FETCH_SUCCESS:
      if (!state.ajaxIDs.includes(action.id)) return state;
      return {
        ...state,
        loading: false,
        error: undefined,
        webinars: state.webinars.concat(action.list),
        countWebinars: action.count,
      };
    case STATISTICS_WEBINARS_FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case STATISTICS_AUTOWEBINAR_EPISODES_FETCH_ATTEMPT:
      return {
        ...state,
        loading: true,
        ajaxIDs: [...state.ajaxIDs, action.id],
      };
    case STATISTICS_AUTOWEBINAR_EPISODES_FETCH_SUCCESS:
      if (!state.ajaxIDs.includes(action.id)) return state;
      return {
        ...state,
        loading: false,
        autowebinarEpisodes: state.autowebinarEpisodes.concat(action.list),
        autowebinarEpisodesMeta: action.meta,
        countAutowebinarEpisodes: action.count,
      };
    case STATISTICS_AUTOWEBINAR_EPISODES_FETCH_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case EPISODE_STATISTICS_FETCH_ATTEMPT:
      return {
        ...state,
        loading: true,
        ajaxIDs: [...state.ajaxIDs, action.id],
      };
    case EPISODE_STATISTICS_FETCH_SUCCESS:
      if (!state.ajaxIDs.includes(action.id)) return state;
      return {
        ...state,
        loading: false,
        episode: action.episode,
      };
    case EPISODE_STATISTICS_FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case UPDATE_REPORT_STATUS: {
      return {
        ...state,
        membersMeta: {
          ...state.membersMeta,
          status: action.status
        },
      };
    }
    case UPDATE_REPORT_EXPORT_STATUS: {
      if (!state.exportFile.reportId) {
        return {
          ...state,
          exportFile: {
            ...state.exportFile,
            reportId: action.reportId,
            status: action.status
          }
        }
      }
      if (action.reportId !== undefined && (state.exportFile.reportId === action.reportId)) {
        return {
          ...state,
          exportFile: {
            ...state.exportFile,
            status: action.status,
            reportId: action.reportId
          }
        }
      }
      return state;
    }
    case STATISTICS_CLEAR_STATE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}

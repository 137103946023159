import { SvgIcon, SvgIconProps } from "@material-ui/core"
import { forwardRef } from "react";


export const PlayOutlined: React.FC<SvgIconProps> = forwardRef(({...props}, ref) => {
  return (
    <SvgIcon {...props} viewBox="0 0 18 18" width="16" height="16" innerRef={ref}>
      <g transform="translate(-4.5 -1)">
        <path fill="none" d="M3,3V16.843H17V3Z" transform="translate(3 -0.229)"/>
        <g stroke="currentColor" transform="translate(5 1.708)">
          <rect fill="none" width="16" height="16" rx="8"/>
          <rect fill="none" x="1" y="1" width="14" height="14" rx="7"/>
        </g>
        <path fill="currentColor" d="M17.218,9.664,12.6,6.9A.384.384,0,0,0,12,7.2v5.523a.384.384,0,0,0,.6.294l4.636-2.761a.33.33,0,0,0-.018-.588Z" transform="translate(-1.2 -0.265)"/>
      </g>
    </SvgIcon>
  );
})

export default PlayOutlined;
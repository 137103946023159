import { useState } from "react";
import { Box, Button, MenuItem, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ExpandMore } from "@material-ui/icons";
import { tl } from "../../utils";
import { MUISelect, themeDefault } from "../../MUI";

import styles from "./styles.module.scss";
import { ContextMenu } from "../core/ContextMenu";
import { PointsMenuItem } from "../PointsMenu/menuItem";
import { IMessage } from "../../types";

interface IFilteredMessage extends IMessage {
  messageNumber: number
}

interface WebinarEventAlertProps {
  content: string;
  author: string;
  role: string;
  messages: IMessage[]
  currentMessage?: {
    id: string
    message: string
  };
  editable: boolean;
  webinarId: string
  onChange?: (params: {id: string; message: string }) => void;
}

const useStyles = makeStyles({
  root: {
    lineHeight: 1.27,
  },

  title: {
    fontWeight: 600,
    lineHeight: "30px",
    lineClamp: 2,
    textOverflow: 'ellipsis',
    '& > span': {
      fontWeight: 'normal'
    }
  },

  authorModerator: {
    color: themeDefault.palette.marks[2],
  },

  authorUser: {
    color: themeDefault.palette.marks[0],
  },
  selectWrap: {
    display: 'flex',
    alignItems: 'center'
  },
  menuItem: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 300

  },
  messagePreview: {
    lineClamp: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }
});

export const WebinarEventAlert = (
  props: WebinarEventAlertProps,
) => {
  const classes = useStyles();
  const [currentMessageId, setCurrentMessageId] = useState(props.currentMessage?.id)

 

  const [isChanging, setIsChanging] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  const onKeydown = (event: any) => {
    event.key === "Escape" && handleCancel();
  }

  const openSettings = () => {
    if (props.editable && !isChanging) {
      setIsChanging(true);
      document.addEventListener("keydown", onKeydown);
    }
  };

  const closeSettings = () => {
    setIsChanging(false);
    document.removeEventListener("keydown", onKeydown);
  }

  const handleCancel = () => {
    closeSettings();
    setIsChanged(false);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    closeSettings();
    if (currentMessageId) {
      const currentMessage = props.messages.find(m => m.id === currentMessageId)!
      props.onChange?.({
        message: currentMessage.value,
        id: currentMessage.id
      });
    }
  }

  const handleMessageChange = (messageId: string) => {
    setIsChanged(true);
    setCurrentMessageId(messageId)
  }

  const handleAddMessage = () => {
    window.open(`/webinar/${props.webinarId}/chat-settings`, "_blank");
  }

  return (
    <Box
      px="10px"
      className={isChanging ? "" : styles.changeBox}
      onClick={openSettings}
    >
      <Typography className={classes.title}>
        {tl("Объявление модератора.")}
      </Typography>

      {!isChanging &&
        <div className={classes.messagePreview}>
          { props.currentMessage?.message || 'Не выбран' }
        </div>
      }
      {props.editable &&
        <Box display={isChanging ? "none" : ""} className={styles.changeBoxIcon}>
          <ExpandMore />
        </Box>
      }

      {isChanging && (
        <form onSubmit={handleSubmit}>
          <Box className={styles.changeBoxSettings}>
            <Box className={classes.selectWrap} pb="12px">
              <MUISelect
                variant="outlined"
                fullWidth
                value={props.currentMessage?.id}
              >
                {props.messages.length < 3 &&
                  <MenuItem onClick={handleAddMessage}>
                    Создать новое объявление
                  </MenuItem>
                }
                {props.messages.map((m) =>
                  <MenuItem value={m.id} key={m.id} onClick={() => handleMessageChange(m.id)} >
                    <Box className={classes.menuItem}>
                      «{m.value}»
                    </Box>
                  </MenuItem>
                )}
              </MUISelect>
              <Box ml={1}>
                <ContextMenu boxProps={{ pr: 0 }}>
                  <PointsMenuItem title={tl('Редактировать')} action={handleAddMessage} />
                </ContextMenu>
              </Box>
            </Box>
            <Box className={styles.changeBoxButtons}>
              <Box pr="8px">
                <Button
                  variant="contained"
                  onClick={handleCancel}
                >
                  Отмена
                </Button>
              </Box>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={!isChanged}
              >
                Сохранить
              </Button>
            </Box>
          </Box>
        </form>
      )}
    </Box>
  );
}
import React from 'react';
import { makeStyles } from '@material-ui/core';
import { TValidationLevel, validationColorMap, validationDictMap } from './utils';

const useStyles = makeStyles(({
  root: {display: 'flex', flexFlow: 'column nowrap'},
  barsWrap: {display: 'flex', flexFlow: 'row nowrap', gap: 8, },
  bar: {display: 'flex', flex: 1, height: 4, borderRadius: 2, backgroundColor: '#b6b6b6' },
  hint: {color: '#b6b6b6', margin: 0, fontSize: 13, lineHeight: '15px'},
  hintWrap: {marginTop: 10, display: 'flex', flexFlow: 'column nowrap', gap: 4,}
}))

interface PasswordBarsHintProps {
  validationLevel: TValidationLevel;
  failedTests: Array<string>;
  strict?: boolean;
}

export const PasswordBarsHint: React.FC<PasswordBarsHintProps> = ({validationLevel, failedTests, strict}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
    <div className={classes.barsWrap}>
      <div className={classes.bar} style={{
        backgroundColor: !strict 
          ? validationColorMap[validationLevel] 
          : validationColorMap[validationLevel === 'valid' ? 'good' : validationLevel]
      }} />
      <div 
        className={classes.bar} 
        style={{
          backgroundColor: !strict 
            ? ['good', 'valid'].includes(validationLevel) ? validationColorMap[validationLevel]: validationColorMap['empty']
            : validationLevel === 'valid' ? validationColorMap['good']: validationColorMap['empty']
        }}/>
      {validationLevel !== 'empty' && !strict &&
        <div 
          className={classes.bar} 
          style={{
            backgroundColor: validationLevel === 'valid' ? validationColorMap[validationLevel]: validationColorMap['empty']
          }}
        />}
    </div>
    {!strict 
      ? <div className={classes.hintWrap}>
          {!!validationDictMap[validationLevel] && <p className={classes.hint} style={{color: validationColorMap[validationLevel]}}>{validationDictMap[validationLevel]}</p>}
          {validationLevel === 'empty' && 
            <p className={classes.hint}>
              Требования: введите буквы и цифры, заглавные буквы, спец. символы, больше 8 символов
            </p>
          }
          {validationLevel === 'good' && 
            <p className={classes.hint}>
              Совет:{' '}<span style={{color: failedTests.includes('numbers') ? validationColorMap['good'] : 'inherit'}}>введите буквы и цифры</span>,{' '}
              <span style={{color: failedTests.includes('capital') ? validationColorMap['good'] : 'inherit'}}>заглавные буквы</span>,{' '}
              <span style={{color: failedTests.includes('specialCharacters') ? validationColorMap['good'] : 'inherit'}}>спец. символы</span>
            </p>
          }
          {validationLevel === 'weak' && 
              <p className={classes.hint}>
                Совет: введите буквы и цифры, заглавные буквы, спец. символы
              </p>
            }
        </div>
      : <div className={classes.hintWrap}>
          {validationLevel === 'invalid' && <p className={classes.hint} style={{color: validationColorMap['invalid']}}> Пароль не соответствует всем требованиям </p>}
          {validationLevel === 'valid' &&  <p className={classes.hint} style={{color: validationColorMap['good']}}> Пароль одобрен</p>}
          {validationLevel === 'empty' && 
            <p className={classes.hint}>
              Требования: введите буквы и цифры, заглавные буквы, спец. символы, больше 8 символов
            </p>
          }
          {validationLevel === 'invalid' && 
            
            <p className={classes.hint}>
              Требования:{' '}<span style={{color: !failedTests.includes('numbers') ? validationColorMap['good'] :  validationColorMap['invalid']}}>введите буквы и цифры</span>,{' '}
              <span style={{color: !failedTests.includes('capital') ? validationColorMap['good'] : validationColorMap['invalid']}}>заглавные буквы</span>,{' '}
              <span style={{color: !failedTests.includes('specialCharacters') ? validationColorMap['good'] : validationColorMap['invalid']}}>спец. символы</span>,{' '}
              <span style={{color: !failedTests.includes('minlength') ? validationColorMap['good'] : validationColorMap['invalid']}}>от 8 символов</span>
            </p>
          }
        
        </div>
      }
  </div>
  )
}
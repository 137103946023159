import * as React from "react";
import classnames from "classnames";
import styles  from "./styles.module.scss";

interface IButtonProps {
  className?: string;
  fullWidth?: boolean;
  style?: any;
  id?: any;
  children?: any;
  onClick?: any;
  type?: any;
  disabled?: boolean;
}

export default class Button extends React.Component<IButtonProps, {}> {
  render() {
    const {children, type, ...inputProps} = this.props;

    let typeClass = "";
    if (type === "green") {
      typeClass = styles.green;
    } else if (type === "red") {
      typeClass = styles.red;
    } else if (type === "light") {
      typeClass = styles.light;
    } else if (type === "lighter") {
      typeClass = styles.lighter;
    }

    let classNames = classnames({
      [styles.Button]: true,
      [typeClass]: true,
    });
    if ("className" in this.props) {
      classNames += " " + this.props.className;
    }

    return (
      <input
        type={"onClick" in this.props ? "button" : "submit"}
        value={children}
        {...inputProps}
        className={`${classNames} button`}
      />
    );
  }
}

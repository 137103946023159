import { EWebinarType, IWebinar } from "./Webinar";

export interface IWebinarStatistic {
  id: string;
  webinar: {
    id: string;
    name: string;
    type: EWebinarType;
    poster: string;
    dateSettings: IWebinar['dateSettings'];
  };
  cost: number;
  avatar: string;
  startDate: string;
  endDate: string;
  members: number;
  blockedMembers: number;
  clicks: string;
  comments: string;
  duration: string;
  report?: IReport
}

export interface IUserStatistic {
  id: string;
  hidden?: boolean;
  isOnline: boolean;
  customFields: {
    [key: string]: string
  };
  avatar: string;
  name: string;
  location: string;
  clicks: string;
  comments: string;
  duration: string;
  durationSeconds: number | string;
  email: string;
  phone: string;
  startTime?: string;
  status?: 'Active' | 'Blocked' | 'Banned';
}

export interface IUserFilter {
  query: string;
  sortBy: string;
  sortOrder: "asc" | "desc";
}

export interface IEpisodeStatistics {
  clicks: number;
  comments: number;
  duration: string;
  endDate: string;
  id: string;
  members: number;
  startDate: string;
  webinar: {
    id: string;
    name: string;
    poster: string;
    type: number;
  }
}
export interface IStatisticsEpisodeMeta {
  members: number
  clicks: number
  comments: number
}
export interface IStatisticsMeta {
  blocked: number
  clicks: number
  comments: number
  members: number
  unblockPrice: number
  status: EReportStatus
}


export interface IReport {
  id: string;
  status: EReportStatus,
  created: string
}

export enum EReportType {
  WEBINAR = 'webinar',
  STREAM = 'stream'
}

export enum EReportStatus {
  UNKNOWN = -1,
  INQUEUE = 0,
  PROCESS = 1,
  DONE = 2
}
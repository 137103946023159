import { apiClient, IListResponse } from './_client';
import { TIntegration, TIntegrationType } from '../types';

export const get = async (id: string): Promise<TIntegration> => {
  const response = await apiClient.get<TIntegration>(`/integrations/${id}`);
  return response.data;
}

export const list = async (offset: number, limit?: number): Promise<IListResponse<TIntegration>> => {
  const response = await apiClient.get<IListResponse<TIntegration>>('/integrations', {
     params: {
       offset: offset,
       limit: limit,
     }
   });
  return response.data;
}

export const create = async (integration: TIntegration): Promise<boolean> => {
  await apiClient.post('/integrations', integration);
  return true;
}

export const update = async (integration: TIntegration): Promise<boolean> => {
  await apiClient.put(`/integrations/${integration.id}`, integration);
  return true;
}

export const remove = async (integration: TIntegration): Promise<boolean> => {
  await apiClient.delete(`/integrations/${integration.id}`);
  return true;
}

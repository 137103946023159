import { apiClient, IListResponse } from "./_client"
import { IWebinar } from "../types";
import { ITicket } from "../types/Ticket";
import { CancelToken } from "axios";
import { ITask } from "../types/Queue";

export const get = async (id: string): Promise<ITicket> => {
  const response = await apiClient.get<ITicket>('/ticket/' + id);
  return response.data;
}

export interface ITicketsGetQueryParams {
  webinarId: string, offset: number, limit?: number, search?: string
}

export const list = async (params:ITicketsGetQueryParams): Promise<IListResponse<ITicket>> => {
  const response = await apiClient.get<IListResponse<ITicket>>(`/ticket?webinar=${params.webinarId}`, {
    params: params
  });
  return response.data;
}

export const create = async (webinar: IWebinar, moderators: Partial<ITicket>[]): Promise<ITask> => {
  const response = await apiClient.post(`/ticket?webinar=${webinar.id}`, moderators);
  return response.data;
}

export const importFile = async (webinarId: string, file: File, cancelToken?: CancelToken): Promise<ITask> => {
  const formData = new FormData();
  formData.append('role', 'member')
  formData.append('file', (file as Blob));
  const response = await apiClient.post(`/ticket?webinar=${webinarId}`, formData, {
    cancelToken: cancelToken
  });
  return response.data;
}

export const save = async (ticket: ITicket): Promise<ITicket> => {
  const response = await apiClient.put<ITicket>(`/ticket/${ticket.id}`, ticket);
  return response.data as ITicket;
}

export const remove = async (ticket: ITicket): Promise<boolean> => {
  await apiClient.delete<ITicket>(`/ticket/${ticket.id}`);
  return true;
}
export const resetPassword = async (ticket: ITicket): Promise<boolean> => {
  await apiClient.post<ITicket>(`/ticket/${ticket.id}/password`);
  return true;
}

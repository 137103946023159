import { Button, styled } from "@material-ui/core";

const AddWebinarButton = styled(Button)(({theme}) => ({
  [theme.breakpoints.down('xs')]: {
    paddingLeft: 8,
    paddingRight: 8,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  '& .MuiButton-startIcon': {
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
      marginLeft: 0,
    }
  },
  '& .label': {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  }
}));


export default AddWebinarButton;
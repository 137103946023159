import { Box, makeStyles, TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { api } from '../../api';
import { MUIDialog, MUIDialogProps } from '../../MUI';
import { IWebinar } from '../../types';
import { tl } from '../../utils';
import StarIcon from '../Icon/star';

const useStyles = makeStyles((theme) => ({
  gridOfFive: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 16,
  },
  paper: {
    maxWidth: 522,
    width: 522,
  },
  dialogContent: {
    padding: 20,
  },
  textMain: {
    fontSize: 15,
    lineHeight: '22px',
  },
  textSub: {
    fontSize: 13,
    lineHeight: '15px',
    color: theme.palette.grey[600],
  },
  starContainer: {
    position: 'relative',
    '&:first-child': {
      '&:after': {
        position: 'absolute',
        content: 'attr(title)',
        left: 0,
        top: '100%',
        fontSize: 13,
        lineHeight: '15px',
        color: theme.palette.grey[600],
      },
    },
    '&:last-child': {
      '&:after': {
        position: 'absolute',
        content: 'attr(title)',
        right: 0,
        top: '100%',
        fontSize: 13,
        lineHeight: '15px',
        color: theme.palette.grey[600],
      },
    },
  },
  star: {
    fontSize: '30px',
  },
}));

interface WebinarAuthorReviewProps extends MUIDialogProps {
  webinar?: IWebinar;
}

export const WebinarAuthorReview: React.FC<WebinarAuthorReviewProps> = (
  props
) => {
  const classes = useStyles();
  const [score, setScore] = useState(0);
  const [loading, setLoading] = useState(false)
  const [hoveredScore, setHoveredScore] = useState(0);
  const [comment, setComment] = useState('');
  const handleSend = async () => {
    setLoading(true);
    await api.userReview.create({
      score: score,
      streamId: props.webinar!.stats.id,
      comment: comment,
    });
    setLoading(false);
    props.onClose!({},'backdropClick');
  };

  return (
    <MUIDialog
      {...props}
      title={tl('Пожалуйста, оцените, как прошел вебинар?')}
      PaperProps={{ square: false, className: classes.paper }}
      mobileFullScreen
      maxWidth="xs"
      fullWidth={false}
      dialogContentClassName={classes.dialogContent}
      actions={
        score > 0
          ? [
              [],
              [
                {
                  label: 'Отмена',
                  btnProps: {
                    variant: 'contained',
                    color: 'default',
                    onClick: props.onClose,
                  },
                },
                {
                  label: 'Отправить',
                  btnProps: {
                    variant: 'contained',
                    color: 'primary',
                    onClick: handleSend,
                    disabled: loading
                  },
                },
              ],
            ]
          : undefined
      }
    >
      {!score ? (
        <Box>
          <Typography className={classes.textMain}>
            {tl('Насколько хорошо платформа справилась с вашими задачами?')}
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent={'center'}
            alignItems="center"
          >
            <Box
              className={classes.gridOfFive}
              onMouseLeave={() => setHoveredScore(0)}
              mb={1.5}
            >
              {Array.from({ length: 5 }, (_v, k) => (k += 1)).map((val) => (
                <Box
                  className={classes.starContainer}
                  key={`score-${val}`}
                  m={1}
                  width={30}
                  title={
                    val === 1
                      ? tl('Плохо')
                      : val === 5
                      ? tl('Хорошо')
                      : val.toString()
                  }
                >
                  <StarIcon
                    className={classes.star}
                    onClick={() => setScore(val)}
                    filled={
                      hoveredScore > 0 ? hoveredScore >= val : score >= val
                    }
                    onMouseMove={() => setHoveredScore(val)}
                  />
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      ) : (
        <Box>
          <Box>
            <Box mb={2}>
              <Typography className={classes.textMain}>
                {score < 5
                  ? tl(
                      'Что пошло не так? Что мы могли бы улучшить, чтобы заслужить от вас 5 звезд?'
                    )
                  : tl(
                      'Что вам больше всего нравится в нашей платформе для вебинаров?'
                    )}
              </Typography>
            </Box>
            <TextField
              variant="outlined"
              name="comment"
              value={comment}
              onChange={(e) => {
                setComment(e.target.value || '')
              }}
              disabled={loading}
              placeholder={tl('Напишите ваши пожелания')}
              fullWidth
              rows={3}
              rowsMax={3}
              multiline
            />
          </Box>
        </Box>
      )}
    </MUIDialog>
  );
};

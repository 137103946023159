import * as React from "react";
import {CardTab} from "../CardTab";
import {ITab, typeTabList} from "../../";
import styles  from "./styles.scss";
import {themeVariables} from "../../../../MUI";

interface ICardTabsState {
  activeTab: any;
}

interface IProps {
  tabs: typeTabList;
  defaultActiveTab: any;
  onChangeTab: any;
}

export class CardTabs extends React.Component<IProps, ICardTabsState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      activeTab: this.props.defaultActiveTab,
    };
  }

  tabChangeHandler = (tab: ITab) => {
    this.setState({
      activeTab: tab.id,
    });

    if (this.props.onChangeTab) {
      this.props.onChangeTab(tab);
    }
  };

  renderTabs() {
    const {tabs} = this.props;
    const {activeTab} = this.state;

    return tabs.map((tab) => (
      <CardTab
        {...tab}
        key={tab.id}
        isActive={activeTab === tab.id}
        onClick={this.tabChangeHandler}
      />
    ));
  }

  render() {
    return (
      <div
        className={styles.cardTabs}
        style={{
          borderBottom: themeVariables.border,
        }}
      >
        <div className={styles.cardTabsSwitcher}>{this.renderTabs()}</div>
      </div>
    );
  }
}

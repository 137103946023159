import React from 'react';

import { Typography } from "@material-ui/core";
import { MUIDialog } from '../../MUI';
import { tl } from "../../utils";

interface RedirectModalProps {
  action?: (() => void);
  close?: (() => void);
  open: boolean;
}

export const RedirectModal: React.FC<RedirectModalProps> = ({action, close, open}) => {
  return (
    <MUIDialog
      title={tl("Покинуть страницу?")}
      open={open}
      mobileFullScreen
      maxWidth="xs"
      onClose={close}
      actions={[
        [],
        [
          {
            label: tl("Покинуть страницу"),
            btnProps: {
              onClick: () => {
                action && action();
              },
            },
          },
          {
            label: tl("Остаться на странице"),
            btnProps: {
              color: "primary",
              onClick: () => {
                close && close();
              },
            },
          },
        ],
      ]}
    >
      <Typography>
        {tl("Вы изменили информацию на странице. Изменения не будут сохранены, если вы покинете страницу")}
      </Typography>
    </MUIDialog>
  )
}

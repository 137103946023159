import * as React from "react";
import {useState} from "react";
import {MUIDialog, MUIPreloader} from "../../MUI";
import {Box, Button, Fade, MenuItem, Select, Typography} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {tl} from "../../utils";
import {IAdsLayout} from "../../types";
import { PointsMenu } from "..";
import { useParams } from "react-router-dom";
import { ExpandMore } from "@material-ui/icons";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

import styles  from "./styles.module.scss";
import { PlusIcon } from "../Icon/PlusIcon";
import SvgIcon from "../SvgIcon";
import { setupBaseUrl } from "../../api/_client";
import AdsList from "../AdsLayouts/List";
import TickingClockIcon from "../Icon/tickingClock";
import { secondsToHumanReadable } from "../../utils/time";

interface WebinarEventAdsProps {
  editable?: boolean;
  adsLayout: Array<IAdsLayout>;
  onChange?: (adsLayout: Array<IAdsLayout>) => void;
}

const useStyles = makeStyles((theme)=>({
  root: {
    paddingTop: "6px",
  },
  select: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 320,
    width: 263,
  },
  firstAndUseless: {
    color: theme.palette.grey[600]
  },
  previewOverlay: {
    // display: 'none',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    //@ts-ignore
    backgroundColor: theme.palette.default.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',


  },
  previewText: {
    fontFamily: 'SF UI Text',
    fontSize: 13,
    lineHeight: 1.2,
    fontWeight: 'bolder',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
     //@ts-ignore
    color: theme.palette.primary.dark,
    '& i': {
      marginRight: 6,
    },
  },
  preview: {
    position: 'relative',
    width: 206,
    height: 116,
    border: '1px solid #D3D9DE',
    borderRadius: 2,
    opacity: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    justifyContent: 'center',
    "& img": {
      display: 'flex',
      // width: '100%',
    }
  },
  menuItem: {
    font: 'normal normal normal 13px/21px SF Pro Text',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.dark,
    '& svg': {
      fontSize: 14,
      marginRight: theme.spacing(.5),
    }
  },
}));

const defaultAd = {
  id: 'off',
  name: 'any',
  buttons: [],
}


export const WebinarEventAds = (
  props: WebinarEventAdsProps,
) => {
  const classes = useStyles();
  const {list, loading} = useSelector((state: RootState) => state.ads);
  const {webinarId} = useParams() as {webinarId: string};
  const [hoverPreview, setHoverPreview] = useState(false);
  const [showPreviewDialog, setShowPreviewDialog] = useState(false);
  const [ads, setAds] = useState<Array<IAdsLayout> >(props.adsLayout);
  const [savedAd, setSavedAd] = useState<Array<IAdsLayout>>(props.adsLayout);
  const [, setLastAd] = useState<Array<IAdsLayout>>(props.adsLayout);
  const [isChanging, setIsChanging] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const onKeydown = (event: any) => {
    event.key === "Escape" && handleCancel();
    event.key === "Enter" && handleSubmit();
  };

  const openSettings = () => {
    if (props.editable) {
      setIsChanging(c => !c);
      document.addEventListener("keydown", onKeydown);
    }
  };

  const closeSettings = () => {
    setIsChanging(false);
    document.removeEventListener("keydown", onKeydown);
  };

  const handleCancel = () => {
    closeSettings();
    setAds(savedAd);
    setIsChanged(false);
  };

  const handleSubmit = (event?: any) => {
    event && event.preventDefault();
    closeSettings();
    props.onChange && ads && props.onChange(ads.filter(ad => ad.id !== 'off'));
    setSavedAd(ads);
  };

  const handleAdEdit = (index: number) => window.open(`/webinar/${webinarId}/ads-layouts/${ads[index] && ads[index].id}`, "_blank");

  const handleDelete = (index: number) => {
    const newAds = ads.filter((ad, idx) => idx !== index);
    setAds(newAds);
  }

  const handleAddMore = () => {
    setAds([
      ...ads,
      {
        id: 'off',
        name: '',
        buttons: []
      }
    ])
  }
  const handleLayoutEdit = (ad:IAdsLayout, index: number) => {
    const prevAds = [...ads];
    let newAds: Array<IAdsLayout>;
    if(index === 0) {
      newAds = [
        ad,
        ...prevAds.slice(1)
      ]
    }
    else {
      newAds = [
        ...prevAds.slice(0, index),
        ad,
        ...prevAds.slice(index+1)
      ]
    }
    setAds(newAds);
  }


  return (
    <Box className={!isChanging ? styles.changeBox : ''} px={'10px'}>
      <Box onClick={!isChanging ? openSettings : () => {}} >
        <Typography
          classes={{root: classes.root}}
          className={styles.presentationTitle}
        >
          <b>{tl("Продающий блок. ")}</b>

          {ads.filter(ad => ad.id !== 'off').length === 0 && tl("Не выбран")}
        </Typography>
        {props.editable &&
          <Box className={styles.changeBoxIcon}>
              <ExpandMore />
          </Box>
        }
      </Box>
      {isChanging && (
        loading ? <MUIPreloader /> : (
          <form onSubmit={handleSubmit}>
            {
              ads.map((ad, index) =>
                <Box key={ad.id + '' + index + 'formItem'} className={styles.changeBoxSettings}>
                  <Box className={styles.changeBoxRow}>
                    <Box mr={1}>
                      <span>{index + 1}: {' '}</span>
                    </Box>
                    <Box maxWidth="100%" width="100%" display="flex">
                      <Select
                        variant="outlined"
                        fullWidth
                        value={ad.id}
                        classes={{
                          select: classes.select,
                          root: ad.id === 'off' ? classes.firstAndUseless: classes.menuItem
                        }}
                        onChange={(event: any) => {
                          const value = event.target.value;

                          let selectedAd = list.find(
                            item => item.id === value
                          );
                          if (value === 'off') {
                            selectedAd = defaultAd;
                          }
                          if(!selectedAd) return;
                          setLastAd(ads);
                          handleLayoutEdit(selectedAd, index);
                          setIsChanged(true);
                        }}
                      >
                        <MenuItem
                            value={"off"}
                            key={ad.id + index}
                            className={classes.firstAndUseless}
                          >
                            Выберите продающий блок
                          </MenuItem>
                        {list.map((ad) => (
                          <MenuItem
                            value={ad.id}
                            key={ad.id}
                            className={classes.menuItem}
                          >
                            {
                              ad.timer && <><TickingClockIcon seconds={25} /><span>{secondsToHumanReadable(ad.timer.time)}.{'\u00A0'} </span></>
                            }
                            {ad.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                    <Box pl="10px">
                      <PointsMenu menuItems={
                        ad.id && ad.id !== 'off' ?
                        [{
                          id: 0,
                          title: tl("Редактировать"),
                          action: () => {
                            handleAdEdit(index);
                          },
                        },
                        {
                          id: 1,
                          title: tl("Удалить"),
                          action: () => {
                            handleDelete(index);
                          },
                        },]:
                        [
                          {
                            id: 1,
                            title: tl("Удалить"),
                            action: () => {
                              handleDelete(index);
                            },
                          }
                        ]
                      } />
                    </Box>
                  </Box>
                </Box>
              )
            }
          <Box mt={1} />
          <Button
            startIcon={<PlusIcon />}
            variant="contained"
            color="default"
            onClick={handleAddMore}
          >
            Блок
          </Button>
          {
            ads.length > 0 && ads.filter(ad => !!ad.preview).length > 0 &&
            <Box
              className={classes.preview}
              mt={1}
              pb={1}
              onMouseEnter={() => setHoverPreview(true)}
              onMouseLeave={() => setHoverPreview(false)}
            >
              {
                ads.filter(ad => !!ad.preview).map((filtreAd, idx) =>
                  <img key={filtreAd.preview!.id + idx + 'filtered2'} src={`${filtreAd.preview!.link}` } alt="preview"/>
                )
              }
              <Fade
                in={hoverPreview}
                timeout={200}
                unmountOnExit
              >
                <div className={classes.previewOverlay} onClick={() => setShowPreviewDialog(true)}>
                  <Typography className={classes.previewText}><SvgIcon notimage type="courses-preview" /> Предпросмотр</Typography>
                </div>
              </Fade>
            </Box>
          }

          <Box className={styles.changeBoxButtons} mt={1}>
            <Box pr="8px">
              <Button
                variant="contained"
                onClick={handleCancel}
              >
                Отмена
              </Button>
            </Box>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={!isChanged}
            >
              Сохранить
            </Button>
          </Box>
          </form>
        )
      )}
      {
          !isChanging && ads.length > 0 && ads.filter(ad => !!ad.preview).length > 0 &&
            <Box
              className={classes.preview}
              mt={1}
              onMouseEnter={() => setHoverPreview(true)}
              onMouseLeave={() => setHoverPreview(false)}
            >
              {
                ads.filter(ad => !!ad.preview).map((filtreAd, idx) =>
                  <img key={filtreAd.preview!.id + idx + '-filtered'} src={`${setupBaseUrl()}/file/${filtreAd.preview!.id}` } alt="preview"/>
                )
              }
              <Fade
                in={hoverPreview}
                unmountOnExit
                mountOnEnter
              >
                <div className={classes.previewOverlay} onClick={() => setShowPreviewDialog(true)}>
                  <Typography className={classes.previewText}><SvgIcon notimage type="courses-preview" /> Предпросмотр</Typography>
                </div>
              </Fade>
            </Box>
          }
      <MUIDialog
        title={tl('Предпросмотр')}
        open={showPreviewDialog}
        mobileFullScreen
        maxWidth="lg"
        onClose={() => setShowPreviewDialog(false)}
        actions={[
          [],
          [
            {
              label: tl('Закрыть'),
              btnProps: {
                color: 'primary',
                onClick: () => setShowPreviewDialog(false),
              },
            },
          ],
        ]}
        >
          <AdsList streamAds={ads} />
        </MUIDialog>
    </Box>
  );
}
import {
  Box,
  Grid,
  InputBase,
  styled,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  makeStyles,
  Typography,
  Button,
} from '@material-ui/core';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import cx from "classnames";
import { IInvoiceSort } from '../../../api/billing';
import { EBillingType, IBillingStat } from '../../../types/Billing';
import { tl } from '../../../utils';
import { Preloader } from '../../Preloader';
import SvgIcon from '../../SvgIcon';
import { SortArrow } from './headComps/sortArrow';
import { BalanceRow } from './rows/balance';
import { MembersRow } from './rows/members';
import { StreamRow } from './rows/stream';
import { useScrollbarStyles } from '../../../hooks/useScrollbarStyles';

const Input = styled(InputBase)({
  alignItems: 'center',
});

const useStyles = makeStyles((theme) => ({
  grid: {
    maxHeight: '85vh',
    overflow: 'auto'
  },
  table: {
    '& thead': {
      '& th': {
        height: 56,
        padding: theme.spacing(0, 3),
        // font: normal normal 600 13px/15px SF UI Text;
        fontWeight: 600,
        fontSize: 13,
        lineHeight: '15px',
        fontFamily: 'SF UI Text',
        color: theme.palette.grey[600],
        background: theme.palette.common.white,
        '& span': {
          color: theme.palette.grey[600],
        },
      },
    },
    '& td': {
      height: 54,
      padding: theme.spacing(0, 3),
    },
  },
  noHistoryContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: 270,
  },
  helpText: {
    fontSize: 14,
  },
  helpTextBold: {
    fontSize: 14,
    fontWeight: 600,
  },
}));

interface BillingTransactionsTableProps {
  items: Array<IBillingStat>;
  sort: IInvoiceSort;
  onSortChange: (sort: IInvoiceSort) => void;
  hasMore: boolean;
  loadMore: () => void;
  loading: boolean;
  loadingNext: boolean;
  onAddCoins: () => void;
}

export const BillingTransactionsTable: React.FC<BillingTransactionsTableProps> = ({
  items,
  sort,
  onSortChange,
  hasMore,
  loadMore,
  loading,
  loadingNext,
  onAddCoins,
}) => {
  const classes = useStyles();
  const scrollbarClass = useScrollbarStyles();

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSortChange({
      ...sort,
      search: e.target.value,
    });
  };

  return (
    // Использование id вместо ref из-за https://github.com/danbovey/react-infinite-scroller/issues/303
    <Grid container item direction="column" className={cx(classes.grid, scrollbarClass.scrollbar)} id="grid">
      {/* <Box position="sticky" zIndex={1300} top={52.5}>
        <FilterSimple search='' onFilterSend={() => {}} setSearch={() => {}} />
      </Box> */}
      <Table stickyHeader className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={4} width={'100%'}>
              <Input
                fullWidth
                data-testid="search"
                placeholder={tl(' Поиск')}
                value={sort.search}
                onChange={handleSearch}
                startAdornment={
                  <Box mr={1} >
                    <SvgIcon type="search" />
                  </Box>
                }
                endAdornment={
                  <Box style={{cursor: 'pointer'}}>
                    <SvgIcon type="close"  onClick={() => onSortChange({
                      ...sort,
                      search: ''
                    })} />
                  </Box>
                }
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell width="17%" style={{ top: 57 }}>
              <TableSortLabel
                direction={sort.sortOrder}
                active
                IconComponent={({ className }) => (
                  <SortArrow className={className} />
                )}
                onClick={() =>
                  onSortChange({
                    sortBy: 'date',
                    sortOrder: sort.sortOrder === 'asc' ? 'desc' : 'asc',
                  })
                }
              >
                {tl('Дата')}
              </TableSortLabel>
            </TableCell>
            <TableCell width="70%" style={{ top: 57 }}>{tl('Транзакция')}</TableCell>
            <TableCell style={{ top: 57 }}></TableCell>
            <TableCell align="right" style={{ top: 57 }}>
              {tl('Сумма')}
            </TableCell>
          </TableRow>
        </TableHead>
        <InfiniteScroll
          element="tbody"
          hasMore={hasMore}
          loadMore={() => {
            !loadingNext && loadMore();
          }}
          useWindow={false}
          getScrollParent={() => document.getElementById("grid")}
        >
          {!loading &&
            items.map((item) => {
              if (item.type === EBillingType.BALANCE || item.type === EBillingType.CHARGE) {
                return <BalanceRow key={item.id} {...item} />;
              } else if (item.type === EBillingType.STREAM) {
                return <StreamRow key={item.id} {...item} />;
              } else if (item.type === EBillingType.MEMBERS) {
                return <MembersRow key={item.id} {...item} />;
              }
              return null;
            })}
        </InfiniteScroll>
      </Table>
      {
        loading || loadingNext
          ? <Preloader />
          : items.length === 0
            ? !sort.search
              ?  <Box className={classes.noHistoryContainer}>
                  <Box mb={1.5}>
                    <Typography className={classes.helpTextBold}>
                      {tl('Здесь будет отображаться история вашего баланса')}
                    </Typography>
                  </Box>
                  <Box mb={2.5}>
                    <Typography className={classes.helpText} align="center">
                      {tl('Пополните баланс, чтобы проводить вебинары более')}
                      <br />
                      {tl('100 зрителей и автовебинары.')}
                    </Typography>
                  </Box>
                  <Button variant="contained" color="primary" onClick={onAddCoins}>
                    {tl('Пополнить баланс')}
                  </Button>
                </Box>
              : <Box className={classes.noHistoryContainer}>
                  <Box mb={1.5}>
                    <Typography className={classes.helpTextBold}>
                      {tl('По поиску: ')}{sort.search}, {tl('ничего не найдено.')}
                    </Typography>
                  </Box>
                </Box>
          :null
      }
    </Grid>
  );
};

// () => dispatch(push('/plans'))

import { EWebinarType, IWebinar } from "../../types";
import { TWebinarsActionType, WEBINARS_FETCH_ATTEMPT, WEBINARS_FETCH_SUCCESS, WEBINARS_ITEM_REMOVE, WEBINARS_LIST_RESET, WEBINARS_LIST_UPDATE } from "./types";

interface IWebinarsInitialState {
    lists: {
        [EWebinarType.WEBINAR]: Array<IWebinar>,
        [EWebinarType.AUTOWEBINAR]: Array<IWebinar>
    }
    count: {
        [EWebinarType.WEBINAR]: number,
        [EWebinarType.AUTOWEBINAR]: number
    }
    loading: {
        [EWebinarType.AUTOWEBINAR]?: boolean,
        [EWebinarType.WEBINAR]?: boolean
    },
    loadingMore: {
        [EWebinarType.AUTOWEBINAR]?: boolean,
        [EWebinarType.WEBINAR]?: boolean
    }
    error: {
        [EWebinarType.AUTOWEBINAR]?: string,
        [EWebinarType.WEBINAR]?: string
    }
}

const initialState:IWebinarsInitialState = {
    lists: {
        [EWebinarType.WEBINAR]: [],
        [EWebinarType.AUTOWEBINAR]: []
    },
    count: {
        [EWebinarType.WEBINAR]: 0,
        [EWebinarType.AUTOWEBINAR]: 0
    },
    loadingMore: {},
    loading:{},
    error: {}
}

export default function webinarsReducer(state=initialState, action:TWebinarsActionType) {
    switch (action.type) {
        case WEBINARS_FETCH_ATTEMPT:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    [action.webinarType]: action.more ? false : true
                },
                loadingMore: {
                    ...state.loadingMore,
                    [action.webinarType]: !!action.more
                }

            };
        case WEBINARS_FETCH_SUCCESS:
            const isAutoweb = action.webinarType === EWebinarType.AUTOWEBINAR
            const metaCount = isAutoweb ? action.meta.webinarCount : action.meta.autowebinarCount
            return {
                ...state,
                loading: {
                    ...state.loading,
                    [action.webinarType]: false
                },
                loadingMore: {
                    ...state.loadingMore,
                    [action.webinarType]: false
                },
                lists: {
                    ...state.lists,
                    [action.webinarType]: action.offset === 0 ? action.list : state.lists[action.webinarType].concat(action.list)
                },
                count: {
                    ...state.count,
                    [action.webinarType]: action.count,
                    [isAutoweb ? EWebinarType.WEBINAR : EWebinarType.AUTOWEBINAR]: metaCount
                }
            };
        case WEBINARS_ITEM_REMOVE: {
            const prevList = state.lists[action.webinarType].slice()
            const idx = prevList.findIndex(w=>w.id === action.webinarId)
            if(idx > -1) {
                return {
                    ...state,
                    lists:{
                        ...state.lists,
                        [action.webinarType]: [
                            ...prevList.slice(0,idx),
                            ...prevList.slice(idx+1)
                        ]
                    },
                    count: {
                        ...state.count,
                        [action.webinarType]:state.count[action.webinarType] - 1
                    }
                }
            } else return state;
        }
        case WEBINARS_LIST_UPDATE: {

            const prevList = state.lists[action.webinar.type].slice()
            if(prevList.length === 0) {
                return state;
            }
            const idx = prevList.findIndex(w=>w.id === action.webinar.id)
            if(idx > -1) {
                return {
                    ...state,
                    lists:{
                        ...state.lists,
                        [action.webinar.type]:[
                            ...prevList.slice(0,idx),
                            action.webinar,
                            ...prevList.slice(idx+1)
                        ]
                    }
                }
            } else {
                return {
                    ...state,
                    lists: {
                        ...state.lists,
                        [action.webinar.type]:[
                            action.webinar,
                            ...prevList
                        ]
                    },
                    count: {
                        ...state.count,
                        [action.webinar.type]:state.count[action.webinar.type] + 1
                    }
                }
            }
        }
        case WEBINARS_LIST_RESET: {
            return {
                ...state,
                lists: {
                    ...state.lists,
                    [action.webinarType]:[]
                },
                loading: {
                    ...state.loading,
                    [action.webinarType]:true
                },
                loadingMore: {
                    ...state.loadingMore,
                    [action.webinarType]: false
                }
            }
        }
        default:
            return state;
    }
}
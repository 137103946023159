import * as React from "react";
import {Link} from "react-router-dom";
import {Divider, MenuItem, MenuList} from "@material-ui/core";
import styles  from "./styles.module.scss";
import {MUIBlock} from "../Block";

export interface INav {
  to?: string;
  href?: string;
  target?: string;
  onClick?: any;
  selected?: boolean;
  label?: string;
  inner?: INav[];
  noactive?: boolean;
}

interface IProps {
  nav: INav[];
  deepness?: number;
}

export const Menu = ({nav, deepness = 0}: IProps) => {
  const MenuContent = () => (
    <MenuList className={styles.nav}>
      {[
        nav.map((navItem: INav, idx: number) => {
          return navItem !== null ? (
            navItem.label ? (
              [
                <MenuItem
                  //@ts-ignore
                  component={navItem.href ? "a" : navItem.to ? Link : undefined}
                  to={navItem.to}
                  href={navItem.href}
                  target={navItem.target}
                  onClick={navItem.onClick}
                  selected={
                    !navItem.noactive &&
                    (navItem.selected ||
                      `${window.location.pathname}${window.location.search}` ===
                      navItem.href ||
                      `${window.location.pathname}/${window.location.search}` ===
                      navItem.href)
                  }
                  className={styles.navItem}
                >
                  {navItem.label}
                </MenuItem>,
                navItem.inner ? (
                  <li className="sidebarMenuDeeper">
                    <Menu nav={navItem.inner} deepness={deepness + 1}/>
                  </li>
                ) : null,
              ]
            ) : (
              <Divider
                component="li"
                key={`menulist-${deepness}-${idx}`}
                className={styles.navDivider}
              />
            )
          ) : null;
        }),
      ]}
    </MenuList>
  );
  return deepness ? (
    <MenuContent/>
  ) : (
    <MUIBlock pInner={0} data={{style: {overflow: "hidden"}}}>
      <MenuContent/>
    </MUIBlock>
  );
};

import { styled } from "@material-ui/core";
import React, {HTMLProps } from "react";
import PlayOutlined from "../playOutlined";

const Link: React.ComponentType<Partial<HTMLProps<HTMLAnchorElement>> & {disabled?: boolean}> = styled('a')(({theme, disabled}) => ({
  fontSize: 13,
  lineHeight: '15px',
  height: 30,
  fontWeight: 600,
  textDecoration: 'none',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  paddingLeft: 8,
  paddingRight: 12,
  color: disabled ? theme.palette.grey[300] : theme.palette.primary.light,
  borderRadius: 2,
  '& svg': {
    marginRight: 4,
    fontSize: 16,
  },
  '&:hover': {
    //@ts-ignore
    background: disabled ? 'none':  theme.palette.default.main,
    color: disabled ? theme.palette.grey[300] : theme.palette.primary.dark
  }
}))

export const PlayIconHintLink: React.FC<HTMLProps<HTMLAnchorElement>> = ({children, disabled, href, ...rest}) => {

  return (
    <Link disabled={disabled} onClick={e => {
      if (disabled) {
        e.preventDefault()
      }
    }} href={disabled ? '#' : href} {...rest}><PlayOutlined />{children}</Link>
  )
}
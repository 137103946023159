import { SvgIcon, SvgIconProps } from "@material-ui/core"
import { forwardRef } from "react";


export const BreadcrumbsSeparatorIcon: React.FC<SvgIconProps> = forwardRef(({...props}, ref) => {
  return (
    <SvgIcon {...props} viewBox="0 0 6 11" innerRef={ref}>
      <path id="Контур_337" data-name="Контур 337" d="M1.5,0H0L4.5,5.5,0,11H1.5L6,5.5Z" fill="#6d6d6d"/>
    </SvgIcon>
  );
})

export default BreadcrumbsSeparatorIcon;
import React, { useCallback, useState } from "react";
import { Box, TextField, TextFieldProps } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PhoneNumber from "awesome-phonenumber";
import { ICountryCode, MUICountryCodesSelect } from "./CountryCodesSelect";

interface IProps extends Omit<TextFieldProps, "onChange"> {
  onChange: (phone: string) => void;
  wrapperClass: string;
  countryCodes: ICountryCode[];
  defaultValue?: string;
}

export interface MUIPhoneInputResult {
  value: string;
  isValid: boolean;
}

const useStyles = makeStyles({
  textField: {
    flexDirection: "row",
    alignItems: "center",
  },
  input: {
    backgroundColor: "#FFFFFF",
    '& input': {
      paddingLeft: 11,
      paddingRight: 0,
      '&::placeholder': {
        color: '#4D4E4EBF',
        fontSize: 16,
        font: "SF Pro Display"
      },
    },
    "&:hover, &:focus, &:active, &.Mui-focused": {
      backgroundColor: "#FFFFFF",
    },
  },
});

export const MUIPhoneInput = ({
  onChange, wrapperClass, countryCodes, defaultValue,...textFieldProps}: IProps) => {
  const classes = useStyles();
  const [countryCodeName, setCountryCodeName] = React.useState<string>("RU");
  const [value, setValue] = useState(defaultValue);

  const getCountryCode = useCallback((name: string) => {
    const code = countryCodes?.find(item => item.name === name);
    if (!code) return "";
    return code.code;
  }, [countryCodes])

  const parsePhone = React.useCallback(
    (dirtyValue: string, countryCode?: string) => {
      if(dirtyValue) {
        const cleanValue = dirtyValue.replace(/\D/g, "");
        const phone = new PhoneNumber(cleanValue, countryCode || countryCodeName);
        const phoneNumber = phone.getNumber("significant") || cleanValue;
        setValue(phoneNumber);
        return `${getCountryCode(countryCodeName)}${phoneNumber}`;
      } else {
        setValue('');
        return '';
      }

    },
    [countryCodeName, getCountryCode]
  );

  const handleCountryCodeChange = (name: string) => {
    setCountryCodeName(name);
    onChange(`${getCountryCode(name)}${value || ''}`);
  }

  React.useEffect(() => {
    if(defaultValue) {
      
      const phone = new PhoneNumber(defaultValue);
      
      if(phone.getRegionCode()) {
        setCountryCodeName(phone.getRegionCode());
        setValue(phone.getNumber('significant'));
        let cd = !value ? '' : `+${phone.getCountryCode()}`;
        onChange(`${cd}${phone.getNumber('significant')}`);
      }
    }
  }, [countryCodes])

  return (
    <Box display="flex" className={`${wrapperClass} ${textFieldProps.error ? 'error' : ''}`}>
      {countryCodes.length > 0 && 
        <MUICountryCodesSelect
          countryCodes={countryCodes}
          value={countryCodeName}
          onChange={handleCountryCodeChange}
          big
        />
      }
      <TextField
        variant={textFieldProps.variant || "outlined"}
        type="tel"
        placeholder="9999999999"
        {...textFieldProps}
        value={value || ''}
        onChange={(evt) => {
          onChange(parsePhone(evt.target.value));
        }}
        InputProps={{
          className: classes.input,
        }}
        className={classes.textField}
      />
    </Box>
  );
};

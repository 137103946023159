import { Box, Collapse, makeStyles } from '@material-ui/core';
import { differenceInSeconds, parseISO } from 'date-fns';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { AdsLayout, AdsLayoutProps } from '.';
import { TimerAdLayout } from './timer';
import { IAdsLayout, IAdsLayoutWithTimer } from '../../../types';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: theme.spacing(1.5,0),
  },
  timerBox: {
    padding: theme.spacing(1),
  }
}));


interface AdsLayoutWithTimerProps extends AdsLayoutProps {
  layout: IAdsLayoutWithTimer;
  editTimer?: () => void;
  removeTimer?: () => void;
  onTimerEnd?: (ads: IAdsLayout) => void;
}

export const AdsLayoutWithTimer:React.FC<AdsLayoutWithTimerProps> = forwardRef<HTMLDivElement,AdsLayoutWithTimerProps>(({
  layout, removeTimer, onTimerEnd, editTimer, ...rest
}, ref) => {
  const classes = useStyles();
  const [secondsLeft, setSecondsLeft] = useState(
    layout.timer.time - differenceInSeconds(
      new Date(),
      layout.timer.started ? 
        parseISO(layout.timer.started) : 
        new Date()
    )
  );
  const [isTimerDead, setTimerDead] = useState(false);
  const [isAdDead, setAdDead] = useState(false);
  const interval = useRef(0);
  const timeout = useRef(0);

  useEffect(() => {
    if (!rest.editable) {
      if (secondsLeft <= 0) {
        setSecondsLeft(0)
      } else {
        interval.current = window.setInterval(() => {
          setSecondsLeft(left => left - 1);
        }, 1000)
      }
    } else {
        setSecondsLeft(layout.timer.time);
    }
    return () => {
      window.clearInterval(interval.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rest.editable, layout.timer.time]);

  useEffect(() => {
    if (secondsLeft <= 0 && !rest.editable) {
      if (layout.timer.hideOnTimeout) {
        setAdDead(true);
      }
      let timerDeadTimeoutCountSeconds = 5 + secondsLeft;
      window.clearInterval(interval.current);
      if ( timerDeadTimeoutCountSeconds > 0) {
        timeout.current = window.setTimeout(() => {
          setTimerDead(true);
        }, timerDeadTimeoutCountSeconds * 1000);
      }  else {
        setTimerDead(true);
      }
    }
    return () => {
      window.clearTimeout(timeout.current);
    }
  }, [rest.editable, onTimerEnd, secondsLeft, layout.timer.hideOnTimeout, layout, isTimerDead]);

  return (
    <div ref={ref} className={classes.root}>
      <Collapse in={!isTimerDead}>
        <Box className={classes.timerBox}>
          <TimerAdLayout 
            secondsLeft={secondsLeft}
            onRemove={removeTimer} 
            onEdit={editTimer} 
            timer={layout.timer} 
            isEditing={rest.editable} 
          />
        </Box>
      </Collapse>
      <Collapse in={!isAdDead}>
        <AdsLayout 
          nested={true}
          layout={layout}
          {...rest}
        />
      </Collapse>
    </div>
  )
})
import { TIntegration } from '../../types';
import {
  INTEGRATION_ADD,
  INTEGRATION_REMOVE,
  INTEGRATION_UPDATE,
  INTEGRATION_FETCH_ATTEMPT,
  INTEGRATION_FETCH_SUCCESS,
  INTEGRATIONS_FETCH_ATTEMPT,
  INTEGRATIONS_FETCH_SUCCESS,
  TIntegrationsAction,
} from './types';

export interface IIntegrationsInitialState {
  list: TIntegration[];
  count: number;
  loading: boolean;
  error?: string;
}

const initialState: IIntegrationsInitialState = {
  list: [],
  count: 0,
  loading: false,
};

export default function integrationsReducer(
  state = initialState,
  action: TIntegrationsAction
) {
  switch (action.type) {
    case INTEGRATION_FETCH_ATTEMPT:
      return {
        ...state,
        list: [],
        loading: true,
      };

    case INTEGRATION_FETCH_SUCCESS:
      return {
        ...state,
        list: [action.integration],
        count: 1,
        loading: false,
      };

    case INTEGRATIONS_FETCH_ATTEMPT:
      return {
        ...state,
        loading: true,
        list: []
      };

    case INTEGRATIONS_FETCH_SUCCESS:
      return {
        ...state,
        list: state.list.concat(...action.list),
        count: action.count,
        loading: false,
      };

    case INTEGRATION_ADD:
      const nextList = state.list.slice();
      nextList.push(action.integration);
      return {
        ...state,
        list: nextList,
      };

    case INTEGRATION_UPDATE: {
      const prevList = state.list.slice();
      const idx = prevList.findIndex((integration) => integration.id === action.integration.id);

      if (idx > -1) {
        prevList[idx] = action.integration;
        return {
          ...state,
          list: prevList,
        };
      }

      return {
        ...state,
      };
    }

    case INTEGRATION_REMOVE: {
      const prevList = state.list.slice();
      return {
        ...state,
        list: prevList.filter((integration) => integration.id !== action.integration.id),
        count: state.count - 1,
      };
    }

    default:
      return state;
  }
}
import {
  Box,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core';
import { format, parseJSON } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { api } from '../../api';
import { IListResponse } from '../../api/_client';
import SWLoader from '../../components/Icon/swLoader';
import { IUserReview } from '../../types/UserReviews';

type ArgumentTypes<F extends Function> = F extends (arg0: infer A) => any
  ? A
  : never;

type TUserReviewListArg = ArgumentTypes<typeof api.userReview.list>;

const useStyles = makeStyles((theme) => ({
  loader: {
    fontSize: 46,
    color: theme.palette.primary.light,
  },
}));

export const UserReviewsPage: React.FC = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [data, setData] = useState<IListResponse<IUserReview>>({
    list: [],
    meta: {},
    paginator: {
      countItems: 1,
    },
  });
  const [params, setParams] = useState<TUserReviewListArg>({
    limit: 20,
    page: 0,
    // offset: 1,
    filter: {
      sortBy: 'webinarDate',
      sortOrder: 'desc',
    }
  });

  useEffect(() => {
    async function setup() {
      setError('');
      setLoading(true);
      try {
        const result = await api.userReview.list(params);
        setData(result);
        setLoading(false);
      } catch (error: any) {
        setError(error);
      }
    }
    if (!loading) {
      setup();
    }
  }, [params]);

  const handleSortParams = useCallback(
    (param: TUserReviewListArg['filter']['sortBy']) => {
      params.filter.sortBy === param
        ? setParams((prevParams) => ({
            ...prevParams,
            filter: {
              ...prevParams.filter,
              sortOrder: prevParams.filter.sortOrder === 'asc' ? 'desc' : 'asc',
            }
          }))
        : setParams((prevParams) => ({
            ...prevParams,
            filter: {
              sortOrder: 'asc',
              sortBy: param,
            }
          }));
    },
    [params]
  );
  if (loading) {
    return (
      <Paper>
        <Box
          display="flex"
          height={270}
          alignItems="center"
          justifyContent="center"
        >
          <SWLoader className={classes.loader} />
        </Box>
      </Paper>
    );
  }
  return (
    <Paper>
      <Grid item container>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sortDirection="desc" width={120}>
                  <TableSortLabel
                    active={params.filter.sortBy === 'webinarDate'}
                    direction={params.filter.sortOrder === 'asc' ? 'asc' : 'desc'}
                    onClick={() => {
                      handleSortParams('webinarDate');
                    }}
                  >
                    Дата
                  </TableSortLabel>
                </TableCell>
                <TableCell width={'80%'}>Отзыв</TableCell>
                <TableCell>Клиент</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.list.map((item) => (
                <TableRow key={item.id}>
                  <TableCell style={{ verticalAlign: 'top' }}>
                    {!item.webinar.stats.startDate && !item.webinar.stats.endDate && item.webinar.startTime && format(parseJSON(item.webinar.startTime), 'dd-MM-yyyy hh:mm')}
                    {item.webinar.stats.startDate && format(parseJSON(item.webinar.stats.startDate), 'dd-MM-yyyy hh:mm')}
                    <br />
                    {item.webinar.stats.endDate && format(parseJSON(item.webinar.stats.endDate), 'dd-MM-yyyy hh:mm')}
                  </TableCell>
                  <TableCell>
                    <b>
                      Оценка: {item.score} | Зрителей: {item.webinar.stats.members} | Тариф:{' '}
                      {/* {item.atPlan} */}
                    </b>
                    <br />
                    <br />
                    <Typography
                      component="pre"
                      style={{ whiteSpace: 'pre-wrap' }}
                    >
                      {item.comment}
                    </Typography>
                    <br />
                    <br />
                    Вебинар:{' '}
                    <Link to={`/webinar/${item.webinar.id}`}>
                      {item.webinar.name}
                    </Link>
                  </TableCell>
                  <TableCell style={{ verticalAlign: 'top' }}>
                    {item.user.name} <br />
                    {item.user.email} <br />
                    {typeof item.user.phone !== "string" && [item.user.phone?.countryCode, item.user.phone?.number]}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter></TableFooter>
          </Table>
          <TablePagination
            rowsPerPageOptions={[20, 50, 100]}
            component="div"
            count={data.paginator.countItems}
            rowsPerPage={params.limit}
            labelRowsPerPage={'Показать на странице:'}
            page={params.page}
            onChangePage={(_e, page) =>
              setParams((prev) => ({
                ...prev,
                page: page,
              }))
            }
            onChangeRowsPerPage={(event) =>
              setParams((prev) => ({
                ...prev,
                limit: +event.target.value,
              }))
            }
          />
        </TableContainer>
      </Grid>
    </Paper>
  );
};

import * as React from "react";
import {differenceInDays, differenceInHours, differenceInMinutes, differenceInMonths} from "date-fns"

export default class DateDiff extends React.Component<{
  from: Date;
  to: Date;
}> {
  render() {
    let monthDiff = differenceInMonths(this.props.from, this.props.to),
      dayDiff = differenceInDays(this.props.from, this.props.to),
      hourDiff = differenceInHours(this.props.from, this.props.to),
      minuteDiff = differenceInMinutes(this.props.from, this.props.to);

    if (dayDiff > 29) {
      dayDiff = dayDiff % 30;
    }
    if (hourDiff > 23) {
      hourDiff = hourDiff % 24;
    }
    if (minuteDiff > 59) {
      minuteDiff = minuteDiff % 60;
    }
    if (
      monthDiff === 0 &&
      dayDiff === 0 &&
      hourDiff === 0 &&
      minuteDiff === 0
    ) {
      minuteDiff = 1;
    }

    return (
      <span>
        {monthDiff > 0 ? `${monthDiff} мес. ` : null}
        {dayDiff > 0 ? `${dayDiff} д. ` : null}
        {hourDiff > 0 ? `${hourDiff} ч. ` : null}
        {minuteDiff > 0 ? `${minuteDiff} м. ` : null}
      </span>
    );
  }
}

import {tl} from "../../utils";
import {FormSettingsRow, MUIBlock, MUIPreloader} from "../../MUI";
import React, { useEffect, useState } from "react";
import {Button, TextField, Typography} from "@material-ui/core";
import { api } from "../../api";
import { useDispatch } from "react-redux";
import { addNotification } from "../../store/notifications/actions";
import {Blacklist} from "../../api/chat";


export const ChatPage: React.FC = () => {
  const dispatch = useDispatch();
  const [blacklist, setBlacklist] = useState<Blacklist>({obscene:'', negative:''});
  const [isBlacklistLoading, setBlacklistLoading] = useState(false);

  useEffect(() => {
    async function getBlacklist() {
      setBlacklistLoading(true);
      try {
        const data = await api.chat.getBlacklist();
        setBlacklist(data);
        setBlacklistLoading(false)
      } catch (error) {
        setBlacklistLoading(false);
      }
    }
    getBlacklist();
  }, [])

  const handleSubmit = async () => {
    try {
      await api.chat.setBlacklist(blacklist)
      dispatch(addNotification('success',  'Настройки чата обновлены'));
    } catch (error: any) {
      dispatch(addNotification('error', error.response?.data?.message || 'Произошла ошибка'));
    }
  }

  if (isBlacklistLoading) {
    return <MUIPreloader/>
  }

  return (
      <MUIBlock style={{overflow: 'hidden'}} head={<Typography>Настройка чата</Typography>}>
          <FormSettingsRow
              smallLabel
              label={tl('Нецензурные слова:')}
          >
            <TextField
                variant="outlined"
                fullWidth
                multiline={true}
                value={blacklist.obscene}
                onChange={(e)=>{
                  setBlacklist({
                    ...blacklist,
                    obscene: e.target.value
                  })
                }}
                name="obscene"
            />
          </FormSettingsRow>
          <FormSettingsRow
              smallLabel
              label={tl('Нерекомедуемые слова:')}
          >
            <TextField
                variant="outlined"
                fullWidth
                multiline={true}
                value={blacklist.negative}
                onChange={(e)=>{
                  setBlacklist({
                    ...blacklist,
                    negative: e.target.value
                  })
                }}
                name="negative"
            />
          </FormSettingsRow>
          <Button variant="contained" color="primary"  onClick={handleSubmit}>Сохранить</Button>
      </MUIBlock>
  );
}
//@ts-nocheck
import * as React from "react";

export class Html extends React.Component<{
  children: any;
}> {
  node: any = null;

  componentDidMount() {
    const scripts: NodeListOf<HTMLScriptElement> = this.node.getElementsByTagName('script');
    for (let i: number = 0; i < scripts.length; i++) {
      const script = document.createElement('script');
      script.setAttribute('type', scripts.item(i).getAttribute('type'));
      script.setAttribute('src', scripts.item(i).getAttribute('src'));
      script.setAttribute('id', scripts.item(i).getAttribute('id'));

      scripts.item(i).parentNode.replaceChild(script, scripts.item(i));
    }

    const players: HTMLCollectionOf<Element> = this.node.getElementsByClassName('js-player');
    for (let i: number = 0; i < players.length; i++) {
      (window as any).AT.initPlayer(players.item(i));
    }
  }

  stripSlashes(content: string) {
    return content.replace(/\\(["'`\\])/g, '$1');
  }

  render() {
    return (
      <div
        dangerouslySetInnerHTML={{__html: this.stripSlashes(this.props.children)}}
        ref={(node) => this.node = node}
      />
    );
  }
}

export default Html

import { Rules } from "../services/AccessControl.service";
import {IChatConfig} from "./Chat";

export const enum EUSerStatus  {
  STATUS_ACTIVE  = 1,
  STATUS_BANNED  = 2,
  STATUS_BLOCKED = 3,
}
export interface IUser
{
  [index: string]: any,
  id?: string,
  authId?: string,
  signature?: string,
  token:string;
  email?: string,
  phone?: {
    country: string
    countryCode: number
    number: string
  } | string,
  balance: number,
  freePeriod: boolean,
  freePeriodDays?: number,
  name: string,
  lastName: string,
  timeZone:string,
  isModerator?:boolean,
  status: EUSerStatus,
  wsToken?:string,
  avatar?: string,
  params?: IUserParams,
  sid:string,
  refreshTokenEndpoint:string,
  permissions: Rules,
  chatConfig?: IChatConfig
  author?: {
    id?: string,
    name: string,
  }
}

export interface IUserParams {
  device: {
    type: string,
    os: string
  },
  url: IAdTags
}

export interface IAdTags {
  [key: string]: string | Array<string> | null
}

export enum EAuthStatus {
  UNKNOWN,
  UNATUHORIZED,
  AUTHORIZED
}

export enum EUserRole {
  MODERATOR = 'moderator',
  AUTHOR = 'author'
}
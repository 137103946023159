import { IWebinar } from "../../types";
import { ITask } from "../../types/Queue";
import {
    TWebinarActionType,
    WEBINAR_ADD_PRESENTATION,
    WEBINAR_CLEAR,
    WEBINAR_EDIT,
    WEBINAR_FETCH_ATTEMPT,
    WEBINAR_FETCH_PRESENTATIONS,
    WEBINAR_FETCH_FAILURE,
    WEBINAR_FETCH_SUCCESS,
    WEBINAR_PRESENTATION_UPLOAD_END,
    WEBINAR_PRESENTATION_UPLOAD_START,
    WEBINAR_REMOVE_PRESENTATION,
    WEBINAR_PRESENTATION_PROCESSING_START,
    WEBINAR_PRESENTATION_PROCESSING_END,
    WEBINAR_SET_PRESENTATIONS_LIST,
    CHAT_SET_MESSAGES
} from "./types";

export interface IWebinarInitialState {
    current?: IWebinar
    loading: boolean
    error?: string
    isEditing: boolean
    imports: ITask[]
}

const initialState: IWebinarInitialState = {
    loading: false,
    isEditing: false,
    imports: []
}

export default function webinarReducer(state = initialState, action: TWebinarActionType) {
    switch (action.type) {
        case WEBINAR_FETCH_ATTEMPT:
            return {
                ...state,
                current: undefined,
                loading: true,
                error: ''
            };
        case WEBINAR_FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                current: action.webinar,
                presentations: action.webinar.presentations
            };
        case WEBINAR_FETCH_FAILURE:
            return {
                ...state,
                current: undefined,
                loading: false,
                error: action.error
            };
        case WEBINAR_EDIT:
            return {
                ...state,
                isEditing: action.isEditing
            }
        case WEBINAR_FETCH_PRESENTATIONS: {
            if (!state.current) return state;
            return {
                ...state,
                current: {
                    ...state.current,
                    presentations: action.presentations
                }
            }
        }
        case WEBINAR_ADD_PRESENTATION: {
            if (!state.current) return state;
            const presentations = state.current.presentations
                ? state.current.presentations.slice()
                : [];
            presentations.push(action.presentation)
            return {
                ...state,
                current: {
                    ...state.current,
                    presentations: presentations
                }
            }
        }
        case WEBINAR_REMOVE_PRESENTATION: {
            if (!state.current) return state;
            const prevList = state.current.presentations.slice();
            return {
                ...state,
                current: {
                    ...state.current,
                    presentations: prevList.filter(
                        (presentation) => presentation.id !== action.presentation.id)
                }
            }
        }
        case WEBINAR_PRESENTATION_UPLOAD_START: {
            if (!state.current) return state;

            const presentations = state.current.presentations
                ? state.current.presentations.slice()
                : [];
            presentations.push({
                ...action.presentation,
                loading: true,
                isReady: false,
            })
            return {
                ...state,
                current: {
                    ...state.current,
                    presentations: presentations
                }
            }
        }
        case WEBINAR_PRESENTATION_UPLOAD_END: {
            if (!state.current) return state;
            const presentations = state.current.presentations.map(item => {
                if (item.id === action.id) {
                    return {
                        ...action.presentation,
                        loading: false,
                        isReady: false,
                    };
                }
                return item;
            });
            return {
                ...state,
                current: {
                    ...state.current,
                    presentations: presentations
                }
            }
        }
        case WEBINAR_PRESENTATION_PROCESSING_END: {
            if (!state.current) return state;

            const presentations = state.current.presentations
                ? state.current.presentations.slice()
                : [];
            const idx = presentations.findIndex((pres) => pres.id === action.id);
            if (idx === -1) return state;
            else {
                presentations[idx].isReady = true;
                presentations[idx].loading = false;
                presentations[idx].pages = action.pages;

            }
            return {
                ...state,
                current: {
                    ...state.current,
                    presentations: presentations
                }
            }
        }

        case WEBINAR_SET_PRESENTATIONS_LIST: {
            if (!state.current) return state;
            return {
                ...state,
                current: {
                    ...state.current,
                    presentations: action.presentations
                }
            }
        }
        case WEBINAR_CLEAR:
            return {
                ...state,
                current: undefined
            }
        case CHAT_SET_MESSAGES:
            return {
                ...state,
                current: {
                    ...state.current as IWebinar,
                    chatSettings: {
                        ...state.current?.chatSettings,
                        ...action.payload
                    }
                }
            }
        default:
            return state;
    }
}
import * as React from "react";
import {useState} from "react";
import {Box, Button, MenuItem, Select, Typography} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {tl} from "../../utils";
import { ExpandMore } from "@material-ui/icons";

import styles  from "./styles.module.scss";
import { layoutHintDict, TStreamLayout } from "../../types";
import { makeLineClamp } from "../../MUI";


interface WebinarEventLayoutProps {
  layout: TStreamLayout;
  editable: boolean;
  onChange?: (layout: string) => void;
}


const useStyles = makeStyles({
  title: {
    flexFlow: 'row nowrap',
    ...makeLineClamp(1,2.2)
  },
  selected: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  select: {
    maxWidth: '60%',
  }
});

export const WebinarEventLayout = (
  props: WebinarEventLayoutProps,
) => {
  const classes = useStyles();

  const [layout, setLayout] = useState(props.layout);
  const [savedLayout, setSavedLayout] = useState(props.layout);
  const [isChanging, setIsChanging] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const onKeydown = (event: any) => {
    event.key === "Escape" && handleCancel();
    event.key === "Enter" && handleSubmit();
  };

  const openSettings = () => {
    if (props.editable && !isChanging) {
      setIsChanging(true);
      document.addEventListener("keydown", onKeydown);
    }
  };

  const closeSettings = () => {
    setIsChanging(false);
    document.removeEventListener("keydown", onKeydown);
  };

  const handleCancel = () => {
    closeSettings();
    setLayout(savedLayout);
    setIsChanged(false);
  };

  const handleSubmit = (event?: any) => {
    event && event.preventDefault();
    closeSettings();
    props.onChange && props.onChange(layout);
    setSavedLayout(layout);
  };

  return (
    <Box
      px="10px"
      className={isChanging ? "" : styles.changeBox}
      onClick={openSettings}
    >
      <Typography className={classes.title}>
        <b>{tl("Вид окна трансляции. ")}</b>
        <span>{layout ? layoutHintDict[layout] : tl('Выбрать')}</span>
      </Typography>
      {props.editable &&
        <Box display={isChanging ? "none" : ""} className={styles.changeBoxIcon}>
            <ExpandMore/>
        </Box>
      }
      {isChanging && (
        <form onSubmit={handleSubmit}>
          <Box className={styles.changeBoxRow}>
            <Select
              variant="outlined"
              fullWidth
              className={classes.select}
              value={layout}
              onChange={(event: any) => {
                setLayout(event.target.value);
                setIsChanged(true);
              }}
            >
              {Object.entries(layoutHintDict).map((layout) => (
                <MenuItem value={layout[0]} key={`layout-${layout[0]}`}>
                  <Typography className={classes.selected}>{layout[1]}</Typography>
                </MenuItem>
              ))}
            </Select>
            <Box className={styles.changeBoxButtons} pl="10px">
              <Box pr="8px">
                <Button
                  variant="contained"
                  onClick={handleCancel}
                >
                  Отмена
                </Button>
              </Box>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={!isChanged}
              >
                Сохранить
              </Button>
            </Box>
          </Box>
        </form>
      )}
    </Box>
  );
}
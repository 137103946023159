import {IChatSettings, IPlayer, IUser, TSplashScreen} from '../../types';

export const PLAYER_SET_MEMBER = 'PLAYER_SET_MEMBER';
export const PLAYER_FETCH_ATTEMPT = 'PLAYER_FETCH_ATTEMPT';
export const PLAYER_FETCH_SUCCESS = 'PLAYER_FETCH_SUCCESS';
export const PLAYER_FETCH_ERROR = 'PLAYER_FETCH_ERROR';

export const PLAYER_REDIRECT_LINK_CHANGE = 'PLAYER_REDIRECT_LINK_CHANGE';
export const PLAYER_CHAT_SETTINGS_CHANGE = 'PLAYER_CHAT_SETTINGS_CHANGE';
export const PLAYER_CLOSE = 'PLAYER_CLOSE';
export const SET_USER_DATA = 'SET_USER_DATA';
export const UPDATE_SPLASH_SCREEN = 'UPDATE_SPLASH_SCREEN';


// export const PLAYER_AUTLOGIN_ATTEMPT = 'PLAYER_AUTLOGIN_ATTEMPT';
// export const PLAYER_AUTLOGIN_ATTEMPT = 'PLAYER_AUTLOGIN_ATTEMPT';



export interface ISetUserData {
  type: typeof SET_USER_DATA;
  userData: Partial<IUser>;
}
export interface ISetMemberAction {
  type: typeof PLAYER_SET_MEMBER;
  member: IUser;
}

export interface IPlayerFetchAttemptAction {
  type: typeof PLAYER_FETCH_ATTEMPT;
}

export interface IPlayerFetchSuccessAction {
  type: typeof PLAYER_FETCH_SUCCESS;
  player: IPlayer;
}

export interface IPlayerFetchErrorAction {
  type: typeof PLAYER_FETCH_ERROR;
  error?: 'not-found' | boolean;
}

export interface IPlayerRedirectLinkChangeAction {
  type: typeof PLAYER_REDIRECT_LINK_CHANGE;
  redirectLink: string;
}

export interface IPlayerChatSettingsChangeAction {
  type: typeof PLAYER_CHAT_SETTINGS_CHANGE
  settings: IChatSettings
}

export interface IPlayerCloseAction {
  type: typeof PLAYER_CLOSE;
}

export interface IUpdateSplashScreenAction {
  type: typeof UPDATE_SPLASH_SCREEN
  params: TSplashScreen
}

export type TPlayerActionType =
  | ISetMemberAction
  | ISetUserData
  | IPlayerFetchAttemptAction
  | IPlayerFetchSuccessAction
  | IPlayerFetchErrorAction
  | IPlayerRedirectLinkChangeAction
  | IPlayerChatSettingsChangeAction
  | IPlayerCloseAction
  | IUpdateSplashScreenAction;

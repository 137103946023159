import { Box, Collapse, Fade, IconButton, makeStyles } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { SvgIcon } from '../../';
import { IAdsTimer } from '../../../types';
import { rgba } from '../../../utils';
import TickingClockIcon from '../../Icon/tickingClock';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    lineHeight: 1,
    width: '100%',
    cursor: 'pointer',
  },
  wrap: {
    borderColor: (props: IAdsTimer) => props.color,
    borderWidth: 4,
    borderStyle: 'solid',
    borderRadius: 20,
    boxShadow: (props: IAdsTimer) => `0px 0px 6px ${rgba(props.color, 50)}`,
    height: 62,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    [theme.breakpoints.between(360, 640)]: {
      height: 44,
      borderRadius: 14,
      borderWidth: 3,
    },
    [theme.breakpoints.between(320, 360)]: {
      height: 27,
      borderRadius: 10,
      borderWidth: 2,
    }
  },
  clock: {
    fontSize: 26,
    width: 28,
    color: (props: IAdsTimer) => props.color,
    [theme.breakpoints.between(360, 640)]: {
      fontSize: 26,
      width: 28,
    },
    [theme.breakpoints.between(320, 360)]: {
      fontSize: 16,
      width: 17,
    },
  },
  clockWrap: {
    marginLeft: theme.spacing(2.5),
    marginRight: theme.spacing(1.5),
    [theme.breakpoints.between(360, 640)]: {
      marginLeft: theme.spacing(1.75),
      marginRight: theme.spacing(1.5),
    },
    [theme.breakpoints.between(320, 360)]: {
      marginLeft: theme.spacing(1.25),
      marginRight: theme.spacing(1),
    }
  },
  endText: {
    color: (props: IAdsTimer) => props.color,
    fontSize: 21,
    lineHeight: '25px',
    fontWeight: 600,
    fontFamily: "SF Pro Display",
    marginTop: theme.spacing(1),
    [theme.breakpoints.down(640)]: {
      marginTop: theme.spacing(.5),
      fontSize: 13,
      lineHeight: '15px',
    }
  },
  text: {
    color: (props: IAdsTimer) => props.color,
    fontSize: 38,
    fontWeight: 600,
    fontFamily: "SF Pro Display",
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginRight: theme.spacing(3.5),
    '& span.hours': {
      textAlign: 'center',
      marginRight: 2,
    },
    '& span.minutes': {
      minWidth: 48,
      maxWidth: 48,
      textAlign: 'center',
    },
    '& span.seconds': {
      display: 'flex',
      alignItems: 'center',
      minWidth: 47,
      maxWidth: 47,
    },
    '& span.separator': {
      marginBottom: 8,
      marginRight: 1,
    },
    [theme.breakpoints.between(320, 360)]: {
      fontSize: 18,
      lineHeight: '17px',
      marginRight: theme.spacing(2),
      '& span.minutes': {
        minWidth: 23,
        maxWidth: 23,
      },
      '& span.seconds': {
        minWidth: 22,
        maxWidth: 22,
      },
      '& span.separator': {
        marginBottom: 4,
      }
    },
    [theme.breakpoints.between(360, 640)]: {
      fontSize: 26,
      marginRight: theme.spacing(2.5),
      '& span.minutes': {
        minWidth: 35,
        width: 35,
        maxWidth: 35,
      },
      '& span.seconds': {
        minWidth: 35,
        width: 35,
        maxWidth: 35,
      },
      '& span.separator': {
        marginBottom: 6,
      }
    },
  },
  settings: {
    display: "flex",
    position: "absolute",
    // maxWidth: "100px",
    width: "100%",
    height: '100%',
    background: 'rgba(0,0,0,.25)',
    justifyContent: "space-around",
    alignItems: 'center',
    borderRadius: 16,
  },
  settingsButton: {
    width: "40px",
    height: "40px",
    backgroundColor: "#fff",

    "&:hover": {
      backgroundColor: "rgba(255,255,255, 0.85)",
    },
  },

  settingsIcon: {
    transform: "scale(1.2)",
  },
}))


interface TimerAdLayoutProps {
  timer: IAdsTimer;
  secondsLeft: number;
  isEditing?: boolean;
  small?: boolean;
  onRemove?: () => void;
  onEdit?: () => void;
}


export const TimerAdLayout: React.FC<TimerAdLayoutProps> = ({
  timer, 
  secondsLeft,
  isEditing, 
  small,
  onEdit,
  onRemove,
}) => {
  const classes = useStyles(timer);
  const [isHovered, setIsHovered] = useState(false);

  const h = useMemo(() => Math.floor(secondsLeft / 3600), [secondsLeft]);
  const m = useMemo(() => Math.floor(secondsLeft / 60 - (h * 60)), [h, secondsLeft]);
  const s = useMemo(() =>Math.floor(secondsLeft - (h * 3600) - (m * 60)), [h, m, secondsLeft]);

  return (
    <Box 
      className={classes.container} 
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
      alignItems={small ? 'flex-start': 'center'}
      data-testid="timerAds"
    >
      <Box className={classes.wrap} >
        <Box className={classes.clockWrap}>
          <TickingClockIcon iconColor={timer.color} className={classes.clock} seconds={secondsLeft < 0 ? 0 : secondsLeft % 60} />
        </Box>
        <div className={classes.text}>
          {Math.floor(secondsLeft / 3600) >= 1 && <span className="hours">{h}</span>}
          {Math.floor(secondsLeft / 3600) >= 1 && <span className="separator">:</span>}
          <span className="minutes">{m.toString().padStart(2,'0')}</span>
          <span className="separator">:</span>
          <span className="seconds">{s.toString().padStart(2,'0')}</span>
        </div>
        {isEditing && !small &&
          <Fade in={isHovered} mountOnEnter unmountOnExit>
            <Box className={classes.settings}>
              <IconButton onClick={onEdit && onEdit} className={classes.settingsButton}>
                <Box className={classes.settingsIcon}>
                  <SvgIcon type="fs-list-settings" />
                </Box>
              </IconButton>
              <IconButton onClick={onRemove && onRemove} className={classes.settingsButton}>
                <SvgIcon type="sw-solid-bin" />
              </IconButton>
            </Box>
          </Fade>
      }
      </Box>

      <Collapse in={Boolean(!isEditing && secondsLeft <= 0)}>
        <div className={classes.endText}>
          <span>Время закончилось</span>
        </div>
      </Collapse>
    </Box>
  )
}
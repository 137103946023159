import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './App.css';
import { history } from './store';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { NotificationCenter } from './components/NotificationCenter';
import { ThemeProvider } from '@material-ui/core/styles';
import { Theme as theme } from './MUI';
import store from './store';
import sentry from './services/ErrorLog.service';

sentry();

ReactDOM.render(
  <Provider store={store}>
      <ConnectedRouter history={history}>
        <ThemeProvider theme={theme}>
          <App history={history} />
          <NotificationCenter />
        </ThemeProvider>
      </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Breadcrumbs, Link as MUILink, TextField, Typography, Grid, makeStyles } from '@material-ui/core';
import { FormSettingsRow, FormSettingsSection, MUIBlock, GridContainer, themeVariables } from '../../../MUI';
import BreadcrumbsSeparatorIcon from '../../../components/Icon/breadcrumbsSeparator';
import Page from '../../../hoc/page';
import { tl } from '../../../utils';
import { SettingsNavigationMenu } from '../menu';
import { PhotoCropper } from './photoCropper';
import { SettingsPhoneInput } from './phoneInput';
import { RootState } from '../../../store';
import { updateProfile } from '../../../store/user/actions';
import { IUser } from '../../../types';
import { api } from '../../../api';

const useStyles = makeStyles({
  wrap: {
    justifyContent: "flex-end",
  
    [themeVariables.breakpoints.down("md")]: {
      justifyContent: "flex-start",
    },
  },
  
  content: {
    minWidth: "300px",
    maxWidth: "687px",
    boxSizing: "content-box",
    // Заглушка вместо левого меню, которое теоретически
    // должно быть добавлено в МР с CloudPayments
    marginLeft: "216px",

    [themeVariables.breakpoints.down("md")]: {
      marginLeft: 0,
    },
  },
});

export const ProfilePage: React.FC = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);
  
  const [localProfile, setLocalProfile] = useState<IUser>(user.profile);
  const [localProfileUpdated, setLocalProfileUpdated] = useState<boolean>(false);

  const handleSubmit = (profile: IUser) => {
    dispatch(updateProfile(profile));
    setLocalProfileUpdated(false);
  };

  return (
    <Page title={tl("Личные данные")}>
      <GridContainer direction="row-reverse" spacing={2} className={classes.wrap}>
        <SettingsNavigationMenu />
        <Grid item xs zeroMinWidth className={classes.content}>
          <MUIBlock
            head={
              <Breadcrumbs
                separator={<BreadcrumbsSeparatorIcon style={{ height: "11px", width: "11px" }}/>}
              >
                <MUILink component={Link} to={`/`} color="inherit">
                  <Typography variant="subtitle1">
                    {tl("Мой аккаунт")}
                  </Typography>
                </MUILink>
                <MUILink component={Link} to={`/settings/profile`} style={{ color: "black" }}>
                  <Typography variant="subtitle1">
                    {tl("Личные данные")}
                  </Typography>
                </MUILink>
              </Breadcrumbs>
            }
            controlsDivider
            controls={
              [
                [],
                [
                  {
                    label: tl('Сохранить'),
                    variant: 'contained',
                    color: 'primary',
                    type: 'button',
                    disabled: !localProfile.name ||
                              !localProfile.lastName ||
                              !localProfile.phone ||
                              (localProfile.phone && typeof localProfile.phone === 'string' && localProfile.phone.length < 5) ||
                              !localProfileUpdated ||
                              user.updatingProfile,
                    onClick: () => handleSubmit(localProfile),
                  }
                ]
              ]
            }
    >     
            <FormSettingsSection noBorderTop>
              <FormSettingsRow smallLabel>
                <PhotoCropper 
                  onCropSave={async (file) => {
                    const uploadLink = await api.user.getPhotoUploadLink(file.size, file.type);
                    if (uploadLink) {
                      try {
                        await api.user.uploadPhoto(uploadLink, file);
                        setLocalProfile({...localProfile, avatar: uploadLink});
                        setLocalProfileUpdated(true);
                      } catch (error) {
                        console.log('Ошибка загрузки фотографии');
                      }
                    }
                  }}
                  photo={localProfile.avatar}
                />
              </FormSettingsRow>
              <FormSettingsRow smallLabel label={tl('Имя:')}>
                <TextField
                  variant="outlined"
                  fullWidth
                  type="text"
                  value={localProfile.name}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => { 
                    setLocalProfile({...localProfile, name: event.target.value});
                    setLocalProfileUpdated(true);
                  }}
                />
              </FormSettingsRow>
              <FormSettingsRow smallLabel label={tl('Фамилия:')}>
                <TextField
                  variant="outlined"
                  fullWidth
                  type="text"
                  value={localProfile.lastName}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => { 
                    setLocalProfile({...localProfile, lastName: event.target.value});
                    setLocalProfileUpdated(true);
                  }}
                />
              </FormSettingsRow>
              <FormSettingsRow smallLabel label={tl('E-mail:')}>
                <TextField
                  variant="outlined"
                  fullWidth
                  type="email"
                  value={localProfile.email}
                  disabled
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => { 
                    setLocalProfile({...localProfile, email: event.target.value});
                    setLocalProfileUpdated(true);
                  }}
                />
              </FormSettingsRow>
              <FormSettingsRow smallLabel label={tl('Телефон для оповещений:')}>
                <SettingsPhoneInput
                  value={localProfile.phone as string}
                  onChange={(value) => { 
                    setLocalProfile({...localProfile, phone: value });
                    setLocalProfileUpdated(true);
                  }}
                />
              </FormSettingsRow>
            </FormSettingsSection>
            {/* Переводов нет, выбор языка бесполезен */}
            {/* <FormSettingsSection>
              <FormSettingsRow smallLabel label={tl('Язык:')}>
                <TextField
                  variant="outlined"
                  fullWidth
                  type="text"
                  value={"Язык"}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => { console.log("Меняем язык") }}
                />
              </FormSettingsRow>
            </FormSettingsSection> */}
          </MUIBlock>
        </Grid>
      </GridContainer>
    </Page>
  )
}
import React, { useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
  Box,
  Breadcrumbs,
  Collapse,
  FormControlLabel,
  Link as MUILink,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import {
  FormSettingsRow,
  FormSettingsSection,
  MUIBlock,
  themeVariables,
} from '../../MUI';
import { Form } from '../../components';
import { TrashBin } from '../../components/Icon/trashBin';
import { tl } from "../../utils";
import { useDispatch } from "react-redux";
import useAppSelector from '../../hooks/useAppSelector';
import { TableInfo } from '../../MUI/Table/TableInfo';
import {
  fetchIntegration,
  fetchAddIntegration,
  fetchRemoveIntegration,
  fetchUpdateIntegration,
} from '../../store/integrations/actions';
import { IIntegrationAuthor } from '../../types';

const useStyles = makeStyles(theme => ({
  annotation: {
    color: theme.palette.grey[600],
  },
}));

const newIntegration: IIntegrationAuthor = {
  id: 'new',
  name:'cloudPayments',
  src: '',
  sellCount: 0,
  type: 'cloudPayments',
  settings: {
    publicId: "",
    apiPassword: "",
    digitalCheck: false,
    resendData: false,
    payNotificationsAddress: "",
  },
};

export const CloudPaymentsIntegration: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { list } = useAppSelector((state) => state.integrations) as { list: IIntegrationAuthor[] };

  const { integrationId } = useParams<{integrationId: string}>();

  const [infoOpened, setInfoOpened] = React.useState<boolean>(true);
  const [currentIntegration, setCurrentIntegration] = React.useState<IIntegrationAuthor>(newIntegration);

  useEffect(() => {
    if (integrationId) {
      const found = list.find(integration => integration.id === integrationId);
      if (found) {
        setCurrentIntegration(found);
      } else {
        dispatch(fetchIntegration(integrationId));
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (integrationId) {
      dispatch(fetchUpdateIntegration(currentIntegration));
    } else {
      dispatch(fetchAddIntegration(currentIntegration));
    }
  };

  const controls = [
    [
      {
        variant: "contained",
        startIcon: <TrashBin />,
        label: tl("Удалить"),
        onClick: () => {
          dispatch(fetchRemoveIntegration(currentIntegration));
        }
      }
    ],
    [
      {
        variant: "contained",
        label: tl("Назад"),
        onClick: () => {
          history.push("/settings/integrations")
        },
      },
      {
        variant: "contained",
        color: "primary",
        label: tl("Сохранить"),
        onClick: handleSubmit,
      },
    ],
  ];

  return (
    <MUIBlock
      head={
        <Breadcrumbs separator=">">
          <MUILink
            component={Link}
            to="/settings/integrations"
            color="inherit"
          >
            <Typography variant="subtitle1">
              {tl("Список интеграций")}
            </Typography>
          </MUILink>
          <Box clone color={themeVariables.palette.grey[50]}>
            <Typography variant="subtitle1">
              {tl("Интеграция с CloudPayments")}
            </Typography>
          </Box>
        </Breadcrumbs>
      }
      controls={controls}
      controlsDivider
      pInner={0}
    >
      <Collapse in={infoOpened}>
        <TableInfo
          info={tl("Прежде чем приступить к настройке интеграции, рекомендуем прочитать инструкцию или воспользоваться информационными сносками ниже.")}
          noBorder
          noBorderTop
          onClose={() => setInfoOpened(false)}
        />
      </Collapse>
      <Form onSubmit={handleSubmit}>
        <FormSettingsSection>
          <FormSettingsRow
            smallLabel
            label={tl('PublicID')}
          >
            <TextField
              variant="outlined"
              fullWidth
              value={currentIntegration.settings.publicId}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setCurrentIntegration({
                  ...currentIntegration,
                  settings: {
                    ...currentIntegration.settings,
                    publicId: event.target.value,
                  }
                })
              }}
            />
          </FormSettingsRow>
          <FormSettingsRow
            smallLabel
            label={tl('Пароль для API')}
          >
            <TextField
              variant="outlined"
              fullWidth
              value={currentIntegration.settings.apiPassword}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setCurrentIntegration({
                  ...currentIntegration,
                  settings: {
                    ...currentIntegration.settings,
                    apiPassword: event.target.value,
                  }
                })
              }}
            />
          </FormSettingsRow>
          <FormSettingsRow
            smallLabel
            label={tl('Выписывать электронный чек')}
          >
            <Box display="flex">
              <FormControlLabel
                style={{pointerEvents: 'auto', marginLeft: 0}}
                label=""
                control={<Switch color="primary" />}
                labelPlacement="end"
                checked={currentIntegration.settings.digitalCheck}
                onChange={(event: React.ChangeEvent<{}>, checked) => {
                  setCurrentIntegration({
                    ...currentIntegration,
                    settings: {
                      ...currentIntegration.settings,
                      digitalCheck: checked,
                    }
                  })
                }}
              />
              {currentIntegration.settings.digitalCheck &&
                <Typography className={classes.annotation}>
                  - Не забудьте настроить чек в CloudPayments
                </Typography>
              }
            </Box>
          </FormSettingsRow>
          <FormSettingsRow
            smallLabel
            label={tl('Отправлять данные о платежах в другую систему')}
          >
            <Box display="flex">
              <FormControlLabel
                style={{pointerEvents: 'auto', marginLeft: 0}}
                label=""
                control={<Switch color="primary" />}
                labelPlacement="end"
                checked={!!currentIntegration.settings.resendData}
                onChange={(event: React.ChangeEvent<{}>, checked) => {
                  setCurrentIntegration({
                    ...currentIntegration,
                    settings: {
                      ...currentIntegration.settings,
                      resendData: checked,
                    }
                  })
                }}
              />
              {currentIntegration.settings.resendData &&
                <TextField
                  variant="outlined"
                  fullWidth
                  placeholder="Адрес callback-а перенаправления"
                  value={currentIntegration.settings.resendData === true ? "" : currentIntegration.settings.resendData}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setCurrentIntegration({
                      ...currentIntegration,
                      settings: {
                        ...currentIntegration.settings,
                        resendData: event.target.value,
                      }
                    })
                  }}
                />
              }
            </Box>
          </FormSettingsRow>
          {currentIntegration.webhook &&
            <FormSettingsRow
              smallLabel
              label={tl('Адрес для Pay-уведомлений')}
            >
              <Typography>
                {currentIntegration.webhook}
              </Typography>
            </FormSettingsRow>
        }
        </FormSettingsSection>
      </Form>
    </MUIBlock>
  );
};

import * as React from "react";
import styles  from "./styles.module.scss";

class Item extends React.Component<{ onChange?: any; id: any }, {}> {
  onChange = () => {
    this.props.onChange(this.props.id);
  };

  render() {
    const {children} = this.props;

    return (
      <div className={styles.DropdownItem} onClick={this.onChange}>
        {children}
      </div>
    );
  }
}

interface IDropdownProps {
  title?: any;
  onChange?: any;
}

export default class Dropdown extends React.Component<IDropdownProps,
  { isOpen: boolean }> {
  public state = {
    isOpen: false
  };

  public static Item = Item;

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  render() {
    const {children, title} = this.props;
    const {isOpen} = this.state;

    return (
      <div className={styles.Dropdown} onClick={this.toggle}>
        <div className={styles.DropdownCurrent}>{title}</div>

        {!isOpen || (
          <>
            <div className={styles.DropdownClose} onClick={this.toggle}/>
            <div className={styles.DropdownItemList}>
              <div>
                {React.Children.map(children, (el: any) =>
                  React.cloneElement(el, {onChange: this.props.onChange})
                )}
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

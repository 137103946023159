import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { Box, styled } from '@material-ui/core';
import { SideMenuItem, SideMenuItemProps } from './item';
import { RedirectModal } from '../Modals/redirectModal';

const SideMenuContainer = styled(Box)(({ theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  width: '200px',
  maxWidth: '687px',
  padding: '10px 0 0 2px',
}));

interface SideMenuProps {
  redirectModal?: boolean;
  children: React.ReactElement<SideMenuItemProps> | Array<React.ReactElement<SideMenuItemProps> | undefined | null> | null;
  onRedirectDismiss?: () => void;
}

export const SideMenu: React.FC<SideMenuProps> = ({redirectModal, onRedirectDismiss, children}) => {
  const [storedOnClick, setStoredOnClick] = useState<(() => void) | undefined>(undefined);
  const [showModal, setShowModal] = useState(false);

  const history = useHistory();

  return (
    <>
      <SideMenuContainer>
        {
          React.Children.map(children, (child) => {
            if (redirectModal && (React.isValidElement<SideMenuItemProps>(child) && child.type === SideMenuItem)) {
              return React.cloneElement(child, {
                onClick: (event: React.MouseEvent<HTMLElement>) => {
                  event.preventDefault();
                  setStoredOnClick(() => () => history.push(child.props.to.toString()));
                  setShowModal(true);
                },
              });
            }
            return child;
          })
        }
      </SideMenuContainer>
      <RedirectModal
        open={showModal}
        action={() => {
          onRedirectDismiss && onRedirectDismiss();
          storedOnClick && storedOnClick();
        }}
        close={() => {
          setShowModal(false);
        }}
      />
    </>
  );
}
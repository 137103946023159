import * as React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Hidden,
  styled,
} from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import { themeVariables } from '../_Theme';
import { SvgIcon } from '../../components/SvgIcon';
import { tl } from '../../utils';
import styles from './styles.module.scss';
import { ModalCloseIcon } from '../../components/Icon/close';

interface IProps extends DialogProps {
  title?: any;
  notitle?: boolean;
  actions?: any[];
  square?: boolean;
  centered?: boolean;
  actionsCentered?: boolean;
  notFullWidth?: boolean;
  mobileFullScreen?: boolean;
  withControls?: boolean;
  headControls?: any;
  dialogContentClassName?: string;
}

export type MUIDialogProps = IProps;

const useStyles = makeStyles({
  Dialog: {
    height: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    boxShadow: 'none',
    borderColor: themeVariables.palette.borderOuter,
  },
  noWrap: {
    [themeVariables.breakpoints.up('sm')]: {
      flexWrap: 'nowrap',
    },
  },
  paper: {
    // borderRadius: 0,
  },
});

const WhiteDialogTitle = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: '#fff',
  color: '#333333',
  '& h2': {
    fontSize: 15,
    lineHeight: '22px',
  },
}));

export const MUIDialog = ({
  title,
  notitle,
  actions,
  square = false,
  centered,
  actionsCentered,
  notFullWidth,
  mobileFullScreen,
  withControls,
  headControls,
  dialogContentClassName,
  ...dialogProps
}: IProps) => {
  const classes = useStyles();

  return (
    <Dialog
      fullWidth={!notFullWidth}
      maxWidth={notFullWidth ? undefined : 'xs'}
      {...dialogProps}
      PaperProps={{
        square: square,
        className: classes.paper,
      }}
      className={` ${mobileFullScreen ? 'mobileFullScreen' : ''} ${
        withControls ? 'withControls' : ''
      } ${dialogProps.className}`}
    >
      {notitle ? null : (
        <WhiteDialogTitle
          className={`${dialogProps.onClose ? styles.closable : ''}`}
        >
          {title ? title : tl('Внимание!')}
          {headControls && (
            <Box
              position="absolute"
              top="0"
              height="54px"
              right={dialogProps.onClose ? '52px' : '10px'}
              display="flex"
              alignItems="center"
            >
              {headControls}
            </Box>
          )}
          {dialogProps.onClose ? (
            <Box
              className={styles.closableTrigger}
              onClick={(evt: any) => {
                dialogProps.onClose && dialogProps.onClose({}, 'backdropClick');
              }}
            >
              {/* <SvgIcon type="dialog-close grey"/> */}
              <ModalCloseIcon style={{ color: '#b6b6b6', fontSize: 20 }} />
            </Box>
          ) : null}
        </WhiteDialogTitle>
      )}
      <DialogContent
        className={`${
          centered ? styles.centered : ''
        } ${dialogContentClassName}`}
      >
        {dialogProps.children}
      </DialogContent>
      {actions ? (
        <DialogActions>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            spacing={withControls ? 2 : undefined}
          >
            {actions.map((actionsGroup: any, i: number) => (
              <Grid
                item
                key={`dialog-actionsGroups-${i}`}
                xs={withControls ? 6 : 12}
                sm={actionsCentered ? true : 'auto'}
              >
                <Grid
                  container
                  spacing={1}
                  alignItems="center"
                  className={classes.noWrap}
                >
                  {actionsGroup.map((action: any, idx: number) =>
                    withControls && action['$$typeof'] ? (
                      <Hidden
                        only="xs"
                        key={`dialog-actions-${action.label}-${idx}`}
                      >
                        <Grid item xs={12} sm={actionsCentered ? true : 'auto'}>
                          <Box clone>{action}</Box>
                        </Grid>
                      </Hidden>
                    ) : (
                      <Grid
                        item
                        key={`dialog-actions-${action.label}-${idx}`}
                        xs={12}
                        sm={actionsCentered ? true : 'auto'}
                      >
                        {action['$$typeof'] ? (
                          <Box clone>{action}</Box>
                        ) : (
                          <Button variant="contained" {...action.btnProps}>
                            {action.label ? action.label : tl('Ок')}
                          </Button>
                        )}
                      </Grid>
                    )
                  )}
                </Grid>
              </Grid>
            ))}
          </Grid>
        </DialogActions>
      ) : null}
      {notitle && dialogProps.onClose ? (
        <Box
          className={`${styles.closableTrigger} ${styles.mobileonly}`}
          onClick={(evt: any) => {
            dialogProps.onClose && dialogProps.onClose({}, 'backdropClick');
          }}
        >
          <SvgIcon type="sw-close-modal" />
        </Box>
      ) : null}
    </Dialog>
  );
};

import { useEffect } from 'react';
import React from 'react';
import { Footer, Header } from '../components';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ServiceToggle } from '../components/ServiceToggle';
import { EAuthStatus } from '../types';
import useAppSelector from '../hooks/useAppSelector';

interface PageProps {
  title: string;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  forStudent?: boolean;
  centered?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    page: {
      backgroundColor: (props: PageProps) =>
        props.forStudent ? theme.palette.grey.A400 : '#EFF0F2',
    },

    wrap: {
      flex: '1 1 auto',
      display: 'flex',
      width: '100%',
      margin: 'auto',
      paddingTop: (props: PageProps) => (props.forStudent ? 0 : 16),
    },

    sm: {
      maxWidth: '940px',
    },

    md: {
      maxWidth: (props: PageProps) => (props.forStudent ? 1118 : 1135),
      paddingLeft: 0,

      [theme.breakpoints.up('md')]: {
        paddingLeft: (props: PageProps) => (props.forStudent ? 0 : 20),
      },

      '@media (min-width: 1300px)': {
        paddingLeft: (props: PageProps) => (props.forStudent ? 0 : 70),
      },
    },

    lg: {
      maxWidth: '1280px',
    },
    xl: {
      maxWidth: 'calc(100vw - 150px)',
    }
  })
);

const Page: React.FC<PageProps> = (props) => {
  const classes = useStyles(props);
  const user = useAppSelector((state) => state.user);
  useEffect(() => {
    document.title = props.title || '';
  }, [props.title]);

  return (
    <>
      <div className={`page ${classes.page}`}>
        <Header
          size={props.size}
          forStudent={props.forStudent}
          centered={props.centered}
        />
        {!props.forStudent && user.status === EAuthStatus.AUTHORIZED && (
          <ServiceToggle user={user.profile} />
        )}
        <div className={`${classes[props.size || 'md']} ${classes.wrap}`}>
          {props.children}
        </div>
      </div>
      {props.forStudent && <Footer />}
    </>
  );
};

export default Page;

/*
 * Сильно нагружает CPU
 */

import * as React from "react";
import {Box, BoxProps} from "@material-ui/core";
import {themeVariables} from "../index";
import styles  from "./styles.module.scss";

interface IProps extends BoxProps {
  cssModifier?: string;
  modal?: boolean;
}

export const MUIPreloader = ({cssModifier, modal, ...boxProps}: IProps) => {
  boxProps.p = boxProps.p || 2;

  return modal ? (
    <Box
      className={styles.fader}
      pt={{xs: 0, lg: 7}}
      pb={{xs: 0, lg: 3.5}}
      bgcolor={themeVariables.palette.fader}
    >
      <Box display="flex" justifyContent="center" {...boxProps}>
        <div className={`svgpreloader svgpreloader-64 svgpreloader-white`}/>
      </Box>
    </Box>
  ) : (
    <Box display="flex" justifyContent="center" {...boxProps}>
      <div className={`svgpreloader ${cssModifier}`}/>
    </Box>
  );
};

import { IAdsLayout, IPresentation, IWebinarSource, TStreamLayout } from "../../types";
import {
    ADS_CHANGE,
    ADS_LIST_CHANGE,
    ADS_LIST_INIT,
    CLEAR_STREAM_STATE,
    LAYOUT_CHANGE,
    MEMBERS_AVAILABLE_UPDATE,
    MEMBERS_ONLINE_UPDATE,
    PRESENTATION_CHANGE,
    SOURCE_CHANGE,
    START_TIME_CHANGE,
    STREAM_END, STREAM_OPEN,
    STREAM_START,
    TStreamActions,
    UPDATE_STREAM_STATUS,
} from "./types";

export interface IStreamInitialState {
    id?: string;
    webinarLayout: TStreamLayout
    presentation?: IPresentation
    presentationPage: number
    presentationLink?: string
    presentationNumPages?: number
    ad: Array<IAdsLayout>
    startTime?: number
    isStarted: boolean
    isFinished: boolean
    membersOnline: number
    membersAvailable: number
    source?: IWebinarSource
    adsLayouts: Array<IAdsLayout>
    membersLimitState: 'empty' | 'low' | 'normal'
}

const initialState: IStreamInitialState = {
    webinarLayout: 'video',
    isStarted: false,
    isFinished: false,
    membersOnline: 0,
    membersAvailable: 0,
    presentationPage: 1,
    adsLayouts: [],
    ad: [],
    membersLimitState: 'normal'
}

export default function streamReducer(state = initialState, action: TStreamActions) {
    switch (action.type) {
        case STREAM_OPEN:
            return {
                ...state,
                id: action.id,
            };
        case STREAM_START:
            return {
                ...state,
                ...action.params,
                isStarted: true,
                startTime: Date.now()
            };
        case UPDATE_STREAM_STATUS:
            return {
                ...state,
                isStarted: action.status
            }
        case STREAM_END:
            return {
                ...state,
                isFinished: true
            }
        case ADS_CHANGE:
            return {
                ...state,
                ad: action.params
            }
        case ADS_LIST_INIT:
        case ADS_LIST_CHANGE:
            return {
                ...state,
                adsLayouts: action.params
            }
        case PRESENTATION_CHANGE:
            return {
                ...state,
                presentation: action.params.presentation,
                ...action.params
            }
        case LAYOUT_CHANGE:
            return {
                ...state,
                webinarLayout: action.layout
            }
        case SOURCE_CHANGE:
            return {
                ...state,
                source: action.params
            }
        case START_TIME_CHANGE:
            return {
                ...state,
                startTime: action.params
            }
        case CLEAR_STREAM_STATE:
            return {
                ...initialState
            }
        case MEMBERS_ONLINE_UPDATE:
            let membersLimitState = state.membersLimitState;
            let leftPlacesPercent =
                Math.abs(state.membersAvailable === 0 || action.members - 1 === 0
                    ? state.membersAvailable
                    : (state.membersAvailable / (action.members - 1)) * 100);
            if (leftPlacesPercent <= 20 && (action.members - 1 !== 0 && state.membersAvailable !== 0)) {
                membersLimitState = leftPlacesPercent > 0 ? 'low' : 'empty';
            }
            return {
                ...state,
                membersOnline: action.members,
                membersLimitState: membersLimitState
            }
        case MEMBERS_AVAILABLE_UPDATE: {

            let membersLimitState = state.membersLimitState;
            let leftPlacesPercent =
                action.available === 0 || state.membersOnline - 1 === 0 || state.membersOnline === 0
                    ? action.available
                    : (action.available / (state.membersOnline - 1)) * 100;

            if (leftPlacesPercent <= 20) {
                membersLimitState = leftPlacesPercent > 0 ? 'low' : 'empty';
            }

            return {
                ...state,
                membersAvailable: action.available,
                membersLimitState: membersLimitState,
            }
        }
        default:
            return state;
    }
}
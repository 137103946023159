import { Grid, List, ListItem } from '@material-ui/core';
import React from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import { PermissionDenied } from '../../components/PermissionDenied';
import Permission from '../../hoc/permission';
import Page from '../../hoc/page';
import { AdminStatistics } from './statistics';
import { TariffPage } from './tariff';
import { ChatPage } from './chat';
import { UserReviewsPage } from './userReviews';

export const AdminRoutes: React.FC = () => {
  return (
    <Permission entity="statistics" fallback={<PermissionDenied />}>
      <Page title="Админка" size="xl">
        <Grid container item md={12} direction="row" wrap="nowrap">
          <Grid item md={10}>
            <Switch>
              <Route path={['/admin/stats', '/admin/stats/usage']}>
                <AdminStatistics />
              </Route>
              <Route path={['/admin/user-reviews']}>
                <UserReviewsPage />
              </Route>
              <Route path={['/admin/tariff']}>
                <TariffPage />
              </Route>
              <Route path={['/admin/chat']}>
                <ChatPage />
              </Route>
            </Switch>
          </Grid>
          <Grid item md={2}>
            <List>
              <ListItem>
                <Link to="/admin/stats/usage">Использование и продвижение</Link>
              </ListItem>
              <ListItem>
                <Link to="/admin/user-reviews">Отзывы</Link>
              </ListItem>
              <ListItem>
                <Link to="/admin/tariff">Тарифы</Link>
              </ListItem>
              <ListItem>
                <Link to="/admin/chat">Настройки чата</Link>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Page>
    </Permission>
  );
};

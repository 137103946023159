import * as React from "react";
import { Box, Button, Grid } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import Cropper from "react-easy-crop";
import { MUIDialog } from "../../index";
import { SvgIcon } from "../../../components";
import styles from "./styles.module.scss";
import { tl } from "../../../utils";

import getCroppedImg from "./cropimage";

interface ImageUploadProps {
  className?: "coursesGroup" | "siteBG";
  onDelete?: (evt: any) => void;
  icon?: string;
  aspect?: number;
  cropSize?: { width: number; height: number };
  noResize?: boolean;
  onChange: (file: File) => void;
  buttonClass?: string;
}

export const ImageUpload: React.FC<ImageUploadProps> = ({
  className = 'coursesGroup',
  onDelete,
  icon,
  aspect,
  cropSize,
  noResize,
  onChange,
  buttonClass,
}) => {
  const [crop, setCrop] = React.useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });
  const [rotation, setRotation] = React.useState<number>(0);
  const [zoom, setZoom] = React.useState<number>(1);
  const [minZoom, setMinZoom] = React.useState<number>(1);
  const [mediadata, setMediadata] = React.useState<{
    width: number;
    height: number;
    naturalWidth: number;
    naturalHeight: number;
  }>();
  const [croppedAreaPixels, setCroppedAreaPixels] = React.useState<{ x: number; y: number; width: number; height: number; }>({
    height: 100,
    width: 100,
    x: 0,
    y: 0
  });
  const [aspectRatio, setAspectRatio] = React.useState<number>(aspect || 4 / 3);
  const [uploadedImageURL, setUploadedImageURL] = React.useState<string>("");
  const [uploadedImageType, setUploadedImageType] = React.useState<string>("");
  const [uploadedImageName, setUploadedImageName] = React.useState<string>("");

  const [inputId, setInputId] = React.useState<string>("coverUpload");
  const theme = useTheme();

  React.useEffect(() => {
    setInputId(`coverUpload-${Math.floor(Math.random() * 1e9)}`);
  }, []);

  const onCropComplete = React.useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onMediaLoaded = React.useCallback(({ ...data }) => {
    setMediadata(data);
    const minZoom = cropSize
      ? Math.max(cropSize.width / data.width, cropSize.height / data.height)
      : 1;
    setMinZoom(minZoom);
    setZoom(minZoom);
    if (noResize || !aspect) {
      setAspectRatio(data.width / data.height);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (!mediadata) {
      return;
    }
    setAspectRatio(1 / aspectRatio);
    const rotatedSize =
      rotation % 180
        ? {
          width: mediadata.height,
          height: mediadata.width,
        }
        : {
          width: mediadata.width,
          height: mediadata.height,
        };
    const sizer = cropSize || rotatedSize;
    const minZoomUpdated = Math.max(
      sizer.width / mediadata.width,
      sizer.height / mediadata.height
    );
    setMinZoom(rotation % 180 ? 1 / minZoomUpdated : minZoomUpdated);
    if (zoom < minZoomUpdated) {
      setZoom(minZoomUpdated);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rotation]);

  const showCroppedImage = React.useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        uploadedImageURL,
        croppedAreaPixels,
        rotation,
        uploadedImageType
      );
      const file = base64toFile(croppedImage, uploadedImageName, uploadedImageType);
      onChange(file);
    } catch (e) {
      console.error(e);
    }
  }, [uploadedImageURL, croppedAreaPixels, rotation, onChange, uploadedImageName, uploadedImageType]);


  const base64toFile = (b64Data: any, name: string, contentType: string, sliceSize = 512): File => {
    const byteCharacters = atob(b64Data.split(',')[1]);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    let blob: any = new Blob(byteArrays, { type: contentType });
    blob.lastModifiedDate = new Date();
    blob.name = name;
    return (blob as File);
  }

  return (
    <Box className={styles.wrap}>
      <input
        type="file"
        accept="image/*"
        className={styles.input}
        id={inputId}
        onChange={(evt: any) => {
          if (evt.target.files && evt.target.files.length > 0) {
            setUploadedImageType(evt.target.files[0].type);
            setUploadedImageName(evt.target.files[0].name);
            setUploadedImageURL(URL.createObjectURL(evt.target.files[0]));
          }
          evt.target.value = null;
          setZoom(1);
          setRotation(0);
          setCrop({ x: 0, y: 0 });
        }}
      />
      {onDelete ? (
        <Box className={styles.deleteButton} onClick={onDelete}>
          <SvgIcon type="courses-delete white" />
        </Box>
      ) : null}
      <Button
        component="label"
        htmlFor={inputId}
        variant="contained"
        className={`MuiButton-cover ${styles.button} ${styles[className]} ${buttonClass || ""}`}
      >
        {icon ? (
          <Box
            bgcolor={theme.palette.background.paper}
            className={styles.cover}
          >
            <img alt="ico" src={icon} className={styles.image} />
            <Box bgcolor={theme.palette.grey.A700} className={styles.refresh}>
              <SvgIcon type="courses-renew" />
            </Box>
          </Box>
        ) : (
          <Box bgcolor={theme.palette.background.paper} className={styles.plus}>
            <SvgIcon type="fs-attach" />
          </Box>
        )}
      </Button>
      <MUIDialog
        maxWidth="md"
        open={!!uploadedImageURL}
        onClose={() => setUploadedImageURL('')}
        actions={[
          [
            {
              label: tl("Отмена"),
              btnProps: {
                onClick: () => {
                  setUploadedImageURL('');
                },
              },
            },
          ],
          [
            {
              label: tl("Подтвердить"),
              btnProps: {
                color: "primary",
                onClick: () => {
                  showCroppedImage();
                  setUploadedImageURL('');
                },
              },
            },
          ],
        ]}
      >
        <Box my={-2} mx={-3} className={styles.crop}>
          <Box position="realtive">
            <Cropper
              image={uploadedImageURL || ""}
              crop={crop}
              zoom={zoom}
              minZoom={minZoom}
              maxZoom={noResize ? 1 : undefined}
              cropSize={cropSize}
              rotation={rotation}
              aspect={aspectRatio}
              onRotationChange={(evt: any, a: any) => {
                // console.log(evt, a);
              }}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              onMediaLoaded={onMediaLoaded}
            />
          </Box>
          <Box pt={1} pr={1} className={styles.cropRotate}>
            <Grid container spacing={1}>
              <Grid item>
                <Button
                  variant="contained"
                  className="MuiButton-iconAction"
                  onClick={() => {
                    setRotation(rotation - 90);
                  }}
                >
                  <SvgIcon type="rotate-rcw" />
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  className="MuiButton-iconAction"
                  onClick={() => {
                    setRotation(rotation + 90);
                  }}
                >
                  <SvgIcon type="rotate-cw" />
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </MUIDialog>
    </Box>
  );
};

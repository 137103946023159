import React from 'react';
import { IAdsLayout } from '../../../types';
import {
  Box,
  Card,
  CardMedia,
  Typography,
  IconButton,
  styled,
  Divider,
  makeStyles
} from '@material-ui/core';
import { themeVariables } from '../../../MUI';

import { SvgIcon } from '../..';
import Permission from '../../../hoc/permission';
import TickingClockIcon from '../../Icon/tickingClock';
import { secondsToHumanReadable } from '../../../utils/time';

interface AdsLayoutCardProps {
  selectable?: boolean;
  selected?: boolean;
  adsLayout: IAdsLayout;
  onClick: (layout: IAdsLayout) => void;
  onDelete?: (layout: IAdsLayout) => void;
  onCopy?: (layout: IAdsLayout) => void;
  onEdit?: (layout: IAdsLayout) => void;
  editable?: boolean;
}

const EditButton = styled(IconButton)({
  width: '30px',
  height: '30px',
  backgroundColor: 'rgba(136, 136, 136, 0.7)',
  position: 'absolute',
  right: '5px',
  top: '5px',
  borderRadius: '2px',

  '&:hover': {
    backgroundColor: 'rgba(136, 136, 136, 1)',
  },
});



export const RootCard = styled(Card)(({theme}) => ({
  width: 213,
  maxWidth: 213,
  border: themeVariables.border,
  borderRadius: '2px',
  boxShadow: 'none',
  cursor: 'pointer',
  position: 'relative',
  [theme.breakpoints.down('xs')]: {
    width: '100%',
    maxWidth: '100%',
  }
}))

const useStyles = makeStyles((theme)=>({
  root: {
    width: 213,
    maxWidth: 213,
    border: themeVariables.border,
    borderRadius: '2px',
    boxShadow: 'none',
    cursor: 'pointer',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      maxWidth: '100%',
    }
  },

  mediaBanner: {
    height: '100%',
  },

  mediaButtons: {
    height: '100%',
    backgroundPositionX: 'right',
  },
  mediaButtonsCenter: {
    height: '100%',
    backgroundPositionX: 'center',
  },
  mediaButtonsStart: {
    height: '100%',
    backgroundPositionX: 'left',
  },
  mediaButtonsEnd: {
    height: '100%',
    backgroundPositionX: 'right',
  },
  mediaBannerButtons: {
    height: '100%',
    backgroundSize: 'contain',
  },
  card: {
    
    '& > .copyDeleteWrap': {
      display: 'none',
      justifyContent: 'space-around',
      alignItems: 'center',
      width: '60px',
      height:'30px',
      backgroundColor: 'rgba(77, 78, 78, 0.75)',
      position: 'absolute',
      right: '5px',
      top: '10px',
      borderRadius: '2px',
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
      },
    },
    '&:hover': {
      '& > .copyDeleteWrap': {
        display: 'flex',
      },
    },
  },
  text: {
    fontWeight: 600,
  },
  cardName: {
    borderTop: '1px solid $common-border',
    padding: '5px 10px',
    height: 57,
  },
  cardText: {
    display: 'block',
    lineClamp: 3,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxHeight: 'calc(1em * 3 * 1,72)',
    lineHeight:'calc(1,72 * 1em)',
    wordBreak: 'break-word',
  },
  cardDelete: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '20px',
    height: '20px',
    backgroundColor: '#888',
    opacity: 0.7,
    position: 'absolute',
    right: '5px',
    top: '5px',
    borderRadius: '2px',
  },
  cardSelected: {
    opacity: (props:AdsLayoutCardProps) => (props.selected ? 1 : 0),
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(48, 171, 89, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1200,
    '&:hover': {
      opacity: 1,
      backgroundColor: (props: AdsLayoutCardProps) =>
        `rgba(48, 171, 89, ${props.selected ? 0.5 : 0.1})`,
    },

    '&:hover > div': {
      opacity: (props: AdsLayoutCardProps) => (props.selected ? 1 : 0.7),
    },
  },
  cardSelectedIcon: {
    position: 'relative',
    width: '36px',
    height: '36px',
    backgroundColor: '#fff',
    borderRadius: '50%',
  },

  cardSelectedIconInner: {
    position: 'absolute',
    top: '-7px',
    left: '-7px',
    transform: 'scale(0.7)',
  },

  timerBox: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    background: theme.palette.common.white,
    borderTopRightRadius: theme.shape.borderRadius,
    borderRight: '1px solid #D3D9DE',
    borderTop: '1px solid #D3D9DE',
    font: 'normal normal 600 16px/21px SF Pro Text',
    '& span': {
      paddingRight: theme.spacing(1),
    }
  },
  tickingClockIcon: {
    fontSize: 20,
    padding: 6,
    color: theme.palette.primary.main,
  },
  hr: {
    marginTop: 0,
    marginBottom: 0,
  }
}));

export const AdsLayoutCard: React.FC<AdsLayoutCardProps> = (props) => {
  const adsLayout = props.adsLayout;
  const copyAndDelete = !!props.onCopy && !!props.onDelete;
  const deleteOnly = !props.onCopy && !!props.onDelete;

  const classes = useStyles(props);
  const handleClick = (event: any) => {
    const target: HTMLElement | null = event.target.closest('.btn');

    if (!target) {
      props.onClick(adsLayout);
      return;
    }

    if (target.classList.contains('copy')) {
      props.onCopy && props.onCopy(adsLayout);
      return;
    }

    if (target.classList.contains('delete')) {
      props.onDelete && props.onDelete(adsLayout);
      return;
    }

    if (target.classList.contains('edit')) {
      props.onEdit && props.onEdit(adsLayout);
      return;
    }
  };

  const hasButtons =
    !!props.adsLayout.buttons && props.adsLayout.buttons.length > 0;
  const hasBanner = !!props.adsLayout.banner && !!props.adsLayout.banner.file;

  const getMediaClasses = () => {
    if (hasButtons && !hasBanner && props.adsLayout.buttonsAlign === 'flex-start') return classes.mediaButtonsEnd;
    if (hasButtons && !hasBanner && props.adsLayout.buttonsAlign === 'flex-end') return classes.mediaButtonsStart;
    if (hasButtons && !hasBanner && props.adsLayout.buttonsAlign === 'center') return classes.mediaButtonsCenter;
    if (hasBanner && !hasButtons) return classes.mediaBanner;
    if (hasButtons && !hasBanner) return classes.mediaButtons;
    if (hasButtons && hasBanner) return classes.mediaBannerButtons;
  };

  return (
    <RootCard className={classes.card} onClick={handleClick}>
      {props.selectable && (
        <Box className={classes.cardSelected}>
          <Permission entity="adsLayout" action="update">
            <EditButton disableRipple className="btn edit">
              <SvgIcon type="sw-edit" />
            </EditButton>
          </Permission>
          <Box className={classes.cardSelectedIcon}>
            <SvgIcon
              type="status"
              modifier={`done ${classes.cardSelectedIconInner}`}
            />
          </Box>
        </Box>
      )}
      {deleteOnly && (
       <Permission entity="adsLayout" action="delete">
          <Box className={classes.cardDelete}>
            <IconButton className="btn delete">
              <SvgIcon type="courses-delete white" />
            </IconButton>
          </Box>
       </Permission>
      )}
      <Box p="10px" height={'111px'} position="relative">
        <CardMedia className={getMediaClasses()} image={adsLayout.preview?.link || 'pix.gif'} />
        {adsLayout.timer &&
        <Box className={classes.timerBox}>
          <TickingClockIcon className={classes.tickingClockIcon} seconds={24} /> <span>{secondsToHumanReadable(adsLayout.timer.time)}</span>
        </Box>
      }
      </Box>
      {copyAndDelete && (
        <Permission entity="adsLayout" action={['create','delete']}>
          <Box className="copyDeleteWrap">
            <Permission entity="adsLayout" action="create">
              <Box p="5px" className="btn copy">
                <SvgIcon type="courses-copy white" />
              </Box>
            </Permission>
            <Permission entity="adsLayout" action="delete">
              <Box p="5px" className="btn delete">
                <SvgIcon type="courses-delete white" />
              </Box>
            </Permission>
          </Box>
        </Permission>
      )}
     
      <Divider className={classes.hr} />
      <Box className={classes.cardName}>
        <Typography className={`${classes.text} ${classes.cardText}`}>
          {adsLayout.name}
        </Typography>
      </Box>
    </RootCard>
  );
};

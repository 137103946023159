import * as React from "react";
import styles  from "./styles.module.scss";

export class File extends React.Component<{
  label: string;
  onChange?: any;
  accept?: string;
  name?: string
}> {
  render() {
    return (
      <label className={styles.file}>
        {this.props.label}
        <input name={this.props.name} type="file" style={{display: "none"}} onChange={this.props.onChange} accept={this.props.accept}/>
      </label>
    );
  }
}

import { ITask, TQueueName } from "../../types/Queue";

export const SET_TASKS = 'SET_TASKS';
export const ADD_TASK = 'ADD_TASK';
export const CLEAR_TASKS = 'CLEAR_TASKS';
export const REMOVE_TASK = 'REMOVE_TASK';
export const MAKE_READY_TASK = 'MAKE_READY_TASK';


export interface IAddTask {
  type: typeof ADD_TASK;
  queueName: TQueueName;
  task: ITask;
}
export interface IRemoveTaskAction {
  type: typeof REMOVE_TASK;
  taskId: string;
  queueName: TQueueName;
}
export interface ISetTasksAction {
  type: typeof SET_TASKS;
  queueName: TQueueName;
  tasks: Array<ITask>;
}

export interface IClearTasksAction {
  type: typeof CLEAR_TASKS;
  queueName: TQueueName;

}


export interface IMakeReadyTask {
  type: typeof MAKE_READY_TASK;
  taskId: string;
  queueName: TQueueName;
}

export type TTasksActions = 
  ISetTasksAction | 
  IClearTasksAction |
  IRemoveTaskAction |
  IMakeReadyTask |
  IAddTask;
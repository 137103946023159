import { SvgIcon, SvgIconProps } from '@material-ui/core';
import { forwardRef } from 'react';

export const StarIcon: React.FC<
  SvgIconProps & { filled?: boolean }
> = forwardRef(({ filled, ...props }, ref) => {
  return (
    <SvgIcon {...props} viewBox="0 0 29.274 27.986" innerRef={ref}>
      <g transform="translate(1.5 1.5)">
        <path
          d="M16.137,3,20.2,11.224l9.078,1.327-6.568,6.4,1.55,9.038-8.119-4.269L8.018,27.986l1.55-9.038L3,12.551l9.078-1.327Z"
          transform="translate(-3 -3)"
          fill={'#3f53d9'}
          fillOpacity={filled ? 1 : 0}
          style={{ transition: 'fill-opacity 50ms' }}
          stroke="#3f53d9"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="3"
        />
      </g>
    </SvgIcon>
  );
});

export default StarIcon;

import {
  Box,
  Button as Btn,
  Grid,
  makeStyles,
  styled,
  Tooltip,
  Typography,
} from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { SvgIcon } from '../..';
import { addNotification } from '../../../store/notifications/actions';
import {
  fetchWebinarConvert,
  fetchWebinarCopy,
  fetchWebinarFinishStream,
  fetchWebinarPause,
  fetchWebinarRemove,
} from '../../../store/webinar/actions';
import { EWebinarStatus, EWebinarType, IWebinar } from '../../../types';
import { copyToClipboard, tl, prettifyDuration } from '../../../utils';
import { ContextMenu } from '../../core/ContextMenu';
import DateInfoBuilder, { HelperText } from '../DateInfoBuilder';
import AWConvert from '../../Icon/awConvert';
import TimeIcon from '../../Icon/timeIcon';
import { WebinarDeleteModal, WebinarPauseModal } from '../../Modals';
import StartStopWebinarModal from '../../Modals/StartStopWebinarModal';
import { CardContextMenuBuilder } from '../CardContextMenu';
import WebinarStatus from '../Status';
import { Skeleton } from '@material-ui/lab';
import useAppSelector from '../../../hooks/useAppSelector';
import AlarmIcon from '../../Icon/alarm';

const Button = styled(Btn)({
  whiteSpace: 'nowrap',
});

const ConvertBtn = styled(Btn)(({ theme }) => ({
  lineHeight: '17px',
  '& .MuiButton-startIcon': {
    fontSize: 20,
  },
  paddingLeft: 12,
  paddingRight: 16,
  paddingTop: 7,
  paddingBottom: 8,
  minWidth: 147,
  [theme.breakpoints.down(1000)]: {
    fontSize: 13,
  },
}));

const useStyles = makeStyles((theme) => ({
  cont: {
    borderBottom: 1,
    borderBottomColor: '#E3E4E8',
    borderBottomStyle: 'solid',
  },
  root: {
    padding: '12px 14px 12px 12px',
    // borderBottom: 1,
    // borderBottomColor: '#E3E4E8',
    // borderBottomStyle: 'solid',
  },
  mainInfoWrap: {
    marginLeft: 12,
    flexWrap: 'nowrap',
  },
  subtitle: {
    fontSize: 13,
    lineHeight: 1.25,
    fontFamily: 'SF UI Text',
    fontWeight: 400,
    marginTop: 4,
    color: theme.palette.grey[600],
  },
  name: {
    fontSize: 13,
    lineHeight: '16px',
    fontFamily: 'SF UI Text',
    fontWeight: 600,
    marginRight: 24,
    overflow: 'hidden',
    display: 'box',
    lineClamp: 2 /* number of lines to show */,
    boxOrient: 'vertical',
    textOverflow: 'elepsiss',
    wordWrap: 'break-word',
  },
  greyColor: {
    fill: '#6d6d6d',
  },
  dateTooltip: {
    maxWidth: 162,
  },
  dateTooltipHelper: {
    textDecoration: 'underline',
  },
  buttonRoot: {
    marginRight: 8,
  },
  gridButton: {
    boxSizing: 'border-box',
    position: 'relative',
    padding: '13px 6px',
    cursor: 'pointer',
    flex: 0,
    '&:hover': {
      //@ts-ignore
      backgroundColor: theme.palette.default.main,
      '&:after': {
        content: '""',
        width: '100%',
        height: '100%',
        position: 'absolute',
        borderLeftColor: '#E3E4E8',
        borderLeftStyle: 'solid',
        borderRightColor: '#E3E4E8',
        borderRightStyle: 'solid',
        borderRight: 1,
        borderLeft: 1,
      },
    },
  },
  viewersCell: {
    minWidth: 67,
    flex: 0,
    margin: '0 24px',
  },
  cell1: {
    padding: '12px 14px 12px 12px',
    textDecoration: 'none',
    color: theme.palette.common.black,
    boxSizing: 'border-box',
    position: 'relative',
    maxWidth: 474,
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'nowrap',
      minWidth: 340,
    },
    '&:hover': {
      //@ts-ignore
      backgroundColor: theme.palette.default.main,

      '&:after': {
        position: 'absolute',
        content: '""',
        right: 0,
        borderRight: 1,
        borderRightColor: '#E3E4E8',
        borderRightStyle: 'solid',
        height: '100%',
      },
    },
  },
  green: {
    //@ts-ignore
    color: theme.palette.marks[5],
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
  },

  red: {
    color: '#EE9791',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
  },
  liveSelect: {
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    maxWidth: 64,
  },
  selectIcon: {
    width: 24,
    height: 24,
  },
  selectRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: '2px 12px',
    '& svg': {
      width: 24,
      height: 24,
    },
  },
  tooltipAlign: {
    textAlign: 'center',
  },
  shortTooltip: {
    maxWidth: 193,
  },
  linkBtn: {
    [theme.breakpoints.down('sm')]: {
      minWidth: 150,
    },
  },
}));

interface WebinarListCardProps {
  webinar: IWebinar;
}

export const WebinarListCard: React.FC<WebinarListCardProps> = ({
  webinar,
}) => {
  const classes = useStyles();
  const balance = useAppSelector((state) => state.user.profile.balance);

  const history = useHistory();

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [pauseModalOpen, setPauseModalOpen] = useState(false);
  const [stopWebinarModal, setStopWebinarModal] = useState(false);
  const dispatch = useDispatch();

  const handleLinkCopy = async (e: React.MouseEvent) => {
    e.preventDefault();
    await copyToClipboard(
      webinar.host
        ? `${webinar.host}/${webinar.link}`
        : `${window.location.origin}/${webinar.link}`
    );
    dispatch(addNotification('success', 'Ссылка скопирована'));
  };

  const convert = useCallback(() => {
    dispatch(fetchWebinarConvert(webinar.id));
  }, [dispatch, webinar]);

  const deleteWebinar = useCallback(() => {
    dispatch(fetchWebinarRemove(webinar));
  }, [webinar, dispatch]);

  const pause = useCallback(() => {
    dispatch(fetchWebinarPause(webinar));
  }, [webinar, dispatch]);

  const copy = useCallback(() => {
    dispatch(fetchWebinarCopy(webinar.id));
  }, [dispatch, webinar.id]);

  const finish = useCallback(
    (status?: boolean) => {
      dispatch(fetchWebinarFinishStream(webinar));
      setStopWebinarModal(false);
    },
    [webinar, dispatch]
  );
  const isBalanceLimited =
    [EWebinarStatus.LIVE, EWebinarStatus.OPEN].includes(webinar.status) &&
    balance <= 0;

  const getLinkTitle = () => {
    if (isBalanceLimited || webinar.status === EWebinarStatus.FINISH)
      return tl('Ссылка входа неактивна');
    if (!webinar.startTime)
      return tl(
        'Ссылка входа неактивна, потому что вы не выбрали дату вебинара или не сохранили ее'
      );

    if (webinar.status === EWebinarStatus.PAUSE) {
      return tl('Ссылка входа неактивна, потому что автовебинар остановлен');
    }

    return tl('Скопировать ссылку для входа зрителей');
  };
  const linkIsDisabled =
    isBalanceLimited ||
    !webinar.startTime ||
    webinar.status === EWebinarStatus.FINISH;

  // const wordRoot = webinar.type === EWebinarType.WEBINAR ? "вебинар" : "автовебинар";

  const renderButton = (webinar: IWebinar) => {
    switch (webinar.status) {
      case EWebinarStatus.ACTIVE:
      case EWebinarStatus.LIVE:
      case EWebinarStatus.OPEN:
        return (
          <Link
            className={classes.linkBtn}
            //@ts-ignore
            variant="contained"
            data-testid="webinar_entry"
            to={`webinar/${webinar.id}/player`}
            component={Button}
          >
            {tl('Вход на вебинар')}
          </Link>
        );

      case EWebinarStatus.DRAFT:
        if (webinar.type === EWebinarType.WEBINAR) {
          return (
            <Link
              className={classes.linkBtn}
              //@ts-ignore
              variant="contained"
              data-testid="webinar_settings_btn"
              to={`webinar/${webinar.id}`}
              component={Button}
            >
              {tl('Настройки')}
            </Link>
          );
        }
        return (
          <Link
            className={classes.linkBtn}
            //@ts-ignore
            variant="contained"
            data-testid="webinar_scenario"
            to={`webinar/${webinar.id}/scenario`}
            component={Button}
          >
            {tl('Сценарий')}
          </Link>
        );

      case EWebinarStatus.FINISH:
        if (webinar.type === EWebinarType.WEBINAR) {
          return (
            <ConvertBtn
              onClick={convert}
              startIcon={<AWConvert />}
              variant="contained"
            >
              {tl('Превратить в Автовебинар')}
            </ConvertBtn>
          );
        }
        return (
          <Link
            className={classes.linkBtn}
            //@ts-ignore
            variant="contained"
            data-testid="webinar_scenario"
            to={`webinar/${webinar.id}/scenario`}
            component={Button}
          >
            {tl('Сценарий')}
          </Link>
        );

      case EWebinarStatus.PAUSE:
        return (
          <Link
            className={classes.linkBtn}
            //@ts-ignore
            variant="contained"
            data-testid="webinar_scenario"
            to={`webinar/${webinar.id}/scenario`}
            component={Button}
          >
            {tl('Сценарий')}
          </Link>
        );
    }
  };

  return (
    <Grid container alignItems="center" wrap="nowrap" className={classes.cont}>
      <Grid
        container
        item
        className={classes.cell1}
        alignItems="center"
        xs={8}
        component={Link}
        to={`webinar/${webinar.id}`}
      >
        <Grid item>
          <WebinarStatus webinar={webinar} />
        </Grid>
        <Grid
          className={classes.mainInfoWrap}
          container
          item
          xs
          direction="column"
          data-testid="webinar_settings"
        >
          <Tooltip title={tl('Настройки вебинара')} arrow placement="top">
            <Grid item xs>
              <Typography
                className={classes.name}
                color={isBalanceLimited ? 'secondary' : undefined}
              >
                {webinar.name}
              </Typography>
            </Grid>
          </Tooltip>
          {webinar.type === EWebinarType.AUTOWEBINAR ? (
            <Grid item xs>
              <DateInfoBuilder webinar={webinar} />
            </Grid>
          ) : (
            <Grid item container direction="row" alignItems="center">
              {webinar.status !== EWebinarStatus.DRAFT ? (
                !!webinar.duration && (
                  <Box mt={1} display="flex" alignItems="center">
                    <TimeIcon />
                    <Box ml={1}>{prettifyDuration(webinar.duration)}</Box>
                  </Box>
                )
              ) : (
                <HelperText>Черновик</HelperText>
              )}
            </Grid>
          )}
        </Grid>

        <Grid item>
          {isBalanceLimited ? (
            <AlarmIcon color="error" />
          ) : (
            <SvgIcon type="courses-settings" />
          )}
        </Grid>
      </Grid>
      {webinar.status === EWebinarStatus.OPEN && !webinar.stats.members ? (
        <Grid item container xs className={classes.viewersCell} />
      ) : (
        <Tooltip
          title={
            webinar.type === EWebinarType.AUTOWEBINAR
              ? tl(
                  `Зрителей всех сессий Автовебинара: ${
                    webinar.stats.allMembers || 0
                  } \n Зрителей за последнюю сессию: ${webinar.stats.members}`
                )
              : tl(
                  webinar.stats.id
                    ? 'Открыть статистику зрителей'
                    : 'Количество зрителей'
                )
          }
          placement="top"
          arrow
        >
          <Grid
            item
            container
            data-testid="statistics"
            justify="center"
            alignItems="center"
            xs
            className={
              webinar.stats.id ? classes.gridButton : classes.viewersCell
            }
            onClick={() =>
              webinar.stats.id &&
              history.push(
                webinar.type === EWebinarType.AUTOWEBINAR
                  ? `/statistics/autowebinars/${webinar.id}`
                  : `/statistics/webinars/${webinar.id}/${webinar.stats.id}`
              )
            }
          >
            <Box
              display="flex"
              alignItems="center"
              py="19px"
              px="18px"
              minWidth="67px"
            >
              <Box ml="5px" mr="5px">
                <SvgIcon
                  type="courses-students"
                  modifier={webinar.stats.members ? undefined : 'dark'}
                />
              </Box>
              <Typography component="span">
                {webinar.type === EWebinarType.AUTOWEBINAR
                  ? webinar.stats.allMembers || 0
                  : webinar.stats.members}
              </Typography>
            </Box>
          </Grid>
        </Tooltip>
      )}
      <Tooltip
        title={getLinkTitle()}
        classes={{ tooltip: !webinar.startTime ? classes.shortTooltip : '' }}
        placement="top"
        arrow
      >
        <Grid
          item
          container
          direction="column"
          justify="center"
          alignItems="center"
          className={classes.gridButton}
          onClick={!linkIsDisabled ? (e) => handleLinkCopy(e) : () => {}}
        >
          <Grid item>
            <SvgIcon type={linkIsDisabled ? 'sw-link-gray' : 'sw-link'} />
          </Grid>
          <Grid item>
            {[EWebinarStatus.OPEN, EWebinarStatus.LIVE].includes(
              webinar.status
            ) && balance > 0 ? (
              <Typography className={classes.green} component="span">
                {tl('Вход')}
                <br /> {tl('открыт')}
              </Typography>
            ) : (
              <Typography className={classes.red} component="span">
                {tl('Вход')}
                <br /> {tl('закрыт')}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Tooltip>
      <Grid
        item
        container
        direction="row"
        xs
        justify="space-between"
        wrap="nowrap"
      >
        <Grid item xs={7} className={classes.buttonRoot}>
          <Box pl={3}>{renderButton(webinar)}</Box>
        </Grid>
        <Grid
          item
          container
          xs
          direction="row"
          alignItems="center"
          justify="flex-end"
        >
          <Grid item></Grid>
          <Grid item>
            <Box ml={1} mr={1}>
              <ContextMenu>
                <CardContextMenuBuilder
                  webinar={webinar}
                  onDelete={() => setDeleteModalOpen(true)}
                  onStop={() => setStopWebinarModal(true)}
                  onCopy={copy}
                />
              </ContextMenu>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <WebinarDeleteModal
        open={deleteModalOpen}
        webinarType={webinar.type}
        externalSettings={webinar.externalSettings}
        handleClose={() => setDeleteModalOpen(false)}
        handleConfirm={() => {
          setDeleteModalOpen(false);
          deleteWebinar();
        }}
      />
      <WebinarPauseModal
        open={pauseModalOpen}
        webinarName={webinar.name}
        handleClose={() => setPauseModalOpen(false)}
        handleConfirm={() => {
          setPauseModalOpen(false);
          pause();
        }}
      />
      <StartStopWebinarModal
        isStarted={webinar.status === EWebinarStatus.LIVE}
        open={stopWebinarModal}
        onClose={() => setStopWebinarModal(false)}
        onChangeStatus={finish}
      />
    </Grid>
  );
};

export const WebinarListCardSkeleton: React.FC = () => {
  const classes = useStyles();
  return (
    <Grid container alignItems="center" wrap="nowrap" className={classes.cont}>
      <Grid container item className={classes.cell1} alignItems="center" xs={8}>
        <Box minWidth={91} minHeight={60}>
          <Skeleton
            width="91px"
            height="60px"
            variant="rect"
            style={{ borderRadius: 4 }}
          />
        </Box>
        <Grid
          className={classes.mainInfoWrap}
          container
          item
          xs
          direction="column"
        >
          <Grid item xs>
            <Skeleton width="150px" height="16px" variant="text" />
          </Grid>
          <Grid item xs>
            <Skeleton width="100px" height="16px" variant="text" />
          </Grid>
        </Grid>
        <Grid item>
          <Skeleton width="20px" height="20px" variant="rect" />
        </Grid>
      </Grid>
      <Grid item container xs className={classes.viewersCell} />
      <Grid
        item
        container
        direction="column"
        justify="center"
        alignItems="center"
        className={classes.gridButton}
      >
        <Grid item>
          <Skeleton
            width="20px"
            height="20px"
            variant="rect"
            style={{ borderRadius: 4 }}
          />
        </Grid>
        <Grid item>
          <Skeleton width="45px" height="40px" variant="text"></Skeleton>
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction="row"
        xs
        justify="space-between"
        wrap="nowrap"
      >
        <Grid item xs={7} className={classes.buttonRoot}>
          <Box pl={3}>
            <Skeleton
              width="100px"
              height="30px"
              variant="rect"
              style={{ borderRadius: 4 }}
            />
          </Box>
        </Grid>
        <Grid
          item
          container
          xs
          direction="row"
          alignItems="center"
          justify="flex-end"
        >
          <Grid item></Grid>
          <Grid item>
            <Box mr={3}>
              <Skeleton width="6px" height="24px" variant="rect" />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

import React, { createContext, useContext, useState } from 'react';
import { IPayment, TIntegration, TAvailableIntegrations } from '../../types';
import { v4 as uuid} from 'uuid';

declare global {
  interface Window {
    cp: any;
  }
}

export const createPayFunction = (integration: TIntegration, payment: IPayment, userId: string) => {
  return () => {
    switch (integration.type) {
      case 'cloudPayments':
        const widget = new window.cp.CloudPayments();
        widget.pay(payment.steps === 2 ? 'auth' : 'charge',
          {
            publicId: integration.settings.publicId, // PublicId
            description: payment.description, //назначение
            amount: +payment.amount, //сумма
            currency: payment.currency.id, //валюта
            skin: payment.skin.type, // дизайн виджета (необязательно)
            requireEmail: payment.requireEmail, // спрашивать у пользователя email,
            accountId: userId, // uuid зрителя
            invoiceId: uuid() //номер заказа
          },
          {
            onSuccess: function () { // success
              //действие при успешной оплате
            },
            onFail: function () { // fail
              //действие при неуспешной оплате
            },
            onComplete: function () { //Вызывается как только виджет получает от api.cloudpayments ответ с результатом транзакции.
              //например вызов вашей аналитики Facebook Pixel
            }
          }
        )
        break;
      default:
        break;
    }
  }
}

interface IPaymentWidgetsContext {
  loaded: TAvailableIntegrations,
  load: (integration: TIntegration, payment: IPayment) => Promise<boolean>,
  createPayFunction: (integration: TIntegration, payment: IPayment, userId: string) => () => void,
}

const defaultLoaded: TAvailableIntegrations = {
  cloudPayments: false,
}

const paymentWidgetsScripts = {
  cloudPayments: 'https://widget.cloudpayments.ru/bundles/cloudpayments.js'
}

const paymentWidgetsContext = createContext<IPaymentWidgetsContext | undefined>(undefined);

interface PaymentWidgetsProviderProps {
  children: React.ReactNode;
}

export const PaymentWidgetsProvider: React.FC<PaymentWidgetsProviderProps> = ({children}) => {
  const [loaded, setLoaded] = useState<TAvailableIntegrations>(defaultLoaded);

  const load = (integration: TIntegration, payment: IPayment) => {
    return new Promise<boolean>((resolve, reject) => {
      const src = paymentWidgetsScripts[integration.type];
      // Необходимо проверить, не был ли скрипт загружен ранее
      const script = document.head.querySelector(`script[src='${src}']`);
      if (!script) {
        // Готовим скрипт для подключения его в head
        const widgetScript = document.createElement('script');
        widgetScript.src = src;
        document.head.appendChild(widgetScript);
        // Если за 5 секунд скрипт не загрузился, то прекращаем ждать его загрузку
        setTimeout(() => resolve(false), 30000);
        // При возникновении ошибки при загрузке скрипта очищаем head
        widgetScript.onerror = () => {
          document.head.removeChild(widgetScript);
          resolve(false);
        }
        // В случае успешной загрузки скрипта выставляем новый статус
        widgetScript.onload = () => {
          setLoaded({...loaded, [integration.type]: true});
          resolve(true);
        }
      } else {
        resolve(loaded[integration.type]);
      }
    })
  }

  return(
    <paymentWidgetsContext.Provider value={{loaded: loaded, load: load, createPayFunction: createPayFunction}}>
      {children}
    </paymentWidgetsContext.Provider>
  )
}

export default function usePaymentWidgets() {
  return useContext(paymentWidgetsContext);
}
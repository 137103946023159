import { Box, IconButton, styled } from "@material-ui/core";
import { PlayArrowRounded } from "@material-ui/icons";
// import { PlayIcon } from "../../Icon/play";

const PlayWrap = styled(Box)({
  '@global': {
    '@keyframes popUp': {
      '0%': {
        opacity: 0,
      },
      '30%': {
        opacity: 0.25,
      },
      '100%': {
        opacity: 1,
      }
    },
  },
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '10%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  aspectRatio: '1/1',
  zIndex: 1300,
  borderRadius: '50%',
  background: '#6d6d6d',
  transform: 'translate(-50%, -50%)',
  animation: '$popUp 500ms ease',
  //fallback for safari
  '&::before': {
    float: 'left',
    paddingTop: '100%',
    content: "''",
  },
  '&::after': {
    display: 'block',
    content: "''",
    clear: 'both',
  },
  '& button': {
    color: '#fff',
    width: '100%',
    height: '100%',
    padding: 0,
    '& svg': {
      width: '80%',
      height: '80%',
    }
  }

});

interface PlayButtonProps {
  onClick: () => void;
  playing?: boolean;
}

export const PlayButton: React.FC<PlayButtonProps> = ({onClick}) => {


  return (
    <PlayWrap>
      <IconButton onClick={onClick}>
        <PlayArrowRounded />
      </IconButton>
    </PlayWrap>
  )
}
import * as React from "react";
import {Select} from "@material-ui/core";
import {SelectProps} from "@material-ui/core/Select";
import {SvgIcon} from "../components/SvgIcon";

export const MUISelect = (props: SelectProps) => {
  return (
    <Select
      variant="outlined"
      IconComponent={() => (
        <div className="MuiSelect-icon">
          <SvgIcon type="select-drop"/>
        </div>
      )}
      {...props}
    >
      {props.children}
    </Select>
  );
};

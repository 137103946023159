import React from 'react';
import { E164Number } from 'libphonenumber-js/types';
import PhoneInputWithCountrySelect, { parsePhoneNumber } from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';
import ru from 'react-phone-number-input/locale/ru.json';
import { makeStyles } from '@material-ui/core';
import { makeLineClamp } from '../../../MUI';
import { tl } from '../../../utils';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  inputWrap: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    position: 'relative',
    height: 32,

    '& .PhoneInput': {
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: theme.palette.common.white,
      border: 'none',
      outline: (props: SettingsPhoneInputProps) => { return props.error ? '1px solid red' : 'none' },
      borderRadius: 35,
    },

    '& .PhoneInput--focus': {
      border: '1px solid ${theme.palette.primary.main}'
    },

    '& .PhoneInputInput': {
      width: '100%',
      border: '1px solid #d3d3d3',
      borderRadius: 4,
      padding: '10px 7px',

      '&:hover': {
        borderColor: theme.palette.primary.main,
      },

      '&:focus-visible': {
        outline: `1px solid ${theme.palette.primary.main}`,
      },

      '&::placeholder': {
        color: '#4D4E4EBF',
        fontSize: 13,
        font: "SF UI Text",
        opacity: 0.42,
        ...makeLineClamp(2, 16),
      },

      '&:-webkit-autofill': {
        backgroundColor: '#FFFFFF!important',
        transition: 'background-color 5000s ease-in-out 0s',
      }
    },

    '& .PhoneInputCountry': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      position: 'relative',
      marginRight: 10,
      width: 216,
      padding: 8,
    },

    '& .PhoneInputCountrySelect': {
      position: 'absolute',
      top: 0,
      left: 0,
      height: '110%',
      width: '100%',
      border: 0,
      opacity: 0,
    },

    '& .PhoneInputCountrySelectArrow': {
      order: 0,
      display: 'none',
      content: '',
      width: '0.3em',
      height: '0.3em',
      marginRight: '0.6em',
      borderStyle: 'solid',
      borderColor: 'inherit',
      borderWidth: '0 1px 1px 0',
      transform: 'rotate(45deg)',
      opacity: 0.45,
    },

    '& .PhoneInputCountryIcon': {
      order: 1,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: 30,
      height: 20,

      '& img': {
        border: `1px solid ${theme.palette.common.black}`,
        maxWidth: '100%',
      },
      '& svg': {
        width: 30,
        height: 20,
      }
    }
  },
}));

interface SettingsPhoneInputProps {
  error?: string;
  value?: string;
  onChange: (value: E164Number | undefined) => void;
}

export const SettingsPhoneInput: React.FC<SettingsPhoneInputProps> = (props) => {
  const classes = useStyles({...props});

  return (
    <div className={classes.root}>
      <div className={classes.inputWrap}>
        <PhoneInputWithCountrySelect
          countrySelectProps={{id: 'phoneCode', 'data-testid': 'phoneCode', disabled: true }}
          numberInputProps={{id: 'phoneNumber', 'data-testid': 'phoneNumber', placeholder: tl("Ваш номер телефона")}}
          international
          flags={flags}
          labels={ru}
          error={props.error}
          value={props.value}
          onChange={value => { props.onChange(value)}}
        />
      </div>
    </div>
  )
}
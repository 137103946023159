import React, { CSSProperties } from "react";
import {KeyboardDatePicker, KeyboardDateTimePicker, KeyboardTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {SvgIcon} from "../../components";
import {tl} from "../../utils";
import styles  from "./styles.module.scss";
import DateFnsUtils from '@date-io/date-fns'
import {ru} from "date-fns/locale";
import { addMinutes, differenceInMinutes } from "date-fns";

interface IProps {
  value: Date | null | undefined;
  onChange: (value: any) => void;
  onClick?: () => void;
  onClose?: () => void;
  disablePast?: boolean;
  type?: "date" | "time" | "timeDate";
  disabled?: boolean;
  minDate?: Date;
  maxDate?: Date;

  error?: boolean;
  helperText?: string;
  style?: CSSProperties
}

export const MUIDateTimePicker = React.forwardRef((props: IProps, ref) => {
  const selectedDate = props.value;
 
  const dateTimePicker = (
    <KeyboardDateTimePicker
      value={selectedDate}
      onChange={(date) => {
        if(!selectedDate){
          if(differenceInMinutes(new Date(date!.getTime()), new Date()) < 11) {
            props.onChange(addMinutes(new Date(), 10));
          } else {
            props.onChange(date);
          }
        } else {
          props.onChange(date);
        }
      }}
      style={props.style}
      disablePast={props.disablePast}
      format="dd.MM.yyyy HH:mm"
      keyboardIcon={<SvgIcon type="datepicker" />}
      inputVariant="outlined"
      okLabel={tl("ОК")}
      cancelLabel={tl("Отмена")}
      clearLabel={tl("Очистить")}
      todayLabel={tl("Сейчас")}
      placeholder={tl("Выберите дату")}
      data-testid="datetimepicker"
      invalidDateMessage={tl("Неправильный формат даты/времени")}
      maxDateMessage={!props.disabled && tl("Выбранная дата не должна превышать максимальную")}
      minDateMessage={!props.disabled && tl("Выбранная дата не должна быть меньше минимальной")}
      ampm={false}
      minDate={props.minDate}
      maxDate={props.maxDate}
      className={styles.dtPicker}
      disabled={props.disabled}
      error={props.error}
      helperText={props.helperText}
    />
  );

  const datePicker = (
    <KeyboardDatePicker
      value={selectedDate}
      onChange={props.onChange}
      disablePast={props.disablePast}
      format="dd.MM.yyyy"
      keyboardIcon={<SvgIcon type="datepicker"/>}
      inputVariant="outlined"
      okLabel={tl("ОК")}
      style={props.style}
      cancelLabel={tl("Отмена")}
      clearLabel={tl("Очистить")}
      todayLabel={tl("Сейчас")}
      invalidDateMessage={tl("Неправильный формат даты/времени")}
      maxDateMessage={!props.disabled && tl("Выбранная дата не должна превышать максимальную")}
      minDateMessage={!props.disabled && tl("Выбранная дата не должна быть меньше минимальной")}
      className={styles.dtPicker}
      disabled={props.disabled}
    />
  );

  const [open, setOpen] = React.useState(false);
  const timePicker = (
      <KeyboardTimePicker
        innerRef={ref}
        value={selectedDate}
        onChange={props.onChange}
        format="HH:mm"
        keyboardIcon={false}
        inputVariant="outlined"
        okLabel={tl("ОК")}
        cancelLabel={tl("Отмена")}
        clearLabel={tl("Очистить")}
        todayLabel={tl("Сейчас")}
        className={styles.timePicker}
        style={props.style}
        ampm={false}
        open={open}
        onClick={() => {
          if (!props.disabled)
            setOpen(true)
            props.onClick?.()
          
        }}
        onClose={() => {
          setOpen(false)
          props.onClose?.()
        }}
        disabled={props.disabled}
      />
  );

  const renderPicker = () => {
    switch (props.type) {
      case ("date"): return datePicker;
      case ("time"): return timePicker;
      default: return dateTimePicker;
    };
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
      {renderPicker()}
    </MuiPickersUtilsProvider>
  );
});

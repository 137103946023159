import { Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store'
import { removeNotification } from '../../store/notifications/actions'
import { tl } from '../../utils'


export interface NotificationCenterProps {

}


export const NotificationCenter:React.FC<NotificationCenterProps> = ()=> {
    const {list} = useSelector((state:RootState)=>state.notifications)
    const dispatch = useDispatch()
    return (
        <React.Fragment>
            {list.map(n=>
                <Snackbar
                    open={true}
                    anchorOrigin={
                        { vertical: 'bottom', horizontal: 'right' }
                    }
                    onClose={()=>dispatch(removeNotification(n.id))}
                    autoHideDuration={8000} 
                    key={n.id}
                >
                    <Alert 
                        elevation={6} 
                        variant="filled"
                        severity={n.type}
                    >
                        {tl(n.message)}
                    </Alert>
                </Snackbar>
            )}
        </React.Fragment>
    )
}
export interface ICurrency {
  id: "RUB" | "USD" | "EUR";
  name: string;
}

export const currencies: ICurrency[] = [
  {
    id: "RUB",
    name: "руб."
  },
  {
    id: "USD",
    name: "usd",
  },
  {
    id: "EUR",
    name: "eur",
  }
];

export interface ICloudPaymentsWidgetSkin {
  type: "classic" | "modern" | "mini",
  name: string;
}

export const cloudPaymentsWidgetSkins: ICloudPaymentsWidgetSkin[] = [
  {
    type: "mini",
    name: "Mini",
  },
  {
    type: "classic",
    name: "Classic",
  },
  {
    type: "modern",
    name: "Modern",
  },
];

export interface IPayment {
  description: string;
  amount: string;
  currency: ICurrency;
  steps: number;
  skin: ICloudPaymentsWidgetSkin;
  requireEmail: boolean;
}
import { push } from "connected-react-router";
import {AppThunk} from "..";
import { api } from "../../api";
import {apiClient} from "../../api/_client";
import {EWebinarStatus, IAdsLayout, IPresentation, IWebinar, IWebinarSource, TStreamLayout} from "../../types";
import {addNotification} from "../notifications/actions";
import {closeConnection} from "../streamWSMiddleware/actions";
import {
    ADS_CHANGE,
    ADS_LIST_CHANGE,
    ADS_LIST_INIT,
    CLEAR_STREAM_STATE,
    IAdsChangeAction,
    IAdsListChangeAction,
    IAdsListInitAction,
    IChangeStreamStatusAction,
    ILayoutChangeAction,
    IMembersAvailableUpdateAction,
    IMembersOnlineUpdateAction,
    IPresentationChangeAction,
    ISourceChangeAction, IStartTimeChangeAction,
    IStreamEndAction, IStreamOpenAction,
    IStreamStartAction,
    LAYOUT_CHANGE,
    MEMBERS_AVAILABLE_UPDATE,
    MEMBERS_ONLINE_UPDATE,
    PRESENTATION_CHANGE,
    SOURCE_CHANGE, START_TIME_CHANGE,
    STREAM_END, STREAM_OPEN,
    STREAM_START,
    TStreamEvent,
    UPDATE_STREAM_STATUS
} from "./types";

export function adChange(params: Array<IAdsLayout>): IAdsChangeAction {
    return {
        type: ADS_CHANGE,
        params
    }
}

export function adsListInit(params: Array<IAdsLayout>): IAdsListInitAction {
    return {
        type: ADS_LIST_INIT,
        params
    }
}

export function adsListChange(params:Array<IAdsLayout>): IAdsListChangeAction {
    return {
        type: ADS_LIST_CHANGE,
        params
    }
}

export function streamOpen(id:string): IStreamOpenAction {
    return {
        type: STREAM_OPEN,
        id
    }
}

export function streamStart(params: {
    webinarLayout: TStreamLayout;
    presentation?: IPresentation;
    presentationPage?: number;
    ad: Array<IAdsLayout>
}): IStreamStartAction {
    return {
        type: STREAM_START,
        params
    }
}


export function streamEnd(): IStreamEndAction {
    return {
        type: STREAM_END
    }
}

export function layoutChange(layout: TStreamLayout): ILayoutChangeAction {
    return {
        type: LAYOUT_CHANGE,
        layout
    }
}

export function presentationChange(params: { presentation?: IPresentation, presentationPage?: number, presentationLink?: string }): IPresentationChangeAction {
    return {
        type: PRESENTATION_CHANGE,
        params
    }
}

export function fetchPresentation(id: string): AppThunk {
    return async dispatch => {
        try {
            const presentation = await api.presentation.get(id)
            dispatch(presentationChange({ presentation }))
        } catch (error) {
            dispatch(addNotification('error', 'Не получилось загрузить презентацию.'))
        }
    }
}

export function sourceChange(params: IWebinarSource): ISourceChangeAction {
    return {
        type: SOURCE_CHANGE,
        params
    }
}

export function startTimeChange(params: number): IStartTimeChangeAction {
    return {
        type: START_TIME_CHANGE,
        params
    }
}

export function membersOnlineUpdate(count: number): IMembersOnlineUpdateAction {
    return {
        type: MEMBERS_ONLINE_UPDATE,
        members: count,

    }
}
export function membersAvailableUpdate(count: number): IMembersAvailableUpdateAction {
    return {
        type: MEMBERS_AVAILABLE_UPDATE,
        available: count
    }
}
export function changeStreamStatus(status: boolean): IChangeStreamStatusAction {
    return {
        type: UPDATE_STREAM_STATUS,
        status
    }
}


export function sync(streamId: string): AppThunk {
    return async (dispatch) => {
        const {isFinished, state} = await api.stream.status(streamId);
        if (isFinished) {
          dispatch(streamEnd());
        }
        if (state.membersAvailable) {
            dispatch(membersAvailableUpdate(state.membersAvailable));
        }
        if (state.membersOnline) {
            dispatch(membersOnlineUpdate(state.membersOnline));
        }
        if (state.start) {
            dispatch(changeStreamStatus(true));
        }
        if (state.ads) {
            dispatch(adChange(state.ads))
        }
        if (state.layout) {
            dispatch(layoutChange(state.layout))
        }
        if (state.presentation) {
            dispatch(presentationChange(state.presentation))
        }
        if (state.video) {
            dispatch(sourceChange(state.video));
        }
    }
}


export function start(webinarLink: string, params: {
    webinarLayout: TStreamLayout;
    presentation?: IPresentation;
    presentationPage?: number;
    ad: Array<IAdsLayout>
}): AppThunk {
    return async dispatch => {
        try {
            await apiClient.post(`/player/${webinarLink}/event`, {
                name: 'start',
                params: params
            });
            dispatch(streamStart(params))
            // dispatch(changeStreamStatus(true))
        } catch (error) {
            dispatch(addNotification('error', 'Не получилось запустить трансляцию'))
        }
    }
}

export function finish(webinarLink: string): AppThunk {
    return async dispatch => {
        try {
            await apiClient.post(`/player/${webinarLink}/event`, {
                name: 'end'
            });
            dispatch(streamEnd())
            dispatch(addNotification('info', 'Вебинар завершен'))
            dispatch(closeConnection())
            dispatch(push('/'))
        } catch (error) {
            dispatch(addNotification('warning', 'Не получилось завершить вебинар, попробуйте еще раз!'))
        }
    }
}


export function createEvent(event: TStreamEvent, webinarLink: string, params: any): AppThunk {
    return async (dispatch) => {

        try {
            await apiClient.post(`/player/${webinarLink}/event`, {
                name: event,
                params: params
            });
        } catch (error) {
            dispatch(addNotification('error', 'Ошибка при создании события трансляции!'))
        }
    }
}


export function clickAds(streamId: string, adId: string, userId: string): AppThunk {
    return async dispatch => {
        try {
            await apiClient.post(`/player/${streamId}/ads`, {
                member: userId,
                ads: adId
            });
        } catch (error) {
            dispatch(addNotification('info', 'Клик по рекламе сломался.'))
        }
    }

}


export function initStreamState(webinar: IWebinar): AppThunk {
    return async (dispatch) => {
        const streamIsReady = (webinar.status === EWebinarStatus.OPEN || webinar.status === EWebinarStatus.LIVE)

        if (streamIsReady) {
            dispatch(streamOpen(webinar.stats.id))
        }
        if(webinar.source.src || webinar.source.videoLink) {
            dispatch(sourceChange(webinar.source))
        }
        if(webinar.adsLayouts) {
            dispatch(adsListInit(webinar.adsLayouts))
        }
        if (webinar.status === EWebinarStatus.LIVE) {
            dispatch(changeStreamStatus(true))
        }
        if (webinar.status === EWebinarStatus.FINISH) {
            dispatch(streamEnd())
        }
        dispatch(syncStreamState(webinar));
    }
}

export function syncStreamState(webinar: IWebinar): AppThunk {
    return async (dispatch) => {
        const wState = webinar.state;
        if (wState) {
            if (wState.presentation) {
              dispatch(presentationChange(wState.presentation));
            }
            if (wState.ads) {
              dispatch(adChange(wState.ads));
            }
            if (wState.layout) {
              dispatch(layoutChange(wState.layout));
            }
            if (wState.video) {
                dispatch(sourceChange(wState.video));
            }
          }
    }
}

export function clearStreamState() {
    return {
        type: CLEAR_STREAM_STATE
    }
}
import {IChatConfig} from "../types";
import {apiClient} from "./_client";

export interface Blacklist {
  obscene: string
  negative: string
}

export const get = async (webinarLink:string, userId:string):Promise<IChatConfig> =>{
  const response = await apiClient.post<IChatConfig>(`/chat/${webinarLink}`, {
    user:userId
  });
  return response.data;
}

export const getBlacklist = async (): Promise<Blacklist> =>{
  const response = await apiClient.get<Blacklist>('/chat/blacklist/');
  return response.data;
}
export const setBlacklist = async (blacklist:Blacklist): Promise<boolean> =>{
  const response = await apiClient.put<Blacklist>('/chat/blacklist/', blacklist);
  return response.status === 200;
}
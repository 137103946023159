import { AppThunk } from "..";
import { api } from "../../api";
import { copyToClipboard } from "../../utils";
import { addNotification } from '../notifications/actions';
import { IInvoiceSort } from "../../api/billing";
import { IBillingStat } from "../../types/Billing";
import { fetchStatisticsMembers } from "../statistics/actions";
import { BILLING_LIST_FETCH_ATTEMPT, BILLING_LIST_FETCH_ERROR, BILLING_LIST_FETCH_SUCCESS, IBillingListFetchSuccess, IBillingListFetchNextSuccess, BILLING_LIST_FETCH_NEXT_SUCCESS, PAY_ATTEMPT, PAY_SUCCESS, PAY_ERROR } from "./types";


export function fetchBillingListError() {
  return {
    type: BILLING_LIST_FETCH_ERROR
  }
}

export function fetchBillingListAttempt(loadingNext?: boolean) {
  return {
    type: BILLING_LIST_FETCH_ATTEMPT,
    loadingNext
  }
}

export function fetchBillingListSuccess(list: Array<IBillingStat>, countItems: number, next?: boolean): IBillingListFetchSuccess | IBillingListFetchNextSuccess {
  return {
    type: next ? BILLING_LIST_FETCH_NEXT_SUCCESS : BILLING_LIST_FETCH_SUCCESS,
    list, countItems
  }
}

export function getInvoices(sort: IInvoiceSort, offset?: number): AppThunk {
  return async (dispatch) => {
    dispatch(fetchBillingListAttempt(!!offset));
    try {
      const data = await api.billing.invoice(sort, 20, offset);
      if (offset) {
        dispatch(fetchBillingListSuccess(data.list, data.paginator.countItems, !!offset));
      } else {
        dispatch(fetchBillingListSuccess(data.list, data.paginator.countItems));
      }
    } catch (error) {
      dispatch(fetchBillingListError());
    }
  }
}

export function buyContacts(streamId: string): AppThunk {
  return async (dispatch) => {
    try {
      const result = await api.billing.buyContacts(streamId);
      if (result) {
        dispatch(fetchStatisticsMembers({
          streamId,
          limit: 10,
          offset: 0
        }))
      }
    } catch (error) {

    }
  }
}

export function payAttempt() {
  return {
    type: PAY_ATTEMPT
  }
}

export function paySuccess() {
  return {
    type: PAY_SUCCESS,
  }
}

export function payError() {
  return {
    type: PAY_ERROR
  }
}

export function getPayLink(amount: number): AppThunk {
  return async (dispatch) => {
    dispatch(payAttempt());
    try {
      const data = await api.billing.pay(amount);
      await copyToClipboard(data.location, () => dispatch(addNotification('success', 'Ссылка на оплату скопирована')));
      dispatch(paySuccess());
    } catch (error) {
      dispatch(payError());
      dispatch(addNotification('error', 'Произошла ошибка'));
    }
  }
}
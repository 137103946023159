import * as React from "react";

export default class Help extends React.Component<{
  title: string;
}> {
  render() {
    return (
      <span title={this.props.title}>
        <img
          alt="help"
          className="b-icon i-quest"
          src="/public/images/pix.gif"
          style={{display: "inline"}}
        />
      </span>
    );
  }
}

import { defRules } from "../../context/accessControl";
import { EAuthStatus, EUSerStatus, IUser } from "../../types";
import { PROFILE_FETCH_ATTEMPT, PROFILE_FETCH_FAILURE, PROFILE_FETCH_SUCCESS, PROFILE_UPDATE_ATTEMPT, PROFILE_UPDATE_SUCCESS, PROFILE_UPDATE_FAILURE, SECURITY_UPDATE_ATTEMPT, SECURITY_UPDATE_SUCCESS, SECURITY_UPDATE_FAILURE, AUTH_LOGOUT, AUTH_STATUS_CHANGED, TUserActionType, UPDATE_BALANCE } from "./types";

interface IUserState {
    status: EAuthStatus
    profile: IUser
    updatingProfile: boolean
    updatingSecurity: boolean
    error?: string
    securityError?: string
}

const initialState:IUserState = {
    status: EAuthStatus.UNKNOWN,
    profile: {
        name: '',
        lastName: '',
        phone: '',
        refreshTokenEndpoint: '',
        timeZone: '',
        token: '',
        sid:'',
        balance: 0,
        freePeriod: false,
        status: EUSerStatus.STATUS_ACTIVE,
        permissions: defRules.rules
    },
    updatingProfile: false,
    updatingSecurity: false,
}


export function userReducer(state=initialState, action:TUserActionType) {
 switch (action.type) {
     case PROFILE_FETCH_ATTEMPT:
         return {
            ...state,
            status: EAuthStatus.UNKNOWN,
            error: ''
         }
    case PROFILE_FETCH_SUCCESS:
        return {
            ...state,
            profile: action.profile,
            status: EAuthStatus.AUTHORIZED,
            error: ''
        }
    case PROFILE_FETCH_FAILURE:
        return {
            ...state,
            status: EAuthStatus.UNATUHORIZED,
            error: action.error
        }
    case PROFILE_UPDATE_ATTEMPT:
        return {
            ...state,
            updatingProfile: true,
        }
    case PROFILE_UPDATE_SUCCESS:
        return {
            ...state,
            profile: {
                ...state.profile,
                avatar: action.profile.avatar,
                name: action.profile.name,
                lastName: action.profile.lastName,
                phone: action.profile.phone
            },
            updatingProfile: false,
        }
    case PROFILE_UPDATE_FAILURE:
        return {
            ...state,
            updatingProfile: false,
            error: action.error
        }
    case SECURITY_UPDATE_ATTEMPT:
        return {
            ...state,
            updatingSecurity: true
        }
    case SECURITY_UPDATE_SUCCESS:
        return {
            ...state,
            updatingSecurity: false
        }
    case SECURITY_UPDATE_FAILURE:
        return {
            ...state,
            updatingSecurity: false,
            securityError: action.securityError
        }
    case UPDATE_BALANCE:
        return {
            ...state,
            profile: {
                ...state.profile,
                balance: state.profile.balance + action.balance
            }
        }
    case AUTH_STATUS_CHANGED:
        return {
            ...state,
            status: action.status,
            error: ''
        }
    case AUTH_LOGOUT:
        return {
            ...state,
            error: '',
            status: EAuthStatus.UNATUHORIZED,
            profile: initialState.profile
        }
    default:
        return state;
 }
}
import React, { useMemo } from "react";
import { Banner } from "./banner";
import { IAdsLayout, IAdTags } from "../../../types";
import { Box, makeStyles } from "@material-ui/core";
import { buildLink } from "../../../utils";
import { AdsButton, DraggableAdsButton } from './button';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const useStyles = makeStyles((theme) => ({
  box: {
    padding: 8,
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      padding: 4,
    },
  },
  buttons: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: (props: { nested?: boolean }) =>
      props.nested ? theme.spacing(0) : theme.spacing(1.5, 0),
  },
}));

export interface AdsLayoutProps {
  layout: IAdsLayout;
  editable?: boolean;
  onClick?: (ads: IAdsLayout) => void;
  buttonEditMaker?: (idx: number) => void;
  buttonDeleteMaker?: (idx: number) => void;
  editBanner?: () => void;
  deleteBanner?: () => void;
  userId?: string;
  adTags?: IAdTags;
  nested?: boolean;
  moveButton?: (dragIdx: number, hoverIdx: number) => void;
}

export const AdsLayout = React.forwardRef<HTMLDivElement, AdsLayoutProps>(
  (
    {
      layout,
      onClick,
      editBanner,
      editable,
      buttonDeleteMaker,
      buttonEditMaker,
      deleteBanner,
      moveButton,
      userId,
      adTags,
      nested,
    },
    ref
  ) => {
    const classes = useStyles({ nested });
    const hasButtons = useMemo(
      () => !!layout.buttons && layout.buttons.length > 0,
      [layout.buttons]
    );
    return (
      <div ref={ref} className={classes.root}>
        {hasButtons && (
          <DndProvider backend={HTML5Backend}>
            <Box
              className={`${classes.box} ${classes.buttons}`}
              display="flex"
              justifyContent={layout.buttonsAlign}
            >
              {!moveButton
                ? layout.buttons &&
                  layout.buttons.map((button, idx) => (
                    <AdsButton
                      key={`ads-button-${button.id}`}
                      id={button.id}
                      type={button.type}
                      color={button.color}
                      text={button.text}
                      link={buildLink(button.link, adTags)}
                      userId={userId}
                      integration={button.integration && layout.integrations && layout.integrations[button.integration] ? layout.integrations[button.integration] : undefined}
                      payment={button.payment}
                      editable={editable}
                      onClick={onClick ? () => onClick(layout) : undefined}
                      onEdit={editable && buttonEditMaker ? () => buttonEditMaker(idx) : undefined}
                      onDelete={editable && buttonDeleteMaker ? () => buttonDeleteMaker(idx) : undefined}
                    />
                  ))
                : layout.buttons &&
                  layout.buttons.map((button, idx) => (
                    <div>
                      <DraggableAdsButton
                        id={button.id || `adsbtn-${idx}`}
                        key={button.id}
                        index={idx}
                        moveButton={moveButton}
                        color={button.color}
                        text={button.text}
                        link={buildLink(button.link, adTags)}
                        editable={editable}
                        onClick={onClick ? () => onClick(layout) : undefined}
                        userId={userId}
                        integration={button.integration && layout.integrations && layout.integrations[button.integration] ? layout.integrations[button.integration] : undefined}
                        payment={button.payment}
                        onEdit={
                          editable && buttonEditMaker
                            ? () => buttonEditMaker(idx)
                            : undefined
                        }
                        onDelete={
                          editable && buttonDeleteMaker
                            ? () => buttonDeleteMaker(idx)
                            : undefined
                        }
                      />
                    </div>
                  ))}
            </Box>
          </DndProvider>
        )}
        {layout.banner && layout.banner.file && (
          <Box className={classes.box}>
            <Banner
              type={layout.banner.type}
              image={layout.banner.file.link}
              link={buildLink(layout.banner.link, adTags)}
              userId={userId}
              integration={layout.banner.integration && layout.integrations && layout.integrations[layout.banner.integration] ? layout.integrations[layout.banner.integration] : undefined}
              payment={layout.banner.payment}
              editable={editable}
              onClick={onClick ? () => onClick(layout) : () => {}}
              onEdit={editBanner}
              onDelete={deleteBanner}
            />
          </Box>
        )}
      </div>
    );
  }
);

import { SvgIcon, SvgIconProps } from "@material-ui/core"


export const TimeIcon: React.FC<SvgIconProps> = ({...props}) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
        <defs>
          <style>{'.a{fill:#fff;stroke:#8b97e8;stroke-width:2px;}.b{fill:#8b97e8;}.c{fill:none;}.d{stroke:none;}'}</style>
        </defs>
        <g transform="translate(-149 -2)">
          <g className="a" transform="translate(149 2)">
            <circle className="d" cx="10" cy="10" r="10"/><circle className="c" cx="10" cy="10" r="9"/></g><path className="b" d="M14,12v3H11a1,1,0,0,0,0,2h4a.945.945,0,0,0,1-1V12a1,1,0,0,0-2,0Z" transform="translate(144 -4)"/></g><rect className="c" width="20" height="20" transform="translate(0)"/>
    </SvgIcon>
  );
}

export default TimeIcon;
import { Box, styled, Typography } from '@material-ui/core';
import { VolumeOff } from '@material-ui/icons';
import React from 'react';
import { tl } from '../../../utils';



const ScreenMutedWrap = styled(Box)(({theme}) => ({
  // root: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    top: '50%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    color: '#fff',
    borderRadius: 4,
    padding: 10,
    zIndex: 1300,
    left: '50%',
    transform: 'translate(-50%, -50%)',
    cursor: 'pointer',
    userSelect: 'none',
    transformOrigin: 'center',
  // }
}))

interface ScreenMutedProps {
  onClick: () => void;
  small?: boolean;
}

export const ScreenMuted: React.FC<ScreenMutedProps> = ({onClick, small}) => {

  return (
    <ScreenMutedWrap onClick={onClick} >
      <Box pr="8px"><VolumeOff/></Box>
      <Typography>
        {tl(
          small
          ? "Включить звук"
          : "Нажмите, чтобы включить звук"
        )}
      </Typography>
    </ScreenMutedWrap> 
  )
}

export default ScreenMuted;
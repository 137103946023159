import * as React from "react";
import {Box, Tooltip, TooltipProps} from "@material-ui/core";
import {themeVariables} from "../_Theme";
import styles  from "./styles.module.scss";

interface IProps extends Partial<TooltipProps> {
  title: string;
  children?: any;
  bgcolor?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;
}

export const MUITooltip = ({bgcolor, children, ...ttProps}: IProps) => {
  return (
    <Tooltip arrow leaveDelay={200} enterTouchDelay={0} {...ttProps}>
      {children ? (
        children
      ) : (
        <Box
          component="span"
          bgcolor={
            bgcolor
              ? themeVariables.palette.marks[bgcolor]
              : themeVariables.palette.grey.A700
          }
          className={styles.tooltip}
        >
          ?
        </Box>
      )}
    </Tooltip>
  );
};

import React, { useCallback } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { themeVariables } from '../../MUI';

import { UserCard } from './userCard';

import './styles.module.scss';
import { RootState } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import Logo from './logo';
import { logout } from '../../store/user/actions';
import useLocalStorage from '../../hooks/useLocalStorage';
import { Balance } from './balance';
import { FreePeriod } from './freePeriod';

interface HeaderProps {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  forStudent?: boolean;
  centered?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    display: 'flex',
    height: '42px',
    paddingRight: (props: HeaderProps) => (props.centered ? '20px' : '7px'),
    paddingLeft: '20px',
    backgroundColor: theme.palette.common.white,
    borderBottom: themeVariables.border,
    boxShadow: '0 -3px 15px #3F53D929',
    zIndex: 20,
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      paddingRight: (props: HeaderProps) => (props.centered ? '20px' : '0px'),
    },
  },
  centered: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  wrap: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    maxWidth: (props: HeaderProps) => (props.centered ? '940px' : '1280px'),
    margin: '0 auto',
  },

  sm: {
    width: '100%',
    maxWidth: '1103px',
  },

  md: {
    width: '100%',
    maxWidth: '1179px',
  },

  lg: {
    width: '100%',
    maxWidth: '1280px',
  },
  xl: {
    width: '100%',
  },

  userWrap: {
    // width: '100%',
    maxWidth: '320px',
    justifyContent: (props: HeaderProps) =>
      props.centered ? 'flex-end' : 'flex-start',

    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      // Хак, для того, чтобы перебить conditional-правило выше
      justifyContent: (props: HeaderProps) =>
        props.centered ? 'flex-end' : 'flex-end',
      right: '16px',
    },
  },

  mobile: {

    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      padding: 0,
      paddingRight: 0,
      paddingLeft: 16,
    },
  },
}));

export const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  const classes = useStyles(props);
  const [branch, ] = useLocalStorage('latest_branch', '');

  const user = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const handleLogout = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  return (
    <Box className={classes.header}>
      {branch}
      <Box className={classes.wrap}>
        <Box
          className={`${classes[props.size || 'md']} ${classes.centered} ${
            classes.mobile
          }`}
        >
          <Box className={classes.centered}>
            <Logo forStudent={props.forStudent} />
            {!props.forStudent &&
              <Balance balance={user.profile.balance} />
            }
            {!props.forStudent && user.profile.freePeriodDays &&
              <FreePeriod freePeriodDays={user.profile.freePeriodDays} />
            }
          </Box>
          <Box className={`${classes.userWrap} ${classes.centered}`}>
            {user.profile.id && (
              <>
                <Box>
                  <UserCard
                    user={user.profile}
                    handleLogout={handleLogout}
                    hideOnline
                  />
                </Box>
                {/* <Hidden only="xs">
                  <IconButton size="small">
                    <SvgIcon type="webinar-bell" />
                  </IconButton>
                </Hidden> */}
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Header;

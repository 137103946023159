import React, { ReactNode } from 'react';
import useAccessControl from '../hooks/useAccessControl';
import { Rules } from '../services/AccessControl.service';

type ActionType = Rules[keyof Rules][number]
interface PermissionProps {
  entity: keyof Rules;
  action?: ActionType | Array<ActionType>;
  fallback?: ReactNode;
}

export const Permission: React.FC<PermissionProps> = ({ fallback, action, entity, children }) => {
  const ac = useAccessControl();
  return (
    <>
      {ac.check(entity, action) ? children || null : fallback || null}
    </>
  );
};

export default Permission;
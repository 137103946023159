type webinarActions = "create" | "list" | "update" | "delete"  | "forecast" | "chatSettings";
type adsLayoutActions = "create" |  "list" |  "update" | "delete";
type autoWebinarActions = "create" | "list" | "update" | "delete" |  "scenario" | "statistics" | "chatSettings";
type chatActions = "block" | "create" | "update";
type presentationActions = "create" | "list";
type streamActions = "view" | "start" | "end" | "presentation" | "layout" | "ads" | "camera" | "members" | "message" |  "history" | "statistics";
type moderatorActions = "create" | "list" | "update";
type profileActions = "get" | "update";
type integrationsActions = "list" | "view" | "update" | "delete";


export type Rules = {
  'webinar': Array<webinarActions>;
  'adsLayout': Array<adsLayoutActions>;
  'stream': Array<streamActions>;
  'presentation': Array<presentationActions>;
  'chat': Array<chatActions>;
  'autowebinar': Array<autoWebinarActions>;
  'moderator': Array<moderatorActions>;
  'profile': Array<profileActions>;
  'statistics': Array<any>;
  'integrations': Array<integrationsActions>;
};

export default class AccessControl {

  rules: Rules;
  //пусть будет потом посмотрим
  role: any;

  constructor(rules: Rules) {
    this.rules = rules;
  };

  public setRules(rules: Rules): void {
    this.rules = rules;
  }
  public check<K extends keyof Rules>(entity: K, action?: Rules[K][number] | Array<Rules[K][number]>): boolean {
    if (!action || typeof action === 'string') {
      return this.checkOne(entity, action);
    } else if (Array.isArray(action)){
      return this.checkMultiple(entity, action);
    } else {
      return false;
    }
  }
  private checkOne<K extends keyof Rules>(entity: K, action: Rules[K][number]): boolean {
    const actions = this.rules[entity];
    if (actions) {
      if (!action && actions.length === 0) {
        return true;
      }
      //@ts-ignore
      if (actions.includes(action)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  private checkMultiple<K extends keyof Rules>(entity: K, actions: Array<Rules[K][number]>): boolean {
    const entityActions = this.rules[entity];
    //@ts-ignore
    return actions.every((action) => entityActions.includes(action));
  }
}
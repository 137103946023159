import { goBack, push } from "connected-react-router";
import { AppThunk } from "..";
import { api } from "../../api";
import { EWebinarStatus, EWebinarType, IPresentation, IWebinar, webinarTypeDict } from "../../types";
import { addNotification } from "../notifications/actions";
import { webinarsItemRemove, webinarsListUpdate } from "../webinars/actions";
import { v4 as uuidv4 } from "uuid";
import {
    IWebinarAddPresentationAction,
    IWebinarClearAction,
    IWebinarEditAction,
    IWebinarFetchAttemptAction,
    IWebinarFetchFailureAction,
    IWebinarFetchPresentations,
    IWebinarFetchSuccessAction,
    IWebinarPresentationProcessingEndAction,
    IWebinarPresentationProcessingStartAction,
    IWebinarPresentationUploadEndAction,
    IWebinarPresentationUploadStartAction,
    IWebinarRemovePresentationAction,
    IWebinarSetPresentationsListAction,
    WEBINAR_ADD_PRESENTATION,
    WEBINAR_CLEAR,
    WEBINAR_EDIT,
    WEBINAR_FETCH_ATTEMPT,
    WEBINAR_FETCH_FAILURE,
    WEBINAR_FETCH_PRESENTATIONS,
    WEBINAR_FETCH_SUCCESS,
    WEBINAR_PRESENTATION_PROCESSING_END,
    WEBINAR_PRESENTATION_PROCESSING_START,
    WEBINAR_PRESENTATION_UPLOAD_END,
    WEBINAR_PRESENTATION_UPLOAD_START,
    WEBINAR_REMOVE_PRESENTATION,
    WEBINAR_SET_PRESENTATIONS_LIST,
    CHAT_SET_MESSAGES,
    IChatSetMessagesAction
} from "./types";
import { finish } from "../stream/actions";
import { INewUserReview } from "../../types/UserReviews";


export function getPresentationsSuccess(list: Array<IPresentation>): IWebinarFetchPresentations {
    return {
        type: WEBINAR_FETCH_PRESENTATIONS,
        presentations: list
    }
}

export function getWebinarAttempt(): IWebinarFetchAttemptAction {
    return {
        type: WEBINAR_FETCH_ATTEMPT
    }
}

export function getWebinarClear(): IWebinarClearAction {
    return {
        type: WEBINAR_CLEAR
    }
}

export function getWebinarSuccess(webinar: IWebinar): IWebinarFetchSuccessAction {
    return {
        type: WEBINAR_FETCH_SUCCESS,
        webinar
    }
}

export function getWebinarFailure(error: string): IWebinarFetchFailureAction {
    return {
        type: WEBINAR_FETCH_FAILURE,
        error
    }
}

export function webinarIsEditing(value: boolean): IWebinarEditAction {
    return {
        type: WEBINAR_EDIT,
        isEditing: value
    }
}


export function fetchAutowebinarCreateWithScenario(data: FormData): AppThunk {
    return async dispatch => {
        dispatch(getWebinarAttempt());
        try {
            const response = await api.webinar.createWithFormData(data);
            dispatch(getWebinarSuccess(response))
            dispatch(push(`/webinar/${response.id}/scenario`))
            dispatch(webinarsListUpdate(response))
            dispatch(addNotification('success', 'Автовебинар создан'))
        } catch (error) {
            dispatch(addNotification('error', `${webinarTypeDict[EWebinarType.AUTOWEBINAR]} не создан. Попробуйте еще`));
            dispatch(getWebinarFailure(''));

        }
    }
}

export function fetchWebinarCreate(webinarType: EWebinarType, name: string): AppThunk {
    return async dispatch => {
        dispatch(getWebinarAttempt());
        try {
            const response = await api.webinar.create(webinarType, name)
            dispatch(getWebinarSuccess(response))
            dispatch(push(`/webinar/${response.id}`))
            dispatch(webinarsListUpdate(response))
            dispatch(addNotification('success', `${webinarTypeDict[webinarType]} создан`))
        } catch (error) {
            dispatch(addNotification('error', `${webinarTypeDict[webinarType]} не создан. Попробуйте еще`));
            dispatch(getWebinarFailure(''));

        }
    }
}

export function fetchWebinarSave(webinar: IWebinar, reason: string = 'Вебинар сохранён', path?: string): AppThunk {
    return async dispatch => {
        try {
            const webinarWithoutStatus: Partial<IWebinar> = Object.assign({}, webinar)
            delete webinarWithoutStatus.status // статус нам изменять не надо
            delete webinarWithoutStatus.presentations

            //? Добавляю текущую таймзону для бэка, т.к. все объекты дат при конвертировании в JSON
            //? форматируются в UTC 
            if (webinarWithoutStatus.dateSettings) {
                webinarWithoutStatus.dateSettings.clientTz = Intl.DateTimeFormat().resolvedOptions().timeZone
            }
            if (webinar.type === EWebinarType.AUTOWEBINAR) {
                //@ts-ignore
                webinarWithoutStatus.source = null;
            }
            const response = await api.webinar.save(webinarWithoutStatus)
            dispatch(webinarsListUpdate(response))
            dispatch(getWebinarSuccess(response))
            dispatch(webinarIsEditing(false))
            reason && dispatch(addNotification('success', reason))
            path && dispatch(push(path))
            return response.status
        } catch (error: any) {
            if (error.response?.data?.validation_messages?.source) {
                dispatch(addNotification('error', 'Ссылка на источник трансляции обязательна'))
            }
            else if (error.response?.data?.title === "Wrong start time") {
                dispatch(addNotification('error', 'Ошибка: время старта вебинара не может быть раньше настоящего момента'))
            } else {
                dispatch(addNotification('error', 'Произошла ошибка'))
            }
        }
    }
}


export function chatSettingsSave(webinar: IWebinar, reason: string = 'Вебинар сохранён', path?: string): AppThunk {
    return async dispatch => {
        try {
            const response = await api.webinar.save({ id: webinar.id, chatSettings: webinar.chatSettings })
            dispatch(webinarsListUpdate(response))
            dispatch(getWebinarSuccess(response))
            dispatch(webinarIsEditing(false))
            reason && dispatch(addNotification('success', reason))
            path && dispatch(push(path))
            return response.status
        } catch (error: any) {
            dispatch(addNotification('error', 'Произошла ошибка'))
        }
    }
}

export function fetchWebinar(id: string): AppThunk {
    return async dispatch => {
        dispatch(getWebinarAttempt())
        try {
            const response = await api.webinar.get(id)
            dispatch(getWebinarSuccess(response))
        } catch (error: any) {
            dispatch(getWebinarFailure(error))
        }
    }
}


export function clearWebinar(): AppThunk {
    return async dispatch => {
        dispatch(getWebinarClear())
    }
}


export function fetchWebinarCopy(webinarId: string): AppThunk {
    return async dispatch => {
        try {
            const response = await api.webinar.copy(webinarId)
            dispatch(webinarsListUpdate(response))
        } catch (error) {
            dispatch(addNotification('error', 'Произошла ошибка'))
        }
    }
}


export function fetchWebinarConvert(webinarId: string, path: string = ""): AppThunk {
    return async dispatch => {
        try {
            const response = await api.webinar.convert(webinarId)
            dispatch(fetchWebinar(response.id))
            dispatch(webinarsListUpdate(response))
            dispatch(push(`/webinar/${response.id}${path}`))
        } catch (error) {

        }
    }
}

export function fetchWebinarRemove(webinar: IWebinar): AppThunk {
    return async dispatch => {
        try {
            await api.webinar.remove(webinar.id);
            dispatch(webinarsItemRemove(webinar.id, webinar.type));
            dispatch(addNotification('info', 'Вебинар удален'));
        } catch (error: any) {
            const message = error.response?.data?.title === 'Webinar is started'
                ? 'Чтобы удалить вебинар (или автовебинар), завершите эфир'
                : 'Произошла ошибка';
            dispatch(addNotification('error', message));
        }
    }
}


export function fetchWebinarPause(webinar: IWebinar): AppThunk {
    return async dispatch => {
        try {
            const response = await api.webinar.pause(webinar);
            dispatch(webinarsListUpdate(response))
            dispatch(getWebinarSuccess(response))
            dispatch(webinarIsEditing(false))
            dispatch(addNotification('info', 'Автовебинар поставлен на паузу'))
        } catch (error: any) {
            dispatch(addNotification('error', 'Произошла ошибка'))
        }
    }
}

export function fetchWebinarPlay(webinar: IWebinar): AppThunk {
    return async dispatch => {
        try {
            const response = await api.webinar.play(webinar)
            dispatch(webinarsListUpdate(response))
            dispatch(getWebinarSuccess(response))
            dispatch(webinarIsEditing(false))
            dispatch(addNotification('info', 'Автовебинар снят с паузы'))
        } catch (error: any) {
            dispatch(addNotification('error', 'Произошла ошибка'))
        }
    }
}


export function fetchWebinarFinishStream(webinar: IWebinar): AppThunk {
    return async dispatch => {
        try {
            dispatch(finish(webinar.link))
            const response = await api.webinar.get(webinar.id);
            //TODO: убрать, как разберемся с тасками, пока оптимистик апдейт
            response.status = EWebinarStatus.FINISH;
            dispatch(webinarsListUpdate(response))
            dispatch(getWebinarSuccess(response))
            dispatch(webinarIsEditing(false))
        } catch (error: any) {
            dispatch(addNotification('error', 'Произошла ошибка'))
        }
    }
}

export function addPresentation(presentation: IPresentation): IWebinarAddPresentationAction {
    return {
        type: WEBINAR_ADD_PRESENTATION,
        presentation
    }
}

export function startPresentationProcessing(presentation: IPresentation): IWebinarPresentationProcessingStartAction {
    return {
        type: WEBINAR_PRESENTATION_PROCESSING_START,
        presentation
    }
}

export function endPresentationProcessing(id: string, pages: number): IWebinarPresentationProcessingEndAction {
    return {
        type: WEBINAR_PRESENTATION_PROCESSING_END,
        id,
        pages,
    }
}

export function startPresentationUploading(presentation: IPresentation): IWebinarPresentationUploadStartAction {
    return {
        type: WEBINAR_PRESENTATION_UPLOAD_START,
        presentation
    }
}

export function endPresentationUploading(id: string, presentation: IPresentation): IWebinarPresentationUploadEndAction {
    return {
        type: WEBINAR_PRESENTATION_UPLOAD_END,
        id,
        presentation
    }
}

export function fetchWebinarPresentations(webinarId: string): AppThunk {
    return async (dispatch) => {
        try {
            const data = await api.presentation.getAll(webinarId);
            dispatch(getPresentationsSuccess(data));
        } catch (error) {

        }
    }
}

export function fetchWebinarAddPresentation(
    webinar: IWebinar,
    file: File,
    presentationCallback?: (presentation: IPresentation) => void,
): AppThunk {
    return async (dispatch) => {
        try {
            let presentation = {
                id: uuidv4(),
                name: file.name,
                isReady: false,
            } as IPresentation
            dispatch(startPresentationUploading(presentation));
            await api.presentation.upload(webinar, presentation, file);
            dispatch(endPresentationUploading(presentation.id, presentation));
            presentationCallback && presentationCallback(presentation);
        } catch (error) {
            dispatch(addNotification('error', 'Произошла ошибка'));
        } finally {
            dispatch(addNotification('info', 'Презентация загружена и обрабатывается'));
        }
    };
}

export function removePresentation(presentation: IPresentation): IWebinarRemovePresentationAction {
    return {
        type: WEBINAR_REMOVE_PRESENTATION,
        presentation
    }
}

export function fetchWebinarRemovePresentation(webinar: IWebinar, presentation: IPresentation): AppThunk {
    return async (dispatch) => {
        try {
            await api.presentation.remove(webinar, presentation);
            dispatch(removePresentation(presentation));
            dispatch(addNotification('error', 'Презентация удалена'))
        } catch (error: any) {
            dispatch(addNotification('error', 'Произошла ошибка при удалении презентации'));
        }
    };
}

export function setPresentationsList(presentations: IPresentation[]): IWebinarSetPresentationsListAction {
    return {
        type: WEBINAR_SET_PRESENTATIONS_LIST,
        presentations
    }
}

export function postReview(review: INewUserReview): AppThunk {
    return async (dispatch) => {
        dispatch(goBack());
        await api.userReview.create(review);
    }
}

export function chatMessagesChange(params: any): IChatSetMessagesAction {
    return {
        type: CHAT_SET_MESSAGES,
        payload: params
    }
}

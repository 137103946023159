import { SvgIcon, SvgIconProps } from "@material-ui/core"
import { forwardRef, useMemo } from "react";
import { fromHSV, toHSV } from "../../utils";

interface TickingIconProps {
  seconds: number;
  iconColor?: string;
}

export const TickingClockIcon: React.FC<TickingIconProps & SvgIconProps> = forwardRef(({seconds, iconColor, ...props}, ref) => {
  
  const circleBackColor = useMemo(() => {
    if (iconColor) {
      const hsv = toHSV(iconColor);
      hsv[1]! *= .2;
      //@ts-ignore
      return fromHSV(hsv);
    } else {
      return "#d8dcf8";
    }
  }, [iconColor])
  return (
    <SvgIcon {...props} viewBox="0 0 26 26" innerRef={ref} style={{transform: 'rotate(-90deg)', ...props.style}}>
       <g id="Круг2" transform="translate(0 -31.33)">
        <rect id="Фон" width="26" height="26" transform="translate(0 31.33)" fill="none"/>
        <path id="Круглый_фон" data-name="Круглый фон" d="M13,57.33a13,13,0,1,1,13-13,13,13,0,0,1-13,13Zm0-23a10,10,0,1,0,10,10,10,10,0,0,0-10-10Z" fill={circleBackColor} />
        <g id="Заполнение_круга" data-name="Заполнение круга" >
          <circle id="Фон-2" data-name="Фон" cx="13" cy="13" r="13" transform="translate(0 31.33)" fill="none"/>
          <circle id="Фон-3" strokeDasharray="72" strokeDashoffset={(seconds * 1.2)} strokeWidth="2.5" data-name="Фон" cx="13" cy="13" r="11.5" strokeLinecap="round" transform="translate(0 31.33)" fill="none" stroke="currentColor"/>

          {/* <path id="Заполнение" d="M13,57.33a13,13,0,0,1-13-13,1.5,1.5,0,1,1,3,0,10,10,0,1,0,10-10,1.5,1.5,0,0,1,0-3,13,13,0,1,1,0,26Z" fill="#3f53d9"/> */}
        </g>
        <g id="Стрелочка" >
          <circle id="Фон-3" data-name="Фон" cx="6" cy="6" r="6" transform="translate(7 38.33)" fill="none"/>
          <path style={{transformOrigin:'55% 50%', transformBox: 'fill-box', transform: `rotate(${-180-(seconds * 6)}deg)`}} data-name="Стрелочка" d="M15.27,46.81l-2.48-2.48,2.48-2.47a1.51,1.51,0,0,0,0-2.12,1.49,1.49,0,0,0-2.12,0L9.61,43.27a1.49,1.49,0,0,0,0,2.12h0l3.54,3.54a1.49,1.49,0,0,0,2.12,0A1.51,1.51,0,0,0,15.27,46.81Z" fill="currentColor"/>
        </g>
      </g>
    </SvgIcon>
  );
})

export default TickingClockIcon;
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Breadcrumbs, Collapse, Grid, Link as MUILink, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormSettingsRow, FormSettingsSection, GridContainer, MUIBlock, themeVariables } from '../../../MUI';
import BreadcrumbsSeparatorIcon from '../../../components/Icon/breadcrumbsSeparator';
import { PasswordBarsHint } from './passwordBarsHint';
import { RootState } from '../../../store';
import { updateSecurity } from '../../../store/user/actions';
import Page from '../../../hoc/page';
import { SettingsNavigationMenu } from '../menu';
import { tl } from '../../../utils';
import { NewPasswordValidatorResult, newPasswordValidator } from './utils';

export interface IPasswordData {
  newPassword: string;
  repeatPassword: string;
}

const useStyles = makeStyles({
  wrap: {
    justifyContent: "flex-end",
  
    [themeVariables.breakpoints.down("md")]: {
      justifyContent: "flex-start",
    },
  },
  
  content: {
    minWidth: "300px",
    maxWidth: "687px",
    boxSizing: "content-box",
    // Заглушка вместо левого меню, которое теоретически
    // должно быть добавлено в МР с CloudPayments
    marginLeft: "216px",

    [themeVariables.breakpoints.down("md")]: {
      marginLeft: 0,
    },
  },
});

export const SecurityPage: React.FC = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);

  const [passwordData, setPasswordData] = useState<IPasswordData>({
    newPassword: '',
    repeatPassword: '',
  });
  const [validationResult, setValidationResult] = useState<NewPasswordValidatorResult>({level: 'empty', failed: []});

  const handleChangeField = (field: keyof IPasswordData, value: string) => {
    setPasswordData(prev => ({
      ...prev,
      [field]: value.trim()
    }));
  };

  const handleNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    
    handleChangeField('newPassword', value);

    if (!value) {
      setValidationResult({level: 'empty', failed: []});
      return;
    }

    const validationResult = newPasswordValidator(value);
    setValidationResult(validationResult);
  };

  const handleSubmit = async (password: string) => {
    dispatch(updateSecurity(password));
  };

  return (
    <Page title={tl("Безопасность")}>
      <GridContainer direction="row-reverse" spacing={2} className={classes.wrap}>
        <SettingsNavigationMenu />
          <Grid item xs zeroMinWidth className={classes.content}>
            <MUIBlock
              head={
                <Breadcrumbs
                  separator={<BreadcrumbsSeparatorIcon style={{ height: "11px", width: "11px" }}/>}
                >
                  <MUILink component={Link} to={`/`} color="inherit">
                    <Typography variant="subtitle1">
                      {tl("Мой аккаунт")}
                    </Typography>
                  </MUILink>
                  <MUILink component={Link} to={`/settings/profile`} style={{ color: "black" }}>
                    <Typography variant="subtitle1">
                      {tl("Безопасность")}
                    </Typography>
                  </MUILink>
                </Breadcrumbs>
              }
              controlsDivider
              controls={
                [
                  [],
                  [
                    {
                      label: tl('Сохранить'),
                      variant: 'contained',
                      color: 'primary',
                      type: 'button',
                      disabled: !passwordData.newPassword || 
                                passwordData.newPassword !== passwordData.repeatPassword ||
                                user.updatingSecurity ||
                                validationResult.level === "weak" ||
                                validationResult.level === "minlength",
                      onClick: () => handleSubmit(passwordData.newPassword),
                    }
                  ]
                ]
              }
    >       
              <FormSettingsSection noBorderTop>
                <FormSettingsRow label={tl('Новый пароль:')} cssModifier="lowMargin" smallLabel>
                  <TextField 
                    fullWidth 
                    variant="outlined" 
                    onChange={handleNewPasswordChange} 
                    type="password" 
                  />
                </FormSettingsRow>
                <FormSettingsRow smallLabel>
                  <PasswordBarsHint validationLevel={validationResult.level} failedTests={validationResult.failed} />
                </FormSettingsRow>
                <FormSettingsRow label={tl('Повторите новый пароль:')} smallLabel>
                  <TextField 
                    fullWidth 
                    variant="outlined" 
                    type="password"
                    error={passwordData.newPassword !== passwordData.repeatPassword}
                    helperText={passwordData.newPassword !== passwordData.repeatPassword ? tl('Пароли должны совпадать') : ''} 
                    onChange={(e) => handleChangeField('repeatPassword', e.currentTarget.value)}
                  />
                </FormSettingsRow>
                <Collapse in={!!user.securityError}>
                  <FormSettingsRow>
                    <Typography color="error">{user.securityError}</Typography>
                  </FormSettingsRow>
                </Collapse>
              </FormSettingsSection>
            </MUIBlock>
          </Grid>
      </GridContainer>
    </Page>
  )
}
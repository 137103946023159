import React from "react";
import {Box, BoxProps,} from "@material-ui/core";
import styles from "./styles.module.scss";
import {themeVariables} from "../index";

interface IProps extends BoxProps {
  noBorderTop?: boolean;
  borderBottom?: boolean;
  head?: any;
  inner?: boolean;
  noPadding?: boolean;
  reversedPadding?: boolean;
  children?: any;
}

export const FormSettingsSection = ({
  noBorderTop,
  borderBottom,
  head,
  inner,
  noPadding,
  reversedPadding,
  children,
  ...boxProps
}: IProps) => {

  return (
    <Box
      className={`${styles.section} ${head ? styles.withHead : ""}`}
      {...boxProps}
    >
      {head ? (
        <Box
          borderTop={noBorderTop ? undefined : themeVariables.border}
          className={styles.sectionHead}
        >
          {head}
        </Box>
      ) : null}
      <Box
        borderTop={noBorderTop && !head? undefined : themeVariables.border}
        borderBottom={borderBottom ? themeVariables.border : undefined}
        className={
          noPadding
            ? undefined
            : `${styles.sectionBody} ${
              reversedPadding ? styles.reversedPadding : ""
            } ${inner ? styles.inner : ""}`
        }
      >
        {children}
      </Box>
    </Box>
  );
};

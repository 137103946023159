import {apiClient} from "./_client";
import {EReportType, IReport} from "../types";

export const create = async (ids:Array<string>, type: EReportType):Promise<IReport> => {
    const response = await apiClient.post<IReport>(`/report`,{
      ids:ids,
      type: type
    });
    return response.data;
}

export const status = async (id:string):Promise<IReport> => {
    const response = await apiClient.get<IReport>(`/report/${id}/status`);
    return response.data
}
import * as React from "react";
import {
  Grid,
  Link as MUILink,
  Box,
  Hidden,
} from "@material-ui/core";
import { themeVariables } from "../../MUI";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { tl } from "../../utils";

import styles  from "./styles.module.scss";
import Logo from "../Header/logo";
import { withStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  footer: {
    boxShadow: '0 3px 15px #3F53D929',
    flex: "0 0 auto",
    backgroundColor: theme.palette.common.white,
    borderTop: themeVariables.border,
    color: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      // marginBottom: 80
    }
  },
  link: {
    fontWeight: 400,
    textDecoration: 'none',
    fontSize: 14,
    lineHeight: '16px',
    color: ' #2C3A97',
    wordBreak: 'keep-all',
    '&:not(:last-child)': {
      paddingBottom: 12,

      [theme.breakpoints.up('sm')]: {
        paddingBottom: 4,
      }
    },
    [theme.breakpoints.down('md')] : {
      display: 'none'
    }
  },
  mobileLogo: {
    // padding: '10px 0',
    height: 66,
  },
  linkMobile: {
    fontWeight: 300,
    textDecoration: 'none',
    fontSize: 14,
    lineHeight: '16px',
    display: 'inline-flex',
    color: ' #2C3A97',
    [theme.breakpoints.up('md')] : {
      display: 'none'
    }
  }
}));

const LogoWrap = withStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'nowrap',
    font: 'normal normal normal 32px/41px Days',
    height: 66,
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
    },
    '& span': {
      color: ' #2C3A97',
    }
  },
}))(Box)

export const Footer: React.FC = () => {
  const classes = useStyles();

  const renderLinks = () => (
    <Grid item xs={12} sm="auto" className={styles.FooterLinks}>
      <MUILink
        href="/offer"
        underline="none"
        className={classes.link}
      >
        {tl("Договор оферты")}
      </MUILink>
      <MUILink
        href="/privacy-policy"
        underline="none"
        className={classes.link}
      >
        {tl("Политика конфиденциальности")}
      </MUILink>
      <MUILink
        href="/offer"
        underline="none"
        className={classes.linkMobile}
      >
        {tl("Договор оферты")}
      </MUILink>
      <MUILink
        href="/privacy-policy"
        underline="none"
        color="primary"
        className={classes.linkMobile}
      >
        {tl("Конфиденциальность")}
      </MUILink>
    </Grid>
  );

  return (
    <Box
      className={`${styles.Footer} ${classes.footer}`}
    >
      <Box className={styles.FooterWrap}>
        <Grid container alignItems="center" justify="space-between">
          <Grid item xs={2} sm="auto">
            <LogoWrap>
              <Hidden only="xs">
                <Logo forStudent />
              </Hidden>
              <Hidden smUp>
                <span>SW</span>
              </Hidden>
            </LogoWrap>
          </Grid>
          <Grid item container xs={10} sm className={styles.FooterText}>
            {renderLinks()}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};


import { SvgIcon, SvgIconProps } from "@material-ui/core"


interface ChatIconProps {
  hasNew?: boolean;
}

export const ChatIcon: React.FC<SvgIconProps & ChatIconProps> = ({hasNew, ...props}) => {
  return (
    <SvgIcon {...props} viewBox="0 0 26 26">
      <g id="_chat" data-name=" chat">
        <path id="Контур_10080" data-name="Контур 10080" d="M0,0H26V26H0Z" transform="translate(0)" fill="none"/>
        <g id="icon-chat" transform="translate(0.337 2.258)" fill="none" strokeLinecap="round">
          <path d="M13.354,19.266H-.337l3.978-4.641a8.383,8.383,0,0,1-1.6-4.883,8.631,8.631,0,0,1,.71-3.328c1.341-3.453,5.59-6.2,10.6-6.2,6.246,0,11.309,4.264,11.309,9.524,0,5.094-4.751,9.255-10.724,9.512l.011.013Z" stroke="none"/>
          <path d="M 13.6036319732666 17.26621627807617 L 13.85278606414795 17.25551605224609 C 16.29257583618164 17.1507453918457 18.55261611938477 16.28339576721191 20.21659660339355 14.81324672698975 C 21.79410552978516 13.41950607299805 22.66287612915039 11.61850643157959 22.66287612915039 9.742006301879883 C 22.66287612915039 7.798136234283447 21.73920631408691 5.949726104736328 20.0620059967041 4.537256240844727 C 18.28593635559082 3.041526317596436 15.90351676940918 2.217796325683594 13.35361671447754 2.217796325683594 C 11.30916595458984 2.217796325683594 9.357436180114746 2.742216348648071 7.709436416625977 3.734376192092896 C 6.220266342163086 4.630916118621826 5.1223464012146 5.839766502380371 4.617946147918701 7.138246536254883 L 4.612006187438965 7.153366088867188 C 4.213366508483887 8.155376434326172 4.043286323547363 8.929546356201172 4.043286323547363 9.742006301879883 C 4.043286323547363 11.04801654815674 4.467896461486816 12.33589649200439 5.271206378936768 13.46641635894775 L 6.176266193389893 14.74013614654541 L 4.011002063751221 17.26621627807617 L 13.6036319732666 17.26621627807617 M 13.9493465423584 19.26621627807617 L -0.3375036418437958 19.26621627807617 L 3.640876293182373 14.62487602233887 C 2.626246452331543 13.19695663452148 2.043286323547363 11.52694606781006 2.043286323547363 9.742006301879883 C 2.043286323547363 8.700546264648438 2.242576360702515 7.698736190795898 2.753666400909424 6.414056301116943 C 4.095126152038574 2.960736274719238 8.343466758728027 0.2177962958812714 13.35361671447754 0.2177962958812714 C 19.59915542602539 0.2177962958812714 24.66287612915039 4.482206344604492 24.66287612915039 9.742006301879883 C 24.66287612915039 14.83646583557129 19.91214561462402 18.99714660644531 13.93859672546387 19.25367546081543 L 13.9493465423584 19.26621627807617 Z" stroke="none" fill="currentColor"/>
        </g>
        {hasNew && <path id="круг" d="M6,0A6,6,0,1,1,0,6,6,6,0,0,1,6,0Z" transform="translate(14 12)" fill="#df4c4c"/>}
      </g>
    </SvgIcon>
  );
}

export default ChatIcon;

import { apiClient } from "./_client";

export interface IInvoiceSort {
  sortBy: 'date';
  sortOrder: 'asc' | 'desc';
  search?: string
}

export async function invoice(sort: IInvoiceSort, limit: number = 20, offset: number = 0) {
  const res = await apiClient.get('/billing/invoice', {
    params: {
      filter: sort,
      offset,
      limit
    }
  });
  return res.data;
}

export async function pay(price: number) {
  const res = await apiClient.get<{ location: string }>('/billing/pay', {
    params: {
      price
    }
  });
  return res.data;
}

export async function buyContacts(stream: string) {
  const response = await apiClient.post('/member/unblock', {
    stream
  });

  return response.data;

}

export async function listPaymentVariants() {
  await apiClient.get('/billing/payment');
}

export async function plans() {
  const res = await apiClient.get('/billing/tariffs');
  return res.data;
}
import { IAdsLayout } from '../../types';

export const AD_UPDATE = 'AD_UPDATE';
export const AD_REMOVE = 'AD_REMOVE';
export const AD_COPY = 'AD_COPY';
export const ADS_RESET = 'ADS_RESET';
export const ADS_FETCH_ATTEMPT = 'ADS_FETCH_ATTEMPT';
export const ADS_FETCH_SUCCESS = 'ADS_FETCH_SUCCESS';
export const ADS_FETCH_FAILURE = 'ADS_FETCH_FAILURE';
export const AD_SAVING = 'AD_SAVING';
export const AD_SAVED = 'AD_SAVED';
export interface IUpdateAdAction {
  type: typeof AD_UPDATE;
  ad: IAdsLayout;
}

export interface IAdSavingAction {
  type: typeof AD_SAVING;
}

export interface IAdSavedAction {
  type: typeof AD_SAVED;
}

export interface IRemoveAdAction {
  type: typeof AD_REMOVE;
  ad: IAdsLayout;
}

export interface ICopyAdAction {
  type: typeof AD_COPY;
  ad: IAdsLayout;
}

export interface IResetAdsAction {
  type: typeof ADS_RESET;
}

export interface IAdsFetchAttemptAction {
  type: typeof ADS_FETCH_ATTEMPT;
}

export interface IAdsFetchSuccessAction {
  type: typeof ADS_FETCH_SUCCESS;
  ads: IAdsLayout[];
  count: number;
  clear?: boolean;
}

export interface IAdsFetchFailureAction {
  type: typeof ADS_FETCH_FAILURE;
}

export type TAdsActionType =
  | IAdSavingAction
  | IAdSavedAction
  | IUpdateAdAction
  | IRemoveAdAction
  | ICopyAdAction
  | IResetAdsAction
  | IAdsFetchAttemptAction
  | IAdsFetchSuccessAction
  | IAdsFetchFailureAction;

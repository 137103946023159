export interface ITicket {
  id: number;
  webinar_id: string;
  name: string;
  last_name?: string;
  email: string;
  phone?: string;
  auth_link: string;
  member_role: ETicketRole;
  auth_code: string;
  released_date?: string;
  is_online?: boolean;
  location?: string;
  avatar?: string;
}

export enum ETicketRole {
  Moderator = 'moderator',
  Member = 'member',
  Speaker = 'speaker'
}
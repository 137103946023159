import React from 'react';
import { PaymentWidgetsProvider } from '../../PaymentWidget';
import { Box, Divider, Grid, makeStyles, styled } from '@material-ui/core';
import { AdsLayout } from '../Layouts';
import {IAdsLayout, IAdsLayoutWithTimer, IAdTags} from '../../../types';
import { ArrowBackIosRounded } from '@material-ui/icons';
import ChatIcon from '../../Icon/chat';
import { AdsLayoutWithTimer } from '../Layouts/withTimer';


const ChatButton = styled('button')(({theme}) => ({
  backgroundColor: theme.palette.common.white,
  boxShadow: '0px 3px 6px #0C53F7',
  color: theme.palette.primary.dark,
  border: 'none',
  borderRadius: 20,
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1,2,1,1),
  '& > span': {
    // font: 'normal normal 600 13px/15px SF UI Text',
    fontSize: 13,
    font: 'SF UI Text',
    textTransform: 'uppercase',
    marginRight: theme.spacing(.5),
  },
  '& svg': {
    color: theme.palette.primary.main,
  },
  '& > .arrow': {
    fontSize: 16,
  },
  '& > .chat': {
    fontSize: 26,
  },
}))

const useStyles = makeStyles((theme)=>({
  adWrap: {
    width: '100%',
  },
  hr: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    }
  }
}))

export interface AdsListPlayerProps {
  userId?: string;
  streamAds: Array<IAdsLayout>;
  onAdClick?: (ad: IAdsLayout) => void;
  adTags?: IAdTags;
  showChatBtn?: boolean;
  onChatButtonClick?: () => void;
}

export const AdsList: React.FC<AdsListPlayerProps> = ({
  userId, streamAds, onAdClick, adTags, showChatBtn, onChatButtonClick
}) => {
  const classes = useStyles();
  return (
    <Grid item container className={classes.adWrap}>
      {
        showChatBtn &&
          <React.Fragment>
            <Box justifyContent="flex-end" display="flex" width="100%" my={1}>
              <ChatButton onClick={onChatButtonClick} data-testid="inChatBtn">
                <ArrowBackIosRounded className="arrow" />
                <span>В чат</span>
                <ChatIcon className="chat" hasNew={true} />
              </ChatButton>
            </Box>
           <Divider className={classes.hr}  />
          </React.Fragment>
      }
      <PaymentWidgetsProvider>
      {streamAds.map((ad, i) =>
        <React.Fragment key={`${i}-ad`}>
         {
           ad.timer?
            <AdsLayoutWithTimer
              key={ad.id}
              userId={userId}
              layout={(ad as IAdsLayoutWithTimer)}
              onClick={onAdClick && onAdClick}
              adTags={adTags}
            />:
            <AdsLayout
              userId={userId}
              layout={ad}
              onClick={onAdClick && onAdClick}
              adTags={adTags}
            />
         }
         {i !== (streamAds.length -1)&& <Divider className={classes.hr}  />}
        </React.Fragment>
      )}
      </PaymentWidgetsProvider>
    </Grid>
  )
}

export default AdsList;
import { ITicket } from '../../types/Ticket';
import {
  TICKETS_FETCH_ATTEMPT,
  TICKETS_FETCH_SUCCESS,
  TICKET_CLEAR,
  TICKET_REMOVE,
  TTicketsAction,
} from './types';

export interface IModeratorsInitialState {
  list: ITicket[];
  count: number;
  loading: boolean;
  error?: string;
}

const initialState: IModeratorsInitialState = {
  list: [],
  count: 0,
  loading: false,
};

export default function ticketsReducer(
  state = initialState,
  action: TTicketsAction
) {
  switch (action.type) {
    case TICKET_CLEAR:
      return {
        ...state,
        loading: false,
        list: [],
        count: 0,
      };

    case TICKETS_FETCH_ATTEMPT:
      return {
        ...state,
        loading: true,
      };
    case TICKETS_FETCH_SUCCESS:
      return {
        ...state,
        list: action.list,
        count: action.count,
        loading: false,
      };

    case TICKET_REMOVE:
      const prevList = state.list.slice();
      
      return {
        ...state,
        list: prevList.filter(
          (moderator) => moderator.id !== action.ticket.id
        ),
        count: state.count - 1,
      };

    default:
      return state;
  }
}

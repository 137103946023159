import { IWebinar } from "../../types";

export const SET_REVIEW_WEBINAR = 'SET_REVIEW_WEBINAR'


export interface ISetReviewWebinar {
  type: typeof SET_REVIEW_WEBINAR;
  webinar?: IWebinar;
}

export type TAppActions = ISetReviewWebinar
import { NavLink, NavLinkProps } from 'react-router-dom';
import { Box, styled } from '@material-ui/core';
import { IThemeSettings } from '../../MUI';
import React from 'react';

type SideMenuItemVariant = 'default' | 'navigation';

const SideMenuItemWrap = styled(Box)(({theme}) => ({
  display: 'flex',
  flexDirection: 'row',
}));

const SideMenuItemNavLink = styled(NavLink)(({ theme, variant }: { theme: IThemeSettings, variant: SideMenuItemVariant }) => ({
  textDecoration: 'none',
  '& .icon': {
    color: theme.palette.primary.light,
  },
  '&:hover .sideMenuItemBadge, &:hover .sideMenuItemContainer': {
    backgroundColor: theme.palette.grey['A100'],
  },
  '&.active .sideMenuItemBadge': {
    backgroundColor: theme.palette.primary.main,
  },
  '&.active .sideMenuItemContainer': {
    backgroundColor: variant === 'navigation' ? theme.palette.default.main : theme.palette.common.white,
    color: theme.palette.common.black,
    fontWeight: 600,
  },
}));

const SideMenuItemContainer = styled(Box)(({ theme, variant }: { theme: IThemeSettings, variant: SideMenuItemVariant }) => ({
  display: 'flex',
  flexDirection: 'row',
  flex: '1 1 auto',
  alignItems: 'center',
  padding: variant === 'navigation' ? '8px 21px' : '6px 8px',
  backgroundColor: variant === 'navigation' ? theme.palette.common.white : 'transparent',
  color: theme.palette.primary.dark,
  cursor: 'pointer',
}));

const SideMenuItemNavigationBadge = styled(Box)(({theme}) => ({
  flex: '0 0 2px',
  backgroundColor: theme.palette.common.white,
}));

const SideMenuItemLabel = styled(Box)({
  flex: '1 1 auto',
  whiteSpace: 'nowrap'
});

const SideMenuItemPrepend = styled(Box)(({ theme}) => ({
  flex: '0 0 auto',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: '8px',
}));

const SideMenuItemAppend = styled(Box)(({ theme}) => ({
  flex: '0 0 auto',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: '8px',
}));

export interface SideMenuItemProps extends NavLinkProps {
  label: string;
  variant?: SideMenuItemVariant;
  prepend?: React.ReactNode;
  append?: React.ReactNode;
}

export const SideMenuItem: React.FC<SideMenuItemProps> = ({ append, label, variant = 'default', prepend, ...navLinkProps }) => {
  return (
    <SideMenuItemNavLink variant={variant} {...navLinkProps}>
      <SideMenuItemWrap>
        {variant === 'navigation' &&
        <SideMenuItemNavigationBadge className="sideMenuItemBadge"/>
        }
        <SideMenuItemContainer className="sideMenuItemContainer" variant={variant}>
          {prepend &&
          <SideMenuItemPrepend>
            {prepend}
          </SideMenuItemPrepend>
          }
          <SideMenuItemLabel>
            {label}
          </SideMenuItemLabel>
          {append &&
          <SideMenuItemAppend>
            {append}
          </SideMenuItemAppend>
          }
        </SideMenuItemContainer>
      </SideMenuItemWrap>
    </SideMenuItemNavLink>
  )
}
import * as React from "react";
import {Grid, makeStyles} from "@material-ui/core";
import {GridProps} from "@material-ui/core/Grid";

const useStyles =makeStyles((theme) => ({
  GridContainer: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginRight: 0,
      marginLeft: 0,
      "& > .MuiGrid-item": {
        flex: "auto",
        paddingRight: 0,
        paddingLeft: 0,
      },
    },
  },
}));

export const GridContainer = ({className, children, ...props}: GridProps) => {
  const classes = useStyles();
  return (
    <Grid
      container
      {...props}
      className={`${className} ${classes.GridContainer}`}
    >
      {children}
    </Grid>
  );
};

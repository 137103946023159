import { AppThunk } from "..";
import { api } from "../../api";
import { IPlan, IPlanMeta } from "../../types/Billing";
import { addNotification } from "../notifications/actions";
import { IPlansFetchSuccess, PLANS_FETCH_ATTEMPT, PLANS_FETCH_SUCCESS, GET_PAYLINK } from "./types";


export function setPaying(isPaying: boolean) {
  return {
    type: GET_PAYLINK,
    isPaying
  }
}

export function plansFetchAttempt() {
  return {
    type: PLANS_FETCH_ATTEMPT
  }
}

export function plansFetchSuccess(list: Array<IPlan>, meta: IPlanMeta): IPlansFetchSuccess {
  return {
    type: PLANS_FETCH_SUCCESS,
    list, meta
  }
} 


export function getPlans(): AppThunk {
  return async (dispatch) => {
    dispatch(plansFetchAttempt());

    try {
      const data = await api.billing.plans();
      dispatch(plansFetchSuccess(data.list, data.meta));
    } catch (error) {
      
    }
  }
}

export function pay(price: number): AppThunk {
  return async (dispatch) => {
    dispatch(setPaying(true));
    try {
      const data = await api.billing.pay(price);
      window.open(data.location, '_blank');
      dispatch(setPaying(false));
    } catch (error) {
      dispatch(setPaying(false));
      dispatch(addNotification('error', 'Сейчас невозможно оплатить, пожалуйста обратитесь в поддержку'))
    }
  }
}
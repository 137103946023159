export interface ISplashScreenCommon {
  type: ESplashScreenType
}

export enum ESplashScreenType {
  TIMER='timer',
  IMAGE='image',
  SLIDESHOW='slideshow',
  VIDEO='video'
}

export interface ITimerSplashScreen extends ISplashScreenCommon{
  type: ESplashScreenType.TIMER;
  audio?: string;
}
export interface IVideoSplashScreen extends ISplashScreenCommon {
  type: ESplashScreenType.VIDEO;
  video: string;
}
export interface IImageSplashScreen extends ISplashScreenCommon {
  type: ESplashScreenType.IMAGE;
  image: string;
  audio?: string;
}
export interface ISlideshowSplashScreen extends ISplashScreenCommon {
  type: ESplashScreenType.SLIDESHOW;
  images: Array<string>;
  audio?: string;
}


export type TSplashScreen =  
                ITimerSplashScreen |
                IVideoSplashScreen |
                IImageSplashScreen |
                ISlideshowSplashScreen
import { Typography,Box } from '@material-ui/core';
import React from 'react'
import { MUIDialog } from '../../MUI';
import { tl } from '../../utils';


export interface WebinarPauseModalProps {
    open: boolean
    webinarName: string
    handleClose:()=>void
    handleConfirm:()=>void

}

export const WebinarPauseModal:React.FC<WebinarPauseModalProps> = ({open,webinarName,handleClose,handleConfirm})=> {

    return (
        <MUIDialog
            title={tl(`Поставить автовебинар на паузу`)}
            open={open}
            onClose={handleClose}
            mobileFullScreen
            maxWidth="sm"
            actions={[
                [],
                [
                {
                    label: tl("На паузу"),
                    btnProps: {
                        onClick: handleConfirm
                    },
                },
                {
                    label: tl("Отмена"),
                    btnProps: {
                    color: "primary",
                    onClick: handleClose
                    },
                },
                ],
            ]}
            >
            <Box>
                <Typography>
                {tl("Вы уверены, что хотите поставить на паузу автовебинар ")}
                <b>{tl(webinarName)}</b>?
                </Typography>
            </Box>
        </MUIDialog>
    )
}

export default WebinarPauseModal
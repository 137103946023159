import * as React from "react";
import classnames from "classnames";
import styles  from "./styles.module.scss";

class Item extends React.Component<{ onChange?: any; id: any }, {}> {
  onChange = () => {
    this.props.onChange(this.props.id);
  };

  render() {
    const {children} = this.props;

    return (
      <div className={styles.DropdownSelectItem} onClick={this.onChange}>
        {children}
      </div>
    );
  }
}

interface IDropdownProps {
  title?: any;
  onChange?: any;
}

export class DropdownSelect extends React.Component<IDropdownProps,
  {
    isOpen: boolean;
    dropDown: boolean;
  }> {
  public state = {
    isOpen: false,
    dropDown: false
  };

  private dropdown: any;

  public static Item = Item;

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
      dropDown:
        this.dropdown.getBoundingClientRect().top < window.innerHeight / 2
    });
    // console.log(this.dropdown.getBoundingClientRect().top, window.innerHeight / 2);
  };

  render() {
    const {children, title} = this.props;
    const {isOpen, dropDown} = this.state;

    const className = {
      DropdownSelect: classnames({
        [styles.DropdownSelect]: true,
        [styles.DropdownSelectDown]: dropDown
      }),
      DropdownSelectCurrent: classnames({
        [styles.DropdownSelectCurrent]: true,
        [styles.DropdownSelectCurrentOpen]: isOpen
      })
    };

    return (
      <div
        className={className.DropdownSelect}
        onClick={this.toggle}
        ref={r => (this.dropdown = r)}
      >
        <div className={className.DropdownSelectCurrent}>{title}</div>

        {!isOpen || (
          <>
            <div
              className={styles.DropdownSelectBlocker}
              onClick={this.toggle}
            />
            <div className={styles.DropdownSelectItemList}>
              {React.Children.map(children, (el: any) =>
                React.cloneElement(el, {onChange: this.props.onChange})
              )}
            </div>
          </>
        )}
      </div>
    );
  }
}

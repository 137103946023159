import { SvgIcon, SvgIconProps } from "@material-ui/core"
import { forwardRef } from "react";


export const UserIcon: React.FC<SvgIconProps> = forwardRef(({...props}, ref) => {
  return (
    <SvgIcon {...props}  viewBox="0 0 20 20" innerRef={ref}>
      <g id="Сгруппировать_24445" data-name="Сгруппировать 24445">
        <rect id="Прямоугольник_1985" data-name="Прямоугольник 1985" width="20" height="20" fill="none"/>
        <g id="Сгруппировать_2434" data-name="Сгруппировать 2434" transform="translate(-1092.502 -190.5)">
          <path id="Контур_10055" data-name="Контур 10055" d="M16697.4,7956.157H16683.6a5.274,5.274,0,0,1-.1-1,5.938,5.938,0,0,1,3.58-5.236,4.038,4.038,0,0,0,6.84,0,5.938,5.938,0,0,1,3.582,5.234,5.425,5.425,0,0,1-.1,1Z" transform="translate(-15587.998 -7749.159)" fill="#8b97e8"/>
          <path id="Контур_386" data-name="Контур 386" d="M3,0A3.279,3.279,0,0,1,6,3.5,3.279,3.279,0,0,1,3,7,3.279,3.279,0,0,1,0,3.5,3.279,3.279,0,0,1,3,0Z" transform="translate(1099.5 194)" fill="#8b97e8"/>
        </g>
      </g>
    </SvgIcon>
  );
})

export default UserIcon;
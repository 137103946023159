import * as React from 'react';
import { useState } from 'react';
import { tl } from '../../utils';
import { Box, styled, TextField, Typography } from '@material-ui/core';
import { FormSettingsRow, MUIDialog } from '../../MUI';
import { Form } from '..';
import { useDispatch } from 'react-redux';
import { Location } from 'history';
import { useHistory } from 'react-router';
import { initializeProfile } from '../../store/user/actions';

const Text = styled(Typography)({
  fontSize: 15,
  paddingBottom: 16,
});

interface NameModalProps {
  location?: Location;
  name?: string;
  lastName?: string;
}

export const NameModal = (props: NameModalProps) => {
  const [name, setName] = useState<string>(props.name || '');
  const [lastName, setLastName] = useState<string>(props.lastName || '');

  const dispatch = useDispatch();
  const history = useHistory();

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value);
  };


  const handleSubmit = (event: any) => {
    event.preventDefault();
    if(name) {
      dispatch(initializeProfile({name: name, lastName: lastName}));
    }
  };

  return (
    <MUIDialog
      title={tl('Вы входите на платформу вебинаров')}
      open={Boolean(props.location)}
      mobileFullScreen
      maxWidth="sm"
      actions={[
        [],
        [ 
          // {
          //   label: 'Отмена',
          //   btnProps: {
          //     onClick:  () => history.go(-1)
          //   }
          // },
          {
            label: tl('Сохранить и войти'),
            btnProps: {
              'data-testid': 'save_btn',
              color: 'primary',
              type: 'submit',
              form: 'name-form',
              disabled: !name,
            },
          },
        ],
      ]}
    >
      <Form id="name-form" onSubmit={handleSubmit}>
        <Text>{tl(`Укажите ваше имя для отображения в чате со зрителями во время эфиров`)}</Text>
        <FormSettingsRow label={tl('Имя:')} smallLabel>
          <Box maxWidth="374px">
            <TextField
              data-testid="author_name"
              variant="outlined"
              fullWidth
              value={name}
              placeholder={tl('Имя')}
              onChange={handleNameChange}
            />
          </Box>
        </FormSettingsRow>
        <FormSettingsRow label={tl('Фамилия:')} smallLabel>
          <Box maxWidth="374px">
            <TextField
              data-testid="author_lastName"
              variant="outlined"
              fullWidth
              value={lastName}
              placeholder={tl('Фамилия')}
              onChange={handleLastNameChange}
            />
          </Box>
        </FormSettingsRow>
      </Form>
    </MUIDialog>
  );
};
